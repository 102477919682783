import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import {
  fetchPriceListStatsFailure,
  fetchPriceListStatsSuccess,
  FetchPriceListStatsRequest
} from "../reducers/priceListStats";

/**
 * Call the API to retrieve the stats for a price list with the given ID.
 */
function fetchPriceListStats(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string
): Promise<Supplier.V1PricelistsStatsDetail.ResponseBody> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/stats`,
    tenancyId
  );
}

/**
 * Saga to retrieve a price list's stats.
 */
export default createSaga(fetchPriceListStats, {
  prepareApiMethodParams: ({ priceListId }: FetchPriceListStatsRequest["payload"]) => [priceListId],
  successAction: fetchPriceListStatsSuccess,
  errorFailureAction: fetchPriceListStatsFailure
});
