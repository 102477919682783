import { Auth0ContextInterface } from "@auth0/auth0-react";

import {
  fetchAllPriceListsFailure,
  fetchAllPriceListsSuccess,
  FetchAllPriceListsRequest
} from "features/suppliers/price-lists/reducers/allPriceLists";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";
import { buildSearchString } from "utils/urls";

/**
 * Calls the API /supplier/v1/pricelists to fetch all the price lists belonging to the current company
 */
function fetchAllPriceLists(
  auth: Auth0ContextInterface,
  tenancyId: string,
  limit?: number
): Promise<Supplier.V1PricelistsList.ResponseBody> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists${buildSearchString({ limit })}`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Saga to fetch all the price lists that belong to that supplier
 */
export default createSaga(fetchAllPriceLists, {
  prepareApiMethodParams: ({ limit }: FetchAllPriceListsRequest["payload"]) => [limit],
  prepareSuccessActionParams: (_action, response) => [
    {
      ...response,
      overrideList: true
    }
  ],
  successAction: fetchAllPriceListsSuccess,
  failureAction: fetchAllPriceListsFailure
});
