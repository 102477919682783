import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  createStandingOrderSuccess,
  deleteStandingOrderProductsSuccess,
  pauseStandingOrderSuccess,
  resumeStandingOrderSuccess,
  updateStandingOrderProductsSuccess
} from "features/suppliers/directory/reducers/standingOrders";
import { SupplierInternal } from "types/api";
import {
  BuyerDetails,
  BuyerLinkInitiatedBy,
  ConsolidatedInvoicesBuyerSetting,
  LinkedBuyerStatus,
  OverridableSettingBuyerFlag,
  PaymentRequirement,
  PriceListType,
  ScheduleStatus,
  Supplier
} from "types/api/generated/supplier";
import { ApiValidationProblem, ContactSummary } from "types/api/generated/supplier-internal";
import { FailureAction, RequestAction } from "types/redux-helpers";

export interface BuyerByIdState extends BuyerDetails {
  contacts: ContactSummary[];
  loading: boolean;
  updatingSettings: boolean;
  updatingTags: boolean;
  contactName?: string;
  error?: Error | ApiValidationProblem;
}

const initialState: BuyerByIdState = {
  isClaimed: false,
  tradingName: "",
  primaryAddress: "",
  addresses: [],
  phone: "",
  contactName: "",
  email: "",
  contacts: [],
  firstName: "",
  lastName: "",
  loading: false,
  updatingSettings: false,
  updatingTags: false,
  link: {
    actions: [],
    initiatedBy: BuyerLinkInitiatedBy.Buyer,
    status: LinkedBuyerStatus.Unlinked
  },
  tags: [],
  standingOrderSchedules: [],
  lastOrder: { orderId: 0 },
  buyerId: "",
  priceList: {
    priceListId: "",
    name: "",
    priceListType: PriceListType.Custom
  },
  settings: {
    defaultOrderNote: "",
    disableInvoices: false,
    consolidatedInvoices: ConsolidatedInvoicesBuyerSetting.Disabled,
    paymentRequirement: PaymentRequirement.SupplierDefault,
    autoOrderApproval: OverridableSettingBuyerFlag.SupplierDefault
  },
  providerContact: null,
  externalReferences: [],
  issues: []
};

export type FetchBuyerByIdRequest = RequestAction<
  Supplier.V1BuyersDetail.RequestParams & { silent?: boolean },
  Supplier.V1BuyersDetail.ResponseBody
>;
type FetchBuyerByIdSuccess = PayloadAction<Supplier.V1BuyersDetail.ResponseBody>;

export type UpdateBuyerByIdRequest = RequestAction<
  Supplier.V1BuyersUpdate.RequestParams & { contact: Supplier.V1BuyersUpdate.RequestBody },
  Supplier.V1BuyersUpdate.ResponseBody
>;
type UpdateBuyerByIdSuccess = PayloadAction<Supplier.V1BuyersUpdate.ResponseBody>;

export type UpdateBuyerSettingsRequest = RequestAction<
  Supplier.V1BuyersSettingsCreate.RequestParams & { settings: Supplier.V1BuyersSettingsCreate.RequestBody },
  Supplier.V1BuyersSettingsCreate.ResponseBody
>;
type UpdateBuyerSettingsSuccess = PayloadAction<Supplier.V1BuyersSettingsCreate.ResponseBody>;

export type UpdateBuyerTagsRequest = RequestAction<
  SupplierInternal.InternalBuyersTagsCreate2.RequestParams & {
    tags: SupplierInternal.InternalBuyersTagsCreate2.RequestBody;
  },
  SupplierInternal.InternalBuyersTagsCreate2.ResponseBody
>;
type UpdateBuyerTagsSuccess = PayloadAction<SupplierInternal.InternalBuyersTagsCreate2.ResponseBody>;

/**
 * Slice for the supplier/buyerById reducer.
 */
const buyerByIdSlice = createSlice({
  name: "buyerById",
  initialState,
  reducers: {
    fetchBuyerByIdRequest: (state, action: FetchBuyerByIdRequest) => ({
      ...state,
      loading: !action.payload.silent
    }),
    fetchBuyerByIdSuccess: (state, { payload: buyer }: FetchBuyerByIdSuccess) => ({
      ...state,
      ...buyer,
      contactName: buyer.firstName || buyer.lastName ? `${buyer.firstName} ${buyer.lastName}` : undefined,
      loading: false
    }),
    fetchBuyerByIdFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error,
      loading: false
    }),
    updateBuyerByIdRequest: (state, _action: UpdateBuyerByIdRequest) => ({
      ...state,
      loading: true
    }),
    updateBuyerByIdSuccess: (state, { payload: buyer }: UpdateBuyerByIdSuccess) => ({
      ...state,
      ...buyer,
      contactName: buyer.firstName || buyer.lastName ? `${buyer.firstName} ${buyer.lastName}` : undefined,
      loading: false
    }),
    updateBuyerByIdFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error,
      loading: false
    }),
    updateBuyerSettingsRequest: (state, _payload: UpdateBuyerSettingsRequest) => ({
      ...state,
      updatingSettings: true
    }),
    updateBuyerSettingsSuccess: (state, { payload: settings }: UpdateBuyerSettingsSuccess) => ({
      ...state,
      updatingSettings: false,
      settings
    }),
    updateBuyerSettingsFailure: state => ({
      ...state,
      updatingSettings: false
    }),
    updateBuyerTagsRequest: (state, _action: UpdateBuyerTagsRequest) => ({
      ...state,
      updatingTags: true
    }),
    updateBuyerTagsSuccess: (state, { payload }: UpdateBuyerTagsSuccess) => ({
      ...state,
      updatingTags: false,
      // Update the tags that changed but leave the existing tags
      tags: state.tags.map(tag => payload.find(newTag => newTag.tagId === tag.tagId) || tag)
    }),
    updateBuyerTagsFailure: state => ({
      ...state,
      updatingTags: false
    })
  },
  extraReducers: builder => {
    // Update the buyer's standing order schedule object when it's changed
    builder.addCase(createStandingOrderSuccess, (state, { payload: standingOrder }) => ({
      ...state,
      standingOrderSchedules: [standingOrder]
    }));
    builder.addCase(pauseStandingOrderSuccess, (state, { payload: newStandingOrder }) => ({
      ...state,
      standingOrderSchedules: state.standingOrderSchedules.map(oldStandingOrder => {
        return oldStandingOrder.externalId === newStandingOrder.externalId ? newStandingOrder : oldStandingOrder;
      })
    }));
    builder.addCase(resumeStandingOrderSuccess, (state, { payload: newStandingOrder }) => ({
      ...state,
      standingOrderSchedules: state.standingOrderSchedules.map(oldStandingOrder => {
        return oldStandingOrder.externalId === newStandingOrder.externalId ? newStandingOrder : oldStandingOrder;
      })
    }));
    // Update the buyer's standing order schedule status when products are added/removed
    builder.addCase(updateStandingOrderProductsSuccess, (state, { payload: { standingOrderId, products } }) => ({
      ...state,
      standingOrderSchedules: state.standingOrderSchedules.map(oldStandingOrder => {
        return oldStandingOrder.externalId === standingOrderId && products.length > 0
          ? { ...oldStandingOrder, status: ScheduleStatus.Active }
          : oldStandingOrder;
      })
    }));
    builder.addCase(deleteStandingOrderProductsSuccess, (state, { payload: { standingOrderId, products } }) => ({
      ...state,
      standingOrderSchedules: state.standingOrderSchedules.map(oldStandingOrder => {
        return oldStandingOrder.externalId === standingOrderId && products.length === 0
          ? { ...oldStandingOrder, status: ScheduleStatus.Disabled }
          : oldStandingOrder;
      })
    }));
  }
});

export const {
  fetchBuyerByIdRequest,
  fetchBuyerByIdSuccess,
  fetchBuyerByIdFailure,
  updateBuyerByIdRequest,
  updateBuyerByIdSuccess,
  updateBuyerByIdFailure,
  updateBuyerSettingsRequest,
  updateBuyerSettingsSuccess,
  updateBuyerSettingsFailure,
  updateBuyerTagsRequest,
  updateBuyerTagsSuccess,
  updateBuyerTagsFailure
} = buyerByIdSlice.actions;
export default buyerByIdSlice.reducer;
