import { combineReducers } from "@reduxjs/toolkit";

import { GlobalReduxState } from "types/redux";

import directory from "./directory";
import pendingInvites from "./pendingInvites";
import pendingLeads from "./pendingLeads";
import pendingSupplierById from "./pendingSupplierById";
import supplierById from "./supplierById";
import suppliersStats from "./suppliersStats";

/**
 * The buyer directory combined reducer.
 */
const reducers = combineReducers({
  directory,
  supplierById,
  pendingInvites,
  pendingSupplierById,
  pendingLeads,
  suppliersStats
});

export interface DirectoryReduxState extends GlobalReduxState {
  directory: ReturnType<typeof reducers>;
}

export default reducers;
