import { FeaturesState } from "redux/reducers/features";

import useAppSelector from "./useAppSelector";

export type Features = string[] | string;

function isInFeature(key: string | null | undefined, feature: string, allFeatures: FeaturesState): boolean {
  const featureKey = allFeatures[key ?? "null"]; // Redux would serialize our null keys as "null"
  // This is just to handle that the `error` property that's in the features index signature
  // Fay adding this as a reminder: " I dunno if we've ever used the error in individual state slices for anything 😆 It might be worth removing it from redux so we don't need code blocks like this."
  if (featureKey instanceof Error) {
    return false;
  }

  return featureKey?.[feature] === "On";
}

/**
 * React hook for doing a lookup of feature flags stored in redux.  Features
 * must first be loaded from the API (can be done using `useFetchFeatures`).
 * Otherwise, any query will only ever return the default values, which are
 * "Off"/`false` and the loading value of `true`.
 *
 * @param key
 *   The key/scope of the feature.
 * @param features
 *   The feature or list of feature names to retrieve.
 * @return
 *   An array of booleans, each representing the feature being on/off in the
 *   order they were requested in.
 */
export default function useFeatures(key: string | null | undefined, ...features: Features[]): boolean[] {
  const allFeatures = useAppSelector(state => state.features);
  return features.map(feature => {
    if (Array.isArray(feature)) {
      return feature.every(f => isInFeature(key, f, allFeatures));
    }
    return isInFeature(key, feature, allFeatures);
  });
}
