import { useAuth0 } from "@auth0/auth0-react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useEffect, useRef } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import CircleIcon from "components/CircleIcon";
import Modal from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import { Root } from "routes/Routes";
import { logger } from "utils/Datadog";
import useModal from "utils/hooks/useModal";

import strings from "./index.strings.json";

const useStyles = makeStyles()(theme => ({
  modal: {
    textAlign: "center"
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  reloadButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

/**
 * This modal is shown when it detects that the logged in user has changed.
 * This can happen when someone logs out/in on another tab.
 */
const UserChangedModal: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { isAuthenticated, isLoading, user } = useAuth0();
  const [isModelOpen, openModal] = useModal();
  const authIdRef = useRef<string>();

  useEffect(() => {
    if (!isLoading && isAuthenticated && user?.sub) {
      if (authIdRef.current && authIdRef.current !== user.sub) {
        logger.info("Auth0 user changed, forcing user to reload the app");
        openModal();
      }
      authIdRef.current = user.sub;
    }
  }, [isLoading, isAuthenticated, user?.sub, openModal]);

  const handleReload = (): void => {
    window.location.href = Root.toUrl();
  };

  return (
    <Modal fullWidth maxWidth="xs" open={isModelOpen} className={cx(classes.modal)}>
      <CircleIcon size={32}>
        <PersonOutlineIcon />
      </CircleIcon>
      <Typography id="dialog-title" className={cx(classes.title)} variant="h2">
        <FormattedMessage id={messages.TITLE} />
      </Typography>

      <Typography>
        <FormattedMessage id={messages.CONTENT} />
      </Typography>

      <Button className={cx(classes.reloadButton)} color="primary" variant="contained" onClick={handleReload}>
        <FormattedMessage id={messages.RELOAD_BUTTON} />
      </Button>
    </Modal>
  );
};

export default UserChangedModal;
