import { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { DECIMALSCALE, roundTo2DP } from "utils/numbers";

// The NumberFormat `customInput` type isn't compatible with the styled `customInput` type, so just omit it since we're not using it anyway.
export interface CurrencyInputProps extends Omit<NumberFormatProps, "customInput"> {
  name?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  autoComplete?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  maxValue?: number;
}

/**
 * A wrapper around the Number Format component from https://github.com/s-yadav/react-number-format specifically for use with currency.
 */
const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ onChange = () => {}, name = "", value = "", maxValue, ...other }, ref) => {
    /* eslint-disable-next-line no-restricted-globals */
    const val = isNaN(parseFloat(value)) ? "" : parseFloat(value).toFixed(DECIMALSCALE);

    return (
      <NumberFormat
        value={val ? roundTo2DP(val) : ""} // Do our own rounding because of https://github.com/s-yadav/react-number-format/issues/415
        getInputRef={ref}
        onValueChange={({ value: stringValue }) => {
          const event = {
            target: {
              name,
              value: stringValue
            }
          } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

          onChange?.(event);
        }}
        thousandSeparator
        isNumericString
        allowEmptyFormatting
        allowNegative
        inputMode="decimal"
        data-lpignore="true"
        isAllowed={({ floatValue }) => (maxValue ? floatValue === undefined || floatValue <= maxValue : true)}
        {...other}
      />
    );
  }
);

export default CurrencyInput;
