import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Supplier } from "types/api";
import { PriceListSummary } from "types/api/generated/supplier";
import { RequestAction } from "types/redux-helpers";

export interface PriceListState extends Partial<Omit<PriceListSummary, "priceListId" | "name">> {
  priceListId: string | null;
  name: string | null;
  loading: boolean;
}

const initialState: PriceListState = {
  priceListId: null,
  name: null,
  loading: true
};

export type CreatePriceListRequest = RequestAction<
  Supplier.V1PricelistsCreate.RequestBody,
  Supplier.V1PricelistsCreate.ResponseBody
>;
type CreatePriceListSuccess = PayloadAction<Supplier.V1PricelistsCreate.ResponseBody>;
export type DeletePriceListRequest = RequestAction<{ priceListId: string }, Supplier.V1PricelistsDelete.ResponseBody>;
type DeletePriceListSuccess = PayloadAction<Supplier.V1PricelistsDelete.ResponseBody>;
export type DuplicatePriceListRequest = RequestAction<
  { priceListId: string },
  Supplier.V1PricelistsDuplicateCreate.ResponseBody
>;
type DuplicatePriceListSuccess = PayloadAction<Supplier.V1PricelistsDuplicateCreate.ResponseBody>;
export type FetchPriceListRequest = RequestAction<{ priceListId: string }>;
type FetchPriceListSuccess = PayloadAction<Supplier.V1PricelistsDetail.ResponseBody>;
export type RenamePriceListRequest = RequestAction<
  { priceListId: string; name: string },
  Supplier.V1PricelistsUpdate.ResponseBody
>;
type RenamePriceListSuccess = PayloadAction<Supplier.V1PricelistsUpdate.ResponseBody>;

/**
 * State slice for working over a single price list.
 */
const priceList = createSlice({
  name: "priceList",
  initialState,
  reducers: {
    createPriceListRequest: (state, _action: CreatePriceListRequest) => ({
      ...state,
      loading: true
    }),
    createPriceListSuccess: (state, { payload: data }: CreatePriceListSuccess) => ({
      ...state,
      ...data,
      loading: false
    }),
    createPriceListFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    deletePriceListRequest: (state, _action: DeletePriceListRequest) => ({
      ...state,
      loading: true
    }),
    deletePriceListSuccess: (state, _action: DeletePriceListSuccess) => ({
      ...state,
      loading: false
    }),
    deletePriceListFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    duplicatePriceListRequest: (state, _action: DuplicatePriceListRequest) => ({
      ...state,
      loading: true
    }),
    duplicatePriceListSuccess: (state, _action: DuplicatePriceListSuccess) => ({
      ...state,
      loading: false
    }),
    duplicatePriceListFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    fetchPriceListRequest: (state, _action: FetchPriceListRequest) => ({
      ...state,
      loading: true
    }),
    fetchPriceListSuccess: (state, { payload: data }: FetchPriceListSuccess) => ({
      ...state,
      ...data,
      loading: false
    }),
    fetchPriceListFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    renamePriceListRequest: (state, _action: RenamePriceListRequest) => ({
      ...state,
      loading: true
    }),
    renamePriceListSuccess: (state, { payload: data }: RenamePriceListSuccess) => ({
      ...state,
      ...data,
      loading: false
    }),
    renamePriceListFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const {
  createPriceListRequest,
  createPriceListSuccess,
  createPriceListFailure,
  deletePriceListRequest,
  deletePriceListSuccess,
  deletePriceListFailure,
  duplicatePriceListRequest,
  duplicatePriceListSuccess,
  duplicatePriceListFailure,
  fetchPriceListRequest,
  fetchPriceListSuccess,
  fetchPriceListFailure,
  renamePriceListRequest,
  renamePriceListSuccess,
  renamePriceListFailure
} = priceList.actions;
export default priceList.reducer;
