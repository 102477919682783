import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { ButtonBase, Container, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { ComponentType, FunctionComponent, ReactNode, useEffect } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import VideoDialog from "components/VideoDialog";
import useMessages from "i18n/hooks/useMessages";
import { Feature } from "types/api/generated/global-internal";
import { useAnalytics } from "utils/analytics";
import useModal from "utils/hooks/useModal";
import { show, showNewMessage } from "utils/Intercom";

import strings from "./PaidFeaturePromotion.strings.json";

export interface Quote {
  image: string;
  text: ReactNode;
  author: ReactNode;
  logo: string;
}

export interface Perk {
  icon: OverridableComponent<SvgIconTypeMap> | ComponentType;
  title: ReactNode;
  description: ReactNode;
}

interface PaidFeaturePromotionProps {
  header: ReactNode;
  subheader?: ReactNode;
  description: ReactNode;
  upgradePrefill?: string;
  demoPrefill?: string;
  videoId?: string;
  videoThumbnail?: string;
  perks: Perk[];
  quote?: Quote;
  feature?: Feature;
}

const useStyles = makeStyles()(theme => ({
  page: {
    maxWidth: 800
  },
  header: {
    textAlign: "center",
    paddingBottom: theme.spacing(1)
  },
  subheader: {
    fontWeight: "bold"
  },
  ctaButton: {
    margin: theme.spacing(3, 1, 0)
  },
  videoContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  video: {
    backgroundColor: theme.palette.grey["200"],
    borderRadius: 12,
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    aspectRatio: "calc(16/9)",
    overflow: "hidden"
  },
  videoThumbnail: {
    width: "100%",
    height: "100%"
  },
  playIcon: {
    fontSize: 64,
    position: "absolute",
    color: theme.palette.secondary.main
  },
  gridContainer: {
    border: `1px solid ${theme.palette.grey["200"]}`,
    backgroundColor: theme.palette.grey["200"],
    borderRadius: 12,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "hidden",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2)
    }
  },
  gridItem: {
    margin: 0.5,
    flex: "49%",
    display: "flex",
    backgroundColor: theme.palette.common.white
  },
  leftAlign: {
    flex: 0,
    padding: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2)
    }
  },
  rightAlign: {
    flex: 1,
    padding: theme.spacing(3, 3, 3, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 2, 2, 0)
    }
  },
  perkTitle: {
    fontWeight: "bold"
  },
  iconContainer: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    borderRadius: 12,
    backgroundColor: "rgb(243, 242, 250)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(6),
      width: theme.spacing(6)
    }
  },
  icon: {
    color: theme.palette.secondary.main,
    height: 28,
    width: 28,
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(3),
      width: theme.spacing(3)
    }
  },
  quoteContainer: {
    paddingTop: theme.spacing(6),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      flexDirection: "column"
    }
  },
  quoteThumbnailContainer: {
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2)
    }
  },
  quote: {
    flex: 1,
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 0, 0, 2)
    }
  },
  quoteThumbnail: {
    width: "100%",
    borderRadius: 12
  },
  quoteAuthor: {
    padding: theme.spacing(2, 0)
  },
  quoteAvatar: {
    width: 64
  },
  getStartedContainer: {
    padding: theme.spacing(2, 0),
    textAlign: "center"
  },
  getStartedText: {
    padding: theme.spacing(3, 0)
  },
  comparePlansText: {
    paddingTop: theme.spacing(3)
  }
}));

const PaidFeaturePromotion: FunctionComponent<PaidFeaturePromotionProps> = ({
  header,
  subheader,
  description,
  upgradePrefill,
  demoPrefill,
  videoId,
  videoThumbnail,
  perks,
  quote,
  feature
}) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const analytics = useAnalytics();
  const [isVideoPlayerOpen, openVideoPlayer, closeVideoPlayer] = useModal();

  useEffect(() => {
    analytics.track("Paid Feature Page Shown", {
      feature
    });
  }, [analytics, feature]);

  return (
    <Container className={cx(classes.page)}>
      <div className={cx(classes.header)}>
        <Typography variant="h1" gutterBottom>
          {header}
        </Typography>
        <Typography variant="body1" className={cx(classes.subheader)} gutterBottom>
          {subheader}
        </Typography>
        <Container maxWidth="xs">
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        </Container>
        <Container maxWidth="xs">
          <Button
            color="primary"
            variant="contained"
            className={cx(classes.ctaButton)}
            onClick={() => showNewMessage(upgradePrefill)}
          >
            <FormattedMessage id={messages.UPGRADE_NOW} />
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className={cx(classes.ctaButton)}
            onClick={() => showNewMessage(demoPrefill)}
          >
            <FormattedMessage id={messages.BOOK_DEMO} />
          </Button>
        </Container>
      </div>
      {videoId && videoThumbnail && (
        <div className={cx(classes.videoContainer)}>
          <ButtonBase onClick={openVideoPlayer} className={cx(classes.video)}>
            <img src={videoThumbnail} alt="Video thumbnail" className={cx(classes.videoThumbnail)} />
            <PlayCircleFilledIcon className={cx(classes.playIcon)} />
          </ButtonBase>
        </div>
      )}
      <div className={cx(classes.gridContainer)}>
        {perks?.map((perk: Perk, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={cx(classes.gridItem)}>
            <div className={cx(classes.leftAlign)}>
              <div className={cx(classes.iconContainer)}>
                <perk.icon className={cx(classes.icon)} />
              </div>
            </div>
            <div className={cx(classes.rightAlign)}>
              <Typography variant="body1" className={cx(classes.perkTitle)} gutterBottom>
                {perk.title}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {perk.description}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      {quote && (
        <div className={cx(classes.quoteContainer)}>
          <div className={cx(classes.quoteThumbnailContainer)}>
            <img src={quote.image} alt="Quote thumbnail" className={cx(classes.quoteThumbnail)} />
          </div>
          <div className={cx(classes.quote)}>
            <Typography variant="h6">{quote.text}</Typography>
            <Typography className={cx(classes.quoteAuthor)} variant="body2">
              {quote.author}
            </Typography>
            <img src={quote.logo} alt="Company logo" className={cx(classes.quoteAvatar)} />
          </div>
        </div>
      )}
      <Container className={cx(classes.getStartedContainer)}>
        <Typography variant="body1" className={cx(classes.getStartedText)} color="textSecondary">
          <FormattedMessage id={messages.GET_STARTED} />
        </Typography>
        <Button color="primary" variant="contained" onClick={show}>
          <FormattedMessage id={messages.GET_STARTED_BUTTON} />
        </Button>
        <Typography variant="body1" className={cx(classes.comparePlansText)}>
          <FormattedMessage
            id={messages.COMPARE_PLANS}
            values={{
              viewPlansLink: {
                href: `https://www.upstock.app/supplier-plans`
              }
            }}
          />
        </Typography>
      </Container>
      {videoId && <VideoDialog vimeoVideoID={videoId} open={isVideoPlayerOpen} onClose={closeVideoPlayer} />}
    </Container>
  );
};

export default PaidFeaturePromotion;
