import { Capacitor } from "@capacitor/core";
import { datadogRum } from "@datadog/browser-rum";
import type { Analytics, Options } from "@segment/analytics-next";
import { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { CompanyRoot } from "routes/Routes";

import useAppSelector from "./hooks/useAppSelector";

let hasSegmentInitialised = false;

// The analytics.js snippet doesn't exist in unit tests. It also helps prevent ad blockers from breaking the app.
if (window.analytics) {
  // This is pulled from the analytics.js snippet so that we can more easily use different write keys in different environments
  window.analytics._writeKey = process.env.REACT_APP_SEGMENT_WRITEKEY;
  if (window.analytics._writeKey) {
    window.analytics.load(window.analytics._writeKey, {
      integrations: {
        "Segment.io": {
          deliveryStrategy: {
            strategy: "batching",
            config: {
              size: 10,
              timeout: 5000
            }
          }
        }
      }
    });

    window.analytics.ready(() => {
      // Automatically send all Segment track events to Datadog RUM
      window.analytics.register({
        name: "Datadog RUM",
        type: "destination",
        version: "1.0.0",
        isLoaded: () => true,
        load: () => Promise.resolve(),
        track(ctx) {
          if (ctx.event.event) {
            datadogRum.addAction(ctx.event.event, ctx.event.properties);
          }
          return ctx;
        },
        page(ctx) {
          datadogRum.startView({ name: ctx.event.name });
          return ctx;
        }
      });

      hasSegmentInitialised = true;
    });
  }
}

export interface CustomAnalytics {
  page: (name?: string, properties?: object, options?: Options) => ReturnType<Analytics["page"]>;
  screen: (name: string, properties?: object, options?: Options) => ReturnType<Analytics["screen"]>;
  track: (eventName: string, properties?: object, options?: Options) => ReturnType<Analytics["track"]>;
  group: (groupId: string, traits?: object, options?: Options) => ReturnType<Analytics["group"]>;
  identify: (userId?: string, traits?: object, options?: Options) => ReturnType<Analytics["identify"]>;
}

/** Deletes the analytics.js tracking cookies and localStorage */
export function logout(): void {
  window.analytics?.reset();
}

function getScreenContext(): { width: number; height: number; density: number } {
  return {
    // Get the width/height of the viewport, including scrollbars
    width: window.innerWidth,
    height: window.innerHeight,
    density: window.devicePixelRatio
  };
}

/**
 * A wrapper around `window.analytics` that automatically calls Datadog when Segment hasn't been initialised yet.
 * This should only be used in cases where the `useAnalytics()` hook can't be used at all, because this
 * doesn't inject the common proeprties.
 */
export const analytics: CustomAnalytics = {
  page: (name, properties, options) => {
    // Call Datadog directly if Segment hasn't been initialised yet. This is because Segment doesn't
    // call the middleware for events that were queued while the SDK was still initialising.
    // This also allows events to still be sent to Datadog when Segment can't load due to things like ad blockers.
    if (!hasSegmentInitialised) {
      datadogRum.startView({ name });
    }

    return window.analytics?.page(name, properties, {
      ...options,
      context: {
        ...options?.context,
        screen: getScreenContext()
      }
    });
  },

  screen: (name, properties, options) => {
    return window.analytics?.screen(name, properties, {
      ...options,
      context: {
        ...options?.context,
        screen: getScreenContext()
      }
    });
  },

  track: (eventName, properties, options) => {
    if (!hasSegmentInitialised) {
      datadogRum.addAction(eventName, properties);
    }

    return window.analytics?.track(eventName, properties, {
      ...options,
      context: {
        ...options?.context,
        screen: getScreenContext()
      }
    });
  },

  group: (groupId, traits, options) => {
    return window.analytics?.group(groupId, traits, {
      ...options,
      context: {
        ...options?.context,
        screen: getScreenContext()
      }
    });
  },

  identify: (userId, traits, options) => {
    return window.analytics?.identify(userId, traits, {
      ...options,
      context: {
        ...options?.context,
        screen: getScreenContext()
      }
    });
  }
};

/**
 * Returns a wrapper around `window.analytics` that automatically injects common properties.
 */
export function useAnalytics(): CustomAnalytics {
  const companyProfile = useAppSelector(state => state.tenancy);
  // Fallback to trying to get the companyId from the URL. This is used on initial page load because
  // the first page track fires before the companyProfile has loaded.
  const matchedRoute = useRouteMatch<typeof CompanyRoot.params>();
  const companyId = companyProfile.companyId || matchedRoute?.params.tenancyId;

  const defaultProperties = useMemo(() => {
    return {
      companyId: companyId || undefined,
      companyName: companyProfile.tradingName || undefined,
      countryCode: companyProfile.country?.code || undefined,
      isBuyer: companyProfile.isBuyer,
      isSupplier: companyProfile.isSupplier,
      platform: Capacitor.getPlatform()
    };
  }, [
    companyId,
    companyProfile.country?.code,
    companyProfile.isBuyer,
    companyProfile.isSupplier,
    companyProfile.tradingName
  ]);

  return useMemo<CustomAnalytics>(
    () => ({
      page: (name, properties) => {
        return analytics.page(name, {
          ...defaultProperties,
          ...properties
        });
      },
      screen: (name, properties) => {
        return analytics.screen(name, {
          ...defaultProperties,
          ...properties
        });
      },
      track: (eventName, properties) => {
        return analytics.track(eventName, {
          ...defaultProperties,
          ...properties,
          pathname: matchedRoute.path
        });
      },
      group: (groupId, traits) => {
        return analytics.group(groupId, traits);
      },
      identify: (userId, traits) => {
        return analytics.identify(userId, traits);
      }
    }),
    [defaultProperties, matchedRoute.path]
  );
}

/**
 * Identifies the user on page load and automatically re-identifies them if any of their details change.
 *
 * Used in the CompanyRouter.
 */
export function useIdentifyUser(): void {
  const { email, firstName, lastName, userId } = useAppSelector(state => state.userProfile);

  useEffect(() => {
    if (userId) {
      analytics.identify(userId, {
        firstName,
        lastName,
        email
      });
    }
  }, [email, firstName, lastName, userId]);
}

let hasPageViewBeenTracked = false;
export function setHasPageViewBeenTracked(hasBeenTracked: boolean): void {
  hasPageViewBeenTracked = hasBeenTracked;
}

export function getHasPageViewBeenTracked(): boolean {
  return hasPageViewBeenTracked;
}
