import { ApiConnection, ProviderFeatureAccess, ProviderOffering } from "types/api/generated/directory-internal";

export function hasProviderPermission(
  provider: ApiConnection,
  feature: ProviderOffering,
  permission: ProviderFeatureAccess
): boolean {
  const featureOnProvider = provider.features.find(it => it.name === feature);
  if (!featureOnProvider) {
    return false;
  }

  return featureOnProvider.permissions.some(p => p === permission);
}
