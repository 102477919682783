import CloudOffIcon from "@mui/icons-material/CloudOff";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { retry } from "@ultraq/promise-utils";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import EventEmitter from "eventemitter3";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import useMessages from "i18n/hooks/useMessages";
import { hideSnackbar, showSnackbar } from "redux/reducers/snackbar";
import { useAnalytics } from "utils/analytics";
import { logger } from "utils/Datadog";
import { trackError } from "utils/Errors";

import { retryAfterMs } from "./fetch";
import strings from "./offlineHandler.strings.json";

// Wait slightly longer than 3 seconds to give the third ping a chance to finish before showing the overlay
export const OFFLINE_OVERLAY_DELAY = 3300;
const MINIMUM_OFFLINE_OVERLAY_DURATION = 3000;

const emitter = new EventEmitter();
let offlineStartTime: number | null = null;

function checkIfOnline(): Promise<Response | Error> {
  return fetch(`${process.env.REACT_APP_API_URL}/ping`, { method: "GET" })
    .then(response => {
      if (!response.ok) {
        // Return an error so we can check it in the retry callback.
        // We're not throwing it so that we don't cause waitForNetworkConnectivity() to error.
        return new TypeError("Ping failed");
      }

      // Don't do anything if we're already online (in the case where the user manually clicked the try button)
      if (offlineStartTime) {
        logger.info("Successful ping");
        emitter.emit("online", Date.now() - offlineStartTime);
        offlineStartTime = null;
      }

      return true;
    })
    .catch(error => {
      // Don't log network errors
      if (error instanceof TypeError) {
        // Return the error so we can check it in the retry callback.
        // We're not throwing it so that we don't cause waitForNetworkConnectivity() to error.
        return error;
      }

      trackError(error, `Error checking network connectivity: ${error.toString()}`);

      return error;
    });
}

export function isWaitingForNetworkConnectivity(): boolean {
  return Boolean(offlineStartTime);
}

export function waitForNetworkConnectivity(): Promise<void> {
  return new Promise((resolve, reject) => {
    emitter.once("online", resolve);

    if (!offlineStartTime) {
      emitter.emit("offline");
      offlineStartTime = Date.now();
      retry(checkIfOnline, (response, _error, attempts) => {
        // Cancel the retries if the user manually clicked the try button and it succeeded while we
        // were waiting between retries
        if (!offlineStartTime) {
          return false;
        }
        if (response instanceof TypeError) {
          return retryAfterMs(attempts);
        }
        return false;
      }).catch(reject);
    }
  });
}

const useStyles = makeStyles()(theme => ({
  snackbar: {
    display: "flex",
    alignItems: "center"
  },
  snackbarIcon: {
    marginRight: theme.spacing(1)
  },
  dialogRoot: {
    // Display above modals but not snackbars and tooltips
    // Needs !important because the MUI applies the z-index as an inline style
    zIndex: `${theme.zIndex.modal + 1} !important` as any
  },
  dialogPaper: {
    borderRadius: theme.spacing(3)
  },
  overlay: {
    padding: theme.spacing(5, 3),
    textAlign: "center"
  },
  overlayIcon: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: "100%",
    marginBottom: theme.spacing(3),
    background: theme.palette.grey[200],
    color: theme.palette.grey[600],
    fontSize: theme.spacing(4)
  },
  overlayTitle: {
    marginBottom: theme.spacing(1)
  },
  overlayDescription: {
    marginBottom: theme.spacing(4)
  }
}));

export default function OfflineHandler(): JSX.Element {
  const messages = useMessages(strings);
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const [isOffline, setIsOffline] = useState(false);
  const showTimeout = useRef<NodeJS.Timeout | null>(null);
  const hideTimeout = useRef<NodeJS.Timeout | null>(null);
  const hasTriggeredRetry = useRef<boolean>(false);
  const { track } = useAnalytics();

  // Listen to the browser offline/online events and show a snackbar when the user is offline.
  // The offline event is only fired when browsers detect that the device isn't connected to a
  // mobile/wifi/ethernet network, but browsers don't check if the user can actually connect to the internet.
  // So if the online event fires, it doesn't mean the user can actually connect to the internet.
  useEffect(() => {
    const handleOffline = (): void => {
      logger.info("Offline snackbar shown");

      dispatch(
        showSnackbar({
          disableAutoClose: true,
          message: (
            <div className={cx(classes.snackbar)}>
              <CloudOffIcon className={cx(classes.snackbarIcon)} />
              <FormattedMessage id={messages.OFFLINE_SNACKBAR} />
            </div>
          )
        })
      );
    };
    const handleOnline = (): void => {
      dispatch(hideSnackbar());
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [classes.snackbar, classes.snackbarIcon, cx, dispatch, messages]);

  // Listen to our own online/offline events and show the overlay when we detect the user is offline.
  // The offline event is fired when we receive a network error after the normal retries.
  // The online event is fired when we manage to successfully ping the server.
  useEffect(() => {
    const handleOffline = (): void => {
      // Wait a little bit before showing the offline overlay so it's not too aggressive in cases like when the page is unloading
      showTimeout.current = setTimeout(() => {
        showTimeout.current = null;
        logger.info("Offline overlay shown");
        track("OfflineOverlayShown");

        setIsOffline(true);

        // Cancel hiding the offline overlay if the network went back offline in the meantime
        if (hideTimeout.current) {
          clearTimeout(hideTimeout.current);
          hideTimeout.current = null;
        }
      }, OFFLINE_OVERLAY_DELAY);

      // Hide the offline snackbar when the offline overlay is shown since it's redundant
      dispatch(hideSnackbar());
    };
    const handleOnline = (offlineDuration: number): void => {
      // Cancel showing the offline overlay if the network came back online in the meantime
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
        showTimeout.current = null;
      }

      // Show the offline overlay for minimum duration so that it isn't a jarring experience when the connection recovers quickly.
      // But hide it immediately if the user manually triggered a retry.
      if (!hasTriggeredRetry.current && offlineDuration < OFFLINE_OVERLAY_DELAY + MINIMUM_OFFLINE_OVERLAY_DURATION) {
        hideTimeout.current = setTimeout(
          () => {
            hideTimeout.current = null;
            hasTriggeredRetry.current = false;
            setIsOffline(false);
          },
          OFFLINE_OVERLAY_DELAY + MINIMUM_OFFLINE_OVERLAY_DURATION - offlineDuration
        );
      } else {
        hasTriggeredRetry.current = false;
        setIsOffline(false);
      }
    };

    emitter.on("offline", handleOffline);
    emitter.on("online", handleOnline);

    return () => {
      emitter.off("offline", handleOffline);
      emitter.off("online", handleOnline);
    };
  }, [dispatch, track]);

  const handleRetryClick = (): void => {
    hasTriggeredRetry.current = true;
    checkIfOnline();
  };

  return (
    <Dialog
      classes={{ root: cx(classes.dialogRoot), paper: cx(classes.dialogPaper) }}
      aria-labelledby="offline-overlay-title"
      open={isOffline}
      maxWidth="xs"
    >
      <div className={cx(classes.overlay)}>
        <div className={cx(classes.overlayIcon)}>
          <CloudOffIcon fontSize="inherit" />
        </div>
        <Typography id="offline-overlay-title" className={cx(classes.overlayTitle)} variant="h2" component="h1">
          <FormattedMessage id={messages.OVERLAY_TITLE} />
        </Typography>
        <Typography className={cx(classes.overlayDescription)} paragraph>
          <FormattedMessage
            id={messages.OVERLAY_DESCRIPTION}
            values={{
              statusPagelink: {
                href: "https://status.upstock.app"
              }
            }}
          />
        </Typography>

        {/* Allow the user to manaully trigger a ping. This is important since the automatic ping does exponential backoff. */}
        <Button color="primary" variant="contained" onClick={handleRetryClick}>
          <FormattedMessage id={messages.OVERLAY_RETRY_BUTTON} />
        </Button>
      </div>
    </Dialog>
  );
}
