import { Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Modal from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import { showSnackbar } from "redux/reducers/snackbar";
import { acceptTermsConditionsRequest } from "redux/reducers/termsConditions";
import useAppSelector from "utils/hooks/useAppSelector";
import { TERMS_AND_CONDITIONS_LINK } from "utils/MarketingSiteLinks";

import strings from "./index.strings.json";

const useStyles = makeStyles()(theme => ({
  dialogContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3, 1)
  },
  logo: {
    padding: theme.spacing(0, 0, 3),
    width: 50,
    height: 50
  },
  title: {
    textAlign: "center"
  },
  paragraph: {
    textAlign: "center",
    padding: theme.spacing(2, 2, 2)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

/**
 * A modal (full-screen on mobile) that informs the user of a Terms and Conditions
 * update, or that they have not accepted the Terms and Conditions.
 */
const TermsConditionsModal: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const { loading, userSettings, permissions } = useAppSelector(state => state.navigationData);

  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [showTermsConditionsModal, setShowTermsConditionsModal] = useState<boolean>(false);
  const [hasAcceptedPreviousTerms, setHasAcceptedPreviousTerms] = useState<boolean>(false);

  useEffect(() => {
    // Make sure the navigation data has loaded and doesn't just contain initial values
    if (userSettings && permissions.companyId && !loading) {
      setShowTermsConditionsModal(!userSettings.hasAcceptedLatestTermsConditions);
      if (userSettings.acceptedTermsConditionsDateUtc !== null) {
        // if we have no knowledge of a previous acceptance, show the user the "first acceptance" modal
        setHasAcceptedPreviousTerms(true);
      }
    }
  }, [userSettings, permissions.companyId, loading]);

  const handleAgree = (): void => {
    setIsAccepting(true);

    dispatch(
      acceptTermsConditionsRequest({
        successCallback() {
          setShowTermsConditionsModal(false);
          setIsAccepting(false);
        },
        failureCallback() {
          setIsAccepting(false);
          setShowTermsConditionsModal(false);
          dispatch(
            showSnackbar({
              message: <FormattedMessage id={messages.ERROR_SNACK_BAR} />,
              isError: true
            })
          );
        }
      })
    );
  };

  const handleSkip = (): void => {
    setShowTermsConditionsModal(false);
  };

  return (
    <Modal
      fullWidth
      maxWidth="xs"
      open={showTermsConditionsModal}
      contentClassName={cx(classes.dialogContent)}
      data-testid="terms-conditions-modal"
    >
      <div>
        <LogoIcon className={cx(classes.logo)} />
      </div>
      <Typography variant="h2" className={cx(classes.title)}>
        <FormattedMessage id={hasAcceptedPreviousTerms ? messages.TITLE_UPDATED : messages.TITLE} />
      </Typography>

      <Typography variant="body1" className={cx(classes.paragraph)}>
        <FormattedMessage
          id={hasAcceptedPreviousTerms ? messages.TEXT_UPDATED : messages.TEXT}
          values={{
            termsConditionsLink: {
              href: TERMS_AND_CONDITIONS_LINK,
              underline: "always"
            }
          }}
        />
      </Typography>

      <Button
        color="primary"
        variant="contained"
        onClick={handleAgree}
        className={cx(classes.button)}
        showLoader={isAccepting}
      >
        <FormattedMessage id={messages.AGREE_BUTTON} />
      </Button>

      {hasAcceptedPreviousTerms && (
        <Button color="primary" onClick={handleSkip} className={cx(classes.button)}>
          <FormattedMessage id={messages.SKIP_BUTTON} />
        </Button>
      )}
    </Modal>
  );
};

export default TermsConditionsModal;
