import { ListItemButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import { LocationDescriptor } from "history";
import { JSX, ReactNode, useCallback, useMemo } from "react";

import ListItemNavLink, { ListItemNavLinkProps } from "components/ListItemNavLink";
import { useAnalytics } from "utils/analytics";

interface GlobalNavItemProps extends Pick<ListItemNavLinkProps, "disabled" | "isActive"> {
  badgeCount?: number;
  href?: string;
  icon: ReactNode;
  onClick?: (to?: LocationDescriptor) => void;
  text: ReactNode;
  to?: ListItemNavLinkProps["to"];
  trackingPropLink?: string;
  trackingPropNav?: "buyer" | "supplier";
}

/**
 * Component for each item in the global nav.
 */
export default function GlobalNavItem({
  badgeCount,
  href,
  icon,
  onClick,
  text,
  to,
  trackingPropLink,
  trackingPropNav,
  ...rest
}: GlobalNavItemProps): JSX.Element | null {
  const { track } = useAnalytics();

  const trackClick = useCallback(() => {
    onClick?.(to || href);
    if (trackingPropLink) {
      track("Global Nav Link Clicked", {
        link: trackingPropLink,
        nav: trackingPropNav
      });
    }
  }, [href, onClick, to, track, trackingPropLink, trackingPropNav]);

  const outerProps = useMemo(
    () => ({
      onClick: trackClick,
      sx: {
        "&.active": {
          color: "primary.main",
          backgroundColor: "rgba(253, 85, 74, 0.1)"
        }
      },
      ...rest
    }),
    [rest, trackClick]
  );

  const inner = useMemo(
    () => (
      <>
        <Box component="span" sx={{ display: "flex", mr: 2 }}>
          {icon}
        </Box>
        <ListItemText primary={text} />
        {/* -12px: To align correctly with the expand icons, they're different widths. */}
        {!!badgeCount && <Badge color="primary" badgeContent={badgeCount} sx={{ left: -12 }} overlap="rectangular" />}
      </>
    ),
    [badgeCount, icon, text]
  );

  return to ? (
    <ListItemNavLink to={to} {...outerProps}>
      {inner}
    </ListItemNavLink>
  ) : href ? (
    <ListItemButton href={href} {...outerProps}>
      {inner}
    </ListItemButton>
  ) : (
    <ListItemButton {...outerProps}>{inner}</ListItemButton>
  );
}
