import { call, getContext, put, select } from "redux-saga/effects";

// Extension needed because it's a JS file referencing a TS one
// eslint-disable-next-line import/extensions
import { recordError } from "redux/reducers/globalError.ts";

/**
 * An object with a `payload` property on it.  Saves us having to write empty
 * action objects in our tests.
 */
const actionWithEmptyPayload = {
  payload: {}
};

/**
 * Create a generator function that can be used as a saga for calling an API
 * method.
 *
 * @param {Function} apiMethod
 * @param {Generator<*>} [prepareApiMethodParams]
 *   A generator function for being able to add more parameters to the API
 *   method call.  Is passed the action payload object and should return an
 *   array of parameters that are spread after the auth token and tenancyId for
 *   the API method.  Because this is all in the context of a saga, any normal
 *   saga effects may be used in this generator.
 * @return {function(*): Generator}
 */
export function makeApiCall(apiMethod, prepareApiMethodParams = () => []) {
  return function* apiSaga(action = actionWithEmptyPayload) {
    const auth = yield getContext("auth0");
    const tenancyId = yield select(state => state.tenancy.companyId);
    return yield call(
      apiMethod,
      auth,
      tenancyId,
      ...(yield prepareApiMethodParams(action.payload || {}))
    );
  };
}

/**
 * Wrap a saga to take the result of it and call a failure action (usually some
 * redux action to place the error message into redux) and callback (if present
 * in the action's payload).
 *
 * Difference between `withErrorFailureHandler` and `withFailureHandler`:
 * For some time now we've been passing only the error message back to the failure handler but not the whole object.
 * This is fine unless we want to display validation errors back from the server which come back as a `errors` array.
 * Instead of converting all our saga's at once we should test one by one when we do so. Eventually, `withErrorFailureHandler`
 * will replace `withFailureHandler`.
 *
 * @param {Function} saga
 * @param {Function} failureAction
 * @param {Boolean} [rethrowError=false]
 *  By default, the saga returned by this function will swallow any errors.
 *   Pass `true` here so that the error is rethrown.
 * @param {Function} [prepareFailureActionParams]
 * @return {function(*): Generator}
 */
export function withFailureHandler(
  saga,
  failureAction,
  rethrowError = false,
  prepareFailureActionParams = (action, response) => [response]
) {
  return function* failureHandlerSaga(action = actionWithEmptyPayload) {
    try {
      return yield saga(action);
    } catch (error) {
      const errorMessage =
        error.message ||
        // We throw the response body as a plain object rather than an Error object for HTTP 400 responses
        error.errors?.[0]?.reason || // Try to get the validation error message from HTTP 400 responses
        error.title;

      const failureActionParams = prepareFailureActionParams(
        action,
        errorMessage
      );
      yield put(failureAction(...failureActionParams));
      yield put(
        recordError(error instanceof Error ? error : new Error(errorMessage))
      );
      const failureCallback = action.payload?.failureCallback;
      if (failureCallback) {
        yield call(failureCallback, errorMessage);
      }
      if (rethrowError) {
        throw error;
      }
    }
  };
}

/**
 * Wrap a saga to take the result of it and call a failure action (usually some
 * redux action to place the error object into redux) and callback (if present
 * in the action's payload).
 *
 * Difference between `withErrorFailureHandler` and `withFailureHandler`:
 * For some time now we've been passing only the error message back to the failure handler but not the whole object.
 * This is fine unless we want to display validation errors back from the server which come back as a `errors` array.
 * Instead of converting all our saga's at once we should test one by one when we do so. Eventually, `withErrorFailureHandler`
 * will replace `withFailureHandler`.
 *
 * @param {Function} saga
 * @param {Function} failureAction
 * @param {Boolean} [rethrowError=false]
 *   By default, the saga returned by this function will swallow any errors.
 *   Pass `true` here so that the error is rethrown.
 * @param {Function} [prepareFailureActionParams]
 * @return {function(*): Generator}
 */
export function withErrorFailureHandler(
  saga,
  failureAction,
  rethrowError = false,
  prepareFailureActionParams = (action, response) => [response]
) {
  return function* failureHandlerSaga(action = actionWithEmptyPayload) {
    try {
      return yield saga(action);
    } catch (rawError) {
      const error = "headers" in rawError ? rawError.data : rawError;
      const failureActionParams = prepareFailureActionParams(action, error);

      yield put(failureAction(...failureActionParams));
      const failureCallback = action.payload?.failureCallback;
      if (failureCallback) {
        yield call(failureCallback, ...failureActionParams);
      } else {
        yield put(recordError(error));
      }
      if (rethrowError) {
        throw error;
      }
    }
  };
}

/**
 * Wrap a saga to take the result of it and call a success action (usually some
 * redux action to place the result into redux) and callback (if present in the
 * action's payload).  The result is also returned.
 *
 * @param {Function} saga
 * @param {Function} successAction
 * @param {Function} [prepareSuccessActionParams]
 *   Given the action and response objects, return an array of parameters that
 *   is spread into the success action.  These will also be spread over the
 *   success callback.  The default handler will return an array containing only
 *   the response object.
 * @return {function(*): Generator}
 */
export function withSuccessHandler(
  saga,
  successAction,
  prepareSuccessActionParams = (action, response) => [response]
) {
  return function* successHandlerSaga(action = actionWithEmptyPayload) {
    const response = yield saga(action);
    const successActionParams = prepareSuccessActionParams(action, response);
    yield put(successAction(...successActionParams));
    const successCallback = action.payload?.successCallback;
    if (successCallback) {
      yield call(successCallback, ...successActionParams);
    }
    return response;
  };
}

/**
 * @typedef { import("@reduxjs/toolkit").PayloadAction } PayloadAction
 * @typedef { import("@auth0/auth0-react").Auth0ContextInterface } Auth0ContextInterface
 */

/**
 * Compose a saga with a single method call.  The only required parameter is an
 * API method call, everything else is in the optional `options` object where
 * the presence/absence of certain keys will wrap the appropriate success and
 * failure handlers around the returned saga.
 *
 * @param {(auth: Auth0ContextInterface, tenancyId: string, ...params: API_PARAMS) => Promise<API_RESPONSE>} apiMethod
 * @param {object} [options] The keys of this object match to the names of the method parameters in
 * all the other saga composition functions in this file.
 * @param {(payload: REQUEST_PAYLOAD) => [...API_PARAMS] | Generator} [options.prepareApiMethodParams] if present, will be passed along to {@link #makeApiCall}
 * when constructing the saga.
 * @param {(payload: SUCCESS_PAYLOAD) => PayloadAction<SUCCESS_PAYLOAD>} [options.successAction] if present, will wrap the saga using {@link #withSuccessHandler}
 * with the value of `prepareSuccessActionParams` from the options object.
 * @param {(requestAction: PayloadAction<REQUEST_PAYLOAD>, response: API_RESPONSE) => [SUCCESS_PAYLOAD]} [options.prepareSuccessActionParams]
 * @param {(requestAction: PayloadAction<REQUEST_PAYLOAD>, response: any) => [FAILURE_PAYLOAD]} [options.prepareFailureActionParams]
 * @param {(payload: FAILURE_PAYLOAD) => PayloadAction<FAILURE_PAYLOAD>} [options.failureAction] if present, will wrap the saga using {@link #withFailureHandler}
 * with the value of the `rethrowError` from the options object.
 * @param {(payload: FAILURE_PAYLOAD) => PayloadAction<FAILURE_PAYLOAD>} [options.errorFailureAction] if present, will wrap the saga using {@link #withErrorFailureHandler}
 * with the value of the `rethrowError` from the options object.
 * @param {boolean} [options.rethrowError]
 * @return {(action?: PayloadAction<REQUEST_PAYLOAD>) => Generator}
 * @template {unknown[]} API_PARAMS
 * @template {unknown} API_RESPONSE
 * @template {unknown} REQUEST_PAYLOAD
 * @template {unknown} SUCCESS_PAYLOAD
 * @template {unknown} FAILURE_PAYLOAD
 */
export function createSaga(
  apiMethod,
  {
    prepareApiMethodParams,
    successAction,
    prepareSuccessActionParams,
    prepareFailureActionParams,
    failureAction,
    errorFailureAction,
    rethrowError
  } = {}
) {
  let saga = makeApiCall(apiMethod, prepareApiMethodParams);
  if (successAction) {
    saga = withSuccessHandler(saga, successAction, prepareSuccessActionParams);
  }
  if (failureAction) {
    saga = withFailureHandler(
      saga,
      failureAction,
      rethrowError,
      prepareFailureActionParams
    );
  }
  if (errorFailureAction) {
    saga = withErrorFailureHandler(
      saga,
      errorFailureAction,
      rethrowError,
      prepareFailureActionParams
    );
  }
  return saga;
}
