import { SxProps } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import useMessages from "i18n/hooks/useMessages";
import { ProductUnavailableReason } from "types/api/generated/supplier";
import Theme from "types/Theme";

import strings from "./Chips.strings.json";
import ColouredChip from "./ColouredChip";

interface UnavailableChipProps {
  unavailableReason?: ProductUnavailableReason | string | null;
  sx?: SxProps<Theme>;
  className?: string;
}

/**
 * Chip displays out of stock message in a slightly lighter grey text colour to the coloured chip
 */
const UnavailableChip: FunctionComponent<UnavailableChipProps> = ({ unavailableReason, sx, className }) => {
  const messages = useMessages(strings);
  return (
    <ColouredChip
      data-testid="unavailable-chip"
      color="grey"
      className={className}
      sx={{
        color: "grey[600]",
        ...sx
      }}
      label={
        unavailableReason === ProductUnavailableReason.OutOfStock ? (
          <FormattedMessage id={messages.OUTOFSTOCK} />
        ) : unavailableReason === ProductUnavailableReason.Archived ? (
          <FormattedMessage id={messages.DISCONTINUED} />
        ) : unavailableReason === ProductUnavailableReason.Hidden ? (
          <FormattedMessage id={messages.PRODUCTHIDDEN} />
        ) : (
          <FormattedMessage id={messages.UNAVAILABLE} />
        )
      }
    />
  );
};

export default UnavailableChip;
