import useAppSelector from "utils/hooks/useAppSelector";
import useTypedUrlParams from "utils/hooks/useTypedUrlParams";
import "dayjs/locale/en";
import "dayjs/locale/en-nz";
import "dayjs/locale/en-au";

const defaultLocale = "en-NZ";

export const dayjsLocales = ["en", "en-nz", "en-au"];

export default function useLocale(): string {
  const [localeFromQueryString] = useTypedUrlParams("locale");
  const companyCountryCode = useAppSelector(state => state.tenancy.country?.code);

  return localeFromQueryString || (companyCountryCode ? `en-${companyCountryCode}` : defaultLocale);
}
