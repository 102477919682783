import { useMediaQuery } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/styles";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { useCallback } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import IntercomHelpArticle from "components/IntercomHelpArticle";
import Modal from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import HelpIcon from "media/help.svg?react";
import { showNewMessage } from "utils/Intercom";

import strings from "./HelpCentreModal.strings.json";
import CsTeamImage from "./HelpCentreModalCsTeam.png";

const useStyles = makeStyles()(theme => ({
  dialogContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  list: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
    width: "100%"
  },
  listItem: {
    padding: 0
  },
  link: {
    color: theme.palette.primary.main,
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    width: "100%"
  },
  csTeamImage: {
    display: "block",
    height: "48px",
    margin: theme.spacing(5, "auto", 2),
    width: "209px"
  },
  chatButton: {
    marginBottom: theme.spacing(2)
  }
}));

interface HelpCentreModalProps {
  onClose?: () => void;
  open: boolean;
  /**
   * Set whether to show buyer or supplier articles in the help modal.
   */
  showSupplierArticles?: boolean;
}

/**
 * A modal (full-screen on mobile) shown to hold some Help Centre items before
 * directing customers to our full help centre.
 */
export default function HelpCentreModal({
  onClose = () => {},
  open,
  showSupplierArticles = false
}: HelpCentreModalProps): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("md"));
  const { formatter } = useMessageFormatter();

  const handleChat = useCallback(() => {
    showNewMessage();
    onClose();
  }, [onClose]);

  return (
    <Modal
      fullWidth
      fullScreen={isXsDown}
      maxWidth="xs"
      open={open}
      title={<FormattedMessage id={messages.TITLE} />}
      onClose={onClose}
      contentClassName={cx(classes.dialogContent)}
    >
      <List disablePadding className={cx(classes.list)}>
        {showSupplierArticles ? (
          <>
            <ListItem button component="li" divider role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={4677875} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_SUPPLIER_PRICELISTS} />
              </IntercomHelpArticle>
            </ListItem>
            <ListItem button component="li" divider role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={5605310} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_SUPPLIER_INTEGRATIONS} />
              </IntercomHelpArticle>
            </ListItem>
            <ListItem button component="li" role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={5351627} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_SUPPLIER_USERMANAGEMENT} />
              </IntercomHelpArticle>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button component="li" divider role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={4303549} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_BUYER_ADDSUPPLIER} />
              </IntercomHelpArticle>
            </ListItem>
            <ListItem button component="li" divider role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={5077866} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_BUYER_FAVOURITES} />
              </IntercomHelpArticle>
            </ListItem>
            <ListItem button component="li" divider role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={5607849} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_BUYER_PLACEORDER} />
              </IntercomHelpArticle>
            </ListItem>
            <ListItem button component="li" role="listitem" className={cx(classes.listItem)}>
              <IntercomHelpArticle articleId={5252567} underline="none" className={cx(classes.link)} onClick={onClose}>
                <FormattedMessage id={messages.HELP_BUYER_USERMANAGEMENT} />
              </IntercomHelpArticle>
            </ListItem>
          </>
        )}
      </List>
      <img
        src={CsTeamImage}
        width="418"
        alt={formatter.format(messages.CSTEAM_ALTTEXT)}
        className={cx(classes.csTeamImage)}
      />
      <Button
        color="primary"
        variant="contained"
        startIcon={<HelpIcon />}
        onClick={handleChat}
        className={cx(classes.chatButton)}
      >
        <FormattedMessage id={messages.ACTION_CHAT} />
      </Button>
    </Modal>
  );
}
