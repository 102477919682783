import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Action } from "@reduxjs/toolkit";

import { loadCompanyProfileFailure, loadCompanyProfileSuccess } from "redux/reducers/tenancy";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Directory } from "types/api/generated/directory";
import { logger } from "utils/Datadog";

/**
 * Calls the API to fetch details on the company by it's ID
 */
function fetchCompanyProfile(
  auth: Auth0ContextInterface,
  tenancyId: string
): Promise<Directory.V1CompaniesProfileDetail.ResponseBody> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/directory/v1/companies/${tenancyId}/profile`,
    tenancyId
  );
}

/**
 * Calls `/directory/v1/companies/{id}/profile` to fetch the company profile by
 * it's ID.
 */
export default createSaga(fetchCompanyProfile, {
  successAction: loadCompanyProfileSuccess,
  failureAction: loadCompanyProfileFailure,
  prepareFailureActionParams(action: Action<string>, response: string) {
    logger.info(
      `Failed to fetch company profile - this will set NoneFound as the companyId.  Triggered by ${action.type}`
    );
    return [response];
  }
});
