import { Auth0ContextInterface } from "@auth0/auth0-react";

import { fetchCustomersFailure, FetchCustomersRequest, fetchCustomersSuccess } from "redux/reducers/supplier/customers";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { BuyerSummaryPagedSet } from "types/api/generated/supplier";
import { buildSearchString } from "utils/urls";

/**
 * Calls the API to fetch all the customers connected to a supplier.
 */
function fetchCustomers(
  auth: Auth0ContextInterface,
  tenancyId: string,
  name?: string,
  sortBy?: string,
  limit?: number,
  hasStandingOrders?: boolean
): Promise<BuyerSummaryPagedSet> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/buyers${buildSearchString({
      name,
      sortBy,
      limit,
      hasStandingOrders
    })}`,
    tenancyId
  );
}

/**
 * Saga for calling the API to retrieve customers of a supplier.
 */
export default createSaga(fetchCustomers, {
  prepareApiMethodParams: ({ name, limit, sortBy, hasStandingOrders }: FetchCustomersRequest["payload"]) => [
    name,
    sortBy,
    limit,
    hasStandingOrders
  ],
  prepareSuccessActionParams: (action, response) => [{ ...action, ...response, overrideList: true }],
  successAction: fetchCustomersSuccess,
  failureAction: fetchCustomersFailure
});
