import { Auth0ContextInterface } from "@auth0/auth0-react";
import { select } from "redux-saga/effects";

import {
  loadProductsWithNoPricesFailure,
  loadProductsWithNoPricesSuccess,
  LoadProductsWithNoPricesRequest
} from "redux/reducers/supplier/products";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

/**
 * Calls the API `/supplier/v1/pricelists/products/review` to fetch the products
 * without base prices to the supplier.
 */
export const fetchProductsWithNoPrice = (
  auth: Auth0ContextInterface,
  tenancyId: string,
  archived: boolean | undefined,
  limit: number
): Promise<Supplier.V1PricelistsProductsReviewList.ResponseBody> => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set("limit", limit.toString());
  archived && urlSearchParams.set("archived", archived.toString());

  const url = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/products/review?${urlSearchParams}`;

  return fetchDataFromApiWrapper(auth, url, tenancyId);
};

/**
 * Saga task for fetching products with no base prices and placing it in the {@link products} state slice.
 *
 * @param {Object} action
 * @return {Generator<*>}
 */

export default createSaga(fetchProductsWithNoPrice, {
  *prepareApiMethodParams({ isArchived, limit: payloadLimit }: LoadProductsWithNoPricesRequest["payload"]) {
    const { limit: defaultLimit } = yield select(state => state.supplier.products.noPrices);

    const limit = payloadLimit || defaultLimit;

    return [isArchived, limit];
  },
  successAction: loadProductsWithNoPricesSuccess,
  errorFailureAction: loadProductsWithNoPricesFailure
});
