import { FormHelperText, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Formik, FormikConfig } from "formik";
import { useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { object, string } from "yup";

import Button from "components/Button";
import CompanyLogo from "components/CompanyLogo/CompanyLogo";
import Modal, { ModalProps } from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import { ConnectAction, SupplierDetails, SupplierSummary } from "types/api/generated/buyer";

import strings from "./ConnectionModal.strings.json";

const useStyles = makeStyles()(theme => ({
  companyLogo: {
    width: 64,
    height: 64,
    margin: theme.spacing(0, "auto", 1)
  },
  title: {
    textAlign: "center"
  },
  body: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.grey[600],
    textAlign: "center"
  },
  radioGroup: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "borderRadius"
  },
  formControlLabel: {
    padding: theme.spacing(1),
    margin: 0,
    flex: 1,
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
  },
  connection: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 2,
    padding: theme.spacing(3),
    margin: theme.spacing(3, 0, 1),
    textAlign: "center"
  },
  actions: {
    justifyContent: "center"
  }
}));

export const CURRENTLY_TRADING = "currently-trading";
export const NEWLY_TRADING = "newly-trading";

export interface ConnectionFormValues {
  tradingRelationship: string | null;
}

export type ConnectionHandler = (values: ConnectionFormValues) => Promise<void>;

type ConnectionModalOptions = ModalProps & {
  supplier: SupplierSummary | SupplierDetails;
  onConnect?: ConnectionHandler;
};

export default function ConnectionModal({ supplier, onConnect, open, onClose }: ConnectionModalOptions): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { formatter } = useMessageFormatter();
  const [isSubmitting, setSubmitting] = useState(false);

  const validationSchema = object({
    tradingRelationship: string().required(formatter.format(messages.PLEASE_SELECT_AN_OPTION))
  });

  const handleConnectionModal: FormikConfig<ConnectionFormValues>["onSubmit"] = useCallback(
    async values => {
      setSubmitting(true);
      try {
        onClose?.();
        await onConnect?.(values);
      } finally {
        setSubmitting(false);
      }
    },
    [onClose, onConnect]
  );

  const filteredDeliveryAreas = supplier.deliveryAreas?.locations.filter(
    it => !["All of New Zealand", "All of Australia"].includes(it)
  );

  const initialValues: ConnectionFormValues = {
    tradingRelationship: null
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="xs"
      title={
        <div className={cx(classes.title)}>
          <CompanyLogo
            tradingName={supplier.tradingName}
            logoUrl={supplier.logoUrl}
            className={cx(classes.companyLogo)}
          />
          <Typography variant="h2">
            <FormattedMessage id={messages.TITLE} values={{ supplierName: supplier.tradingName }} />
          </Typography>
        </div>
      }
      actionsClassName={classes.actions}
      actions={
        <Button color="primary" variant="contained" type="submit" form="connection-form" showLoader={isSubmitting}>
          {supplier.connectAction === ConnectAction.InviteRequest ? (
            <FormattedMessage id={messages.CONNECT_INVITE_REQUEST_CONNECTION} />
          ) : supplier.connectAction === ConnectAction.Automatic ? (
            <FormattedMessage id={messages.CONNECT_AUTOMATIC} />
          ) : null}
        </Button>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleConnectionModal}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleChange, values, handleSubmit, errors }) => (
          <>
            {filteredDeliveryAreas && filteredDeliveryAreas.length > 0 && (
              <Typography variant="body1" className={cx(classes.body)}>
                <FormattedMessage id={messages.DELIVERY_AREAS} values={{ locations: filteredDeliveryAreas }} />
              </Typography>
            )}
            <form onSubmit={handleSubmit} id="connection-form" data-testid="connectionmodal-form">
              <RadioGroup
                aria-label="default tax settings"
                name="tradingRelationship"
                value={values.tradingRelationship}
                onChange={handleChange}
                className={cx(classes.radioGroup)}
              >
                <FormControlLabel
                  className={classes.formControlLabel}
                  value={CURRENTLY_TRADING}
                  control={<Radio color="primary" data-testid="connectionmodal-yes-option" />}
                  label={<FormattedMessage id={messages.EXISTING_OPTION} />}
                />
                <FormControlLabel
                  className={classes.formControlLabel}
                  value={NEWLY_TRADING}
                  control={<Radio color="primary" data-testid="connectionmodal-no-option" />}
                  label={<FormattedMessage id={messages.NEW_OPTION} />}
                />
              </RadioGroup>
              <FormHelperText error={!!errors}>{Object.values(errors).join(", ")}</FormHelperText>
            </form>

            <div className={cx(classes.connection)}>
              {supplier.connectAction === ConnectAction.InviteRequest ? (
                <FormattedMessage id={messages.TIP_INVITE_REQUEST_CONNECTION} />
              ) : supplier.connectAction === ConnectAction.Automatic ? (
                <FormattedMessage id={messages.TIP_AUTOMATIC_CONNECTION} />
              ) : null}
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
}
