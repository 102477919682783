import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { postDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import { createPriceListFailure, CreatePriceListRequest, createPriceListSuccess } from "../reducers/priceList";

/**
 * Calls the API to create a new price list.
 */
function createPriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  name: string
): Promise<Supplier.V1PricelistsCreate.ResponseBody> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists`;
  return postDataToApiWrapper(auth, urlWithDomain, tenancyId, {
    name
  });
}

/**
 * Saga for creating a new price list with the given name.
 */
export default createSaga(createPriceList, {
  prepareApiMethodParams: ({ name }: CreatePriceListRequest["payload"]) => [name],
  successAction: createPriceListSuccess,
  errorFailureAction: createPriceListFailure,
  rethrowError: false
});
