import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import * as LiveUpdates from "@capacitor/live-updates";
import { JSX, ReactNode, useRef } from "react";
import { useLocation } from "react-router-dom";

import { isPathReloadable } from "routes/unreloadableRoutes";
import { fetchWithRetry } from "services/fetch";
import dayjs from "utils/dayjs";
import { HTTP_STATUS_NETWORK_FAILURE } from "utils/HttpStatuses";

import { COMMIT_HASH } from "../config";

import { logger } from "./Datadog";
import { trackError } from "./Errors";

export const CHECK_INTERVAL = dayjs.duration({ minutes: 5 }).asMilliseconds(); // Milliseconds in 5 minutes
export const STALE_LIMIT = dayjs.duration({ days: 7 }).asMilliseconds(); // Milliseconds in 7 days

let staleTime: number | null = null;
let interval: number | null = null;

// Use functions for these variables so they can be mocked in the tests after the file has been imported

// Use APP_ENV here because a production build of the web app is used when working on the native app in development
const isDevelopment = (): boolean => process.env.REACT_APP_ENV === "dev";

const areLiveUpdatesAvailable = (): boolean =>
  !isDevelopment() &&
  Capacitor.isNativePlatform() &&
  Capacitor.isPluginAvailable("App") &&
  Capacitor.isPluginAvailable("LiveUpdates");

async function checkForWebUpdate(): Promise<void> {
  if (staleTime) {
    if (Date.now() - staleTime >= STALE_LIMIT && isPathReloadable(window.location.pathname)) {
      logger.info("Reloading the page because the web app has been out of date for too long");
      window.location.reload();
    }

    return;
  }

  // metadata.json is only generated as part of the production build so don't try to fetch it in development
  if (isDevelopment()) {
    return;
  }

  try {
    const response = await fetchWithRetry(`${process.env.REACT_APP_APPLICATION_DOMAIN}/metadata.json`);
    if (!response.ok) {
      throw new Error("Failed to load metadata.json");
    }

    let metadata = null;
    try {
      metadata = await response.json();
    } catch (error) {
      throw new Error("Failed to parse metadata.json");
    }

    const latestCommitHash = metadata.commitHash;
    if (!latestCommitHash) {
      throw new Error("Failed to read metadata commit hash");
    }

    if (latestCommitHash !== COMMIT_HASH) {
      logger.info("Web app is out of date");
      staleTime = Date.now();
    }
  } catch (error: any) {
    // Don't log network errors
    if (error.code === HTTP_STATUS_NETWORK_FAILURE) {
      return;
    }

    trackError(error, `Web update checker error: ${error.message}`);
  }
}

function isErrorIgnored(error: any): boolean {
  if (typeof error?.message !== "string") {
    return false;
  }

  const message = error.message.toLowerCase();

  // LiveUpdates.sync() will throw an error if a sync is already in progress
  return (
    message.includes("already in progress") ||
    // Also ignore timeouts
    message.includes("timeout") ||
    // And DNS errors because they're likely caused by bad network connectivity
    message.includes("unable to resolve host") ||
    // Failed file downloads due to connection failing
    message.includes("manifest path broken or file malformed") ||
    message.includes("file(s) were unable to be downloaded") ||
    message.includes("failed to connect") ||
    // Shows up if part of the handshake fails, maybe
    message.includes("trust anchor for certification not found")
  );
}

async function checkForNativeUpdate(): Promise<void> {
  if (!areLiveUpdatesAvailable()) {
    return;
  }

  try {
    const result = await LiveUpdates.sync();
    if (result.activeApplicationPathChanged) {
      logger.info("Native app is out of date");
      staleTime = Date.now();
    }
  } catch (error: any) {
    if (!isErrorIgnored(error)) {
      trackError(error, `Native update checker error: ${error.message}`);
    }
  }
}

export function startCheckingForUpdates(): void {
  if (Capacitor.isNativePlatform()) {
    if (areLiveUpdatesAvailable()) {
      // Check for live updates whenever the app is brought back to the foreground, but wait for a page navigation before installing it
      App.addListener("resume", checkForNativeUpdate);
      // Also check on initial launch becuase `resume` is only fired when reopening the app from the background.
      checkForNativeUpdate();
    }
  } else {
    interval = window.setInterval(checkForWebUpdate, CHECK_INTERVAL);
  }
}

export function isOutOfDate(): boolean {
  return Boolean(staleTime);
}

export function stopCheckingForUpdates(): void {
  if (interval) {
    window.clearInterval(interval);
  }
  staleTime = null;
  interval = null;
}

interface UpdateOnNavigationProps {
  children: ReactNode;
}

/**
 * Wrapper component that triggers seemless reloads during route navigations to automatically keep the web app up to date.
 */
export function UpdateOnNavigation({ children }: UpdateOnNavigationProps): JSX.Element | null {
  const location = useLocation();
  const previousPathnameRef = useRef(location.pathname);
  const isReloadingRef = useRef(false);

  // Prevent the page from rendering to avoid the page briefly showing before reloading
  if (isReloadingRef.current) {
    return null;
  }

  // Only reload the page when the pathname changes, which likely indicates a route change
  if (previousPathnameRef.current !== location.pathname) {
    previousPathnameRef.current = location.pathname;

    if (isOutOfDate() && isPathReloadable(location.pathname)) {
      logger.info("Reloading the page on navigation to update the web app", {
        pathname: location.pathname
      });

      if (Capacitor.isNativePlatform()) {
        if (areLiveUpdatesAvailable()) {
          LiveUpdates.reload().catch(error => {
            trackError(error, `Live update reload error: ${error.message}`);
          });
        }
      } else {
        const newUrl = new URL(window.location.href);
        newUrl.pathname = location.pathname;
        newUrl.search = location.search;
        newUrl.hash = location.hash;
        // Assign window.location instead of using window.location.reload() to avoid any potential race conditions
        window.location.href = newUrl.toString();
      }

      // Use a ref instead of state because we don't want it to render before the state updates
      isReloadingRef.current = true;

      return null;
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Workaround for React types bug where `children` isn't assignable to the Component return type
  return <>{children}</>;
}
