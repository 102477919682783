import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { quickAddProductsKey } from "./useQuickAddProducts";

type RequestBody = Supplier.V1ProductsQuickAddDelete.RequestBody;
type RequestQuery = Supplier.V1ProductsQuickAddDelete.RequestQuery;
type ResponseBody = "";

export const removeQuickAddProductParams = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody>(() => {
  return {
    method: "DELETE",
    path: safeurl`/supplier/v1/products/quick-add`
  };
});

export function useRemoveQuickAddProduct(): UseMutationResult<ResponseBody, unknown, RequestBody> {
  const apiFetch = useApiFetch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async variables => {
      const response = await apiFetch(removeQuickAddProductParams(undefined, { data: variables }));
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(quickAddProductsKey.list._def);
    }
  });
}
