import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { VoidFunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { useShallowCompareEffect } from "react-use";

import LoadingScreen from "pages/LoadingScreen";
import { isExternalRoute, MobileWelcome } from "routes/Routes";
import TrackedRoute, { TrackedRouteProps } from "routes/TrackedRoute";
import { ANDROID } from "utils/MobilePlatforms";
import { PARAM_FORWARD_PATH } from "utils/MobileUrlSearchParams";

export interface AuthenticatedRouteProps extends TrackedRouteProps {
  loginOptions?: RedirectLoginOptions;
}

/**
 * Wraps components with authentication checks to make sure only authenticated
 * users can view these pages.
 *
 * It's recommended to use this component with routes that require authorisation
 * but not tied to a company. i.e anything listed in teh `/a/` routes namespace
 * without a `:tenancyId`
 *
 * The auth0 examples make use of a HOC, `withAuthenticationRequired`, but you
 * can only load the `loginOptions` of that part statically, not through props.
 * So this component replicates what `withAuthenticationRequired` does, but with
 * being able to pass `loginOptions` as a prop.
 */
const AuthenticatedRoute: VoidFunctionComponent<AuthenticatedRouteProps> = ({ loginOptions = {}, ...routeProps }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading: authLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  useShallowCompareEffect(() => {
    if (!isAuthenticated && !authLoading) {
      if (Capacitor.isNativePlatform()) {
        getAccessTokenSilently({
          authorizationParams: {
            redirect_uri: Capacitor.getPlatform() === ANDROID ? "https://localhost/auth0-callback" : undefined
          }
        }).catch(() => {
          // If restoring the token failed, the user must sign in again. Redirect them. Web handles this itself.
          const { pathname } = window.location;

          window.location.href = MobileWelcome.toUrl(undefined, {
            [PARAM_FORWARD_PATH]: isExternalRoute(pathname) ? window.location.pathname : undefined
          });
        });
      } else {
        loginWithRedirect({
          ...loginOptions,
          appState: {
            ...loginOptions.appState,
            returnTo: location.pathname + location.search + location.hash
          }
        });
      }
    }
  }, [isAuthenticated, authLoading, loginWithRedirect, loginOptions, location]);

  return isAuthenticated ? <TrackedRoute {...routeProps} /> : <LoadingScreen />;
};

export default AuthenticatedRoute;
