import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Chip, { ChipProps } from "./index";

type ColorMapItem = {
  color: string;
  backgroundColor: string;
  border: string;
};

const colorMap = {
  red: {
    color: "#CA443B",
    backgroundColor: "#FFEEED",
    border: "1px solid #F2D4D2"
  },
  pink: {
    color: "#ffffff",
    backgroundColor: "#E72565",
    border: "1px solid #d1215c"
  },
  green: {
    color: "#0D5F30",
    backgroundColor: "#E8F9EF",
    border: "1px solid #C8DDD1"
  },
  darkGreen: {
    color: "#ffffff",
    backgroundColor: "#159688",
    border: "1px solid #128a7c"
  },
  blue: {
    color: "#120095",
    backgroundColor: "#ECEBF7",
    border: "1px solid #D2CEEB"
  },
  grey: {
    color: "#616161",
    backgroundColor: "#FAFAFA",
    border: "1px solid #E0E0E0"
  },
  yellow: {
    color: "#212121",
    backgroundColor: "#FFF5DD",
    border: "1px solid #EFE1BD"
  },
  orange: {
    color: "#ffffff",
    backgroundColor: "#FD9F28",
    border: "1px solid #e08e22"
  }
} satisfies Record<string, ColorMapItem>;

export interface ColouredChipProps extends Omit<ChipProps, "color"> {
  color: keyof typeof colorMap;
}

const useStyles = makeStyles<{ chipColor: keyof typeof colorMap }>()((_theme, { chipColor }) => ({
  chip: {
    color: colorMap[chipColor].color,
    backgroundColor: colorMap[chipColor].backgroundColor,
    border: colorMap[chipColor].border
  }
}));

const ColouredChip: FunctionComponent<ColouredChipProps> = ({ sx, label, color, className, ...other }) => {
  const { classes, cx } = useStyles({ chipColor: color });
  return (
    <Chip
      className={cx(classes.chip, className)}
      sx={Array.isArray(sx) ? sx : [sx]}
      label={label}
      size="small"
      {...other}
    />
  );
};

export default ColouredChip;
