import { useCallback, useState } from "react";

/**
 * React hook for taking out some of the boilerplate associated with managing a
 * modal component on a page.
 *
 * @param initialOpenState
 *   The initial open state of the modal.  Defaults to `false`.
 * @return
 *   An array of the following items:
 *    - the open state of the modal, starts off as whatever you passed as the
 *      value for `initialOpenState`
 *    - a function for setting the open state of the modal to "open"/`true`
 *    - a function for setting the open state of the modal to "close"/`false`
 */
export default function useModal(
  initialOpenState = false
): [isModalOpen: boolean, openModal: () => void, closeModal: () => void] {
  const [isModalOpen, setModalOpen] = useState(initialOpenState);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return [isModalOpen, openModal, closeModal];
}
