import { ProviderFeatureAccess, ProviderOffering } from "types/api/generated/directory-internal";
import { hasProviderPermission } from "utils/Providers";

import useProviderConnection from "./useProviderConnection";

/**
 * React hook for determining whether the default provider for the given
 * `feature` has the necessary permission.
 */
export default function useHasProviderPermissions(
  feature: ProviderOffering,
  permission: ProviderFeatureAccess
): boolean {
  const [provider] = useProviderConnection(null, feature, true);

  if (!provider) {
    return false;
  }

  return hasProviderPermission(provider, feature, permission);
}
