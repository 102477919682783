import { all, takeLatest } from "redux-saga/effects";

import acceptTermsConditions from "redux/tasks/acceptTermsConditions";

import { acceptTermsConditionsRequest } from "../reducers/termsConditions";

/**
 * Saga for accepting Terms and Conditions over the
 * `/directory/internal/users/accept-terms-conditions` endpoint.
 */
export default function* termsConditions(): Generator {
  yield all([takeLatest(acceptTermsConditionsRequest, acceptTermsConditions)]);
}
