import { createSlice } from "@reduxjs/toolkit";

import { Buyer } from "types/api/generated/buyer";
import { RequestAction } from "types/redux-helpers";

interface PendingSupplierState {
  error?: Error;
  loading: boolean;
}

export const initialState: PendingSupplierState = {
  loading: false
};

export type RemoveConnectionByIdRequest = RequestAction<
  Buyer.V1SuppliersPendingLeadsDelete.RequestParams,
  Buyer.V1SuppliersPendingLeadsDelete.ResponseBody
>;

const pendingSupplierSlice = createSlice({
  name: "pendingSupplierById",
  initialState,
  reducers: {
    removeConnectionByIdRequest: (_state, _action: RemoveConnectionByIdRequest) => ({
      ...initialState,
      loading: true
    }),
    removeConnectionByIdSuccess: state => ({
      ...state,
      loading: false
    }),
    removeConnectionByIdFailure: (state, { payload: error }) => ({
      ...state,
      loading: false,
      error
    })
  }
});

export const { removeConnectionByIdRequest, removeConnectionByIdSuccess, removeConnectionByIdFailure } =
  pendingSupplierSlice.actions;
export default pendingSupplierSlice.reducer;
