import { createQueryKeys } from "@lukemorales/query-key-factory";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useUnauthenticatedApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api/generated/supplier-internal";
import { safeurl } from "utils/urls";

type RequestBody = Supplier.InternalExternalViewOrderDetail.RequestBody;
type RequestQuery = Supplier.InternalExternalViewOrderDetail.RequestQuery;
export type ResponseBody = Supplier.InternalExternalViewOrderDetail.ResponseBody;
type RequestParams = Supplier.InternalExternalViewOrderDetail.RequestParams;

export const fetchUnauthenticatedOrderKey = createQueryKeys("unauthenticatedOrder", {
  detail: (supplierId: string, viewId: string) => [{ supplierId, viewId }]
});

export const fetchUnauthenticatedOrderParams = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestParams
>(({ supplierId, viewId }) => {
  return {
    method: "GET",
    path: safeurl`/supplier/internal/external-view-order/${supplierId}/${viewId}`
  };
});

export default function useFetchUnauthenticatedOrder(query: {
  supplierId: string;
  viewId: string;
}): UseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useUnauthenticatedApiFetch();

  return useQuery({
    queryKey: fetchUnauthenticatedOrderKey.detail(query.supplierId, query.viewId).queryKey,
    queryFn: async () => {
      const response = await apiFetch(
        fetchUnauthenticatedOrderParams({ supplierId: query.supplierId, viewId: query.viewId })
      );
      return response.data;
    }
  });
}
