import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";
import useSnackbar from "utils/hooks/useSnackbar";

import useDeleteInboxMessages from "../../queries/useDeleteInboxMessages";

import strings from "./InboxMessageDeleteModal.strings.json";

type InboxMessageDeleteModalProps = ModalProps & {
  externalIds: string[];
  successCallback?: () => void;
};

const InboxMessageDeleteModal: FunctionComponent<InboxMessageDeleteModalProps> = ({
  externalIds,
  successCallback,
  onClose,
  ...rest
}) => {
  const messages = useMessages(strings);
  const { showSnackbar } = useSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();

  const { mutate: deleteMessages, isLoading: isDeleting } = useDeleteInboxMessages();

  const onDelete = (): void => {
    deleteMessages(
      { externalIds },
      {
        onSuccess: () => {
          showSnackbar(<FormattedMessage id={messages.DELETE_SUCCESS} values={{ count: externalIds.length }} />);
          onClose?.();
          successCallback?.();
        },
        onError: error => {
          showErrorSnackbar(error, <FormattedMessage id={messages.DELETE_ERROR} />);
        }
      }
    );
  };

  return (
    <Modal
      onClose={onClose}
      maxWidth="xs"
      title={<FormattedMessage id={messages.DELETE_TITLE} values={{ count: externalIds.length }} />}
      actions={
        <Stack direction="row" gap={1}>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage id={messages.CANCEL} />
          </Button>
          <Button color="primary" variant="contained" onClick={onDelete} showLoader={isDeleting}>
            <FormattedMessage id={messages.CONFIRM} />
          </Button>
        </Stack>
      }
      {...rest}
    >
      <Typography>
        <FormattedMessage id={messages.DELETE_BODY} values={{ count: externalIds.length }} />
      </Typography>
    </Modal>
  );
};

export default InboxMessageDeleteModal;
