import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

import { inviteToTradeFailure, InviteToTradeRequest, inviteToTradeSuccess } from "../reducers/supplierById";

/**
 * Saga for calling the API to invite a supplier to trade.
 */
export default createSagaNext<InviteToTradeRequest>(
  (auth0, tenancyId, { supplierId, hasTradingRelationship }) => {
    return postDataToApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/pending/invites/${supplierId}`,
      tenancyId,
      {
        supplierId,
        hasTradingRelationship
      }
    );
  },
  {
    successAction: inviteToTradeSuccess,
    failureAction: inviteToTradeFailure
  }
);
