import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Divider, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useState } from "react";
import { makeStyles } from "tss-react/mui";

import UnavailableChip from "components/Chip/UnavailableChip";
import CircleIcon from "components/CircleIcon";
import Image from "components/Image/Image";
import Menu, { MenuProps } from "components/Menu";
import SearchBox from "components/SearchBox";
import useProducts from "features/suppliers/products/queries/useProducts";
import useMessages from "i18n/hooks/useMessages";
import { ProductStatus, ProductUnavailableReason } from "types/api/generated/supplier";
import { SupplierProductSummary } from "types/api/generated/supplier-internal";

import strings from "./ProductSelectMenu.strings.json";

const useStyles = makeStyles()(theme => ({
  list: {
    maxHeight: theme.spacing(55),
    padding: 0
  },
  menuItem: {
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(2)
  },
  menu: {
    maxWidth: "calc(100% - 56px)"
  },
  loadingIndicatorItem: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2.25)
  },
  search: {
    padding: theme.spacing(2),
    width: "100%"
  },
  emptyState: {
    padding: theme.spacing(3, 3),
    textAlign: "center"
  },
  unavailableChip: {
    maxHeight: theme.spacing(2)
  }
}));

type ProductSelectMenuProps = MenuProps & {
  buyerId: string;
  priceListName?: string | null;
  anchorEl: HTMLElement | null;
  searchHint?: string | null;
  onSelectProduct: (product: SupplierProductSummary) => void;
  isOpen: boolean;
};

const ProductSelectMenu: FunctionComponent<ProductSelectMenuProps> = ({
  buyerId,
  priceListName,
  anchorEl,
  searchHint,
  onSelectProduct,
  isOpen,
  ...rest
}) => {
  const theme = useTheme();
  const messages = useMessages(strings);
  const { classes } = useStyles();
  const { formatter } = useMessageFormatter();

  const [search, setSearch] = useState(searchHint ?? "");

  const { data: products, isLoading } = useProducts(
    { buyerId, search, status: [ProductStatus.Active, ProductStatus.OutOfStock] },
    isOpen
  );

  return (
    <Menu
      id="select-product-dropdown"
      anchorEl={isOpen ? anchorEl : null}
      PaperProps={{ sx: { width: anchorEl && anchorEl.offsetWidth } }}
      classes={{ list: classes.list }}
      {...rest}
    >
      <SearchBox
        value={search}
        onChange={value => setSearch(value)}
        placeholder={
          priceListName
            ? formatter.format(messages.SEARCH_PRICE_LIST, { priceListName })
            : formatter.format(messages.SEARCH)
        }
        onKeyDown={e => e.stopPropagation()}
        className={classes.search}
        searchOnMount={false}
        useUrlParam={false}
        variant="none"
        onFocus={e => e.target.select()}
      />
      <Divider />
      {products?.data?.map((product, index) => (
        <MenuItem
          key={product.productId}
          className={classes.menuItem}
          onClick={() => onSelectProduct(product)}
          divider={index < products.data.length - 1}
        >
          <Image image={product.imagesMetadata?.images[0]} alt={product.name} sizes="40px" />
          <Stack className={classes.menu}>
            <Typography noWrap>{product.name}</Typography>
            <Typography color="textSecondary" variant="caption">
              {product.code}
            </Typography>
            <Box>
              {product.status === ProductStatus.OutOfStock && (
                <UnavailableChip
                  unavailableReason={ProductUnavailableReason.OutOfStock}
                  className={classes.unavailableChip}
                />
              )}
            </Box>
          </Stack>
        </MenuItem>
      ))}
      {isLoading ? (
        <MenuItem className={classes.loadingIndicatorItem}>
          <CircularProgress />
        </MenuItem>
      ) : products?.data.length === 0 ? (
        <Stack className={classes.emptyState} alignItems="center" gap={2}>
          <CircleIcon color={theme.palette.text.secondary}>
            <SearchIcon />
          </CircleIcon>
          <Typography color="textSecondary">
            {priceListName ? (
              <FormattedMessage id={messages.EMPTY_STATE_1_PRICE_LIST} values={{ priceListName }} />
            ) : (
              <FormattedMessage id={messages.EMPTY_STATE_1} />
            )}
          </Typography>
          <Typography color="textSecondary">
            <FormattedMessage id={messages.EMPTY_STATE_2} />
          </Typography>
        </Stack>
      ) : null}
    </Menu>
  );
};

export default ProductSelectMenu;
