import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { postDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";
import { AddCustomerRequest } from "types/api/generated/supplier";

/**
 * Post to the API the list of customers to add to a price list.
 */
function postCustomersToPriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string,
  customers: AddCustomerRequest[]
): Promise<Supplier.V1PricelistsCustomersCreate.ResponseBody> {
  return postDataToApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/customers`,
    tenancyId,
    customers
  );
}

/**
 * Saga to add the list of customers to a price list.
 */
export default createSaga(postCustomersToPriceList, {
  prepareApiMethodParams: ({ priceListId, customers }: { priceListId: string; customers: AddCustomerRequest[] }) => [
    priceListId,
    customers
  ]
});
