import { all, fork, takeLatest } from "redux-saga/effects";

import { startUpAuthenticatedSagas } from "./actions";
import rootAuthenticatedSaga from "./authenticatedSagas";
import rootUnauthenticatedSaga from "./unauthenticatedSaga";

function* rootSaga(): Generator {
  yield all([fork(rootUnauthenticatedSaga), takeLatest(startUpAuthenticatedSagas, rootAuthenticatedSaga)]);
}

export default rootSaga;
