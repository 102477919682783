import { Add as AddIcon, ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { JSX, ReactNode, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { makeStyles } from "tss-react/mui";

import CurrencyCell from "components/CurrencyCell";
import DropDownMenu from "components/DropDownMenu";
import Image from "components/Image/Image";
import NumberCell from "components/NumberCell";
import NumberStepper from "components/NumberStepper";
import useMessages from "i18n/hooks/useMessages";
import SmallRemoveIcon from "media/icon-remove-small.svg?react";
import {
  InvoiceLinesSortOrderType,
  OrderLineProblemType,
  PurchaseOrderSuggestionsV1,
  SupplierProductSummary
} from "types/api/generated/supplier";
import type { LocalInvalidOrderLine, LocalOrderLine } from "types/Orders";
import useCountryConfig from "utils/hooks/useCountryConfig";
import { DECIMALSCALE } from "utils/numbers";
import { sortByLineId, sortByProductName } from "utils/Orders";

import LineProblemChipTooltipWrapper from "./LineProblemChipTooltipWrapper";
import ProductSelectMenu from "./ProductSelectMenu/ProductSelectMenu";
import strings from "./SupplierEditLineItemsTable.strings.json";

const useStyles = makeStyles<void, "hoverButtons">()((theme, _params, classes) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  table: {
    // Allows children of table cells to use height:100%
    height: "100%",
    borderCollapse: "separate", // Fixes some weird rendering in Safari
    "& thead th": {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      lineHeight: 1,
      whiteSpace: "nowrap",
      color: theme.palette.text.secondary,
      background: theme.palette.grey[50]
    },
    "& tfoot td": {
      background: theme.palette.grey[50],
      border: "none",
      borderTop: `1px solid ${theme.palette.divider}`
    },
    "& tbody td:not(:first-child), & th:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.divider}`
    },
    "& tbody tr:last-child td, & tbody tr:last-child th": {
      borderBottom: "none"
    }
  },
  quantityColumn: {
    boxSizing: "border-box",
    width: theme.spacing(10)
  },
  priceColumn: {
    boxSizing: "border-box",
    width: theme.spacing(12)
  },
  removeColumn: {
    boxSizing: "border-box",
    width: theme.spacing(5)
  },
  row: {
    [`&:hover .${classes.hoverButtons}`]: {
      visibility: "visible",
      opacity: 1
    }
  },
  cell: {
    padding: theme.spacing(1.5, 2)
  },
  productNameCell: {
    // Used by the hover quantity buttons
    position: "relative"
  },
  buttonCellWrapper: {
    width: "100%",
    textAlign: "left"
  },
  productImage: {
    marginRight: theme.spacing(2)
  },
  missingProductImage: {
    width: 40,
    height: 40,
    outline: "1px solid rgba(0, 0, 0, 0.15)",
    outlineOffset: "-1px",
    outlineStyle: "dashed",
    borderRadius: theme.shape.borderRadius
  },
  productName: {
    "& *": {
      lineHeight: 1.25
    }
  },
  productNameProblemsWrapper: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
  invalidLineActions: {
    paddingTop: theme.spacing(0.75)
  },
  productProblems: {
    padding: theme.spacing(0.5, 0)
  },
  hoverButtons: {
    visibility: "hidden",
    gap: theme.spacing(1),
    flexDirection: "row",
    opacity: 0,
    transition: "all 0.2s ease-in-out",
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
    transform: "translateY(-50%)"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 2)
  },
  pricingSource: {
    // Hacky workaround to make text-overflow:ellipsis work in a table layout
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    minWidth: 0,
    lineHeight: 1.25,
    fontSize: theme.typography.caption.fontSize,
    padding: theme.spacing(1),
    margin: theme.spacing(-1)
  },
  pricingSourceButton: {
    borderRadius: theme.shape.borderRadius,
    color: "inherit"
  },
  pricingSourceButtonWrapper: {
    display: "inline-flex",
    alignItems: "center"
  },
  pricingSourceButtonIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    lineHeight: 1,
    marginLeft: "4px"
  },
  priceWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    marginLeft: "auto",
    whiteSpace: "nowrap"
  },
  priceAmount: {
    paddingLeft: theme.spacing(2),
    fontWeight: "bold"
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: theme.spacing(2),
    paddingLeft: "12px",
    color: theme.palette.primary.main
  },
  addButtonIcon: {
    width: "20px",
    height: "20px",
    marginRight: "4px"
  },
  noItems: {
    color: theme.palette.grey[600]
  },
  disabledText: {
    color: theme.palette.text.disabled
  },
  numberStepper: {
    boxShadow: "none"
  },
  highlight: {
    backgroundColor: "#FFF5DD"
  }
}));

export interface UnitPriceChange<L extends LocalOrderLine> {
  lineItem: L;
  unitPrice: number;
}

export interface SupplierEditLineItemsTableProps<L extends LocalOrderLine, IL extends LocalInvalidOrderLine> {
  className?: string;
  lineItems: L[];
  invalidLineItems?: IL[];
  suggestedLineItems?: PurchaseOrderSuggestionsV1["orderLines"];
  pricingSource?: ReactNode;
  priceListName?: string | null;
  buyerId?: string;
  onRemove: (lineItem: L | IL) => void;
  onQuantityChange: (lineItem: L, quantity: number) => void;
  onUnitPriceChange: (changes: UnitPriceChange<L>[]) => void;
  onAddItem?: () => void;
  onAddItemDropdown?: (product: SupplierProductSummary) => void;
  onReplace?: (lineItem: L | IL, product: SupplierProductSummary) => void;
  quickAddProducts?: SupplierProductSummary[];
  sortOrder?: InvoiceLinesSortOrderType;
}

function SupplierEditLineItemsTable<L extends LocalOrderLine, IL extends LocalInvalidOrderLine>({
  className,
  lineItems,
  invalidLineItems = [],
  suggestedLineItems = [],
  pricingSource,
  priceListName,
  buyerId,
  onRemove,
  onQuantityChange,
  onUnitPriceChange,
  onAddItem,
  onAddItemDropdown,
  onReplace,
  quickAddProducts,
  sortOrder = InvoiceLinesSortOrderType.OrderLineId
}: SupplierEditLineItemsTableProps<L, IL>): JSX.Element {
  const { classes, cx } = useStyles();
  const messages = useMessages(strings);
  const { salesTaxName } = useCountryConfig();

  const addCellReference = useRef<HTMLTableCellElement | null>(null);
  const [isProductSelectMenuOpen, setIsProductSelectMenuOpen] = useState(false);

  const allLineItems = [...lineItems, ...invalidLineItems].sort((a, b) =>
    sortOrder === InvoiceLinesSortOrderType.ProductName ? sortByProductName(a, b) : sortByLineId(a, b)
  );
  const hasPrices = allLineItems.some(lineItem => Boolean(lineItem.unitAmount));
  const priceListsActive = lineItems.some(lineItem => Boolean(lineItem.priceListUnitAmount));
  const orderAmount = hasPrices
    ? allLineItems.reduce((total, lineItem) => total + (lineItem.lineAmount?.amount ?? 0), 0)
    : null;

  const handleUnitPriceChange = (lineItem: L, unitPrice: number): void => {
    onUnitPriceChange([{ lineItem, unitPrice }]);
  };

  const handlePricesReset = (): void => {
    onUnitPriceChange(
      lineItems.map(lineItem => ({
        lineItem,
        unitPrice: lineItem.priceListUnitAmount?.amount ?? 0
      }))
    );
  };

  return (
    <TableContainer className={cx(className, classes.root)}>
      <Table className={cx(classes.table)}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id={messages.PRODUCT_HEADING} />
            </TableCell>
            <TableCell className={cx(classes.quantityColumn)} align="right">
              <FormattedMessage id={messages.QUANTITY_HEADING} />
            </TableCell>

            {hasPrices && (
              <>
                <TableCell className={cx(classes.priceColumn)} align="right">
                  <FormattedMessage id={messages.UNIT_PRICE_HEADING} />
                </TableCell>
                <TableCell className={cx(classes.priceColumn)} align="right">
                  <FormattedMessage id={messages.AMOUNT_HEADING} />
                </TableCell>
              </>
            )}

            <TableCell className={cx(classes.removeColumn)} />
          </TableRow>
        </TableHead>

        <TableBody>
          {allLineItems.map(lineItem =>
            "productId" in lineItem ? (
              <LineItemRow
                key={`${lineItem.lineId} ${lineItem.productId}`}
                lineItem={lineItem}
                hasPrices={hasPrices}
                onRemove={onRemove}
                onQuantityChange={onQuantityChange}
                onUnitPriceChange={handleUnitPriceChange}
                quickAddProducts={quickAddProducts}
                onReplace={onReplace}
                priceListName={priceListName}
                suggestedLineItems={suggestedLineItems}
                buyerId={buyerId}
              />
            ) : (
              <LineItemRow
                key={lineItem.lineId}
                lineItem={lineItem}
                hasPrices={hasPrices}
                onReplace={onReplace}
                onRemove={onRemove}
                priceListName={priceListName}
                suggestedLineItems={suggestedLineItems}
                buyerId={buyerId}
              />
            )
          )}

          {lineItems.length === 0 && invalidLineItems.length === 0 && !onAddItem && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="div" className={cx(classes.noItems)}>
                  <FormattedMessage id={messages.NO_ITEMS_MESSAGE} />
                </Typography>
              </TableCell>

              <TableCell />

              {hasPrices && (
                <>
                  <TableCell />
                  <TableCell />
                </>
              )}

              <TableCell />
            </TableRow>
          )}

          {onAddItem && (
            <TableRow>
              <TableCell component="th" scope="row" padding="none">
                <ButtonBase className={cx(classes.addButton)} focusRipple onClick={onAddItem}>
                  <AddIcon className={cx(classes.addButtonIcon)} />
                  <Typography component="div">
                    <FormattedMessage id={messages.ADD_BUTTON} />
                  </Typography>
                </ButtonBase>
              </TableCell>

              <TableCell />

              {hasPrices && (
                <>
                  <TableCell />
                  <TableCell />
                </>
              )}

              <TableCell />
            </TableRow>
          )}

          {onAddItemDropdown && buyerId && (
            <TableRow>
              <TableCell colSpan={5} component="th" scope="row" padding="none" ref={addCellReference}>
                <ButtonBase className={classes.addButton} onClick={() => setIsProductSelectMenuOpen(true)}>
                  <Typography component="div">
                    <FormattedMessage id={messages.ADD_BUTTON} />
                  </Typography>
                  <ExpandMoreIcon className={classes.addButtonIcon} />
                </ButtonBase>
              </TableCell>

              <ProductSelectMenu
                buyerId={buyerId}
                priceListName={priceListName}
                onSelectProduct={product => {
                  onAddItemDropdown(product);
                  setIsProductSelectMenuOpen(false);
                }}
                isOpen={isProductSelectMenuOpen}
                onClose={() => setIsProductSelectMenuOpen(false)}
                anchorEl={addCellReference.current}
              />
            </TableRow>
          )}
        </TableBody>

        {hasPrices && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} padding="none">
                <div className={cx(classes.footer)}>
                  {priceListName && priceListsActive ? (
                    <DropDownMenu
                      id="edit-line-items-table-reset-prices-menu"
                      trigger={triggerProps => (
                        <ButtonBase
                          data-testid="edit-line-items-table-pricing-source"
                          className={cx(classes.pricingSource, classes.pricingSourceButton)}
                          focusRipple
                          {...triggerProps}
                        >
                          <span className={cx(classes.pricingSourceButtonWrapper)}>
                            {pricingSource}
                            <ExpandMoreIcon className={cx(classes.pricingSourceButtonIcon)} />
                          </span>
                        </ButtonBase>
                      )}
                    >
                      <MenuItem onClick={handlePricesReset}>
                        <FormattedMessage id={messages.RESET_PRICES} values={{ priceListName }} />
                      </MenuItem>
                    </DropDownMenu>
                  ) : (
                    <span className={cx(classes.pricingSource)} data-testid="edit-line-items-table-pricing-source">
                      {pricingSource}
                    </span>
                  )}

                  <div className={cx(classes.priceWrapper)}>
                    <Typography variant="caption">
                      <FormattedMessage id={messages.TOTAL_LABEL} values={{ salesTaxName }} />
                    </Typography>

                    <Typography
                      component="div"
                      className={cx(classes.priceAmount)}
                      color="textPrimary"
                      data-testid="edit-line-items-table-total-price"
                    >
                      <NumberFormat
                        displayType="text"
                        prefix="$"
                        value={orderAmount}
                        thousandSeparator
                        decimalScale={DECIMALSCALE}
                        fixedDecimalScale
                      />
                    </Typography>
                  </div>
                </div>
              </TableCell>

              <TableCell />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

interface LineItemRowProps<L extends LocalOrderLine | LocalInvalidOrderLine> {
  lineItem: L;
  hasPrices: boolean;
  buyerId?: string;
  onRemove?: (lineItem: L) => void;
  onQuantityChange?: (lineItem: L, quantity: number) => void;
  onUnitPriceChange?: (lineItem: L, unitPrice: number) => void;
  onReplace?: (lineItem: L, product: SupplierProductSummary) => void;
  quickAddProducts?: SupplierProductSummary[];
  priceListName?: string | null;
  suggestedLineItems?: PurchaseOrderSuggestionsV1["orderLines"];
}

function LineItemRow<L extends LocalOrderLine | LocalInvalidOrderLine>({
  lineItem,
  hasPrices,
  buyerId,
  onRemove,
  onQuantityChange,
  onUnitPriceChange,
  onReplace,
  quickAddProducts,
  priceListName,
  suggestedLineItems
}: LineItemRowProps<L>): JSX.Element {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const messages = useMessages(strings);
  const cellReference = useRef<HTMLTableCellElement | null>(null);
  const { classes, cx } = useStyles();
  const { formatter } = useMessageFormatter();

  const [isProductSelectMenuOpen, setIsProductSelectMenuOpen] = useState(false);

  const canReplaceProduct = onReplace && buyerId;

  const lineWasSuggested = suggestedLineItems?.some(
    it => it.productName === lineItem.productName || it.productCode === lineItem.productCode
  );
  const isLineMatchedToProduct = "productId" in lineItem;
  const shouldHighlightLine = lineWasSuggested && isLineMatchedToProduct;

  let lineItemProblems = "problems" in lineItem ? lineItem.problems : [];

  // Filter out ProductHidden problems for quick add products.
  // This is a hack and should really be done in the API.
  if ("productId" in lineItem && quickAddProducts) {
    lineItemProblems = lineItemProblems.filter(problem => {
      return !(
        problem.type === OrderLineProblemType.ProductHidden &&
        quickAddProducts.find(product => lineItem.productId && product.productId)
      );
    });
  }

  // Filter out DifferentPrice problems when the unitAmount matches the priceListUnitAmount or the
  // price has been edited, to make the problem disappear in real time when editing so it reflects
  // what it'll look like when saved.
  if ("priceListUnitAmount" in lineItem) {
    lineItemProblems = lineItemProblems.filter(problem => {
      return !(
        problem.type === OrderLineProblemType.DifferentPrice &&
        (lineItem.unitAmount?.amount === lineItem.priceListUnitAmount?.amount || lineItem.hasCustomUnitAmount)
      );
    });
  }

  const isDeleted = lineItemProblems.some(problem => problem.type === OrderLineProblemType.ProductDeleted);

  return (
    <TableRow className={cx(classes.row)}>
      <TableCell className={classes.productNameCell} padding="none" component="th" scope="row" ref={cellReference}>
        <ButtonBase
          disableRipple
          disabled={!onReplace}
          onClick={() => setIsProductSelectMenuOpen(true)}
          className={cx(classes.cell, classes.buttonCellWrapper)}
        >
          {isSmUp &&
            (isLineMatchedToProduct ? (
              <Image
                className={classes.productImage}
                image={lineItem.productImages?.images[0]}
                alt={formatter.format(messages.PRODUCT_IMAGE_ALT, { name: lineItem.productName })}
                sizes="40px"
              />
            ) : (
              <Box className={cx(classes.productImage, classes.missingProductImage)} />
            ))}
          <div className={cx(classes.productNameProblemsWrapper)}>
            <Stack className={cx(classes.productName)}>
              <Typography color={isLineMatchedToProduct ? "textPrimary" : "textSecondary"}>
                <span className={cx({ [classes.highlight]: shouldHighlightLine })}>{lineItem.productName}</span>
              </Typography>
              {lineItem.productCode && (
                <Typography variant="caption" color="textSecondary">
                  <span className={cx({ [classes.highlight]: shouldHighlightLine })}>{lineItem.productCode}</span>
                </Typography>
              )}
            </Stack>
          </div>
          <Stack direction="row" alignItems="center" gap={1}>
            {"problems" in lineItem && (
              <LineProblemChipTooltipWrapper problems={lineItemProblems} className={classes.productProblems} />
            )}
            {canReplaceProduct && (
              <IconButton onClick={() => setIsProductSelectMenuOpen(true)}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Stack>
        </ButtonBase>
        <Box className={classes.hoverButtons}>
          {/* At the moment, we don't support showing the stepper when the product replace menu is available */}
          {onQuantityChange && !isDeleted && !canReplaceProduct && (
            <NumberStepper
              value={lineItem.quantity ?? 0}
              onChange={q => onQuantityChange(lineItem, q)}
              minValue={0}
              hideInput
              className={classes.numberStepper}
            />
          )}
        </Box>
      </TableCell>

      <NumberCell
        inputClassName={cx(classes.cell)}
        value={lineItem.quantity ?? 0}
        onBlur={newQuantity => onQuantityChange?.(lineItem, newQuantity ?? 0)}
        aria-label={formatter.format(messages.QUANTITY_LABEL)}
        placeholder="0"
        disabled={!onQuantityChange || isDeleted}
      />

      {hasPrices && (
        <>
          <CurrencyCell
            inputClassName={cx(classes.cell)}
            prefix=""
            value={lineItem.unitAmount?.amount.toString()}
            onBlur={newUnitPrice => onUnitPriceChange?.(lineItem, Number(newUnitPrice ?? 0))}
            aria-label={formatter.format(messages.UNIT_PRICE_LABEL)}
            placeholder="0"
            disabled={!onUnitPriceChange || isDeleted}
          />
          <TableCell className={cx(classes.cell)} align="right" data-testid={`lineamount-for-${lineItem.productCode}`}>
            <Typography component="div" className={cx({ [classes.disabledText]: !onUnitPriceChange || isDeleted })}>
              <NumberFormat
                displayType="text"
                value={lineItem.lineAmount?.amount ?? 0}
                thousandSeparator
                decimalScale={DECIMALSCALE}
                fixedDecimalScale
              />
            </Typography>
          </TableCell>
        </>
      )}

      <TableCell align="center" padding="none">
        <IconButton
          onClick={() => onRemove?.(lineItem)}
          title={formatter.format(messages.REMOVE_BUTTON)}
          disabled={!onRemove}
          size="large"
        >
          <SmallRemoveIcon aria-hidden />
        </IconButton>
      </TableCell>

      {canReplaceProduct && (
        <ProductSelectMenu
          buyerId={buyerId}
          priceListName={priceListName}
          searchHint={lineItem.productName}
          onSelectProduct={product => {
            onReplace(lineItem, product);
            setIsProductSelectMenuOpen(false);
          }}
          isOpen={isProductSelectMenuOpen}
          onClose={() => setIsProductSelectMenuOpen(false)}
          anchorEl={cellReference.current}
        />
      )}
    </TableRow>
  );
}

export default SupplierEditLineItemsTable;
