import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem, WriteFileResult } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";

import { trackError } from "./Errors";
import { html } from "./Html";

/**
 * Open the browser download dialog for any object type representable as an
 * `href` attribute on a link element.
 */
export function downloadForWeb(href: string, filename: string): void {
  const a = html("a", {
    href,
    target: "_blank",
    download: filename
  });
  a.click();
}

/**
 * Takes a blob, converts it to a base64 string, and presents the native
 * system share sheet to share, export or save the file.
 *
 * Supports iOS and Android.
 *
 * @param blob
 *   A blob that represents the file you're passing to the share menu.
 * @param filename
 *   The name of the file to share, export or save.
 */
export function shareForMobile(blob: Blob, filename: string): void {
  if (blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob); // read pdf and extract base64 data
    reader.onload = (readerBlob: ProgressEvent<FileReader>) => {
      const readerResult = readerBlob.target?.result as string;
      const data = readerResult.split(",")[1] || ""; // remove base64 prefix

      Filesystem.writeFile({
        path: filename,
        data,
        directory: Directory.Cache,
        recursive: true
      })
        .then((result: WriteFileResult) => {
          const title = filename.replace(/\.[^/.]+$/, ""); // trim filetype extension from filename
          Share.share({
            title,
            dialogTitle: title,
            url: result.uri
          });
        })
        .catch(error => {
          trackError(error, `Mobile - failed to share file: ${error.toString()}`);
        });
    };
  }
}

/**
 * Open the browser download dialog for a blob.
 */
export function downloadBlob(blob: Blob, filename: string): void {
  if (Capacitor.isNativePlatform()) {
    shareForMobile(blob, filename);
  } else {
    downloadForWeb(URL.createObjectURL(blob), filename);
  }
}

/**
 * Web and mobile support for exporting files.
 *
 * @param content Content to be included in the exported file.
 * @param filename Filename for the exported file.
 * @param type File content type.
 */
export function downloadFile(content: string, filename: string, type: string = "text/csv"): void {
  if (Capacitor.isNativePlatform()) {
    shareForMobile(new Blob([content], { type }), filename);
  } else {
    downloadForWeb(`data:${type};charset=utf-8,%EF%BB%BF${encodeURIComponent(content)}`, filename);
  }
}
