import { alpha, useTheme } from "@mui/material";
import { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from "react";
import { makeStyles } from "tss-react/mui";

export interface CircleIconProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  color?: string;
  size?: number;
}

interface CircleIconStyledProps {
  color: string;
  size: number;
}

const useStyles = makeStyles<CircleIconStyledProps>()((_theme, { color, size }) => ({
  icon: {
    color,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: size + 24,
    height: size + 24,
    background: alpha(color, 0.1),
    borderRadius: "100%",
    "& > svg": {
      width: size,
      height: size
    }
  }
}));

const CircleIcon: FunctionComponent<CircleIconProps> = ({ color, size, children, className, ...props }) => {
  const theme = useTheme();
  const { classes, cx } = useStyles({ color: color ?? theme.palette.secondary.main, size: size ?? 24 });

  return (
    <span className={cx(classes.icon, className)} {...props}>
      {children}
    </span>
  );
};

export default CircleIcon;
