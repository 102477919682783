import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type Deserializer<T> = (stringValue: string) => T;
export type TypedUrlParamsResult<T> = [T, (newValue: T) => void];

/**
 * React hook for backing serializable state in the URL query params, with the
 * same API as `useState`.
 *
 * @template T
 * @param name
 *   The name of the query parameter.
 * @param initialValue
 *   The default value to return if there is no matching query parameter on the
 *   URL.  Specifying this will not place put the value on to the URL.
 * @param deserializer
 *   Special handling of the query parameter value to convert it to the type `T`.
 */
export default function useTypedUrlParams<T = string>(
  name: string,
  initialValue?: T,
  deserializer?: Deserializer<T>
): TypedUrlParamsResult<T | undefined> {
  const history = useHistory();
  const location = useLocation();

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const stringValue = searchParams.get(name);
    return stringValue !== null ? (deserializer?.(stringValue) ?? (stringValue as T)) : initialValue;
  }, [deserializer, initialValue, location.search, name]);

  const setValue = useCallback(
    (newValue: T | undefined): void => {
      const newSearchParams = new URLSearchParams(history.location.search);

      if (newValue === undefined) {
        newSearchParams.delete(name);
      } else {
        newSearchParams.set(name, newValue!.toString());
      }

      history.replace({
        ...history.location,
        search: newSearchParams.toString()
      });
    },
    [history, name]
  );

  return useMemo(() => [value, setValue], [setValue, value]);
}

export const BooleanDeserializer: Deserializer<boolean> = stringValue => stringValue === "true";
export const NumberDeserializer: Deserializer<number> = stringValue => parseFloat(stringValue);
