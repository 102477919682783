import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from "@mui/material/MenuItem";
import { ForwardedRef, forwardRef, ReactNode } from "react";

import useLongRunningActionHandler, { LongRunningAction } from "utils/hooks/useLongRunningActionHandler";

export interface MenuItemProps<E> extends Omit<MuiMenuItemProps, "button" | "onClick"> {
  children: ReactNode;
  disabled?: boolean;
  onClick: LongRunningAction<E>;
}

/**
 * A small wrapper around MUI's `<MenuItem>` that can disable and re-enable
 * itself when provided an `onClick` handler that returns a `Promise`.
 */
const MenuItem = forwardRef(
  (
    { children, disabled = false, onClick, ...other }: MenuItemProps<HTMLLIElement>,
    ref: ForwardedRef<HTMLLIElement>
  ): JSX.Element => {
    const [clickHandler, isActionRunning] = useLongRunningActionHandler(onClick);

    return (
      <MuiMenuItem onClick={clickHandler} disabled={disabled || isActionRunning} {...other} ref={ref}>
        {children}
      </MuiMenuItem>
    );
  }
);

export default MenuItem;
