import { Permission } from "types/api";

import Route from "./Route";

export const Root = new Route("/");

// COMPANY ROUTES
// -----------------------------------------------------------------------------

export const CompanyRedirectRoute = new Route("/c/company/:targetRoute*");
export const CompanyRoot = new Route("/c/:tenancyId");
export const CompanyError = new Route("/c/:tenancyId/error");

/**
 * Return whether or not the given path is for a company route.
 */
export function isCompanyRoute(pathname: string): boolean {
  return CompanyRoot.matches(pathname);
}

// BUYER ROUTES
// -----------------------------------------------------------------------------

export const BuyerRoot = new Route("/c/:tenancyId/buyer");

export const BuyerDashboard = new Route("/c/:tenancyId/buyer/dashboard");

export const BuyerGettingStarted = new Route("/c/:tenancyId/buyer/getting-started", [Permission.ViewGettingStarted]);
export const BuyerGettingStartedOld = new Route("/c/:tenancyId/getting-started");

export const BuyerFavouritesLists = new Route("/c/:tenancyId/buyer/favourites", [Permission.ViewFavourites]);
export const BuyerFavouritesList = new Route("/c/:tenancyId/buyer/favourites/:favouritesListId", [
  Permission.ViewFavourites
]);
export const BuyerFavouritesListAddProducts = new Route(
  "/c/:tenancyId/buyer/favourites/:favouritesListId/add-products",
  [Permission.ViewConnectedSuppliers, Permission.BuyerViewProducts, Permission.UpdateFavouriteProducts]
);

export const BuyerSuppliers = new Route("/c/:tenancyId/buyer/suppliers", [Permission.ViewConnectedSuppliers]);
export const BuyerSupplier = new Route("/c/:tenancyId/buyer/suppliers/:supplierId", [
  Permission.ViewSuppliersProfile,
  Permission.BuyerViewSupplierSettings
]);
export const BuyerSupplierPendingLead = new Route("/c/:tenancyId/buyer/suppliers/pending-lead/:requestId", [
  Permission.ViewPendingSuppliers
]);

export const BuyerOrders = new Route("/c/:tenancyId/buyer/orders", [Permission.BuyerViewOrders]);

export const BuyerNewOrder = new Route("/c/:tenancyId/buyer/orders/new-order", [
  Permission.BuyerCreateOrder,
  Permission.BuyerViewSupplierSettings,
  Permission.BuyerViewProducts
]);
export const BuyerNewOrderEdit = new Route("/c/:tenancyId/buyer/orders/new-order/edit", BuyerNewOrder.permissions);
export const BuyerNewOrderMobileProducts = new Route(
  "/c/:tenancyId/buyer/orders/new-order/products",
  BuyerNewOrder.permissions
);
export const BuyerNewOrderMobileProductsGrouped = new Route(
  "/c/:tenancyId/buyer/orders/new-order/products-grouped",
  BuyerNewOrder.permissions
);
export const BuyerNewOrderConfirm = new Route(
  "/c/:tenancyId/buyer/orders/new-order/confirm",
  BuyerNewOrder.permissions
);
export const BuyerPlacedOrder = new Route("/c/:tenancyId/buyer/orders/new-order/placed", BuyerNewOrder.permissions);
export const BuyerOrderNowRedirect = new Route("/c/:tenancyId/buyer/order-now/:supplierId", BuyerNewOrder.permissions);

export const BuyerViewOrder = new Route("/c/:tenancyId/buyer/orders/:orderId", [Permission.BuyerViewOrders]);
export const BuyerPrintInvoice = new Route("/c/:tenancyId/buyer/orders/:orderId/print-invoice", [
  Permission.ViewInvoice
]);

export const BuyerEditOrderLines = new Route("/c/:tenancyId/buyer/orders/:orderId/edit-lines", [
  Permission.BuyerUpdateOrder
]);

export const BuyerOnboardingNewUserWelcome = new Route("/c/:tenancyId/onboarding/buyer/new-user-welcome");

export const BuyerNewsfeed = new Route("/c/:tenancyId/buyer/newsfeed");

export const BuyerReports = new Route("/c/:tenancyId/buyer/reports");
export const BuyerReportsOrdersExport = new Route("/c/:tenancyId/buyer/reports/orders-export/:reportId", [
  Permission.BuyerViewOrdersReport
]);

export const BuyerPaymentManagement = new Route("/c/:tenancyId/settings/buyer/payments/management", [
  Permission.PaymentViewMethods
]);
/**
 * Return whether or not the path is one that is exclusive to the buyer app.
 * This will return true for any route starting with `/c/:tenancyId/buyer`, plus
 * a handful of other buyer routes that don't have that path prefix.
 *
 * Note that those other routes really should be changed to start with
 * `/c/:tenancyId/buyer`.
 */
export function isBuyerRoute(pathname: string): boolean {
  return [BuyerRoot, BuyerGettingStartedOld, BuyerPaymentManagement].some(route => route.matches(pathname));
}

// SUPPLIER ROUTES
// -----------------------------------------------------------------------------

export const SupplierRoot = new Route("/c/:tenancyId/supplier");

export const SupplierDashboard = new Route("/c/:tenancyId/supplier/dashboard");

export const SupplierGettingStarted = new Route("/c/:tenancyId/supplier/getting-started", [
  Permission.ViewGettingStarted
]);

export const SupplierCustomers = new Route("/c/:tenancyId/supplier/customers", [
  Permission.ViewConnectedBuyers,
  Permission.ViewPendingBuyers
]);
export const SupplierCustomersRequest = new Route("/c/:tenancyId/supplier/customers/request");
export const SupplierCustomersRequestComplete = new Route("/c/:tenancyId/supplier/customers/request-complete");
export const SupplierBuyerProfile = new Route("/c/:tenancyId/supplier/customers/:buyerId");
export const SupplierEditUnclaimedBuyer = new Route("/c/:tenancyId/supplier/customers/:buyerId/edit");
export const SupplierUploadCustomerList = new Route("/c/:tenancyId/supplier/customers/upload");
export const SupplierCustomersImport = new Route("/c/:tenancyId/supplier/customers/import", [
  Permission.ViewProviderContacts
]);
export const SupplierOldOrdersLayout = new Route("/c/:tenancyId/supplier/orders", [Permission.SupplierViewOrders]);
export const SupplierOrdersGridLayout = new Route("/c/:tenancyId/supplier/orders-grid", [
  Permission.SupplierViewOrders
]);

export const SupplierNewOrder = new Route("/c/:tenancyId/supplier/orders/new-order");
export const SupplierNewOrderChooseCustomer = new Route("/c/:tenancyId/supplier/orders/new-order/choose-customer");
export const SupplierNewOrderEdit = new Route("/c/:tenancyId/supplier/orders/new-order/edit");
export const SupplierNewOrderConfirm = new Route("/c/:tenancyId/supplier/orders/new-order/confirm");

export const SupplierViewOrder = new Route("/c/:tenancyId/supplier/orders/:orderId");
export const SupplierPrintInvoice = new Route("/c/:tenancyId/supplier/orders/:orderId/print-invoice", [
  Permission.ViewInvoice
]);

export const SupplierInboxMessage = new Route("/c/:tenancyId/supplier/inbox/:messageId");

// TODO: Rename to include /supplier in it
export const SupplierProducts = new Route("/c/:tenancyId/products", [Permission.SupplierViewProducts]);
export const SupplierProduct = new Route("/c/:tenancyId/products/:productId");
export const SupplierProductsNewProduct = new Route("/c/:tenancyId/products/new");
export const SupplierUploadProductList = new Route("/c/:tenancyId/products/upload");

export const SupplierProductImport = new Route("/c/:tenancyId/supplier/product-import", [Permission.ImportProducts]);
export const SupplierProductImportTips = new Route("/c/:tenancyId/supplier/product-import/tips");
export const SupplierProductImportXeroTax = new Route("/c/:tenancyId/supplier/product-import/xero-tax");
export const SupplierProductImportChooseProducts = new Route("/c/:tenancyId/supplier/product-import/choose-products");
export const SupplierProductImportValidation = new Route("/c/:tenancyId/supplier/product-import/validate");
export const SupplierProductImportSalesAccountMissing = new Route(
  "/c/:tenancyId/supplier/product-import/sales-account-missing"
);
export const SupplierProductImportArchivedProducts = new Route(
  "/c/:tenancyId/supplier/product-import/archived-products"
);
export const SupplierProductImportReview = new Route("/c/:tenancyId/supplier/product-import/review-and-import");
export const SupplierProductImportImporting = new Route("/c/:tenancyId/supplier/product-import/products-importing");
export const SupplierProductImportImported = new Route("/c/:tenancyId/supplier/product-import/products-imported");
export const SupplierProductImportError = new Route("/c/:tenancyId/supplier/product-import/error");
export const SupplierProductImportSetTaxRate = new Route("/c/:tenancyId/supplier/product-import/set-tax-rates");

export const SupplierOrderSettings = new Route("/c/:tenancyId/settings/orders", [Permission.SupplierViewOrderSettings]);
export const SupplierQuickAddProducts = new Route("/c/:tenancyId/settings/quick-add-products", [
  Permission.ViewQuickAddProducts
]);
export const SupplierIntegrationsSettings = new Route("/c/:tenancyId/settings/integrations", [
  Permission.ViewApiConnections
]);

export const SupplierPriceLists = new Route("/c/:tenancyId/supplier/price-lists", [Permission.ViewPriceLists]);
export const SupplierPriceListSetup = new Route("/c/:tenancyId/supplier/price-lists/setup");
export const SupplierPriceListSetupIntro = new Route("/c/:tenancyId/supplier/price-lists/setup/intro");
export const SupplierPriceListSetupTaxExclusive = new Route("/c/:tenancyId/supplier/price-lists/setup/tax-exclusive");
export const SupplierPriceListSetupTaxRegistered = new Route("/c/:tenancyId/supplier/price-lists/setup/tax-registered");
export const SupplierPriceListSetupReviewPrices = new Route("/c/:tenancyId/supplier/price-lists/setup/review-prices");
export const SupplierPriceListSetupMissingDetails = new Route(
  "/c/:tenancyId/supplier/price-lists/setup/missing-details"
);
export const SupplierPriceListSetupNextSteps = new Route("/c/:tenancyId/supplier/price-lists/setup/next-steps");
export const SupplierPriceList = new Route("/c/:tenancyId/supplier/price-lists/:priceListId");
export const SupplierPriceListChooseCustomers = new Route(
  "/c/:tenancyId/supplier/price-lists/:priceListId/choose-customers"
);
export const SupplierPriceListsActivate = new Route("/c/:tenancyId/supplier/price-lists/activate");
export const SupplierPriceListsActivateConfirmation = new Route("/c/:tenancyId/supplier/price-lists/activate/confirm");
export const SupplierPriceListsActivateComplete = new Route("/c/:tenancyId/supplier/price-lists/activate/complete");

export const SupplierNewsfeed = new Route("/c/:tenancyId/supplier/newsfeed");
export const SupplierNewsfeedNewPost = new Route("/c/:tenancyId/supplier/newsfeed/new-post");
export const SupplierNewsfeedEditPost = new Route("/c/:tenancyId/supplier/newsfeed/edit-post/:postId");

export const SupplierReports = new Route("/c/:tenancyId/supplier/reports");
export const SupplierReportsPayoutReport = new Route("/c/:tenancyId/supplier/reports/payout-report", [
  Permission.PaymentViewPayouts
]);
export const SupplierReportsPayoutReportDetail = new Route("/c/:tenancyId/supplier/reports/payout-report/:payoutId", [
  Permission.PaymentViewPayouts
]);
export const SupplierReportsProductionReport = new Route("/c/:tenancyId/supplier/reports/production-report", [
  Permission.SupplierViewProductionReport
]);
export const SupplierReportsOrdersExportReport = new Route(
  "/c/:tenancyId/supplier/reports/orders-export-report/:reportId",
  [Permission.SupplierViewOrdersExportReport]
);

export const SupplierPayments = new Route("/c/:tenancyId/supplier/payments");
export const SupplierPaymentBenefits = new Route("/c/:tenancyId/supplier/payments/benefits", [
  Permission.PaymentCreateMerchantAccount
]);
export const SupplierPaymentStripeConnect = new Route("/c/:tenancyId/supplier/payments/setup/stripe-connect", [
  Permission.PaymentCreateMerchantAccount,
  Permission.PaymentLinkMerchantAccount
]);
export const SupplierPaymentShowPrices = new Route("/c/:tenancyId/supplier/payments/setup/show-prices");
export const SupplierPaymentTaxRegistered = new Route("/c/:tenancyId/supplier/payments/setup/tax-registered", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const SupplierPaymentNonGstRegistered = new Route("/c/:tenancyId/supplier/payments/setup/not-tax-registered");
export const SupplierPaymentReviewProducts = new Route("/c/:tenancyId/supplier/payments/setup/review-products", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const SupplierPaymentMissingDetails = new Route("/c/:tenancyId/supplier/payments/setup/missing-details", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const SupplierPaymentReconciliationSettings = new Route(
  "/c/:tenancyId/supplier/payments/setup/reconciliation-settings",
  [
    Permission.SupplierViewPaymentSettings,
    Permission.SupplierUpdatePaymentSettings,
    Permission.ViewProviderContacts,
    Permission.CreateProviderContacts,
    Permission.ViewProviderAccounts,
    Permission.CreateProviderAccounts
  ]
);
export const SupplierPaymentSetupComplete = new Route("/c/:tenancyId/supplier/payments/setup/complete");
export const SupplierPaymentSettings = new Route("/c/:tenancyId/supplier/payments/settings", [
  Permission.PaymentCreateMerchantAccount,
  Permission.PaymentLinkMerchantAccount,
  Permission.SupplierViewPaymentSettings,
  Permission.SupplierUpdatePaymentSettings,
  Permission.ViewProviderContacts,
  Permission.CreateProviderContacts,
  Permission.ViewProviderAccounts,
  Permission.CreateProviderAccounts
]);
export const SupplierPaymentTerms = new Route("/c/:tenancyId/supplier/payment-terms", [
  Permission.PaymentCreatePaymentTerms,
  Permission.PaymentUpdatePaymentTerms,
  Permission.PaymentViewPaymentTerms
]);
export const SupplierPaymentTerm = new Route("/c/:tenancyId/supplier/payment-terms/:paymentTermsId", [
  Permission.PaymentUpdatePaymentTerms,
  Permission.PaymentViewPaymentTerms
]);
export const SupplierPaymentTermCustomers = new Route(
  "/c/:tenancyId/supplier/payment-terms/:paymentTermsId/customers",
  [Permission.PaymentUpdatePaymentTerms, Permission.PaymentViewPaymentTerms]
);

export const SupplierTaxSetup = new Route("/c/:tenancyId/supplier/tax-setup");
export const SupplierTaxSetupTaxRegistered = new Route("/c/:tenancyId/supplier/tax-setup/tax-registered", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const SupplierTaxSetupReviewProducts = new Route("/c/:tenancyId/supplier/tax-setup/review-products", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const SupplierTaxSetupMissingDetails = new Route("/c/:tenancyId/supplier/tax-setup/missing-details", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);
export const GenericProductTaxSetup = new Route("/c/:tenancyId/supplier/tax-setup/all-products", [
  Permission.SupplierViewProducts,
  Permission.UpdateProduct,
  Permission.SupplierUpdateSettings
]);

export const SupplierDeliveryGroups = new Route("/c/:tenancyId/supplier/fulfilment-groups", [
  Permission.SupplierViewDeliveryGroups
]);
export const SupplierDeliveryGroupsGate = new Route("/c/:tenancyId/supplier/fulfilment-groups/intro");
export const SupplierDeliveryGroup = new Route("/c/:tenancyId/supplier/fulfilment-groups/:deliveryGroupId", [
  Permission.SupplierViewDeliveryGroups
]);
export const SupplierDeliveryGroupChooseCustomers = new Route(
  "/c/:tenancyId/supplier/fulfilment-groups/:deliveryGroupId/choose-customers",
  [Permission.SupplierViewDeliveryGroups, Permission.SupplierUpdateDeliveryGroups]
);

/**
 * Return whether or not the path is one that is exclusive to the supplier app.
 * This will return true for any route starting with `/c/:tenancyId/supplier`,
 * plus a handful of other supplier routes that don't have that path prefix.
 *
 * Note that those other routes really should be changed to start with
 * `/c/:tenancyId/supplier`.
 */
export function isSupplierRoute(pathname: string): boolean {
  return [SupplierRoot, SupplierProducts, SupplierIntegrationsSettings, SupplierOrderSettings].some(route =>
    route.matches(pathname)
  );
}

// COMMON ROUTES (eg: settings pages)
// -----------------------------------------------------------------------------

export const Auth0Callback = new Route("/auth0-callback");

export const Settings = new Route("/c/:tenancyId/settings");
export const BusinessProfile = new Route("/c/:tenancyId/settings/business-profile", [Permission.ViewCompanyProfile]);
export const BusinessProfilePreview = new Route("/c/:tenancyId/settings/business-profile/preview", [
  Permission.ViewCompanyProfile
]);
export const Notifications = new Route("/c/:tenancyId/settings/notifications");
export const PersonalProfile = new Route("/c/:tenancyId/settings/personal-profile");
export const Users = new Route("/c/:tenancyId/users", [Permission.ViewUsers]);
export const ViewUserRoute = new Route("/c/:tenancyId/users/user/:userId", [Permission.ViewUsers]);
export const ViewUserInviteRoute = new Route("/c/:tenancyId/users/invite/:inviteId", [Permission.ViewUserInvites]);
export const PlansPricing = new Route("/c/:tenancyId/settings/plans-and-pricing", [Permission.ViewBilling]);
export const NotificationForwardingRules = new Route("/c/:tenancyId/settings/notification-forwarding", [
  Permission.SupplierViewNotificationForwardingSettings
]);

export const CompanyOnboardingRoot = new Route("/c/:tenancyId/onboarding");
export const OnboardingPersonalProfile = new Route("/c/:tenancyId/onboarding/personal-profile");
export const OnboardingBusinessProfile1 = new Route("/c/:tenancyId/onboarding/business-profile-1", [
  Permission.UpdateCompanyProfile
]);
export const OnboardingBusinessProfile2 = new Route("/c/:tenancyId/onboarding/business-profile-2", [
  Permission.UpdateCompanyProfile
]);
export const OnboardingNewUserWelcome = new Route("/c/:tenancyId/onboarding/new-user-welcome");

// AUTHENTICATED BUT NOT COMPANY ROUTES /a
// -----------------------------------------------------------------------------
// Unacceptable fallback routes

export const AuthenticatedOnboarding = new Route("/a/onboarding");
export const AuthenticatedOnboardingAcceptInvite = new Route("/a/onboarding/accept-invite");
export const AuthenticatedOnboardingInviteAccepted = new Route("/a/onboarding/invite-accepted");
export const AuthenticatedOnboardingInviteForDifferentAccount = new Route("/a/onboarding/invite-for-different-account");

export const Signup = new Route("/a/signup");
export const SignupWelcome = new Route("/a/signup/welcome");
export const SignupBuyerBusinessContact = new Route("/a/signup/buyer-business-contact");
export const SignupBuyerBusinessProfile = new Route("/a/signup/buyer-business-profile");
export const SignupBuyerCustomerStatus = new Route("/a/signup/buyer-customer-status");
export const SignupBuyerSetup = new Route("/a/signup/buyer-setup");
export const SignupSupplierBusinessContact = new Route("/a/signup/supplier-business-contact");
export const SignupSupplierSetup = new Route("/a/signup/supplier-setup");
export const SignupSupplierBusinessDetails = new Route("/a/signup/supplier-business-details");
export const SignupBothBusinessContact = new Route("/a/signup/both-business-contact");
export const SignupBothSetup = new Route("/a/signup/both-setup");
export const SignupBothBusinessDetails = new Route("/a/signup/both-business-details");
export const SignupActivateAccount = new Route("/a/signup/activate-account");
export const SignupConsumerAccount = new Route("/a/signup/consumer-account");

export const ExistingAccount = new Route("/a/existing-account");

export const AcceptClaim = new Route("/a/:tenancyId/accept-claim");
export const ClaimCompany = new Route("/a/:tenancyId/claim"); // N.B. different from /accept-claim since it works anywhere, not just from external orders. We should probably look at deprecating /accept-claim in favour of this one.
export const PendingInvite = new Route("/a/:tenancyId/pending-invite");
export const ClaimedRedirect = new Route("/a/:tenancyId/claimed-redirect");

export const ConnectXeroCallback = new Route("/a/connect/xero");
export const ConnectCodatCallback = new Route("/a/connect/codat");
export const ConnectIdentityCallbackRoute = new Route("/a/connect/identity");
export const ConnectStripeCallback = new Route("/a/connect/stripe");

export const AuthenticatedError = new Route("/a/error");
export const ConfirmEmail = new Route("/a/confirm-email");
export const ConfirmEmailError = new Route("/a/confirm-email-error");

export const NoCompanyAccess = new Route("/a/no-company-access");
export const NewUserRoute = new Route("/a/new-user");

export const StoreBuyerNewOrderCallback = new Route("/a/store-buyer-order-locally");

/**
 * Return whether or not the path is one for an authenticated route.
 */
export function isAuthenticatedWithoutCompanyRoute(pathname: string): boolean {
  return pathname.startsWith("/a/");
}

// UNAUTHENTICATED /u
// -----------------------------------------------------------------------------
// Unacceptable fallback routes

export const OnboardingRoot = new Route("/u/onboarding");
export const OnboardingChooseInviteUser = new Route("/u/onboarding/choose-invite-user");
export const OnboardingInviteAccepted = new Route("/u/onboarding/invite-accepted");
export const OnboardingInviteExpired = new Route("/u/onboarding/invite-expired");

export const UnauthenticatedOrder = new Route("/u/order/:supplierId/:viewId");
export const UnauthenticatedOrderPrintInvoice = new Route("/u/buyer/:supplierId/:viewId/print-invoice");
export const UnauthenticatedOrderPaymentConfirmation = new Route("/u/order/:supplierId/:viewId/payment-confirmation");

export const Logout = new Route("/u/logout");
export const UnauthenticatedError = new Route("/u/error");

export const SignupReferral = new Route("/u/signup/referral");
export const XeroSignupReferral = new Route("/u/signup/referral/xero");

// MOBILE ROUTES
// -----------------------------------------------------------------------------
// Unacceptable fallback routes

export const MobileWelcome = new Route("/u/mobile-welcome");

/**
 * Return whether or not the path is one for an unauthenticated route.
 */
export function isUnauthenticatedRoute(pathname: string): boolean {
  return pathname.startsWith("/u/");
}
export function isUnbrandedRoute(pathname: string): boolean {
  return pathname.includes("/u/order") || pathname.includes("/u/buyer");
}

// EXTERNAL /ext
// -----------------------------------------------------------------------------
// Unacceptable fallback routes

export const BuyerNewOrderExternal = new Route("/ext/:tenancyId/buyer/order/new");
export const BuyerOrdersExternal = new Route("/ext/:tenancyId/buyer/orders");
export const BuyerViewOrderExternal = new Route("/ext/:tenancyId/buyer/order/:orderId");

export const BuyerDirectoryOldExternal = new Route("/ext/:tenancyId/buyer/directory");
export const BuyerSupplierExternal = new Route("/ext/:tenancyId/buyer/suppliers/:supplierId");
export const BuyerPaymentManagementExternal = new Route("/ext/:tenancyId/settings/buyer/payments/management");
// TODO: This should really be /buyer/, but will require finding what external source creates this link
export const BuyerSuppliersExternal = new Route("/ext/:tenancyId/buyers/suppliers");

export const SupplierNewOrderExternal = new Route("/ext/:tenancyId/supplier/order/new");
export const SupplierOrdersExternal = new Route("/ext/:tenancyId/supplier/orders");
export const SupplierViewOrderExternal = new Route("/ext/:tenancyId/supplier/order/:orderId");
export const SupplierViewOrderXeroRedirect = new Route("/:tenancyId/supplier/order/:orderId");
export const SupplierPendingInvitesExternal = new Route("/ext/:tenancyId/suppliers/view-pending-invites");
export const SupplierPriceListsExternal = new Route("/ext/:tenancyId/supplier/price-lists");
export const SupplierCustomersExternal = new Route("/ext/:tenancyId/supplier/customers");

export const NotificationsExternal = new Route("/ext/:tenancyId/notifications");

export const SupplierReportsExternal = new Route("/ext/:tenancyId/supplier/reports");
export const SupplierReportsProductionListExternal = new Route("/ext/:tenancyId/reports/production-list");

export const ConfirmEmailExternal = new Route("/ext/confirm-email");

export const UnauthenticatedOrderExternal = new Route("/ext/buyer/order/:supplierId/:viewId");
export const UnauthenticatedOrderQrCodeExternal = new Route("/ext/ps-qr/:supplierId/:viewId");
export const UnauthenticatedOrderPrintInvoiceExternal = new Route("/ext/buyer/:supplierId/:viewId/print-invoice");

export const AcceptInviteExternal = new Route("/ext/accept-invite");

// Will work out what the company type is and redirect to the correct new order page retaining any search params passed.
export const GenericCompanyNewOrder = new Route("/ext/order/new");

export const UsersExternal = new Route("/ext/:tenancyId/users");
export const UsersExternalSettings = new Route("/ext/:tenancyId/settings/users"); // changed with ORD-3371: https://upstock.atlassian.net/browse/ORD-3371
export const SupplierOrderSettingsExternal = new Route("/ext/:tenancyId/settings/orders");
export const BusinessProfileExternal = new Route("/ext/:tenancyId/settings/business-profile");

export const BuyerFavouritesExternal = new Route("/ext/:tenancyId/buyer/favourites");

export const BuyerNewsfeedExternal = new Route("/ext/:tenancyId/buyer/newsfeed");
export const SupplierNewsfeedExternal = new Route("/ext/:tenancyId/supplier/newsfeed");

export const SupplierDeliveryGroupsExternal = new Route("/ext/:tenancyId/supplier/fulfilment-groups");

export const SupplierProductsExternal = new Route("/ext/:tenancyId/products");

export const SignupExternal = new Route("/ext/signup");
export const SignupExternalQrCode = new Route("/ext/signup-qr");
export const AcceptClaimExternal = new Route("/ext/:tenancyId/accept-claim");
export const ClaimCompanyExternal = new Route("/ext/:tenancyId/claim");
export const ClaimedRedirectExternal = new Route("/ext/:tenancyId/claimed-redirect");

export const PlansPricingExternal = new Route("/ext/plans-and-pricing");

export const SupplierIntegrationsExternal = new Route("/ext/:tenancyId/settings/integrations");

/**
 * Return whether or not the path is one for an external route.
 */
export function isExternalRoute(pathname: string): boolean {
  return pathname.startsWith("/ext/");
}
