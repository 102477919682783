import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { inboxQueryKey } from "./keys";

export type RequestBody = Supplier.V1MailboxInboxMessagesDelete.RequestBody;
type RequestQuery = Supplier.V1MailboxInboxMessagesDelete.RequestQuery;
type RequestParamaters = Supplier.V1MailboxInboxMessagesDelete.RequestParams;
export type ResponseBody = Supplier.V1MailboxInboxMessagesDelete.ResponseBody;

export const deleteInboxMessages = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParamaters>(
  _ => ({
    method: "DELETE",
    path: safeurl`/supplier/v1/mailbox/inbox/messages`
  })
);

const useDeleteInboxMessages = (): UseMutationResult<ResponseBody, FetchV2Error, RequestBody> => {
  const apiFetch = useApiFetch();
  const client = useQueryClient();

  return useMutation({
    mutationKey: inboxQueryKey.delete().queryKey,
    mutationFn: async body => {
      const response = await apiFetch(deleteInboxMessages({}, { data: body }));
      return response.data;
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: inboxQueryKey.list({}).queryKey });
    }
  });
};

export default useDeleteInboxMessages;
