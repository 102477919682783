import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import useAppSelector from "utils/hooks/useAppSelector";
import { show } from "utils/Intercom";

import strings from "./strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start"
    }
  },
  icon: {
    width: "50px",
    height: "50px",
    margin: theme.spacing(0, 2, 2)
  }
}));

/**
 * Message to the user when an requested invite is pending.
 */
const PendingInvite: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { tradingName } = useAppSelector(state => state.tenancy);

  return (
    <Page
      containerClassName={cx(classes.container)}
      containerMaxWidth="sm"
      header={<SkeletonHeader backgroundColor="white" />}
      displayNav={false}
    >
      <LogoIcon className={cx(classes.icon)} />
      <Typography variant="h1" paragraph>
        <FormattedMessage id={messages.REQUESTPENDING} />
      </Typography>
      <Typography paragraph>
        <FormattedMessage id={messages.AREQUESTTOJOIN} values={{ tradingName }} />
      </Typography>
      <Typography paragraph>
        <FormattedMessage
          id={messages.IFYOUHAVEQUESTIONSTEXT}
          values={{
            getInTouch: (text: string) => (
              <Button displayAsLink onClick={show}>
                {text}
              </Button>
            )
          }}
        />
      </Typography>
    </Page>
  );
};

export default PendingInvite;
