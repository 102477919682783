import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { LinkedSupplierStats } from "types/api/generated/buyer";

import { fetchSuppliersStatsFailure, fetchSuppliersStatsSuccess } from "../reducers/suppliersStats";

/**
 * Calls the stats API endpoint /buyer/v1/suppliers/stats for suppliers as a buyer.
 */
function fetchSuppliersStats(auth: Auth0ContextInterface, tenancyId: string): Promise<LinkedSupplierStats> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/stats`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Saga to call the `/buyer/v1/suppliers/stats` endpoint to get a count of the
 * different buyer's supplier groups.
 */
export default createSaga(fetchSuppliersStats, {
  successAction: fetchSuppliersStatsSuccess,
  failureAction: fetchSuppliersStatsFailure
});
