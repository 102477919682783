import { TypeHandler } from "@ultraq/icu-message-formatter";
import { ReactNode } from "react";

/**
 * Formats a list of strings in a human readable way. e.g:  value1, value2 and value3
 * An additional format can also be passed that's applied to each item in the list.
 *
 * @example
 * const values = ["value1", "value2", "value3"];
 * "{values, list}" = "value1, value2 and value3"
 * "{values, list, <strong>{value}</strong>}" = "<strong>value1</strong>, <strong>value2</strong> and <strong>value3</strong>"
 */
const listTypeHandler: TypeHandler = (value, matches, _, __, process): ReactNode => {
  // Allows additional formatting to be applied to each list item (like adding <strong> tags)
  const formatting = matches || "{value}";

  if (!Array.isArray(value) || value.length === 0) {
    return;
  }
  if (value.length === 1) {
    return process(formatting, { value: value[0] });
  }
  if (value.length > 1) {
    const start = value.slice(0, -1).map(v => process(formatting, { value: v }).join(""));
    const end = process(formatting, { value: value[value.length - 1] }).join("");
    return `${start.join(", ")} and ${end}`;
  }
};

export default listTypeHandler;
