import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import ColouredChip from "components/Chip/ColouredChip";
import useMessages from "i18n/hooks/useMessages";
import { LocalOrderLineProblemType } from "types/Orders";
import useCountryConfig from "utils/hooks/useCountryConfig";

import strings from "./LineProblemChip.strings.json";

// UI only problem
export const LINE_PROBLEM_MULTIPLE = "ProblemMultiple";

export interface LineProblemChipProps {
  type: LocalOrderLineProblemType | typeof LINE_PROBLEM_MULTIPLE;
  problemsCount?: number;
}

/**
 * Chip displays the line problem test at either red for errors, yellow for
 * warnings or the default grey colours
 */
const LineProblemChip: FunctionComponent<LineProblemChipProps> = ({ type, problemsCount = 1 }) => {
  const messages = useMessages(strings);
  const { salesTaxName } = useCountryConfig();

  return (
    <ColouredChip
      color="red"
      label={
        type === "ProductOutOfStock" ? (
          <FormattedMessage id={messages.OUTOFSTOCK} />
        ) : type === "ProductArchived" ? (
          <FormattedMessage id={messages.DISCONTINUED} />
        ) : type === "DifferentPrice" ? (
          <FormattedMessage id={messages.PRICEMISMATCH} />
        ) : type === "ProductHidden" ? (
          <FormattedMessage id={messages.PRODUCTHIDDEN} />
        ) : type === "NoTaxRatesCoded" ? (
          <FormattedMessage id={messages.NO_TAX_RATES_CODED} values={{ salesTaxName }} />
        ) : type === "ProductDeleted" ? (
          <FormattedMessage id={messages.PRODUCTDELETED} />
        ) : type === "ProviderProductNotMatched" ? (
          <FormattedMessage id={messages.PRODUCTNOTMATCHED} />
        ) : type === "MissingBasePrice" ? (
          <FormattedMessage id={messages.MISSINGBASEPRICE} />
        ) : type === "ProductNotFound" ? (
          <FormattedMessage id={messages.PRODUCTNOTFOUND} />
        ) : type === "UnitAmountLostPrecision" ? (
          <FormattedMessage id={messages.UNIT_AMOUNT_LOST_PRECISION} />
        ) : type === "QuantityDidNotMatch" || type === "QuantityLostPrecision" ? (
          <FormattedMessage id={messages.QUANTITY_MISMATCH} />
        ) : type === "LineAmountDidNotMatch" ? (
          <FormattedMessage id={messages.LINE_AMOUNT_MISMATCH} />
        ) : type === "ProblemMultiple" ? (
          <FormattedMessage id={messages.WARNINGS} values={{ problemsCount }} />
        ) : (
          <FormattedMessage id={messages.UNAVAILABLE} />
        )
      }
    />
  );
};

export default LineProblemChip;
