import SvgIcon from "@mui/material/SvgIcon";
import { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  iconAndTitle: {
    alignItems: "center",
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

interface ModalIconAndTitleProps {
  Icon: typeof SvgIcon;
  title: ReactNode;
}

/**
 * Text accompanied by an icon, for use in a modal/dialog title context.
 */
export default function ModalIconAndTitle({ Icon, title }: ModalIconAndTitleProps): JSX.Element {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.iconAndTitle)}>
      <Icon className={cx(classes.icon)} />
      {title}
    </div>
  );
}
