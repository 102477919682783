import { UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import useCompleteGettingStartedAction, {
  CompleteGettingStartedRequest
} from "features/getting-started/useCompleteGettingStartedAction";
import useGettingStarted, { gettingStartedQueryKey } from "features/getting-started/useGettingStarted";
import { FetchV2Error } from "services/fetchV2";
import { GettingStartedAction, MenuGroupType } from "types/api/generated/directory-internal";
import { trackError } from "utils/Errors";
import useAppSelector from "utils/hooks/useAppSelector";

import { remainingGettingStartedActions } from "./Actions";

type SupplierGettingStartedResult = {
  actions: GettingStartedAction[];
  completeAction: UseMutateFunction<void, FetchV2Error, CompleteGettingStartedRequest>;
  remainingActions: GettingStartedAction[];
};

/**
 * React hook for accessing the Getting Started features for supplier companies.
 */
export default function useSupplierGettingStarted(fetchGettingStarted = true): SupplierGettingStartedResult {
  const { actions: initialActions } = useAppSelector(state => state.supplierGettingStarted);
  const { data } = useGettingStarted({
    type: MenuGroupType.Supplier,
    enabled: fetchGettingStarted
  });

  const actions = useMemo(() => data?.actions ?? initialActions, [data?.actions, initialActions]);
  const remainingActions = useMemo(() => remainingGettingStartedActions(actions), [actions]);

  const { mutate: completeGettingStartedAction } = useCompleteGettingStartedAction();
  const queryClient = useQueryClient();
  const completeAction = useCallback(
    (options: CompleteGettingStartedRequest) => {
      completeGettingStartedAction(options, {
        onSuccess() {
          queryClient.invalidateQueries(gettingStartedQueryKey.list({ type: MenuGroupType.Supplier }).queryKey);
        },
        onError(error) {
          trackError(error);
        }
      });
    },
    [completeGettingStartedAction, queryClient]
  );

  return useMemo(
    () => ({
      actions,
      completeAction,
      remainingActions
    }),
    [actions, completeAction, remainingActions]
  );
}
