import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import useHasPermission from "auth/useHasPermission";
import usePermissions from "auth/usePermissions";
import useGettingStarted from "features/getting-started/useGettingStarted";
import { isSupplierOnboarding } from "features/suppliers/getting-started/useIsSupplierOnboarding";
import Route from "routes/Route";
import {
  BuyerGettingStarted,
  BuyerOrders,
  OnboardingBusinessProfile1,
  OnboardingBusinessProfile2,
  OnboardingNewUserWelcome,
  OnboardingPersonalProfile,
  SupplierGettingStarted,
  SupplierOrdersGridLayout
} from "routes/Routes";
import { Permission } from "types/api";
import { GettingStartedAction, GettingStartedActionType, MenuGroupType } from "types/api/generated/directory-internal";
import { logger } from "utils/Datadog";
import useAppSelector from "utils/hooks/useAppSelector";

// Key for local storage value
const CURRENT_FLOW_KEY = "currentOnboardingFlow";

type OnboardingFlow = Route[];

const BuyerNewSignupFlow: OnboardingFlow = [BuyerGettingStarted];

const BuyerFlow: OnboardingFlow = [OnboardingPersonalProfile, BuyerGettingStarted];

// Supplier first time
const SupplierFirstTime: OnboardingFlow = [
  OnboardingPersonalProfile,
  OnboardingBusinessProfile1,
  OnboardingBusinessProfile2,
  SupplierGettingStarted
];

// Supplier subsequent user
const SupplierSubsequentUser: OnboardingFlow = [
  OnboardingNewUserWelcome
  // Finish with Getting Started or Orders, determined below
];

// Supplier with the limited role
const LimitedSupplier: OnboardingFlow = [OnboardingNewUserWelcome, SupplierOrdersGridLayout];

const SupplierNewSignupFlow: OnboardingFlow = [SupplierGettingStarted];

type DetermineFlowFunction = (actions?: GettingStartedAction[], useNewSignupFlow?: boolean) => Promise<string>;

interface OnboardingFlowManagementResult {
  activeStep: number;
  getFirstOnboardingFlowPath: DetermineFlowFunction;
  isLoading: boolean;
  next: () => string;
  previous: () => string;
  stepTotal: number;
}

/**
 * Hook to decide which step is next in the flow, what number step they're
 * on in the flow and what the previous step is.
 */
export default function useOnboardingFlowManagement(): OnboardingFlowManagementResult {
  const location = useLocation();
  const { companyProfileLoading, isSupplier, companyId: tenancyId } = useAppSelector(state => state.tenancy);
  const [currentFlow, setCurrentFlow, removeCurrentFlow] = useLocalStorage<string[]>(CURRENT_FLOW_KEY, []);
  const [currentStepPosition, setCurrentStepPosition] = useState(0);
  const { loading: permissionsLoading } = usePermissions();
  const canUpdateProfilePermission = useHasPermission([Permission.UpdateCompanyProfile]);
  const canAccessGettingStarted = useHasPermission([Permission.ViewGettingStarted]);

  const isLoading = companyProfileLoading || permissionsLoading;

  const { refetch } = useGettingStarted({
    type: MenuGroupType.Supplier,
    enabled: isSupplier,
    tenancyId
  });

  useEffect(() => {
    if (currentFlow) {
      setCurrentStepPosition(currentFlow.indexOf(location.pathname));
    }
  }, [currentFlow, location.pathname]);

  /**
   * Figure out if we should end the supplier flow with the getting started or
   * orders page, based on whether the 'set up' getting started actions have
   * been completed already or not.
   *
   * @return
   *   The updated supplier flow with an appropriate final route added to it.
   */
  const shouldEndSupplierWithGettingStartedOrOrders = useCallback(
    async (flow: OnboardingFlow): Promise<OnboardingFlow> => {
      const { data, isSuccess } = await refetch();
      return flow.concat(
        isSuccess && isSupplierOnboarding(data?.actions) ? SupplierGettingStarted : SupplierOrdersGridLayout
      );
    },
    [refetch]
  );

  const routeToUrl = useCallback((route: Route): string => route.toUrl({ tenancyId }), [tenancyId]);

  /**
   * Determine the onboarding flow the current user is going through, setting it
   * in `localStorage` so that it can be fetched through multiple pages or page
   * reloads, and returning a Promise of the first path of that flow.
   */
  const determineFlow: DetermineFlowFunction = useCallback(
    async (actions = [], useNewSignupFlow = false) => {
      // Need to wait for company profile to load to determine if they are a buyer or supplier.
      if (isLoading) {
        return "";
      }

      // If the company hasn't had it's profile checked.
      const hasNotCompletedFirstStep =
        !!actions?.length &&
        !actions.find(({ actionType }) => actionType === GettingStartedActionType.CheckProfile)?.isCompleted;

      let flow;

      // Supplier flows
      if (isSupplier) {
        if (useNewSignupFlow) {
          flow = SupplierNewSignupFlow;
        } else if (!canAccessGettingStarted) {
          flow = LimitedSupplier;
        } else if (hasNotCompletedFirstStep && canUpdateProfilePermission) {
          flow = SupplierFirstTime;
        } else {
          flow = await shouldEndSupplierWithGettingStartedOrOrders(SupplierSubsequentUser);
        }
      }
      // Buyer flows
      else {
        // eslint-disable-next-line no-lonely-if
        if (useNewSignupFlow) {
          flow = BuyerNewSignupFlow;
        } else {
          flow = BuyerFlow;
        }
      }

      const flowAsUrls = flow.map(routeToUrl);
      setCurrentFlow(flowAsUrls);
      return flowAsUrls[0]!;
    },
    [
      canAccessGettingStarted,
      canUpdateProfilePermission,
      isLoading,
      isSupplier,
      routeToUrl,
      setCurrentFlow,
      shouldEndSupplierWithGettingStartedOrOrders
    ]
  );

  return useMemo(
    () => ({
      isLoading,
      getFirstOnboardingFlowPath: determineFlow,
      next: () => {
        // In the case we have no current flow entry we fallback to redirecting
        // to the orders page. A user will get into this case if they manually
        // navigate here.
        if (!currentFlow || !currentFlow.length) {
          currentFlow && removeCurrentFlow(); // Gets set when the hook starts but we're exiting onboarding so remove it.
          return routeToUrl(isSupplier ? SupplierOrdersGridLayout : BuyerOrders);
        }

        // Clear local storage if we're on the last route in the flow
        if (currentStepPosition === currentFlow.length - 1) {
          removeCurrentFlow();
        }

        let nextRoute =
          currentStepPosition >= currentFlow?.length
            ? null // Theres nowhere to go forward from here
            : currentFlow[currentStepPosition + 1];

        // Special case for buyers who still have the old pricing route in their localstorage 🙃
        if (nextRoute?.includes("/pricing")) {
          logger.info(`User has old /pricing route in localstorage: ${currentFlow}`);
          nextRoute = currentFlow[currentStepPosition + 2] ?? routeToUrl(BuyerOrders);
        }
        return nextRoute!;
      },
      previous: () => {
        // Assumes that a current flow must exist to determine a previous step
        return (currentStepPosition > 0 ? currentFlow![currentStepPosition - 1] : currentFlow![0])!;
      },
      activeStep: currentStepPosition || 0,
      stepTotal: currentFlow && currentFlow?.length > 0 ? currentFlow.length - 1 : 0
    }),
    [currentFlow, currentStepPosition, determineFlow, isLoading, isSupplier, removeCurrentFlow, routeToUrl]
  );
}
