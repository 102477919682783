import { ActionCreatorWithPayload, Dispatch } from "@reduxjs/toolkit";

import { RequestPayload } from "types/redux-helpers";

/**
 * Convert a saga that has `successCallback` and `failureCallback` options in
 * the action payload, to a `Promise`.
 */
export function promisifySaga<P extends Record<string, any>, R, E>(
  dispatch: Dispatch,
  action: ActionCreatorWithPayload<RequestPayload<P, R, E>>
) {
  return (args: P) => {
    return new Promise<R>((resolve, reject) => {
      dispatch(
        action({
          ...args,
          successCallback: resolve,
          failureCallback: reject
        })
      );
    });
  };
}
