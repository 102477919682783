import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { ElementType, useContext, useEffect, VoidFunctionComponent } from "react";
import { RouteProps, useLocation } from "react-router-dom";
import { useShallowCompareEffect } from "react-use";

import useHasPermission from "auth/useHasPermission";
import LoadingScreen from "pages/LoadingScreen";
import NoAccessOverlay from "pages/NoAccessOverlay";
import { FallbackRouteContext } from "routes/FallbackRouteProvider";
import Route from "routes/Route";
import { isCompanyRoute, MobileWelcome, Root } from "routes/Routes";
import TrackedRoute from "routes/TrackedRoute";
import { Permission } from "types/api";
import { Feature } from "types/api/generated/global-internal";
import useHasBillingFeature from "utils/hooks/useHasBillingFeature";

import usePermissions from "./usePermissions";

export interface PrivateRouteProps extends RouteProps {
  loginOptions?: RedirectLoginOptions;
  permissions?: Permission[];
  fallbackRoute?: Route;
  billingFeature?: Feature;
  BillingFeatureGate?: ElementType;
}
/**
 * Wraps components with authentication checks to make sure only authenticated
 * and authorized users can view these pages.
 *
 * The auth0 examples make use of a HOC, `withAuthenticationRequired`, but you
 * can only load the `loginOptions` of that part statically, not through props.
 * So this component replicates what `withAuthenticationRequired` does, but with
 * being able to pass `loginOptions` as a prop.
 */
const PrivateRoute: VoidFunctionComponent<PrivateRouteProps> = ({
  loginOptions = {},
  permissions: requiredPermissions,
  fallbackRoute = Root,
  billingFeature,
  BillingFeatureGate,
  ...routeProps
}) => {
  const { pathname, search, hash } = useLocation();
  const hasAccessToBillingFeature = useHasBillingFeature(billingFeature);
  const hasRequiredPermissions = useHasPermission(requiredPermissions);
  const { setFallbackRoute } = useContext(FallbackRouteContext);
  const { isAuthenticated, isLoading: authLoading, loginWithRedirect } = useAuth0();
  const { hasCompanyAccess, loading: permissionsLoading } = usePermissions();

  const loading = authLoading || permissionsLoading;
  const { children, ...otherRouteProps } = routeProps;
  const considerCompanyAccess = isCompanyRoute(pathname) ? hasCompanyAccess : true;

  useEffect(() => {
    setFallbackRoute(fallbackRoute);
  }, [setFallbackRoute, fallbackRoute]);

  useShallowCompareEffect(() => {
    if (!isAuthenticated && !authLoading) {
      if (Capacitor.isNativePlatform()) {
        window.location.href = MobileWelcome.toUrl();
      } else {
        loginWithRedirect({
          ...loginOptions,
          appState: {
            ...loginOptions.appState,
            returnTo: pathname + search + hash
          }
        });
      }
    }
  }, [authLoading, isAuthenticated, loginOptions, loginWithRedirect]);

  if (isAuthenticated && considerCompanyAccess && !loading && !hasAccessToBillingFeature && BillingFeatureGate) {
    return <BillingFeatureGate feature={billingFeature} />;
  }

  if (isAuthenticated && considerCompanyAccess && !hasRequiredPermissions && !loading) {
    // If we're trying to access a route we don't have permission to, render an error page.
    return <NoAccessOverlay />;
  }

  return isAuthenticated && considerCompanyAccess && hasRequiredPermissions ? (
    <TrackedRoute {...otherRouteProps} />
  ) : (
    <LoadingScreen />
  );
};

export default PrivateRoute;
