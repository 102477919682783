import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, MouseEventHandler, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Header, { HeaderProps } from "components/Header";
import { isPathAcceptableFallback } from "routes/blacklistFallbackRoutes";
import { FallbackRouteContext } from "routes/FallbackRouteProvider";
import { useAnalytics } from "utils/analytics";

import strings from "./SimpleHeader.strings.json";

const useStyles = makeStyles()(theme => ({
  leftIcon: {
    display: "flex"
  },
  button: {
    padding: theme.spacing(1),
    left: theme.spacing(-1)
  },
  title: {
    fontWeight: 700,
    marginLeft: theme.spacing(1)
  },
  red: {
    color: theme.palette.common.white
  },
  white: {
    color: theme.palette.primary.main
  }
}));

interface Params {
  tenancyId: string;
}

export interface SimpleHeaderProps extends HeaderProps {
  onBackClick?: MouseEventHandler<HTMLButtonElement>;
}

const SimpleHeader: FunctionComponent<SimpleHeaderProps> = ({ title = "", onBackClick, variant = "red", ...other }) => {
  const { classes, cx } = useStyles();
  const { formatter } = useMessageFormatter();
  const history = useHistory();
  const { tenancyId, ...others } = useParams<Params>();
  const analytics = useAnalytics();

  const { fallbackRoute, previousPathname } = useContext(FallbackRouteContext);

  const onBackClickDefault = (): void => {
    // detect if the previous link is an acceptable path to go back to
    if (history.length > 1 && !!previousPathname && isPathAcceptableFallback(previousPathname)) {
      history.goBack();
    } else {
      if (fallbackRoute) {
        history.push(fallbackRoute.toUrl({ tenancyId, ...others }));
        return;
      }
      history.goBack(); // incase a route doesn't have a fallback
    }
  };
  const handleOnBackClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (onBackClick) {
      onBackClick(e);
    } else {
      onBackClickDefault();
    }
    analytics.track("Back Button Clicked");
  };

  return (
    <Header
      LeftComp={
        <>
          <IconButton
            onClick={handleOnBackClick}
            className={cx(classes.button, classes[variant])}
            aria-label={formatter.format(strings.BACK_ACTION)}
            data-testid="cancel-action"
            size="large"
          >
            <span className={classes.leftIcon}>
              <ArrowBack />
            </span>
          </IconButton>
          <Typography variant="h3" className={cx(classes.title)}>
            {title}
          </Typography>
        </>
      }
      title={title}
      variant={variant}
      {...other}
    />
  );
};

export default SimpleHeader;
