import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Supplier } from "types/api";
import { RequestAction } from "types/redux-helpers";

export interface PriceListStatsState {
  visibleProductCount: number;
  hiddenProductCount: number;
  buyerCount: number;
  loading: boolean;
}

const initialState: PriceListStatsState = {
  visibleProductCount: 0,
  hiddenProductCount: 0,
  buyerCount: 0,
  loading: true
};

export type FetchPriceListStatsRequest = RequestAction<
  { priceListId: string },
  Supplier.V1PricelistsStatsDetail.ResponseBody
>;
type FetchPriceListStatsSuccess = PayloadAction<Supplier.V1PricelistsStatsDetail.ResponseBody>;

/**
 * State slice for the counts associated with a price list.
 */
const priceListStatsSlice = createSlice({
  name: "priceListStats",
  initialState,
  reducers: {
    fetchPriceListStatsRequest: (state, _action: FetchPriceListStatsRequest) => ({
      ...state,
      loading: true
    }),
    fetchPriceListStatsSuccess: (state, { payload: stats }: FetchPriceListStatsSuccess) => ({
      ...state,
      ...stats,
      loading: false
    }),
    fetchPriceListStatsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const { fetchPriceListStatsRequest, fetchPriceListStatsSuccess, fetchPriceListStatsFailure } =
  priceListStatsSlice.actions;
export default priceListStatsSlice.reducer;
