import { FunctionComponent } from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "auth/PrivateRoute";
import { SupplierInboxMessage } from "routes/Routes";

import InboxMessageOrder from "./views/InboxMessageOrder";

const InboxFeature: FunctionComponent = () => {
  return (
    <Switch>
      <PrivateRoute exact path={SupplierInboxMessage.path} component={InboxMessageOrder} />
    </Switch>
  );
};

export default InboxFeature;
