import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

import { submitNewLeadFailure, SubmitNewLeadRequest, submitNewLeadSuccess } from "../reducers/pendingLeads";

/**
 * Calls `/buyer/v1/suppliers/pending/leads` to add a supplier lead for our
 * internal team to follow up with.
 */
export default createSagaNext<SubmitNewLeadRequest>(
  (auth0, tenancyId, payload) => {
    return postDataToApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/pending/leads`,
      tenancyId,
      payload
    );
  },
  {
    successAction: submitNewLeadSuccess,
    failureAction: submitNewLeadFailure
  }
);
