import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put, select } from "redux-saga/effects";

import { fetchDataFromApiWrapper } from "services/helpers";
import { LoadNextPageRequest } from "types/redux-helpers";

/**
 * Calls the API with the passed url to fetch the next page of paginated data
 */
export const fetchNextPage = (auth: Auth0ContextInterface, url: string, tenancyId: string): Promise<any> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}${url}`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
};

/**
 * Saga task for fetching data from the url passed to the saga form the action, then
 * placing it in the relevant state slice.
 */
export default function* loadNextPage(a: any): Generator {
  const action = a as LoadNextPageRequest;
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const tenancyId = (yield select(state => state.tenancy.companyId)) as string;
  const url = action.payload?.url;
  const overrideList = action.payload?.overrideList;
  const successCallback = action.payload?.successCallback;
  const failureCallback = action.payload?.failureCallback;

  try {
    if (!url) {
      throw new Error("No url provided");
    }
    const data = (yield call(fetchNextPage, auth, url, tenancyId)) as any;
    yield put(
      action.payload?.onSuccess({
        ...data,
        overrideList
      })
    );
    if (successCallback) {
      yield call(successCallback, data);
    }
  } catch (e) {
    yield put(action.payload?.onFailure(e));
    if (failureCallback) {
      yield call(failureCallback, e);
    }
    throw e;
  }
}
