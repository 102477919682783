import { Auth0ContextInterface } from "@auth0/auth0-react";

import { putDataToApiWrapper } from "services/helpers";
import { ModalToHide } from "types/api/generated/directory-internal";

import { HideModalsRequest, hideModalsSuccess } from "../actions/hideModals";

import { createSaga } from "./createSaga";

/**
 * Call `PUT` `/directory/internal/users/hide-modals` to set which modals should
 * be hidden for next time.
 */
function hideModals(auth0: Auth0ContextInterface, tenancyId: string, modals: ModalToHide[]): Promise<Response> {
  return putDataToApiWrapper(
    auth0,
    `${process.env.REACT_APP_API_URL}/directory/internal/users/hide-modals`,
    tenancyId,
    {
      modals
    }
  );
}

/**
 * Saga for calling the hide modals API endpoint.
 */
export default createSaga(hideModals, {
  prepareApiMethodParams: ({ modals }: HideModalsRequest["payload"]) => [modals],
  successAction: hideModalsSuccess
});
