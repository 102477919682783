import { Component, ErrorInfo, ReactNode } from "react";

import { trackError } from "utils/Errors";

export interface HeaderErrorBoundaryProps {
  children: ReactNode;
}
export interface HeaderErrorBoundaryState {
  hasError: boolean;
}

/**
 * Why is this a class component?
 *
 * Simply, `componentDidCatch` cannot be read as a hook at the moment.
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
export default class HeaderErrorBoundary extends Component<HeaderErrorBoundaryProps, HeaderErrorBoundaryState> {
  constructor(props: HeaderErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): Partial<HeaderErrorBoundaryState> {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    trackError(error, undefined, { componentStack: errorInfo.componentStack ?? undefined });
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <h1
          style={{
            fontSize: "1.5rem",
            position: "absolute",
            margin: "8px 16px 20px"
          }}
        >
          Uh oh, looks like something went wrong.
        </h1>
      );
    }

    return children;
  }
}
