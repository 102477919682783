import { ICache } from "@auth0/auth0-react";
import { Preferences } from "@capacitor/preferences";

/**
 * Implementation of the custom caching mechanism introduced in
 * auth0-react v1.6.0. This stores auth0 sessions using the
 * Capacitor storage API which is much more permanent than
 * window.localStorage on mobile devices.
 *
 * Capacitor Storage falls back to window.localStorage on web.
 */
const Auth0CustomCache: ICache = {
  set<T>(key: string, value: T) {
    return Preferences.set({ key, value: JSON.stringify(value) });
  },

  get<T>(key: string): Promise<T | undefined> {
    return Preferences.get({ key }).then(item => {
      return item.value ? JSON.parse(item.value) : undefined;
    });
  },

  remove(key: string) {
    return Preferences.remove({ key });
  }
};

export default Auth0CustomCache;
