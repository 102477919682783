import { Avatar, AvatarProps, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";
import { FunctionComponent, useLayoutEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import useMessages from "i18n/hooks/useMessages";

import strings from "./UserAvatar.strings.json";

const DEFAULT_SIZE = 40;

type Styles = {
  fontSize: number;
};

const useStyles = makeStyles<Styles>()((_, { fontSize }) => ({
  avatar: {
    backgroundColor: teal[500],
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE
  },
  initials: {
    fontSize,
    fontWeight: "bold"
  }
}));

export interface CompanyLogoProps extends AvatarProps {
  name?: string;
}

const UserAvatar: FunctionComponent<CompanyLogoProps> = ({ name, className, ...rest }) => {
  const avatar = useRef<HTMLDivElement>(null);
  const messages = useMessages(strings);
  const [width, setWidth] = useState<number>(DEFAULT_SIZE);

  const { classes, cx } = useStyles({ fontSize: width * 0.4 });

  useLayoutEffect(() => {
    setWidth(avatar.current?.offsetWidth ?? DEFAULT_SIZE);
  }, []);

  const getInitials = (userName: string): string => {
    const initials = userName
      .replace(/[^a-z0-9 ]/gi, "") // remove any special characters from the string (-,&'" etc)
      .replace(/ +(?= )/g, "") // remove any extra spaces within the name
      .trim() // remove any spaces from the start or end of the name
      .split(" ")
      .map(n => n[0]);

    return initials.join("").substring(0, 2).toUpperCase();
  };

  return (
    <Avatar ref={avatar} className={cx(classes.avatar, className)} alt={messages.ALT} {...rest}>
      {name ? <Typography className={classes.initials}>{getInitials(name)}</Typography> : null}
    </Avatar>
  );
};

export default UserAvatar;
