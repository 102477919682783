import { PayloadAction } from "@reduxjs/toolkit";

import { DeletePromptRequest, deletePromptFailure, deletePromptSuccess } from "redux/actions/deletePrompt";
import { deleteDataFromApiWrapper } from "services/helpers";

import createSagaNext from "./createSagaNext";

export default createSagaNext<PayloadAction<DeletePromptRequest>>(
  async (auth0, tenancyId, { type }) => {
    await deleteDataFromApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/directory/internal/users/actionable-prompt/${type}`,
      tenancyId
    );

    return type;
  },
  {
    successAction: deletePromptSuccess,
    failureAction: deletePromptFailure
  }
);
