import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PageLinks } from "types/api/generated/buyer-internal";
import { Supplier } from "types/api/generated/supplier";
import { PriceListSummary } from "types/api/generated/supplier-internal";
import { FailureAction, LoadNextPageRequest, PagingOptions, RequestAction, RequestPayload } from "types/redux-helpers";

export type AllPriceListsState = {
  error: string | Error | {};
  loading: boolean;
  list: PriceListSummary[];
  total?: number | null;
  links: PageLinks | null;
};

const initialState: AllPriceListsState = {
  loading: true,
  list: [],
  total: 0,
  links: null,
  error: {}
};

export type FetchAllPriceListsRequest = RequestAction<Supplier.V1PricelistsList.RequestQuery>;

type FetchAllPriceListsSuccess = PayloadAction<Supplier.V1PricelistsList.ResponseBody & PagingOptions>;
type LoadNextPriceListsPage = LoadNextPageRequest;
export type CreateDefaultPriceListRequest = RequestAction<
  Supplier.V1PricelistsDefaultCreate.RequestBody,
  Supplier.V1PricelistsDefaultCreate.ResponseBody
>;
type CreateDefaultPriceListSuccess = PayloadAction<Supplier.V1PricelistsDefaultCreate.ResponseBody>;
export type ActivatePriceListsRequest = PayloadAction<
  RequestPayload<{}, Supplier.V1PricelistsActivateCreate.ResponseBody> | undefined
>;
export type ActivatePriceListSuccess = PayloadAction<Supplier.V1PricelistsActivateCreate.ResponseBody>;

const allPriceLists = createSlice({
  name: "allPriceLists",
  initialState,
  reducers: {
    fetchAllPriceListsRequest: (state, _action: FetchAllPriceListsRequest) => ({ ...state, loading: true }),
    fetchAllPriceListsSuccess: (
      state,
      { payload: { data, links, total, overrideList = false } }: FetchAllPriceListsSuccess
    ) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      total,
      error: {},
      loading: false
    }),
    fetchAllPriceListsFailure: (state, { payload: err }) => ({
      ...state,
      error: err,
      loading: false
    }),
    loadNextPriceListsPage: (state, _action: LoadNextPriceListsPage) => ({ ...state, loading: true }),
    createDefaultPriceListRequest: (state, _action: CreateDefaultPriceListRequest) => ({ ...state, loading: true }),
    createDefaultPriceListSuccess: (state, { payload: defaultPriceList }: CreateDefaultPriceListSuccess) => ({
      ...state,
      list: [...state.list, defaultPriceList],
      loading: false
    }),
    createDefaultPriceListFailure: (state, { payload: err }: FailureAction) => ({
      ...state,
      error: err,
      loading: false
    }),
    activatePriceListsRequest: (state, _action: ActivatePriceListsRequest) => state,
    activatePriceListsSuccess: (state, _action: ActivatePriceListSuccess) => state,
    activatePriceListsFailure: (state, { payload: error }) => ({
      ...state,
      error
    })
  }
});

export const {
  fetchAllPriceListsRequest,
  fetchAllPriceListsSuccess,
  fetchAllPriceListsFailure,
  loadNextPriceListsPage,
  createDefaultPriceListRequest,
  createDefaultPriceListSuccess,
  createDefaultPriceListFailure,
  activatePriceListsRequest,
  activatePriceListsSuccess,
  activatePriceListsFailure
} = allPriceLists.actions;

export default allPriceLists.reducer;
