import { HelpOutline as HelpIcon } from "@mui/icons-material";
import { IconButton, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import * as CSS from "csstype";
import { FunctionComponent, JSX, memo, ReactNode } from "react";
import NumberFormat from "react-number-format";
import { CSSObject } from "tss-react";
import { makeStyles } from "tss-react/mui";

import Image from "components/Image/Image";
import { BuyerOrderLine } from "features/buyers/orders/new-order/reducers/types";
import useMessages from "i18n/hooks/useMessages";
import SmallRemoveIcon from "media/icon-remove-small.svg?react";
import { BuyerProductSummary } from "types/api/generated/buyer";
import { ProductUnavailableReason, SupplierProductSummary } from "types/api/generated/supplier";
import { LocalOrderLine } from "types/Orders";
import { useAnalytics } from "utils/analytics";
import useModal from "utils/hooks/useModal";
import { DECIMALSCALE } from "utils/numbers";

import Button from "./Button";
import CompanyLogo from "./CompanyLogo/CompanyLogo";
import NumberStepper from "./NumberStepper";
import strings from "./ProductItem.strings.json";
import ProductDetailsModal from "./Products/ProductDetailsModal";
import StackedPrice from "./StackedPrice";
import Tooltip from "./Tooltip";

function isBuyerProduct(
  product: SupplierProductSummary | BuyerProductSummary | BuyerOrderLine | LocalOrderLine
): product is BuyerProductSummary | BuyerOrderLine {
  return "supplierId" in product;
}

function hideLogoBreakpoint(theme: Theme): string {
  return theme.breakpoints.between("sm", 768);
}

function wrappingBreakpoint(theme: Theme): string {
  return theme.breakpoints.down(1280);
}

const useSkeletonStyles = makeStyles<{ forceMobileLayout?: boolean }>()((theme, { forceMobileLayout }) => {
  const rootMobile: CSS.Properties = {
    paddingBottom: theme.spacing(8)
  };
  const root: CSSObject = {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    ...(forceMobileLayout ? rootMobile : {}),
    [wrappingBreakpoint(theme)]: rootMobile
  };

  const logoMobile: CSS.Properties = {
    display: "none"
  };
  const logo: CSSObject = {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1.5),
    ...(forceMobileLayout ? logoMobile : {}),
    [hideLogoBreakpoint(theme)]: logoMobile
  };

  return {
    root,
    logo,
    text: {
      flex: 1
    },
    text1: {
      maxWidth: theme.spacing(30)
    },
    text2: {
      maxWidth: theme.spacing(20)
    }
  };
});

export interface ProductItemSkeletonProps {
  className?: string;
  forceMobileLayout?: boolean;
}

export const ProductItemSkeleton: FunctionComponent<ProductItemSkeletonProps> = ({ className, forceMobileLayout }) => {
  const { classes, cx } = useSkeletonStyles({ forceMobileLayout });
  return (
    <div className={cx(classes.root, className)}>
      <Skeleton className={cx(classes.logo)} variant="circular" />
      <div className={cx(classes.text)}>
        <Skeleton className={cx(classes.text1)} variant="text" />
        <Skeleton className={cx(classes.text2)} variant="text" />
      </div>
    </div>
  );
};

const useStyles = makeStyles<{ forceMobileLayout?: boolean }>()((theme, { forceMobileLayout }) => {
  const imageMobile: CSS.Properties = {
    display: "none"
  };
  const image: CSSObject = {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1.5),
    ...(forceMobileLayout ? imageMobile : {}),
    [hideLogoBreakpoint(theme)]: imageMobile
  };

  const contentMobile: CSS.Properties = {
    flexDirection: "column"
  };
  const content: CSSObject = {
    display: "flex",
    flex: 1,
    minWidth: 0,
    ...(forceMobileLayout ? contentMobile : {}),
    [wrappingBreakpoint(theme)]: contentMobile
  };

  const leftContentMobile: CSS.Properties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1)
  };
  const leftContent: CSSObject = {
    flex: 1,
    minWidth: 0,
    overflowWrap: "break-word",
    ...(forceMobileLayout ? leftContentMobile : {}),
    [wrappingBreakpoint(theme)]: leftContentMobile
  };

  const rightContentMobile: CSS.Properties = {
    flexWrap: "wrap"
  };
  const rightContent: CSSObject = {
    display: "flex",
    alignItems: "center",
    ...(forceMobileLayout ? rightContentMobile : {}),
    [wrappingBreakpoint(theme)]: rightContentMobile
  };

  const priceWrapperMobile: CSS.Properties = {
    marginLeft: 0
  };
  const priceWrapper: CSSObject = {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    ...(forceMobileLayout ? priceWrapperMobile : {}),
    [wrappingBreakpoint(theme)]: priceWrapperMobile
  };

  const pricesMobile: CSS.Properties = {
    flexDirection: "row"
  };
  const prices: CSSObject = {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    ...(forceMobileLayout ? pricesMobile : {}),
    [wrappingBreakpoint(theme)]: pricesMobile
  };

  const originalPriceMobile: CSS.Properties = {
    marginRight: 0,
    marginLeft: theme.spacing(1)
  };
  const originalPrice: CSSObject = {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "14px",
    lineHeight: 1,
    textDecoration: "line-through",
    ...(forceMobileLayout ? originalPriceMobile : {}),
    [wrappingBreakpoint(theme)]: originalPriceMobile
  };

  const mobileMenuMobile: CSS.Properties = {
    display: "block",
    marginRight: "-4px",
    marginTop: "-4px",
    marginLeft: theme.spacing(1)
  };
  const mobileMenu: CSSObject = {
    display: "none",
    ...(forceMobileLayout ? mobileMenuMobile : {}),
    [wrappingBreakpoint(theme)]: mobileMenuMobile
  };

  return {
    root: {
      position: "relative",
      display: "flex",
      padding: theme.spacing(2)
    },
    image,
    content,
    leftContent,
    rightContent,
    productName: {
      lineHeight: 1.25
    },
    subContent: {
      color: theme.palette.grey[600],
      lineHeight: 1.25
    },
    subContentSpacer: {
      margin: "3px"
    },
    viewDetailsButton: {
      textDecoration: "underline"
    },
    priceWrapper,
    prices,
    originalPrice,
    noPrice: {
      display: "inline-flex",
      alignItems: "center",
      color: theme.palette.grey[600],
      fontSize: theme.typography.caption.fontSize,
      cursor: "default",
      whiteSpace: "nowrap"
    },
    noPriceIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      margin: theme.spacing(0.5)
    },
    quantityButtons: {
      width: theme.spacing(15),
      height: theme.spacing(4.5),
      flexShrink: 0,
      marginLeft: "auto"
    },
    addButton: {
      padding: theme.spacing(0),
      borderColor: theme.palette.grey[300]
    },
    unavailableReason: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.grey[600],
      border: "1px solid",
      borderColor: theme.palette.grey[300],
      background: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.body2.fontSize,
      cursor: "default"
    },
    unavailableReasonTooltip: {
      display: "flex",
      alignItems: "center"
    },
    unavailableReasonIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginLeft: "6px"
    },
    removeButton: {
      position: "absolute",
      display: "flex",
      left: theme.spacing(-1),
      top: theme.spacing(-1),
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.grey[600],
      background: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      borderRadius: "100%",
      "& > *": {
        flex: 1,
        color: "inherit"
      }
    },
    mobileMenu
  };
});

export interface ProductItemProps<P> {
  className?: string;
  dataTestId?: string;

  /**
   * Supplied by buyer-side code to render the favourite heart/button alongside
   * a product item.
   */
  renderFavouriteButton?: (product: P) => ReactNode;

  /**
   * Used to force the mobile layout in places like modals where the media
   * queries don't work correctly
   */
  forceMobileLayout?: boolean;

  /**
   * Used to hide the price in cases where the API returns prices but we don't
   * want to show them (like when adding products to an order that was created
   * before price lists were enabled)
   */
  hidePrice?: boolean;

  isInOrder?: boolean;
  renderMobileMenu?: (product: P) => ReactNode;
  onAdd?: (product: P) => void;
  onRemove?: (product: P) => void;
  onQuantityChange?: (product: P, quantity: number) => void;
  product: P;
  quantity: number;

  /**
   * Used in places where a ProductSummary is being displayed but the OrderLine
   * price needs to be shown as well (like in the AddProductsModal)
   */
  unitAmount?: number;
}

function ProductItem<P extends SupplierProductSummary | BuyerProductSummary | BuyerOrderLine | LocalOrderLine>({
  className,
  dataTestId,
  renderFavouriteButton,
  forceMobileLayout,
  hidePrice,
  isInOrder,
  renderMobileMenu,
  onAdd,
  onQuantityChange,
  onRemove,
  product,
  quantity,
  unitAmount
}: ProductItemProps<P>): JSX.Element {
  const { classes, cx } = useStyles({ forceMobileLayout });
  const theme = useTheme();
  const isWrapping = useMediaQuery(wrappingBreakpoint(theme));
  const { track } = useAnalytics();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const [isProductDetailsModalOpen, openProductDetailsModal, closeProductDetailsModal] = useModal();

  const isBuyer = isBuyerProduct(product);
  const productImages =
    "imagesMetadata" in product
      ? product.imagesMetadata
      : "productImages" in product
        ? product.productImages
        : undefined;
  const productName = "productName" in product ? product.productName : product.name;
  const productCode = "productCode" in product ? product.productCode : product.code;
  const supplierLogo = "supplierLogoUrl" in product ? product.supplierLogoUrl : undefined;
  const description = "description" in product ? product.description : undefined;
  let price =
    "buyerPrice" in product
      ? product.buyerPrice?.amount
      : "unitAmount" in product
        ? product.unitAmount?.amount
        : undefined;
  let originalPrice = "priceListUnitAmount" in product ? product.priceListUnitAmount?.amount : undefined;

  const canViewProductDetails = isBuyerProduct(product);

  // If a unitAmount is passed in, use the `product` price as the original price
  if (unitAmount) {
    originalPrice = price;
    price = unitAmount;
  }

  const onClickProductImage = (): void => {
    track("ProductDetailsModal Opened", { source: "ProductImage" });
    openProductDetailsModal();
  };

  const onClickViewDetails = (): void => {
    track("ProductDetailsModal Opened", { source: "ViewDetails" });
    openProductDetailsModal();
  };

  return (
    <div className={cx(classes.root, className)} data-testid={dataTestId}>
      <Button
        className={cx(classes.image)}
        displayAsLink
        onClick={onClickProductImage}
        disabled={!canViewProductDetails}
      >
        <Image
          image={productImages?.images[0]}
          emptyState={isBuyer ? <CompanyLogo tradingName={product.supplierName} logoUrl={supplierLogo} /> : undefined}
          alt={formatter.format(messages.PRODUCT_IMAGE_ALT, { name: productName })}
          sizes="40px"
        />
      </Button>
      <div className={cx(classes.content)}>
        <div className={cx(classes.leftContent)}>
          <div>
            <Typography component="div" className={cx(classes.productName)} variant="body1">
              {productName}
            </Typography>
            <Typography className={cx(classes.subContent)} variant="caption">
              {isBuyer && (
                <>
                  <span>{product.supplierName}</span>
                  <span className={cx(classes.subContentSpacer)}> · </span>
                </>
              )}
              {productCode}
              {canViewProductDetails && description && (
                <>
                  <span className={cx(classes.subContentSpacer)}> · </span>
                  <Button displayAsLink onClick={onClickViewDetails}>
                    <Typography color="textSecondary" variant="caption" className={cx(classes.viewDetailsButton)}>
                      <FormattedMessage id={messages.VIEW_DETAILS} />
                    </Typography>
                  </Button>
                </>
              )}
            </Typography>
          </div>

          {isBuyer && isWrapping
            ? renderFavouriteButton?.(product)
            : renderMobileMenu && <div className={cx(classes.mobileMenu)}>{renderMobileMenu(product)}</div>}
        </div>

        <div className={cx(classes.rightContent)}>
          {!hidePrice && typeof price === "number" ? (
            <div className={cx(classes.priceWrapper, classes.prices)}>
              <StackedPrice price={price} />
              {originalPrice != null && originalPrice !== price && (
                <span className={cx(classes.originalPrice)} data-testid="productitem-original-price">
                  <NumberFormat
                    displayType="text"
                    value={originalPrice}
                    thousandSeparator
                    decimalScale={DECIMALSCALE}
                    fixedDecimalScale
                  />
                </span>
              )}
            </div>
          ) : (
            isBuyer && (
              <Tooltip
                className={cx(classes.priceWrapper, classes.noPrice)}
                title={
                  <Typography>
                    <FormattedMessage
                      id={messages.NO_PRICE_TOOLTIP}
                      values={{
                        learnMore: {
                          articleId: 5168102
                        }
                      }}
                    />
                  </Typography>
                }
              >
                <>
                  <HelpIcon className={cx(classes.noPriceIcon)} />
                  <FormattedMessage id={messages.NO_PRICE} />
                </>
              </Tooltip>
            )
          )}

          {(isBuyer ? !product.isAvailable : !product.isOrderable) ? (
            <div className={cx(classes.quantityButtons, classes.unavailableReason)}>
              {product.unavailableReason === ProductUnavailableReason.OutOfStock ? (
                <FormattedMessage id={messages.UNAVAILABLE_REASON_OUTOFSTOCK} />
              ) : product.unavailableReason === ProductUnavailableReason.Archived ? (
                <FormattedMessage id={messages.UNAVAILABLE_REASON_ARCHIVED} />
              ) : product.unavailableReason === ProductUnavailableReason.Hidden ? (
                <Tooltip
                  className={cx(classes.unavailableReasonTooltip)}
                  title={<FormattedMessage id={messages.UNAVAILABLE_REASON_HIDDEN_TOOLTIP} />}
                >
                  <>
                    <FormattedMessage id={messages.UNAVAILABLE_REASON_HIDDEN} />
                    <HelpIcon className={cx(classes.unavailableReasonIcon)} />
                  </>
                </Tooltip>
              ) : product.unavailableReason === ProductUnavailableReason.Deleted ? (
                <FormattedMessage id={messages.UNAVAILABLE_REASON_DELETED} />
              ) : (
                <FormattedMessage id={messages.UNAVAILABLE_REASON_UNAVAILABLE} />
              )}
            </div>
          ) : isInOrder && onQuantityChange ? (
            <NumberStepper
              className={cx(classes.quantityButtons)}
              value={quantity}
              onChange={q => onQuantityChange(product, q)}
              minValue={0}
            />
          ) : (
            onAdd && (
              <Button
                className={cx(classes.quantityButtons, classes.addButton)}
                variant="outlined"
                onClick={() => onAdd(product)}
                color="primary"
              >
                <Typography variant="body2">
                  <FormattedMessage id={messages.ADD_BUTTON} />
                </Typography>
              </Button>
            )
          )}

          {isBuyer && !isWrapping && renderFavouriteButton?.(product)}
        </div>
      </div>

      {onRemove && isInOrder && (
        <div className={cx(classes.removeButton)}>
          <IconButton
            size="small"
            onClick={() => onRemove(product)}
            aria-label={formatter.format(messages.REMOVE_BUTTON)}
          >
            <SmallRemoveIcon />
          </IconButton>
        </div>
      )}
      {isBuyerProduct(product) && (
        <ProductDetailsModal product={product} open={isProductDetailsModalOpen} onClose={closeProductDetailsModal} />
      )}
    </div>
  );
}

// This memoisation is important to prevent performance issues with large product lists
export default memo(ProductItem) as typeof ProductItem;
