/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface ActionablePrompt {
  type: ActionablePromptType;
}

export enum ActionablePromptType {
  RateMobileApplication = "RateMobileApplication"
}

export enum AddressProvider {
  Addy = "Addy"
}

export interface AddressSuggestion {
  $type: "AddressSuggestionV1";
}

export type AddressSuggestionV1 = UtilRequiredKeys<AddressSuggestion, "$type"> & {
  provider: AddressProvider;
  id: string;
  address: string;
  /** @default "AddressSuggestionV1" */
  $type: string;
};

export enum AddressType {
  Physical = "Physical"
}

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export interface ApiConnection {
  name: ApiTokenType;
  tenantName?: string | null;
  displayedProvider?: ApiTokenDisplayedProviderType | null;
  features: ProviderFeature[];
}

export enum ApiTokenDisplayedProviderType {
  Sandbox = "Sandbox",
  Myob = "Myob",
  Xero = "Xero",
  QuickBooksOnlineSandbox = "QuickBooksOnlineSandbox",
  QuickBooksOnline = "QuickBooksOnline"
}

export enum ApiTokenType {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Webhooks = "Webhooks",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface Attachment {
  name: string;
  file: File;
}

export interface BillingSummary {
  features: Feature[];
}

export enum BuyerCategory {
  GrocerySupermarket = "GrocerySupermarket",
  Cafe = "Cafe",
  Restaurant = "Restaurant",
  Bar = "Bar",
  Accommodation = "Accommodation",
  AgedCare = "AgedCare",
  Bakery = "Bakery",
  BeautySalonSpa = "BeautySalonSpa",
  Butcher = "Butcher",
  Catering = "Catering",
  ChildCare = "ChildCare",
  Club = "Club",
  ConvenienceStore = "ConvenienceStore",
  Distributor = "Distributor",
  Florist = "Florist",
  FoodCoffeeTruck = "FoodCoffeeTruck",
  GiftBoxService = "GiftBoxService",
  GymFitness = "GymFitness",
  HairSalon = "HairSalon",
  LiquorStore = "LiquorStore",
  MedicalHealthCare = "MedicalHealthCare",
  Office = "Office",
  PetStore = "PetStore",
  PetrolStation = "PetrolStation",
  Pharmacy = "Pharmacy",
  RetailStore = "RetailStore",
  SchoolingEducation = "SchoolingEducation",
  WholesaleSupplier = "WholesaleSupplier"
}

export enum BuyerIssueCategory {
  StandingOrder = "StandingOrder"
}

export interface BuyerIssueCategorySummary {
  category: BuyerIssueCategory;
  /** @format int32 */
  count: number;
}

export enum CategoryIcon {
  Apparel = "Apparel",
  Car = "Car",
  Cleaning = "Cleaning",
  Drink = "Drink",
  Food = "Food",
  Kitchen = "Kitchen",
  Office = "Office",
  Packaging = "Packaging",
  Personal = "Personal",
  Pet = "Pet",
  Services = "Services",
  Vaping = "Vaping"
}

export interface CategorySummary {
  id: string;
  name: string;
  fullName: string;
  /** @format int32 */
  level: number;
  isLeaf: boolean;
  isRoot: boolean;
  parentId?: string | null;
  isCoreCategory?: boolean | null;
  /** @format int32 */
  sortOrder?: number | null;
  iconType?: CategoryIcon | null;
}

export interface ClientApiTokens {
  streamToken: string;
}

export interface CompanyAddress {
  externalId: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  type: AddressType;
  isDefault: boolean;
  country: Country;
  full: string;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface CompanyPermissions {
  companyId: string;
  permissions: Permission[];
}

export interface CompanyPreferences {
  defaultView: MenuGroupType;
}

export interface CompanyProfile {
  companyId: string;
  tradingName: string;
  legalBusinessName?: string | null;
  website?: string | null;
  shortDescription?: string | null;
  description?: string | null;
  phone?: string | null;
  email?: string | null;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  logoUrl?: string | null;
  profileImages?: ProfileImages | null;
  directoryTags?: DirectoryTags | null;
  brandPrimaryColour?: string | null;
  isVisibleInDirectoryAsSupplier: boolean;
  /** @deprecated */
  isVisibleInDirectoryAsBuyer: boolean;
  isBuyer: boolean;
  buyerStatus: CompanyTypeStatus;
  isSupplier: boolean;
  supplierStatus: CompanyTypeStatus;
  isClaimed: boolean;
  countryExtensions?: CountryExtensionsApi | null;
  country: Country;
  timezone: string;
  attachments?: Attachment[] | null;
}

export interface CompanySummary {
  companyId: string;
  tradingName: string;
  isBuyer: boolean;
  buyerStatus: CompanyTypeStatus;
  isSupplier: boolean;
  supplierStatus: CompanyTypeStatus;
  country: Country;
}

export enum CompanyTypeStatus {
  None = "None",
  Onboarding = "Onboarding",
  Active = "Active",
  Inactive = "Inactive"
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CountryExtensionsApi {
  australianBusinessNumber?: string | null;
  newZealandBusinessNumber?: string | null;
  newZealandGstNumber?: string | null;
}

export interface CountrySummary {
  alpha2Code: string;
  commonName: string;
  callingCodes: string[];
  isSupported: boolean;
}

export interface DirectoryTags {
  tags: Tag[];
  buyerCategories: BuyerCategory[];
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export enum Feature {
  CompanyAccess = "CompanyAccess",
  BuyerAccess = "BuyerAccess",
  SupplierAccess = "SupplierAccess",
  ManageProducts = "ManageProducts",
  ManageOrders = "ManageOrders",
  SupplierPlacedOrders = "SupplierPlacedOrders",
  ManagePriceLists = "ManagePriceLists",
  ManageCustomers = "ManageCustomers",
  ManageCustomerTags = "ManageCustomerTags",
  ManageNewsFeeds = "ManageNewsFeeds",
  SupplierManageStandingOrderSchedules = "SupplierManageStandingOrderSchedules",
  SupplierDashboard = "SupplierDashboard",
  SupplierOnboardingGoLiveCall = "SupplierOnboardingGoLiveCall",
  SupplierOnboardingGoLiveForm = "SupplierOnboardingGoLiveForm",
  SupplierDetailedProfile = "SupplierDetailedProfile",
  ManageTaxRates = "ManageTaxRates",
  DirectoryFeatured = "DirectoryFeatured",
  SampleStoreAccess = "SampleStoreAccess",
  ManagePaymentTerms = "ManagePaymentTerms",
  ManageDeliveryGroups = "ManageDeliveryGroups",
  ManageProductPromotions = "ManageProductPromotions",
  PayUpstock = "PayUpstock",
  FullCategoryManagement = "FullCategoryManagement",
  Reports = "Reports",
  Integrations = "Integrations",
  IntegrationsFoodstuffs = "IntegrationsFoodstuffs",
  IntegrationsExternalOrders = "IntegrationsExternalOrders",
  PaymentMerchant = "PaymentMerchant",
  CodatIntegration = "CodatIntegration",
  WebhookIntegration = "WebhookIntegration",
  WoolworthsIntegration = "WoolworthsIntegration",
  MyIntegratorIntegration = "MyIntegratorIntegration",
  StarshipitIntegration = "StarshipitIntegration",
  LimitedBuyerRole = "LimitedBuyerRole",
  StockTransfer = "StockTransfer"
}

export interface File {
  id: string;
  originalName: string;
  url: string;
  contentType?: string | null;
}

export interface GettingStartedAction {
  actionType: GettingStartedActionType;
  isCompleted: boolean;
  isLocked: boolean;
  isRequiredForOnboarding: boolean;
}

export enum GettingStartedActionType {
  BuyerFirstOrder = "BuyerFirstOrder",
  SupplierFirstOrder = "SupplierFirstOrder",
  AddSupplier = "AddSupplier",
  AddBuyer = "AddBuyer",
  CheckProfile = "CheckProfile",
  AddFavourites = "AddFavourites",
  ConnectToProvider = "ConnectToProvider",
  SupplierAddProducts = "SupplierAddProducts",
  SupplierSetupPricing = "SupplierSetupPricing",
  SupplierGrowthCustomersOrders = "SupplierGrowthCustomersOrders",
  InviteUsers = "InviteUsers",
  SupplierActivation = "SupplierActivation",
  SupplierQuickAddProducts = "SupplierQuickAddProducts",
  SupplierAddCustomers = "SupplierAddCustomers",
  PaymentMerchant = "PaymentMerchant",
  PayUpstock = "PayUpstock",
  DeliveryGroupSettings = "DeliveryGroupSettings"
}

export interface GettingStartedDetails {
  actions: GettingStartedAction[];
  isCompleted: boolean;
}

export interface HideModalProperties {
  hideApproveOrderConfirmationModal: boolean;
  hidePriceMismatchWarningModal: boolean;
  hideUpdateProductInProviderModal: boolean;
  hideBasePriceInfoModal: boolean;
  hideCannotSyncOrderModal: boolean;
  hideApproveOrderPaymentModal: boolean;
  hideOrderLineProblemsModalForProviderOrders: boolean;
  hideFoodstuffsOrderEditWarningModal: boolean;
  hideMobilePushNotificationsModal: boolean;
  hideWoolworthsOrderEditWarningModal: boolean;
  hideWoolworthsShipInvoiceWarningModal: boolean;
  hideScheduledOrderForceApprovedWarningModal: boolean;
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface ImageSummary {
  id: string;
  urls: ProfileImageAsset[];
  orientation: Orientation;
}

export interface LinkedBuyerStats {
  /** @format int32 */
  linkedCount: number;
  /** @format int32 */
  invitedCount: number;
  /** @format int32 */
  leadCount: number;
  /** @format int32 */
  standingOrdersCount: number;
  issues: BuyerIssueCategorySummary[];
}

export enum MenuGroupType {
  Buyer = "Buyer",
  Supplier = "Supplier"
}

export interface NavigationResult {
  userProfile: UserProfile;
  companyProfile: CompanyProfile;
  companies: CompanySummary[];
  permissions: CompanyPermissions;
  preferences: CompanyPreferences;
  buyerGettingStarted?: GettingStartedDetails | null;
  supplierGettingStarted?: GettingStartedDetails | null;
  features: Record<string, string>;
  connections: ApiConnection[];
  userSettings: UserSettings;
  clientApiTokens: ClientApiTokens;
  linkedBuyerStats?: LinkedBuyerStats | null;
  billing: BillingSummary;
  supplierSettings?: SupplierSettings | null;
  paymentSettings?: PaymentSettings | null;
  taxOnboardingStatus?: TaxOnboardingStatus | null;
  availableActionablePrompts: ActionablePrompt[];
}

export enum NotificationTemplate {
  OrderCommentCreated = "OrderCommentCreated",
  OrderSupplierCreatedPaymentRequired = "OrderSupplierCreatedPaymentRequired",
  OrderSupplierCreated = "OrderSupplierCreated",
  OrderBuyerCreated = "OrderBuyerCreated",
  OrderScheduleCreated = "OrderScheduleCreated",
  OrderStatusSupplierApproved = "OrderStatusSupplierApproved",
  OrderStatusSupplierShipped = "OrderStatusSupplierShipped",
  OrderStatusSupplierCancelled = "OrderStatusSupplierCancelled",
  OrderStatusBuyerCancelled = "OrderStatusBuyerCancelled",
  OrderStatusSchedulePlaced = "OrderStatusSchedulePlaced",
  Custom = "Custom",
  BuyerAddressUpdated = "BuyerAddressUpdated"
}

export enum Orientation {
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square"
}

export enum PaymentOnboardingDependency {
  ProductsAreMissing = "ProductsAreMissing",
  PriceListsNotActive = "PriceListsNotActive",
  TaxNotActive = "TaxNotActive",
  TaxExemptPaymentsNotAllowed = "TaxExemptPaymentsNotAllowed"
}

export interface PaymentOnboardingState {
  status: PaymentOnboardingStatus;
  missingDependencies: PaymentOnboardingDependency[];
}

export enum PaymentOnboardingStatus {
  Available = "Available",
  MissingDependencies = "MissingDependencies",
  Completed = "Completed",
  Unavailable = "Unavailable"
}

export interface PaymentReconciliationSettings {
  clearingAccount: PaymentReconciliationSettingsAccount;
  feeAccount: PaymentReconciliationSettingsAccount;
  feeContact: PaymentReconciliationSettingsContact;
  feeTaxRate: PaymentReconciliationSettingsTaxRate;
  payoutAccount: PaymentReconciliationSettingsAccount;
}

export interface PaymentReconciliationSettingsAccount {
  code?: string | null;
  name: string;
  identifier: string;
}

export interface PaymentReconciliationSettingsContact {
  identifier: string;
  name: string;
}

export interface PaymentReconciliationSettingsTaxRate {
  identifier: string;
  name: string;
}

export enum PaymentRequirement {
  SupplierDefault = "SupplierDefault",
  None = "None",
  PayNow = "PayNow",
  PayNowOptional = "PayNowOptional"
}

export interface PaymentSettings {
  /** @deprecated */
  paymentRequirement: PaymentRequirement;
  paymentsActive: boolean;
  reconciliationSettings?: PaymentReconciliationSettings | null;
  paymentOnboardingState?: PaymentOnboardingState | null;
}

export enum Permission {
  ViewCompanyProfile = "ViewCompanyProfile",
  UpdateCompanyProfile = "UpdateCompanyProfile",
  GetNotificationPreferences = "GetNotificationPreferences",
  UpdateNotificationPreferences = "UpdateNotificationPreferences",
  ViewGettingStarted = "ViewGettingStarted",
  UpdateGettingStarted = "UpdateGettingStarted",
  UploadProductList = "UploadProductList",
  UploadCustomerList = "UploadCustomerList",
  ActivateSupplier = "ActivateSupplier",
  DeactivateSupplier = "DeactivateSupplier",
  ViewBilling = "ViewBilling",
  UpdateBilling = "UpdateBilling",
  ViewUsers = "ViewUsers",
  ManageUsers = "ManageUsers",
  ViewUserInvites = "ViewUserInvites",
  CreateUserInvite = "CreateUserInvite",
  DeleteUserInvite = "DeleteUserInvite",
  UpdateUserInvite = "UpdateUserInvite",
  CreateCompanyInvite = "CreateCompanyInvite",
  SupplierViewOrderSettings = "SupplierViewOrderSettings",
  SupplierUpdateOrderSettings = "SupplierUpdateOrderSettings",
  SupplierViewSettings = "SupplierViewSettings",
  BuyerViewSupplierSettings = "BuyerViewSupplierSettings",
  SupplierUpdateSettings = "SupplierUpdateSettings",
  SupplierViewNotificationForwardingSettings = "SupplierViewNotificationForwardingSettings",
  SupplierUpdateNotificationForwardingSettings = "SupplierUpdateNotificationForwardingSettings",
  SupplierUpdatePaymentSettings = "SupplierUpdatePaymentSettings",
  SupplierViewPaymentSettings = "SupplierViewPaymentSettings",
  ViewApiConnections = "ViewApiConnections",
  ConnectApi = "ConnectApi",
  DisconnectApi = "DisconnectApi",
  ViewProviderContacts = "ViewProviderContacts",
  CreateProviderContacts = "CreateProviderContacts",
  ViewProviderSettings = "ViewProviderSettings",
  UpdateProviderSettings = "UpdateProviderSettings",
  LinkProviderProducts = "LinkProviderProducts",
  CreateOrderExternalDocument = "CreateOrderExternalDocument",
  ViewOrderExternalDocument = "ViewOrderExternalDocument",
  ForwardInvoice = "ForwardInvoice",
  CreateProviderShipment = "CreateProviderShipment",
  CreateProduct = "CreateProduct",
  UpdateProduct = "UpdateProduct",
  ImportProducts = "ImportProducts",
  DeleteProduct = "DeleteProduct",
  SupplierViewProducts = "SupplierViewProducts",
  BuyerViewProducts = "BuyerViewProducts",
  BuyerViewAllProducts = "BuyerViewAllProducts",
  BuyerViewPublicProducts = "BuyerViewPublicProducts",
  CreateQuickAddProducts = "CreateQuickAddProducts",
  RemoveQuickAddProducts = "RemoveQuickAddProducts",
  ViewQuickAddProducts = "ViewQuickAddProducts",
  SupplierCreateProductPromotions = "SupplierCreateProductPromotions",
  SupplierDeleteProductPromotions = "SupplierDeleteProductPromotions",
  ViewConnectedBuyers = "ViewConnectedBuyers",
  UpdateConnectedBuyers = "UpdateConnectedBuyers",
  ManageBuyerInvites = "ManageBuyerInvites",
  UpdateBuyerSettings = "UpdateBuyerSettings",
  ViewPendingBuyers = "ViewPendingBuyers",
  AddPendingBuyer = "AddPendingBuyer",
  ViewPendingBuyer = "ViewPendingBuyer",
  DeletePendingBuyer = "DeletePendingBuyer",
  UpdatePendingBuyer = "UpdatePendingBuyer",
  ViewConnectedSuppliers = "ViewConnectedSuppliers",
  ViewSupplierDirectory = "ViewSupplierDirectory",
  ViewSuppliersProfile = "ViewSuppliersProfile",
  CreateSupplierInvite = "CreateSupplierInvite",
  DeleteSupplierInvite = "DeleteSupplierInvite",
  ViewSupplierInvites = "ViewSupplierInvites",
  AddPendingSupplier = "AddPendingSupplier",
  ViewPendingSuppliers = "ViewPendingSuppliers",
  DeletePendingSupplier = "DeletePendingSupplier",
  BuyerViewOrders = "BuyerViewOrders",
  BuyerCreateOrder = "BuyerCreateOrder",
  BuyerUpdateOrder = "BuyerUpdateOrder",
  SupplierViewOrders = "SupplierViewOrders",
  SupplierCreateOrder = "SupplierCreateOrder",
  SupplierUpdateOrder = "SupplierUpdateOrder",
  SupplierUploadOrderFile = "SupplierUploadOrderFile",
  AddComment = "AddComment",
  ViewInvoice = "ViewInvoice",
  DeleteOrder = "DeleteOrder",
  ViewPackingSlips = "ViewPackingSlips",
  ViewEmailPreviews = "ViewEmailPreviews",
  ReportOrderIssue = "ReportOrderIssue",
  ViewOrderIssues = "ViewOrderIssues",
  ResolveOrderIssue = "ResolveOrderIssue",
  ViewPriceLists = "ViewPriceLists",
  CreatePriceList = "CreatePriceList",
  UpdatePriceList = "UpdatePriceList",
  DeletePriceList = "DeletePriceList",
  DuplicatePriceList = "DuplicatePriceList",
  ActivatePriceLists = "ActivatePriceLists",
  DeactivatePriceLists = "DeactivatePriceLists",
  SyncPriceLists = "SyncPriceLists",
  SupplierCreateStandingOrderSchedules = "SupplierCreateStandingOrderSchedules",
  SupplierUpdateStandingOrderSchedules = "SupplierUpdateStandingOrderSchedules",
  SupplierViewStandingOrderSchedules = "SupplierViewStandingOrderSchedules",
  BuyerUpdateStandingOrderSchedules = "BuyerUpdateStandingOrderSchedules",
  ViewFavourites = "ViewFavourites",
  CreateFavourite = "CreateFavourite",
  UpdateFavourites = "UpdateFavourites",
  DeleteFavourites = "DeleteFavourites",
  UpdateFavouriteProducts = "UpdateFavouriteProducts",
  SupplierViewTags = "SupplierViewTags",
  SupplierDeleteTags = "SupplierDeleteTags",
  SupplierCreateTags = "SupplierCreateTags",
  DeleteLinkedBuyerTags = "DeleteLinkedBuyerTags",
  CreateLinkedBuyerTags = "CreateLinkedBuyerTags",
  UpdateLinkedBuyerTags = "UpdateLinkedBuyerTags",
  SupplierViewReports = "SupplierViewReports",
  SupplierViewOrdersExportReport = "SupplierViewOrdersExportReport",
  SupplierViewProductionReport = "SupplierViewProductionReport",
  SupplierViewPaymentsPayoutReport = "SupplierViewPaymentsPayoutReport",
  BuyerViewOrdersReport = "BuyerViewOrdersReport",
  SupplierCreateNewsfeedPost = "SupplierCreateNewsfeedPost",
  SupplierDeleteNewsfeedPost = "SupplierDeleteNewsfeedPost",
  SupplierUpdateNewsfeedPost = "SupplierUpdateNewsfeedPost",
  CreateNewsfeedPostReaction = "CreateNewsfeedPostReaction",
  DeleteNewsfeedPostReaction = "DeleteNewsfeedPostReaction",
  CreateNewsfeedsBuyerCompany = "CreateNewsfeedsBuyerCompany",
  ViewSupplierDashboard = "ViewSupplierDashboard",
  PaymentLinkMerchantAccount = "PaymentLinkMerchantAccount",
  PaymentViewMerchantAccount = "PaymentViewMerchantAccount",
  PaymentCreateMerchantAccount = "PaymentCreateMerchantAccount",
  PaymentViewMethods = "PaymentViewMethods",
  PaymentSetupMethods = "PaymentSetupMethods",
  PaymentUpdateForOrder = "PaymentUpdateForOrder",
  PaymentTakeForOrder = "PaymentTakeForOrder",
  PaymentViewPayouts = "PaymentViewPayouts",
  PaymentCreatePaymentTerms = "PaymentCreatePaymentTerms",
  PaymentViewPaymentTerms = "PaymentViewPaymentTerms",
  PaymentUpdatePaymentTerms = "PaymentUpdatePaymentTerms",
  PaymentDeletePaymentTerms = "PaymentDeletePaymentTerms",
  ViewTaxRates = "ViewTaxRates",
  ViewProviderTaxRates = "ViewProviderTaxRates",
  ViewProviderAccounts = "ViewProviderAccounts",
  CreateProviderAccounts = "CreateProviderAccounts",
  SupplierCreateDeliveryGroups = "SupplierCreateDeliveryGroups",
  SupplierViewDeliveryGroups = "SupplierViewDeliveryGroups",
  SupplierUpdateDeliveryGroups = "SupplierUpdateDeliveryGroups",
  SupplierDeleteDeliveryGroups = "SupplierDeleteDeliveryGroups",
  SupplierAutocategoriseProducts = "SupplierAutocategoriseProducts",
  SupplierViewFeatureCategories = "SupplierViewFeatureCategories",
  SupplierUpdateFeatureCategories = "SupplierUpdateFeatureCategories",
  GlobalViewProductTaxonomy = "GlobalViewProductTaxonomy"
}

export enum ProductSortBy {
  Name = "Name",
  Code = "Code",
  FeatureCategory = "FeatureCategory"
}

export interface ProfileImageAsset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  resolution: Resolution;
}

export interface ProfileImages {
  imageSummaries: ImageSummary[];
}

export interface ProviderFeature {
  name: ProviderOffering;
  isDefault: boolean;
  permissions: ProviderFeatureAccess[];
}

export enum ProviderFeatureAccess {
  Create = "Create",
  Edit = "Edit",
  Delete = "Delete",
  Pull = "Pull",
  DeepLink = "DeepLink",
  PullPdfs = "PullPdfs",
  SendToCustomer = "SendToCustomer",
  Sync = "Sync"
}

export enum ProviderOffering {
  Products = "Products",
  Invoices = "Invoices",
  Contacts = "Contacts",
  IncomingOrders = "IncomingOrders",
  OutgoingOrders = "OutgoingOrders",
  PriceLists = "PriceLists",
  Shipping = "Shipping"
}

export enum Resolution {
  OneX = "OneX",
  TwoX = "TwoX"
}

export interface SupplierSettings {
  allowDuplicateProductNames: boolean;
  autoAcceptBuyerInvites: boolean;
  allowInternationalBuyers: boolean;
  productSortBy: ProductSortBy;
}

export enum Tag {
  Alcohol = "Alcohol",
  BakedGoods = "BakedGoods",
  CheeseDairy = "CheeseDairy",
  CoffeeTea = "CoffeeTea",
  Condiments = "Condiments",
  Drinks = "Drinks",
  MeatVeg = "MeatVeg",
  Packaging = "Packaging",
  PersonalCare = "PersonalCare",
  DryGoods = "DryGoods",
  Vegan = "Vegan",
  Beer = "Beer",
  Bread = "Bread",
  CertifiedOrganic = "CertifiedOrganic",
  Chocolate = "Chocolate",
  Coffee = "Coffee",
  Confectionary = "Confectionary",
  CookingOil = "CookingOil",
  Dessert = "Dessert",
  Eggs = "Eggs",
  Equipment = "Equipment",
  FreeRange = "FreeRange",
  FrozenFoods = "FrozenFoods",
  Fruit = "Fruit",
  GlutenFree = "GlutenFree",
  Honey = "Honey",
  HotSauce = "HotSauce",
  Juice = "Juice",
  Meat = "Meat",
  Milk = "Milk",
  PlantMilk = "PlantMilk",
  Produce = "Produce",
  Seafood = "Seafood",
  Snacks = "Snacks",
  Spirits = "Spirits",
  SugarFree = "SugarFree",
  Tea = "Tea",
  Wine = "Wine",
  Cleaning = "Cleaning",
  Supplements = "Supplements",
  Pets = "Pets",
  Homewares = "Homewares",
  Kitchenware = "Kitchenware",
  BCorp = "BCorp",
  AlcoholFree = "AlcoholFree",
  SpicesSeasonings = "SpicesSeasonings",
  ReadyMeals = "ReadyMeals",
  FirstNations = "FirstNations"
}

export enum TaxOnboardingStatus {
  NotAvailable = "NotAvailable",
  Available = "Available",
  Overdue = "Overdue"
}

export interface TaxonomySummary {
  currentNode?: CategorySummary | null;
  nodeTaxonomy: CategorySummary[];
}

export interface UserProfile {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
}

export interface UserSettings {
  /** @format date-time */
  acceptedTermsConditionsDateUtc?: string | null;
  userFlags: HideModalProperties;
  hasAcceptedLatestTermsConditions: boolean;
}

export enum ValidationErrorCodes {
  NotEmptyValidator = "NotEmptyValidator",
  EmptyValidator = "EmptyValidator",
  NotDuplicateValidator = "NotDuplicateValidator",
  EmailValidator = "EmailValidator",
  InviteAlreadyAccepted = "InviteAlreadyAccepted",
  InviteAlreadyAcceptedByCurrentUser = "InviteAlreadyAcceptedByCurrentUser",
  InviteUserAlreadyHasRole = "InviteUserAlreadyHasRole",
  InviteExistingOwner = "InviteExistingOwner",
  InvalidStatusTransition = "InvalidStatusTransition",
  InviteDuplicateUserEmail = "InviteDuplicateUserEmail",
  InviteMustBeAcceptedBySignupUser = "InviteMustBeAcceptedBySignupUser",
  CompanyIsClaimed = "CompanyIsClaimed",
  CannotClaimFromExternalView = "CannotClaimFromExternalView",
  ClaimCompanyAlreadyHasRole = "ClaimCompanyAlreadyHasRole",
  ProductNotFound = "ProductNotFound",
  ProductUnavailable = "ProductUnavailable",
  DraftProductCodeAlreadyUsedByDraft = "DraftProductCodeAlreadyUsedByDraft",
  DraftProductCodeAlreadyUsedByProduct = "DraftProductCodeAlreadyUsedByProduct",
  DraftProductNameAlreadyUsedByDraft = "DraftProductNameAlreadyUsedByDraft",
  DraftProductNameAlreadyUsedByProduct = "DraftProductNameAlreadyUsedByProduct",
  PriceListCreateMissingBasePrices = "PriceListCreateMissingBasePrices",
  PriceListDefaultCannotChange = "PriceListDefaultCannotChange",
  PriceListCreateNoDefault = "PriceListCreateNoDefault",
  PriceListsActivateNoDefault = "PriceListsActivateNoDefault",
  PriceListsActivateMissingBasePrices = "PriceListsActivateMissingBasePrices",
  PriceListsActivateTaxSettingNotSet = "PriceListsActivateTaxSettingNotSet",
  PriceListProductsInvalidDiscount = "PriceListProductsInvalidDiscount",
  PriceListsNotActive = "PriceListsNotActive",
  OrderSettingsTaxCannotBeInclusiveWithActivePriceLists = "OrderSettingsTaxCannotBeInclusiveWithActivePriceLists",
  InvalidTax = "InvalidTax",
  InvalidProviderContact = "InvalidProviderContact",
  OrderLineProblems = "OrderLineProblems",
  InvalidOrderLines = "InvalidOrderLines",
  InviteEmailRequired = "InviteEmailRequired",
  InviteRoleRequired = "InviteRoleRequired",
  InvalidRoleChange = "InvalidRoleChange",
  InviteEmailAlreadyExists = "InviteEmailAlreadyExists",
  InvalidDateRange = "InvalidDateRange",
  PendingDirectoryInvite = "PendingDirectoryInvite",
  BuyerMustBeClaimed = "BuyerMustBeClaimed",
  EmailConfirmationUsersDontMatch = "EmailConfirmationUsersDontMatch",
  AccountAlreadyLinked = "AccountAlreadyLinked",
  OrderNotEditable = "OrderNotEditable",
  InvalidCredentials = "InvalidCredentials",
  NotAValidGuid = "NotAValidGuid",
  UnsupportedFileType = "UnsupportedFileType",
  InvalidBillingPlanForCompany = "InvalidBillingPlanForCompany",
  InvalidCurrencyForBillingPlan = "InvalidCurrencyForBillingPlan",
  AlreadyHasScheduledBillingPlan = "AlreadyHasScheduledBillingPlan",
  InvalidAddOnForBillingPlan = "InvalidAddOnForBillingPlan",
  StandingOrderScheduleNoProducts = "StandingOrderScheduleNoProducts",
  StandingOrderProductInvalidQuantity = "StandingOrderProductInvalidQuantity",
  DeliveryDayUnavailable = "DeliveryDayUnavailable",
  DeliveryScheduleInvalid = "DeliveryScheduleInvalid",
  DefaultDeliveryGroupCannotBeDeleted = "DefaultDeliveryGroupCannotBeDeleted",
  IntegrationDefaultValuesCanNotBeRetrieved = "IntegrationDefaultValuesCanNotBeRetrieved",
  NoLinkedSupplier = "NoLinkedSupplier",
  NoLinkedBuyer = "NoLinkedBuyer",
  PageLimitGreaterThanZero = "PageLimitGreaterThanZero",
  UserHasExistingAuth0Account = "UserHasExistingAuth0Account",
  UserHasExistingSocialAppleAccount = "UserHasExistingSocialAppleAccount",
  UserHasExistingSocialGoogleAccount = "UserHasExistingSocialGoogleAccount",
  UserHasExistingSocialOtherAccount = "UserHasExistingSocialOtherAccount",
  UserDoesNotHaveEmailAddress = "UserDoesNotHaveEmailAddress",
  PriceListNotEditable = "PriceListNotEditable",
  ReadOnlyField = "ReadOnlyField",
  IntegrationNotConnected = "IntegrationNotConnected",
  ClaimTokenExpired = "ClaimTokenExpired",
  ClaimTokenRedeemed = "ClaimTokenRedeemed",
  ApiCreditsExhausted = "ApiCreditsExhausted",
  FileSizeExceeded = "FileSizeExceeded",
  ImageResolutionExceeded = "ImageResolutionExceeded",
  InvalidHexColour = "InvalidHexColour",
  ToDateRequired = "ToDateRequired",
  FromDateRequired = "FromDateRequired",
  TaxRatesAreManagedByUpstock = "TaxRatesAreManagedByUpstock",
  TaxRateNotFound = "TaxRateNotFound",
  CountryCodeRequired = "CountryCodeRequired",
  SupplierMissingRequiredTag = "SupplierMissingRequiredTag",
  BuyerMissingRequiredTagValue = "BuyerMissingRequiredTagValue",
  InvalidCoordinates = "InvalidCoordinates",
  PaymentInvalidMethod = "PaymentInvalidMethod",
  PaymentRequired = "PaymentRequired",
  ShowOrderPricesToBuyersRequired = "ShowOrderPricesToBuyersRequired",
  InvalidMinimumMonetaryValue = "InvalidMinimumMonetaryValue",
  TaxRateRequiredForActiveProducts = "TaxRateRequiredForActiveProducts",
  NotInFeature = "NotInFeature",
  PaymentAlreadyProcessed = "PaymentAlreadyProcessed",
  CompanyPreConditionsNotMet = "CompanyPreConditionsNotMet",
  NoActiveMerchantAccount = "NoActiveMerchantAccount",
  PaymentNotUpdatable = "PaymentNotUpdatable",
  InvalidAmount = "InvalidAmount",
  TaxNotActive = "TaxNotActive",
  TaxExemptPaymentsNotAllowed = "TaxExemptPaymentsNotAllowed",
  OrderHasNoLines = "OrderHasNoLines",
  ProviderDisconnected = "ProviderDisconnected",
  ProviderReferenceInvalid = "ProviderReferenceInvalid",
  NoProductsAvailable = "NoProductsAvailable",
  CollectionCanOnlyContainOneElement = "CollectionCanOnlyContainOneElement",
  ExceedsMaximumLength = "ExceedsMaximumLength",
  DefaultPaymentTermsCannotBeDeleted = "DefaultPaymentTermsCannotBeDeleted",
  ValueOutOfRange = "ValueOutOfRange",
  InternationalBuyersNotAllowed = "InternationalBuyersNotAllowed",
  SupplierNotVisibleInDirectory = "SupplierNotVisibleInDirectory",
  DuplicateProductPromotion = "DuplicateProductPromotion",
  InvalidStatus = "InvalidStatus",
  ProductImageRequired = "ProductImageRequired",
  FeaturedProductLimitReached = "FeaturedProductLimitReached",
  ManualTakePaymentNotAvailable = "ManualTakePaymentNotAvailable",
  InvalidProductCategory = "InvalidProductCategory",
  InvalidSignature = "InvalidSignature",
  BillingPlanMissingChargebeeMetadata = "BillingPlanMissingChargebeeMetadata",
  ChargebeePaymentSourceMissing = "ChargebeePaymentSourceMissing",
  MissingFeature = "MissingFeature",
  PaymentInvalidRegion = "PaymentInvalidRegion",
  InvoiceNotFound = "InvoiceNotFound",
  InvalidWebsite = "InvalidWebsite",
  NoOrder = "NoOrder",
  MissingAddressData = "MissingAddressData"
}

export namespace Global {
  /**
   * No description
   * @tags Categories
   * @name InternalCategoriesList
   * @summary  (Auth Permissions: GlobalViewProductTaxonomy)
   * @request GET:/global/internal/categories
   * @secure
   * @response `200` `TaxonomySummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalCategoriesList {
    export type RequestParams = {};
    export type RequestQuery = {
      categoryId?: string;
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxonomySummary;
  }
  /**
   * No description
   * @tags Files
   * @name InternalFilesOrderAttachmentsCreate
   * @summary Upload a file intended as an attachment for an order comment. (Auth Permissions: AddComment)
   * @request POST:/global/internal/files/order-attachments
   * @secure
   * @response `200` `Image` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalFilesOrderAttachmentsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      attachment?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Image;
  }
  /**
   * No description
   * @tags Files
   * @name InternalFilesProfileAttachmentsCreate
   * @summary Upload a file intended as an attachment to a profile page. (Auth Permissions: UpdateCompanyProfile)
   * @request POST:/global/internal/files/profile-attachments
   * @secure
   * @response `200` `File` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalFilesProfileAttachmentsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      attachment?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = File;
  } /**
 * No description
 * @tags Geography
 * @name InternalGeographyCountriesList
 * @summary Returns a list of country objects that include things like a common
English name and the 2-letter character code.
 * @request GET:/global/internal/geography/countries
 * @response `200` `(CountrySummary)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalGeographyCountriesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CountrySummary[];
  } /**
 * No description
 * @tags Geography
 * @name InternalGeographyAddressesList
 * @request GET:/global/internal/geography/addresses
 * @response `200` `((UtilRequiredKeys<AddressSuggestionV1,"$type">) & ({
    $type: "AddressSuggestionV1",

}))[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalGeographyAddressesList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      countryCode?: Alpha2Code;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (UtilRequiredKeys<AddressSuggestionV1, "$type"> & {
      $type: "AddressSuggestionV1";
    })[];
  } /**
 * No description
 * @tags Navigation
 * @name InternalNavigationList
 * @summary All of the data the app needs to start, in a single more efficient
package. (Auth Permissions: ViewCompanyProfile)
 * @request GET:/global/internal/navigation
 * @secure
 * @response `200` `NavigationResult` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalNavigationList {
    export type RequestParams = {};
    export type RequestQuery = {
      menuOverride?: MenuGroupType;
      feature?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = NavigationResult;
  }
}
