import { JSX, MouseEventHandler, ReactNode, useCallback } from "react";

import Button, { ButtonProps } from "components/Button";
import { useAnalytics } from "utils/analytics";
import { showArticle } from "utils/Intercom";

export interface IntercomHelpArticleProps
  extends Pick<ButtonProps, "color" | "displayAsLink" | "onClick" | "size" | "underline" | "variant"> {
  articleId: number;
  children: ReactNode;
  className?: string;
  trackEventName?: string;
  trackEventProperties?: object;
}

/**
 * A button-as-text component for showing an Intercom help article using the
 * in-app popup.
 */
export default function IntercomHelpArticle({
  articleId,
  children,
  className,
  displayAsLink = true,
  onClick,
  trackEventName,
  trackEventProperties,
  ...buttonProps
}: IntercomHelpArticleProps): JSX.Element {
  const { track } = useAnalytics();
  const helpArticleUrl = `https://help.upstock.app/en/articles/${articleId}`;

  const openIntercomAndShowArticle: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.preventDefault();

      showArticle(articleId);
      track("Intercom Help Article Opened", { articleId });

      if (trackEventName) {
        track(trackEventName, trackEventProperties);
      }

      onClick?.(event);
    },
    [articleId, onClick, track, trackEventName, trackEventProperties]
  );

  return (
    <Button
      className={className}
      displayAsLink={displayAsLink}
      href={helpArticleUrl}
      component="a"
      color="primary"
      {...buttonProps}
      onClick={openIntercomAndShowArticle}
    >
      {children}
    </Button>
  );
}
