import { Switch } from "react-router-dom";

import PrivateRoute from "auth/PrivateRoute";
import { SupplierNewOrder, SupplierOrdersGridLayout, SupplierPrintInvoice, SupplierViewOrder } from "routes/Routes";
import lazyComponent from "services/lazyComponent";
import { Feature } from "types/api/generated/global-internal";

import OrdersGate from "./orders-grid/views/OrdersGate";

const SupplierOrdersGridFeature = lazyComponent(() => import("./orders-grid/OrdersGridFeature"));
const SupplierViewOrderFeature = lazyComponent(() => import("./view-order/ViewOrderFeature"));
const SupplierNewOrderFeature = lazyComponent(() => import("./new-order/NewOrderFeature"));

/**
 * The feature component that encompoasses all of the Supplier > Orders
 * pages/features/flows.  This is useful to act as a boundary around all of
 * supplier ordering, allowing us to hook into the entry/exit of this area of
 * our app.
 */
export default function OrdersFeature(): JSX.Element {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={SupplierOrdersGridLayout.path}
        component={SupplierOrdersGridFeature}
        permissions={SupplierOrdersGridLayout.permissions}
        billingFeature={Feature.ManageOrders}
        BillingFeatureGate={OrdersGate}
      />
      <PrivateRoute
        path={SupplierNewOrder.path}
        component={SupplierNewOrderFeature}
        billingFeature={Feature.SupplierPlacedOrders}
        BillingFeatureGate={OrdersGate}
      />
      <PrivateRoute
        exact
        path={[SupplierViewOrder.path, SupplierPrintInvoice.path]}
        component={SupplierViewOrderFeature}
        billingFeature={Feature.ManageOrders}
        BillingFeatureGate={OrdersGate}
      />
    </Switch>
  );
}
