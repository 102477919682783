import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { deleteDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import { deletePriceListFailure, deletePriceListSuccess, DeletePriceListRequest } from "../reducers/priceList";

/**
 * Calls the API to delete a price list.
 */
function deletePriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string
): Promise<Supplier.V1PricelistsDelete.ResponseBody> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}`;
  return deleteDataFromApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Saga for deleting the price list with the given ID.
 */
export default createSaga(deletePriceList, {
  prepareApiMethodParams: ({ priceListId }: DeletePriceListRequest["payload"]) => [priceListId],
  successAction: deletePriceListSuccess,
  errorFailureAction: deletePriceListFailure
});
