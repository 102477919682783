import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMount } from "react-use";

import useBuyerGettingStarted from "features/buyers/getting-started/useBuyerGettingStarted";
import useMessages from "i18n/hooks/useMessages";
import LoadingScreen from "pages/LoadingScreen";
import { BuyerNewOrder, BuyerOrderNowRedirect, BuyerSupplier } from "routes/Routes";
import { GettingStartedActionType } from "types/api/generated/directory-internal";
import { isConnected, isPending } from "utils/CompanyLinkStatus";
import useAppSelector from "utils/hooks/useAppSelector";
import useModal from "utils/hooks/useModal";
import useSnackbar from "utils/hooks/useSnackbar";
import { promisifySaga } from "utils/Sagas";

import ConnectionModal, { ConnectionHandler, CURRENTLY_TRADING } from "./components/ConnectionModal";
import useCanConnectToSupplier from "./hooks/useCanConnectToSupplier";
import strings from "./OrderNowRedirect.strings.json";
import { fetchSupplierDetailsRequest, inviteToTradeRequest } from "./reducers/supplierById";
import { DirectoryReduxState } from "./Types";
import useInjectDirectory from "./useInjectDirectory";

/**
 * Redirects the buyer to the specified supplier in the new order flow if the buyer is connected.
 * Otherwise it shows the connection modal and if the supplier is provisional the buyer is
 * redirected straight to the new order flow (since there's no approval step for provisional suppliers),
 * otherwise the buyer is redirected to the supplier's profile.
 */
const OrderNowRedirect: FunctionComponent = () => {
  const messages = useMessages(strings);
  const dispatch = useDispatch();
  const { tenancyId, supplierId } = useParams<typeof BuyerOrderNowRedirect.params>();
  const history = useHistory();
  const supplierById = useAppSelector((state: DirectoryReduxState) => state.directory.supplierById);
  const {
    loading,
    tradingName,
    connectAction,
    link: { status: currentStatus },
    supplierId: reduxSupplierId
  } = supplierById;
  const { showSnackbar } = useSnackbar();
  const [isConnectionModalOpen, openConnectionModal, closeConnectionModal] = useModal();
  const { canConnectToSupplier } = useCanConnectToSupplier(supplierById);
  const { completeAction } = useBuyerGettingStarted(false);

  const newOrderUrl = BuyerNewOrder.toUrl({ tenancyId }, { supplierId });

  const onConnect: ConnectionHandler = useCallback(
    async ({ tradingRelationship }) => {
      try {
        const { status: newStatus } = await promisifySaga(
          dispatch,
          inviteToTradeRequest
        )({
          supplierId,
          hasTradingRelationship: tradingRelationship === CURRENTLY_TRADING
        });

        completeAction({ actionType: GettingStartedActionType.AddSupplier });

        const isPendingInvite = isPending(newStatus);
        showSnackbar(
          <FormattedMessage
            id={isPendingInvite ? messages.INVITESENTTO : messages.CONNECTEDTO}
            values={{ supplierName: tradingName }}
          />
        );

        if (isPendingInvite) {
          history.replace(BuyerSupplier.toUrl({ tenancyId, supplierId }));
        } else {
          // Invites to provisional suppliers are automatically accepted
          history.replace(newOrderUrl);
        }
      } catch (error) {
        // Redirect to the supplier's profile if the invite failed
        history.replace(BuyerSupplier.toUrl({ tenancyId, supplierId }));
      }
    },
    [completeAction, dispatch, history, messages, newOrderUrl, showSnackbar, supplierId, tenancyId, tradingName]
  );

  const onClose = useCallback(() => {
    closeConnectionModal();
    if (history.length > 1) {
      history.goBack();
    } else {
      // Fallback to redirecting to the supplier's profile if there's no history
      history.replace(BuyerSupplier.toUrl({ tenancyId, supplierId }));
    }
  }, [closeConnectionModal, history, supplierId, tenancyId]);

  useEffect(() => {
    if (
      // Wait for the data to finish loading
      !loading &&
      currentStatus &&
      // Handle the case where `state.directory.supplierById` is already populated with old data
      reduxSupplierId === supplierId
    ) {
      if (isConnected(currentStatus)) {
        history.replace(newOrderUrl);
      } else if (isPending(currentStatus) || !canConnectToSupplier) {
        history.replace(BuyerSupplier.toUrl({ tenancyId, supplierId }));
      } else {
        openConnectionModal();
      }
    }
  }, [
    canConnectToSupplier,
    connectAction,
    currentStatus,
    history,
    loading,
    newOrderUrl,
    openConnectionModal,
    reduxSupplierId,
    supplierId,
    tenancyId
  ]);

  useMount(() => {
    // Check if the buyer is connected to the supplier
    dispatch(fetchSupplierDetailsRequest({ id: supplierId }));
  });

  return (
    <>
      <LoadingScreen />
      <ConnectionModal supplier={supplierById} onConnect={onConnect} onClose={onClose} open={isConnectionModalOpen} />
    </>
  );
};

/**
 * Wrapper around the above that ensures the directory reducer/saga are loaded
 * since it relies on them being present to function.
 */
export default function OrderNowRedirectWrapper(): JSX.Element | null {
  const directoryInjected = useInjectDirectory();
  return directoryInjected ? <OrderNowRedirect /> : null;
}
