import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button, { ButtonProps } from "./index";

const useStyles = makeStyles()({
  button: {
    borderRadius: "24px"
  }
});
/**
 * A button made to look like a FAB, used in the nav for the "New order" button.
 */
const FakeFabButton: FunctionComponent<ButtonProps> = ({ children, className, ...props }) => {
  const { classes, cx } = useStyles();
  return (
    <Button className={cx(classes.button, className)} color="secondary" variant="contained" {...props}>
      {children}
    </Button>
  );
};

export default FakeFabButton;
