import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put, select } from "redux-saga/effects";

import { postDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";
import { PriceListProduct } from "types/api/generated/supplier";

import { updatePriceListProductsFailure, UpdatePriceListProductsRequest } from "../reducers/priceListProducts";

/**
 * Call the API to update a list of products in it.
 */
function postPriceListProducts(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string,
  productDetails: PriceListProduct[]
): Promise<Supplier.V1PricelistsProductsCreate.ResponseBody> {
  return postDataToApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/products`,
    tenancyId,
    {
      priceListId,
      productDetails
    }
  );
}

/**
 * Saga to update the values of some products in a price list.
 */
export default function* updatePriceListProducts({
  payload: { failureCallback, priceListId, productDetails, successAction, successCallback }
}: UpdatePriceListProductsRequest): Generator {
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const tenancyId = (yield select(state => state.tenancy.companyId)) as string;

  try {
    const response = (yield call(
      postPriceListProducts,
      auth,
      tenancyId,
      priceListId,
      productDetails
    )) as Supplier.V1PricelistsProductsCreate.ResponseBody;
    yield put(
      successAction({
        priceListId,
        productDetails: response
      })
    );
    if (successCallback) {
      yield call(successCallback, response);
    }
    return response;
  } catch (error) {
    yield put(updatePriceListProductsFailure(error));
    if (failureCallback) {
      yield call(failureCallback, error as Error);
    }
  }
}
