import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

import {
  fetchSupplierDetailsFailure,
  FetchSupplierDetailsRequest,
  fetchSupplierDetailsSuccess
} from "../reducers/supplierById";

/**
 * Saga for calling the `/buyer/v1/suppliers/{id}` endpoint, to fetch a
 * connected supplier to the current buyer
 */
export default createSagaNext<FetchSupplierDetailsRequest>(
  (auth0, tenancyId, { id }) => {
    return fetchDataFromApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/${id}`, tenancyId);
  },
  {
    successAction: fetchSupplierDetailsSuccess,
    failureAction: fetchSupplierDetailsFailure
  }
);
