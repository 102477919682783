import { FunctionComponent, ReactElement, useEffect } from "react";
import { matchPath, Route, RouteComponentProps, RouteProps, useLocation } from "react-router-dom";

import { getHasPageViewBeenTracked, setHasPageViewBeenTracked, useAnalytics } from "utils/analytics";

interface TrackedRouteWrapperProps {
  component: RouteProps["component"];
  render: RouteProps["render"];
  renderProps: RouteComponentProps;
}

const TrackedRouteWrapper: FunctionComponent<TrackedRouteWrapperProps> = ({
  component: Component,
  render,
  renderProps
}) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const routePattern = renderProps.match.path;

  useEffect(() => {
    if (
      // Prevent the page from being double tracked when the feature route matches the full URL path
      // (which happens when viewing the root route of a feature)
      !getHasPageViewBeenTracked() &&
      // Check that the route matches the full URL path, which most likely indicates that this is the route for the current page
      // (to prevent the top level feature route from triggering a page view when viewing a sub-route)
      matchPath(location.pathname, {
        path: routePattern,
        exact: true
      })
    ) {
      setHasPageViewBeenTracked(true);
      // Track the page view using the route pattern as the page name
      analytics.page(routePattern);
    }
  }, [analytics, location.pathname, routePattern]);

  // Support both the `component` and `render` <Route> props
  if (Component) {
    return <Component {...renderProps} />;
  }
  if (render) {
    return render(renderProps) as ReactElement | null;
  }

  throw new Error("TrackedRoute doesn't support passing children");
};

// Don't support the `children` option to make it easier to wrap
// https://v5.reactrouter.com/web/api/Route/children-func
export interface TrackedRouteProps extends Omit<RouteProps, "children"> {}

/**
 * A wrapper around the react-router <Route> component that does automatic page view tracking with the route pattern.
 *
 * This is required because react-router doesn't give you access to the route pattern of the current page in any central way.
 */
const TrackedRoute: FunctionComponent<TrackedRouteProps> = ({ component, render, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={props => {
        return <TrackedRouteWrapper component={component} render={render} renderProps={props} />;
      }}
    />
  );
};

export default TrackedRoute;
