import { Alpha2Code } from "types/api/generated/directory";

import useAppSelector from "./useAppSelector";

export interface CountryConfig {
  salesTaxName: string;
}

/**
 * Returns the country specific config for the current company's country.
 *
 * TODO: This is a temporary solution until the API returns it via the navigation data.
 */
export default function useCountryConfig(): CountryConfig {
  const countryCode = useAppSelector(state => state.tenancy.country.code);

  if (countryCode === Alpha2Code.AU) {
    return {
      salesTaxName: "GST"
    };
  }

  if (countryCode === Alpha2Code.US) {
    return {
      salesTaxName: "tax"
    };
  }

  // Use NZ as the default
  return {
    salesTaxName: "GST"
  };
}
