import { createUserFailure, CreateUserRequest, createUserSuccess } from "redux/reducers/userProfile";
import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

/**
 * Saga to call POST `/directory/internal/users` to create a new user from the
 * current auth0 user.
 */
export default createSagaNext<CreateUserRequest>(
  auth0 => {
    return postDataToApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/directory/internal/users`);
  },
  {
    successAction: createUserSuccess,
    failureAction: createUserFailure
  }
);
