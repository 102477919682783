import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import Header from "components/Header";
import Page from "components/Page";
import PaidFeaturePromotion, { Perk, Quote } from "components/PaidFeaturePromotion/PaidFeaturePromotion";
import useMessages from "i18n/hooks/useMessages";
import BoltIcon from "media/gated-sections/bolt.svg?react";
import CompanyPhoto from "media/gated-sections/company-images/waitoa-photo.jpg";
import CompanyLogo from "media/gated-sections/company-logos/waitoa-logo.png";
import PriceListsThumbnail from "media/gated-sections/thumbnails/price-lists.png";
import VariablePricingIcon from "media/gated-sections/variable-pricing.svg?react";
import WandIcon from "media/gated-sections/wand.svg?react";
import { Feature } from "types/api/generated/global-internal";

import strings from "./PriceListGate.strings.json";

interface PriceListGateProps {
  feature?: Feature;
}

const PriceListGate: FunctionComponent<PriceListGateProps> = ({ feature }) => {
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();

  const header = <FormattedMessage id={messages.HEADER} />;
  const subtitle = <FormattedMessage id={messages.SUBHEADER} />;
  const description = <FormattedMessage id={messages.DESCRIPTION} />;
  const upgradePrefill = formatter.format(messages.UPGRADE_PREFILL);
  const demoPrefill = formatter.format(messages.DEMO_PREFILL);
  const videoId = "703100474";
  const perks: Perk[] = [
    {
      icon: VariablePricingIcon,
      title: <FormattedMessage id={messages.PERK1_TITLE} />,
      description: <FormattedMessage id={messages.PERK1_DESCRIPTION} />
    },
    {
      icon: WandIcon,
      title: <FormattedMessage id={messages.PERK2_TITLE} />,
      description: <FormattedMessage id={messages.PERK2_DESCRIPTION} />
    },
    {
      icon: VisibilityIcon,
      title: <FormattedMessage id={messages.PERK3_TITLE} />,
      description: <FormattedMessage id={messages.PERK3_DESCRIPTION} />
    },
    {
      icon: BoltIcon,
      title: <FormattedMessage id={messages.PERK4_TITLE} />,
      description: <FormattedMessage id={messages.PERK4_DESCRIPTION} />
    }
  ];
  const quote: Quote = {
    image: CompanyPhoto,
    text: <FormattedMessage id={messages.QUOTE} />,
    author: <FormattedMessage id={messages.QUOTE_AUTHOR} />,
    logo: CompanyLogo
  };

  return (
    <Page header={<Header title={formatter.format(messages.TITLE)} />}>
      <PaidFeaturePromotion
        header={header}
        subheader={subtitle}
        description={description}
        upgradePrefill={upgradePrefill}
        demoPrefill={demoPrefill}
        videoId={videoId}
        videoThumbnail={PriceListsThumbnail}
        perks={perks}
        quote={quote}
        feature={feature}
      />
    </Page>
  );
};

export default PriceListGate;
