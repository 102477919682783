import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { deletePromptSuccess } from "redux/actions/deletePrompt";
import { GlobalInternal } from "types/api";
import { MenuGroupType, NavigationResult } from "types/api/generated/global-internal";
import { FailureAction, RequestAction } from "types/redux-helpers";
import { isError } from "utils/Errors";

import { hideModalsSuccess } from "../actions/hideModals";

interface Preferences {
  defaultView: MenuGroupType | null;
}

export interface NavigationDataState
  extends Omit<
    NavigationResult,
    | "buyerGettingStarted"
    | "connections"
    | "features"
    | "preferences"
    | "supplierGettingStarted"
    | "userProfile"
    | "companyProfile"
  > {
  error?: string;
  loading: boolean;
  preferences: Preferences;
}

export const initialState: NavigationDataState = {
  loading: true,
  billing: {
    features: []
  },
  companies: [],
  permissions: {
    companyId: "",
    permissions: []
  },
  preferences: {
    defaultView: null
  },
  userSettings: {
    acceptedTermsConditionsDateUtc: null,
    hasAcceptedLatestTermsConditions: false,
    userFlags: {
      hideApproveOrderPaymentModal: false,
      hideCannotSyncOrderModal: false,
      hideApproveOrderConfirmationModal: false,
      hideBasePriceInfoModal: false,
      hidePriceMismatchWarningModal: false,
      hideUpdateProductInProviderModal: false,
      hideOrderLineProblemsModalForProviderOrders: false,
      hideFoodstuffsOrderEditWarningModal: false,
      hideWoolworthsOrderEditWarningModal: false,
      hideMobilePushNotificationsModal: false,
      hideWoolworthsShipInvoiceWarningModal: false,
      hideScheduledOrderForceApprovedWarningModal: false
    }
  },
  clientApiTokens: {
    streamToken: ""
  },
  availableActionablePrompts: []
};

export type MenuOverride = Pick<GlobalInternal.InternalNavigationList.RequestQuery, "menuOverride">;

export type FetchNavigationDataRequest = RequestAction<
  { companyId?: string } & MenuOverride,
  GlobalInternal.InternalNavigationList.ResponseBody
>;
type FetchNavigationDataSuccess = PayloadAction<GlobalInternal.InternalNavigationList.ResponseBody>;

const navigationDataSlice = createSlice({
  name: "navigationData",
  initialState,
  reducers: {
    fetchNavigationDataRequest: (state, _action: FetchNavigationDataRequest) => ({
      ...state,
      loading: true
    }),
    fetchNavigationDataSuccess: (
      state,
      { payload: { buyerGettingStarted, features, supplierGettingStarted, ...rest } }: FetchNavigationDataSuccess
    ) => ({
      ...state,
      ...rest,
      loading: false
    }),
    fetchNavigationDataFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error: isError(error) ? error.message : undefined,
      loading: false
    }),
    fetchNavigationDataSilentlyRequest: (state, _action: FetchNavigationDataRequest) => state
  },
  extraReducers: builder => {
    builder
      // Update modal settings when choosing "don't show again"
      .addCase(hideModalsSuccess, (state, { payload }) => ({
        ...state,
        userSettings: {
          ...state.userSettings,
          userFlags: {
            ...payload
          }
        }
      }))
      .addCase(deletePromptSuccess, (state, { payload: prompt }) => ({
        ...state,
        availableActionablePrompts: state.availableActionablePrompts.filter(it => it.type !== prompt)
      }));
  }
});

export const {
  fetchNavigationDataRequest,
  fetchNavigationDataSuccess,
  fetchNavigationDataFailure,
  fetchNavigationDataSilentlyRequest
} = navigationDataSlice.actions;
export default navigationDataSlice.reducer;
