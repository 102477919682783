import { JSX, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMount } from "react-use";

import useIsSupplierOnboarding from "features/suppliers/getting-started/useIsSupplierOnboarding";
import LoadingScreen from "pages/LoadingScreen";
import { loadTenancyIdRequest } from "redux/reducers/tenancy";
import { BuyerDashboard, CompanyRoot, Signup, SupplierDashboard, SupplierGettingStarted } from "routes/Routes";
import { MenuGroupType } from "types/api/generated/global-internal";
import { logger } from "utils/Datadog";
import useAppSelector from "utils/hooks/useAppSelector";
import { TENANCY_NONE_FOUND } from "utils/Tenancy";

/**
 * This route is in charge of directing the user to their 'Home' from the root.
 * For buyers this is buyer/orders.  For suppliers this is supplier/orders.  For
 * a user with no companies it's the new user page
 */
export default function RootRedirect(): JSX.Element {
  const history = useHistory();
  const { search } = useLocation();
  const { tenancyId: tenancyIdFromUrl } = useParams<typeof CompanyRoot.params>();

  // We still need to get the `tenancyId` from `state.tenancy` here because that's where
  // `loadTenancyIdRequest()` saves it and it's used to check for TENANCY_NONE_FOUND below
  const dispatch = useDispatch();
  const { companyId: tenancyId, loading: companyLoading } = useAppSelector(state => state.tenancy);
  const {
    loading: navigationDataLoading,
    preferences: { defaultView }
  } = useAppSelector(state => state.navigationData);

  const isSupplierOnboarding = useIsSupplierOnboarding();

  useEffect(() => {
    if (!companyLoading && tenancyId === TENANCY_NONE_FOUND) {
      logger.info("RootRedirect sending user to the Signup flow");
      history.replace(Signup.toUrl(undefined, new URLSearchParams(search)));
    }
  }, [companyLoading, tenancyId, history, search]);

  useEffect(() => {
    if (!navigationDataLoading && defaultView && tenancyId) {
      if (defaultView === MenuGroupType.Supplier) {
        const route = isSupplierOnboarding
          ? SupplierGettingStarted.toUrl({ tenancyId })
          : SupplierDashboard.toUrl({ tenancyId });

        history.replace(route);
      } else {
        history.replace(BuyerDashboard.toUrl({ tenancyId }));
      }
    }
  }, [defaultView, history, isSupplierOnboarding, navigationDataLoading, tenancyId]);

  useMount(() => {
    if (!tenancyId && !tenancyIdFromUrl) {
      dispatch(
        loadTenancyIdRequest({
          failureCallback: () => {}
        })
      );
    }
  });

  return <LoadingScreen />;
}
