import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { putDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import { renamePriceListSuccess, renamePriceListFailure, RenamePriceListRequest } from "../reducers/priceList";

/**
 * Calls the API to rename a price list.
 */
function renamePriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string,
  name: string
): Promise<Supplier.V1PricelistsUpdate.ResponseBody> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}`;
  return putDataToApiWrapper(auth, urlWithDomain, tenancyId, {
    priceListId,
    name
  });
}

/**
 * Saga for renaming a price list with the given name.
 */
export default createSaga(renamePriceList, {
  prepareApiMethodParams: ({ priceListId, name }: RenamePriceListRequest["payload"]) => [priceListId, name],
  successAction: renamePriceListSuccess,
  errorFailureAction: renamePriceListFailure,
  rethrowError: false
});
