import { MouseEvent, MouseEventHandler, useCallback, useMemo, useState } from "react";

export type LongRunningAction<E> = (event: MouseEvent<E>, ...args: any[]) => Promise<unknown> | unknown;
type LongRunningClickHandlerResult<E> = [LongRunningAction<E>, boolean];

/**
 * React hook for creating a click handler with a flag to indicate if the action
 * being performed is still running or not.  This works by providing an action
 * that returns a `Promise`.  Useful for disabling components to prevent
 * doubling-up of actions.
 */
export default function useLongRunningActionHandler<E = Element>(
  action?: LongRunningAction<E>
): LongRunningClickHandlerResult<E> {
  const [isActionRunning, setActionRunning] = useState(false);

  const clickHandler: MouseEventHandler<E> = useCallback(
    (...args) => {
      if (action) {
        const clickResult = action(...args);
        if (clickResult instanceof Promise) {
          setActionRunning(true);
          clickResult.finally(() => {
            setActionRunning(false);
          });
        }
      }
    },
    [action]
  );

  return useMemo(() => [clickHandler, isActionRunning], [clickHandler, isActionRunning]);
}
