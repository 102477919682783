import { Auth0ContextInterface } from "@auth0/auth0-react";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, PayloadAction } from "@reduxjs/toolkit";
import { call, getContext, select } from "redux-saga/effects";

import { ApiValidationProblem } from "types/api/generated/supplier";
import { RequestAction } from "types/redux-helpers";

import { withErrorFailureHandler, withSuccessHandler } from "./createSaga";

type ApiMethod<A extends PayloadAction<any>, R> = (
  auth0: Auth0ContextInterface,
  tenancyId: string,
  payload: A["payload"]
) => Promise<R>;

type CreateSagaConfig<R, E> = {
  successAction: ActionCreatorWithPayload<R>;
  failureAction: ActionCreatorWithPayload<E> | ActionCreatorWithoutPayload;
  rethrowError?: boolean;
};

type Saga<A extends PayloadAction<any>, R> = (action: A) => Generator<unknown, R>;

type GetResponseType<A> = A extends RequestAction<any> ? Parameters<A["payload"]["successCallback"]>[0] : unknown;
type GetErrorType<A> =
  A extends RequestAction<any> ? Parameters<A["payload"]["failureCallback"]>[0] : ApiValidationProblem | Error;

/**
 * Create a generator function that can be used as a saga for calling an API
 * method.
 */
export function makeApiCall<A extends PayloadAction<any>, R>(apiMethod: ApiMethod<A, R>): Saga<A, R> {
  return function* apiSaga(action: A): Generator {
    const auth = (yield getContext("auth0")) as Auth0ContextInterface;
    const tenancyId = (yield select(state => state.tenancy.companyId)) as string;

    // Extract failure/success callbacks so we don't have to worry about them in the method call
    const { failureCallback, successCallback, ...payload } = action.payload ?? {};

    return yield call(apiMethod, auth, tenancyId, payload);
  };
}

/**
 * Compose a saga that calls out to the API with a single method call, unifying
 * a lot of the boilerplate that goes into its creation.
 *
 * This method is different from the one in `createSaga.js` in that:
 *  - it's attempting to simplify things like removing the methods for preparing
 *    method parameters
 *  - no more deprecated `failureAction` (`failureAction` here now redirects to
 *    the behaviour of `errorFailureAction` of the JS file)
 *  - it's in TypeScript
 */
export default function createSagaNext<A extends RequestAction<any, R, E>, R = GetResponseType<A>, E = GetErrorType<A>>(
  apiMethod: ApiMethod<A, R>,
  config: CreateSagaConfig<R, E>
): Saga<A, R> {
  const { successAction, failureAction, rethrowError } = config;
  let saga = makeApiCall(apiMethod);
  if (successAction) {
    saga = withSuccessHandler(saga, successAction);
  }
  if (failureAction) {
    saga = withErrorFailureHandler(saga, failureAction, rethrowError);
  }
  return saga;
}
