import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { PopoverOrigin } from "@mui/material/Popover";
import MSelect, { SelectProps as MSelectProps } from "@mui/material/Select";
import { ReactNode, useCallback, useMemo, useState } from "react";

export interface SelectWithoutFormikProps<T>
  extends Pick<FormControlProps, "className" | "disabled" | "error" | "fullWidth" | "margin">,
    Omit<MSelectProps<T>, "className" | "fullWidth" | "margin" | "variant"> {
  helperText?: ReactNode;
  id: string;
  label?: ReactNode;
  name: string;
  menuProps?: MSelectProps<T>["MenuProps"];
}

const anchorOrigin: PopoverOrigin = {
  horizontal: "left",
  vertical: "bottom"
};
const transformOrigin: PopoverOrigin = {
  horizontal: "left",
  vertical: "top"
};

/**
 * Customizations to the Material UI `<Select>` component, styled for the
 * defaults of our app.  All of the defaults can be overridden as all props are
 * spread over the original `<Select>`.
 *
 * All inputs should be in a form of some kind, however some don't currently and
 * it will either take a bit of refactoring to add them or they should remain
 * the exception and not the norm.  So this `<Select>` variant exists for those
 * cases.
 */
export default function SelectWithoutFormik<T>({
  children,
  className,
  disabled,
  error,
  fullWidth,
  helperText,
  id,
  label,
  margin = "dense",
  inputProps,
  menuProps,
  ...selectProps
}: SelectWithoutFormikProps<T>): JSX.Element {
  const labelId = `${id}-label`;
  const [focused, setFocused] = useState(false);

  const onOpen = useCallback(() => {
    setFocused(true);
  }, []);

  const onClose = useCallback(() => {
    setFocused(false);
  }, []);

  const localMenuProps = useMemo(
    () => ({
      anchorOrigin,
      transformOrigin,
      ...menuProps
    }),
    [menuProps]
  );

  return (
    <FormControl
      disabled={disabled}
      error={error}
      focused={focused}
      fullWidth={fullWidth}
      margin={margin}
      variant="filled"
      className={className}
      hiddenLabel={!label}
    >
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <MSelect
        id={id}
        labelId={labelId}
        disabled={disabled}
        error={error}
        onClose={onClose}
        onOpen={onOpen}
        {...selectProps}
        inputProps={inputProps}
        MenuProps={localMenuProps}
      >
        {children}
      </MSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
