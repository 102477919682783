import { Auth0ContextInterface, OAuthError, withAuth0 } from "@auth0/auth0-react";
import { GenericError } from "@auth0/auth0-spa-js";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FormattedMessage, MessageFormatterContext, withMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Component, ErrorInfo, ReactNode } from "react";
import { connect } from "react-redux";
import { withStyles } from "tss-react/mui";

import HelpButton from "components/Header/HelpButton";
import { makeMessageBundle } from "i18n/hooks/useMessages";
import LoadingScreen from "pages/LoadingScreen";
import { GlobalReduxState } from "types/redux";
import { logger } from "utils/Datadog";
import { trackError } from "utils/Errors";
import { HTTP_STATUS_FORBIDDEN } from "utils/HttpStatuses";

import strings from "./ErrorBoundary.strings.json";

/**
 * Check our error isn't a forbidden error so we can handle these
 * separately from the normal error boundary.
 *
 * @return Whether the error thats passed is one we want to throw the Error Boundary for.
 */
export const hasValidError = (error?: Error | string | null): boolean => {
  if (typeof error !== "string" && error?.message === HTTP_STATUS_FORBIDDEN) {
    return false;
  }
  return Boolean(error);
};

const isAuth0Error = (error?: any): boolean => {
  return (
    error?.error === "invalid_grant" ||
    error?.error === "login_required" ||
    error?.error === "missing_refresh_token" ||
    // The old saga error handling code converts Error objects to plain objects with a message property (i.e: `{ message: "Unknown or invalid refresh token."}`),
    // so we need to do a string search of the message.
    // This error occurs when the refresh token is invalid and the refresh token fallback is disabled.
    error?.message?.includes?.("invalid refresh token") ||
    error?.message?.includes?.("Missing Refresh Token") ||
    error instanceof OAuthError ||
    error instanceof GenericError
  );
};

export interface ErrorBoundaryProps extends Pick<MessageFormatterContext, "formatter"> {
  auth0: Auth0ContextInterface;
  children?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  classes?: Partial<Record<"page" | "appBar" | "toolbar" | "main" | "header" | "helpButton" | "button", string>>;
  error?: string | Error | null;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  hasAuth0Error: boolean;
}

/**
 * Why is this a class component?
 *
 * Simply, `componentDidCatch` cannot be read as a hook at the moment.
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false, hasAuth0Error: false };
  }

  /**
   * This gets called when an uncaught error occurs when executing React lifecycle methods, like render.
   * This doesn't include errors that occur in callbacks.
   */
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> | null {
    if (hasValidError(error)) {
      if (isAuth0Error(error)) {
        return { hasError: true, hasAuth0Error: true };
      }

      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    return null;
  }

  /**
   * This gets called when `state.globalError.error` is set, which is only set by the Redux Saga error handlers.
   * https://github.com/UpstockApp/web-ui/blob/005ba74cfd6951fd19ea5c2f2ca7a445f4f1061f/src/redux/tasks/createSaga.js#L116
   */
  static getDerivedStateFromProps(
    { error, auth0 }: ErrorBoundaryProps,
    prevState: ErrorBoundaryState
  ): Partial<ErrorBoundaryState> | null {
    if (error && hasValidError(error) && !prevState.hasError) {
      const errorMessage: string | undefined =
        typeof error === "string"
          ? error
          : error.message ||
            // We throw the response body as a plain object rather than an Error object for HTTP 400 responses
            (error as any).errors?.[0]?.reason || // Try to get the validation error message from HTTP 400 responses
            (error as any).title;

      if (isAuth0Error(error)) {
        // Call logout when there's an Auth0 error clear out any bad state the user might have
        logger.info(`Auth0 error: ${errorMessage}`, { error }, error instanceof Error ? error : undefined);
        auth0.logout();
        return { hasError: true, hasAuth0Error: true };
      }

      trackError(error, `App crashed with error: \`${errorMessage}\``);

      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    return null;
  }

  /**
   * This gets called when an uncaught error occurs when executing React lifecycle methods, like render.
   * This doesn't include errors that occur in callbacks.
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { auth0 } = this.props;

    // Throw errors when running the unit tests so they don't get swallowed by the error boundary
    if (process.env.NODE_ENV === "test") {
      throw error;
    }

    if (isAuth0Error(error)) {
      // Call logout when there's an Auth0 error clear out any bad state the user might have
      logger.info(`Auth0 error: ${error.message}`, { error }, error);
      auth0.logout();
      return;
    }

    trackError(error, `App crashed with error: \`${error.message}\``, {
      componentStack: errorInfo.componentStack ?? undefined
    });
  }

  render(): ReactNode {
    const { hasError, hasAuth0Error } = this.state;
    const { children, formatter } = this.props;

    const classes = withStyles.getClasses(this.props);
    const messages = makeMessageBundle(strings, formatter.locale);

    if (hasError) {
      // Don't render the error page when there's an Auth0 error because we're just redirecting to the login page
      if (hasAuth0Error) {
        return <LoadingScreen />;
      }

      return (
        <div className={classes.page}>
          <AppBar color="transparent" position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <HelpButton className={classes.helpButton} />
            </Toolbar>
          </AppBar>
          <main className={classes.main}>
            <header className={classes.header}>
              <Typography variant="h1">
                <FormattedMessage id={messages.HEADER} />
              </Typography>
            </header>
            <Container maxWidth="xs">
              <Typography paragraph>
                <FormattedMessage
                  id={messages.INFO_1}
                  values={{ statusPage: { href: "https://status.upstock.app/" } }}
                />
              </Typography>
              <Typography paragraph>
                <FormattedMessage id={messages.INFO_2} />
              </Typography>

              <Button component="a" color="primary" variant="contained" href="/" className={classes.button}>
                <FormattedMessage id={messages.GO_HOME} />
              </Button>
              <Typography>
                <FormattedMessage id={messages.CONTACT_SUPPORT} />
              </Typography>
            </Container>
          </main>
        </div>
      );
    }

    return children;
  }
}

export default withMessageFormatter(
  withAuth0(
    // @ts-expect-error
    connect((state: GlobalReduxState) => ({ error: state.globalError.error }))(
      withStyles(
        // @ts-expect-error
        ErrorBoundary,
        theme => ({
          page: {
            backgroundColor: "white",
            height: "100vh"
          },
          appBar: {
            boxShadow: "none"
          },
          toolbar: {
            display: "flex",
            justifyContent: "flex-end"
          },
          main: {
            boxSizing: "border-box",
            textAlign: "center"
          },
          header: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(5)
          },
          helpButton: {
            color: theme.palette.primary.main
          },
          button: {
            margin: theme.spacing(2, 0, 3)
          }
        })
      )
    )
  )
);
