import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import { LocationDescriptor, LocationState } from "history";
import { useCallback } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export interface ListItemNavLinkProps<S = LocationState>
  extends Omit<ListItemButtonProps, "onClick">,
    Pick<NavLinkProps<S>, "activeClassName" | "isActive"> {
  onClick?: (to: LocationDescriptor<S>) => void;
  to: LocationDescriptor<S>;
}

/**
 * A combined Material UI `<ListItem>` that renders a react-router `<NavLink>`
 * so that the list item can contain standard links.
 */
export default function ListItemNavLink<S>({ children, to, onClick, ...rest }: ListItemNavLinkProps<S>): JSX.Element {
  const clickHandler = useCallback(() => {
    onClick?.(to);
  }, [onClick, to]);

  return (
    <li>
      <ListItemButton component={NavLink} onClick={clickHandler} role="link" to={to} {...rest}>
        {children}
      </ListItemButton>
    </li>
  );
}
