import { CalculatedDeliverySchedule, CalculatedDeliveryScheduleDay } from "types/api/generated/buyer-internal";
import { DayOfWeek, WeeklyDeliveryScheduleDay, WeeklyDeliveryScheduleDetails } from "types/api/generated/supplier";
import dayjs, { Dayjs } from "utils/dayjs";

export function createDefaultDeliveryScheduleDay(dayOfWeek: DayOfWeek): WeeklyDeliveryScheduleDay {
  return {
    deliveryAvailable: true,
    deadline: {
      time: "23:30",
      day: dayOfWeek
    }
  };
}

export function createDefaultDeliverySchedule(): WeeklyDeliveryScheduleDetails {
  return {
    monday: createDefaultDeliveryScheduleDay(DayOfWeek.Sunday),
    tuesday: createDefaultDeliveryScheduleDay(DayOfWeek.Monday),
    wednesday: createDefaultDeliveryScheduleDay(DayOfWeek.Tuesday),
    thursday: createDefaultDeliveryScheduleDay(DayOfWeek.Wednesday),
    friday: createDefaultDeliveryScheduleDay(DayOfWeek.Thursday),
    saturday: createDefaultDeliveryScheduleDay(DayOfWeek.Friday),
    sunday: createDefaultDeliveryScheduleDay(DayOfWeek.Saturday)
  };
}

export function createDefaultCalculatedDeliverySchedule(): CalculatedDeliverySchedule {
  return {
    monday: { deliveryAvailable: true },
    tuesday: { deliveryAvailable: true },
    wednesday: { deliveryAvailable: true },
    thursday: { deliveryAvailable: true },
    friday: { deliveryAvailable: true },
    saturday: { deliveryAvailable: true },
    sunday: { deliveryAvailable: true }
  };
}

function getScheduleForDay<S extends CalculatedDeliverySchedule | WeeklyDeliveryScheduleDetails>(
  deliverySchedule: S,
  day: Dayjs
): S["monday"] {
  // 0 (Sunday) to 6 (Saturday)
  if (day.day() === 0) {
    return deliverySchedule.sunday;
  }
  if (day.day() === 1) {
    return deliverySchedule.monday;
  }
  if (day.day() === 2) {
    return deliverySchedule.tuesday;
  }
  if (day.day() === 3) {
    return deliverySchedule.wednesday;
  }
  if (day.day() === 4) {
    return deliverySchedule.thursday;
  }
  if (day.day() === 5) {
    return deliverySchedule.friday;
  }
  if (day.day() === 6) {
    return deliverySchedule.saturday;
  }

  return deliverySchedule.monday;
}

function findScheduleForDate(
  deliverySchedule: CalculatedDeliverySchedule,
  date: Dayjs,
  timezone: string
): CalculatedDeliveryScheduleDay | undefined {
  const scheduleDays: CalculatedDeliveryScheduleDay[] = Object.values(deliverySchedule);
  return scheduleDays.find(
    scheduleDay => scheduleDay.deliveryDateUtc && dayjs(scheduleDay.deliveryDateUtc).tz(timezone).isSame(date, "day")
  );
}

export function isDeliveryAvailableForDay(
  deliverySchedule: CalculatedDeliverySchedule | WeeklyDeliveryScheduleDetails,
  day: Dayjs | null
): boolean {
  // Day is nullable because the `shouldDisableDate` date is nullable in `<Calendar>`
  if (!day) {
    return false;
  }

  return getScheduleForDay(deliverySchedule, day).deliveryAvailable;
}

export function isDayPastCutoffTime(
  deliverySchedule: CalculatedDeliverySchedule,
  date: Dayjs,
  timezone: string
): boolean {
  const now = dayjs().tz(timezone);

  const schedule = findScheduleForDate(deliverySchedule, date, timezone);
  if (!schedule || !schedule.cutOffDateUtc) {
    return false;
  }

  // Schedule not available, return as if 'now' is past the cutoff time so as
  // to act like an invalid result and cause our loop to continue to try the
  // next schedule.
  if (!schedule.deliveryAvailable) {
    return true;
  }

  return now.isAfter(dayjs(schedule.cutOffDateUtc).tz(timezone));
}

export function getNextAvailableDeliveryDay(deliverySchedule: CalculatedDeliverySchedule, timezone: string): Dayjs {
  const twoWeeks = dayjs().tz(timezone).add(2, "weeks");
  let nextAvailableDay = dayjs().tz(timezone);

  while (
    !getScheduleForDay(deliverySchedule, nextAvailableDay).deliveryAvailable ||
    isDayPastCutoffTime(deliverySchedule, nextAvailableDay, timezone)
  ) {
    nextAvailableDay = nextAvailableDay.add(1, "day");

    // Fail safe to prevent accidental infinite loops
    if (nextAvailableDay.isAfter(twoWeeks)) {
      break;
    }
  }

  return nextAvailableDay;
}
