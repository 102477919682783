import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

import useHasPermission from "auth/useHasPermission";
import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { DirectoryInternal, Permission } from "types/api";
import useTenancyId from "utils/hooks/useTenancyId";

type RequestQuery = DirectoryInternal.InternalGettingStartedList.RequestQuery;
type RequestBody = DirectoryInternal.InternalGettingStartedList.RequestBody;
export type ResponseBody = DirectoryInternal.InternalGettingStartedList.ResponseBody;

export type GettingStartedOptions = RequestQuery & {
  enabled?: boolean;
  // This can be called in situations where the tenancyId isn't in the URL yet,
  // which apiFetch relies on, so allow calls to define their own.
  tenancyId?: string;
};

export const gettingStartedQueryKey = createQueryKeys("getting-started", {
  list: ({ type }: RequestQuery) => [type]
});

export const gettingStartedOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestQuery>(
  ({ type }) => ({
    method: "GET",
    path: "/directory/internal/getting-started",
    searchParams: { type }
  })
);

/**
 * React query hook for retrieving the getting started actions for a company.
 */
export default function useGettingStarted({
  enabled,
  tenancyId,
  ...request
}: GettingStartedOptions): UseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useApiFetch();
  const tenancyIdFromUrl = useTenancyId();
  const canViewGettingStarted = useHasPermission([Permission.ViewGettingStarted]);

  return useQuery({
    queryKey: gettingStartedQueryKey.list(request).queryKey,
    async queryFn() {
      const response = await apiFetch(
        gettingStartedOptions(request, {
          tenancyId: tenancyIdFromUrl ?? tenancyId
        })
      );
      return response.data;
    },
    enabled: enabled && canViewGettingStarted
  });
}
