import { LocationDescriptorObject } from "history";
import { generatePath, matchPath } from "react-router-dom";
import { SetRequired } from "type-fest";

import { Permission } from "types/api";

/**
 * Extracts the params type from the route string. The resulting type can be passed directly to `useParams()`.
 *
 * @example ExtractRouteParams<'/c/:tenancyId/products/import/choose-products'> === { tenancyId: string }
 */
type ExtractRouteParams<T extends string> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
    : T extends `${infer _Start}:${infer Param}`
      ? { [k in Param]: string }
      : {};

/**
 * Inspired by the API of the [react-app-location](https://github.com/bradstiff/react-app-location)
 * package, a `Route` object is defined by a path in the format specified by
 * `react-router` and comes with methods that convert it into something suitable
 * in links, or as a redirect target in client-side navigation.
 *
 * The original `path` is also available as a property for use in `<Route>`
 * components.
 */
export default class Route<P extends string = ""> {
  /**
   * The original `path` used to build this route.  Good for react-router
   * `<Route>` components and their derivatives.
   */
  path: string;

  permissions: Permission[];

  params: ExtractRouteParams<P>;

  /**
   * Constructor, defines a route by its `path-to-regexp` path string.
   */
  constructor(path: P, permissions: Permission[] = []) {
    this.path = path;
    this.permissions = permissions;
    // This is just a dummy property to hold the route params type
    this.params = {} as ExtractRouteParams<P>;
  }

  /**
   * Return whether or not the given URL is a match for this route.
   *
   * @param pathname
   * @param [exact]
   */
  matches(pathname: string, exact = false): boolean {
    return !!matchPath(pathname, {
      path: this.path,
      exact
    });
  }

  /**
   * Creates a domain-relative {@link Location} from this location's
   * {@link Route#path} that can be used in links or as a redirect target when
   * you need to encode some kind of `state` into the object.
   *
   * @param [params]
   *   An object whose keys/values are used to replace the placeholder values in
   *   the original path, eg: a value whose key is called "tenancy" will replace
   *   instances of `:tenancy`.
   * @param [searchParams]
   *   An object or `URLSearchParams` instance that is used to add search
   *   parameters to the returned URL.
   * @param [state]
   *   Optional state to include in the returned location.
   */
  toLocation<S = void>(
    params?: ExtractRouteParams<P>,
    searchParams?: Record<string, any> | URLSearchParams,
    state?: S
  ): SetRequired<LocationDescriptorObject<S>, "pathname" | "search"> {
    let path: string;
    try {
      path = generatePath(this.path, params);
    } catch (error) {
      path = this.path;
    }

    const urlSearchParams = searchParams
      ? searchParams instanceof URLSearchParams
        ? searchParams
        : Object.entries(searchParams).reduce((acc, [key, value]) => {
            if (value || value === 0) {
              acc.set(key, value);
            }
            return acc;
          }, new URLSearchParams())
      : undefined;

    return {
      pathname: path,
      search: urlSearchParams ? `?${urlSearchParams.toString()}` : "",
      state
    };
  }

  /**
   * Creates a domain-relative URL from this location's {@link Route#path}
   * that can be used in links or as a redirect target.
   *
   * @param [params]
   *   An object whose keys/values are used to replace the placeholder values in
   *   the original path, eg: a value whose key is called "tenancy" will replace
   *   instances of `:tenancy`.
   * @param [searchParams]
   *   An object or `URLSearchParams` instance that is used to add search
   *   parameters to the returned URL.
   */
  toUrl(params?: ExtractRouteParams<P>, searchParams?: Record<string, any> | URLSearchParams): string {
    const location = this.toLocation(params, searchParams);
    return location.pathname + location.search;
  }
}
