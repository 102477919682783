/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AcceptBuyerLinkRequest {
  comment?: string | null;
  priceListId?: string | null;
  providerContactId?: string | null;
  createProviderContact: boolean;
  paymentTermsId?: string | null;
  deliveryGroupId?: string | null;
}

export enum AccountIntent {
  PaymentReconciliationClearing = "PaymentReconciliationClearing",
  PaymentReconciliationFees = "PaymentReconciliationFees",
  PaymentReconciliationPayout = "PaymentReconciliationPayout"
}

export enum AccountStatus {
  Onboarding = "Onboarding",
  Active = "Active",
  Disabled = "Disabled"
}

export interface AddCustomerRequest {
  buyerId: string;
}

export type AddDocumentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  /** @default "AddDocumentTimelineMetadata" */
  $type: string;
};

export interface AddPendingBuyerRequest {
  tradingName: string;
  email: string;
  deliveryAddress: string;
  alpha2CountryCode?: string | null;
}

export enum AddressType {
  Physical = "Physical"
}

export type AdvanceOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  ignoreOrderLineWarnings: boolean;
  delivered?: DeliveredOptions | null;
  /** @default "Advance" */
  $type: string;
};

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export enum ApiTokenType {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Webhooks = "Webhooks",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface AssignBuyersToDeliveryGroupRequest {
  buyerId: string;
  isInDeliveryGroup: boolean;
}

export interface BooleanUpdateResult {
  result: boolean;
}

export interface BulkUpdateProductRequest {
  productId: string;
  status?: ProductStatus | null;
  basePrice?: Money | null;
  defaultTaxRates?: CreateDefaultProductTaxRate[] | null;
  categoryId?: string | null;
}

export interface BulkUpdateProductsCommand {
  productDetails: BulkUpdateProductRequest[];
}

export interface BulkUpdateProductsError {
  productId: string;
  problem: ApiValidationProblem;
}

export interface BulkUpdateProductsResponse {
  results: SupplierProductSummary[];
  errors: BulkUpdateProductsError[];
}

export interface BuyerDetails {
  buyerId: string;
  tradingName: string;
  legalBusinessName?: string | null;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  lastOrder?: BuyerOrderSummary | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  shortDescription?: string | null;
  description?: string | null;
  website?: string | null;
  priceList?: PriceListSummary | null;
  paymentTerms?: PaymentTermsRef | null;
  providerContact?: ProviderContactSummary | null;
  isClaimed: boolean;
  link: BuyerLink;
  settings?: BuyerSettings | null;
  tags: LinkedBuyerTag[];
  standingOrderSchedules: StandingOrderSchedule[];
  deliveryGroup?: DeliveryGroupRef | null;
  issues: (UtilRequiredKeys<StandingOrderCannotBeDelivered, "$type"> & {
    $type: "StandingOrderCannotBeDelivered";
  })[];
  externalReferences: ((
    | UtilRequiredKeys<BuyerExternalReferenceProviderContact, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceExternalIdentifier, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceTag, "$type">
  ) & {
    $type: "ProviderContact" | "ExternalIdentifier" | "Tag";
  })[];
}

export interface BuyerExternalMetadata {
  externalReferences: ((
    | UtilRequiredKeys<BuyerExternalReferenceProviderContact, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceExternalIdentifier, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceTag, "$type">
  ) & {
    $type: "ProviderContact" | "ExternalIdentifier" | "Tag";
  })[];
}

export interface BuyerExternalReference {
  $type: "ProviderContact" | "ExternalIdentifier" | "Tag";
}

export type BuyerExternalReferenceExternalIdentifier = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  identifier: string;
  source: IdentifierSource;
  /** @default "ExternalIdentifier" */
  $type: string;
};

export type BuyerExternalReferenceProviderContact = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  identifier: string;
  source: ContactProvider;
  name: string;
  /** @default "ProviderContact" */
  $type: string;
};

export type BuyerExternalReferenceTag = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  name: string;
  value: string;
  /** @default "Tag" */
  $type: string;
};

export interface BuyerIssue {
  buyerId: string;
  category: BuyerIssueCategory;
  $type: "StandingOrderCannotBeDelivered";
}

export enum BuyerIssueCategory {
  StandingOrder = "StandingOrder"
}

export interface BuyerIssueCategorySummary {
  category: BuyerIssueCategory;
  /** @format int32 */
  count: number;
}

export interface BuyerLink {
  status: LinkedBuyerStatus;
  actions: LinkAction[];
  initiatedBy?: BuyerLinkInitiatedBy | null;
  requester?: UserProfile | null;
}

export enum BuyerLinkActions {
  InviteToTrade = "InviteToTrade",
  Cancel = "Cancel",
  Order = "Order",
  Accept = "Accept",
  Decline = "Decline"
}

export enum BuyerLinkInitiatedBy {
  Buyer = "Buyer",
  Supplier = "Supplier"
}

export interface BuyerOrderSummary {
  /** @format int32 */
  orderId: number;
}

export interface BuyerReference {
  buyerId: string;
  tradingName: string;
}

export interface BuyerSettings {
  defaultOrderNote: string;
  disableInvoices: boolean;
  consolidatedInvoices: ConsolidatedInvoicesBuyerSetting;
  autoOrderApproval: OverridableSettingBuyerFlag;
  /** @deprecated */
  paymentRequirement: PaymentRequirement;
}

export interface BuyerSummary {
  buyerId: string;
  tradingName: string;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  priceList?: PriceListSummary | null;
  paymentTerms?: PaymentTermsRef | null;
  deliveryGroup?: DeliveryGroupRef | null;
  issues: (UtilRequiredKeys<StandingOrderCannotBeDelivered, "$type"> & {
    $type: "StandingOrderCannotBeDelivered";
  })[];
  standingOrderSchedules: StandingOrderSchedule[];
  settings: BuyerSettings;
  isClaimed: boolean;
  /** @format date-time */
  lastOrderDateUtc?: string | null;
  relationshipSource?: RelationshipSource | null;
}

export interface BuyerSummaryPagedSet {
  links: PageLinks;
  data: BuyerSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface CalculatedDeliverySchedule {
  monday: CalculatedDeliveryScheduleDay;
  tuesday: CalculatedDeliveryScheduleDay;
  wednesday: CalculatedDeliveryScheduleDay;
  thursday: CalculatedDeliveryScheduleDay;
  friday: CalculatedDeliveryScheduleDay;
  saturday: CalculatedDeliveryScheduleDay;
  sunday: CalculatedDeliveryScheduleDay;
}

export interface CalculatedDeliveryScheduleDay {
  deliveryAvailable: boolean;
  /** @format date-time */
  deliveryDateUtc?: string | null;
  /** @format date-time */
  cutOffDateUtc?: string | null;
}

export type CancelOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  /** @default "Cancel" */
  $type: string;
};

export interface CancelOrderPauseScheduleCommand {
  comment?: string | null;
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
  cancelUpcomingOrders?: boolean | null;
}

export type CardPaymentMethod = UtilRequiredKeys<PaymentMethod, "$type"> & {
  cardType: CardPaymentMethodType;
  /** @format int32 */
  expirationMonth: number;
  /** @format int32 */
  expirationYear: number;
  last4: string;
  country?: Alpha2Code | null;
  failureDetails?: (UtilRequiredKeys<DefaultPaymentFailure, "$type"> | null) &
    ({
      $type: "Default";
    } | null);
  /** @default "Card" */
  $type: string;
};

export enum CardPaymentMethodType {
  Visa = "Visa",
  Mastercard = "Mastercard",
  Amex = "Amex",
  Diners = "Diners",
  Discover = "Discover",
  Jcb = "Jcb",
  UnionPay = "UnionPay"
}

export enum CategoryIcon {
  Apparel = "Apparel",
  Car = "Car",
  Cleaning = "Cleaning",
  Drink = "Drink",
  Food = "Food",
  Kitchen = "Kitchen",
  Office = "Office",
  Packaging = "Packaging",
  Personal = "Personal",
  Pet = "Pet",
  Services = "Services",
  Vaping = "Vaping"
}

export interface CategoryRef {
  id: string;
  name: string;
  fullName: string;
}

export interface CategorySummary {
  id: string;
  name: string;
  fullName: string;
  /** @format int32 */
  level: number;
  isLeaf: boolean;
  isRoot: boolean;
  parentId?: string | null;
  isCoreCategory?: boolean | null;
  /** @format int32 */
  sortOrder?: number | null;
  iconType?: CategoryIcon | null;
}

export type CommentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  message: string;
  images?: Image[] | null;
  /** @default "CommentTimelineMetadata" */
  $type: string;
};

export interface CompanyAddress {
  externalId: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  type: AddressType;
  isDefault: boolean;
  country: Country;
  full: string;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface ConsolidatedInvoiceSettings {
  supplierSetting: ConsolidatedInvoicesSupplierSetting;
  /** @format date-time */
  nextRunDateUtc?: string | null;
}

export enum ConsolidatedInvoicesBuyerSetting {
  SupplierDefault = "SupplierDefault",
  Disabled = "Disabled",
  Enabled = "Enabled"
}

export enum ConsolidatedInvoicesSupplierSetting {
  Disabled = "Disabled",
  OptIn = "OptIn",
  OptOut = "OptOut"
}

export enum ContactProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Codat = "Codat",
  Dear = "Dear",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CreateDefaultPriceListCommand {
  name: string;
}

export interface CreateDefaultProductTaxRate {
  taxRateId: string;
}

export interface CreateDeliveryGroupRequest {
  /** @maxLength 128 */
  name: string;
}

export interface CreateFeedPostCommand {
  contentText: string;
  images: NewsfeedImage[];
  visibility: PostVisibility;
}

export interface CreateFeedPostResponse {
  postId: string;
}

export interface CreateMailboxBody {
  mailboxType: MailboxType;
}

export interface CreateMailboxResponse {
  mailbox: Mailbox;
}

export interface CreateMerchantAccountCommand {
  provider: PaymentProvider;
}

export interface CreateOrderCommand {
  /** @minLength 1 */
  buyerId: string;
  /** @format date-time */
  deliveryDateUtc: string;
  orderLines: OrderLineItemRequest[];
  /** @maxLength 2048 */
  notes?: string | null;
  /** @deprecated */
  deliveryAddress?: string | null;
  address?: DeliveryAddress | null;
  /** @maxLength 50 */
  referenceNumber?: string | null;
  /** @maxLength 2048 */
  trackingNumber?: string | null;
  /** @maxLength 1000 */
  comment?: string | null;
  payment?: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
    | null
  ) &
    ({
      $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
    } | null);
  files: PrivateFile[];
  /** @deprecated */
  inboxMessageId?: string | null;
}

export type CreateOrderTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceList?: OrderActionPriceListDetails | null;
  customLines?: OrderLineModificationAudit[] | null;
  files?: PrivateFile[] | null;
  /** @default "CreateOrderTimelineMetadata" */
  $type: string;
};

export interface CreatePriceListCommand {
  /** @maxLength 100 */
  name: string;
}

export interface CreateProductBody {
  /** @maxLength 100 */
  name: string;
  /** @maxLength 50 */
  code: string;
  basePrice?: Money | null;
  status: ProductStatus;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  defaultTaxRates?: CreateDefaultProductTaxRate[] | null;
  categoryId?: string | null;
}

export interface CreateProductLegacyCommand {
  name: string;
  code: string;
  isArchived: boolean;
  basePrice?: Money | null;
}

export interface CreateProductPromotionBody {
  promotion: ProductPromotion;
}

export interface CreateQuickAddProductCommand {
  productId: string;
}

export interface CreateSupplierTagCommand {
  /** @maxLength 250 */
  name?: string | null;
}

export interface CreateWeeklyStandingOrderScheduleBody {
  buyerId: string;
  products?: ManageWeeklyStandingOrderProductRequest[] | null;
}

export interface Currency {
  code: string;
}

export interface DateTimeUpdateResult {
  /** @format date-time */
  result: string;
}

export type DayOfFollowingMonth = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  day: number;
  /** @default "DayOfFollowingMonth" */
  $type: string;
};

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}

export interface Deadline {
  time: string;
  day: DayOfWeek;
}

export interface DeclineBuyerLinkCommand {
  comment?: string | null;
}

export type DefaultPaymentFailure = UtilRequiredKeys<PaymentFailureDetails, "$type"> & {
  /** @default "Default" */
  $type: string;
};

export interface DefaultProductTaxRate {
  taxRateId: string;
  name: string;
  code?: string | null;
  /** @format double */
  percentage: number;
}

export interface DeleteInboxMessagesBody {
  externalIds: string[];
}

export type DeleteInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider: InvoiceProvider;
  /** @default "DeleteInvoiceTimelineMetadata" */
  $type: string;
};

export interface DeleteProductPromotionBody {
  type: ProductPromotionType;
}

export interface DeliveredOptions {
  signature?: PrivateFile | null;
  signatory?: string | null;
  files?: PrivateFile[] | null;
}

export interface DeliveryAddress {
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface DeliveryAreas {
  locations: string[];
}

export interface DeliveryGroupDetails {
  deliveryGroupId: string;
  name: string;
  deliverySchedule: WeeklyDeliveryScheduleDetails;
  orderMinimums?:
    | (UtilRequiredKeys<OrderMinimumSettingMinimumMonetaryValue, "$type"> & {
        $type: "MinimumMonetaryValue";
      })[]
    | null;
  note?: string | null;
  type: DeliveryGroupType;
  /** @format int32 */
  buyerCount: number;
  buyerIssues: BuyerIssueCategorySummary[];
  canRename: boolean;
  canDelete: boolean;
}

export interface DeliveryGroupRef {
  deliveryGroupId: string;
  name: string;
  type: DeliveryGroupType;
}

export interface DeliveryGroupSummary {
  deliveryGroupId: string;
  name: string;
  /** @format int32 */
  buyerCount: number;
  type: DeliveryGroupType;
  canRename: boolean;
  canDelete: boolean;
}

export interface DeliveryGroupSummaryPagedSet {
  links: PageLinks;
  data: DeliveryGroupSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum DeliveryGroupType {
  Default = "Default",
  Custom = "Custom"
}

export type DeliveryGroupUpdatedMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  deliveryGroupId: string;
  deliveryGroupName: string;
  type: DeliveryGroupType;
  /** @default "DeliveryGroupUpdatedMetadata" */
  $type: string;
};

export interface EditLineItemsActionPriceListDetails {
  priceWarningsDisabled: boolean;
  effectiveOrderPriceListNameBefore?: string | null;
  effectiveOrderPriceListNameAfter?: string | null;
}

export type EditLineItemsTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceListDetails?: EditLineItemsActionPriceListDetails | null;
  editedLines: OrderLineModificationAudit[];
  /** @default "EditLineItemsTimelineMetadata" */
  $type: string;
};

export type EmailInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider?: InvoiceProvider | null;
  emails: string[];
  /** @default "EmailInvoiceTimelineMetadata" */
  $type: string;
};

export interface Error {
  code: string;
  reason: string;
}

export enum ErrorProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Dear = "Dear",
  Codat = "Codat",
  Payments = "Payments",
  Woolworths = "Woolworths",
  Automation = "Automation",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export enum ExportDateOption {
  SinceLastExport = "SinceLastExport",
  Custom = "Custom"
}

export interface ExternalProduct {
  provider: ProductProvider;
  providerProductId: string;
  providerProductUrl: string;
  name?: string | null;
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export interface FeatureCategoryResult {
  categories: FeatureCategorySummary[];
}

export interface FeatureCategorySummary {
  category: CategoryRef;
  sortOrder: string;
  hasProducts: boolean;
}

export interface FileSignature {
  hash: string;
  version: FileSignatureVersion;
}

export enum FileSignatureVersion {
  V1 = "V1"
}

export type FoodstuffsCancelOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  rejectionReason?: string | null;
  /** @default "FoodstuffsCancel" */
  $type: string;
};

export type ForwardInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  emails: string[];
  /** @default "ForwardInvoiceTimelineMetadata" */
  $type: string;
};

export type FromDefaultsV1 = UtilRequiredKeys<OrderMinimums, "$type"> & {
  status: OrderMinimumsStatus;
  monetaryValue?: Money | null;
  /** @default "FromDefaultsV1" */
  $type: string;
};

export interface GetMailboxResponse {
  mailbox: Mailbox;
}

export enum IdentifierSource {
  Foodstuffs = "Foodstuffs",
  Woolworths = "Woolworths"
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface InboxMessageDetails {
  externalId: string;
  sender: InboxMessageSender;
  subject: string;
  htmlBody?: PrivateFile | null;
  textBody?: PrivateFile | null;
  attachments: PrivateFile[];
  status: InboxMessageStatus;
  /** @format date-time */
  createdDateUtc: string;
  buyer?: BuyerReference | null;
  /** @format int32 */
  orderId?: number | null;
  suggestionsStatus: (
    | UtilRequiredKeys<InboxMessageSuggestionsStatusNone, "$type">
    | UtilRequiredKeys<InboxMessageSuggestionsStatusPending, "$type">
    | UtilRequiredKeys<InboxMessageSuggestionsStatusCompleted, "$type">
  ) & {
    $type: "None" | "Pending" | "Completed";
  };
}

export interface InboxMessageSender {
  name?: string | null;
  email?: string | null;
}

export interface InboxMessageStats {
  active: Statistic;
}

export enum InboxMessageStatus {
  Active = "Active",
  Processed = "Processed"
}

export interface InboxMessageSuggestionsRef {
  $type: "PurchaseOrder";
}

export type InboxMessageSuggestionsRefPurchaseOrder = UtilRequiredKeys<InboxMessageSuggestionsRef, "$type"> & {
  purchaseOrderSuggestionsId: string;
  /** @default "PurchaseOrder" */
  $type: string;
};

export interface InboxMessageSuggestionsStatus {
  $type: "None" | "Pending" | "Completed";
}

export type InboxMessageSuggestionsStatusCompleted = UtilRequiredKeys<InboxMessageSuggestionsStatus, "$type"> & {
  result: UtilRequiredKeys<InboxMessageSuggestionsRefPurchaseOrder, "$type"> & {
    $type: "PurchaseOrder";
  };
  /** @default "Completed" */
  $type: string;
};

export type InboxMessageSuggestionsStatusNone = UtilRequiredKeys<InboxMessageSuggestionsStatus, "$type"> & {
  /** @default "None" */
  $type: string;
};

export type InboxMessageSuggestionsStatusPending = UtilRequiredKeys<InboxMessageSuggestionsStatus, "$type"> & {
  jobRef: JobRef;
  /** @default "Pending" */
  $type: string;
};

export interface InboxMessageSummary {
  externalId: string;
  sender: InboxMessageSender;
  summary: string;
  status: InboxMessageStatus;
  /** @format int32 */
  orderId?: number | null;
  /** @format date-time */
  createdDateUtc: string;
}

export interface InboxMessageSummaryPagedSet {
  links: PageLinks;
  data: InboxMessageSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum IncomingOrderProvider {
  Foodstuffs = "Foodstuffs",
  Woolworths = "Woolworths"
}

export interface InvalidOrderLine {
  productName: string;
  productCode?: string | null;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: InvalidOrderLineProblem[];
  providerProductId: string;
}

export interface InvalidOrderLineProblem {
  type: InvalidOrderLineProblemType;
  severity: Severity;
}

export enum InvalidOrderLineProblemType {
  ProviderProductNotMatched = "ProviderProductNotMatched",
  ProductNotFound = "ProductNotFound",
  MissingBasePrice = "MissingBasePrice"
}

export interface InvoiceDetails {
  invoiceNumber: string;
  provider?: InvoiceProvider | null;
  viewInProviderUrl?: string | null;
  viewPdfUrl?: string | null;
  isForwarded: boolean;
  isConsolidated: boolean;
}

export enum InvoiceLinesSortOrderType {
  OrderLineId = "OrderLineId",
  ProductName = "ProductName"
}

export enum InvoiceProvider {
  Xero = "Xero",
  Codat = "Codat",
  MyIntegrator = "MyIntegrator"
}

export interface JobRef {
  jobId: string;
}

export interface Line {
  lineSuggestionId?: string | null;
  /** @format double */
  quantity?: number | null;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  $type: "Matched" | "Unmatched";
}

export interface LinkAction {
  action: BuyerLinkActions;
}

export interface LinkStripeAccountCommand {
  /** @minLength 1 */
  accountId: string;
  /** @minLength 1 */
  returnUrl: string;
  /** @minLength 1 */
  refreshUrl: string;
}

export interface LinkedBuyerStats {
  /** @format int32 */
  linkedCount: number;
  /** @format int32 */
  invitedCount: number;
  /** @format int32 */
  leadCount: number;
  /** @format int32 */
  standingOrdersCount: number;
  issues: BuyerIssueCategorySummary[];
}

export enum LinkedBuyerStatus {
  Unlinked = "Unlinked",
  Pending = "Pending",
  Linked = "Linked"
}

export interface LinkedBuyerTag {
  /** @format int32 */
  tagId: number;
  name: string;
  value?: string | null;
}

export interface Mailbox {
  emailAddress: string;
  hash: string;
  type: MailboxType;
}

export enum MailboxType {
  PurchaseOrder = "PurchaseOrder"
}

export interface ManageWeeklyStandingOrderProductRequest {
  productExternalId: string;
  /** @format double */
  monday?: number | null;
  /** @format double */
  tuesday?: number | null;
  /** @format double */
  wednesday?: number | null;
  /** @format double */
  thursday?: number | null;
  /** @format double */
  friday?: number | null;
  /** @format double */
  saturday?: number | null;
  /** @format double */
  sunday?: number | null;
}

export interface ManageWeeklyStandingOrderProductsBody {
  products: ManageWeeklyStandingOrderProductRequest[];
}

export interface Money {
  /** @format double */
  amount: number;
  currency: Currency;
}

export type NetDays = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  days: number;
  /** @default "NetDays" */
  $type: string;
};

export interface NewsfeedImage {
  imageUrl: string;
  imageUrl2X?: string | null;
}

export interface NotificationForwardingSettings {
  ordersEmail?: string | null;
}

export type OnAccountDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "OnAccount" */
  $type: string;
};

export type OnAccountOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "OnAccount" */
  $type: string;
};

export interface OrderAction {
  action: OrderActionType;
}

export interface OrderActionPriceListDetails {
  name: string;
  priceListId: string;
  effectiveOrderPriceListName?: string | null;
  priceWarningsDisabled: boolean;
}

export enum OrderActionType {
  EditOrderLines = "EditOrderLines",
  ForwardInvoice = "ForwardInvoice",
  NewOrder = "NewOrder",
  CopyOrder = "CopyOrder",
  AddComments = "AddComments",
  PayNow = "PayNow",
  UpdatePaymentOption = "UpdatePaymentOption",
  ManualTakePayment = "ManualTakePayment"
}

export enum OrderAuditAction {
  Ordered = "Ordered",
  Shipped = "Shipped",
  Processing = "Processing",
  CreateInvoice = "CreateInvoice",
  UpdateInvoice = "UpdateInvoice",
  Deleted = "Deleted",
  Comment = "Comment",
  Approved = "Approved",
  Cancelled = "Cancelled",
  Received = "Received",
  EmailInvoice = "EmailInvoice",
  EditLineItems = "EditLineItems",
  DeleteInvoice = "DeleteInvoice",
  ForwardInvoice = "ForwardInvoice",
  LinkExternalDocument = "LinkExternalDocument",
  Scheduled = "Scheduled",
  ReportIssue = "ReportIssue",
  PaymentReceived = "PaymentReceived",
  PaymentFailed = "PaymentFailed",
  ResolveIssue = "ResolveIssue",
  PaymentOptionUpdated = "PaymentOptionUpdated",
  InvalidOrderLineRemoved = "InvalidOrderLineRemoved",
  DeliveryGroupUpdated = "DeliveryGroupUpdated",
  Delivered = "Delivered",
  ShipmentStatusUpdated = "ShipmentStatusUpdated"
}

export enum OrderAuditActionSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Scheduler = "Scheduler",
  PaymentProvider = "PaymentProvider",
  ShipmentProvider = "ShipmentProvider"
}

export interface OrderComment {
  message: string;
  images?: Image[] | null;
}

export type OrderDeliveredMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  signature?: PrivateFile | null;
  signatory?: string | null;
  files: PrivateFile[];
  /** @default "OrderDeliveredMetadata" */
  $type: string;
};

export interface OrderDeliveryGroupSummary {
  deliveryGroupId: string;
  name: string;
  type: DeliveryGroupType;
}

export interface OrderDeliveryGroupSummaryUpdateResult {
  result: OrderDeliveryGroupSummary;
}

export interface OrderDetails {
  /** @format int32 */
  orderId: number;
  orderStatus: OrderStatus;
  source: OrderSource;
  buyerName: string;
  buyerIsClaimed: boolean;
  supplierIsClaimed: boolean;
  buyerId: string;
  /** @format date-time */
  createdDateUtc: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  deliveryAddress?: string | null;
  lines: OrderLine[];
  invalidLines: InvalidOrderLine[];
  referenceNumber?: string | null;
  trackingNumber?: string | null;
  notes?: string | null;
  invoice?: InvoiceDetails | null;
  printed: boolean;
  workflow: OrderWorkflow;
  orderErrors: OrderError[];
  /** @format int32 */
  totalComments: number;
  orderProvider?: IncomingOrderProvider | null;
  tax?: OrderTaxDetails | null;
  payment?: (
    | UtilRequiredKeys<PaymentMethodDetails, "$type">
    | UtilRequiredKeys<PaymentLinkDetails, "$type">
    | UtilRequiredKeys<OnAccountDetails, "$type">
    | null
  ) &
    ({
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    } | null);
  subtotal?: Money | null;
  total?: Money | null;
  address?: DeliveryAddress | null;
  files: PrivateFile[];
  invoiceIsConsolidated: boolean;
  documents: OrderExternalDocument[];
  hasCustomPrices: boolean;
  orderIssues: OrderIssueSummary[];
}

export interface OrderError {
  /** @format int64 */
  notificationId: number;
  /** @format int32 */
  orderId: number;
  buyerName: string;
  providerError: ProviderError;
  isDismissed: boolean;
  isRetryable: boolean;
}

export enum OrderErrorType {
  Invoice = "Invoice",
  ApproveInvoice = "ApproveInvoice",
  InvalidOrderLines = "InvalidOrderLines",
  BuyerNotLinked = "BuyerNotLinked",
  CreateExternalOrder = "CreateExternalOrder",
  PaymentFailed = "PaymentFailed",
  UpdateInvoice = "UpdateInvoice",
  PaymentFailedNonRetryable = "PaymentFailedNonRetryable",
  OrderNotShippedExternalOrderOutOfSync = "OrderNotShipped_ExternalOrderOutOfSync",
  AutoApprovalFailed = "AutoApprovalFailed",
  OrderNotShippedExternalOrderOutOfSyncInvoicesIncomingOrderProvider = "OrderNotShipped_ExternalOrderOutOfSync_InvoicesIncomingOrderProvider",
  OrderNotShippedExternalOrderOutOfSyncOrderHasPayment = "OrderNotShipped_ExternalOrderOutOfSync_OrderHasPayment",
  ExternalOrderOutOfSyncOrderPaidIncorrectAmount = "ExternalOrderOutOfSync_OrderPaidIncorrectAmount",
  PaymentValidationTotal = "PaymentValidation_Total",
  PaymentValidationTax = "PaymentValidation_Tax",
  QuickBooksOnlineCustomTransactionNumberMustBeOff = "QuickBooksOnline_CustomTransactionNumberMustBeOff",
  IntegrationRecordPaymentFailed = "Integration_RecordPaymentFailed",
  CreateShipment = "Create_Shipment",
  WoolworthsRejectAlreadyAcknowledged = "Woolworths_Reject_AlreadyAcknowledged"
}

export interface OrderExternalDocument {
  documentProvider: ApiTokenType;
  documentType: OrderExternalDocumentType;
  documentStatus: OrderExternalDocumentStatus;
  name?: string | null;
  url?: string | null;
  errors: OrderError[];
  deferredReason?: OrderExternalDocumentDeferredReason | null;
  metadata?: (UtilRequiredKeys<StarshipitMetadata, "$type"> | null) &
    ({
      $type: "Starshipit";
    } | null);
}

export interface OrderExternalDocumentDeferredReason {
  type: OrderExternalDocumentDeferredReasonType;
  orderStatus?: OrderStatus | null;
}

export enum OrderExternalDocumentDeferredReasonType {
  WaitingForConsolidatedInvoiceRun = "WaitingForConsolidatedInvoiceRun",
  WaitingForOrderStatus = "WaitingForOrderStatus"
}

export interface OrderExternalDocumentMetadata {
  $type: "Starshipit";
}

export enum OrderExternalDocumentStatus {
  Submitted = "Submitted",
  Created = "Created",
  Failed = "Failed",
  Deferred = "Deferred"
}

export enum OrderExternalDocumentType {
  Invoice = "Invoice",
  GeneratedOrder = "GeneratedOrder",
  Shipment = "Shipment"
}

export interface OrderIssueSummary {
  orderIssueId: string;
  /** @format int32 */
  orderId: number;
  buyerTradingName: string;
  /** @deprecated */
  raisedBy?: UserProfile | null;
  raisedByName: string;
  /** @format date-time */
  raisedDateUtc: string;
  issueType: OrderIssueType;
  comment: OrderComment;
  isResolved: boolean;
  /** @format date-time */
  resolvedDateUtc?: string | null;
}

export interface OrderIssueSummaryPagedSet {
  links: PageLinks;
  data: OrderIssueSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum OrderIssueType {
  Other = "Other",
  DidNotArrive = "DidNotArrive",
  ProductListOutOfDate = "ProductListOutOfDate",
  ProductDamaged = "ProductDamaged",
  ProductQuality = "ProductQuality"
}

export interface OrderLine {
  productName: string;
  productCode: string;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  productId: string;
  status: ProductStatus;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: OrderLineProblem[];
  hasCustomUnitAmount: boolean;
  priceListUnitAmount?: Money | null;
  priceListLineAmount?: Money | null;
  productImages?: ProductImagesMetadata | null;
  externalReferences: ProductExternalReference[];
  tax?: OrderLineTaxDetails | null;
  packingStatus?: OrderLinePackingStatus | null;
}

export interface OrderLineItemRequest {
  productId: string;
  /** @format double */
  quantity: number;
  /** @format double */
  customUnitAmount?: number | null;
  /** @format int32 */
  orderLineId?: number | null;
}

export interface OrderLineModificationAudit {
  modificationType: OrderLineModificationType;
  /** @format double */
  quantityBefore: number;
  /** @format double */
  quantityAfter: number;
  /** @format double */
  unitAmountBefore?: number | null;
  /** @format double */
  unitAmountAfter?: number | null;
  priceListNameBefore?: string | null;
  priceListNameAfter?: string | null;
  productName: string;
  productCode: string;
  productId: string;
  /** @format int32 */
  orderLineId?: number | null;
}

export enum OrderLineModificationType {
  Added = "Added",
  Removed = "Removed",
  QuantityChange = "QuantityChange",
  PriceChange = "PriceChange"
}

export enum OrderLinePackingStatus {
  NotPacked = "NotPacked",
  Packed = "Packed"
}

export interface OrderLineProblem {
  type: OrderLineProblemType;
  severity: Severity;
}

export enum OrderLineProblemType {
  DifferentPrice = "DifferentPrice",
  ProductArchived = "ProductArchived",
  ProductOutOfStock = "ProductOutOfStock",
  ProductHidden = "ProductHidden",
  NoTaxRatesCoded = "NoTaxRatesCoded",
  ProductDeleted = "ProductDeleted"
}

export interface OrderLineTaxDetails {
  taxRateComponents: OrderLineTaxRateComponent[];
  taxAmount: Money;
}

export interface OrderLineTaxRateComponent {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderMinimumSetting {
  $type: "MinimumMonetaryValue";
}

export type OrderMinimumSettingMinimumMonetaryValue = UtilRequiredKeys<OrderMinimumSetting, "$type"> & {
  /** @format double */
  value: number;
  /** @default "MinimumMonetaryValue" */
  $type: string;
};

export interface OrderMinimums {
  $type: "FromDefaultsV1";
}

export enum OrderMinimumsStatus {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export interface OrderPaymentDetails {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
}

export interface OrderPaymentFailure {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
}

export interface OrderPaymentRecord {
  identifier: string;
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  fee?: Money | null;
  failure?: OrderPaymentFailure | null;
}

export interface OrderPaymentStatusDetails {
  $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
}

export type OrderPaymentStatusDetailsFailed = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Failed" */
  $type: string;
};

export type OrderPaymentStatusDetailsPaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Paid" */
  $type: string;
};

export type OrderPaymentStatusDetailsProcessing = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Processing" */
  $type: string;
};

export type OrderPaymentStatusDetailsScheduled = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @format date-time */
  takePaymentDateUtc: string;
  delayUsed: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type">) & {
    $type: "NetDays" | "DayOfFollowingMonth";
  };
  /** @default "Scheduled" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnknown = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Unknown" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnpaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  deferredUntilOrderStatus?: OrderStatus | null;
  delayAfterOrderStatus?: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type"> | null) &
    ({
      $type: "NetDays" | "DayOfFollowingMonth";
    } | null);
  /** @default "Unpaid" */
  $type: string;
};

export interface OrderProgress {
  status: OrderStatus;
  isComplete: boolean;
  /** @format date-time */
  actionDateUtc?: string | null;
}

export interface OrderSettingMetadata {
  enabled: boolean;
  required: boolean;
  immutableReason?: OrderSettingsImmutableReason | null;
  immutable: boolean;
}

export interface OrderSettings {
  taxSetting: TaxType;
  invoiceLinesSortBy: InvoiceLinesSortOrderType;
  hideInvoicesFromBuyers: boolean;
  showOrderPricesToBuyers: boolean;
  priceListsActive: boolean;
  promptForDeliveryDate: boolean;
  emailInvoiceWhenShipped: boolean;
  disableInvoiceGeneration: boolean;
  /** @deprecated */
  deliverySchedule: WeeklyDeliveryScheduleDetails;
  calculatedDeliverySchedule: CalculatedDeliverySchedule;
  note?: string | null;
  /** @format int32 */
  autoApproveStandingOrderOffsetMinutes: number;
  /** @format int32 */
  autoGenerateStandingOrderOffsetMinutes: number;
  deliveryAreas?: DeliveryAreas | null;
  defaultSupplierEnteredOrderStatus: OrderStatus;
  consolidatedInvoiceSettings: ConsolidatedInvoiceSettings;
  orderMinimums?: (UtilRequiredKeys<FromDefaultsV1, "$type"> | null) &
    ({
      $type: "FromDefaultsV1";
    } | null);
  disableOrderShippedEmail: boolean;
  autoOrderApproval: OverridableSettingFlag;
  /** @deprecated */
  consolidatedInvoices: ConsolidatedInvoicesSupplierSetting;
}

export enum OrderSettingsImmutableReason {
  PaymentsActive = "PaymentsActive",
  OrderMinimumsActive = "OrderMinimumsActive",
  NotEditable = "NotEditable"
}

export interface OrderSettingsMetadata {
  showOrderPricesToBuyers: OrderSettingMetadata;
  hideInvoicesFromBuyers: OrderSettingMetadata;
  emailInvoiceWhenShipped: OrderSettingMetadata;
  consolidatedInvoiceSupplierSetting: OrderSettingMetadata;
  taxSetting: OrderSettingsTaxSettingMetadata;
}

export interface OrderSettingsTaxSettingMetadata {
  enabled: boolean;
  selectableTaxTypes: TaxType[];
}

export enum OrderSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  StandingOrder = "StandingOrder"
}

export enum OrderStatus {
  Ordered = "Ordered",
  Processing = "Processing",
  Shipped = "Shipped",
  Cancelled = "Cancelled",
  Received = "Received",
  Scheduled = "Scheduled",
  Delivered = "Delivered"
}

export interface OrderTaxCodeBreakdownLine {
  taxCode: string;
  taxAmount: Money;
}

export interface OrderTaxDetails {
  taxCodeBreakdown?: OrderTaxCodeBreakdownLine[] | null;
  taxRateBreakdown: OrderTaxRateBreakdownLine[];
  totalTaxAmount: Money;
}

export interface OrderTaxRateBreakdownLine {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderTimeline {
  /** @format int32 */
  orderId: number;
  actionType: OrderAuditAction;
  /** @format date-time */
  actionDateUtc: string;
  actionByFirstName?: string | null;
  actionByLastName?: string | null;
  metadata?: (
    | UtilRequiredKeys<CreateOrderTimelineMetadata, "$type">
    | UtilRequiredKeys<EmailInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<DeleteInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<EditLineItemsTimelineMetadata, "$type">
    | UtilRequiredKeys<ForwardInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<AddDocumentTimelineMetadata, "$type">
    | UtilRequiredKeys<ReportIssueTimelineMetadata, "$type">
    | UtilRequiredKeys<CommentTimelineMetadata, "$type">
    | UtilRequiredKeys<PaymentOptionsUpdatedTimelineMetadata, "$type">
    | UtilRequiredKeys<DeliveryGroupUpdatedMetadata, "$type">
    | UtilRequiredKeys<OrderDeliveredMetadata, "$type">
    | UtilRequiredKeys<ShipmentStatusUpdatedTimelineMetadata, "$type">
    | null
  ) &
    ({
      $type:
        | "CreateOrderTimelineMetadata"
        | "EmailInvoiceTimelineMetadata"
        | "DeleteInvoiceTimelineMetadata"
        | "EditLineItemsTimelineMetadata"
        | "ForwardInvoiceTimelineMetadata"
        | "AddDocumentTimelineMetadata"
        | "ReportIssueTimelineMetadata"
        | "CommentTimelineMetadata"
        | "PaymentOptionsUpdatedTimelineMetadata"
        | "DeliveryGroupUpdatedMetadata"
        | "OrderDeliveredMetadata"
        | "ShipmentSatusUpdatedTimelineMetadata";
    } | null);
  actionBySelf: boolean;
  actionSource: OrderAuditActionSource;
}

export interface OrderTimelineMetadata {
  $type:
    | "CreateOrderTimelineMetadata"
    | "EmailInvoiceTimelineMetadata"
    | "DeleteInvoiceTimelineMetadata"
    | "EditLineItemsTimelineMetadata"
    | "ForwardInvoiceTimelineMetadata"
    | "AddDocumentTimelineMetadata"
    | "ReportIssueTimelineMetadata"
    | "CommentTimelineMetadata"
    | "PaymentOptionsUpdatedTimelineMetadata"
    | "DeliveryGroupUpdatedMetadata"
    | "OrderDeliveredMetadata"
    | "ShipmentSatusUpdatedTimelineMetadata";
}

export interface OrderTransition {
  isPrimary: boolean;
  action: OrderStatus;
  actionUrl: string;
  isDisabled: boolean;
}

export interface OrderWorkflow {
  availableActions: OrderAction[];
  transitions: OrderTransition[];
  progress: OrderProgress[];
}

export interface OrdersExportReportFormat {
  format: SupplierExportFormatOption;
  name: string;
}

export enum OverridableSettingBuyerFlag {
  SupplierDefault = "SupplierDefault",
  Off = "Off",
  On = "On"
}

export enum OverridableSettingFlag {
  Disabled = "Disabled",
  OffByDefault = "OffByDefault",
  OnByDefault = "OnByDefault"
}

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface PaymentAccount {
  provider: PaymentProvider;
  accountId: string;
  status: AccountStatus;
  errors: Error[];
}

export interface PaymentAccountPagedSet {
  links: PageLinks;
  data: PaymentAccount[];
  /** @format int32 */
  total?: number | null;
}

export interface PaymentFailureDetails {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
  $type: "Default";
}

export enum PaymentFailureReason {
  Unknown = "Unknown",
  InsufficientFunds = "InsufficientFunds",
  Expired = "Expired"
}

export enum PaymentFailureType {
  Retryable = "Retryable",
  NonRetryable = "NonRetryable"
}

export type PaymentLinkDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentLink" */
  $type: string;
};

export type PaymentLinkOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "PaymentLink" */
  $type: string;
};

export interface PaymentMethod {
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  identifier: string;
  /** @format date-time */
  createdDateUtc: string;
  status: PaymentMethodStatus;
  $type: "Card";
}

export type PaymentMethodDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentMethod" */
  $type: string;
};

export type PaymentMethodOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  /** @default "PaymentMethod" */
  $type: string;
};

export enum PaymentMethodStatus {
  Active = "Active",
  Failed = "Failed",
  Hidden = "Hidden"
}

export enum PaymentOnboardingDependency {
  ProductsAreMissing = "ProductsAreMissing",
  PriceListsNotActive = "PriceListsNotActive",
  TaxNotActive = "TaxNotActive",
  TaxExemptPaymentsNotAllowed = "TaxExemptPaymentsNotAllowed"
}

export interface PaymentOnboardingState {
  status: PaymentOnboardingStatus;
  missingDependencies: PaymentOnboardingDependency[];
}

export enum PaymentOnboardingStatus {
  Available = "Available",
  MissingDependencies = "MissingDependencies",
  Completed = "Completed",
  Unavailable = "Unavailable"
}

export interface PaymentOption {
  isUnavailable: boolean;
  unavailableReason?: PaymentOptionUnavailableReason | null;
  $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
}

export enum PaymentOptionCategory {
  Card = "Card",
  OnAccount = "OnAccount"
}

export enum PaymentOptionUnavailableReason {
  UnsupportedMerchantAccount = "UnsupportedMerchantAccount",
  PaymentMethodFailed = "PaymentMethodFailed"
}

export type PaymentOptionsUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  updatedOption: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
  /** @default "PaymentOptionsUpdatedTimelineMetadata" */
  $type: string;
};

export enum PaymentProvider {
  Stripe = "Stripe"
}

export interface PaymentReconciliationSettings {
  clearingAccount: PaymentReconciliationSettingsAccount;
  feeAccount: PaymentReconciliationSettingsAccount;
  feeContact: PaymentReconciliationSettingsContact;
  feeTaxRate: PaymentReconciliationSettingsTaxRate;
  payoutAccount: PaymentReconciliationSettingsAccount;
}

export interface PaymentReconciliationSettingsAccount {
  code?: string | null;
  name: string;
  identifier: string;
}

export interface PaymentReconciliationSettingsAccountSelectMetadata {
  accountIntent?: AccountIntent | null;
}

export interface PaymentReconciliationSettingsContact {
  identifier: string;
  name: string;
}

export interface PaymentReconciliationSettingsFeeContactMetadata {
  searchSuggestion?: string | null;
}

export interface PaymentReconciliationSettingsFeeTaxRateSelectMetadata {
  taxName?: string | null;
  /** @format double */
  taxPercentage?: number | null;
}

export interface PaymentReconciliationSettingsMetadata {
  enabled: boolean;
  required: boolean;
  clearingAccount: PaymentReconciliationSettingsAccountSelectMetadata;
  feeAccount: PaymentReconciliationSettingsAccountSelectMetadata;
  feeContact: PaymentReconciliationSettingsFeeContactMetadata;
  feeTaxRate: PaymentReconciliationSettingsFeeTaxRateSelectMetadata;
  payoutAccount: PaymentReconciliationSettingsAccountSelectMetadata;
}

export interface PaymentReconciliationSettingsTaxRate {
  identifier: string;
  name: string;
}

export enum PaymentRequirement {
  SupplierDefault = "SupplierDefault",
  None = "None",
  PayNow = "PayNow",
  PayNowOptional = "PayNowOptional"
}

export interface PaymentSettings {
  /** @deprecated */
  paymentRequirement: PaymentRequirement;
  paymentsActive: boolean;
  reconciliationSettings?: PaymentReconciliationSettings | null;
  paymentOnboardingState?: PaymentOnboardingState | null;
}

export interface PaymentSettingsMetadata {
  reconciliationSettings: PaymentReconciliationSettingsMetadata;
  paymentTerms: PaymentTermsMetadata;
}

export enum PaymentStatus {
  Unknown = "Unknown",
  Unpaid = "Unpaid",
  Processing = "Processing",
  Paid = "Paid",
  Failed = "Failed",
  Scheduled = "Scheduled"
}

export enum PaymentTakenTrigger {
  OnOrderProcessing = "OnOrderProcessing",
  OnOrderShipped = "OnOrderShipped",
  AfterOrderShipped = "AfterOrderShipped"
}

export type PaymentTakenTriggerAfterOrderShippedOptions = UtilRequiredKeys<PaymentTakenTriggerOptions, "$type"> & {
  delay: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type">) & {
    $type: "NetDays" | "DayOfFollowingMonth";
  };
  /** @default "PaymentTakenTriggerAfterOrderShippedOptions" */
  $type: string;
};

export interface PaymentTakenTriggerOptions {
  $type: "PaymentTakenTriggerAfterOrderShippedOptions";
}

export interface PaymentTermsCreateRequest {
  name: string;
  paymentOptions?: PaymentOptionCategory[] | null;
  invoiceDueDate?: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type"> | null) &
    ({
      $type: "NetDays" | "DayOfFollowingMonth";
    } | null);
  paymentTakenTrigger?: PaymentTakenTrigger | null;
  paymentTakenTriggerOptions?: (UtilRequiredKeys<PaymentTakenTriggerAfterOrderShippedOptions, "$type"> | null) &
    ({
      $type: "PaymentTakenTriggerAfterOrderShippedOptions";
    } | null);
}

export enum PaymentTermsDeletionUnavailableReason {
  DefaultPaymentTerms = "DefaultPaymentTerms"
}

export interface PaymentTermsDetails {
  paymentTermsId: string;
  name: string;
  type: PaymentTermsType;
  paymentOptions: PaymentOptionCategory[];
  invoiceDueDate: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type">) & {
    $type: "NetDays" | "DayOfFollowingMonth";
  };
  paymentTakenTrigger: PaymentTakenTrigger;
  paymentTakenTriggerOptions?: (UtilRequiredKeys<PaymentTakenTriggerAfterOrderShippedOptions, "$type"> | null) &
    ({
      $type: "PaymentTakenTriggerAfterOrderShippedOptions";
    } | null);
  /** @format int32 */
  buyerCount: number;
  metadata: PaymentTermsDetailsMetadata;
}

export interface PaymentTermsDetailsMetadata {
  deletionUnavailableReason?: PaymentTermsDeletionUnavailableReason | null;
  renameUnavailableReason?: PaymentTermsRenameUnavailableReason | null;
  paymentOptions: PaymentTermsPaymentOptionsMetadata;
  invoiceDueDate: PaymentTermsInvoiceDueDateMetadata;
  paymentTakenTrigger: PaymentTermsPaymentTakenTriggerMetadata;
  canDelete: boolean;
  canRename: boolean;
}

export interface PaymentTermsInvoiceDueDateMetadata {
  enabled: boolean;
}

export interface PaymentTermsMetadata {
  enabled: boolean;
  /** @deprecated */
  paymentOptions: PaymentTermsPaymentOptionsMetadata;
  /** @deprecated */
  invoiceDueDate: PaymentTermsInvoiceDueDateMetadata;
  /** @deprecated */
  paymentTakenTrigger: PaymentTermsPaymentTakenTriggerMetadata;
}

export interface PaymentTermsPaymentDelay {
  $type: "NetDays" | "DayOfFollowingMonth";
}

export interface PaymentTermsPaymentOptionsMetadata {
  enabled: PaymentOptionCategory[];
}

export interface PaymentTermsPaymentTakenTriggerMetadata {
  enabled: boolean;
  recommended?: PaymentTakenTrigger | null;
}

export interface PaymentTermsRef {
  paymentTermsId: string;
  name: string;
  type: PaymentTermsType;
}

export enum PaymentTermsRenameUnavailableReason {
  DefaultPaymentTerms = "DefaultPaymentTerms"
}

export interface PaymentTermsSummary {
  paymentTermsId: string;
  name: string;
  type: PaymentTermsType;
  paymentOptions: PaymentOptionCategory[];
  /** @format int32 */
  buyerCount: number;
}

export interface PaymentTermsSummaryPagedSet {
  links: PageLinks;
  data: PaymentTermsSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum PaymentTermsType {
  Default = "Default",
  Custom = "Custom",
  Woolworths = "Woolworths"
}

export interface PaymentTermsUpdateRequest {
  name?: string | null;
  paymentOptions?: PaymentOptionCategory[] | null;
  invoiceDueDate?: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type"> | null) &
    ({
      $type: "NetDays" | "DayOfFollowingMonth";
    } | null);
  paymentTakenTrigger?: PaymentTakenTrigger | null;
  paymentTakenTriggerOptions?: (UtilRequiredKeys<PaymentTakenTriggerAfterOrderShippedOptions, "$type"> | null) &
    ({
      $type: "PaymentTakenTriggerAfterOrderShippedOptions";
    } | null);
}

export interface Payout {
  payoutId: string;
  /** @format date-time */
  payoutDateUtc: string;
  reference: string;
  amount: Money;
  reconciliationStatus: PayoutReconciliationStatus;
}

export interface PayoutPagedSet {
  links: PageLinks;
  data: Payout[];
  /** @format int32 */
  total?: number | null;
}

export interface PayoutReconciliationAccountingReport {
  totals: PayoutReconciliationReportTotals;
  lines: PayoutReconciliationAccountingReportLine[];
}

export interface PayoutReconciliationAccountingReportLine {
  lineType: PayoutReconciliationAccountingReportLineType;
  amount: Money;
  taxAmount?: Money | null;
  /** @format int32 */
  orderId?: number | null;
  contactName?: string | null;
  document?: PayoutReconciliationDocumentReference | null;
  /** @format date-time */
  paymentDateUtc?: string | null;
}

export enum PayoutReconciliationAccountingReportLineType {
  CustomerPayment = "CustomerPayment",
  ApplicationFee = "ApplicationFee",
  CustomerRefund = "CustomerRefund"
}

export interface PayoutReconciliationDocumentReference {
  documentName: string;
  documentUrl?: string | null;
  providerType: ApiTokenType;
}

export interface PayoutReconciliationReportTaxMetadata {
  taxName: string;
  /** @format double */
  taxPercentage: number;
}

export interface PayoutReconciliationReportTotals {
  taxMetadata: PayoutReconciliationReportTaxMetadata;
  payoutAmount: Money;
  totalPaymentAmount: Money;
  totalPaymentFee: Money;
  totalPaymentFeeTax: Money;
  totalUnreconcilablePaymentAmount: Money;
  totalUnreconcilablePaymentFee: Money;
  totalRefundAmount: Money;
}

export enum PayoutReconciliationStatus {
  Processing = "Processing",
  Completed = "Completed"
}

export interface PendingBuyerLinkSummary {
  tradingName: string;
  buyerId: string;
  initiatedBy: BuyerLinkInitiatedBy;
  /** @format date-time */
  createdDateUtc: string;
}

export interface PendingBuyerSummary {
  /** @format int32 */
  requestId: number;
  tradingName: string;
  email: string;
  deliveryAddress: string;
  status: Status;
}

export interface PendingBuyerSummaryPagedSet {
  links: PageLinks;
  data: PendingBuyerSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface PlanWeeklyStandingOrderScheduleBody {
  deliverySchedule: WeeklyDeliveryScheduleDetails;
  /** @format int32 */
  autoApproveOffsetMinutes: number;
  /** @format int32 */
  autoGenerateOffsetMinutes: number;
}

export interface PlanWeeklyStandingOrderScheduleResult {
  existingSchedule: WeeklyStandingOrderRun;
  proposedSchedule: WeeklyStandingOrderRun;
}

export enum PostVisibility {
  Public = "Public",
  Customers = "Customers",
  NotCustomers = "NotCustomers"
}

export interface Price {
  discountType: PriceListDiscountType;
  /** @format double */
  discountPercentage?: number | null;
  discountAmount?: Money | null;
  calculatedPrice: Money;
  basePrice: Money;
}

export enum PriceListDiscountType {
  None = "None",
  Percentage = "Percentage",
  Dollar = "Dollar"
}

export interface PriceListProduct {
  productId: string;
  name: string;
  code: string;
  isHidden: boolean;
  price: Price;
  isAvailable: boolean;
  unavailableReason?: ProductUnavailableReason | null;
}

export interface PriceListProductPagedSet {
  links: PageLinks;
  data: PriceListProduct[];
  /** @format int32 */
  total?: number | null;
}

export interface PriceListProductRequest {
  productId: string;
  isHidden: boolean;
  price?: PriceRequest | null;
}

export interface PriceListStats {
  /** @format int32 */
  visibleProductCount: number;
  /** @format int32 */
  hiddenProductCount: number;
  /** @format int32 */
  buyerCount: number;
}

export interface PriceListSummary {
  priceListId: string;
  name: string;
  priceListType: PriceListType;
  /** @format int32 */
  totalBuyers?: number | null;
}

export interface PriceListSummaryPagedSet {
  links: PageLinks;
  data: PriceListSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum PriceListType {
  Default = "Default",
  Custom = "Custom",
  Generated = "Generated"
}

export interface PriceListsMetadata {
  active: boolean;
  needsSetup: boolean;
}

export interface PriceRequest {
  discountType?: PriceListDiscountType | null;
  /** @format double */
  discountPercentage?: number | null;
  discountAmount?: Money | null;
}

export interface PrivateFile {
  id: string;
  originalName: string;
  location: string;
  contentType: string;
  /** @format int64 */
  contentLength: number;
  signature: FileSignature;
}

export interface ProcessPurchaseOrderResponse {
  /** @format int32 */
  orderId: number;
  buyerName: string;
}

export interface ProductBasePriceMetadata {
  enabled: boolean;
  required: boolean;
}

export interface ProductBasePriceValidation {
  productId: string;
  name: string;
  code: string;
  basePrice?: Money | null;
  problem: ApiValidationProblem;
}

export interface ProductBasePriceValidationPagedSet {
  links: PageLinks;
  data: ProductBasePriceValidation[];
  /** @format int32 */
  total?: number | null;
}

export interface ProductCategoriesMetadata {
  hasCategorisedProducts: boolean;
  /** @format int32 */
  uncategorisedProductCount: number;
  hasFeatureCategories: boolean;
}

export interface ProductDefaultTaxRatesMetadata {
  enabled: boolean;
  required: boolean;
  immutableReason?: ProductDefaultTaxRatesMetadataImmutableReason | null;
  immutable: boolean;
}

export enum ProductDefaultTaxRatesMetadataImmutableReason {
  ReplicatedFromProvider = "ReplicatedFromProvider"
}

export interface ProductDetails {
  productId: string;
  name: string;
  code: string;
  status: ProductStatus;
  supplier: ProductSupplier;
  /** @deprecated */
  providerLink: string;
  basePrice?: Money | null;
  externalProducts: ExternalProduct[];
  priceLists: ProductPriceList[];
  imagesMetadata?: ProductImagesMetadata | null;
  defaultTaxRates: DefaultProductTaxRate[];
  description?: string | null;
  category?: CategoryRef | null;
}

export interface ProductExternalReference {
  identifier: string;
  source: ProductProvider;
  name: string;
}

export interface ProductImagesMetadata {
  images: Image[];
}

export interface ProductMetadata {
  basePrice: ProductBasePriceMetadata;
  defaultTaxRates: ProductDefaultTaxRatesMetadata;
  categories: ProductCategoriesMetadata;
}

export interface ProductPriceList {
  priceListId: string;
  priceListName: string;
  productIsHidden: boolean;
  productPrice?: Price | null;
}

export interface ProductPriceListRequest {
  priceListId: string;
  productIsHidden: boolean;
  productPrice?: PriceRequest | null;
}

export interface ProductPromotion {
  type: ProductPromotionType;
}

export enum ProductPromotionType {
  Featured = "Featured"
}

export enum ProductProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export enum ProductSortBy {
  Name = "Name",
  Code = "Code",
  FeatureCategory = "FeatureCategory"
}

export interface ProductStats {
  /** @format int32 */
  activeCount: number;
  /** @format int32 */
  outOfStockCount: number;
  /** @format int32 */
  archivedCount: number;
}

export enum ProductStatus {
  Active = "Active",
  Archived = "Archived",
  OutOfStock = "OutOfStock"
}

export interface ProductSupplier {
  name: string;
  supplierId: string;
}

export enum ProductUnavailableReason {
  Unavailable = "Unavailable",
  Archived = "Archived",
  Hidden = "Hidden",
  OutOfStock = "OutOfStock",
  Deleted = "Deleted"
}

export interface ProductionReportSummaryRow {
  description: string;
  code: string;
  /** @format double */
  quantity: number;
}

export interface ProductionReportSummaryRowPagedSet {
  links: PageLinks;
  data: ProductionReportSummaryRow[];
  /** @format int32 */
  total?: number | null;
}

export interface ProviderContactSummary {
  name: string;
  viewInProviderUrl?: string | null;
  provider: ContactProvider;
}

export interface ProviderError {
  invoiceNumber?: string | null;
  providerType: ErrorProvider;
  errorType: OrderErrorType;
  errors: string[];
}

export interface PurchaseOrderSuggestions {
  $type: "PurchaseOrderSuggestions_V1";
}

export interface PurchaseOrderSuggestionsLineNotification {
  severity: Severity;
  $type:
    | "QuantityLostPrecision"
    | "QuantityDidNotMatch"
    | "UnitAmountLostPrecision"
    | "LineAmountDidNotMatch"
    | "ProductOutOfStock"
    | "ProductArchived"
    | "ProductHidden"
    | "LineNotMatched";
}

export type PurchaseOrderSuggestionsLineNotificationLineAmountDidNotMatch = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @format double */
  receivedLineAmount: number;
  /** @default "LineAmountDidNotMatch" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationLineNotMatched = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @default "LineNotMatched" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationProductArchived = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @default "ProductArchived" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationProductHidden = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @default "ProductHidden" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationProductOutOfStock = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @default "ProductOutOfStock" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationQuantityDidNotMatch = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @format double */
  receivedQuantity: number;
  /** @default "QuantityDidNotMatch" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationQuantityLostPrecision = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @format double */
  receivedQuantity: number;
  /** @default "QuantityLostPrecision" */
  $type: string;
};

export type PurchaseOrderSuggestionsLineNotificationUnitAmountLostPrecision = UtilRequiredKeys<
  PurchaseOrderSuggestionsLineNotification,
  "$type"
> & {
  /** @format double */
  receivedUnitAmount: number;
  /** @default "UnitAmountLostPrecision" */
  $type: string;
};

export interface PurchaseOrderSuggestionsNotification {
  $type: "ProductMatchAmbiguityDetected" | "TotalCheckFailed" | "TotalCouldNotBeVerified";
}

export type PurchaseOrderSuggestionsNotificationProductMatchAmbiguityDetected = UtilRequiredKeys<
  PurchaseOrderSuggestionsNotification,
  "$type"
> & {
  /** @default "ProductMatchAmbiguityDetected" */
  $type: string;
};

export type PurchaseOrderSuggestionsNotificationTotalCheckFailed = UtilRequiredKeys<
  PurchaseOrderSuggestionsNotification,
  "$type"
> & {
  /** @format double */
  receivedTotal: number;
  /** @default "TotalCheckFailed" */
  $type: string;
};

export type PurchaseOrderSuggestionsNotificationTotalCouldNotBeVerified = UtilRequiredKeys<
  PurchaseOrderSuggestionsNotification,
  "$type"
> & {
  /** @default "TotalCouldNotBeVerified" */
  $type: string;
};

export type PurchaseOrderSuggestionsV1 = UtilRequiredKeys<PurchaseOrderSuggestions, "$type"> & {
  referenceNumber?: string | null;
  /** @format date-time */
  deliveryDateUtc?: string | null;
  orderLines?:
    | ((
        | UtilRequiredKeys<PurchaseOrderSuggestionsV1LineMatched, "$type">
        | UtilRequiredKeys<PurchaseOrderSuggestionsV1LineUnmatched, "$type">
      ) & {
        $type: "Matched" | "Unmatched";
      })[]
    | null;
  notifications: ((
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationProductMatchAmbiguityDetected, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationTotalCheckFailed, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationTotalCouldNotBeVerified, "$type">
  ) & {
    $type: "ProductMatchAmbiguityDetected" | "TotalCheckFailed" | "TotalCouldNotBeVerified";
  })[];
  /** @default "PurchaseOrderSuggestions_V1" */
  $type: string;
};

export type PurchaseOrderSuggestionsV1LineMatched = UtilRequiredKeys<Line, "$type"> & {
  productId: string;
  productCode: string;
  documentProductCode?: string | null;
  productName: string;
  productImages?: ProductImagesMetadata | null;
  notifications: ((
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationUnitAmountLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineAmountDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductOutOfStock, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductArchived, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductHidden, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineNotMatched, "$type">
  ) & {
    $type:
      | "QuantityLostPrecision"
      | "QuantityDidNotMatch"
      | "UnitAmountLostPrecision"
      | "LineAmountDidNotMatch"
      | "ProductOutOfStock"
      | "ProductArchived"
      | "ProductHidden"
      | "LineNotMatched";
  })[];
  /** @default "Matched" */
  $type: string;
};

export type PurchaseOrderSuggestionsV1LineUnmatched = UtilRequiredKeys<Line, "$type"> & {
  productCode?: string | null;
  productName?: string | null;
  notifications: ((
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationUnitAmountLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineAmountDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductOutOfStock, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductArchived, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductHidden, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineNotMatched, "$type">
  ) & {
    $type:
      | "QuantityLostPrecision"
      | "QuantityDidNotMatch"
      | "UnitAmountLostPrecision"
      | "LineAmountDidNotMatch"
      | "ProductOutOfStock"
      | "ProductArchived"
      | "ProductHidden"
      | "LineNotMatched";
  })[];
  /** @default "Unmatched" */
  $type: string;
};

export interface QuickAddProducts {
  products: SupplierProductSummary[];
}

export enum RelationshipSource {
  Existing = "Existing",
  Upstock = "Upstock"
}

export interface RemoveCustomerRequest {
  buyerId: string;
}

export interface RemoveQuickAddProductCommand {
  productId: string;
}

export interface RemoveWeeklyStandingOrderProductsBody {
  productsIds: string[];
}

export type ReportIssueTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  issue: OrderIssueType;
  comment: OrderComment;
  /** @default "ReportIssueTimelineMetadata" */
  $type: string;
};

export enum ReportType {
  ProductionReport = "ProductionReport",
  OrdersExportReport = "OrdersExportReport"
}

export type SavedOrdersExportReportDetails = UtilRequiredKeys<SavedSupplierReportDetails, "$type"> & {
  requiredOrderStatus?: OrderStatus | null;
  includeCancelled: boolean;
  exportDate: ExportDateOption;
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  exportFormat: SupplierExportFormatOption;
  /** @default "OrdersExportReport" */
  $type: string;
};

export type SavedProductionReportDetails = UtilRequiredKeys<SavedSupplierReportDetails, "$type"> & {
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  orderStatuses?: OrderStatus[] | null;
  /** @default "ProductionReport" */
  $type: string;
};

export interface SavedSupplierReportDetails {
  reportId: string;
  /** @format date-time */
  lastExportDateUtc?: string | null;
  $type: "ProductionReport" | "OrdersExportReport";
}

export interface SavedSupplierReportSummary {
  reportId: string;
  reportType: ReportType;
  exportFormat?: SupplierExportFormatOption | null;
  /** @format date-time */
  lastExportDateUtc?: string | null;
}

export enum ScheduleSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Scheduler = "Scheduler"
}

export enum ScheduleStatus {
  Active = "Active",
  Paused = "Paused",
  Disabled = "Disabled"
}

export enum ScheduleType {
  Weekly = "Weekly"
}

export enum Severity {
  Error = "Error",
  Warning = "Warning"
}

export type ShipmentStatusUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  status: string;
  /** @default "ShipmentSatusUpdatedTimelineMetadata" */
  $type: string;
};

export type StandingOrderCannotBeDelivered = UtilRequiredKeys<BuyerIssue, "category" | "$type"> & {
  scheduleId: string;
  daysWithIssue: DayOfWeek[];
  category: BuyerIssueCategory;
  /** @default "StandingOrderCannotBeDelivered" */
  $type: string;
};

export interface StandingOrderSchedule {
  externalId: string;
  status: ScheduleStatus;
  type: ScheduleType;
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
  /** @format date-time */
  nextRunDateUtc?: string | null;
  nextRunOrderDay?: DayOfWeek | null;
  pausedBy?: ScheduleSource | null;
}

export interface StandingOrderScheduleSummary {
  scheduleId: string;
  buyerId: string;
  buyerName: string;
  status: ScheduleStatus;
  type: ScheduleType;
}

export interface StandingOrderScheduleSummaryPagedSet {
  links: PageLinks;
  data: StandingOrderScheduleSummary[];
  /** @format int32 */
  total?: number | null;
}

export type StarshipitMetadata = UtilRequiredKeys<OrderExternalDocumentMetadata, "$type"> & {
  tracking: StarshipitTrackingMetadata;
  /** @default "Starshipit" */
  $type: string;
};

export interface StarshipitTrackingMetadata {
  status?: string | null;
  number?: string | null;
  url?: string | null;
}

export interface Statistic {
  /** @format int32 */
  count: number;
}

export enum Status {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected"
}

export interface StringUpdateResult {
  result: string;
}

export enum SupplierExportFormatOption {
  Standard = "Standard",
  Unleashed = "Unleashed",
  MyobAccountRight = "MyobAccountRight",
  Cin7 = "Cin7",
  GlobalBake = "GlobalBake",
  Vinsight = "Vinsight",
  Accredo = "Accredo",
  DeliveryRun = "DeliveryRun",
  ProductionTallies = "ProductionTallies"
}

export interface SupplierOrderSummary {
  /** @format int32 */
  orderId: number;
  buyerName: string;
  buyerId: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  createdDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  orderStatus: OrderStatus;
  source: OrderSource;
  /** @format int32 */
  totalComments: number;
  trackingNumber?: string | null;
  referenceNumber?: string | null;
  notes?: string | null;
  invoice?: InvoiceDetails | null;
  documents: OrderExternalDocument[];
  printed: boolean;
  workflow: OrderWorkflow;
  orderErrors: OrderError[];
  orderProvider?: IncomingOrderProvider | null;
  payment?: (
    | UtilRequiredKeys<PaymentMethodDetails, "$type">
    | UtilRequiredKeys<PaymentLinkDetails, "$type">
    | UtilRequiredKeys<OnAccountDetails, "$type">
    | null
  ) &
    ({
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    } | null);
  hasUnresolvedOrderIssues: boolean;
  deliveryGroup?: OrderDeliveryGroupSummary | null;
}

export interface SupplierOrderSummaryPagedSet {
  links: PageLinks;
  data: SupplierOrderSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface SupplierOrdersExportReportSummaryRow {
  customer: string;
  customerId: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  createdDateUtc: string;
  referenceNumber?: string | null;
  status: OrderStatus;
}

export interface SupplierOrdersExportReportSummaryRowPagedSet {
  links: PageLinks;
  data: SupplierOrdersExportReportSummaryRow[];
  /** @format int32 */
  total?: number | null;
}

export interface SupplierPauseStandingOrderScheduleCommand {
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
  cancelUpcomingOrders?: boolean | null;
}

export interface SupplierProductSummary {
  productId: string;
  name: string;
  code: string;
  status: ProductStatus;
  basePrice?: Money | null;
  buyerPrice?: Money | null;
  isAvailable: boolean;
  isOrderable: boolean;
  unavailableReason?: ProductUnavailableReason | null;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  promotions: ProductPromotion[];
  category?: CategoryRef | null;
}

export interface SupplierProductSummaryPagedSet {
  links: PageLinks;
  data: SupplierProductSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface SupplierResumeStandingOrderScheduleRequest {
  backfillOrders?: boolean | null;
}

export interface SupplierSettings {
  allowDuplicateProductNames: boolean;
  autoAcceptBuyerInvites: boolean;
  allowInternationalBuyers: boolean;
  productSortBy: ProductSortBy;
}

export interface SupplierTag {
  /** @format int32 */
  tagId: number;
  name: string;
}

export interface SupplierTagPagedSet {
  links: PageLinks;
  data: SupplierTag[];
  /** @format int32 */
  total?: number | null;
}

export interface SupplierViewOrder {
  orderDetails: OrderDetails;
  priceListName?: string | null;
  currentPriceListName?: string | null;
  deliveryGroupSummary?: OrderDeliveryGroupSummary | null;
  buyerMetadata: BuyerExternalMetadata;
}

export enum TaxProvider {
  Xero = "Xero",
  Codat = "Codat",
  Dear = "Dear",
  Unleashed = "Unleashed",
  MyIntegrator = "MyIntegrator"
}

export interface TaxRate {
  taxRateId: string;
  code?: string | null;
  name: string;
  /** @format double */
  percentage: number;
}

export interface TaxRateExternalReference {
  identifier: string;
  source: TaxProvider;
}

export interface TaxRatePagedSet {
  links: PageLinks;
  data: TaxRate[];
  /** @format int32 */
  total?: number | null;
}

export enum TaxType {
  Unknown = "Unknown",
  Exclusive = "Exclusive",
  Inclusive = "Inclusive",
  NoTax = "NoTax"
}

export enum TaxonomyShape {
  Tree = "Tree",
  List = "List"
}

export interface TaxonomySummary {
  currentNode?: CategorySummary | null;
  nodeTaxonomy: CategorySummary[];
}

export interface UpdateBuyerInPaymentTermsRequest {
  buyerId: string;
  isInPaymentTerms: boolean;
}

export interface UpdateDeliveryGroupRequest {
  /** @maxLength 280 */
  note?: string | null;
  deliverySchedule?: WeeklyDeliveryScheduleDetails | null;
  /** @maxLength 128 */
  name?: string | null;
  orderMinimums?:
    | (UtilRequiredKeys<OrderMinimumSettingMinimumMonetaryValue, "$type"> & {
        $type: "MinimumMonetaryValue";
      })[]
    | null;
}

export interface UpdateFeaturedCategoriesRequest {
  featureCategoryIds: string[];
}

export interface UpdateFeedPostCommand {
  postId: string;
  contentText: string;
  images: NewsfeedImage[];
}

export interface UpdateInboxMessageBody {
  status?: InboxMessageStatus | null;
  buyerId?: string | null;
}

export interface UpdateLinkedBuyerDetailsCommand {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
}

export interface UpdateLinkedBuyerSettingsCommand {
  disableInvoiceGeneration?: boolean | null;
  consolidateInvoices?: ConsolidatedInvoicesBuyerSetting | null;
  /** @deprecated */
  paymentRequirement?: PaymentRequirement | null;
  paymentTermsId?: string | null;
  deliveryGroupId?: string | null;
  /** @maxLength 2048 */
  defaultOrderNote?: string | null;
  autoOrderApproval?: OverridableSettingBuyerFlag | null;
}

export interface UpdateNotificationForwardingSettingsCommand {
  /** @maxLength 100 */
  ordersEmail?: string | null;
}

export interface UpdateOrderAddressBody {
  deliveryAddress: DeliveryAddress;
}

export interface UpdateOrderAddressResult {
  deliveryAddress: DeliveryAddress;
}

export interface UpdateOrderDeliveryGroupRequest {
  deliveryGroupId: string;
}

export interface UpdateOrderErrorCommand {
  isDismissed: boolean;
}

export interface UpdateOrderSettingsCommand {
  /** @maxLength 280 */
  note?: string | null;
  hideInvoicesFromBuyers?: boolean | null;
  showOrderPricesToBuyers?: boolean | null;
  promptForDeliveryDate?: boolean | null;
  emailInvoiceWhenShipped?: boolean | null;
  disableInvoiceGeneration?: boolean | null;
  disableOrdersSyncWithIntegrations?: boolean | null;
  taxSetting?: TaxType | null;
  invoiceLinesSortBy?: InvoiceLinesSortOrderType | null;
  consolidatedInvoices?: ConsolidatedInvoicesSupplierSetting | null;
  /** @deprecated */
  deliverySchedule?: WeeklyDeliveryScheduleDetails | null;
  /** @format int32 */
  autoApproveStandingOrderOffsetMinutes?: number | null;
  deliveryAreas?: DeliveryAreas | null;
  defaultSupplierEnteredOrderStatus?: OrderStatus | null;
  taxActive?: boolean | null;
  orderMinimums?: (UtilRequiredKeys<FromDefaultsV1, "$type"> | null) &
    ({
      /** @default "FromDefaultsV1" */
      $type: string;
    } | null);
  disableOrderShippedEmail?: boolean | null;
  autoOrderApproval?: OverridableSettingFlag | null;
  priceMismatchAlerts?: boolean | null;
}

export interface UpdateOrderStatusCommand {
  options?: (
    | UtilRequiredKeys<AdvanceOrderOptions, "$type">
    | UtilRequiredKeys<CancelOrderOptions, "$type">
    | UtilRequiredKeys<FoodstuffsCancelOrderOptions, "$type">
    | null
  ) &
    ({
      $type: "Advance" | "Cancel" | "FoodstuffsCancel";
    } | null);
}

export interface UpdateOrderStatusOptions {
  comment?: OrderComment | null;
  $type: "Advance" | "Cancel" | "FoodstuffsCancel";
}

export interface UpdatePaymentOptionForSupplierOrderRequest {
  paymentOption: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
}

export interface UpdatePaymentSettingsCommand {
  /** @deprecated */
  paymentRequirement?: PaymentRequirement | null;
  reconciliationSettings?: PaymentReconciliationSettings | null;
}

export interface UpdatePendingBuyerCommand {
  status?: Status | null;
}

export interface UpdatePriceListCommand {
  priceListId: string;
  /** @maxLength 100 */
  name: string;
}

export interface UpdatePriceListProductsCommand {
  priceListId: string;
  productDetails: PriceListProductRequest[];
}

export interface UpdateProductBody {
  /** @maxLength 100 */
  name: string;
  /** @maxLength 50 */
  code: string;
  status?: ProductStatus | null;
  basePrice?: Money | null;
  priceLists?: ProductPriceListRequest[] | null;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  categoryId?: string | null;
  defaultTaxRates?: CreateDefaultProductTaxRate[] | null;
}

export interface UpdateSupplierSettingsCommand {
  allowDuplicateProductNames?: boolean | null;
  autoAcceptBuyerInvites?: boolean | null;
  allowInternationalBuyers?: boolean | null;
}

export interface UserProfile {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
}

export interface ValidatePurchaseOrderRequest {
  buyerId: string;
  orderLines: ValidatePurchaseOrderRequestOrderLine[];
}

export interface ValidatePurchaseOrderRequestOrderLine {
  lineSuggestionId?: string | null;
  productId?: string | null;
  /** @format double */
  quantity?: number | null;
  customUnitAmount?: Money | null;
}

export interface ValidatePurchaseOrderResponse {
  notifications: ((
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationProductMatchAmbiguityDetected, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationTotalCheckFailed, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsNotificationTotalCouldNotBeVerified, "$type">
  ) & {
    $type: "ProductMatchAmbiguityDetected" | "TotalCheckFailed" | "TotalCouldNotBeVerified";
  })[];
  lines?: ValidatePurchaseResponseOrderLine[] | null;
}

export interface ValidatePurchaseResponseOrderLine {
  notifications: ((
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationQuantityDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationUnitAmountLostPrecision, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineAmountDidNotMatch, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductOutOfStock, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductArchived, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationProductHidden, "$type">
    | UtilRequiredKeys<PurchaseOrderSuggestionsLineNotificationLineNotMatched, "$type">
  ) & {
    $type:
      | "QuantityLostPrecision"
      | "QuantityDidNotMatch"
      | "UnitAmountLostPrecision"
      | "LineAmountDidNotMatch"
      | "ProductOutOfStock"
      | "ProductArchived"
      | "ProductHidden"
      | "LineNotMatched";
  })[];
}

export interface WeeklyDeliveryScheduleDay {
  deliveryAvailable: boolean;
  deadline: Deadline;
}

export interface WeeklyDeliveryScheduleDetails {
  monday: WeeklyDeliveryScheduleDay;
  tuesday: WeeklyDeliveryScheduleDay;
  wednesday: WeeklyDeliveryScheduleDay;
  thursday: WeeklyDeliveryScheduleDay;
  friday: WeeklyDeliveryScheduleDay;
  saturday: WeeklyDeliveryScheduleDay;
  sunday: WeeklyDeliveryScheduleDay;
}

export interface WeeklyStandingOrderProduct {
  product: SupplierProductSummary;
  /** @format double */
  monday: number;
  /** @format double */
  tuesday: number;
  /** @format double */
  wednesday: number;
  /** @format double */
  thursday: number;
  /** @format double */
  friday: number;
  /** @format double */
  saturday: number;
  /** @format double */
  sunday: number;
}

export interface WeeklyStandingOrderRun {
  /** @format date-time */
  nextRunDateUtc: string;
  nextRunOrderDay: DayOfWeek;
}

export namespace Supplier {
  /**
   * No description
   * @tags Buyers
   * @name V1BuyersList
   * @summary  (Auth Permissions: ViewConnectedBuyers)
   * @request GET:/supplier/v1/buyers
   * @secure
   * @response `200` `BuyerSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?: string;
      priceListId?: string;
      paymentTermsId?: string;
      deliveryGroupId?: string;
      sortBy?: string;
      hasStandingOrders?: boolean;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerSummaryPagedSet;
  }
  /**
   * No description
   * @tags Buyers
   * @name V1BuyersDetail
   * @summary Return details about a supplier's customer. (Auth Permissions: ViewConnectedBuyers)
   * @request GET:/supplier/v1/buyers/{buyerId}
   * @secure
   * @response `200` `BuyerDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1BuyersDetail {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerDetails;
  }
  /**
   * No description
   * @tags Buyers
   * @name V1BuyersUpdate
   * @summary Update a buyer's contact details. (Auth Permissions: UpdateConnectedBuyers)
   * @request PUT:/supplier/v1/buyers/{buyerId}
   * @secure
   * @response `200` `BuyerDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1BuyersUpdate {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateLinkedBuyerDetailsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerDetails;
  }
  /**
   * No description
   * @tags Buyers
   * @name V1BuyersStatsList
   * @summary Get stats for the various customer types. (Auth Permissions: ViewConnectedBuyers)
   * @request GET:/supplier/v1/buyers/stats
   * @secure
   * @response `200` `LinkedBuyerStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersStatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = LinkedBuyerStats;
  }
  /**
   * No description
   * @tags Buyers
   * @name V1BuyersSettingsCreate
   * @summary  (Auth Permissions: UpdateBuyerSettings)
   * @request POST:/supplier/v1/buyers/{buyerId}/settings
   * @secure
   * @response `200` `BuyerSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1BuyersSettingsCreate {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateLinkedBuyerSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerSettings;
  }
  /**
   * No description
   * @tags Categories
   * @name V1CategoriesList
   * @request GET:/supplier/v1/categories
   * @response `200` `TaxonomySummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1CategoriesList {
    export type RequestParams = {};
    export type RequestQuery = {
      categoryId?: string;
      /** @deprecated */
      onlySupplierCategories?: boolean;
      search?: string;
      status?: ProductStatus[];
      shape?: TaxonomyShape;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxonomySummary;
  }
  /**
   * No description
   * @tags Categories
   * @name V1CategoriesFeaturedList
   * @summary  (Auth Permissions: SupplierViewFeatureCategories)
   * @request GET:/supplier/v1/categories/featured
   * @secure
   * @response `200` `FeatureCategoryResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CategoriesFeaturedList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FeatureCategoryResult;
  }
  /**
   * No description
   * @tags Categories
   * @name V1CategoriesFeaturedUpdate
   * @summary  (Auth Permissions: SupplierUpdateFeatureCategories)
   * @request PUT:/supplier/v1/categories/featured
   * @secure
   * @response `200` `FeatureCategoryResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CategoriesFeaturedUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateFeaturedCategoriesRequest;
    export type RequestHeaders = {};
    export type ResponseBody = FeatureCategoryResult;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsUpdate
   * @summary  (Auth Permissions: SupplierUpdateDeliveryGroups)
   * @request PUT:/supplier/v1/delivery/groups/{groupId}
   * @secure
   * @response `200` `DeliveryGroupDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1DeliveryGroupsUpdate {
    export type RequestParams = {
      groupId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateDeliveryGroupRequest;
    export type RequestHeaders = {};
    export type ResponseBody = DeliveryGroupDetails;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsDetail
   * @summary  (Auth Permissions: SupplierViewDeliveryGroups)
   * @request GET:/supplier/v1/delivery/groups/{groupId}
   * @secure
   * @response `200` `DeliveryGroupDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1DeliveryGroupsDetail {
    export type RequestParams = {
      groupId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DeliveryGroupDetails;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsDelete
   * @summary  (Auth Permissions: SupplierDeleteDeliveryGroups)
   * @request DELETE:/supplier/v1/delivery/groups/{groupId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1DeliveryGroupsDelete {
    export type RequestParams = {
      groupId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsList
   * @summary  (Auth Permissions: SupplierViewDeliveryGroups)
   * @request GET:/supplier/v1/delivery/groups
   * @secure
   * @response `200` `DeliveryGroupSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1DeliveryGroupsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DeliveryGroupSummaryPagedSet;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsCreate
   * @summary  (Auth Permissions: SupplierCreateDeliveryGroups)
   * @request POST:/supplier/v1/delivery/groups
   * @secure
   * @response `201` `DeliveryGroupDetails` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1DeliveryGroupsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateDeliveryGroupRequest;
    export type RequestHeaders = {};
    export type ResponseBody = DeliveryGroupDetails;
  }
  /**
   * No description
   * @tags DeliveryGroups
   * @name V1DeliveryGroupsBuyersUpdate
   * @summary  (Auth Permissions: SupplierUpdateDeliveryGroups)
   * @request PUT:/supplier/v1/delivery/groups/{groupId}/buyers
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1DeliveryGroupsBuyersUpdate {
    export type RequestParams = {
      groupId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AssignBuyersToDeliveryGroupRequest[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags ErrorNotifications
   * @name V1ErrorNotificationsOrdersList
   * @request GET:/supplier/v1/error-notifications/orders
   * @response `200` `(OrderError)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1ErrorNotificationsOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderError[];
  }
  /**
   * No description
   * @tags ErrorNotifications
   * @name V1ErrorNotificationsOrdersDetail
   * @request GET:/supplier/v1/error-notifications/orders/{notificationId}
   * @response `200` `OrderError` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1ErrorNotificationsOrdersDetail {
    export type RequestParams = {
      /** @format int64 */
      notificationId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderError;
  }
  /**
   * No description
   * @tags ErrorNotifications
   * @name V1ErrorNotificationsOrdersUpdate
   * @request PUT:/supplier/v1/error-notifications/orders/{notificationId}
   * @response `200` `OrderError` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1ErrorNotificationsOrdersUpdate {
    export type RequestParams = {
      /** @format int64 */
      notificationId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderErrorCommand;
    export type RequestHeaders = {};
    export type ResponseBody = OrderError;
  }
  /**
   * No description
   * @tags ErrorNotifications
   * @name V1ErrorNotificationsOrdersRetryCreate
   * @request POST:/supplier/v1/error-notifications/orders/{notificationId}/retry
   * @response `200` `JobRef` Success
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1ErrorNotificationsOrdersRetryCreate {
    export type RequestParams = {
      /** @format int64 */
      notificationId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxCreate
   * @request POST:/supplier/v1/mailbox
   * @response `200` `CreateMailboxResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateMailboxBody;
    export type RequestHeaders = {};
    export type ResponseBody = CreateMailboxResponse;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxList
   * @request GET:/supplier/v1/mailbox
   * @response `200` `GetMailboxResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxList {
    export type RequestParams = {};
    export type RequestQuery = {
      mailboxType?: MailboxType;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetMailboxResponse;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesList
   * @request GET:/supplier/v1/mailbox/inbox/messages
   * @response `200` `InboxMessageSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      statuses?: InboxMessageStatus[];
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InboxMessageSummaryPagedSet;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesDelete
   * @request DELETE:/supplier/v1/mailbox/inbox/messages
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeleteInboxMessagesBody;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesStatsList
   * @request GET:/supplier/v1/mailbox/inbox/messages/stats
   * @response `200` `InboxMessageStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesStatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InboxMessageStats;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesDetail
   * @request GET:/supplier/v1/mailbox/inbox/messages/{externalId}
   * @response `200` `InboxMessageDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesDetail {
    export type RequestParams = {
      externalId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InboxMessageDetails;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesUpdate
   * @request PUT:/supplier/v1/mailbox/inbox/messages/{externalId}
   * @response `200` `InboxMessageDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesUpdate {
    export type RequestParams = {
      externalId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateInboxMessageBody;
    export type RequestHeaders = {};
    export type ResponseBody = InboxMessageDetails;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesFilesDetail
   * @request GET:/supplier/v1/mailbox/inbox/messages/{externalId}/files/{fileId}
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesFilesDetail {
    export type RequestParams = {
      externalId: string;
      fileId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags Mailbox
 * @name V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate
 * @request PUT:/supplier/v1/mailbox/inbox/messages/purchase-orders-suggestions/{purchaseOrderSuggestionsId}
 * @response `200` `(UtilRequiredKeys<PurchaseOrderSuggestionsV1,"$type">) & ({
    $type: "PurchaseOrderSuggestions_V1",

})` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate {
    export type RequestParams = {
      purchaseOrderSuggestionsId: string;
    };
    export type RequestQuery = {
      buyerId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UtilRequiredKeys<PurchaseOrderSuggestionsV1, "$type"> & {
      $type: "PurchaseOrderSuggestions_V1";
    };
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesValidateOrderCreate
   * @request POST:/supplier/v1/mailbox/inbox/messages/validate-order/{inboxMessageId}
   * @response `200` `ValidatePurchaseOrderResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesValidateOrderCreate {
    export type RequestParams = {
      inboxMessageId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ValidatePurchaseOrderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatePurchaseOrderResponse;
  }
  /**
   * No description
   * @tags Mailbox
   * @name V1MailboxInboxMessagesCreateOrderUpdate
   * @request PUT:/supplier/v1/mailbox/inbox/messages/create-order/{inboxMessageId}
   * @response `200` `ProcessPurchaseOrderResponse` Success
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1MailboxInboxMessagesCreateOrderUpdate {
    export type RequestParams = {
      inboxMessageId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateOrderCommand;
    export type RequestHeaders = {};
    export type ResponseBody = ProcessPurchaseOrderResponse;
  }
  /**
   * No description
   * @tags MerchantAccounts
   * @name V1PaymentsAccountsList
   * @request GET:/supplier/v1/payments/accounts
   * @response `200` `PaymentAccountPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1PaymentsAccountsList {
    export type RequestParams = {};
    export type RequestQuery = {
      provider?: PaymentProvider;
      accountId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentAccountPagedSet;
  }
  /**
   * No description
   * @tags MerchantAccounts
   * @name V1PaymentsAccountsCreate
   * @summary  (Auth Permissions: PaymentCreateMerchantAccount)
   * @request POST:/supplier/v1/payments/accounts
   * @secure
   * @response `201` `PaymentAccount` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsAccountsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateMerchantAccountCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentAccount;
  }
  /**
   * No description
   * @tags MerchantAccounts
   * @name V1PaymentsAccountLinkCreate
   * @summary  (Auth Permissions: PaymentLinkMerchantAccount)
   * @request POST:/supplier/v1/payments/account-link
   * @deprecated
   * @secure
   * @response `200` `string` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsAccountLinkCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LinkStripeAccountCommand;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedPostCreate
   * @summary  (Auth Permissions: SupplierCreateNewsfeedPost)
   * @request POST:/supplier/v1/newsfeed/post
   * @secure
   * @response `200` `CreateFeedPostResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedPostCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateFeedPostCommand;
    export type RequestHeaders = {};
    export type ResponseBody = CreateFeedPostResponse;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedPostUpdate
   * @summary  (Auth Permissions: SupplierUpdateNewsfeedPost)
   * @request PUT:/supplier/v1/newsfeed/post/{id}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedPostUpdate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateFeedPostCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedPostDelete
   * @summary  (Auth Permissions: SupplierDeleteNewsfeedPost)
   * @request DELETE:/supplier/v1/newsfeed/post/{id}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedPostDelete {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags OrderIssues
   * @name V1OrderIssuesList
   * @summary Return a list of all unresolved issues raised on orders for the current supplier. (Auth Permissions: ViewOrderIssues)
   * @request GET:/supplier/v1/order-issues
   * @secure
   * @response `200` `(OrderIssueSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrderIssuesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderIssueSummary[];
  }
  /**
   * No description
   * @tags OrderIssues
   * @name V11OrderIssuesList
   * @summary Return a paged set of unresolved order issues raised on orders for the current supplier. (Auth Permissions: ViewOrderIssues)
   * @request GET:/supplier/v1.1/order-issues
   * @secure
   * @response `200` `OrderIssueSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V11OrderIssuesList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderIssueSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderIssues
   * @name V1OrderIssuesResolveCreate
   * @summary Mark an order issue as resolved. (Auth Permissions: ResolveOrderIssue)
   * @request POST:/supplier/v1/order-issues/{orderIssueId}/resolve
   * @secure
   * @response `200` `OrderIssueSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrderIssuesResolveCreate {
    export type RequestParams = {
      orderIssueId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderIssueSummary;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCreate
   * @summary  (Auth Permissions: SupplierCreateOrder)
   * @request POST:/supplier/v1/orders
   * @secure
   * @response `201` `SupplierOrderSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrderCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummary;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersList
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/v1/orders
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      buyerId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags Orders
   * @name V11OrdersDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/v1.1/orders/{orderId}
   * @secure
   * @response `200` `SupplierViewOrder` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V11OrdersDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierViewOrder;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersNotesUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/notes
   * @secure
   * @response `200` `StringUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersNotesUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = string;
    export type RequestHeaders = {};
    export type ResponseBody = StringUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersReferenceUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/reference
   * @secure
   * @response `200` `StringUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersReferenceUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = string;
    export type RequestHeaders = {};
    export type ResponseBody = StringUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersTrackingUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/tracking
   * @secure
   * @response `200` `StringUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersTrackingUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = string;
    export type RequestHeaders = {};
    export type ResponseBody = StringUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersDeliveryaddressUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/deliveryaddress
   * @deprecated
   * @secure
   * @response `200` `StringUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersDeliveryaddressUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = string;
    export type RequestHeaders = {};
    export type ResponseBody = StringUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersDeliveryGroupUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/delivery-group
   * @secure
   * @response `200` `OrderDeliveryGroupSummaryUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersDeliveryGroupUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderDeliveryGroupRequest;
    export type RequestHeaders = {};
    export type ResponseBody = OrderDeliveryGroupSummaryUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersAddressUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/address
   * @secure
   * @response `200` `UpdateOrderAddressResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersAddressUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderAddressBody;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateOrderAddressResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersDeliverydateUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/deliverydate
   * @secure
   * @response `200` `DateTimeUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersDeliverydateUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = string;
    export type RequestHeaders = {};
    export type ResponseBody = DateTimeUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersPrintedUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/v1/orders/{orderId}/printed
   * @secure
   * @response `200` `BooleanUpdateResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersPrintedUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = boolean;
    export type RequestHeaders = {};
    export type ResponseBody = BooleanUpdateResult;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersStatusCreate
   * @summary Transition an order to the given status. (Auth Permissions: SupplierUpdateOrder)
   * @request POST:/supplier/v1/orders/{orderId}/status/{status}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersStatusCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
      status: OrderStatus;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderStatusCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersTimelineDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/v1/orders/{orderId}/timeline
   * @secure
   * @response `200` `(OrderTimeline)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersTimelineDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderTimeline[];
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCommentsCreate
   * @summary  (Auth Permissions: AddComment)
   * @request POST:/supplier/v1/orders/{orderId}/comments
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCommentsCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderComment;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersDelete
   * @summary  (Auth Permissions: DeleteOrder)
   * @request DELETE:/supplier/v1/orders/{orderId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersDelete {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCancelOrderAndPauseScheduleCreate
   * @summary  (Auth Permissions: SupplierUpdateOrder, SupplierUpdateStandingOrderSchedules)
   * @request POST:/supplier/v1/orders/{orderId}/cancel-order-and-pause-schedule
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCancelOrderAndPauseScheduleCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = CancelOrderPauseScheduleCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags Orders
 * @name V1OrdersPaymentOptionCreate
 * @summary This allows a supplier to attach a shared payment method to an order.
If payment is due, it will be taken immediately. (Auth Permissions: PaymentUpdateForOrder)
 * @request POST:/supplier/v1/orders/{orderId}/payment-option
 * @secure
 * @response `200` `(UtilRequiredKeys<PaymentMethodDetails,"$type"> | UtilRequiredKeys<PaymentLinkDetails,"$type"> | UtilRequiredKeys<OnAccountDetails,"$type">) & ({
    $type: "PaymentMethod" | "PaymentLink" | "OnAccount",

})` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1OrdersPaymentOptionCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePaymentOptionForSupplierOrderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | UtilRequiredKeys<PaymentMethodDetails, "$type">
      | UtilRequiredKeys<PaymentLinkDetails, "$type">
      | UtilRequiredKeys<OnAccountDetails, "$type">
    ) & {
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    };
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersTakePaymentCreate
   * @summary  (Auth Permissions: PaymentTakeForOrder)
   * @request POST:/supplier/v1/orders/{orderId}/take-payment
   * @secure
   * @response `200` `JobRef` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersTakePaymentCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersFilesCreate
   * @summary  (Auth Permissions: SupplierUploadOrderFile)
   * @request POST:/supplier/v1/orders/{orderId}/files
   * @secure
   * @response `200` `PrivateFile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersFilesCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      file?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = PrivateFile;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersFilesDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/v1/orders/{orderId}/files/{fileId}
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersFilesDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
      fileId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsDefaultUpdate
   * @summary  (Auth Permissions: PaymentCreatePaymentTerms)
   * @request PUT:/supplier/v1/payments/terms/default
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsTermsDefaultUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsList
   * @summary  (Auth Permissions: PaymentViewPaymentTerms)
   * @request GET:/supplier/v1/payments/terms
   * @secure
   * @response `200` `PaymentTermsSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsTermsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      applicableToBuyerId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentTermsSummaryPagedSet;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsCreate
   * @summary  (Auth Permissions: PaymentCreatePaymentTerms)
   * @request POST:/supplier/v1/payments/terms
   * @secure
   * @response `201` `PaymentTermsDetails` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PaymentTermsCreateRequest;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentTermsDetails;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsWoolworthsCreate
   * @summary  (Auth Permissions: PaymentCreatePaymentTerms)
   * @request POST:/supplier/v1/payments/terms/woolworths
   * @secure
   * @response `201` `PaymentTermsDetails` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsWoolworthsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PaymentTermsCreateRequest;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentTermsDetails;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsDetail
   * @summary  (Auth Permissions: PaymentViewPaymentTerms)
   * @request GET:/supplier/v1/payments/terms/{paymentTermsId}
   * @secure
   * @response `200` `PaymentTermsDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsDetail {
    export type RequestParams = {
      paymentTermsId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentTermsDetails;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsUpdate
   * @summary  (Auth Permissions: PaymentUpdatePaymentTerms)
   * @request PUT:/supplier/v1/payments/terms/{paymentTermsId}
   * @secure
   * @response `200` `PaymentTermsDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsUpdate {
    export type RequestParams = {
      paymentTermsId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PaymentTermsUpdateRequest;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentTermsDetails;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsDelete
   * @summary  (Auth Permissions: PaymentDeletePaymentTerms)
   * @request DELETE:/supplier/v1/payments/terms/{paymentTermsId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsDelete {
    export type RequestParams = {
      paymentTermsId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PaymentTerms
   * @name V1PaymentsTermsBuyersUpdate
   * @summary  (Auth Permissions: PaymentUpdatePaymentTerms)
   * @request PUT:/supplier/v1/payments/terms/{paymentTermsId}/buyers
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsTermsBuyersUpdate {
    export type RequestParams = {
      paymentTermsId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBuyerInPaymentTermsRequest[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Payouts
   * @name V1PaymentsPayoutsList
   * @summary  (Auth Permissions: PaymentViewPayouts)
   * @request GET:/supplier/v1/payments/payouts
   * @secure
   * @response `200` `PayoutPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsPayoutsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PayoutPagedSet;
  }
  /**
   * No description
   * @tags Payouts
   * @name V1PaymentsPayoutsDetail
   * @summary  (Auth Permissions: PaymentViewPayouts)
   * @request GET:/supplier/v1/payments/payouts/{payoutId}
   * @secure
   * @response `200` `Payout` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PaymentsPayoutsDetail {
    export type RequestParams = {
      payoutId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Payout;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingLeadsCreate
   * @summary  (Auth Permissions: AddPendingBuyer)
   * @request POST:/supplier/v1/buyers/pending/leads
   * @secure
   * @response `201` `PendingBuyerSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingLeadsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AddPendingBuyerRequest;
    export type RequestHeaders = {};
    export type ResponseBody = PendingBuyerSummary;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingLeadsList
   * @summary  (Auth Permissions: ViewPendingBuyer)
   * @request GET:/supplier/v1/buyers/pending/leads
   * @secure
   * @response `200` `PendingBuyerSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingLeadsList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingBuyerSummaryPagedSet;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingLeadsDetail
   * @summary  (Auth Permissions: ViewPendingBuyer)
   * @request GET:/supplier/v1/buyers/pending/leads/{requestId}
   * @secure
   * @response `200` `PendingBuyerSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingLeadsDetail {
    export type RequestParams = {
      /** @format int32 */
      requestId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingBuyerSummary;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingLeadsDelete
   * @summary  (Auth Permissions: DeletePendingBuyer)
   * @request DELETE:/supplier/v1/buyers/pending/leads/{requestId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingLeadsDelete {
    export type RequestParams = {
      /** @format int32 */
      requestId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingLeadsUpdate
   * @summary  (Auth Permissions: UpdatePendingBuyer)
   * @request PUT:/supplier/v1/buyers/pending/leads/{requestId}
   * @secure
   * @response `200` `PendingBuyerSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingLeadsUpdate {
    export type RequestParams = {
      /** @format int32 */
      requestId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePendingBuyerCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PendingBuyerSummary;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingInvitesAcceptCreate
   * @summary Accept a buyer invitation to trade. (Auth Permissions: ManageBuyerInvites)
   * @request POST:/supplier/v1/buyers/pending/invites/{buyerId}/accept
   * @secure
   * @response `200` `BuyerLink` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1BuyersPendingInvitesAcceptCreate {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AcceptBuyerLinkRequest;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerLink;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingInvitesDeclineCreate
   * @summary Decline a buyer invite to trade. (Auth Permissions: ManageBuyerInvites)
   * @request POST:/supplier/v1/buyers/pending/invites/{buyerId}/decline
   * @secure
   * @response `200` `BuyerLink` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingInvitesDeclineCreate {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = DeclineBuyerLinkCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerLink;
  }
  /**
   * No description
   * @tags PendingBuyers
   * @name V1BuyersPendingInvitesList
   * @summary  (Auth Permissions: ViewPendingBuyers)
   * @request GET:/supplier/v1/buyers/pending/invites
   * @secure
   * @response `200` `(PendingBuyerLinkSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1BuyersPendingInvitesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingBuyerLinkSummary[];
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsMetaList
   * @summary Returns metadata about our price lists. (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists/meta
   * @secure
   * @response `200` `PriceListsMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsMetaList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListsMetadata;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsList
   * @summary  (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists
   * @secure
   * @response `200` `PriceListSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummaryPagedSet;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsCreate
   * @summary  (Auth Permissions: CreatePriceList)
   * @request POST:/supplier/v1/pricelists
   * @secure
   * @response `201` `PriceListSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreatePriceListCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummary;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsDefaultCreate
   * @summary  (Auth Permissions: CreatePriceList)
   * @request POST:/supplier/v1/pricelists/default
   * @secure
   * @response `201` `PriceListSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsDefaultCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateDefaultPriceListCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummary;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsDetail
   * @summary  (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists/{id}
   * @secure
   * @response `200` `PriceListSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummary;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsUpdate
   * @summary  (Auth Permissions: UpdatePriceList)
   * @request PUT:/supplier/v1/pricelists/{id}
   * @secure
   * @response `200` `PriceListSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsUpdate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePriceListCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummary;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsDelete
   * @summary  (Auth Permissions: DeletePriceList)
   * @request DELETE:/supplier/v1/pricelists/{id}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsDelete {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsStatsDetail
   * @summary  (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists/{id}/stats
   * @secure
   * @response `200` `PriceListStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsStatsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListStats;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsProductsReviewList
   * @summary  (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists/products/review
   * @secure
   * @response `200` `ProductBasePriceValidationPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsProductsReviewList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductBasePriceValidationPagedSet;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsProductsDetail
   * @summary  (Auth Permissions: ViewPriceLists)
   * @request GET:/supplier/v1/pricelists/{id}/products
   * @secure
   * @response `200` `PriceListProductPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsProductsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {
      priceListId?: string;
      search?: string;
      isHidden?: boolean;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListProductPagedSet;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsProductsCreate
   * @summary  (Auth Permissions: UpdatePriceList)
   * @request POST:/supplier/v1/pricelists/{id}/products
   * @secure
   * @response `200` `(PriceListProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsProductsCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePriceListProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListProduct[];
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsCustomersCreate
   * @summary  (Auth Permissions: UpdatePriceList)
   * @request POST:/supplier/v1/pricelists/{id}/customers
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsCustomersCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AddCustomerRequest[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsCustomersDelete
   * @summary  (Auth Permissions: UpdatePriceList)
   * @request DELETE:/supplier/v1/pricelists/{id}/customers
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsCustomersDelete {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RemoveCustomerRequest[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description The URL returned in the `Location` header is for retrieving a more detailed price list object from the API.
   * @tags PriceLists
   * @name V1PricelistsDuplicateCreate
   * @summary Copy an existing price list. (Auth Permissions: DuplicatePriceList)
   * @request POST:/supplier/v1/pricelists/{id}/duplicate
   * @secure
   * @response `201` `PriceListSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1PricelistsDuplicateCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListSummary;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsActivateCreate
   * @summary  (Auth Permissions: ActivatePriceLists)
   * @request POST:/supplier/v1/pricelists/activate
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsActivateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsSyncCreate
   * @summary  (Auth Permissions: SyncPriceLists)
   * @request POST:/supplier/v1/pricelists/sync
   * @secure
   * @response `200` `JobRef` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsSyncCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * No description
   * @tags PriceLists
   * @name V1PricelistsDeactivateCreate
   * @summary  (Auth Permissions: DeactivatePriceLists)
   * @request POST:/supplier/v1/pricelists/deactivate
   * @deprecated
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PricelistsDeactivateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsMetaList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/v1/products/meta
   * @secure
   * @response `200` `ProductMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsMetaList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductMetadata;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/v1/products
   * @deprecated
   * @secure
   * @response `200` `SupplierProductSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      archived?: boolean;
      buyerId?: string;
      pageToken?: string;
      sortBy?: string;
      /** @format int32 */
      limit?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierProductSummaryPagedSet;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsCreate
   * @summary  (Auth Permissions: CreateProduct)
   * @request POST:/supplier/v1/products
   * @deprecated
   * @secure
   * @response `201` `SupplierProductSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateProductLegacyCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierProductSummary;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsUpdate
   * @summary Bulk update products for a supplier. (Auth Permissions: UpdateProduct)
   * @request PUT:/supplier/v1/products
   * @secure
   * @response `200` `BulkUpdateProductsResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1ProductsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {
      updateProvider?: boolean;
    };
    export type RequestBody = BulkUpdateProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BulkUpdateProductsResponse;
  }
  /**
   * No description
   * @tags Products
   * @name V11ProductsList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/v1.1/products
   * @secure
   * @response `200` `SupplierProductSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V11ProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      status?: ProductStatus[];
      promotionTypes?: ProductPromotionType[];
      buyerId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
      categoryId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierProductSummaryPagedSet;
  }
  /**
   * No description
   * @tags Products
   * @name V11ProductsCreate
   * @summary  (Auth Permissions: CreateProduct)
   * @request POST:/supplier/v1.1/products
   * @secure
   * @response `201` `SupplierProductSummary` Created
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V11ProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateProductBody;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierProductSummary;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsQuickAddList
   * @summary  (Auth Permissions: ViewQuickAddProducts)
   * @request GET:/supplier/v1/products/quick-add
   * @secure
   * @response `200` `QuickAddProducts` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsQuickAddList {
    export type RequestParams = {};
    export type RequestQuery = {
      buyerId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QuickAddProducts;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsQuickAddCreate
   * @summary  (Auth Permissions: CreateQuickAddProducts)
   * @request POST:/supplier/v1/products/quick-add
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsQuickAddCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateQuickAddProductCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsQuickAddDelete
   * @summary  (Auth Permissions: RemoveQuickAddProducts)
   * @request DELETE:/supplier/v1/products/quick-add
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsQuickAddDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RemoveQuickAddProductCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsDetail
   * @summary Return details of the supplier product with the given ID. (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/v1/products/{productId}
   * @secure
   * @response `200` `ProductDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1ProductsDetail {
    export type RequestParams = {
      productId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductDetails;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsUpdate2
   * @summary Update the details of the supplier product with the given ID. (Auth Permissions: UpdateProduct)
   * @request PUT:/supplier/v1/products/{productId}
   * @originalName v1ProductsUpdate
   * @duplicate
   * @secure
   * @response `200` `ProductDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsUpdate2 {
    export type RequestParams = {
      productId: string;
    };
    export type RequestQuery = {
      updateProvider?: boolean;
    };
    export type RequestBody = UpdateProductBody;
    export type RequestHeaders = {};
    export type ResponseBody = ProductDetails;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsDelete
   * @summary Delete the supplier product with the given ID. (Auth Permissions: DeleteProduct)
   * @request DELETE:/supplier/v1/products/{productId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1ProductsDelete {
    export type RequestParams = {
      productId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsPromoteCreate
   * @summary  (Auth Permissions: SupplierCreateProductPromotions)
   * @request POST:/supplier/v1/products/{productId}/promote
   * @secure
   * @response `200` `ProductDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsPromoteCreate {
    export type RequestParams = {
      productId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateProductPromotionBody;
    export type RequestHeaders = {};
    export type ResponseBody = ProductDetails;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsPromoteDelete
   * @summary  (Auth Permissions: SupplierDeleteProductPromotions)
   * @request DELETE:/supplier/v1/products/{productId}/promote
   * @secure
   * @response `200` `ProductDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsPromoteDelete {
    export type RequestParams = {
      productId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = DeleteProductPromotionBody;
    export type RequestHeaders = {};
    export type ResponseBody = ProductDetails;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsStatsList
   * @summary Counts of product types/categories. (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/v1/products/stats
   * @secure
   * @response `200` `ProductStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsStatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductStats;
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsImagesCreate
   * @summary  (Auth Permissions: UpdateProduct)
   * @request POST:/supplier/v1/products/images
   * @secure
   * @response `200` `Image` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsImagesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      image?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Image;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsSavedList
   * @summary Returns a list of saved reports for the supplier. (Auth Permissions: SupplierViewReports)
   * @request GET:/supplier/v1/reports/saved
   * @secure
   * @response `200` `(SavedSupplierReportSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsSavedList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedSupplierReportSummary[];
  } /**
 * @description This endpoint uses a polymorphic response and will be one of an object specific to the report being request, eg: `SavedOrdersExportReportDetails` for orders export reports.
 * @tags Reports
 * @name V1ReportsSavedDetail
 * @summary Returns the details of a saved report. (Auth Permissions: SupplierViewReports)
 * @request GET:/supplier/v1/reports/saved/{reportId}
 * @secure
 * @response `200` `(UtilRequiredKeys<SavedProductionReportDetails,"$type"> | UtilRequiredKeys<SavedOrdersExportReportDetails,"$type">) & ({
    $type: "ProductionReport" | "OrdersExportReport",

})` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `404` `void` Not Found
*/
  export namespace V1ReportsSavedDetail {
    export type RequestParams = {
      reportId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | UtilRequiredKeys<SavedProductionReportDetails, "$type">
      | UtilRequiredKeys<SavedOrdersExportReportDetails, "$type">
    ) & {
      $type: "ProductionReport" | "OrdersExportReport";
    };
  }
  /**
   * @description The list can be controlled by the paging and sorting criteria in the query parameters for this endpoint.
   * @tags Reports
   * @name V1ReportsProductionList
   * @summary Returns a paged list of report rows in the production report. (Auth Permissions: SupplierViewProductionReport)
   * @request GET:/supplier/v1/reports/production
   * @secure
   * @response `200` `ProductionReportSummaryRowPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsProductionList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      orderIds?: number[];
      orderStatuses?: OrderStatus[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductionReportSummaryRowPagedSet;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsProductionExportList
   * @summary Return all the order items that were in orders for the given period. (Auth Permissions: SupplierViewProductionReport)
   * @request GET:/supplier/v1/reports/production/export
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsProductionExportList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      orderIds?: number[];
      orderStatuses?: OrderStatus[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsProductionPrintList
   * @summary Create a PDF of the production report that can be used for printing. (Auth Permissions: SupplierViewProductionReport)
   * @request GET:/supplier/v1/reports/production/print
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsProductionPrintList {
    export type RequestParams = {};
    export type RequestQuery = {
      sortBy?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      orderIds?: number[];
      orderStatuses?: OrderStatus[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags Reports
 * @name V1ReportsOrdersExportList
 * @summary Return a paged summary view of the orders that will be included in
an order export report. (Auth Permissions: SupplierViewOrdersExportReport)
 * @request GET:/supplier/v1/reports/orders-export
 * @secure
 * @response `200` `SupplierOrdersExportReportSummaryRowPagedSet` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1ReportsOrdersExportList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      requiredOrderStatus?: OrderStatus;
      includeCancelled?: boolean;
      exportDate?: ExportDateOption;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrdersExportReportSummaryRowPagedSet;
  } /**
 * No description
 * @tags Reports
 * @name V1ReportsOrdersExportAvailableFormatsList
 * @summary Return a list of available orders export report formats for the
current supplier. (Auth Permissions: SupplierViewOrdersExportReport)
 * @request GET:/supplier/v1/reports/orders-export/available-formats
 * @secure
 * @response `200` `(OrdersExportReportFormat)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1ReportsOrdersExportAvailableFormatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrdersExportReportFormat[];
  } /**
 * No description
 * @tags Reports
 * @name V1ReportsOrdersExportRunCreate
 * @summary Return all of the orders in a format that can be used as an input
source for another system. (Auth Permissions: SupplierViewOrdersExportReport)
 * @request POST:/supplier/v1/reports/orders-export/{reportId}/run
 * @secure
 * @response `200` `void` Success
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1ReportsOrdersExportRunCreate {
    export type RequestParams = {
      /**
       * This can currently be anything, but when we move to multiple reports
       * will be the externalId of a SavedReport record, obtained from
       * running a query or an existing saved report.
       */
      reportId: string;
    };
    export type RequestQuery = {
      exportFormat?: SupplierExportFormatOption;
      requiredOrderStatus?: OrderStatus;
      includeCancelled?: boolean;
      exportDate?: ExportDateOption;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsPaymentsPayoutsAccountingDetail
   * @summary  (Auth Permissions: SupplierViewPaymentsPayoutReport)
   * @request GET:/supplier/v1/reports/payments/payouts/{payoutId}/accounting
   * @secure
   * @response `200` `PayoutReconciliationAccountingReport` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsPaymentsPayoutsAccountingDetail {
    export type RequestParams = {
      payoutId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PayoutReconciliationAccountingReport;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsList
   * @summary  (Auth Permissions: SupplierViewSettings)
   * @request GET:/supplier/v1/settings
   * @secure
   * @response `200` `SupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsCreate
   * @summary  (Auth Permissions: SupplierUpdateSettings)
   * @request POST:/supplier/v1/settings
   * @secure
   * @response `200` `SupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateSupplierSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsOrdersMetaList
   * @summary  (Auth Permissions: SupplierViewOrderSettings)
   * @request GET:/supplier/v1/settings/orders/meta
   * @secure
   * @response `200` `OrderSettingsMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsOrdersMetaList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSettingsMetadata;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsOrdersList
   * @summary  (Auth Permissions: SupplierViewOrderSettings)
   * @request GET:/supplier/v1/settings/orders
   * @secure
   * @response `200` `OrderSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsOrdersCreate
   * @summary  (Auth Permissions: SupplierUpdateOrderSettings)
   * @request POST:/supplier/v1/settings/orders
   * @secure
   * @response `200` `OrderSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsOrdersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsPaymentsList
   * @summary  (Auth Permissions: SupplierViewPaymentSettings)
   * @request GET:/supplier/v1/settings/payments
   * @secure
   * @response `200` `PaymentSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsPaymentsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsPaymentsCreate
   * @summary  (Auth Permissions: SupplierUpdatePaymentSettings)
   * @request POST:/supplier/v1/settings/payments
   * @secure
   * @response `200` `PaymentSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsPaymentsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdatePaymentSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsPaymentsMetadataList
   * @summary  (Auth Permissions: SupplierUpdatePaymentSettings)
   * @request GET:/supplier/v1/settings/payments/metadata
   * @deprecated
   * @secure
   * @response `200` `PaymentSettingsMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsPaymentsMetadataList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSettingsMetadata;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsPaymentsMetaList
   * @summary  (Auth Permissions: SupplierUpdatePaymentSettings)
   * @request GET:/supplier/v1/settings/payments/meta
   * @secure
   * @response `200` `PaymentSettingsMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsPaymentsMetaList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSettingsMetadata;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsPaymentsActivateCreate
   * @summary  (Auth Permissions: SupplierUpdatePaymentSettings)
   * @request POST:/supplier/v1/settings/payments/activate
   * @secure
   * @response `200` `PaymentSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsPaymentsActivateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsNotificationForwardingList
   * @summary  (Auth Permissions: SupplierViewNotificationForwardingSettings)
   * @request GET:/supplier/v1/settings/notification-forwarding
   * @secure
   * @response `200` `NotificationForwardingSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsNotificationForwardingList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = NotificationForwardingSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsNotificationForwardingCreate
   * @summary  (Auth Permissions: SupplierUpdateNotificationForwardingSettings)
   * @request POST:/supplier/v1/settings/notification-forwarding
   * @secure
   * @response `200` `NotificationForwardingSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsNotificationForwardingCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateNotificationForwardingSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = NotificationForwardingSettings;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsActivateCreate
   * @summary  (Auth Permissions: ActivateSupplier)
   * @request POST:/supplier/v1/settings/activate
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsActivateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Settings
   * @name V1SettingsDeactivateCreate
   * @summary  (Auth Permissions: DeactivateSupplier)
   * @request POST:/supplier/v1/settings/deactivate
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SettingsDeactivateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersList
   * @summary  (Auth Permissions: SupplierViewStandingOrderSchedules)
   * @request GET:/supplier/v1/schedules/standingorders
   * @secure
   * @response `200` `StandingOrderScheduleSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderScheduleSummaryPagedSet;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersResumeCreate
   * @summary  (Auth Permissions: SupplierUpdateStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/{id}/resume
   * @secure
   * @response `200` `StandingOrderSchedule` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersResumeCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = SupplierResumeStandingOrderScheduleRequest;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderSchedule;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersPauseCreate
   * @summary  (Auth Permissions: SupplierUpdateStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/{id}/pause
   * @secure
   * @response `200` `StandingOrderSchedule` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersPauseCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = SupplierPauseStandingOrderScheduleCommand;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderSchedule;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersBackfillCreate
   * @summary  (Auth Permissions: SupplierCreateStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/{id}/backfill
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SchedulesStandingordersBackfillCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersWeeklyCreate
   * @summary  (Auth Permissions: SupplierCreateStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/weekly
   * @secure
   * @response `200` `StandingOrderSchedule` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SchedulesStandingordersWeeklyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateWeeklyStandingOrderScheduleBody;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderSchedule;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersWeeklyPlanCreate
   * @summary  (Auth Permissions: SupplierViewStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/weekly/plan
   * @secure
   * @response `200` `PlanWeeklyStandingOrderScheduleResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SchedulesStandingordersWeeklyPlanCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PlanWeeklyStandingOrderScheduleBody;
    export type RequestHeaders = {};
    export type ResponseBody = PlanWeeklyStandingOrderScheduleResult;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersWeeklyProductsCreate
   * @summary  (Auth Permissions: SupplierUpdateStandingOrderSchedules)
   * @request POST:/supplier/v1/schedules/standingorders/weekly/{id}/products
   * @secure
   * @response `200` `(WeeklyStandingOrderProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersWeeklyProductsCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ManageWeeklyStandingOrderProductsBody;
    export type RequestHeaders = {};
    export type ResponseBody = WeeklyStandingOrderProduct[];
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersWeeklyProductsDelete
   * @summary  (Auth Permissions: SupplierUpdateStandingOrderSchedules)
   * @request DELETE:/supplier/v1/schedules/standingorders/weekly/{id}/products
   * @secure
   * @response `200` `(WeeklyStandingOrderProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersWeeklyProductsDelete {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RemoveWeeklyStandingOrderProductsBody;
    export type RequestHeaders = {};
    export type ResponseBody = WeeklyStandingOrderProduct[];
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersWeeklyProductsDetail
   * @summary  (Auth Permissions: SupplierViewStandingOrderSchedules)
   * @request GET:/supplier/v1/schedules/standingorders/weekly/{id}/products
   * @secure
   * @response `200` `(WeeklyStandingOrderProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersWeeklyProductsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = WeeklyStandingOrderProduct[];
  }
  /**
   * No description
   * @tags SupplierTags
   * @name V1TagsList
   * @summary  (Auth Permissions: SupplierViewTags)
   * @request GET:/supplier/v1/tags
   * @secure
   * @response `200` `SupplierTagPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1TagsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierTagPagedSet;
  }
  /**
   * No description
   * @tags SupplierTags
   * @name V1TagsCreate
   * @summary  (Auth Permissions: SupplierCreateTags)
   * @request POST:/supplier/v1/tags
   * @secure
   * @response `200` `SupplierTag` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1TagsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateSupplierTagCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierTag;
  }
  /**
   * No description
   * @tags SupplierTags
   * @name V1TagsDelete
   * @summary  (Auth Permissions: SupplierDeleteTags)
   * @request DELETE:/supplier/v1/tags/{tagId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1TagsDelete {
    export type RequestParams = {
      /** @format int32 */
      tagId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags TaxRates
   * @name V1TaxRatesList
   * @summary  (Auth Permissions: ViewTaxRates)
   * @request GET:/supplier/v1/tax-rates
   * @secure
   * @response `200` `TaxRatePagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1TaxRatesList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxRatePagedSet;
  }
}
