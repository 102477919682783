import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import LoadingScreen from "pages/LoadingScreen";
import { Auth0Callback } from "routes/Routes";

/**
 * This page acts as the landing and waiting page for the auth0 callback URL.
 * This only ever displays our loading animation while it gives our
 * `<Auth0Provider>` component the chance to restore URLs from app state.
 */
export default function Auth0CallbackPage(): JSX.Element {
  const history = useHistory();

  // On the off-chance that someone actually entered this page URL into the
  // address bar (it can appear in browser history), set a timer to redirect to
  // the root URL if a redirect hasn't kicked in by then.
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (Auth0Callback.matches(history.location.pathname)) {
        history.replace("/");
      }
    }, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, [history]);

  return <LoadingScreen />;
}
