/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface BuyerOrderLineStatus {
  /** @format int32 */
  lineId: number;
  productId: string;
  productName: string;
  productCode: string;
  /** @format double */
  quantity: number;
  /** @deprecated */
  buyerPrice?: Money | null;
  unitAmount?: Money | null;
  hasCustomUnitAmount: boolean;
  priceListUnitAmount?: Money | null;
  isFavourite: boolean;
  isAvailable: boolean;
  unavailableReason?: ProductUnavailableReason | null;
  imagesMetadata?: ProductImagesMetadata | null;
}

export interface BuyerOrderPaymentDetails {
  status: PaymentStatus;
}

export interface BuyerOrderSummary {
  /** @format int32 */
  orderGroupId: number;
  /** @format int32 */
  buyerOrderId: number;
  supplierName: string;
  supplierId: string;
  /** @format int32 */
  supplierOrderId: number;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  orderStatus: OrderStatus;
  source?: OrderSource | null;
  /** @format int32 */
  orderLinesCount: number;
  actions: OrderTransition[];
  /** @format int32 */
  totalComments: number;
  referenceNumber?: string | null;
  invoiceNumber?: string | null;
  trackingNumber?: string | null;
  supplierLogoUrl?: string | null;
  payment?: BuyerOrderPaymentDetails | null;
}

export interface BuyerOrderSummaryPagedSet {
  links: PageLinks;
  data: BuyerOrderSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface CalculatedDeliverySchedule {
  monday: CalculatedDeliveryScheduleDay;
  tuesday: CalculatedDeliveryScheduleDay;
  wednesday: CalculatedDeliveryScheduleDay;
  thursday: CalculatedDeliveryScheduleDay;
  friday: CalculatedDeliveryScheduleDay;
  saturday: CalculatedDeliveryScheduleDay;
  sunday: CalculatedDeliveryScheduleDay;
}

export interface CalculatedDeliveryScheduleDay {
  deliveryAvailable: boolean;
  /** @format date-time */
  deliveryDateUtc?: string | null;
  /** @format date-time */
  cutOffDateUtc?: string | null;
}

export type CardPaymentMethod = UtilRequiredKeys<PaymentMethod, "$type"> & {
  cardType: CardPaymentMethodType;
  /** @format int32 */
  expirationMonth: number;
  /** @format int32 */
  expirationYear: number;
  last4: string;
  country?: Alpha2Code | null;
  failureDetails?: (UtilRequiredKeys<DefaultPaymentFailure, "$type"> | null) &
    ({
      $type: "Default";
    } | null);
  /** @default "Card" */
  $type: string;
};

export enum CardPaymentMethodType {
  Visa = "Visa",
  Mastercard = "Mastercard",
  Amex = "Amex",
  Diners = "Diners",
  Discover = "Discover",
  Jcb = "Jcb",
  UnionPay = "UnionPay"
}

export interface CategoryRef {
  id: string;
  name: string;
  fullName: string;
}

export interface CreateStripeSetupIntentResult {
  setupIntentId: string;
  clientSecret: string;
  publishableKey: string;
}

export interface Currency {
  code: string;
}

export type DefaultPaymentFailure = UtilRequiredKeys<PaymentFailureDetails, "$type"> & {
  /** @default "Default" */
  $type: string;
};

export interface EditOrderLinesAndConfirmStandingOrderCommand {
  lines: OrderLineItemRequest[];
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export interface FeatureCategorySummary {
  category: CategoryRef;
  /** @format int64 */
  productCount: number;
}

export type FromDefaultsV1 = UtilRequiredKeys<OrderMinimums, "$type"> & {
  status: OrderMinimumsStatus;
  monetaryValue?: Money | null;
  /** @default "FromDefaultsV1" */
  $type: string;
};

export interface GetBuyerOrderLinesResult {
  supplierId: string;
  supplierName: string;
  supplierLogoUrl?: string | null;
  buyerId: string;
  buyerName: string;
  /** @format int32 */
  orderId: number;
  lines: BuyerOrderLineStatus[];
  hasCustomPrices: boolean;
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface Money {
  /** @format double */
  amount: number;
  currency: Currency;
}

export type OnAccountOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "OnAccount" */
  $type: string;
};

export interface OrderLine {
  productName: string;
  productCode: string;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  productId: string;
  status: ProductStatus;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: OrderLineProblem[];
  hasCustomUnitAmount: boolean;
  priceListUnitAmount?: Money | null;
  priceListLineAmount?: Money | null;
  productImages?: ProductImagesMetadata | null;
  externalReferences: ProductExternalReference[];
  tax?: OrderLineTaxDetails | null;
  packingStatus?: OrderLinePackingStatus | null;
}

export interface OrderLineItemRequest {
  productId: string;
  /** @format double */
  quantity: number;
  /** @format double */
  customUnitAmount?: number | null;
  /** @format int32 */
  orderLineId?: number | null;
}

export enum OrderLinePackingStatus {
  NotPacked = "NotPacked",
  Packed = "Packed"
}

export interface OrderLineProblem {
  type: OrderLineProblemType;
  severity: Severity;
}

export enum OrderLineProblemType {
  DifferentPrice = "DifferentPrice",
  ProductArchived = "ProductArchived",
  ProductOutOfStock = "ProductOutOfStock",
  ProductHidden = "ProductHidden",
  NoTaxRatesCoded = "NoTaxRatesCoded",
  ProductDeleted = "ProductDeleted"
}

export interface OrderLineResult {
  /** @format int32 */
  orderId: number;
  lines: OrderLine[];
}

export interface OrderLineTaxDetails {
  taxRateComponents: OrderLineTaxRateComponent[];
  taxAmount: Money;
}

export interface OrderLineTaxRateComponent {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderMinimumSetting {
  $type: "MinimumMonetaryValue";
}

export type OrderMinimumSettingMinimumMonetaryValue = UtilRequiredKeys<OrderMinimumSetting, "$type"> & {
  /** @format double */
  value: number;
  /** @default "MinimumMonetaryValue" */
  $type: string;
};

export interface OrderMinimums {
  $type: "FromDefaultsV1";
}

export enum OrderMinimumsStatus {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export interface OrderSettingsSummary {
  supplierId: string;
  timezone: string;
  orderNote?: string | null;
  promptForDeliveryDate: boolean;
  emailInvoiceWhenShipped: boolean;
  calculatedDeliverySchedule: CalculatedDeliverySchedule;
  paymentOptions: SupplierPaymentOptions;
  orderMinimumSettings?:
    | (UtilRequiredKeys<OrderMinimumSettingMinimumMonetaryValue, "$type"> & {
        $type: "MinimumMonetaryValue";
      })[]
    | null;
  upcomingOrders: UpcomingOrderDetails[];
  /** @deprecated */
  orderMinimums?: (UtilRequiredKeys<FromDefaultsV1, "$type"> | null) &
    ({
      $type: "FromDefaultsV1";
    } | null);
}

export enum OrderSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  StandingOrder = "StandingOrder"
}

export enum OrderStatus {
  Ordered = "Ordered",
  Processing = "Processing",
  Shipped = "Shipped",
  Cancelled = "Cancelled",
  Received = "Received",
  Scheduled = "Scheduled",
  Delivered = "Delivered"
}

export interface OrderTransition {
  isPrimary: boolean;
  action: OrderStatus;
  actionUrl: string;
  isDisabled: boolean;
}

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface PaymentFailureDetails {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
  $type: "Default";
}

export enum PaymentFailureReason {
  Unknown = "Unknown",
  InsufficientFunds = "InsufficientFunds",
  Expired = "Expired"
}

export enum PaymentFailureType {
  Retryable = "Retryable",
  NonRetryable = "NonRetryable"
}

export type PaymentLinkOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "PaymentLink" */
  $type: string;
};

export interface PaymentMerchantAccount {
  provider: PaymentProvider;
  region: Alpha2Code;
}

export interface PaymentMethod {
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  identifier: string;
  /** @format date-time */
  createdDateUtc: string;
  status: PaymentMethodStatus;
  $type: "Card";
}

export type PaymentMethodOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  /** @default "PaymentMethod" */
  $type: string;
};

export enum PaymentMethodStatus {
  Active = "Active",
  Failed = "Failed",
  Hidden = "Hidden"
}

export interface PaymentOption {
  isUnavailable: boolean;
  unavailableReason?: PaymentOptionUnavailableReason | null;
  $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
}

export enum PaymentOptionUnavailableReason {
  UnsupportedMerchantAccount = "UnsupportedMerchantAccount",
  PaymentMethodFailed = "PaymentMethodFailed"
}

export enum PaymentProvider {
  Stripe = "Stripe"
}

export enum PaymentRequirement {
  SupplierDefault = "SupplierDefault",
  None = "None",
  PayNow = "PayNow",
  PayNowOptional = "PayNowOptional"
}

export enum PaymentStatus {
  Unknown = "Unknown",
  Unpaid = "Unpaid",
  Processing = "Processing",
  Paid = "Paid",
  Failed = "Failed",
  Scheduled = "Scheduled"
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProductExternalReference {
  identifier: string;
  source: ProductProvider;
  name: string;
}

export interface ProductImagesMetadata {
  images: Image[];
}

export enum ProductProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export enum ProductStatus {
  Active = "Active",
  Archived = "Archived",
  OutOfStock = "OutOfStock"
}

export enum ProductUnavailableReason {
  Unavailable = "Unavailable",
  Archived = "Archived",
  Hidden = "Hidden",
  OutOfStock = "OutOfStock",
  Deleted = "Deleted"
}

export interface ScheduledOrdersSummary {
  supplierId: string;
  supplierTradingName: string;
}

export enum Severity {
  Error = "Error",
  Warning = "Warning"
}

export interface SupplierCategoriesMetadata {
  featureCategories: FeatureCategorySummary[];
  hasCategorisedProducts: boolean;
}

export interface SupplierPaymentOptions {
  requirement: PaymentRequirement;
  supportedAccounts: PaymentMerchantAccount[];
  options: ((
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  })[];
}

export interface SupplierProductMetadata {
  categoriesMetadata: SupplierCategoriesMetadata;
}

export enum TaxProvider {
  Xero = "Xero",
  Codat = "Codat",
  Dear = "Dear",
  Unleashed = "Unleashed",
  MyIntegrator = "MyIntegrator"
}

export interface TaxRate {
  taxRateId: string;
  code?: string | null;
  name: string;
  /** @format double */
  percentage: number;
}

export interface TaxRateExternalReference {
  identifier: string;
  source: TaxProvider;
}

export interface UpcomingOrderDetails {
  /** @format int32 */
  buyerOrderId: number;
  /** @format date-time */
  deliveryDateUtc: string;
}

export namespace Buyer {
  /**
   * No description
   * @tags Invoice
   * @name InternalInvoicesPdfDetail
   * @summary  (Auth Permissions: ViewInvoice)
   * @request GET:/buyer/internal/invoices/{orderId}/pdf
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `ProblemDetails` Not Found
   */
  export namespace InternalInvoicesPdfDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersLinesDetail
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/orders/{buyerOrderId}/lines
   * @secure
   * @response `200` `GetBuyerOrderLinesResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesDetail {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetBuyerOrderLinesResult;
  } /**
 * No description
 * @tags Orders
 * @name InternalOrdersLinesCreate
 * @summary This is useful if you want to add or update quantities for items.
This will not replace all items with the requested items, only update
those matching.
            
Use PUT if you want to replace the line items entirely. (Auth Permissions: BuyerUpdateOrder)
 * @request POST:/buyer/internal/orders/{buyerOrderId}/lines
 * @secure
 * @response `200` `OrderLineResult` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `404` `void` Not Found
*/
  export namespace InternalOrdersLinesCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderLineItemRequest[];
    export type RequestHeaders = {
      "if-Match"?: string;
    };
    export type ResponseBody = OrderLineResult;
  } /**
 * No description
 * @tags Orders
 * @name InternalOrdersLinesUpdate
 * @summary This will replace all line items for the order with those in the
request. This allows line items to be removed. So all of the line
items must be included in this request.
            
If you only want to add one line item, or update it's quantity,
you can use POST instead. (Auth Permissions: BuyerUpdateOrder)
 * @request PUT:/buyer/internal/orders/{buyerOrderId}/lines
 * @secure
 * @response `200` `OrderLineResult` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `404` `void` Not Found
*/
  export namespace InternalOrdersLinesUpdate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderLineItemRequest[];
    export type RequestHeaders = {
      "if-Match"?: string;
    };
    export type ResponseBody = OrderLineResult;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersReplaceLinesAndConfirmOrderUpdate
   * @summary  (Auth Permissions: BuyerUpdateOrder)
   * @request PUT:/buyer/internal/orders/{buyerOrderId}/replace-lines-and-confirm-order
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersReplaceLinesAndConfirmOrderUpdate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = EditOrderLinesAndConfirmStandingOrderCommand;
    export type RequestHeaders = {
      "if-Match"?: string;
    };
    export type ResponseBody = OrderLineResult;
  }
  /**
   * No description
   * @tags Stripe
   * @name InternalStripeSetupIntentCreate
   * @summary  (Auth Permissions: PaymentSetupMethods)
   * @request POST:/buyer/internal/stripe/setup-intent
   * @secure
   * @response `200` `CreateStripeSetupIntentResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalStripeSetupIntentCreate {
    export type RequestParams = {};
    export type RequestQuery = {
      providerRegion?: Alpha2Code;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CreateStripeSetupIntentResult;
  }
  /**
   * No description
   * @tags Suppliers
   * @name InternalSuppliersSettingsList
   * @summary Get order settings for each of the given supplierIds. (Auth Permissions: BuyerViewSupplierSettings)
   * @request GET:/buyer/internal/suppliers/settings
   * @secure
   * @response `200` `(OrderSettingsSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalSuppliersSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {
      supplierIds?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderSettingsSummary[];
  }
  /**
   * No description
   * @tags Suppliers
   * @name InternalSuppliersScheduledOrdersList
   * @summary Get scheduled orders for each of the given supplierIds. (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/suppliers/scheduled-orders
   * @secure
   * @response `200` `(ScheduledOrdersSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalSuppliersScheduledOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {
      supplierIds?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ScheduledOrdersSummary[];
  }
  /**
   * No description
   * @tags Suppliers
   * @name InternalSuppliersProductsMetaDetail
   * @summary  (Auth Permissions: BuyerViewProducts)
   * @request GET:/buyer/internal/suppliers/{id}/products/meta
   * @secure
   * @response `200` `SupplierProductMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalSuppliersProductsMetaDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierProductMetadata;
  }
  /**
   * No description
   * @tags Views
   * @name InternalViewsOrdersRecentList
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/views/orders/recent
   * @secure
   * @response `200` `BuyerOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersRecentList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags Views
   * @name InternalViewsOrdersDueList
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/views/orders/due
   * @secure
   * @response `200` `BuyerOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersDueList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags Views
   * @name InternalViewsOrdersScheduledList
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/views/orders/scheduled
   * @secure
   * @response `200` `BuyerOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersScheduledList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags Views
   * @name InternalViewsOrdersFailedPaymentsList
   * @summary Return a list of orders with failed payments on them. (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/internal/views/orders/failed-payments
   * @secure
   * @response `200` `BuyerOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersFailedPaymentsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummaryPagedSet;
  }
}
