import {
  AuthenticatedOnboarding,
  AuthenticatedOnboardingAcceptInvite,
  AuthenticatedOnboardingInviteAccepted,
  BuyerNewOrder,
  BuyerNewOrderConfirm,
  BuyerNewOrderEdit,
  BuyerNewOrderMobileProducts,
  BuyerNewOrderMobileProductsGrouped,
  BuyerOnboardingNewUserWelcome,
  CompanyOnboardingRoot,
  OnboardingBusinessProfile1,
  OnboardingBusinessProfile2,
  OnboardingChooseInviteUser,
  OnboardingInviteAccepted,
  OnboardingNewUserWelcome,
  OnboardingPersonalProfile,
  OnboardingRoot,
  SupplierNewOrder,
  SupplierNewOrderChooseCustomer,
  SupplierNewOrderConfirm,
  SupplierNewOrderEdit
} from "routes/Routes";

/**
 * Routes that aren't safe to be reloaded because some important state will be lost.
 * Used by the update checker so that these URLs aren't automatically reloaded.
 */
export const unreloadableRoutes = [
  BuyerNewOrder,
  BuyerNewOrderConfirm,
  BuyerNewOrderEdit,
  BuyerNewOrderMobileProducts,
  BuyerNewOrderMobileProductsGrouped,
  SupplierNewOrder,
  SupplierNewOrderChooseCustomer,
  SupplierNewOrderConfirm,
  SupplierNewOrderEdit,
  CompanyOnboardingRoot,
  OnboardingPersonalProfile,
  OnboardingBusinessProfile1,
  OnboardingBusinessProfile2,
  OnboardingNewUserWelcome,
  BuyerOnboardingNewUserWelcome,
  AuthenticatedOnboarding,
  AuthenticatedOnboardingAcceptInvite,
  AuthenticatedOnboardingInviteAccepted,
  OnboardingRoot,
  OnboardingChooseInviteUser,
  OnboardingInviteAccepted
];

export function isPathReloadable(pathname: string): boolean {
  return !unreloadableRoutes.some(route => route.matches(pathname));
}
