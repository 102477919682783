import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { GlobalInternal } from "types/api";
import { Alpha2Code } from "types/api/generated/supplier";
import useDebounceValue from "utils/hooks/useDebounceValue";
import { safeurl } from "utils/urls";

import addressLookupQueryKeys from "./addressLookupQueryKeys";

type RequestBody = GlobalInternal.InternalGeographyAddressesList.RequestBody;
type RequestQuery = GlobalInternal.InternalGeographyAddressesList.RequestQuery;
type ResponseBody = GlobalInternal.InternalGeographyAddressesList.ResponseBody;

export const fetchAddressLookupParams = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestQuery | undefined
>(searchParams => ({
  method: "GET",
  path: safeurl`/global/internal/geography/addresses`,
  searchParams
}));

export const useAddressLookup = ({ search, countryCode }: RequestQuery): UseQueryResult<ResponseBody> => {
  const apiFetch = useApiFetch();
  const debouncedSearch = useDebounceValue(search || "", 500);

  return useQuery({
    queryKey: addressLookupQueryKeys.detail(debouncedSearch, countryCode).queryKey,
    queryFn: async () => {
      const response = await apiFetch(
        fetchAddressLookupParams({
          search: debouncedSearch,
          countryCode
        })
      );

      return response.data;
    },
    enabled:
      Boolean(debouncedSearch) &&
      debouncedSearch.length > 2 &&
      (countryCode === Alpha2Code.NZ || countryCode === Alpha2Code.AU),
    useErrorBoundary: false, // these are just suggestions, so don't throw the full error boundary
    staleTime: Infinity, // never clear this because these results won't change frequently, and this will result in less calls to our API
    retry: false
  });
};
