import { ActionCreatorWithPayload, PayloadAction } from "@reduxjs/toolkit";

import { ApiValidationProblem } from "types/api/generated/supplier";

export interface PagingOptions {
  overrideList?: boolean;
  prepend?: boolean;
}

/**
 * Helper type for creating a request action type with the success and failure callbacks added.
 *
 * Useful for typing createSlice().
 *
 * @template P Action payload type
 * @template R Success response type
 * @template E Failure error type
 */
export type RequestAction<P extends Record<string, any>, R = unknown, E = Error | ApiValidationProblem> = PayloadAction<
  RequestPayload<P, R, E>
>;

/**
 * Helper type for creating a request payload type with the success and failure callbacks added.
 *
 * Useful for typing createAction().
 *
 * @template P Action payload type
 * @template R Success response type
 * @template E Failure error type
 */
export type RequestPayload<P extends Record<string, any>, R = unknown, E = Error | ApiValidationProblem> = P & {
  successCallback?: (response: R) => void;
  failureCallback?: (error: E) => void;
};

/**
 * Type of an action accepted by `redux/tasks/loadNextPageV2`.
 */
export type LoadNextPageRequest<R = unknown, E = unknown> = PayloadAction<
  PagingOptions & {
    url?: string | null;
    successCallback?: (data: R) => void;
    failureCallback?: (error: E) => void;
    onSuccess: ActionCreatorWithPayload<any>;
    onFailure: ActionCreatorWithPayload<any>;
  }
>;

/**
 * Type for an action accepted by `redux/tasks/loadPreviousPage`.
 */
export type LoadPreviousPageRequest<R = unknown, E = unknown> = PayloadAction<
  PagingOptions & {
    url?: string | null;
    successCallback?: (data: R) => void;
    failureCallback?: (error: E) => void;
    onSuccess: ActionCreatorWithPayload<any>;
    onFailure: ActionCreatorWithPayload<any>;
  }
>;

/**
 * Type for failure actions with the combined JS error or API validation failures
 * in our reducers.
 */
export type FailureAction = PayloadAction<ApiValidationProblem | Error>;

/**
 * Function to remove duplication in the definition of the payload type when
 * using `createAction`, as suggested by:
 * https://redux-toolkit.js.org/usage/usage-with-typescript#createaction
 */
export function withPayloadType<T>() {
  return (payload: T) => ({ payload });
}
