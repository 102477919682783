import { logger } from "utils/Datadog";

export const FETCH_TIMEOUT_ERROR = "TimeoutError";
export const FETCH_NETWORK_ERROR = "NetworkError";
export const FETCH_REQUEST_ERROR = "RequestError";

export interface FetchV2ErrorOptions {
  request: RequestInit;
  url: string;
  status?: number;
  statusText?: string;
  body?: unknown;
  code?: string;
}

export class FetchV2Error extends Error {
  code?: string;

  url?: string;

  endpoint?: string;

  method?: string;

  status?: number;

  statusText?: string;

  body?: unknown;

  constructor(message: string, options?: FetchV2ErrorOptions) {
    super(message);
    this.name = "FetchV2Error";

    if (options) {
      const method = options.request.method?.toUpperCase() || "GET";
      const { pathname } = new URL(options.url, window.location.origin);
      const endpoint = `${method} ${pathname}`;
      this.code = options.code;
      this.endpoint = endpoint;
      this.url = options.url;
      this.method = method;
      this.body = options.body;
      this.status = options.status;
      this.statusText = options.statusText;

      // Generate an error message if none was provided
      if (options.status && !message) {
        this.message = `${options.status} ${endpoint}`;
      }
    }
  }
}

export function isFetchV2Error(error: unknown): error is FetchV2Error {
  return error instanceof FetchV2Error;
}

/**
 * Checks if the given media type is a JSON derived media type.
 *
 * Matches things like:
 * - `application/json`
 * - `application/json; charset=UTF-8`
 * - `application/json-patch+json`
 * - `application/geo+json`
 */
export function isJsonMediaType(mediaType: string | null | undefined): boolean {
  if (!mediaType) {
    return false;
  }

  return /application\/[^+]*[+]?json;?.*/.test(mediaType);
}

/**
 * Check if the given media type matches `text/*`.
 */
function isTextMediaType(mediaType: string | null | undefined): boolean {
  return mediaType?.startsWith("text/") ?? false;
}

export interface FetchV2Options extends RequestInit {
  /**
   * Cancel the request after the given number of milliseconds.
   *
   * This option is ignored when a `signal` is provided.
   * */
  timeout?: number;
}

/**
 * Small wrapper around fetch() that adds timeout functionality and standardised error formatting.
 */
export default async function fetchV2(url: string, options?: FetchV2Options): Promise<Response> {
  const fetchOptions: FetchV2Options = {
    method: "GET",
    ...options
  };

  let controller: AbortController | undefined;
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  if (!fetchOptions.signal && fetchOptions.timeout) {
    controller = new AbortController();
    fetchOptions.signal = controller.signal;
    timeoutId = setTimeout(() => controller?.abort(), fetchOptions.timeout);
  }

  try {
    const response = await fetch(url, fetchOptions);
    await saveApiCall(url, fetchOptions, response);

    clearTimeout(timeoutId);

    if (!response.ok) {
      // Try to extract the response body of the failed request
      let body: unknown;
      try {
        if (isJsonMediaType(response.headers.get("content-type"))) {
          body = await response.json();
        } else {
          body = await response.text();
        }
      } catch (error) {
        // Ignore errors parsing the response body
      }

      throw new FetchV2Error("", {
        code: FETCH_REQUEST_ERROR,
        url,
        request: fetchOptions,
        status: response.status,
        statusText: response.statusText,
        body
      });
    }

    return response;
  } catch (error: unknown) {
    clearTimeout(timeoutId);

    // Non-Errors should never be thrown, but handle it just in case
    if (!(error instanceof Error)) {
      throw new FetchV2Error(`Fetch error (${error})`, { url, request: fetchOptions });
    }

    // If the error was thrown above, just rethrow it
    if (isFetchV2Error(error)) {
      throw error;
    }

    const err = new FetchV2Error(`Fetch error (${error.message})`, { url, request: fetchOptions });

    if (error.name === "AbortError") {
      err.message = "Fetch timeout error";
      err.code = FETCH_TIMEOUT_ERROR;
    } else if (error instanceof TypeError) {
      err.message = "Fetch network error";
      err.code = FETCH_NETWORK_ERROR;
    }

    throw err;
  }
}

/**
 * A simplified representation of a fetch request/response for logging purposes.
 */
type ApiCall = {
  requestHeaders: Record<string, string>;
  requestBody?: any;
  responseHeaders: Record<string, string>;
  responseBody?: any;
};

/**
 * Log API calls made so we can see them in datadog session replays.
 */
async function saveApiCall(url: string, fetchOptions: FetchV2Options, response: Response): Promise<void> {
  try {
    const requestHeaders = headersRecordFromHeadersInit(fetchOptions.headers);
    if (requestHeaders.Authorization) {
      requestHeaders.Authorization = "(removed)";
    }
    const requestContentType = requestHeaders["Content-Type"];
    const responseForLogging = response.clone();
    const responseContentType = responseForLogging.headers.get("content-type");

    const apiCall: ApiCall = {
      requestHeaders,
      requestBody: requestContentType
        ? isJsonMediaType(requestContentType) || isTextMediaType(requestContentType)
          ? fetchOptions.body
          : "(binary or other format)"
        : "(no request body)",
      responseHeaders: Object.fromEntries(responseForLogging.headers),
      responseBody: isJsonMediaType(responseContentType)
        ? ((await responseForLogging.json()) ?? "(no response body)")
        : isTextMediaType(responseContentType)
          ? ((await responseForLogging.text()) ?? "(no response body)")
          : "(binary or other format)"
    };
    putMatchingApiCall(url, fetchOptions.method, apiCall);
  } catch (error) {
    logger.error("Failed to save API call", undefined, error instanceof Error ? error : undefined);
  }
}

/**
 * A function to take a `HeadersInit` object, which is one of several types, and
 * return a simpler `Record` type that we can use for logging.
 */
function headersRecordFromHeadersInit(headers: HeadersInit | undefined): Record<string, string> {
  let record: Record<string, string> = {};
  if (headers instanceof Headers) {
    headers.forEach((value, key) => {
      record[key] = value;
    });
  } else if (Array.isArray(headers)) {
    headers.forEach(([key, value]) => {
      record[key] = value;
    });
  } else if (headers) {
    record = headers;
  }
  return record;
}

/**
 * A mapping of fetch requests made to their serialized request/response
 * objects, so that we can log them alongside a DataDog RUM event later.
 */
const apiCalls = new Map<string, ApiCall>();

function apiCallKey(url: string, method: string | undefined): string {
  return JSON.stringify({ url, method });
}

/**
 * Store a serialized request/response object against the given key values.
 */
function putMatchingApiCall(url: string, method: string | undefined, apiCall: ApiCall): void {
  // If for some reason we reach 50 stored calls, free up half the space by deleting the 25 oldest entries
  if (apiCalls.size >= 50) {
    const keyIter = apiCalls.keys();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 25; i++) {
      apiCalls.delete(keyIter.next().value);
    }
  }
  apiCalls.set(apiCallKey(url, method), apiCall);
}

/**
 * Retrieve a matching serialized request/response object for the given fetch
 * request values.
 */
export function takeMatchingApiCall(url: string, method: string | undefined): ApiCall | undefined {
  const fetchKey = apiCallKey(url, method);
  const match = apiCalls.get(fetchKey);

  // Clear entries once retrieved so this object doesn't grow.  Could probably
  // use an LRU cache implementation here to be smarter instead.
  if (match) {
    apiCalls.delete(fetchKey);
  }
  return match;
}
