import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { loadNextPageRequest as loadNextMatchingProductsPage } from "features/suppliers/match-products/reducers/products";
import { fetchAllPriceListsRequest } from "features/suppliers/price-lists/reducers/allPriceLists";
import fetchAllPriceLists from "features/suppliers/price-lists/sagas/fetchAllPriceLists";
import { hideModalsRequest } from "redux/actions/hideModals";
import { uploadOrderAttachmentRequest } from "redux/actions/uploadOrderAttachment";
import { fetchNavigationDataRequest, fetchNavigationDataSilentlyRequest } from "redux/reducers/navigationData";
import { fetchBuyerByIdRequest, updateBuyerByIdRequest } from "redux/reducers/supplier/buyerById";
import {
  fetchCustomersRequest,
  loadNextPageRequest as customersLoadNextPageRequest,
  loadPreviousPageRequest as customersLoadPreviousPageRequest
} from "redux/reducers/supplier/customers";
import { loadPaymentSettingsMetadataRequest } from "redux/reducers/supplier/paymentSettingsMetadata";
import {
  loadNextPageNoPricesRequest,
  loadNextPageRequest as supplierProductsLoadNextPageRequest,
  loadPreviousPageNoPricesRequest,
  loadPreviousPageRequest as loadPreviousSupplierProductsPageRequest,
  loadProductsRequest
} from "redux/reducers/supplier/products";
import {
  claimCompanyFromExternalOrderRequest,
  claimCompanyFromExternalOrderSuccess,
  fetchUsersCompaniesRequest,
  fetchUsersForCompanyRequest,
  loadCompanyProfileRequest,
  loadTenancyIdRequest,
  loadTenancyIdSuccess,
  setCompanyId,
  updateLatestCompanyRequest
} from "redux/reducers/tenancy";
import { createUserRequest, loadUserProfileRequest } from "redux/reducers/userProfile";
import termsConditions from "redux/sagas/termsConditions";
import claimCompanyFromExternalOrder from "redux/tasks/claimCompanyFromExternalOrder";
import createUser from "redux/tasks/createUser";
import fetchTenancyId from "redux/tasks/Directory/fetchTenancyId";
import fetchUsersCompanies from "redux/tasks/Directory/fetchUsersCompanies";
import fetchUsersForCompany from "redux/tasks/Directory/fetchUsersForCompany";
import updateLatestCompany from "redux/tasks/Directory/updateLatestCompany";
import fetchCompanyProfile from "redux/tasks/fetchCompanyProfile";
import fetchNavigationData from "redux/tasks/fetchNavigationData";
import hideModals from "redux/tasks/hideModals";
import loadNextPageV2 from "redux/tasks/loadNextPageV2";
import loadPreviousPage from "redux/tasks/loadPreviousPage";
import loadUserProfile from "redux/tasks/loadUserProfile";
import fetchBuyerById from "redux/tasks/Supplier/fetchBuyerById";
import fetchCustomers from "redux/tasks/Supplier/fetchCustomers";
import fetchProducts from "redux/tasks/Supplier/fetchProducts";
import updateBuyerById from "redux/tasks/Supplier/updateBuyerById";
import uploadOrderAttachment from "redux/tasks/uploadOrderAttachment";

import { deletePromptRequest } from "./actions/deletePrompt";
import {
  createCompanyInviteRequest,
  fetchCompanyInviteQrCodeRequest,
  fetchCompanyProfileByIdRequest
} from "./reducers/company";
import { fetchAddressSuggestionsRequest } from "./reducers/geography";
import {
  deregisterDevicePushNotificationRequest,
  registerDevicePushNotificationRequest
} from "./reducers/pushNotifications";
import createCompanyInvite from "./sagas/createCompanyInvite";
import fetchCompanyInviteQrCode from "./sagas/fetchCompanyInviteQrCode";
import fetchCompanyProfileById from "./sagas/fetchCompanyProfileById";
import fetchPaymentSettingsMetadata from "./sagas/supplier/fetchPaymentSettingsMetadata";
import deletePrompt from "./tasks/deletePrompt";
import deregisterDevicePushNotification from "./tasks/deregisterDevicePushNotification";
import fetchAddressSuggestions from "./tasks/fetchAddressSuggestions";
import registerDevicePushNotification from "./tasks/registerDevicePushNotification";

function* rootSaga(): Generator {
  yield all([
    takeLatest(createUserRequest, createUser),
    takeLatest(loadUserProfileRequest, loadUserProfile),
    takeLatest(registerDevicePushNotificationRequest, registerDevicePushNotification),
    takeLatest(deregisterDevicePushNotificationRequest, deregisterDevicePushNotification),
    takeLatest(loadTenancyIdRequest, fetchTenancyId),
    takeLatest(
      [
        loadTenancyIdSuccess,
        setCompanyId,
        claimCompanyFromExternalOrderSuccess,
        fetchNavigationDataRequest,
        fetchNavigationDataSilentlyRequest
      ],
      fetchNavigationData
    ),
    takeLatest([claimCompanyFromExternalOrderSuccess, loadCompanyProfileRequest], fetchCompanyProfile),
    takeLatest([fetchUsersCompaniesRequest], fetchUsersCompanies),
    takeLatest(fetchCustomersRequest, fetchCustomers),
    takeLatest(loadProductsRequest, fetchProducts),
    takeLatest([updateLatestCompanyRequest, loadTenancyIdSuccess, setCompanyId], updateLatestCompany),
    takeLatest(fetchBuyerByIdRequest, fetchBuyerById),
    takeLatest(updateBuyerByIdRequest, updateBuyerById),

    takeLatest(fetchUsersForCompanyRequest, fetchUsersForCompany),
    takeLatest(claimCompanyFromExternalOrderRequest, claimCompanyFromExternalOrder),
    takeLatest(createCompanyInviteRequest, createCompanyInvite),
    takeLatest(fetchCompanyProfileByIdRequest, fetchCompanyProfileById),
    takeLatest(fetchCompanyInviteQrCodeRequest, fetchCompanyInviteQrCode),
    takeLatest(loadPaymentSettingsMetadataRequest, fetchPaymentSettingsMetadata),

    takeEvery([loadPreviousSupplierProductsPageRequest, loadPreviousPageNoPricesRequest], loadPreviousPage),
    takeEvery(
      [
        loadNextPageNoPricesRequest,
        supplierProductsLoadNextPageRequest,
        customersLoadNextPageRequest,
        customersLoadPreviousPageRequest,
        loadNextMatchingProductsPage
      ],
      loadNextPageV2
    ),

    takeLatest(fetchAllPriceListsRequest, fetchAllPriceLists),
    takeLatest(hideModalsRequest, hideModals),
    takeLatest(deletePromptRequest, deletePrompt),
    takeEvery(uploadOrderAttachmentRequest, uploadOrderAttachment),
    takeLatest(fetchAddressSuggestionsRequest, fetchAddressSuggestions)
  ]);

  yield termsConditions();
}

export default rootSaga;
