import { Checkbox, FormControlLabel, Typography, List, ListItem } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import { ModalToHide } from "types/api/generated/directory-internal";
import { IncomingOrderProvider } from "types/api/generated/supplier-internal";
import useDontShowAgain from "utils/hooks/useDontShowAgain";

import strings from "./EditOrderProviderWarningModal.strings.json";

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: "480px"
  },
  syncedTable: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    },
    "& li": {
      padding: 0,
      marginTop: theme.spacing(1),
      "&::before": {
        marginRight: theme.spacing(1),
        fontWeight: "bold"
      }
    }
  },
  syncedColumn: {
    "& li::before": {
      content: `"✓"`,
      color: theme.palette.success.main
    }
  },
  notSyncedColumn: {
    "& li::before": {
      content: `"✗"`,
      color: theme.palette.error.main
    }
  },
  syncedTableHeading: {
    fontWeight: "bold"
  },
  otherAppsDisclaimer: {
    marginBottom: theme.spacing(2)
  }
}));

export interface EditOrderProviderWarningModalProps extends ModalProps {
  onConfirm: () => void;
  onClose: () => void;
  orderProvider?: IncomingOrderProvider | null;
}

/**
 * Modal for warning users that certain order changes can't be synced to the Foodstuffs exchange.
 */
const EditOrderProviderWarningModal: FunctionComponent<EditOrderProviderWarningModalProps> = ({
  open,
  onClose,
  onConfirm,
  orderProvider,
  ...modalProps
}) => {
  const { classes, cx } = useStyles();
  const messages = useMessages(strings);
  const modalToHide =
    orderProvider === IncomingOrderProvider.Foodstuffs
      ? ModalToHide.HideFoodstuffsOrderEditWarningModal
      : ModalToHide.HideWoolworthsOrderEditWarningModal;
  const { actionWithHideModalRequest, dontShowAgain, updateDontShowAgain } = useDontShowAgain(modalToHide, onConfirm);

  return (
    <Modal
      className={cx(classes.root)}
      open={open}
      title={<FormattedMessage id={messages.TITLE} />}
      onClose={onClose}
      actions={
        <Button color="primary" variant="contained" onClick={actionWithHideModalRequest}>
          <FormattedMessage id={messages.CONFIRM_BUTTON} />
        </Button>
      }
      {...modalProps}
      data-testid="edit-order-provider-warning-modal"
    >
      <Typography>
        <FormattedMessage id={messages.DESCRIPTION} values={{ provider: orderProvider }} />
      </Typography>

      <div className={cx(classes.syncedTable)}>
        <section className={cx(classes.syncedColumn)}>
          <Typography component="h3" className={cx(classes.syncedTableHeading)}>
            <FormattedMessage id={messages.SYNCED_HEADING} values={{ provider: orderProvider }} />
          </Typography>

          <List disablePadding>
            <ListItem>
              <FormattedMessage id={messages.DECREASED_QUANTITIES_ITEM} />
            </ListItem>
            <ListItem>
              <FormattedMessage id={messages.CANCELLED_ORDERS_ITEM} />
            </ListItem>
          </List>
        </section>

        <section className={cx(classes.notSyncedColumn)}>
          <Typography component="h3" className={cx(classes.syncedTableHeading)}>
            <FormattedMessage id={messages.NOT_SYNCED_HEADING} values={{ provider: orderProvider }} />
          </Typography>

          <List disablePadding>
            <ListItem>
              <FormattedMessage id={messages.INCREASED_QUANTITIES_ITEM} />
            </ListItem>
            <ListItem>
              <FormattedMessage id={messages.NEW_PRODUCTS_ADDED_ITEM} />
            </ListItem>
            <ListItem>
              <FormattedMessage id={messages.UNIT_PRICE_CHANGES_ITEM} />
            </ListItem>
          </List>
        </section>
      </div>

      <Typography className={cx(classes.otherAppsDisclaimer)}>
        {orderProvider === IncomingOrderProvider.Woolworths ? (
          <FormattedMessage id={messages.WOOLWORTHS_DISCLAIMER} />
        ) : (
          <FormattedMessage id={messages.OTHER_APPS_DISCLAIMER} />
        )}
      </Typography>

      <FormControlLabel
        control={
          <Checkbox checked={dontShowAgain} color="primary" name="dont-show-again" onChange={updateDontShowAgain} />
        }
        label={<FormattedMessage id={messages.SKIP_OPTION_LABEL} />}
      />
    </Modal>
  );
};

export default EditOrderProviderWarningModal;
