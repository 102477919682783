import { useCallback, useEffect, useMemo } from "react";

import useTypedUrlParams from "utils/hooks/useTypedUrlParams";

export const SELECTEDTAB_URL_PARAM = "selectedTab";

type UpdateTabCallback<T> = (event: any, newTab?: T) => void;
type UseTabsResult<T> = [selectedTab: T, updateTab: UpdateTabCallback<T>];

/**
 * Hook for working with a tabbed UI.  Returns the currently-selected tab, and a
 * method to handle tab changes.
 *
 * @param defaultTab
 *   The default tab that should be used if no tab has been selected yet.
 * @return
 *   An array with 2 items: the selected tab, and a tab change handler that
 *   should be used as the `onChange` function of a `<TabList>` component.
 */
export default function useTabs<T = string>(defaultTab?: T): UseTabsResult<T> {
  const [selectedTab, setSelectedTab] = useTypedUrlParams<T>(SELECTEDTAB_URL_PARAM);

  const updateTab: UpdateTabCallback<T> = useCallback(
    (_event, newTab) => {
      if (newTab) {
        setSelectedTab(newTab);
      }
    },
    [setSelectedTab]
  );

  useEffect(() => {
    if (!selectedTab && defaultTab) {
      setSelectedTab(defaultTab);
    }
  }, [defaultTab, selectedTab, setSelectedTab]);

  return useMemo(() => [selectedTab ?? defaultTab ?? ("" as T), updateTab], [defaultTab, selectedTab, updateTab]);
}
