import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";

import HelpIcon from "media/help.svg?react";
import { useAnalytics } from "utils/analytics";

import strings from "./HelpButton.strings.json";

interface Props {
  className?: string;
  notificationsCount?: number;
}

/**
 * The Intercom/help button in the upper-right corner of most of our headers.
 */
export default function HelpButton({ className, notificationsCount = 0 }: Props): JSX.Element {
  const { formatter } = useMessageFormatter();
  const analytics = useAnalytics();

  return (
    <IconButton
      className={className}
      id="customIntercomLauncher"
      aria-label={formatter.format(strings.HELP_BUTTON)}
      size="large"
      onClick={() => analytics.track("App bar help button clicked")}
    >
      <Badge
        color="secondary"
        badgeContent={notificationsCount}
        variant="dot"
        overlap="rectangular"
        data-testid="intercom-badge"
      >
        <HelpIcon />
      </Badge>
    </IconButton>
  );
}
