import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FunctionComponent, HTMLProps, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import CircleIcon from "components/CircleIcon";

interface StyleParams {
  disableMargin?: boolean;
}

const useStyles = makeStyles<StyleParams>()((theme, params) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: params.disableMargin ? undefined : theme.spacing(3),
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.grey[600],
    textAlign: "center",
    borderRadius: "12px"
  },
  icon: {
    marginBottom: theme.spacing(2)
  },
  content: {
    maxWidth: theme.spacing(50),
    margin: "auto"
  }
}));

export interface EmptyStateBoxProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  icon?: ReactNode;
  disableMargin?: boolean;
}

const EmptyStateBox: FunctionComponent<EmptyStateBoxProps> = ({
  children,
  icon,
  className,
  disableMargin,
  ...other
}) => {
  const { classes, cx } = useStyles({ disableMargin });
  const theme = useTheme();

  return (
    <div className={cx(classes.root, className)} data-testid="empty-state" {...other}>
      {icon && (
        <CircleIcon className={classes.icon} color={theme.palette.text.secondary}>
          {icon}
        </CircleIcon>
      )}
      <Typography component="div" className={classes.content}>
        {children}
      </Typography>
    </div>
  );
};

export default EmptyStateBox;
