import { retry } from "@ultraq/promise-utils";
import React, { ComponentType, LazyExoticComponent } from "react";

import { isError } from "utils/Errors";

import { MAX_ATTEMPTS, retryAfterMs } from "./fetch";
import { waitForNetworkConnectivity } from "./offlineHandler";

type ImportResult = {
  default: ComponentType<any>;
};

function isNetworkLoadError(error: unknown): boolean {
  // SystemJS throws an error 3 for network errors
  // https://github.com/systemjs/systemjs/blob/main/docs/errors.md#3
  return (
    isError(error) &&
    error.message.includes("SystemJS Error#3") &&
    // Don't retry network errors if SystemJS isn't loaded
    !!window.System
  );
}

async function loadComponent(importCallback: () => Promise<ImportResult>): Promise<ImportResult> {
  try {
    return await retry(importCallback, (_response, error, attempts) => {
      // If the module throws an error on initialisation, don't retry it and allow the error to be thrown normally.
      if (attempts < MAX_ATTEMPTS && isNetworkLoadError(error)) {
        return retryAfterMs(attempts);
      }
      return false;
    });
  } catch (error) {
    // If the bundle fails to download, wait for network connectivity and then retry the download.
    // Allow all other errors to be thrown normally.
    if (isNetworkLoadError(error)) {
      await waitForNetworkConnectivity();
      return loadComponent(importCallback);
    }
    throw error;
  }
}

/**
 * Lazy loads a component with retries for network errors and offline handling.
 *
 * @param importCallback
 *   Callback containing an import() statement for a component
 */
export default function lazyComponent(
  importCallback: () => Promise<ImportResult>
): LazyExoticComponent<ComponentType<any>> {
  return React.lazy(() => loadComponent(importCallback));
}
