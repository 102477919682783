import "core-js/actual/array/flat";
import "core-js/actual/object/from-entries";
import "./globalShim";
import { GlobalStyles } from "@mui/material";
import { createRoot } from "react-dom/client";

import AppProviders from "AppProviders";
import { BUILD_TIME_ISO, BUILD_TIMESTAMP, COMMIT_HASH } from "config";
import ThirdPartyProviders from "ThirdPartyProviders";
import { logger } from "utils/Datadog";

import App from "./App";

// Remove failed dynamic imports from the SystemJS cache so that we can retry the imports
// (in the case of network issues)
if (window.System) {
  window.System.constructor.prototype.onload = (
    error: unknown,
    id: string,
    _deps: string[] | undefined,
    _isErrorSource: boolean
  ): void => {
    if (window.System && error) {
      window.System.delete(id);
    }
  };
}

logger.info("Environment", {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.REACT_APP_ENV,
  COMMIT_HASH,
  APPLICATION_DOMAIN: process.env.REACT_APP_APPLICATION_DOMAIN,
  BUILD_TIME_ISO,
  BUILD_TIMESTAMP
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <ThirdPartyProviders>
    <AppProviders>
      <GlobalStyles
        styles={{
          "#cb-container": {
            // So that the Chargebee iframe won't go over the notch on mobile
            inset: "env(safe-area-inset-top) 0 0 !important"
          }
        }}
      />
      <App />
    </AppProviders>
  </ThirdPartyProviders>
);
