import {
  fetchCompanyInviteQrCodeFailure,
  FetchCompanyInviteQrCodeRequest,
  fetchCompanyInviteQrCodeSuccess
} from "redux/reducers/company";
import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

export default createSagaNext<FetchCompanyInviteQrCodeRequest>(
  (auth, tenancyId, { id }) => {
    return fetchDataFromApiWrapper(
      auth,
      `${process.env.REACT_APP_API_URL}/directory/internal/company-claim/qr/${id}`,
      tenancyId
    );
  },
  {
    successAction: fetchCompanyInviteQrCodeSuccess,
    failureAction: fetchCompanyInviteQrCodeFailure
  }
);
