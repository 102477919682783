import { createSlice } from "@reduxjs/toolkit";

import { fetchNavigationDataSuccess } from "redux/reducers/navigationData";
import { GettingStartedDetails } from "types/api/generated/directory-internal";

const initialState: GettingStartedDetails = {
  actions: [],
  isCompleted: true
};

/**
 * Reducer for the Buyer Getting Started slice.  This only holds the initial
 * data from the navigation data endpoint as that is still done using redux
 * saga.  After that, it's all react query.  This should be removable once nav
 * data becomes react query too.
 */
const gettingStartedSlice = createSlice({
  name: "buyerGettingStarted",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNavigationDataSuccess, (state, { payload: { buyerGettingStarted } }) => ({
      ...state,
      ...buyerGettingStarted
    }));
  }
});

export default gettingStartedSlice.reducer;
