import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  addGlobalNav as addGlobalNavAction,
  hideGlobalNav as hideGlobalNavAction,
  removeGlobalNav as removeGlobalNavAction,
  showGlobalNav as showGlobalNavAction
} from "redux/reducers/app";

import useAppSelector from "./useAppSelector";

interface GlobalNavResult {
  addGlobalNav: () => void;
  globalNavRendered: boolean;
  globalNavVisible: boolean;
  hideGlobalNav: () => void;
  removeGlobalNav: () => void;
  showGlobalNav: () => void;
}

/**
 * React hook for working with the global nav.
 *
 * @param displayNav
 *   Controls whether or not the global nav could potentially be shown on the
 *   page.  Some pages don't include navigation, like the new order flow, so
 *   setting this to `false` can be used to hide the nav even if other criteria
 *   would normally have it available.
 */
export default function useGlobalNav(displayNav?: boolean): GlobalNavResult {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const { globalNavRendered, globalNavVisible } = useAppSelector(state => state.app);

  const addGlobalNav = useCallback(() => {
    dispatch(addGlobalNavAction());
  }, [dispatch]);

  const removeGlobalNav = useCallback(() => {
    dispatch(removeGlobalNavAction());
  }, [dispatch]);

  const showGlobalNav = useCallback(() => {
    dispatch(showGlobalNavAction());
  }, [dispatch]);

  const hideGlobalNav = useCallback(() => {
    dispatch(hideGlobalNavAction());
  }, [dispatch]);

  // The global nav is always rendered on mobile so it can animate sliding
  // in/out, but it is optionally rendered on desktop as it is either always
  // present or always gone.
  const shouldRenderGlobalNav = !isLgUp || (isLgUp && displayNav);
  const shouldShowGlobalNav = shouldRenderGlobalNav && ((!isLgUp && globalNavVisible) || isLgUp);

  useEffect(() => {
    if (displayNav !== undefined) {
      // Update global nav being rendered
      if (shouldRenderGlobalNav && !globalNavRendered) {
        addGlobalNav();
      } else if (!shouldRenderGlobalNav && globalNavRendered) {
        removeGlobalNav();
      }

      // Update global nav being shown
      if (shouldShowGlobalNav && !globalNavVisible) {
        showGlobalNav();
      } else if (!shouldShowGlobalNav && globalNavVisible) {
        hideGlobalNav();
      }
    }
  }, [
    addGlobalNav,
    displayNav,
    globalNavRendered,
    globalNavVisible,
    hideGlobalNav,
    removeGlobalNav,
    showGlobalNav,
    shouldRenderGlobalNav,
    shouldShowGlobalNav
  ]);

  return useMemo(
    () => ({
      addGlobalNav,
      globalNavRendered,
      globalNavVisible,
      hideGlobalNav,
      removeGlobalNav,
      showGlobalNav
    }),
    [addGlobalNav, globalNavRendered, globalNavVisible, hideGlobalNav, removeGlobalNav, showGlobalNav]
  );
}
