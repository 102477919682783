import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import LoadingScreen from "pages/LoadingScreen";
import PageNotFound from "pages/PageNotFound";
import { loadTenancyIdRequest } from "redux/reducers/tenancy";
import { PlansPricing } from "routes/Routes";
import { logger } from "utils/Datadog";
import { trackError } from "utils/Errors";
import useAppSelector from "utils/hooks/useAppSelector";
import { TENANCY_NONE_FOUND } from "utils/Tenancy";

/**
 * The tenancyId is unknown when coming from the Terms and Conditions page on the marketing website,
 * so we intercept the external link here and insert the tenancyId.
 */
const RedirectToPlansPricing: FunctionComponent = () => {
  const dispatch = useDispatch();
  const tenancyId = useAppSelector(state => state.tenancy.companyId);
  const history = useHistory();
  const [show404, setShow404] = useState(false);

  useEffect(() => {
    if (tenancyId) {
      if (tenancyId !== TENANCY_NONE_FOUND) {
        history.push(PlansPricing.toUrl({ tenancyId }));
      } else {
        logger.warn(
          "User doesn't have last company information to redirect to their plans/pricing.  Showing them 404 content instead."
        );
        setShow404(true);
      }
    }
  }, [history, tenancyId]);

  useMount(() => {
    dispatch(
      loadTenancyIdRequest({
        failureCallback: error => trackError(error)
      })
    );
  });

  return show404 ? <PageNotFound /> : <LoadingScreen />;
};

export default RedirectToPlansPricing;
