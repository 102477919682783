import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { makeStyles } from "tss-react/mui";

import DropDownMenu from "components/DropDownMenu";
import useMessages from "i18n/hooks/useMessages";
import { show, showArticle } from "utils/Intercom";

import strings from "./AccountDropDownMenu.strings.json";

const useStyles = makeStyles()(theme => ({
  accountIcon: {
    color: theme.palette.common.white
  },
  menuItem: {
    padding: theme.spacing(2, 3)
  },
  menuItemIcon: {
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
    width: theme.spacing(5)
  }
}));

/**
 * The drop-down menu in the upper corner of some signup pages.
 */
export default function AccountDropDownMenu(): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { formatter } = useMessageFormatter();
  const { logout, user } = useAuth0();

  return (
    <DropDownMenu
      id="account-dropdown"
      trigger={triggerProps => (
        <IconButton
          aria-label={formatter.format(messages.ACCOUNTMENU_LABEL)}
          className={cx(classes.accountIcon)}
          {...triggerProps}
          size="large"
        >
          <AccountCircleIcon />
        </IconButton>
      )}
    >
      <MenuItem disabled className={cx(classes.menuItem)}>
        <AccountCircleIcon color="disabled" className={cx(classes.menuItemIcon)} />
        {user?.email}
      </MenuItem>
      <Divider />
      <MenuItem onClick={show} className={cx(classes.menuItem)}>
        <FormattedMessage id={messages.ACCOUNTMENU_CHAT} />
      </MenuItem>
      <MenuItem onClick={() => showArticle(5395941)} className={cx(classes.menuItem)}>
        <FormattedMessage id={messages.ACCOUNTMENU_HELP} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => logout()} className={cx(classes.menuItem)}>
        <FormattedMessage id={messages.ACCOUNTMENU_LOGOUT} />
      </MenuItem>
    </DropDownMenu>
  );
}
