import BusinessIcon from "@mui/icons-material/Business";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, ListItem, ListItemButton, ListItemText, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { JSX, useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import IntegrationIcon from "components/IntegrationIcon/IntegrationIcon";
import { useIntegrationName } from "components/IntegrationName/IntegrationName";
import IntercomHelpArticle from "components/IntercomHelpArticle";
import List from "components/List";
import LoadingDots from "components/LoadingDots";
import Modal from "components/Modal";
import ModalIconAndTitle from "components/Modal/ModalIconAndTitle";
import SearchBox from "components/SearchBox";
import useMessages from "i18n/hooks/useMessages";
import UpstockLogo from "media/logo_mark.svg?react";
import { useApiJob } from "services/apiJobs";
import { ProviderFeatureAccess } from "types/api/generated/directory-internal";
import { ProviderOffering } from "types/api/generated/global-internal";
import useDebounceValue from "utils/hooks/useDebounceValue";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";
import useHasProviderPermissions from "utils/hooks/useHasProviderPermissions";
import useProviderConnection from "utils/hooks/useProviderConnection";

import strings from "./ConnectProviderContactModal.strings.json";
import useLinkProviderContact from "./queries/useLinkProviderContact";
import useProviderContacts, { providerContactsKey } from "./queries/useProviderContacts";

const ADD_CONTACT_ID = "ADD_CONTACT";

const useStyles = makeStyles()(theme => ({
  modal: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "480px",
      // Allow the modal to become vertically small with margins, but also set a max height
      maxHeight: `min(${theme.spacing(99)}, calc(100vh - ${theme.spacing(6)}))`,
      width: "450px"
    }
  },
  modalContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column"
  },
  searchBox: {
    display: "flex",
    margin: theme.spacing(0, 3),
    width: "auto"
  },
  message: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2, 3),
    textAlign: "center"
  },
  searchIcon: {
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    borderRadius: "50%",
    color: theme.palette.grey[600],
    display: "flex",
    height: "48px",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    width: "48px"
  },
  list: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    marginTop: theme.spacing(3)
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  modalActions: {
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  helpButton: {
    marginLeft: theme.spacing(1),
    marginRight: "auto"
  },
  syncingIcons: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "center",
    margin: theme.spacing(3, 0, 2)
  },
  syncingIcon: {
    height: "48px",
    width: "48px"
  }
}));

const ListLoadingPlaceholder = (
  <>
    <ListItem divider>
      <ListItemText primary={<Skeleton width="60%" />} secondary={<Skeleton width="40%" />} />
    </ListItem>
    <ListItem divider>
      <ListItemText primary={<Skeleton width="60%" />} secondary={<Skeleton width="40%" />} />
    </ListItem>
  </>
);

export interface ConnectProviderContactModalProps {
  /**
   * ID of the buyer.
   */
  buyerId: string;

  companyName: string;

  /**
   * Controls whether the dialog is open or closed.
   */
  open: boolean;

  /**
   * Called when the dialog is closed or cancelled.
   */
  onClose?: () => void;

  /**
   * Called when the continue button is clicked and gets passed the provider
   * contact ID.
   */
  onContinue?: (providerContactId?: string | false | null) => void;

  /**
   * Whether the dialog should handle contact linking API call.
   */
  shouldLinkContact?: boolean;
}

/**
 * Displays a dialog that allows a supplier to search the contacts in their
 * linked provider.
 */
export default function ConnectProviderContactModal({
  open,
  buyerId,
  companyName,
  shouldLinkContact = true,
  onClose = () => {},
  onContinue = () => {}
}: ConnectProviderContactModalProps): JSX.Element {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const messages = useMessages(strings);
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);
  const { showErrorSnackbar } = useErrorSnackbar();
  const { formatter } = useMessageFormatter();
  const [contactProvider] = useProviderConnection(null, ProviderOffering.Contacts);
  const contactProviderName = useIntegrationName(contactProvider);
  const canCreateCustomers = useHasProviderPermissions(ProviderOffering.Contacts, ProviderFeatureAccess.Create);
  const canSyncCustomers = useHasProviderPermissions(ProviderOffering.Contacts, ProviderFeatureAccess.Sync);
  const [isAddContactHidden, setIsAddContactHidden] = useState(false);
  const isAddContactSelected = selectedContactId === ADD_CONTACT_ID;
  const [hasDoneInitialMatchOnOpen, setHasDoneInitialMatchOnOpen] = useState(false);

  // Provider contact search and results
  const [searchTerm, setSearchTerm] = useState(companyName);
  const debouncedSearchTerm = useDebounceValue(searchTerm);
  const {
    data: pagedProviderContacts,
    error: providerContactsError,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingContacts,
    isSuccess: contactsLoaded
  } = useProviderContacts({
    search: debouncedSearchTerm,
    enabled: open && !!debouncedSearchTerm
  });
  const providerContacts = useMemo(
    () => pagedProviderContacts?.pages.flatMap(page => page.data) ?? [],
    [pagedProviderContacts?.pages]
  );

  useEffect(() => {
    if (providerContactsError) {
      showErrorSnackbar(providerContactsError, <FormattedMessage id={messages.FETCH_CONTACTS_FAILURE} />);
    }
  }, [messages, providerContactsError, showErrorSnackbar]);

  // Sync customers from provider
  const { start: startProviderContactsSync, isLoading: isSyncingProviderContacts } = useApiJob(
    "/supplier/internal/provider/contacts/sync"
  );
  const queryClient = useQueryClient();
  const isSyncingAndRefetching = isSyncingProviderContacts || isLoadingContacts;

  const syncProviderContacts = useCallback(() => {
    startProviderContactsSync(undefined, {
      onSuccess() {
        queryClient.invalidateQueries(providerContactsKey._def, {
          refetchPage: () => true
        });
      }
    });
  }, [queryClient, startProviderContactsSync]);

  const resetState = useCallback(() => {
    setSelectedContactId(null);
    setIsAddContactHidden(false);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setHasDoneInitialMatchOnOpen(false);
  }, [onClose]);

  const handleSearchChange = useCallback((search: string) => {
    setSearchTerm(search);
    setSelectedContactId(null);
    setIsAddContactHidden(false);
  }, []);

  const handleEverySearchChange = useCallback((search: string) => {
    setSearchTerm(search);
  }, []);

  // Provider contact linking
  const { mutate: linkProviderContact, isLoading: isLinkingContact } = useLinkProviderContact();

  const handleContinue = useCallback(
    (contactId: string | null): void => {
      if (shouldLinkContact) {
        linkProviderContact(
          {
            buyerId,
            providerContactId: contactId === ADD_CONTACT_ID ? null : contactId,
            createProviderContact: contactId === ADD_CONTACT_ID
          },
          {
            onSuccess() {
              onContinue();
            },
            onError(error) {
              showErrorSnackbar(
                error,
                <FormattedMessage id={messages.CONTACT_LINK_FAILURE} values={{ contactProviderName }} />
              );
            }
          }
        );
      } else {
        onContinue(contactId === ADD_CONTACT_ID ? false : contactId);
      }
    },
    [buyerId, contactProviderName, linkProviderContact, messages, onContinue, shouldLinkContact, showErrorSnackbar]
  );

  const handleAddNewContactOptionClick = useCallback(() => {
    setSelectedContactId(ADD_CONTACT_ID);
  }, []);

  // For the first time the modal is opened, do a search of the exact company
  // name and pick the contact if it's a 100% match.  Otherwise, clear the
  // search field in preparation for the user to do their own search.
  useEffect(() => {
    if (open && contactsLoaded && !hasDoneInitialMatchOnOpen) {
      const matchingContact = providerContacts.find(contact => contact.contactName === companyName);
      if (matchingContact) {
        setSelectedContactId(matchingContact.providerContactId);
        setIsAddContactHidden(true);
      } else if (providerContacts.length === 0) {
        setSearchTerm("");
      }
      setHasDoneInitialMatchOnOpen(true);
    }
  }, [companyName, hasDoneInitialMatchOnOpen, contactsLoaded, open, providerContacts]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      fullScreen={isXsDown}
      className={cx(classes.modal)}
      contentClassName={cx(classes.modalContent)}
      actionsClassName={cx(classes.modalActions)}
      title={
        <ModalIconAndTitle
          Icon={BusinessIcon}
          title={<FormattedMessage id={messages.TITLE} values={{ contactProviderName }} />}
        />
      }
      actions={
        <>
          <IntercomHelpArticle className={cx(classes.helpButton)} articleId={5225763} color="primary">
            <FormattedMessage id={messages.HELP_BUTTON} />
          </IntercomHelpArticle>
          <Button color="primary" onClick={handleClose}>
            <FormattedMessage id={messages.CANCEL_BUTTON} />
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleContinue(selectedContactId)}
            disabled={!selectedContactId || isLoadingContacts}
            showLoader={isLinkingContact}
          >
            <FormattedMessage id={messages.CONTINUE_BUTTON} />
          </Button>
        </>
      }
      TransitionProps={{
        onExited: resetState
      }}
    >
      <SearchBox
        className={cx(classes.searchBox)}
        placeholder={formatter.format(messages.SEARCH_CONTACTS_PLACEHOLDER, { contactProviderName })}
        searchOnMount={false}
        useUrlParam={false}
        onChange={handleSearchChange}
        onEveryChange={handleEverySearchChange}
        inputProps={{ autoFocus: true }}
        value={searchTerm}
      />
      {!searchTerm && (
        <div className={cx(classes.message)} data-testid="pre-search-message">
          <Typography component="p" color="textSecondary">
            <FormattedMessage
              id={canCreateCustomers ? messages.PRE_SEARCH_MESSAGE_ADD_CONTACTS : messages.PRE_SEARCH_MESSAGE}
              values={{
                contactProviderName,
                companyName,
                addContactButton: (children: string) => (
                  <Button displayAsLink onClick={() => handleContinue(ADD_CONTACT_ID)} disabled={isLinkingContact}>
                    {formatter.format(children, { contactProviderName })}
                  </Button>
                )
              }}
            />
          </Typography>
        </div>
      )}
      {searchTerm &&
        (!isLoadingContacts && !canCreateCustomers && providerContacts.length === 0 ? (
          isSyncingAndRefetching ? (
            <div className={cx(classes.message)}>
              <div className={classes.syncingIcons}>
                <IntegrationIcon source={contactProvider!} className={classes.syncingIcon} />
                <LoadingDots />
                <UpstockLogo className={classes.syncingIcon} />
              </div>
              <Typography color="textSecondary" paragraph>
                <FormattedMessage id={messages.SYNCING} values={{ contactProviderName }} />
              </Typography>
            </div>
          ) : (
            <div className={cx(classes.message)}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Typography color="textSecondary" paragraph>
                <FormattedMessage id={messages.NO_RESULTS} values={{ searchTerm }} />
              </Typography>
              {canSyncCustomers ? (
                <Typography color="textSecondary" paragraph>
                  <Button displayAsLink onClick={syncProviderContacts}>
                    <FormattedMessage id={messages.NO_RESULTS_SYNC} values={{ contactProviderName }} />
                  </Button>
                </Typography>
              ) : (
                <Typography color="textSecondary" paragraph>
                  <FormattedMessage id={messages.NO_RESULTS_CHECK} values={{ contactProviderName }} />
                </Typography>
              )}
            </div>
          )
        ) : (
          <div className={cx(classes.list)}>
            <Divider />
            <List
              loader={ListLoadingPlaceholder}
              isLoading={isLoadingContacts}
              disablePadding
              hasMore={hasNextPage}
              loadMoreCallback={fetchNextPage}
              isScrollable
            >
              {providerContacts.map(contact => {
                const isSelected = selectedContactId === contact.providerContactId;
                return (
                  <ListItemButton
                    component="li"
                    key={contact.providerContactId}
                    className={cx(classes.listItem)}
                    selected={isSelected}
                    divider
                    onClick={() => setSelectedContactId(contact.providerContactId)}
                  >
                    <ListItemText primary={contact.contactName} secondary={contact.emailAddress} />
                    {isSelected && <CheckIcon />}
                  </ListItemButton>
                );
              })}

              {!isLoadingContacts && canCreateCustomers && !isAddContactHidden && (
                <ListItemButton
                  component="li"
                  className={cx(classes.listItem)}
                  selected={isAddContactSelected}
                  divider
                  onClick={handleAddNewContactOptionClick}
                >
                  <ListItemText
                    primary={
                      <FormattedMessage id={messages.ADD_NEW_CONTACT} values={{ contactProviderName, companyName }} />
                    }
                  />
                  {isAddContactSelected && <CheckIcon />}
                </ListItemButton>
              )}
            </List>
          </div>
        ))}
    </Modal>
  );
}
