import MTextField, { TextFieldProps as MTextFieldProps } from "@mui/material/TextField";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FocusEventHandler, FunctionComponent, useCallback } from "react";
import { makeStyles } from "tss-react/mui";

import useMessages from "i18n/hooks/useMessages";
import calculateCharacterRemaining from "utils/calculateCharactersRemaining";

import strings from "./TextField.strings.json";

const useStyles = makeStyles()(() => ({
  charactersRemaining: {
    display: "block"
  }
}));

export type TextFieldWithoutFormikProps = MTextFieldProps & {
  characterLimit?: number;
  id: string;
  name: string;
  selectTextOnFocus?: boolean;
};

/**
 * Customizations to the Material UI `<TextField>` component, styled for the
 * defaults of our app.  All of the defaults can be overridden as all props are
 * spread over the original `<TextField>`.
 *
 * All inputs should be in a form of some kind, however some don't currently and
 * it will either take a bit of refactoring to add them or they should remain
 * the exception and not the norm.  So this text field variant exists for those
 * cases.
 */
const TextFieldWithoutFormik: FunctionComponent<TextFieldWithoutFormikProps> = ({
  characterLimit,
  helperText,
  id,
  label,
  multiline,
  name,
  selectTextOnFocus,
  value,
  variant = "filled",
  inputProps,
  ...rest
}) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const charactersRemaining = calculateCharacterRemaining(value, characterLimit);

  const handleOnFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      if (selectTextOnFocus && target.value) {
        target.setSelectionRange(0, target.value.length);
      }
    },
    [selectTextOnFocus]
  );

  return (
    <MTextField
      id={id}
      name={name}
      autoComplete="off"
      fullWidth
      label={label}
      hiddenLabel={!label}
      multiline={multiline}
      value={value}
      variant={variant}
      FormHelperTextProps={{
        // @ts-expect-error Data attributes aren't in this type for some reason
        "data-testid": `${name}-helper-text`
      }}
      inputProps={{
        "data-lpignore": "true",
        maxLength: characterLimit,
        ...inputProps
      }}
      helperText={
        helperText || characterLimit ? (
          <>
            {helperText}
            {characterLimit && (
              <FormattedMessage
                className={cx(classes.charactersRemaining)}
                id={messages.CHARACTERS}
                values={{ charactersRemaining }}
              />
            )}
          </>
        ) : undefined
      }
      onFocus={handleOnFocus}
      {...rest}
    />
  );
};

export default TextFieldWithoutFormik;
