import { ReactNode, useMemo } from "react";
import { useDispatch } from "react-redux";

import { showSnackbar, ShowSnackbarPayload } from "redux/reducers/snackbar";
import { trackError } from "utils/Errors";

interface ShowErrorSnackbarResult {
  showErrorSnackbar(error: any, message?: ReactNode, snackbarOptions?: ShowSnackbarPayload): void;
}

/**
 * Shows an error message to the user in a Snackbar and also logs the error to the console and Datadog.
 */
const useErrorSnackbar = (): ShowErrorSnackbarResult => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      showErrorSnackbar: (error, message, snackbarOptions) => {
        trackError(error);

        dispatch(
          showSnackbar({
            isError: true,
            message: message || error?.message || "An unknown error occurred",
            ...snackbarOptions
          })
        );
      }
    }),
    [dispatch]
  );
};

export default useErrorSnackbar;
