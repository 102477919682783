import { ReactNode } from "react";

/**
 * Returns whether or not the field should display an error.
 *
 * @return true` if an error is present and has been touched.
 */
export function hasError(touched?: boolean, error?: string): boolean {
  return !!(touched && error);
}

/**
 * Return error helper text for the field.  If no error is present then
 * `defaultText` is returned (defaults to some whitespace as this signals to
 * Material UI to leave space in the layout for helper text).
 *
 * @param [defaultText=""] Text to use as the default, when there are no errors.
 * @return Helper text from `error` if an error is present on the field (see {@link #hasError}), or the `defaultText`.
 */
export function getHelperText(touched?: boolean, error?: string, defaultText: ReactNode = ""): ReactNode | undefined {
  return hasError(touched, error) ? error : defaultText;
}
