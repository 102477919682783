import { useAuth0 } from "@auth0/auth0-react";
import { RedirectLoginResult } from "@auth0/auth0-spa-js";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { useMount } from "react-use";

import {
  AcceptInviteExternal,
  Auth0Callback,
  ConnectIdentityCallbackRoute,
  MobileWelcome,
  UnauthenticatedOrderExternal
} from "routes/Routes";
import { trackError } from "utils/Errors";
import { PARAM_FORWARD_PATH } from "utils/MobileUrlSearchParams";

export default function useDeepLinks(): void {
  const { isAuthenticated, handleRedirectCallback } = useAuth0();

  useMount(() => {
    if (Capacitor.isPluginAvailable("App")) {
      App.addListener("appUrlOpen", ({ url }) => {
        const { protocol, pathname, search, hash } = new URL(url);

        if (pathname.includes(Auth0Callback.path)) {
          if (search.includes("code=") && search.includes("state=")) {
            // Handle authentication callbacks from Auth0
            handleRedirectCallback(url).then((appState: RedirectLoginResult) => {
              if (appState) {
                Browser.close();
                window.location.href = appState.appState.returnTo || "/";
              }
            });
          } else {
            Browser.close(); // close in case of error (e.g., connection access cancelled)
          }
        } else if (pathname.includes(ConnectIdentityCallbackRoute.path)) {
          window.location.href = ConnectIdentityCallbackRoute.path + search + hash;
          Browser.close();
        } else if (protocol === "https:") {
          // Handle deeplinks from external apps/links
          if (
            isAuthenticated ||
            pathname.includes(UnauthenticatedOrderExternal.path.substr(0, 16)) || // subtr to avoid :supplierId
            pathname.includes(AcceptInviteExternal.path)
          ) {
            window.location.href = pathname + search + hash; // Re-assign over push() to avoid any past state
            Browser.close(); // Close if open (e.g., callback from Xero authentication)
          } else {
            const forwardPathQuery = encodeURIComponent(pathname + search + hash);
            window.location.href = `${MobileWelcome.toUrl()}?${PARAM_FORWARD_PATH}=${forwardPathQuery}`;
          }
        }
      });
    } else if (Capacitor.isNativePlatform()) {
      trackError(new Error("Capacitor App plugin was required but not available"));
    }
  });
}
