import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { SupplierInternal } from "types/api";

export type RequestBody = SupplierInternal.InternalProductsMatchCreate.RequestBody;
export type RequestQuery = SupplierInternal.InternalProductsMatchCreate.RequestQuery;
export type RequestParameters = SupplierInternal.InternalProductsMatchCreate.RequestParams;
export type ResponseBody = SupplierInternal.InternalProductsMatchCreate.ResponseBody;

type Request = {
  parameters: RequestParameters;
  body: RequestBody;
};

export const createProductMatch = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParameters>(
  parameters => ({
    method: "POST",
    path: `/supplier/internal/products/${parameters.productId}/${parameters.buyerId}/match`
  })
);

const useCreateProductMatch = (): UseMutationResult<ResponseBody, FetchV2Error, Request> => {
  const apiFetch = useApiFetch();

  return useMutation({
    mutationFn: async data => {
      const response = await apiFetch(createProductMatch(data.parameters, { data: data.body }));
      return response.data;
    }
  });
};

export default useCreateProductMatch;
