import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import { Dialog, DialogContent } from "components/Dialog";

const useStyles = makeStyles<{ aspectRatio: number }>()((theme, { aspectRatio }) => ({
  header: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: `calc(${theme.spacing(1)} + env(safe-area-inset-top))`, // fix for mobile devices with statusbar/notch
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playerContent: {
    padding: theme.spacing(0, 3, 3)
  },
  playerContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    // Match on both width and height to provide a better experience for phones in landscape orientation
    "@media (min-width:600px) and (min-height:600px)": {
      width: `calc(70vh * ${aspectRatio})`,
      maxWidth: "100%",
      height: "70vh"
    },
    "& iframe": {
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "100%"
    }
  }
}));

export interface VideoDialogProps {
  /** The ID of the Vimeo video. */
  vimeoVideoID: string;
  /** Controls whether the modal is open or closed. */
  open: boolean;
  /** Called when the modal is closed or cancelled. */
  onClose: () => void;
  /** Whether the video is in the landscape or portrait orientation. */
  videoOrientation?: "landscape" | "portrait";
}

/** Dialog for showing a Vimeo video to the user. */
const VideoDialog: FunctionComponent<VideoDialogProps> = ({
  vimeoVideoID,
  open,
  onClose,
  videoOrientation = "landscape"
}) => {
  const { classes, cx } = useStyles({
    aspectRatio: videoOrientation === "landscape" ? 16 / 9 : 9 / 16
  });
  // Match on both width and height to provide a better experience for phones in landscape orientation
  const fullScreenVideoPlayer = useMediaQuery("@media (max-width:599.95px), (max-height:599.95px)");

  return (
    <Dialog
      open={open}
      fullScreen={fullScreenVideoPlayer}
      maxWidth={false}
      onClose={onClose}
      data-testid="video-dialog"
    >
      <div className={cx(classes.header)}>
        <IconButton onClick={onClose} aria-label="Close" size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={cx(classes.playerContent)}>
        <div className={cx(classes.playerContainer)}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src={`https://player.vimeo.com/video/${vimeoVideoID}?autoplay=1&color=FD554A&title=0&byline=0&portrait=0`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            data-testid="video-dialog-iframe"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoDialog;
