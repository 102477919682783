import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ButtonGroup, alpha } from "@mui/material";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import Decimal from "decimal.js-light";
import { ChangeEventHandler, FunctionComponent, MouseEvent, useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import { logger } from "utils/Datadog";
import { isNullOrUndefined } from "utils/helpers";
import { hasNumericValue } from "utils/numbers";

import strings from "./NumberStepper.strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    boxShadow: "0px 0px 0px 8px white",
    background: "white"
  },
  step: {
    padding: theme.spacing(0.5),
    borderColor: alpha(theme.palette.grey[600], 0.3)
  },
  disabledStep: {
    background: theme.palette.grey["50"],
    color: theme.palette.grey["300"]
  },
  disabledInput: {
    color: theme.palette.grey["600"]
  },
  input: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: alpha(theme.palette.grey[600], 0.3)
  },
  inputEl: {
    textAlign: "center"
  }
}));

/**
 * Number of decimal places we apply to the rounding when the quantity is pushed
 * to the newOrder.
 */
const DECIMAL_PLACES = 4;

/**
 * Number of significant digits to use when formatting a number.
 */
export const PRECISION = 14;

/**
 * Checks the value passed is a value value which is less or equal to the max value
 */
const validateMaxValue = (newValue: Decimal, maxValue: number | null | undefined): boolean =>
  isNullOrUndefined(maxValue) || newValue.lessThanOrEqualTo(maxValue);

/**
 * Checks the value passed is a value value which is greater or equal to the min value
 */
const validateMinValue = (newValue: Decimal, minValue: number | null | undefined): boolean =>
  isNullOrUndefined(minValue) || newValue.greaterThanOrEqualTo(minValue);

/**
 * Checks the passed value is valid against the min and max values
 */
const isValueValid = (
  newValue: Decimal,
  minValue: number | null | undefined,
  maxValue: number | null | undefined
): boolean => {
  return validateMinValue(newValue, minValue) && validateMaxValue(newValue, maxValue);
};

export interface NumberStepperProps {
  className?: string;
  value?: number;
  onChange?: (newValue: number) => void;
  maxValue?: number;
  minValue?: number;
  hideInput?: boolean;
  inputProps?: InputBaseProps["inputProps"];
  autoFocus?: boolean;
}

/**
 * A controlling component to increase and decrease the value of the input
 * between the two buttons.  Commonly used for managing quantities on products.
 *
 * @param onChange Called with a Number representing what is in the stepper whenever it changes.
 */
const NumberStepper: FunctionComponent<NumberStepperProps> = ({
  className,
  value = 0,
  onChange = () => {},
  // Always set a max value to prevent Infinity errors from happening when someone keeps entering digits
  maxValue = Number.MAX_SAFE_INTEGER,
  minValue = Number.MIN_SAFE_INTEGER,
  inputProps,
  autoFocus = false,
  hideInput = false
}) => {
  const { classes, cx } = useStyles();
  const inputEl = useRef<HTMLInputElement>();
  const { formatter } = useMessageFormatter();
  const [stateValue, setStateValue] = useState(new Decimal(value).toSignificantDigits(PRECISION).toString());

  const stateValueNumber = Number(stateValue);

  const handleOnDecreaseChange = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    const newValue = new Decimal(stateValue).minus(1);
    if (validateMinValue(newValue, minValue)) {
      setStateValue(newValue.toSignificantDigits(PRECISION).toString());
      onChange(newValue.toNumber());
    }
  };

  const handleOnIncreaseChange = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    const newValue = new Decimal(stateValue).plus(1);
    if (validateMaxValue(newValue, maxValue)) {
      setStateValue(newValue.toSignificantDigits(PRECISION).toString());
      onChange(newValue.toNumber());
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }): void => {
    let currentValue = target.value;
    // Allow users to delete values without reporting a value change - that'll happen on blur instead
    if (currentValue === "") {
      setStateValue(currentValue);
      return;
    }

    // Manually handle the case someone type '.' instead of '0.' otherwise we get a NaN error.
    if (currentValue === ".") {
      currentValue = "0.";
    }

    try {
      const newValue = new Decimal(currentValue);
      if (isValueValid(newValue, minValue, maxValue)) {
        setStateValue(currentValue);
        onChange(newValue.toNumber());
      }
    } catch (error: any) {
      // Log to DataDog anything that could not be converted to a number instead of throwing an error
      logger.error(`NumberStepper error: ${error.message}`, { error }, error);
    }
  };

  const handleInputBlur = (): void => {
    const inputValue = inputEl.current?.value;
    const newValue = new Decimal(hasNumericValue(inputValue) ? inputValue : 0).toDecimalPlaces(DECIMAL_PLACES);
    setStateValue(newValue.toSignificantDigits(PRECISION).toString());
    onChange(newValue.toNumber());
  };

  useEffect(() => {
    autoFocus && inputEl.current?.focus();
  }, [autoFocus]);

  useEffect(() => {
    setStateValue(new Decimal(value).toSignificantDigits(PRECISION).toString());
  }, [value]);

  return (
    <ButtonGroup variant="outlined" color="primary" className={cx(classes.container, className)}>
      <Button
        onClick={handleOnDecreaseChange}
        className={cx(classes.step, { [`${classes.disabledStep}`]: stateValueNumber <= minValue })}
        title={formatter.format(strings.DECREASE_QUANTITY_BUTTON)}
        data-testid="numberstepper-decrement"
        disabled={stateValueNumber <= 0}
        variant="outlined"
        color="primary"
      >
        <RemoveIcon />
      </Button>
      {!hideInput && (
        <InputBase
          value={stateValue}
          className={cx(classes.input, { [`${classes.disabledInput}`]: stateValueNumber <= minValue })}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onClick={e => e.stopPropagation()}
          placeholder="0"
          inputRef={inputEl}
          inputProps={{
            "data-testid": "numberstepper-input",
            autoComplete: "off",
            autoCorrect: "off",
            autoCapitalize: "off",
            className: classes.inputEl,
            inputMode: "decimal",
            ...inputProps
          }}
        />
      )}
      <Button
        onClick={handleOnIncreaseChange}
        className={cx(classes.step, { [`${classes.disabledStep}`]: stateValueNumber >= maxValue })}
        title={formatter.format(strings.INCREASE_QUANTITY_BUTTON)}
        data-testid="numberstepper-increment"
        disabled={stateValueNumber >= maxValue}
        variant="outlined"
        color="primary"
      >
        <AddIcon />
      </Button>
    </ButtonGroup>
  );
};

export default NumberStepper;
