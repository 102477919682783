import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import React, { ReactElement } from "react";

import Tooltip from "components/Tooltip";
import useMessages from "i18n/hooks/useMessages";

import strings from "./PermissionTooltip.strings.json";

export interface PermissionTooltipProps {
  className?: string;
  hasPermission: boolean;
  isInline?: boolean;
  children: ReactElement;
}

const PermissionTooltip = React.forwardRef((props: PermissionTooltipProps, ref) => {
  const { children, className, hasPermission, isInline } = props;
  const messages = useMessages(strings);
  return (
    <Tooltip
      ref={ref}
      className={className}
      disabled={hasPermission}
      isInline={isInline}
      title={
        <>
          <Typography paragraph>
            <FormattedMessage id={messages.REQUEST_ACCESS} />
          </Typography>
          <FormattedMessage
            id={messages.LEARN_MORE}
            values={{
              learnMore: {
                articleId: 5252567
              }
            }}
          />
        </>
      }
    >
      {children}
    </Tooltip>
  );
});

export default PermissionTooltip;
