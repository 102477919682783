import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useLocation, useParams } from "react-router-dom";

import FakeFabButton from "components/Button/FakeFabButton";
import GlobalNavItem from "components/GlobalNav/GlobalNavItem";
import useCustomersStats from "features/suppliers/directory/queries/useCustomersStats";
import useSupplierGettingStarted from "features/suppliers/getting-started/useSupplierGettingStarted";
import useMessages from "i18n/hooks/useMessages";
import GettingStartedIcon from "media/icon-getting-started.svg?react";
import ReportsIcon from "media/icon-reports.svg?react";
import NewsfeedIcon from "media/newsfeed.svg?react";
import OrdersIcon from "media/orders.svg?react";
import PeopleIcon from "media/people-three.svg?react";
import ProductsIcon from "media/products_icon.svg?react";
import {
  CompanyRoot,
  SupplierCustomers,
  SupplierDashboard,
  SupplierGettingStarted,
  SupplierNewOrder,
  SupplierNewsfeed,
  SupplierOrdersGridLayout,
  SupplierPriceLists,
  SupplierProducts,
  SupplierReports,
  SupplierViewOrder,
  Users
} from "routes/Routes";

import strings from "./strings.json";

interface Props {
  handleNavClose?: () => void;
}

/**
 * Nav items for the supplier side of the app.
 */
const SupplierNav: FunctionComponent<Props> = ({ handleNavClose = () => {} }) => {
  const messages = useMessages(strings);
  const location = useLocation();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const { remainingActions } = useSupplierGettingStarted(false);
  const {
    data: { invitedCount }
  } = useCustomersStats();
  const hasGettingStartedActions = remainingActions.length > 0;

  return (
    <div data-testid="supplier-menu">
      <FakeFabButton
        to={SupplierNewOrder.toLocation({ tenancyId }, undefined, { referrer: location })}
        onClick={handleNavClose}
        sx={{
          height: "32px",
          padding: 2,
          margin: 2,
          boxShadow: "none !important"
        }}
      >
        <FormattedMessage id={messages.NEWORDER} />
      </FakeFabButton>
      {hasGettingStartedActions && (
        <GlobalNavItem
          to={SupplierGettingStarted.toUrl({ tenancyId })}
          icon={<GettingStartedIcon />}
          text={<FormattedMessage id={messages.GETTINGSTARTED} />}
          badgeCount={remainingActions.length}
          onClick={handleNavClose}
          trackingPropLink="Getting started"
          trackingPropNav="supplier"
        />
      )}
      <GlobalNavItem
        to={SupplierDashboard.toUrl({ tenancyId })}
        icon={<GridViewOutlinedIcon />}
        text={<FormattedMessage id={messages.DASHBOARD} />}
        onClick={handleNavClose}
        trackingPropLink="Dashboard"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierOrdersGridLayout.toUrl({ tenancyId })}
        icon={<OrdersIcon />}
        text={<FormattedMessage id={messages.ORDERS} />}
        // TODO: have the grid layout live on
        //       the same /orders URL so that we don't need to have this
        //       special matching code.
        isActive={(match, loc) => !!match || SupplierViewOrder.matches(loc.pathname)}
        onClick={handleNavClose}
        trackingPropLink="Orders"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierNewsfeed.toUrl({ tenancyId })}
        icon={<NewsfeedIcon />}
        text={<FormattedMessage id={messages.NEWSFEED} />}
        onClick={handleNavClose}
        trackingPropLink="Newsfeed"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierProducts.toUrl({ tenancyId })}
        icon={<ProductsIcon />}
        text={<FormattedMessage id={messages.PRODUCTS} />}
        onClick={handleNavClose}
        trackingPropLink="Products"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierPriceLists.toUrl({ tenancyId })}
        icon={<LocalOfferIcon />}
        text={<FormattedMessage id={messages.PRICELISTS} />}
        onClick={handleNavClose}
        trackingPropLink="PriceLists"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierCustomers.toUrl({ tenancyId })}
        icon={<ShoppingBasketOutlinedIcon />}
        text={<FormattedMessage id={messages.CUSTOMERS} />}
        badgeCount={invitedCount}
        onClick={handleNavClose}
        trackingPropLink="Customers"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={Users.toUrl({ tenancyId })}
        icon={<PeopleIcon />}
        text={<FormattedMessage id={messages.TEAM} />}
        onClick={handleNavClose}
        trackingPropLink="Team"
        trackingPropNav="supplier"
      />
      <GlobalNavItem
        to={SupplierReports.toUrl({ tenancyId })}
        icon={<ReportsIcon />}
        text={<FormattedMessage id={messages.REPORTS} />}
        onClick={handleNavClose}
        trackingPropLink="Reports"
        trackingPropNav="supplier"
      />
    </div>
  );
};

export default SupplierNav;
