import { SelectProps as MSelectProps } from "@mui/material/Select";
import { useField } from "formik";

import { getHelperText, hasError } from "utils/fields";

import SelectWithoutFormik, { SelectWithoutFormikProps } from "./SelectWithoutFormik";

export interface SelectProps<T> extends SelectWithoutFormikProps<T> {}

/**
 * Customizations to the Material UI `<Select>` component, styled for the
 * defaults of our app and Formik as the form library.  All of the defaults can
 * be overridden as all props are spread over the original `<Select>`.
 */
export default function Select<T>({
  helperText,
  id,
  name,
  onChange,
  error: errorProp,
  ...rest
}: SelectProps<T>): JSX.Element {
  const [field, meta] = useField(name);
  const error = hasError(meta.touched, meta.error);
  const helperTextNode = getHelperText(meta.touched, meta.error, helperText);

  const handleOnChange: MSelectProps<T>["onChange"] = (ev, child) => {
    onChange?.(ev, child);
    field.onChange(ev);
  };

  return (
    <SelectWithoutFormik
      id={id}
      name={name}
      error={error}
      helperText={(errorProp && helperText) || error || helperText ? helperTextNode : undefined}
      onChange={handleOnChange}
      {...rest}
    />
  );
}
