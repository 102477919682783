import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";
import { buildSearchString } from "utils/urls";

import {
  fetchPriceListCustomersFailure,
  FetchPriceListCustomersRequest,
  fetchPriceListCustomersSuccess
} from "../reducers/priceListCustomers";

/**
 * Call the API endpoint for retrieving a list of customers attached to a price
 * list.
 */
function fetchPriceListCustomers(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId?: string,
  name?: string,
  limit?: number
): Promise<Supplier.V1BuyersList.ResponseBody> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/buyers${buildSearchString({
      priceListId,
      name,
      limit
    })}`,
    tenancyId
  );
}

/**
 * Saga to retrieve the customers attached to a price list.
 */
export default createSaga(fetchPriceListCustomers, {
  prepareApiMethodParams: ({ limit, name, priceListId }: FetchPriceListCustomersRequest["payload"]) => [
    priceListId,
    name,
    limit
  ],
  successAction: fetchPriceListCustomersSuccess,
  prepareSuccessActionParams: (_action: FetchPriceListCustomersRequest, response) => [
    {
      ...response,
      overrideList: true
    }
  ],
  errorFailureAction: fetchPriceListCustomersFailure
});
