import { Link } from "@mui/material";
import { ReactNode } from "react";

import Button from "components/Button";
import { showNewMessage } from "utils/Intercom";

export const joinIfPresent = (parts: (string | undefined | null)[], separator: string = ", "): string => {
  const validParts = parts.filter(item => item !== null && item !== undefined && item.length > 0);
  return validParts.join(separator);
};

export const capitalize = (s: unknown): string => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

interface FindLinksResult {
  string: string;
  type: "text" | "link";
}

/**
 * This function breaks up text into a list of objects describing their parts as
 * either links or text.  This is so we can discern the 2 and create clickable
 * links from link sections.  eg:
 *
 * ```
 * [
 *   {
 *     string: "Check out this page on our website:",
 *     type: "text"
 *   },
 *   {
 *     string: "https://www.upstock.app/shipping-rates",
 *     type: "link"
 *   },
 *   {
 *     string: "for info on shipping rates",
 *     type: "text"
 *   }
 * ]
 * ```
 */
export function findLinks(string: string): FindLinksResult[] {
  if (!string) {
    return [];
  }
  const indexOfHttp = string.indexOf("http");
  if (indexOfHttp === 0) {
    // End a link string at an optional closing parenthesis, bracket or brace () , ] , } )
    // followed by an optional punctuation mark and either a space or the end of the string.
    const linkEnd = string.search(/[)\]}]?[.,\-?@!;:_'"*]?( |\n|$)/);
    const link = string.substring(0, linkEnd);
    const linkTail = string.substring(linkEnd);
    return [
      {
        string: link,
        type: "link"
      },
      ...findLinks(linkTail)
    ];
  }
  const textEnd = indexOfHttp >= 0 ? indexOfHttp : string.length;
  const text = string.substring(0, textEnd);
  const textTail = string.substring(textEnd);
  return [
    {
      string: text,
      type: "text"
    },
    ...findLinks(textTail)
  ];
}

/**
 * Return whether or not a string is falsey or only whitespace.
 */
export function isEmpty(string: string | undefined | null): boolean {
  return !string?.trim();
}

export function isNotEmpty(string: string | undefined | null): boolean {
  return !!string?.trim();
}

/**
 * Converts Markdown style links in a string to <Link> React elements.
 *
 * Also supports creating Intercom links using a special `intercom:` protocol.
 *
 * @example linkifyText("For tips check out [our guide](https://help.upstock.app/en/articles/5902700)")
 * @example linkifyText("For tips check out [our guide](intercom:Pre-populated message)")
 * @example linkifyText("For tips check out: https://help.upstock.app/en/articles/5902700")
 */
export function linkifyText(text: string, allowIntercom?: boolean): ReactNode {
  const regex =
    /\[(?<name>.*?)\]\((?<url>.*?)\)|(?<rawUrl>(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]))/gm;

  const textWithLinks: ReactNode[] = [];
  let previousIndex = 0;
  let match = regex.exec(text);

  while (match) {
    const url = match.groups?.url;
    const name = match.groups?.name;
    const rawUrl = match.groups?.rawUrl;

    textWithLinks.push(text.slice(previousIndex, match.index));

    // Don't allow javascript links
    if (url?.match(/^javascript:/)) {
      textWithLinks.push(match[0]);
    } else {
      const intercomMatch = url?.match(/^intercom:(?<message>.*?)$/);
      if (allowIntercom && intercomMatch) {
        textWithLinks.push(
          <Button displayAsLink onClick={() => showNewMessage(intercomMatch.groups?.message || "")}>
            {name}
          </Button>
        );
      } else if (rawUrl) {
        textWithLinks.push(
          <Link key={match.index} href={rawUrl} target="_blank" rel="noreferrer" component="a">
            {rawUrl}
          </Link>
        );
      } else {
        textWithLinks.push(
          <Link key={match.index} href={url} target="_blank" rel="noreferrer" component="a">
            {name}
          </Link>
        );
      }
    }

    previousIndex = regex.lastIndex;
    match = regex.exec(text);
  }

  if (previousIndex) {
    textWithLinks.push(text.slice(previousIndex));
    return textWithLinks;
  }

  return text;
}

export const trimMiddle = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }

  const ellipsis = "...";
  const partLength = Math.floor((maxLength - ellipsis.length) / 2);
  const start = text.substring(0, partLength);
  const end = text.substring(text.length - partLength);

  return start + ellipsis + end;
};
