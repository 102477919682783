import { findClosingBracket, TypeHandler } from "@ultraq/icu-message-formatter";

/**
 * A type handler for providing a fallback value within the strings file itself
 * when the first argument block passed to it results in `null` or `undefined`.
 *
 * The expected string for this handler looks something like:
 *
 * ```
 * {unused, fallback, {firstBlock} {fallbackBlock}}
 * ```
 *
 * So if `{firstBlock}` fails to provide a useful value, `{fallbackBlock}` is
 * processed instead.
 */
const fallbackTypeHandler: TypeHandler = (_, argumentString: string, __, values, process): string[] | string => {
  // Try the first block
  const afterFirstBlock = findClosingBracket(argumentString, 0) + 1;
  const result = process(argumentString.substring(0, afterFirstBlock), values);
  if (result.every(r => r != null)) {
    return result;
  }

  // Use the fallback block
  const startOfFallbackBlock = argumentString.indexOf("{", afterFirstBlock) + 1;
  return argumentString.substring(startOfFallbackBlock, findClosingBracket(argumentString, startOfFallbackBlock));
};

export default fallbackTypeHandler;
