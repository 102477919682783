import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { deleteDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";
import { RemoveCustomerRequest } from "types/api/generated/supplier";

/**
 * Delete request to the API of the list of customers to remove from a price
 * list.
 */
function deleteCustomersFromPriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string,
  customers: Supplier.V1PricelistsCustomersDelete.RequestBody
): Promise<Supplier.V1PricelistsCustomersDelete.ResponseBody> {
  return deleteDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/customers`,
    tenancyId,
    customers
  );
}

/**
 * Saga to add the list of customers to a price list.
 */
export default createSaga(deleteCustomersFromPriceList, {
  prepareApiMethodParams: ({ priceListId, customers }: { priceListId: string; customers: RemoveCustomerRequest[] }) => [
    priceListId,
    customers
  ]
});
