import { TypeHandler } from "@ultraq/icu-message-formatter";

import { formatDate } from "utils/Dates";

type DateAndOptions = {
  date?: Date | string | null;
  options?: Intl.DateTimeFormatOptions;
};

/**
 * Type handler for the `dateTime` type in ICU message strings.  This is just a
 * bridge between message formatter and native `Intl.DateTimeFormat` APIs.
 */
const dateTimeTypeHandler: TypeHandler = (
  { date, options }: DateAndOptions,
  _: string,
  locale: string
): string | null => {
  return date ? formatDate(date, options, locale) : null;
};

export default dateTimeTypeHandler;
