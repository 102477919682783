import {
  createCompanyInviteFailure,
  CreateCompanyInviteRequest,
  createCompanyInviteSuccess
} from "redux/reducers/company";
import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

export default createSagaNext<CreateCompanyInviteRequest>(
  (auth, _tenancyId, data) => {
    return postDataToApiWrapper(
      auth,
      `${process.env.REACT_APP_API_URL}/directory/internal/company-claim/${data.id}`,
      undefined,
      data
    );
  },
  {
    successAction: createCompanyInviteSuccess,
    failureAction: createCompanyInviteFailure
  }
);
