import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useInfiniteQuery, UseInfiniteQueryResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { SupplierInternal } from "types/api";

type RequestBody = SupplierInternal.InternalProviderContactsList.RequestBody;
type RequestQuery = SupplierInternal.InternalProviderContactsList.RequestQuery;
type ResponseBody = SupplierInternal.InternalProviderContactsList.ResponseBody;

type ProviderContactsOptions = RequestQuery & {
  enabled?: boolean;
};

export const providerContactsKey = createQueryKeys("providerContacts", {
  list: (searchParams?: RequestQuery) => [searchParams]
});

export const providerContactsOptions = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestQuery | undefined
>(searchParams => ({
  method: "GET",
  path: "/supplier/internal/provider/contacts",
  searchParams
}));

/**
 * React query hook to call `GET /supplier/internal/provider/contacts` for a
 * list of contacts from the supplier's contact provider.
 */
export default function useProviderContacts(
  options?: ProviderContactsOptions
): UseInfiniteQueryResult<ResponseBody, FetchV2Error> {
  const { enabled = true, ...searchParams } = options ?? {};
  const apiFetch = useApiFetch();

  return useInfiniteQuery({
    queryKey: providerContactsKey.list(searchParams).queryKey,
    async queryFn({ pageParam }) {
      const response = await apiFetch(
        pageParam ? { ...providerContactsOptions(undefined), path: pageParam } : providerContactsOptions(searchParams)
      );
      return response.data;
    },
    enabled,
    getNextPageParam: lastPage => lastPage.links.next,
    useErrorBoundary: false
  });
}
