import { Check as CheckIcon } from "@mui/icons-material";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  ListItemButton
} from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import List from "components/List";
import Modal from "components/Modal";
import SearchBox from "components/SearchBox";
import {
  fetchNextPageRequest as fetchMorePriceListProductsRequest,
  fetchPriceListProductsFailure,
  fetchPriceListProductsRequest,
  fetchPriceListProductsSuccess
} from "features/suppliers/price-lists/reducers/priceListProducts";
import useMessages from "i18n/hooks/useMessages";
import {
  loadNextPageRequest as loadMoreProductsRequest,
  loadProductsFailure,
  loadProductsRequest,
  loadProductsSuccess
} from "redux/reducers/supplier/products";
import { PriceListSummary, ProductStatus } from "types/api/generated/supplier";
import useAppSelector from "utils/hooks/useAppSelector";

import { SupplierDirectoryReduxState } from "../../Types";

import strings from "./ChooseProductsModal.strings.json";

const useStyles = makeStyles()(theme => ({
  modal: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "480px",
      // Allow the modal to become vertically small with margins, but also set a max height
      maxHeight: `min(800px, calc(100vh - ${theme.spacing(3 * 2)}))`,
      width: "450px"
    }
  },
  modalContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column"
  },
  searchBox: {
    display: "flex",
    margin: theme.spacing(0, 2, 0),
    width: "auto"
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    "& > *": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
  },
  listItem: {
    flex: "0"
  },
  listEmpty: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(0, 4)
  },
  modalActions: {
    marginTop: "-1px",
    borderTop: `1px solid ${theme.palette.grey[300]}`
  }
}));

export interface ChooseProductsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (selectedProductIds: string[]) => void;
  isSaving?: boolean;
  priceList?: PriceListSummary | null;
  singleSelection?: boolean;
}

const ChooseProductsModal: FunctionComponent<ChooseProductsModalProps> = ({
  open,
  onClose,
  onSave,
  isSaving,
  priceList,
  singleSelection
}) => {
  const messages = useMessages(strings);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { formatter } = useMessageFormatter();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const priceListProducts = useAppSelector((state: SupplierDirectoryReduxState) => state.priceLists.priceListProducts);
  const allProducts = useAppSelector((state: SupplierDirectoryReduxState) => state.supplier.products);
  const productsState = priceList ? priceListProducts : allProducts;
  const products = productsState.list;

  const resetState = (): void => {
    setSearchTerm("");
    setSelectedProducts([]);
  };

  const handleEverySearchChange = (search: string): void => {
    setSearchTerm(search);
  };

  const handleSearchChange = (search: string): void => {
    if (priceList) {
      dispatch(
        fetchPriceListProductsRequest({
          priceListId: priceList?.priceListId,
          search,
          isHidden: false,
          overrideList: true
        })
      );
    } else {
      dispatch(
        loadProductsRequest({
          search,
          status: [ProductStatus.Active, ProductStatus.OutOfStock],
          overrideList: true
        })
      );
    }
  };

  const handleLoadMore = (): void => {
    if (priceList) {
      dispatch(
        fetchMorePriceListProductsRequest({
          url: priceListProducts.links?.next,
          onSuccess: fetchPriceListProductsSuccess,
          onFailure: fetchPriceListProductsFailure,
          overrideList: false
        })
      );
    } else {
      loadMoreProductsRequest({
        url: allProducts.links?.next,
        onSuccess: loadProductsSuccess,
        onFailure: loadProductsFailure,
        overrideList: false
      });
    }
  };

  const handleSaveClick = (): void => {
    onSave(selectedProducts);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullScreen={isXsDown}
      className={classes.modal}
      contentClassName={classes.modalContent}
      actionsClassName={classes.modalActions}
      title={<FormattedMessage id={singleSelection ? messages.TITLE_SINGLE_SELECTION : messages.TITLE} />}
      TransitionProps={{ onExited: resetState }}
      actions={
        <>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage id={messages.CANCEL_BUTTON} />
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSaveClick}
            disabled={selectedProducts.length === 0}
            showLoader={isSaving}
          >
            <FormattedMessage id={messages.SAVE_BUTTON} />
          </Button>
        </>
      }
    >
      <SearchBox
        className={classes.searchBox}
        placeholder={formatter.format(
          priceList ? messages.SEARCH_PLACEHOLDER_PRICELISTS : messages.SEARCH_PLACEHOLDER,
          {
            priceListName: priceList?.name
          }
        )}
        useUrlParam={false}
        onChange={handleSearchChange}
        onEveryChange={handleEverySearchChange}
        value={searchTerm}
      />
      <List
        className={classes.list}
        disablePadding
        isLoading={productsState.loading}
        hasMore={Boolean(productsState.links?.next)}
        loadMoreCallback={handleLoadMore}
        isScrollable
        loader={
          <ListItem className={classes.listItem} dense data-testid="choose-products-modal-skeleton-loader">
            {!singleSelection && (
              <ListItemIcon>
                <Checkbox color="primary" edge="start" disabled />
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography component="div">
                  <Skeleton width="60%" />
                </Typography>
              }
              secondary={
                <Typography variant="caption">
                  <Skeleton width="30%" />
                </Typography>
              }
            />
          </ListItem>
        }
      >
        {!productsState.loading && products.length === 0 ? (
          <div className={classes.listEmpty}>
            <Typography paragraph color="textSecondary" data-testid="choose-products-modal-empty-text">
              <FormattedMessage
                id={priceList ? messages.EMPTY_LIST_PRICELISTS_1 : messages.EMPTY_LIST_1}
                values={{ priceListName: priceList?.name }}
              />
            </Typography>
            <Typography paragraph color="textSecondary">
              <FormattedMessage id={priceList ? messages.EMPTY_LIST_PRICELISTS_2 : messages.EMPTY_LIST_2} />
            </Typography>
          </div>
        ) : (
          products.map(product => {
            const labelId = `choose-products-modal-list-label-${product.productId}`;

            const onClick = (): void => {
              setSelectedProducts(currentlySelected => {
                return currentlySelected.includes(product.productId)
                  ? singleSelection
                    ? []
                    : currentlySelected.filter(id => id !== product.productId)
                  : singleSelection
                    ? [product.productId]
                    : [...currentlySelected, product.productId];
              });
            };

            const isSelected = selectedProducts.includes(product.productId);

            return (
              <ListItemButton
                className={classes.listItem}
                component="li"
                key={product.productId}
                dense
                onClick={onClick}
              >
                {!singleSelection && (
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={isSelected}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography component="div" id={labelId}>
                      {product.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="textSecondary">
                      {product.code}
                    </Typography>
                  }
                />
                {singleSelection && isSelected && <CheckIcon />}
              </ListItemButton>
            );
          })
        )}
      </List>
    </Modal>
  );
};

export default ChooseProductsModal;
