import { Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import List from "components/List";
import Modal, { ModalProps } from "components/Modal";
import ProductItem, { ProductItemSkeleton } from "components/ProductItem";
import SearchBox from "components/SearchBox";
import { SupplierDirectoryReduxState } from "features/suppliers/directory/Types";
import useMessages from "i18n/hooks/useMessages";
import {
  loadNextPageRequest as loadMoreProductsRequest,
  loadProductsFailure,
  loadProductsRequest,
  loadProductsSuccess
} from "redux/reducers/supplier/products";
import { ProductStatus, SupplierProductSummary } from "types/api/generated/supplier";
import { LocalOrderLine } from "types/Orders";
import useAppSelector from "utils/hooks/useAppSelector";

import strings from "./AddProductsModal.strings.json";

const useStyles = makeStyles()(theme => ({
  modal: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "460px",
      // Allow the modal to become vertically small with margins, but also set a max height
      maxHeight: `min(800px, calc(100vh - ${theme.spacing(3 * 2)}))`
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 0
  },
  actions: {
    position: "relative",
    // Prevents the list from overlapping the box shadow
    zIndex: 1,
    boxShadow: theme.shadows[3]
  },
  searchBox: {
    display: "flex",
    width: "auto",
    margin: theme.spacing(2, 2, 1)
  },
  list: {
    flex: 1,
    padding: theme.spacing(2),
    "& > ul": {
      padding: 0,
      margin: 0,
      listStyle: "none"
    },
    "& li:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  listEmpty: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(0, 4)
  }
}));

export interface AddProductsModalProps extends Omit<ModalProps, "onSubmit"> {
  open: boolean;
  buyerId: string;
  lineItems: Array<LocalOrderLine>;
  onClose: () => void;
  onSubmit: (lineItems: Array<LocalOrderLine>) => void;
  isSubmitting?: boolean;
  priceListName?: string | null;
  submitButtonText: string;
  /** Used to hide the prices in cases where the API returns prices but we don't want to show them (like when adding products to an order that was created before price lists were enabled) */
  hidePrices?: boolean;
}

const AddProductsModal: FunctionComponent<AddProductsModalProps> = ({
  open,
  buyerId,
  lineItems,
  onClose,
  onSubmit,
  isSubmitting,
  priceListName,
  submitButtonText,
  hidePrices,
  ...rest
}) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { formatter } = useMessageFormatter();
  const [searchTerm, setSearchTerm] = useState("");
  const [editedLineItems, setEditedLineItems] = useState<Array<LocalOrderLine>>(lineItems);
  const productsState = useAppSelector((state: SupplierDirectoryReduxState) => state.supplier.products);
  const products = productsState.list;
  const isEmpty = !productsState.loading && products.length === 0;

  useEffect(() => {
    setEditedLineItems(lineItems);
  }, [lineItems]);

  const resetState = (): void => {
    setEditedLineItems(lineItems);
    setSearchTerm("");
  };

  const handleEverySearchChange = (search: string): void => {
    setSearchTerm(search);
  };

  const handleSearchChange = (search: string): void => {
    dispatch(
      loadProductsRequest({
        search,
        status: [ProductStatus.Active, ProductStatus.OutOfStock],
        buyerId,
        overrideList: true
      })
    );
  };

  const handleLoadMore = (): void => {
    dispatch(
      loadMoreProductsRequest({
        url: productsState.links?.next,
        onSuccess: loadProductsSuccess,
        onFailure: loadProductsFailure,
        overrideList: false
      })
    );
  };

  const handleAddProduct = useCallback(
    (product: SupplierProductSummary): void => {
      const line: LocalOrderLine = {
        productId: product.productId,
        productName: product.name,
        productCode: product.code,
        quantity: 1,
        isAvailable: product.isAvailable,
        isOrderable: product.isOrderable,
        unavailableReason: product.unavailableReason,
        unitAmount: !hidePrices ? product.buyerPrice : null,
        lineAmount: !hidePrices ? product.buyerPrice : null,
        priceListUnitAmount: !hidePrices ? product.buyerPrice : null,
        priceListLineAmount: !hidePrices ? product.buyerPrice : null,
        hasCustomUnitAmount: false,
        productImages: product.imagesMetadata,
        problems: [],
        lineId: lineItems.length
      };

      setEditedLineItems(prevLineItems => [...prevLineItems, line]);
    },
    [hidePrices, lineItems.length]
  );

  const handleQuantityChange = useCallback((product: SupplierProductSummary, quantity: number): void => {
    setEditedLineItems(prevLineItems =>
      prevLineItems.map(line =>
        line.productId === product.productId
          ? {
              ...line,
              quantity,
              lineAmount: line.unitAmount && {
                ...line.unitAmount,
                amount: quantity * line.unitAmount.amount
              },
              priceListLineAmount: line.priceListUnitAmount && {
                ...line.priceListUnitAmount,
                amount: quantity * line.priceListUnitAmount.amount
              }
            }
          : line
      )
    );
  }, []);

  const handleSubmit = (): void => {
    onSubmit(editedLineItems);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullScreen={isXsDown}
      className={cx(classes.modal)}
      contentClassName={cx(classes.content)}
      actionsClassName={cx(classes.actions)}
      title={<FormattedMessage id={messages.TITLE} />}
      TransitionProps={{ onExited: resetState }}
      maxWidth="xs"
      fullWidth
      actions={
        <>
          <Button key="cancel" color="primary" onClick={onClose}>
            <FormattedMessage id={messages.CANCEL_BUTTON} />
          </Button>
          <Button key="submit" color="primary" variant="contained" onClick={handleSubmit} showLoader={isSubmitting}>
            {submitButtonText}
          </Button>
        </>
      }
      {...rest}
    >
      <SearchBox
        className={cx(classes.searchBox)}
        placeholder={formatter.format(
          priceListName ? messages.SEARCH_PLACEHOLDER_PRICELISTS : messages.SEARCH_PLACEHOLDER,
          { priceListName }
        )}
        useUrlParam={false}
        onChange={handleSearchChange}
        onEveryChange={handleEverySearchChange}
        value={searchTerm}
      />
      {isEmpty ? (
        <div className={cx(classes.listEmpty)}>
          <Typography paragraph color="textSecondary" data-testid="add-product-modal-empty-text">
            <FormattedMessage
              id={priceListName ? messages.EMPTY_LIST_PRICELISTS_1 : messages.EMPTY_LIST_1}
              values={{ priceListName }}
            />
          </Typography>
          <Typography paragraph color="textSecondary">
            <FormattedMessage id={priceListName ? messages.EMPTY_LIST_PRICELISTS_2 : messages.EMPTY_LIST_2} />
          </Typography>
        </div>
      ) : (
        <List
          component="div"
          className={cx(classes.list)}
          disablePadding
          isLoading={productsState.loading}
          hasMore={Boolean(productsState.links?.next)}
          loadMoreCallback={handleLoadMore}
          isScrollable
        >
          <Paper component="ul">
            {products.map(product => {
              const lineItem = editedLineItems.find(line => line.productId === product.productId);
              return (
                <li key={product.productId}>
                  <ProductItem
                    dataTestId="add-products-modal-product-item"
                    forceMobileLayout
                    product={product}
                    quantity={lineItem?.quantity ?? 0}
                    unitAmount={lineItem?.unitAmount?.amount}
                    onAdd={handleAddProduct}
                    onQuantityChange={handleQuantityChange}
                    isInOrder={Boolean(lineItem)}
                    hidePrice={hidePrices}
                  />
                </li>
              );
            })}

            {productsState.loading && <ProductItemSkeleton forceMobileLayout />}
          </Paper>
        </List>
      )}
    </Modal>
  );
};

export default AddProductsModal;
