import { ListItemProps } from "@mui/material/ListItem";

import { GettingStartedAction, GettingStartedActionType } from "types/api/generated/directory-internal";

export interface ActionProps extends Pick<ListItemProps, "divider"> {
  action: GettingStartedAction;
}

/**
 * The known actions for supplier getting started, and the order in which they
 * will be displayed.  Anything outside of this list will not end up showing on
 * screen.
 */
export const Actions = [
  GettingStartedActionType.ConnectToProvider,
  GettingStartedActionType.SupplierAddCustomers,
  GettingStartedActionType.SupplierAddProducts,
  GettingStartedActionType.SupplierSetupPricing,
  GettingStartedActionType.DeliveryGroupSettings,
  GettingStartedActionType.PaymentMerchant,
  GettingStartedActionType.PayUpstock,
  GettingStartedActionType.CheckProfile,
  GettingStartedActionType.SupplierFirstOrder,
  GettingStartedActionType.SupplierQuickAddProducts,
  GettingStartedActionType.InviteUsers,
  GettingStartedActionType.SupplierGrowthCustomersOrders
];

/**
 * Filter getting started actions down to only those that are known and
 * incomplete.
 */
export function remainingGettingStartedActions(actions: GettingStartedAction[]): GettingStartedAction[] {
  return actions.filter(action => Actions.includes(action.actionType) && !action.isCompleted);
}
