import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ApiValidationProblem,
  MatchUpstockProductToProviderProductCommand,
  PageLinks,
  ProductProvider,
  ProductStatus,
  ProviderProductMatchSummaryProviderProductMatchPagedSet,
  SupplierViewOrder
} from "types/api/generated/supplier-internal";
import { FailureAction, LoadNextPageRequest, RequestAction } from "types/redux-helpers";

export interface MatchingProductsState extends Omit<ProviderProductMatchSummaryProviderProductMatchPagedSet, "links"> {
  loading: boolean;
  limit: number;
  error?: ApiValidationProblem | Error;
  links: PageLinks | null;
}

const initialState: MatchingProductsState = {
  loading: false,
  data: [],
  links: null,
  total: 0,
  limit: 25,
  priceList: null
};

interface SaveProviderProductMatchRequestResponse {
  order: SupplierViewOrder;
  eTag: string | null;
}
export type SaveProviderProductMatchRequest = RequestAction<
  {
    orderId: number;
    lineId: number;
    product: MatchUpstockProductToProviderProductCommand;
  },
  SaveProviderProductMatchRequestResponse
>;
type SaveProviderProductMatchSuccess = PayloadAction<SaveProviderProductMatchRequestResponse>;
export type LoadProductsProviderMatchRequest = RequestAction<{
  provider: ProductProvider;
  showHidden: boolean;
  status: ProductStatus[];
  search?: string;
  buyerId?: string;
  limit?: number;
  pageToken?: string;
}>;
type LoadProductsProviderMatchSuccess = PayloadAction<ProviderProductMatchSummaryProviderProductMatchPagedSet>;

/**
 * Reducer for fetching the product list.
 */
const matchProducts = createSlice({
  name: "matchingProducts",
  initialState,
  reducers: {
    loadNextPageRequest: (state, _payload: LoadNextPageRequest) => ({ ...state, loading: true }),
    saveProviderProductMatchRequest: (state, _payload: SaveProviderProductMatchRequest) => ({
      ...state,
      loading: true
    }),
    saveProviderProductMatchSuccess: (state, _payload: SaveProviderProductMatchSuccess) => ({
      ...state,
      loading: false
    }),
    saveProviderProductMatchFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error,
      loading: false
    }),
    loadProductsProviderMatchRequest: (state, _payload: LoadProductsProviderMatchRequest) => ({
      ...state,
      data: [],
      loading: true
    }),
    loadProductsProviderMatchSuccess: (
      state,
      { payload: { data = [], links, total, priceList } }: LoadProductsProviderMatchSuccess
    ) => ({
      ...state,
      data: [...state.data, ...data],
      links,
      total,
      priceList,
      loading: false
    }),
    loadProductsProviderMatchFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const {
  loadNextPageRequest,
  saveProviderProductMatchRequest,
  saveProviderProductMatchSuccess,
  saveProviderProductMatchFailure,
  loadProductsProviderMatchRequest,
  loadProductsProviderMatchSuccess,
  loadProductsProviderMatchFailure
} = matchProducts.actions;
export default matchProducts.reducer;
