import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LinkedSupplierStats } from "types/api/generated/buyer";

export interface SupplierStatsState {
  invitedCount: number;
  leadCount: number;
  linkedCount: number;
}

const initialState: SupplierStatsState = {
  linkedCount: 0,
  invitedCount: 0,
  leadCount: 0
};

type FetchSuppliersStatsSuccess = PayloadAction<LinkedSupplierStats>;

/**
 * Reducer for the number of different supplier types.
 */
const suppliersStats = createSlice({
  name: "suppliersStats",
  initialState,
  reducers: {
    fetchSuppliersStatsRequest: state => state,
    fetchSuppliersStatsSuccess: (state, { payload: stats }: FetchSuppliersStatsSuccess) => ({
      ...state,
      ...stats
    }),
    fetchSuppliersStatsFailure: (state, { payload: error }) => ({
      ...state,
      error
    })
  }
});

export const { fetchSuppliersStatsRequest, fetchSuppliersStatsSuccess, fetchSuppliersStatsFailure } =
  suppliersStats.actions;
export default suppliersStats.reducer;
