import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer, PageLinks, SupplierSummary } from "types/api/generated/buyer";
import { LoadNextPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";

import { inviteToTradeRequest, inviteToTradeSuccess } from "./supplierById";

export interface DirectoryState {
  error: string;
  links: PageLinks | null;
  list: SupplierSummary[];
  loading: boolean;
  featuredSuppliers: SupplierSummary[];
  loadingFeaturedSuppliers: boolean;
  supplierId: string | null;
}

const initialState: DirectoryState = {
  list: [],
  links: null,
  loading: false,
  featuredSuppliers: [],
  loadingFeaturedSuppliers: false,
  supplierId: null,
  error: ""
};

export type FetchDirectoryRequest = RequestAction<
  Buyer.V1DirectoryList.RequestQuery,
  Buyer.V1DirectoryList.ResponseBody
>;
type FetchDirectorySuccess = PayloadAction<Buyer.V1DirectoryList.ResponseBody & PagingOptions>;

export type FetchFeaturedSuppliersRequest = RequestAction<
  Buyer.V1DirectoryList.RequestQuery,
  Buyer.V1DirectoryList.ResponseBody
>;
type FetchFeaturedSuppliersSuccess = PayloadAction<Buyer.V1DirectoryList.ResponseBody>;

const buyerDirectorySlice = createSlice({
  name: "buyerDirectory",
  initialState,
  reducers: {
    clearDirectory: state => ({
      ...state,
      list: [],
      links: null,
      loading: false,
      featuredSuppliers: [],
      loadingFeaturedSuppliers: false
    }),
    fetchDirectoryRequest: (state, _action: FetchDirectoryRequest) => ({
      ...state,
      loading: true
    }),
    fetchDirectorySuccess: (state, { payload: { data, links, overrideList = false } }: FetchDirectorySuccess) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      loading: false
    }),
    fetchDirectoryFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    fetchFeaturedSuppliersRequest: (state, _action: FetchFeaturedSuppliersRequest) => ({
      ...state,
      loadingFeaturedSuppliers: true
    }),
    fetchFeaturedSuppliersSuccess: (state, { payload: { data } }: FetchFeaturedSuppliersSuccess) => ({
      ...state,
      featuredSuppliers: data,
      loadingFeaturedSuppliers: false
    }),
    fetchFeaturedSuppliersFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loadingFeaturedSuppliers: false
    }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest) => ({ ...state, loading: true })
  },
  extraReducers: builder => {
    builder
      .addCase(inviteToTradeRequest, (state, { payload: { supplierId } }) => ({
        ...state,
        supplierId
      }))
      .addCase(inviteToTradeSuccess, (state, { payload: { status } }) => ({
        ...state,
        list: state.list.map(supplier => {
          // If the supplierId matches the previous stored in the request we can update the status to appear in the list
          if (supplier.supplierId === state.supplierId) {
            return {
              ...supplier,
              linkStatus: status
            };
          }
          return supplier;
        }),
        featuredSuppliers: state.featuredSuppliers.map(supplier => {
          // If the supplierId matches the previous stored in the request we can update the status to appear in the list
          if (supplier.supplierId === state.supplierId) {
            return {
              ...supplier,
              linkStatus: status
            };
          }
          return supplier;
        }),
        supplierId: null
      }));
  }
});

export const {
  clearDirectory,
  fetchDirectoryRequest,
  fetchDirectorySuccess,
  fetchDirectoryFailure,
  fetchFeaturedSuppliersRequest,
  fetchFeaturedSuppliersSuccess,
  fetchFeaturedSuppliersFailure,
  loadNextPageRequest
} = buyerDirectorySlice.actions;
export default buyerDirectorySlice.reducer;
