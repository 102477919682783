import DoneAllIcon from "@mui/icons-material/DoneAll";
import DraftsIcon from "@mui/icons-material/Drafts";
import ForumIcon from "@mui/icons-material/Forum";
import PaymentIcon from "@mui/icons-material/Payment";
import PrintIcon from "@mui/icons-material/Print";
import UpdateIcon from "@mui/icons-material/Update";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";

import Header from "components/Header";
import Page from "components/Page";
import PaidFeaturePromotion, { Perk, Quote } from "components/PaidFeaturePromotion/PaidFeaturePromotion";
import useMessages from "i18n/hooks/useMessages";
import BoltIcon from "media/gated-sections/bolt.svg?react";
import CompanyPhoto from "media/gated-sections/company-images/eightthirty-photo.jpg";
import CompanyLogo from "media/gated-sections/company-logos/eightthirty-logo.png";
import FileStackIcon from "media/gated-sections/file-stack.svg?react";
import OrdersThumbnail from "media/gated-sections/thumbnails/orders-view.png";
import WandIcon from "media/gated-sections/wand.svg?react";
import { CompanyRoot } from "routes/Routes";
import { Feature } from "types/api/generated/global-internal";
import { SUPPLIER_PAYMENTS } from "utils/flags";
import useFeatures from "utils/hooks/useFeatures";

import strings from "./OrdersGate.strings.json";

interface OrdersGateProps {
  feature?: Feature;
}

const OrdersGate: FunctionComponent<OrdersGateProps> = ({ feature }) => {
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const [isInSupplierPaymentFeature] = useFeatures(tenancyId, SUPPLIER_PAYMENTS);

  const header = <FormattedMessage id={messages.HEADER} />;
  const description = <FormattedMessage id={messages.DESCRIPTION} />;
  const upgradePrefill = formatter.format(messages.UPGRADE_PREFILL);
  const demoPrefill = formatter.format(messages.DEMO_PREFILL);
  const videoId = "703100192";

  const secondFeatureFlaggedPerk = isInSupplierPaymentFeature
    ? {
        icon: PaymentIcon,
        title: <FormattedMessage id={messages.PAYMENT_TITLE} />,
        description: <FormattedMessage id={messages.PAYMENT_DESCRIPTION} />
      }
    : {
        icon: BoltIcon,
        title: <FormattedMessage id={messages.SIMPLE_TITLE} />,
        description: <FormattedMessage id={messages.SIMPLE_DESCRIPTION} />
      };

  const perks: Perk[] = [
    {
      icon: WandIcon,
      title: <FormattedMessage id={messages.AUTOMATED_TITLE} />,
      description: <FormattedMessage id={messages.AUTOMATED_DESCRIPTION} />
    },

    secondFeatureFlaggedPerk,
    {
      icon: DraftsIcon,
      title: <FormattedMessage id={messages.CUSTOMER_TITLE} />,
      description: <FormattedMessage id={messages.CUSTOMER_DESCRIPTION} />
    },
    {
      icon: FileStackIcon,
      title: <FormattedMessage id={messages.HISTORY_TITLE} />,
      description: <FormattedMessage id={messages.HISTORY_DESCRIPTION} />
    },
    {
      icon: PrintIcon,
      title: <FormattedMessage id={messages.PACKINGSLIP_TITLE} />,
      description: <FormattedMessage id={messages.PACKINGSLIP_DESCRIPTION} />
    },
    {
      icon: ForumIcon,
      title: <FormattedMessage id={messages.COMMENTS_TITLE} />,
      description: <FormattedMessage id={messages.COMMENTS_DESCRIPTION} />
    },
    {
      icon: UpdateIcon,
      title: <FormattedMessage id={messages.STANDINGORDER_TITLE} />,
      description: <FormattedMessage id={messages.STANDINGORDER_DESCRIPTION} />
    },
    {
      icon: DoneAllIcon,
      title: <FormattedMessage id={messages.BULKPROCESS_TITLE} />,
      description: <FormattedMessage id={messages.BULKPROCESS_DESCRIPTION} />
    }
  ];
  const quote: Quote = {
    image: CompanyPhoto,
    text: <FormattedMessage id={messages.QUOTE} />,
    author: <FormattedMessage id={messages.QUOTE_AUTHOR} />,
    logo: CompanyLogo
  };

  return (
    <Page header={<Header title={formatter.format(messages.PAGE_TITLE)} />}>
      <PaidFeaturePromotion
        header={header}
        upgradePrefill={upgradePrefill}
        demoPrefill={demoPrefill}
        description={description}
        videoId={videoId}
        videoThumbnail={OrdersThumbnail}
        perks={perks}
        quote={quote}
        feature={feature}
      />
    </Page>
  );
};

export default OrdersGate;
