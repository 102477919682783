import { Avatar, AvatarProps, Typography, Skeleton, IconButton, Badge, useTheme, TypographyProps } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

import useMessages from "i18n/hooks/useMessages";
import SmallRemoveIcon from "media/icon-remove-small.svg?react";

import strings from "./CompanyLogo.strings.json";

const DEFAULT_SIZE = 40;

const useStyles = makeStyles()(theme => ({
  logo: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    containerType: "size"
  },
  noLogo: {
    backgroundColor: theme.palette.grey[400]
  },
  initials: {
    fontWeight: "bold"
  }
}));

export interface EmptyStateProps extends TypographyProps {
  tradingName: string;
}

const InitialsEmptyState: FunctionComponent<EmptyStateProps> = ({ tradingName, ...rest }) => {
  const { classes } = useStyles();

  const initials = tradingName
    .replace(/[^a-z0-9 ]/gi, "") // remove any special characters from the string (-,&'" etc)
    .replace(/ +(?= )/g, "") // remove any extra spaces within the name
    .trim() // remove any spaces from the start or end of the name
    .split(" ")
    .map(n => n[0]);

  return (
    <Typography className={classes.initials} {...rest}>
      {initials[0]?.toUpperCase()}
      {initials[1]?.toUpperCase()}
    </Typography>
  );
};

export interface CompanyLogoProps extends AvatarProps {
  tradingName: string;
  onDelete?: () => void;
  logoUrl?: string | null;
  isLoading?: boolean;
  EmptyState?: ReactNode;
}

const CompanyLogo: FunctionComponent<CompanyLogoProps> = ({
  tradingName,
  onDelete,
  logoUrl,
  isLoading,
  className,
  EmptyState,
  ...rest
}) => {
  const logo = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const { classes, cx } = useStyles();
  const [width, setWidth] = useState<number>(DEFAULT_SIZE);

  useLayoutEffect(() => {
    setWidth(logo.current?.offsetWidth ?? DEFAULT_SIZE);
  }, []);

  if (isLoading) {
    return <Skeleton variant="circular" className={cx(classes.logo, className)} />;
  }

  return (
    <Badge
      overlap="circular"
      invisible={!onDelete}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        <IconButton style={{ backgroundColor: "white", boxShadow: theme.shadows[1] }} onClick={onDelete}>
          <SmallRemoveIcon />
        </IconButton>
      }
    >
      <Avatar
        ref={logo}
        className={cx(classes.logo, { [classes.noLogo]: !logoUrl }, className)}
        src={logoUrl ?? undefined}
        alt={formatter.format(messages.ALT, { tradingName })}
        slotProps={{ img: { loading: "lazy" } }}
        sx={{ width: DEFAULT_SIZE, height: DEFAULT_SIZE }}
        {...rest}
      >
        {EmptyState === null ? (
          <Typography color="textSecondary">
            <FormattedMessage id={messages.EMPTY_STATE_TEXT} />
          </Typography>
        ) : (
          (EmptyState ?? <InitialsEmptyState tradingName={tradingName} fontSize={width * 0.4} />)
        )}
      </Avatar>
    </Badge>
  );
};

export default CompanyLogo;
