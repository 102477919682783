import FavoriteIcon from "@mui/icons-material/FavoriteBorderOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useLocation, useParams } from "react-router-dom";

import PermissionTooltip from "auth/PermissionTooltip";
import useHasPermission from "auth/useHasPermission";
import FakeFabButton from "components/Button/FakeFabButton";
import GlobalNavItem from "components/GlobalNav/GlobalNavItem";
import useBuyerGettingStarted from "features/buyers/getting-started/useBuyerGettingStarted";
import useMessages from "i18n/hooks/useMessages";
import GettingStartedIcon from "media/icon-getting-started.svg?react";
import ReportsIcon from "media/icon-reports.svg?react";
import NewsfeedIcon from "media/newsfeed.svg?react";
import OrdersIcon from "media/orders.svg?react";
import PeopleIcon from "media/people-three.svg?react";
import {
  BuyerFavouritesLists,
  BuyerGettingStarted,
  BuyerNewOrder,
  BuyerNewsfeed,
  BuyerOrders,
  BuyerReports,
  BuyerSuppliers,
  CompanyRoot,
  Users
} from "routes/Routes";

import strings from "./strings.json";

interface Props {
  handleNavClose?: () => void;
}

/**
 * Nav items for the buyer side of the app.
 */
const BuyerNav: FunctionComponent<Props> = ({ handleNavClose = () => {} }) => {
  const messages = useMessages(strings);
  const location = useLocation();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const canCreateBuyerOrder = useHasPermission(BuyerNewOrder.permissions);
  const canViewOrders = useHasPermission(BuyerOrders.permissions);
  const canViewFavourites = useHasPermission(BuyerFavouritesLists.permissions);
  const canViewSuppliers = useHasPermission(BuyerSuppliers.permissions);
  const canViewUsers = useHasPermission(Users.permissions);
  const canViewReports = useHasPermission(BuyerReports.permissions);
  const { remainingActions } = useBuyerGettingStarted();

  return (
    <div data-testid="buyer-menu">
      <PermissionTooltip hasPermission={canCreateBuyerOrder}>
        <FakeFabButton
          to={BuyerNewOrder.toLocation({ tenancyId }, undefined, { referrer: location })}
          onClick={handleNavClose}
          sx={{
            height: "32px",
            padding: 2,
            margin: 2,
            boxShadow: "none !important"
          }}
          disabled={!canCreateBuyerOrder}
        >
          <FormattedMessage id={messages.NEWORDER} />
        </FakeFabButton>
      </PermissionTooltip>
      <GlobalNavItem
        to={BuyerGettingStarted.toUrl({ tenancyId })}
        icon={<GettingStartedIcon />}
        text={<FormattedMessage id={messages.GETTINGSTARTED} />}
        badgeCount={remainingActions.length}
        onClick={handleNavClose}
        trackingPropLink="Getting started"
        trackingPropNav="buyer"
      />
      <PermissionTooltip hasPermission={canViewOrders}>
        <GlobalNavItem
          to={BuyerOrders.toUrl({ tenancyId })}
          icon={<OrdersIcon />}
          text={<FormattedMessage id={messages.ORDERS} />}
          onClick={handleNavClose}
          disabled={!canViewOrders}
          trackingPropLink="Orders"
          trackingPropNav="buyer"
        />
      </PermissionTooltip>
      <GlobalNavItem
        to={BuyerNewsfeed.toUrl({ tenancyId })}
        icon={<NewsfeedIcon />}
        text={<FormattedMessage id={messages.NEWSFEED} />}
        onClick={handleNavClose}
        trackingPropLink="Newsfeed"
        trackingPropNav="buyer"
      />
      <PermissionTooltip hasPermission={canViewFavourites}>
        <GlobalNavItem
          to={BuyerFavouritesLists.toUrl({ tenancyId })}
          icon={<FavoriteIcon />}
          text={<FormattedMessage id={messages.FAVOURITES} />}
          onClick={handleNavClose}
          disabled={!canViewFavourites}
          trackingPropLink="Favourites"
          trackingPropNav="buyer"
        />
      </PermissionTooltip>
      <PermissionTooltip hasPermission={canViewSuppliers}>
        <GlobalNavItem
          to={BuyerSuppliers.toUrl({ tenancyId })}
          icon={<StorefrontIcon />}
          text={<FormattedMessage id={messages.SUPPLIERS} />}
          onClick={handleNavClose}
          disabled={!canViewSuppliers}
          trackingPropLink="Suppliers"
          trackingPropNav="buyer"
        />
      </PermissionTooltip>
      <PermissionTooltip hasPermission={canViewUsers}>
        <GlobalNavItem
          to={Users.toUrl({ tenancyId })}
          icon={<PeopleIcon />}
          text={<FormattedMessage id={messages.TEAM} />}
          onClick={handleNavClose}
          disabled={!canViewUsers}
          trackingPropLink="Team"
          trackingPropNav="buyer"
        />
      </PermissionTooltip>
      <PermissionTooltip hasPermission={canViewReports}>
        <GlobalNavItem
          to={BuyerReports.toUrl({ tenancyId })}
          icon={<ReportsIcon />}
          text={<FormattedMessage id={messages.REPORTS} />}
          onClick={handleNavClose}
          disabled={!canViewReports}
          trackingPropLink="Reports"
          trackingPropNav="buyer"
          data-chameleon-id="buyer-nav-reports-link"
        />
      </PermissionTooltip>
    </div>
  );
};

export default BuyerNav;
