import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { GlobalInternal } from "types/api";
import { Alpha2Code, CountrySummary } from "types/api/generated/global-internal";

type RequestQuery = GlobalInternal.InternalGeographyCountriesList.RequestQuery;
type RequestBody = GlobalInternal.InternalGeographyCountriesList.RequestBody;
export type ResponseBody = GlobalInternal.InternalGeographyCountriesList.ResponseBody;

const countriesQueryKey = createQueryKeys("countries");

export const countriesOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody>(() => ({
  method: "GET",
  path: "/global/internal/geography/countries"
}));

export const defaultCountries: CountrySummary[] = [
  {
    alpha2Code: Alpha2Code.AU,
    commonName: "Australia",
    isSupported: true,
    callingCodes: ["61"]
  },
  {
    alpha2Code: Alpha2Code.NZ,
    commonName: "New Zealand",
    isSupported: true,
    callingCodes: ["64"]
  },
  {
    alpha2Code: Alpha2Code.US,
    commonName: "United States",
    isSupported: true,
    callingCodes: ["1"]
  }
];

/**
 * React hook for calling `GET /global/internal/geography/countries` to retrieve
 * all countries.
 */
export default function useCountries(): UseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: countriesQueryKey._def,
    async queryFn() {
      const response = await apiFetch(countriesOptions());
      return response.data;
    },
    placeholderData: defaultCountries,
    staleTime: 1000 * 60 * 60 // 1 hour, though I don't imagine our list of countries changes that much
  });
}
