import { Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FormikConfig, useFormik } from "formik";
import { ReactNode, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import CurrencyInput from "components/CurrencyInput";
import Modal from "components/Modal";
import NumberStepper from "components/NumberStepper";
import Tooltip from "components/Tooltip";
import useMessages from "i18n/hooks/useMessages";
import { OrderLine } from "types/api/generated/buyer-internal";
import { LocalOrderLine } from "types/Orders";

import strings from "./MobileEditLineItemModal.strings.json";

interface FormValues {
  quantity: number;
  unitPrice?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    width: theme.spacing(43)
  },
  content: {
    position: "relative",
    overflow: "visible",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  productCode: {
    position: "absolute",
    top: theme.spacing(-2),
    zIndex: 1
  },
  quantityButtons: {
    width: theme.spacing(15),
    height: theme.spacing(4.5),
    flexShrink: 0,
    marginLeft: "auto"
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2)
    }
  },
  input: {
    position: "relative",
    marginLeft: theme.spacing(1),
    "& input": {
      boxSizing: "border-box",
      width: theme.spacing(16),
      height: theme.spacing(5),
      padding: theme.spacing(1.5),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.primary,
      fontSize: theme.typography.body1.fontSize,
      fontFamily: "inherit",
      lineHeight: theme.typography.body1.lineHeight,
      textAlign: "right",
      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
        outlineOffset: "-2px"
      },
      "&:disabled": {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[50]
      }
    }
  },
  inputPrefix: {
    position: "absolute",
    top: 0,
    left: theme.spacing(2),
    lineHeight: "40px"
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    "& button:not(:first-child)": {
      marginLeft: theme.spacing(1)
    }
  }
}));

export interface MobileEditLineItemModalProps<L extends OrderLine | LocalOrderLine> {
  lineItem: L;
  open: boolean;
  onClose: () => void;
  onRemove: (lineItem: L) => void;
  onUpdate: (lineItem: L, quantity: number, unitAmount?: number) => void;
  isRemoving?: boolean;
  isUpdating?: boolean;
  disableRemoveButton?: boolean;
  removeButtonTooltip?: ReactNode;
  submitButtonText: string;
}

function MobileEditLineItemModal<L extends OrderLine | LocalOrderLine>({
  lineItem,
  open,
  onClose,
  onRemove,
  onUpdate,
  isRemoving,
  isUpdating,
  disableRemoveButton,
  removeButtonTooltip,
  submitButtonText
}: MobileEditLineItemModalProps<L>): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();

  const [unitPriceHasChanged, setUnitPriceHasChanged] = useState(false);
  const hasPrices = Boolean(lineItem.unitAmount) && Boolean(lineItem.lineAmount);
  const quantityId = "mobile-edit-line-item-modal-quantity";
  const unitPriceId = "mobile-edit-line-item-modal-unit-price";
  const amountId = "mobile-edit-line-item-modal-amount";
  const formId = "mobile-edit-line-item-modal-form";

  const handleRemove = (): void => {
    onRemove(lineItem);
  };

  const handleUpdate: FormikConfig<FormValues>["onSubmit"] = values => {
    onUpdate(lineItem, values.quantity, unitPriceHasChanged ? Number(values.unitPrice) : undefined);
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      quantity: lineItem.quantity,
      unitPrice: lineItem.unitAmount?.amount.toString()
    },
    onSubmit: handleUpdate,
    enableReinitialize: true
  });

  const resetState = (): void => {
    setUnitPriceHasChanged(false);
    formik.resetForm();
  };

  return (
    <Modal
      className={cx(classes.root)}
      open={open}
      title={lineItem.productName}
      onClose={onClose}
      contentClassName={classes.content}
      TransitionProps={{ onExited: resetState }}
      actions={
        <div className={cx(classes.actions)}>
          <Tooltip disabled={!removeButtonTooltip} title={removeButtonTooltip ?? ""}>
            <Button
              type="button"
              color="primary"
              variant="text"
              onClick={handleRemove}
              showLoader={isRemoving}
              disabled={isRemoving || isUpdating || disableRemoveButton}
            >
              <FormattedMessage id={messages.REMOVE_BUTTON} />
            </Button>
          </Tooltip>

          <div>
            <Button type="button" color="primary" variant="text" onClick={onClose}>
              <FormattedMessage id={messages.CANCEL_BUTTON} />
            </Button>
            <Button
              type="submit"
              form={formId}
              color="primary"
              variant="contained"
              showLoader={isUpdating}
              disabled={isUpdating || isRemoving}
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
      }
    >
      {lineItem.productCode && (
        <Typography className={cx(classes.productCode)} color="textSecondary" variant="caption">
          {lineItem.productCode}
        </Typography>
      )}

      <form id={formId} onSubmit={formik.handleSubmit}>
        <div className={cx(classes.inputGroup)}>
          <Typography component="label" htmlFor={quantityId} variant="caption" color="textSecondary">
            <FormattedMessage id={messages.QUANTITY_LABEL} />
          </Typography>
          <NumberStepper
            className={cx(classes.quantityButtons)}
            inputProps={{ id: quantityId }}
            value={formik.values.quantity}
            onChange={value => formik.setFieldValue("quantity", value)}
          />
        </div>

        {hasPrices && (
          <>
            <div className={cx(classes.inputGroup)}>
              <Typography component="label" htmlFor={unitPriceId} variant="caption" color="textSecondary">
                <FormattedMessage id={messages.UNIT_PRICE_LABEL} />
              </Typography>
              <div className={cx(classes.input)}>
                <Typography component="span" className={cx(classes.inputPrefix)} color="textSecondary">
                  $
                </Typography>
                <CurrencyInput
                  id={unitPriceId}
                  name="unitPrice"
                  required
                  value={formik.values.unitPrice}
                  onChange={e => {
                    formik.handleChange(e);
                    setUnitPriceHasChanged(true);
                  }}
                />
              </div>
            </div>

            <div className={cx(classes.inputGroup)}>
              <Typography component="label" htmlFor={amountId} variant="caption" color="textSecondary">
                <FormattedMessage id={messages.AMOUNT_LABEL} />
              </Typography>
              <div className={cx(classes.input)}>
                <Typography component="span" className={cx(classes.inputPrefix)} color="textSecondary">
                  $
                </Typography>
                <CurrencyInput
                  id={amountId}
                  name="amount"
                  disabled
                  value={String(formik.values.quantity * Number(formik.values.unitPrice))}
                />
              </div>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
}

export default MobileEditLineItemModal;
