import { all, call, CallEffect, put } from "redux-saga/effects";

import { AddCustomerRequest, RemoveCustomerRequest } from "types/api/generated/supplier";

import {
  updatePriceListCustomersFailure,
  UpdatePriceListCustomersRequest,
  updatePriceListCustomersSuccess
} from "../reducers/priceListCustomers";

import addCustomersToPriceList from "./addCustomersToPriceList";
import removeCustomersFromPriceList from "./removeCustomersFromPriceList";

/**
 * Return the call effect if the customers list has items in it, otherwise
 * return `null`.
 */
function callIfCustomersAvailable(
  saga: typeof addCustomersToPriceList | typeof removeCustomersFromPriceList,
  priceListId: string,
  customers: AddCustomerRequest[] | RemoveCustomerRequest[]
): CallEffect<any> | null {
  return customers?.length
    ? call(saga, {
        payload: {
          priceListId,
          customers
        }
      })
    : null;
}

/**
 * A saga that combines {@link addCustomersToPriceList} and
 * {@link removeCustomersFromPriceList} so that it can be treated as a single
 * action.
 */
export default function* updatePriceListCustomers(action: UpdatePriceListCustomersRequest): Generator {
  const { customersToAdd, customersToRemove, failureCallback, priceListId, successCallback } = action.payload;
  try {
    yield all([
      callIfCustomersAvailable(addCustomersToPriceList, priceListId, customersToAdd),
      callIfCustomersAvailable(removeCustomersFromPriceList, priceListId, customersToRemove)
    ]);
    yield put(updatePriceListCustomersSuccess());
    if (successCallback) {
      yield call(successCallback);
    }
  } catch (error) {
    yield put(updatePriceListCustomersFailure(error as Error));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}
