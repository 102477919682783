import {
  PurchaseOrderSuggestionsLineNotification,
  PurchaseOrderSuggestionsV1LineMatched,
  PurchaseOrderSuggestionsV1LineUnmatched,
  ValidatePurchaseOrderRequestOrderLine,
  ValidatePurchaseResponseOrderLine
} from "types/api/generated/supplier";
import { Severity } from "types/api/generated/supplier-internal";
import { LocalInvalidOrderLine, LocalOrderLine, LocalOrderLineProblem } from "types/Orders";

import { FetchInboxMessageSuggestionsResult } from "../queries/useFetchInboxMessageSuggestions";

export function mapSuggestedOrderLines(suggestions: FetchInboxMessageSuggestionsResult | null | undefined): {
  validOrderLines: LocalOrderLine[];
  invalidOrderLines: LocalInvalidOrderLine[];
} {
  const suggestedLines = suggestions?.purchaseOrderSuggestions?.orderLines || [];

  const validOrderLines: LocalOrderLine[] = [];
  const invalidOrderLines: LocalInvalidOrderLine[] = [];

  suggestedLines.forEach((suggestion, index) => {
    switch (suggestion.$type) {
      case "Matched":
        validOrderLines.push(toValidOrderLine(suggestion as PurchaseOrderSuggestionsV1LineMatched, index));
        break;
      case "Unmatched":
        invalidOrderLines.push(toInvalidOrderLine(suggestion as PurchaseOrderSuggestionsV1LineUnmatched, index));
        break;
      default:
        throw new Error(`Unknown suggestion type: ${suggestion.$type}`);
    }
  });

  return {
    validOrderLines,
    invalidOrderLines
  };
}

const toValidOrderLine = (suggestion: PurchaseOrderSuggestionsV1LineMatched, index: number): LocalOrderLine => ({
  lineId: index,
  quantity: suggestion.quantity ?? 0,
  productId: suggestion.productId!,
  unitAmount: suggestion.unitAmount,
  lineAmount: suggestion.lineAmount,
  priceListUnitAmount: suggestion.unitAmount,
  priceListLineAmount: suggestion.lineAmount,
  hasCustomUnitAmount: false,
  productName: suggestion.productName!,
  productCode: suggestion.productCode!,
  productImages: suggestion.productImages,
  documentProductCode: suggestion.documentProductCode,
  lineSuggestionId: suggestion.lineSuggestionId,
  isAvailable: true,
  isOrderable: true,
  unavailableReason: null,
  tax: null,
  packingStatus: null,
  problems: suggestion.notifications?.map(notificationToLocalOrderLineProblem) ?? []
});

const toInvalidOrderLine = (
  suggestion: PurchaseOrderSuggestionsV1LineUnmatched,
  index: number
): LocalInvalidOrderLine => ({
  lineId: index,
  quantity: suggestion.quantity,
  productCode: suggestion.productCode,
  productName: suggestion.productName,
  unitAmount: suggestion.unitAmount,
  lineAmount: suggestion.lineAmount,
  providerProductId: "",
  problems: []
});

const notificationToLocalOrderLineProblem = (
  notification: PurchaseOrderSuggestionsLineNotification
): LocalOrderLineProblem => ({
  type: notification.$type,
  severity: Severity.Error // TODO: map severity when that's available
});

export const mergeLocalOrderLineWithLineNotifications = (
  line: LocalOrderLine,
  notifications: ValidatePurchaseResponseOrderLine["notifications"]
): LocalOrderLine => ({
  ...line,
  problems: notifications.map(notificationToLocalOrderLineProblem)
});

export const toValidationLine = (line: LocalOrderLine): ValidatePurchaseOrderRequestOrderLine => ({
  lineSuggestionId: line.lineSuggestionId,
  productId: line.productId,
  quantity: line.quantity,
  customUnitAmount: line.unitAmount
});
