export const localStorageHelpers = <T>(
  key: string
): [() => any, (value: T) => void, () => void, () => string | null | undefined] => {
  const set = (newValue: T): void => {
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  const getJSON = (): string | null | undefined => {
    return localStorage.getItem(key);
  };

  const get = (): any => {
    const JSONValue = getJSON();

    return JSONValue ? JSON.parse(JSONValue) : undefined;
  };

  const remove = (): void => {
    localStorage.removeItem(key);
  };

  return [get, set, remove, getJSON];
};
