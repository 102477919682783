import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api/generated/supplier";

export type RequestBody = Supplier.V1MailboxInboxMessagesCreateOrderUpdate.RequestBody;
type RequestQuery = Supplier.V1MailboxInboxMessagesCreateOrderUpdate.RequestQuery;
type RequestParameters = Supplier.V1MailboxInboxMessagesCreateOrderUpdate.RequestParams;
export type ResponseBody = Supplier.V1MailboxInboxMessagesCreateOrderUpdate.ResponseBody;

export const createMailboxOrderOptions = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestParameters
>(parameters => ({
  method: "PUT",
  path: `/supplier/v1/mailbox/inbox/messages/create-order/${parameters.inboxMessageId}`
}));

const useCreateMailboxOrder = (
  parameters: RequestParameters
): UseMutationResult<ResponseBody, FetchV2Error, RequestBody> => {
  const apiFetch = useApiFetch();

  return useMutation({
    mutationFn: async data => {
      const response = await apiFetch(createMailboxOrderOptions(parameters, { data }));
      return response.data;
    }
  });
};

export default useCreateMailboxOrder;
