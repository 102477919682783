import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import CircleIcon from "components/CircleIcon";
import Modal from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import NextIcon from "media/icon-next.svg?react";
import PreviousIcon from "media/icon-previous.svg?react";
import { theme } from "theme/ThemeProvider";
import { PrivateFile } from "types/api/generated/buyer";
import { downloadBlob } from "utils/Download";
import useModal from "utils/hooks/useModal";
import { trimMiddle } from "utils/string";

import { useFetchInboxMessageFile } from "../../queries/useFetchInboxMessageFile";

import strings from "./InboxMessageFileViewer.strings.json";

const FILE_VIEWER_SUPPORTED_TYPES = [
  "application/pdf",
  "text/plain",
  "application/json",
  "text/html",
  "text/xml",
  "text/csv"
];

const useStyles = makeStyles()(_ => ({
  container: {
    flex: 1,
    height: `calc(100vh - ${theme.spacing(17)})`
  },
  controls: {
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    flexWrap: "wrap"
  },
  fileContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  unsupportedFileContainer: {
    margin: theme.spacing(2, 3),
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius * 3,
    alignItems: "center",
    textAlign: "center",
    wordBreak: "break-word"
  },
  fileViewer: {
    border: "none"
  }
}));

interface InboxMessageFileViewerProps {
  externalId: string;
  files: PrivateFile[];
  onDeleteFile: (file: PrivateFile) => void;
}

const InboxMessageFileViewer: FunctionComponent<InboxMessageFileViewerProps> = ({
  externalId,
  files,
  onDeleteFile
}) => {
  const messages = useMessages(strings);
  const { classes } = useStyles();

  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [isDeleteAttachmentModalOpen, openDeleteAttachmentModalOpen, closeDeleteAttachmentModalOpen] = useModal();

  const selectedFileReference = files[selectedFileIndex];

  const { data: file, isSuccess: hasFetchedFile } = useFetchInboxMessageFile({
    externalId,
    fileId: selectedFileReference?.id ?? ""
  });

  const hasNextFile = selectedFileIndex < files.length - 1;
  const hasPreviousFile = selectedFileIndex > 0;

  if (!selectedFileReference) {
    return (
      <Box className={classes.fileContainer}>
        <Typography color="textSecondary">
          <FormattedMessage id={messages.NO_FILE_SELECTED} />
        </Typography>
      </Box>
    );
  }

  return (
    <Stack className={classes.container}>
      <Stack direction="row" justifyContent="space-between">
        <Stack className={classes.controls}>
          <IconButton
            data-testid="previous-button"
            disabled={!hasPreviousFile}
            onClick={() => setSelectedFileIndex(selectedFileIndex - 1)}
          >
            <PreviousIcon />
          </IconButton>
          <IconButton
            data-testid="next-button"
            disabled={!hasNextFile}
            onClick={() => setSelectedFileIndex(selectedFileIndex + 1)}
          >
            <NextIcon />
          </IconButton>
          <Typography>
            <FormattedMessage
              id={messages.NAVIGATION_FILE_COUNT}
              values={{ currentCount: selectedFileIndex + 1, totalCount: files.length }}
            />
          </Typography>
        </Stack>
        <Stack className={classes.controls}>
          <Button color="primary" onClick={openDeleteAttachmentModalOpen}>
            <FormattedMessage id={messages.NAVIGATION_DELETE_BUTTON} />
          </Button>
        </Stack>
      </Stack>
      {!hasFetchedFile ? (
        <Box className={classes.fileContainer}>
          <CircularProgress />
        </Box>
      ) : FILE_VIEWER_SUPPORTED_TYPES.includes(file.type) ? (
        <Box className={classes.fileContainer}>
          <FileViewer file={file} name={selectedFileReference.originalName} />
        </Box>
      ) : file?.type.includes("image") ? (
        <Box className={classes.fileContainer}>
          <ImageViewer file={file} name={selectedFileReference.originalName} />
        </Box>
      ) : (
        <Stack className={classes.unsupportedFileContainer} gap={2}>
          <CircleIcon color={theme.palette.text.secondary}>
            <FileIcon />
          </CircleIcon>
          <Typography color="textSecondary" maxWidth={400}>
            <FormattedMessage
              id={messages.UNSUPPORTED_FILE_TYPE}
              values={{ fileName: trimMiddle(selectedFileReference.originalName, 40) }}
            />
          </Typography>
          <Button color="primary" onClick={() => downloadBlob(file, selectedFileReference.originalName)}>
            <Typography>
              <FormattedMessage id={messages.DOWNLOAD_FILE} />
            </Typography>
          </Button>
        </Stack>
      )}
      <Modal
        open={isDeleteAttachmentModalOpen}
        onClose={closeDeleteAttachmentModalOpen}
        title={<FormattedMessage id={messages.MODAL_DELETE_TITLE} />}
        maxWidth="xs"
        actions={
          <Stack direction="row" gap={1}>
            <Button color="primary" onClick={closeDeleteAttachmentModalOpen}>
              <FormattedMessage id={messages.MODAL_CANCEL} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onDeleteFile(selectedFileReference);
                setSelectedFileIndex(hasNextFile ? selectedFileIndex : selectedFileIndex - 1);
                closeDeleteAttachmentModalOpen();
              }}
            >
              <FormattedMessage id={messages.MODAL_CONFIRM} />
            </Button>
          </Stack>
        }
      >
        <FormattedMessage id={messages.MODAL_DELETE_BODY} />
      </Modal>
    </Stack>
  );
};

type ImageViewerProps = {
  file: Blob;
  name: string;
};

const ImageViewer: FunctionComponent<ImageViewerProps> = ({ file, name }) => {
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  return <img src={url} alt={name} width="100%" />;
};

type FileViewerProps = {
  file: Blob;
  name: string;
};

const FileViewer: FunctionComponent<FileViewerProps> = ({ file, name }) => {
  const { classes } = useStyles();
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  return <iframe src={url} title={name} className={classes.fileViewer} width="100%" height="100%" />;
};

export default InboxMessageFileViewer;
