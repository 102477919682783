import { SnackbarProps } from "@mui/material/Snackbar";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

export interface SnackbarState {
  action?: SnackbarProps["action"];
  isError?: boolean;
  autoHideDuration: number;
  message: ReactNode;
  open: boolean;
  disableAutoClose: boolean;
}

const initialState: SnackbarState = {
  message: "",
  autoHideDuration: 5000,
  open: false,
  disableAutoClose: false
};

export type ShowSnackbarPayload = Partial<SnackbarState>;
export type ShowSnackbarAction = PayloadAction<ShowSnackbarPayload>;

/**
 * A state slice for the global snackbar.
 */
const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (_state, { payload: snackbarProps }: ShowSnackbarAction) => ({
      ...initialState,
      ...snackbarProps,
      open: true
    }),
    hideSnackbar: state => ({
      ...state,
      open: false
    })
  }
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
