import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PriceListsMetadata, Supplier } from "types/api/generated/supplier";
import { FailureAction, RequestAction } from "types/redux-helpers";

interface MetadataState extends Partial<PriceListsMetadata> {
  loading: boolean;
}

const initialState: MetadataState = {
  active: false,
  loading: true,
  needsSetup: false
};

export type FetchPriceListsMetadataRequest = RequestAction<{}, Supplier.V1PricelistsMetaList.ResponseBody>;
type FetchPriceListsMetadataSuccess = PayloadAction<Supplier.V1PricelistsMetaList.ResponseBody>;

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    fetchPriceListsMetadataRequest: (state, _action: FetchPriceListsMetadataRequest) => ({
      ...state,
      loading: true
    }),
    fetchPriceListsMetadataSuccess: (state, { payload: metadata }: FetchPriceListsMetadataSuccess) => ({
      ...state,
      loading: false,
      ...metadata
    }),
    fetchPriceListsMetadataFailure: (state, _action: FailureAction) => ({
      ...state,
      loading: false
    })
  }
});

export const { fetchPriceListsMetadataRequest, fetchPriceListsMetadataSuccess, fetchPriceListsMetadataFailure } =
  metadataSlice.actions;
export default metadataSlice.reducer;
