import { useParams } from "react-router-dom";

import { CompanyRoot } from "routes/Routes";
import { Permission } from "types/api";
import useAppSelector from "utils/hooks/useAppSelector";

/**
 * Returns a boolean indicating whether the user has the given permissions.
 *
 * This should only be called within the CompanyRouter because it blocks rendering while the permissions are loading.
 * Otherwise parts of the UI will show/hide or disable/enable when the permissions load in.
 */
export default function useHasPermission(requiredPermissions?: Permission[]): boolean {
  const { tenancyId: tenancyIdFromUrl } = useParams<typeof CompanyRoot.params>();
  const { permissions } = useAppSelector(state => state.navigationData);
  const { companyId: tenancyIdFromState } = useAppSelector(state => state.tenancy);

  // Not all urls, such as invites have a tenant id in them so fallback to state.
  const tenancyId = tenancyIdFromUrl || tenancyIdFromState;

  // If no permissions are passed, assume no permission check is required (this is for convenience)
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }

  // If the `useHasPermission()` is called outside of a company, it's impossible to have permissions.
  // (but allow it in the tests for convenience)
  if (!tenancyId && process.env.NODE_ENV !== "test") {
    return false;
  }

  return requiredPermissions.every(permission => permissions.permissions?.includes(permission));
}
