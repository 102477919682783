import { datadogRum } from "@datadog/browser-rum";

import { FetchError } from "services/fetch";
import { FetchV2Error } from "services/fetchV2";
import { ValidationErrorCodes } from "types/api";
import { ApiValidationProblem } from "types/api/generated/supplier";

import { logger } from "./Datadog";

/**
 * Type guard for narrowing some value to the API's `ApiValidationProblem` type.
 */
export function isApiValidationProblem(error: unknown): error is ApiValidationProblem {
  return !!error && typeof error === "object" && "type" in error && "status" in error && "errors" in error;
}

/**
 * Shorthand for calling {@link #isApiValidationProblem} followed up with a
 * check for an error to contain any one of a list of validation error codes.
 */
export function isApiValidationProblemWithErrorCode(
  error: unknown,
  ...validationErrorCodes: ValidationErrorCodes[]
): error is ApiValidationProblem {
  return (
    isApiValidationProblem(error) &&
    // error.code will be a ValidationErrorCodes type, but they're not real
    // enums over on the API side, so we have to cast
    error.errors.some(err => validationErrorCodes.includes(err.code as ValidationErrorCodes))
  );
}

/**
 * Shorthand for calling {@link #isApiValidationProblemWithErrorCode} followed up with a
 * check for an error to contain any to contain the expected property.
 */
export function isApiValidationProblemWithErrorAndProperty(
  error: unknown,
  validationErrorCode: ValidationErrorCodes,
  property: string
): error is ApiValidationProblem {
  return (
    isApiValidationProblem(error) &&
    error.errors.some(err => err.code === validationErrorCode && err.property === property)
  );
}

/**
 * Type guard for narrowing some value to a JavaScript `Error` type.
 */
export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

/**
 * Type guard for narrowing some value to our `FetchError` type.
 */
export function isFetchError(error: unknown): error is FetchError {
  return error instanceof FetchError;
}

interface Context {
  message?: string;
  [key: string]: string | number | boolean | undefined;
}

export function trackError(error: any, message?: string, context?: Context): void {
  const data: Context = {
    message: message || error?.message || error?.errors?.[0]?.reason || error?.title,
    ...context
  };

  if (error instanceof FetchError) {
    data.url = error.url;
    data.method = error.method;
    data.status = error.status;
    data.statusText = error.statusText;
    data.code = error.code;
  } else if (error instanceof FetchV2Error) {
    data.endpoint = error.endpoint;
    data.url = error.url;
    data.method = error.method;
    data.status = error.status;
    data.statusText = error.statusText;
    data.code = error.code;
  }

  let trackedError: Error;
  if (error instanceof Error) {
    trackedError = error;
  } else {
    // Convert any non-Errors to Error objects so they have a stack trace (and because Datadog RUM only accepts Error objects).
    // This most commonly happens with API 400 errors, where we throw the JSON response body as the error.
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
    trackedError = new Error(data.message || "Unknown error");
    data.originalError = error;
  }

  logger.error(
    data.message || "Unknown error",
    {
      error: trackedError,
      ...data
    },
    trackedError
  );
  datadogRum.addError(trackedError, data);
}

/**
 * A helper function for statically asserting that a case is handled.
 *
 * Typescript will generate a compile-time error if this function is called with a value that is not `never`. This is an
 * indication that a case of a union is unhandled.
 */
export function throwUnhandledCase(value: never): never {
  throw new Error(`Unhandled case: ${value}`);
}
