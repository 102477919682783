import { createAction } from "@reduxjs/toolkit";

import { Image } from "types/api/generated/global-internal";
import { ApiValidationProblem } from "types/api/generated/supplier";
import { RequestPayload, withPayloadType } from "types/redux-helpers";

export type UploadOrderAttachmentRequest = RequestPayload<{ attachment: File }, Image>;

export const uploadOrderAttachmentRequest = createAction(
  "orders/uploadOrderAttachmentRequest",
  withPayloadType<UploadOrderAttachmentRequest>()
);
export const uploadOrderAttachmentSuccess = createAction(
  "orders/uploadOrderAttachmentSuccess",
  withPayloadType<Image>()
);
export const uploadOrderAttachmentFailure = createAction(
  "orders/uploadOrderAttachmentFailure",
  withPayloadType<ApiValidationProblem | Error>()
);
