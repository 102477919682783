/**
 * We're using this file to guarantee the plugins we need have been loaded at their
 * first opportunity, we should use this file when using dayjs
 * */
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezonePlugin from "dayjs/plugin/duration";
import durationPlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);

export { Dayjs };
export default dayjs;
