import Snackbar from "@mui/material/Snackbar";
import { FunctionComponent, useContext } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { FabContext } from "components/Fab";
import useMobileNav from "mobile/useMobileNav";
import { hideSnackbar } from "redux/reducers/snackbar";
import useAppSelector from "utils/hooks/useAppSelector";

const useStyles = makeStyles<{ mobileNavRendered: boolean; sharedWithFab: boolean }>()(
  (theme, { mobileNavRendered, sharedWithFab }) => ({
    bar: {
      [theme.breakpoints.down("xs")]: {
        bottom: `calc(${
          sharedWithFab ? theme.spacing(mobileNavRendered ? 21 : 14) : theme.spacing(mobileNavRendered ? 8 : 1)
        }px + env(safe-area-inset-bottom))`
      },
      "& a, & button": {
        color: theme.palette.common.white,
        fontSize: "inherit",
        textDecoration: "underline",
        "&:hover": {
          textDecoration: "underline"
        }
      }
    },
    content: {
      borderRadius: theme.shape.borderRadius,
      whiteSpace: "pre-wrap"
    },
    standard: {
      backgroundColor: `${theme.palette.secondary.main} !important`
    },
    error: {
      backgroundColor: `${theme.palette.error.main} !important`
    }
  })
);

/**
 * The app-wide snackbar, this component is always present and can be opened
 * from elsewhere by dispatching the `showSnackbar` redux action.
 */
const GlobalSnackbar: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { action, isError, autoHideDuration, message, open, disableAutoClose } = useAppSelector(
    state => state.snackbar
  );
  const { renderedFabCount } = useContext(FabContext);
  const { mobileNavRendered } = useMobileNav();
  const { classes, cx } = useStyles({ mobileNavRendered, sharedWithFab: renderedFabCount > 0 });

  const closeSnackbar = (): void => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      message={message}
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      autoHideDuration={disableAutoClose ? null : autoHideDuration}
      action={action}
      onClose={disableAutoClose ? undefined : closeSnackbar}
      className={cx(classes.bar)}
      ContentProps={{
        className: cx(classes.content, {
          [classes.standard]: !isError,
          [classes.error]: isError
        })
      }}
      data-testid="snackbar-global"
    />
  );
};

export default GlobalSnackbar;
