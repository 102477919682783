import { Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { useCallback, useState } from "react";

import Button from "components/Button";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "components/Dialog";
import useMessages from "i18n/hooks/useMessages";
import { IdentifierSource } from "types/api/generated/supplier";
import { LocalInvalidOrderLine } from "types/Orders";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";

import useRemoveInvalidOrderLine from "../queries/useRemoveInvalidOrderLine";

import strings from "./RemoveInvalidLineModal.strings.json";

interface RemoveInvalidLineModalProps extends Pick<DialogProps, "open"> {
  onClose?: () => void;
  orderId: number;
  externalProvider?: IdentifierSource;
  invalidLine?: LocalInvalidOrderLine;
}

/**
 * Confirmation modal shown before removing an invalid order line.
 */
export default function RemoveInvalidLineModal({
  onClose,
  invalidLine,
  open,
  orderId,
  externalProvider
}: RemoveInvalidLineModalProps): JSX.Element {
  const messages = useMessages(strings);
  const [removingInvalidLine, setRemovingInvalidLine] = useState(false);
  const { showErrorSnackbar } = useErrorSnackbar();
  const { mutateAsync: removeInvalidOrderLine } = useRemoveInvalidOrderLine();

  const handleRemoveInvalidLine = useCallback(async () => {
    if (invalidLine) {
      try {
        setRemovingInvalidLine(true);
        await removeInvalidOrderLine({ orderId, lineId: invalidLine.lineId });
      } catch (error) {
        showErrorSnackbar(error, <FormattedMessage id={messages.ERROR} />);
      } finally {
        setRemovingInvalidLine(false);
      }
      onClose?.();
    }
  }, [invalidLine, messages.ERROR, onClose, orderId, removeInvalidOrderLine, showErrorSnackbar]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id={messages.TITLE} />
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          <FormattedMessage id={messages.CONTENT} values={{ externalProvider }} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={removingInvalidLine} onClick={onClose}>
          <FormattedMessage id={messages.ACTION_CANCEL} />
        </Button>
        <Button color="primary" showLoader={removingInvalidLine} variant="contained" onClick={handleRemoveInvalidLine}>
          <FormattedMessage id={messages.ACTION_REMOVE} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
