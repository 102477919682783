import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";
import { companyFlags } from "utils/flags";
import { buildSearchString } from "utils/urls";

import {
  fetchNavigationDataFailure,
  FetchNavigationDataRequest,
  fetchNavigationDataSuccess
} from "../reducers/navigationData";

/**
 * Fetches a bunch of common data used on every page load like the company profile,
 * user's companies, user's permissions and getting started progress.
 */
export default createSagaNext<FetchNavigationDataRequest>(
  (auth0, tenancyId, { companyId, menuOverride }) => {
    const urlWithDomain = `${process.env.REACT_APP_API_URL}/global/internal/navigation${buildSearchString({
      menuOverride,
      feature: companyFlags
    })}`;
    return fetchDataFromApiWrapper(auth0, urlWithDomain, tenancyId || companyId);
  },
  {
    successAction: fetchNavigationDataSuccess,
    failureAction: fetchNavigationDataFailure
  }
);
