import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

import {
  fetchPendingInvitesFailure,
  FetchPendingInvitesRequest,
  FetchPendingInvitesSuccess,
  fetchPendingInvitesSuccess
} from "../reducers/pendingInvites";

/**
 * Saga to call `GET /buyer/v1/suppliers/pending/invites` to get the supplier
 * invites still pending for the buyer
 */
export default createSagaNext<FetchPendingInvitesRequest, FetchPendingInvitesSuccess["payload"]>(
  (auth0, tenancyId) => {
    return fetchDataFromApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/pending/invites`,
      tenancyId
    );
  },
  {
    successAction: fetchPendingInvitesSuccess,
    failureAction: fetchPendingInvitesFailure
  }
);
