import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { DirectoryInternal } from "types/api";
import useTenancyId from "utils/hooks/useTenancyId";

type RequestQuery = DirectoryInternal.InternalGettingStartedCreate.RequestQuery;
type RequestBody = DirectoryInternal.InternalGettingStartedCreate.RequestBody;
export type ResponseBody = DirectoryInternal.InternalGettingStartedCreate.ResponseBody;

export type CompleteGettingStartedRequest = RequestBody & {
  // This can be called in situations where the tenancyId isn't in the URL yet,
  // which apiFetch relies on, so allow calls to define their own.
  tenancyId?: string;
};

export const completeGettingStartedActionOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody>(
  () => ({
    method: "POST",
    path: "/directory/internal/getting-started"
  })
);

/**
 * React query hook to mark a getting started action as complete.
 */
export default function useCompleteGettingStartedAction(): UseMutationResult<
  ResponseBody,
  FetchV2Error,
  CompleteGettingStartedRequest
> {
  const apiFetch = useApiFetch();
  const tenancyIdFromUrl = useTenancyId();

  return useMutation({
    async mutationFn({ tenancyId, ...request }) {
      const response = await apiFetch(
        completeGettingStartedActionOptions(undefined, {
          data: request,
          tenancyId: tenancyIdFromUrl ?? tenancyId
        })
      );
      return response.data;
    }
  });
}
