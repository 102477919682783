import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchNavigationDataSuccess } from "redux/reducers/navigationData";

export type FeatureStatus = "On" | "Off" | "Loading";

interface Features {
  [feature: string]: string | FeatureStatus | undefined;
}

export interface FeaturesState {
  error: Error | null;
  [key: string]: Features | undefined | Error | null;
}

const initialState: FeaturesState = {
  error: null
};

export type FetchFeatureRequest = PayloadAction<{
  key: string | null | undefined;
  features: string[];
  successCallback?: (features: Features) => void;
}>;

export type FetchFeatureSuccess = PayloadAction<{
  key: string;
  features: Features;
}>;
/**
 * Reducer for feature flags, this state slice is grouped by key then the name
 * of the feature and its associated value.
 */
const featuresSlice = createSlice({
  name: "features",
  initialState,
  reducers: {
    fetch: (state, { payload: { key, features } }: FetchFeatureRequest) => ({
      ...state,
      // Redux would serialize our null keys as "null"
      [key ?? "null"]: {
        ...state[key ?? "null"],
        ...features.reduce<Features>((acc, feature) => {
          acc[feature] = "Loading";
          return acc;
        }, {})
      }
    }),
    fetchSuccess: (state, { payload: { key, features } }: FetchFeatureSuccess) => ({
      ...state,
      [key]: {
        ...state[key],
        ...features
      },
      error: null
    }),
    fetchFailure: (state, { payload: error }) => ({
      ...state,
      error
    })
  },
  extraReducers: builder => {
    // Populate the features state from the navigationData. This allows us to remove the request
    // for fetching the company feature flags on page load.
    builder.addCase(fetchNavigationDataSuccess, (state, action) => ({
      ...state,
      [action.payload.companyProfile.companyId]: action.payload.features
    }));
  }
});

export const { fetch, fetchSuccess, fetchFailure } = featuresSlice.actions;
export default featuresSlice.reducer;
