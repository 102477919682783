import {
  RegisterDevicePushNotificationRequest,
  registerDevicePushNotificationFailure,
  registerDevicePushNotificationSuccess
} from "redux/reducers/pushNotifications";
import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

export default createSagaNext<RegisterDevicePushNotificationRequest>(
  (auth0, tenancyId, payload) => {
    return postDataToApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/directory/internal/users/notifications/push/register`,
      tenancyId,
      payload
    );
  },
  {
    successAction: registerDevicePushNotificationSuccess,
    failureAction: registerDevicePushNotificationFailure
  }
);
