import { AppBar, CircularProgress, Container, Toolbar } from "@mui/material";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  main: {
    backgroundColor: theme.palette.grey[100]
  },
  appBar: {
    backgroundColor: theme.palette.common.white
  },
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 64px)",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 48px)"
    }
  },
  circularProgress: {
    color: theme.palette.grey[400]
  }
}));

const LoadingScreen: FunctionComponent = () => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.main)}>
      <AppBar position="static" className={cx(classes.appBar)}>
        <Toolbar />
      </AppBar>
      <Container className={cx(classes.container)} maxWidth="md" data-testid="loading-screen-unbranded">
        <CircularProgress className={cx(classes.circularProgress)} />
      </Container>
    </div>
  );
};

export default LoadingScreen;
