import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { saveProviderProductMatchSuccess } from "features/suppliers/match-products/reducers/products";
import {
  ApiValidationProblem,
  BooleanUpdateResult,
  DateTimeUpdateResult,
  DeliveryAddress,
  OrderComment,
  OrderDetails,
  OrderError,
  OrderExternalDocument,
  OrderLine,
  OrderSource,
  OrderStatus,
  OrderTimeline,
  StringUpdateResult,
  Supplier,
  SupplierViewOrder
} from "types/api/generated/supplier";
import { OrderErrorType } from "types/api/generated/supplier-internal";
import { FailureAction, RequestAction } from "types/redux-helpers";

import { dismissErrorNotificationSuccess } from "../actions/dismissErrorNotification";

export interface SupplierOrderByIdState extends Omit<SupplierViewOrder, "orderDetails">, OrderDetails {
  loading: boolean;
  loadingOrder: boolean;
  loadingTimeline: boolean;
  updatingStatus: boolean;
  error?: ApiValidationProblem | Error | null;
  events: OrderTimeline[];
  invoicePdfBlob?: Blob;
  removingItem?: boolean;
  updatingItems?: boolean;
  eTag: string | null;
  isEditing: boolean;
  address: DeliveryAddress | null;
}

const initialState: SupplierOrderByIdState = {
  orderId: 0,
  orderStatus: OrderStatus.Ordered,
  buyerName: "",
  buyerId: "",
  createdDateUtc: "",
  deliveryDateUtc: "",
  deliveryAddress: "",
  lines: [],
  invalidLines: [],
  referenceNumber: "",
  trackingNumber: "",
  notes: "",
  events: [],
  loading: true,
  loadingOrder: true,
  loadingTimeline: true,
  updatingStatus: false,
  printed: false,
  buyerIsClaimed: false,
  hasCustomPrices: false,
  orderErrors: [],
  error: null,
  source: OrderSource.Supplier,
  workflow: {
    availableActions: [],
    transitions: [],
    progress: [
      {
        isComplete: false,
        status: OrderStatus.Ordered,
        actionDateUtc: ""
      },
      {
        isComplete: false,
        status: OrderStatus.Processing,
        actionDateUtc: ""
      },
      {
        isComplete: false,
        status: OrderStatus.Shipped,
        actionDateUtc: ""
      },
      {
        isComplete: false,
        status: OrderStatus.Delivered,
        actionDateUtc: ""
      }
    ]
  },
  buyerMetadata: { externalReferences: [] },
  supplierIsClaimed: false,
  totalComments: 0,
  documents: [],
  invoiceIsConsolidated: false,
  removingItem: false,
  updatingItems: false,
  eTag: null,
  isEditing: false,
  address: null,
  orderIssues: [],
  files: []
};

export type OrderId = { orderId: number };
type ETagResponse = { eTag: string | null };

interface LoadSupplierOrderByIdResponse extends ETagResponse {
  order: SupplierViewOrder;
}
export type LoadSupplierOrderByIdRequest = RequestAction<OrderId, LoadSupplierOrderByIdResponse>;
export type LoadSupplierOrderByIdSuccess = PayloadAction<LoadSupplierOrderByIdResponse>;

type CancelOrderSuccessPayload = OrderId;
export type CancelAndHoldStandingOrderSuccess = PayloadAction<CancelOrderSuccessPayload>;

export type UpdateOrderNoteSuccess = PayloadAction<OrderId & { message: StringUpdateResult }>;

type UpdateDeliveryAddressSuccess = PayloadAction<Supplier.V1OrdersAddressUpdate.ResponseBody>;

type SelectDeliveryDate = PayloadAction<{ deliveryDateUtc: string }>;

type UpdateOrderDueDateSuccess = PayloadAction<DateTimeUpdateResult>;

type MarkOrderAsPrintedSuccess = PayloadAction<BooleanUpdateResult>;

type UpdateSupplierOrderStatusResponse = OrderId & { newStatus: OrderStatus; comment?: OrderComment | null };
export type UpdateSupplierOrderStatusSuccess = PayloadAction<UpdateSupplierOrderStatusResponse>;
export type UpdateSupplierOrderDeliveryGroup = PayloadAction<SupplierOrderByIdState["deliveryGroupSummary"]>;

type SetDocument = PayloadAction<OrderExternalDocument>;
type SetOrderErrors = PayloadAction<OrderError[]>;
type SetIsEditing = PayloadAction<boolean>;
const supplierOrderByIdSlice = createSlice({
  name: "supplierOrderById",
  initialState,
  reducers: {
    loadSupplierOrderByIdRequest: (state, _payload: LoadSupplierOrderByIdRequest) => ({
      ...state,
      workflow: initialState.workflow,
      orderStatus: initialState.orderStatus,
      loading: true,
      loadingOrder: true
    }),
    loadSupplierOrderByIdSuccess: (
      state,
      {
        payload: {
          order: { orderDetails, ...rest },
          eTag
        }
      }: LoadSupplierOrderByIdSuccess
    ) => ({
      ...state,
      ...orderDetails,
      ...rest,
      eTag,
      isClaimed: false,
      loading: false,
      loadingOrder: false,
      // Disable editing mode if the order is refetched
      isEditing: false
    }),
    loadSupplierOrderByIdFailure: (_state, { payload: error }: FailureAction) => ({
      ...initialState,
      loading: false,
      loadingOrder: false,
      error
    }),
    cancelAndHoldStandingOrderSuccess: (state, _action: CancelAndHoldStandingOrderSuccess) => ({
      ...state,
      updatingStatus: false,
      orderStatus: OrderStatus.Cancelled,
      // Disable editing mode if the order status is changed
      isEditing: false
    }),
    updateOrderNoteSuccess: (state, { payload: { message } }: UpdateOrderNoteSuccess) => ({
      ...state,
      notes: message.result
    }),
    updateOrderReferenceSuccess: (state, { payload: referenceNumber }) => ({
      ...state,
      referenceNumber
    }),
    updateOrderTrackingNumberSuccess: (state, { payload: trackingNumber }) => ({
      ...state,
      trackingNumber
    }),
    updateDeliveryAddressSuccess: (state, { payload }: UpdateDeliveryAddressSuccess) => ({
      ...state,
      address: payload.deliveryAddress
    }),
    selectDeliveryDate: (state, { payload: { deliveryDateUtc } }: SelectDeliveryDate) => ({
      ...state,
      deliveryDateUtc
    }),
    updateOrderDueDateSuccess: (state, { payload: { result } }: UpdateOrderDueDateSuccess) => ({
      ...state,
      deliveryDateUtc: result
    }),
    clearOrder: () => initialState,
    markOrderAsPrintedSuccess: (state, { payload: printed }: MarkOrderAsPrintedSuccess) => ({
      ...state,
      printed: printed.result
    }),
    updateSupplierOrderStatusSuccess: (state, { payload: { newStatus } }: UpdateSupplierOrderStatusSuccess) => ({
      ...state,
      orderStatus: newStatus,
      updatingStatus: false,
      workflow: initialState.workflow,
      // Disable editing mode if the order status is changed
      isEditing: false
    }),
    updateSupplierOrderDeliveryGroup: (state, { payload }: UpdateSupplierOrderDeliveryGroup) => ({
      ...state,
      deliveryGroupSummary: payload
    }),
    clearDocument: state => ({
      ...state,
      documents: [],
      orderErrors: state.orderErrors.filter(
        error =>
          error.providerError.errorType !== OrderErrorType.Invoice &&
          error.providerError.errorType !== OrderErrorType.CreateExternalOrder
      )
    }),
    setDocument: (state, { payload: document }: SetDocument) => ({
      ...state,
      documents: [document],
      orderErrors: state.orderErrors.filter(
        error =>
          error.providerError.errorType !== OrderErrorType.Invoice &&
          error.providerError.errorType !== OrderErrorType.CreateExternalOrder
      )
    }),
    setDocumentErrors: (state, { payload: orderErrors }: SetOrderErrors) => ({
      ...state,
      orderErrors: state.orderErrors
        .filter(
          error =>
            error.providerError.errorType !== OrderErrorType.Invoice &&
            error.providerError.errorType !== OrderErrorType.CreateExternalOrder
        )
        .concat(orderErrors)
    }),
    setIsEditing: (state, { payload: isEditing }: SetIsEditing) => ({
      ...state,
      isEditing
    }),
    updatePricingSource: (state, { payload: lineItems }: PayloadAction<OrderLine[]>) => ({
      ...state,
      hasCustomPrices: lineItems.some(line => line.hasCustomUnitAmount),
      priceListName:
        state.currentPriceListName &&
        lineItems.every(lineItem => lineItem.unitAmount?.amount === lineItem.priceListUnitAmount?.amount)
          ? state.currentPriceListName
          : state.priceListName
    })
  },
  extraReducers: builder => {
    builder
      .addCase(saveProviderProductMatchSuccess, (state, { payload: { order, eTag } }) => ({
        ...state,
        ...order,
        ...order.orderDetails,
        eTag,
        isClaimed: false,
        loading: false
      }))
      .addCase(dismissErrorNotificationSuccess, (state, { payload }) => ({
        ...state,
        orderErrors: state.orderErrors.filter(it => it.notificationId !== payload.notificationId)
      }));
  }
});

export const {
  loadSupplierOrderByIdRequest,
  loadSupplierOrderByIdSuccess,
  loadSupplierOrderByIdFailure,
  updateOrderNoteSuccess,
  updateOrderReferenceSuccess,
  updateOrderTrackingNumberSuccess,
  updateDeliveryAddressSuccess,
  selectDeliveryDate,
  updateOrderDueDateSuccess,
  clearOrder,
  markOrderAsPrintedSuccess,
  updateSupplierOrderStatusSuccess,
  updateSupplierOrderDeliveryGroup,
  clearDocument,
  setDocument,
  setDocumentErrors,
  cancelAndHoldStandingOrderSuccess,
  setIsEditing,
  updatePricingSource
} = supplierOrderByIdSlice.actions;

export default supplierOrderByIdSlice.reducer;
