import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { forwardRef, JSX, Ref } from "react";
import { makeStyles } from "tss-react/mui";

import LocalLinkOrExternalLink, { LocalLinkOrExternalLinkProps } from "components/LocalLinkOrExternalLink";

interface MenuItemLinkProps extends Pick<MenuItemProps, "disabled" | "onClick">, LocalLinkOrExternalLinkProps {}

const useStyles = makeStyles()({
  menuItem: {
    padding: 0
  },
  link: {
    color: "inherit",
    display: "flex",
    padding: "6px 16px",
    textDecoration: "none",
    width: "100%",
    "&:hover, &:focus": {
      textDecoration: "none"
    }
  }
});

/**
 * A combined Material UI `<MenuItem>` that renders either a react-router
 * `<Link>` for local links, or a Material UI `<Link>` for absolute URLs, so
 * that the list item can contain standard links.
 */
const MenuItemLink = forwardRef(function MenuItemLinkInner(
  { children, className, disabled, ...linkProps }: MenuItemLinkProps,
  ref: Ref<HTMLLIElement>
): JSX.Element {
  const { classes, cx } = useStyles();

  return (
    <MenuItem ref={ref} disabled={disabled} className={classes.menuItem}>
      <LocalLinkOrExternalLink className={cx(classes.link, className)} {...linkProps}>
        {children}
      </LocalLinkOrExternalLink>
    </MenuItem>
  );
});

export default MenuItemLink;
