import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer } from "types/api";
import { BuyerProductSummary } from "types/api/generated/buyer";
import { ApiValidationProblem } from "types/api/generated/supplier-internal";
import { FailureAction, RequestAction } from "types/redux-helpers";

export interface FavouritesListState {
  listId: number | null;
  name: string;
  products: BuyerProductSummary[];
  filteredProducts: BuyerProductSummary[];

  loading: boolean;
  error?: ApiValidationProblem | Error;
}

const initialState: FavouritesListState = {
  loading: false,
  listId: null,
  name: "",
  products: [],
  filteredProducts: []
};

const beginsWith = (product: BuyerProductSummary, string: string): boolean =>
  product.productName.toLowerCase().startsWith(string) ||
  product.productCode.toLowerCase().startsWith(string) ||
  product.supplierName?.toLowerCase().startsWith(string);

const includesString = (product: BuyerProductSummary, string: string): boolean =>
  product.productName.toLowerCase().includes(string) ||
  product.productCode.toLowerCase().includes(string) ||
  product.supplierName?.toLowerCase().includes(string);

const filterMethod = (product: BuyerProductSummary, string: string): boolean =>
  string.length === 1 ? beginsWith(product, string) : includesString(product, string);

type ListId = { listId: number };
type Product = { products: [BuyerProductSummary] };
type RefreshLists = { refreshLists?: boolean };

export type DeleteFavouritesListRequest = RequestAction<Buyer.V1FavouritesDelete.RequestParams, ListId>;
type DeleteFavouritesListSuccess = PayloadAction<ListId>;

export type FetchFavouritesListRequest = RequestAction<
  Buyer.V1FavouritesDetail.RequestParams,
  Buyer.V1FavouritesDetail.ResponseBody
>;
export type FetchFavouritesListSuccess = PayloadAction<Buyer.V1FavouritesDetail.ResponseBody>;

export type EditFavouritesListRequest = RequestAction<
  Buyer.V1FavouritesCreate2.RequestParams & { name: string },
  string
>;
type EditFavouritesListSuccess = PayloadAction<string>;

export type CreateFavouritesListRequest = RequestAction<
  Omit<Buyer.V1FavouritesCreate.RequestBody, "products"> & { products?: BuyerProductSummary[] },
  Buyer.V1FavouritesCreate.ResponseBody
>;
type CreateFavouritesListSuccess = PayloadAction<Buyer.V1FavouritesCreate.ResponseBody>;

export type SearchOverListProducts = PayloadAction<string | null | undefined>;
export type SetFavouriteListId = PayloadAction<number>;

export type AddProductToFavouritesListRequest = RequestAction<
  Buyer.V1FavouritesProductsCreate.RequestParams & Product & RefreshLists,
  RefreshLists,
  ListId & Product
>;
export type AddProductToFavouritesListSuccess = PayloadAction<RefreshLists>;
export type AddProductToFavouritesListFailure = PayloadAction<ListId & Product>;

export type RemoveProductFromFavouritesListRequest = RequestAction<
  Buyer.V1FavouritesProductsDelete.RequestParams & Product & RefreshLists,
  RefreshLists
>;
export type RemoveProductFromFavouritesListSuccess = PayloadAction<RefreshLists>;

const favouritesListSlice = createSlice({
  name: "favouritesList",
  initialState,
  reducers: {
    deleteFavouritesListRequest: (state, { payload }: DeleteFavouritesListRequest) => ({
      ...state,
      ...payload
    }),
    deleteFavouritesListSuccess: (state, _action: DeleteFavouritesListSuccess) => ({
      ...state,
      loading: true
    }),
    deleteFavouritesListFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error
    }),
    fetchFavouritesListRequest: (_state, _action: FetchFavouritesListRequest) => ({ ...initialState, loading: true }),
    fetchFavouritesListSuccess: (state, { payload }: FetchFavouritesListSuccess) => ({
      ...state,
      ...payload,
      loading: false,
      filteredProducts: payload.products
    }),
    fetchFavouritesListFailure: (state, { payload: error }) => ({
      ...state,
      loading: false,
      error
    }),
    editFavouritesListRequest: (state, _action: EditFavouritesListRequest) => ({
      ...state
    }),
    editFavouritesListSuccess: (state, { payload: name }: EditFavouritesListSuccess) => ({
      ...state,
      name
    }),
    editFavouritesListFailure: (state, { payload: error }) => ({
      ...state,
      error
    }),
    createFavouritesListRequest: (state, { payload: { name, products } }: CreateFavouritesListRequest) => ({
      ...state,
      name,
      products:
        products?.map(product => ({
          ...product,
          isAFavourite: true
        })) ?? [],
      loading: true
    }),
    createFavouritesListSuccess: (state, { payload }: CreateFavouritesListSuccess) => ({
      ...state,
      ...payload,
      filteredProducts: [],
      loading: false
    }),
    createFavouritesListFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      loading: false,
      error
    }),
    searchOverFavouritesListProducts: (state, { payload: searchString }: SearchOverListProducts) => {
      if (!searchString) {
        return {
          ...state,
          filteredProducts: state.products
        };
      }

      const filteredProducts = state.products.filter(product =>
        filterMethod(product, searchString?.toLowerCase() ?? "")
      );
      return {
        ...state,
        filteredProducts,
        loading: false
      };
    },
    clearFavouritesList: () => ({ ...initialState }),
    setFavouritesListId: (state, { payload: listId }: SetFavouriteListId) => ({
      ...state,
      listId
    }),
    addProductToFavouritesListRequest: (state, { payload }: AddProductToFavouritesListRequest) => {
      const {
        listId,
        products: [product]
      } = payload;
      if (state.listId === listId) {
        return {
          ...state,
          products: [...state.products, { ...product, isAFavourite: true }],
          filteredProducts: [...state.filteredProducts, { ...product, isAFavourite: true }]
        };
      }
      return state;
    },
    addProductToFavouritesListSuccess: (state, _action: AddProductToFavouritesListSuccess) => state,
    addProductToFavouritesListFailure: (state, { payload }: AddProductToFavouritesListFailure) => {
      const {
        listId,
        products: [product]
      } = payload;
      if (state.listId === listId) {
        return {
          ...state,
          products: state.products.filter(p => p.productId !== product.productId),
          filteredProducts: state.filteredProducts.filter(p => p.productId !== product.productId)
        };
      }
      return state;
    },
    removeProductFromFavouritesListRequest: (state, { payload }: RemoveProductFromFavouritesListRequest) => {
      const {
        listId,
        products: [product]
      } = payload;
      if (state.listId === listId) {
        return {
          ...state,
          products: state.products.filter(p => p.productId !== product.productId),
          filteredProducts: state.filteredProducts.filter(p => p.productId !== product.productId)
        };
      }
      return state;
    },
    removeProductFromFavouritesListSuccess: (state, _action: RemoveProductFromFavouritesListSuccess) => state,
    removeProductFromFavouritesListFailure: (state, _action: FailureAction) => state
  }
});

export const {
  deleteFavouritesListRequest,
  deleteFavouritesListSuccess,
  deleteFavouritesListFailure,
  fetchFavouritesListRequest,
  fetchFavouritesListSuccess,
  fetchFavouritesListFailure,
  editFavouritesListRequest,
  editFavouritesListSuccess,
  editFavouritesListFailure,
  createFavouritesListRequest,
  createFavouritesListSuccess,
  createFavouritesListFailure,
  searchOverFavouritesListProducts,
  clearFavouritesList,
  setFavouritesListId,
  addProductToFavouritesListRequest,
  addProductToFavouritesListSuccess,
  addProductToFavouritesListFailure,
  removeProductFromFavouritesListRequest,
  removeProductFromFavouritesListSuccess,
  removeProductFromFavouritesListFailure
} = favouritesListSlice.actions;

export default favouritesListSlice.reducer;
