import { createSlice } from "@reduxjs/toolkit";

import { Permission } from "types/api";
import { ApiValidationProblem } from "types/api/generated/supplier";

export interface PermissionsState {
  companyId: string | null;
  error: ApiValidationProblem | null;
  list: Permission[];
  loading: boolean;
}

const initialState: PermissionsState = {
  list: [],
  error: null,
  companyId: null,
  loading: false
};

/**
 * Reducer for the Permissions slice.
 */
const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    fetchRequest: () => ({
      ...initialState,
      loading: true
    }),
    fetchSuccess: (state, { payload }) => ({
      ...state,
      list: payload.permissions,
      companyId: payload.companyId,
      loading: false
    }),
    fetchFailure: (state, { payload: error }) => ({
      ...state,
      loading: false,
      error: error.message
    })
  }
});

export const { fetchRequest, fetchSuccess, fetchFailure } = permissionsSlice.actions;
export default permissionsSlice.reducer;
