import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
// import ReduxLogger from "redux-logger"; // Uncomment this if you wish to see actions in the console.
import createSagaMiddleware from "redux-saga";

import { UnauthenticatedError } from "routes/Routes";
import { trackError } from "utils/Errors";
import history from "utils/history";

import { createReducer } from "./reducers";

export const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    trackError(error, `An error occurred in our sagas: ${error.toString()}`);
    history.push(UnauthenticatedError.toUrl());
  }
});

export default configureStore({
  reducer: createReducer(),
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false
    }).concat([
      sagaMiddleware
      // ReduxLogger
    ]);
  },
  enhancers: [
    createInjectorsEnhancer({
      createReducer,
      runSaga: sagaMiddleware.run
    })
  ]
});
