import { GettingStartedAction, GettingStartedActionType } from "types/api/generated/directory-internal";

/**
 * The collection of known Getting Started actions, and their respective UI
 * elements.  The order in this object is the order that the items are displayed
 * in the UI.
 */
export const Actions = [
  GettingStartedActionType.AddSupplier,
  GettingStartedActionType.AddFavourites,
  GettingStartedActionType.BuyerFirstOrder,
  GettingStartedActionType.InviteUsers,
  GettingStartedActionType.CheckProfile
];

/**
 * Filter getting started actions down to only those that are in incomplete and
 * in the {@link #Actions} list.
 */
export function remainingGettingStartedActions(actions: GettingStartedAction[]): GettingStartedAction[] {
  return actions.filter(action => Actions.includes(action.actionType) && !action.isCompleted);
}
