import { PopoverOrigin } from "@mui/material/Popover";
import { forwardRef, MouseEvent, MouseEventHandler, ReactNode, useCallback, useMemo, useState } from "react";

import Menu, { MenuProps } from "./Menu";

export type TriggerProps = {
  /**
   * The same value as the `id` prop.
   */
  "aria-controls": string;

  "aria-haspopup": "true";

  /**
   * Opens the menu part of the component.
   */
  onClick: MouseEventHandler;
};

export interface DropDownMenuProps extends MenuProps {
  id: string;

  /**
   * A function that is passed an object of trigger props to spread over a
   * trigger component of your choice.
   */
  trigger: (props: TriggerProps, open: boolean) => ReactNode;
}

const anchorOrigin: PopoverOrigin = {
  horizontal: "left",
  vertical: "bottom"
};
const transformOrigin: PopoverOrigin = {
  horizontal: "left",
  vertical: "top"
};

/**
 * A combination of a trigger component (usually a button) and a responsive menu
 * that becomes a bottom drawer component at smaller screen sizes.  Takes care
 * of managing the open state of the menu.
 */
const DropDownMenu = forwardRef<HTMLDivElement, DropDownMenuProps>(({ children, id, trigger, ...rest }, ref) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const openMenu = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const toggleMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      if (anchorEl) {
        closeMenu(event);
      } else {
        openMenu(event);
      }
    },
    [anchorEl]
  );

  const triggerProps: TriggerProps = useMemo(
    () => ({
      "aria-controls": id,
      "aria-haspopup": "true",
      onClick: toggleMenu
    }),
    [id, toggleMenu]
  );

  const anchorProps = useMemo(() => {
    return !rest.anchorOrigin && !rest.transformOrigin
      ? {
          anchorOrigin,
          transformOrigin
        }
      : undefined;
  }, [rest.anchorOrigin, rest.transformOrigin]);

  return (
    <>
      {trigger(triggerProps, !!anchorEl)}
      <Menu id={id} ref={ref} anchorEl={anchorEl} closeMenu={closeMenu} {...anchorProps} {...rest}>
        {children}
      </Menu>
    </>
  );
});

export default DropDownMenu;
