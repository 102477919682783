import { Permission } from "types/api";
import useAppSelector from "utils/hooks/useAppSelector";
import { HTTP_STATUS_FORBIDDEN } from "utils/HttpStatuses";

export interface UsePermissionsResult {
  hasCompanyAccess: boolean;
  permissions: Permission[];
  loading: boolean;
}

/**
 * React hook to help determine if the current user can access a company or
 * specific page.
 */
export default function usePermissions(): UsePermissionsResult {
  const { error, permissions, loading } = useAppSelector(state => state.navigationData);

  return {
    hasCompanyAccess: !loading && error !== HTTP_STATUS_FORBIDDEN,
    permissions: permissions.permissions ?? [],
    loading
  };
}
