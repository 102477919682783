import { useAuth0 } from "@auth0/auth0-react";
import { useMount } from "react-use";

/**
 * Special page for performing a logout for some URLs.
 */
export default function LogoutPage(): null {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  useMount(() => {
    if (isAuthenticated) {
      logout();
    } else {
      loginWithRedirect({
        authorizationParams: {
          prompt: "login"
        }
      });
    }
  });

  return null;
}
