import { ButtonProps as MButtonProps, default as MButton } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { LinkProps as MLinkProps } from "@mui/material/Link";
import { FunctionComponent, memo, useMemo } from "react";
import { makeStyles } from "tss-react/mui";

import { LocalLink, LocalLinkOrExternalLinkProps } from "components/LocalLinkOrExternalLink";
import Theme from "types/Theme";

type ColorType = NonNullable<MButtonProps["color"]> | "white";

function getColor(theme: Theme, color: Exclude<ColorType, "inherit">): string {
  return color === "white" ? theme.palette.common.white : theme.palette[color].main;
}

type StyleProps = Pick<ButtonProps, "backgroundOpacityPercent" | "color" | "variant"> &
  Pick<MLinkProps, "underline"> & {
    theme?: Theme;
    isTextButton: boolean;
  };

export interface ButtonProps extends Omit<MButtonProps, "color">, LocalLinkOrExternalLinkProps {
  backgroundOpacityPercent?: number;
  color?: ColorType;
  displayAsLink?: boolean;
  showLoader?: boolean;
}

const useStyles = makeStyles<StyleProps>()(
  (theme, { isTextButton, color, variant, backgroundOpacityPercent, underline }) => ({
    button: {
      textTransform: "none",
      minWidth: isTextButton ? "auto" : "inherit",
      backgroundColor:
        color && color !== "inherit" && variant === "contained"
          ? backgroundOpacityPercent
            ? getColor(theme, color) + String(backgroundOpacityPercent)
            : getColor(theme, color)
          : undefined
    },
    buttonAsLink: {
      color: color ?? theme.palette.primary.main,
      display: "inline-flex",
      font: "inherit",
      lineHeight: "inherit",
      minWidth: 0,
      padding: 0,
      textDecoration: underline === "always" ? "underline" : "none",
      verticalAlign: "baseline",
      "&:hover, &:focus": {
        backgroundColor: "transparent",
        textDecoration: underline === "none" ? "none" : "underline"
      }
    },
    circleProgress: {
      left: "calc(50% - 12px)",
      position: "absolute",
      top: "calc(50% - 12px)",
      color: theme.palette.grey[600]
    }
  })
);

/**
 * Our wrapper to the standard Material UI button, adds some custom abilities
 * like loading animations and client-side links.
 */
const Button: FunctionComponent<ButtonProps> = ({
  backgroundOpacityPercent,
  children,
  color: colorProp,
  disableFocusRipple = false,
  disableRipple = false,
  displayAsLink = false,
  disabled = false,
  showLoader = false,
  to,
  underline = "hover",
  variant,
  className,
  ...buttonProps
}) => {
  const isTextButton: boolean = variant === "text";
  const color = colorProp === "white" ? "inherit" : displayAsLink ? "primary" : (colorProp ?? "inherit");
  const { classes, cx } = useStyles({ isTextButton, color, variant, backgroundOpacityPercent, underline });

  const buttonAsALinkProps: Partial<MButtonProps> = displayAsLink
    ? {
        className: cx(classes.button, classes.buttonAsLink, className),
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true
      }
    : {};

  const buttonComponent = useMemo(() => (to ? LocalLink : "button"), [to]);

  return (
    <MButton
      className={cx(classes.button, className)}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      disabled={disabled || showLoader}
      variant={variant}
      color={color}
      {...buttonAsALinkProps}
      component={buttonComponent}
      to={to}
      {...buttonProps}
    >
      {children}
      {showLoader ? <CircularProgress className={cx(classes.circleProgress)} size={24} /> : null}
    </MButton>
  );
};

export default memo(Button);
