import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AddCustomerRequest,
  BuyerSummary,
  PageLinks,
  RemoveCustomerRequest,
  Supplier
} from "types/api/generated/supplier";
import { LoadNextPageRequest, LoadPreviousPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";
import { excludeCustomerIfPresent, isCustomerInList } from "utils/Customers";

export interface PriceListCustomersState {
  error: Error | null;
  links: PageLinks | null;
  list: BuyerSummary[];
  loading: boolean;
  total: number;
  changes: Array<AddCustomerRequest | RemoveCustomerRequest>;
}

const initialState: PriceListCustomersState = {
  list: [],
  links: null,
  total: 0,
  error: null,
  loading: true,
  changes: []
};

export type FetchPriceListCustomersRequest = RequestAction<
  { priceListId?: string; name?: string; limit?: number },
  Supplier.V1BuyersList.ResponseBody
>;
type FetchPriceListCustomersSuccess = PayloadAction<Supplier.V1BuyersList.ResponseBody & PagingOptions>;
type AddCustomerToPriceList = PayloadAction<string>;
type RemoveCustomerFromPriceList = PayloadAction<string>;
export type UpdatePriceListCustomersRequest = PayloadAction<{
  priceListId: string;
  customersToAdd: AddCustomerRequest[];
  customersToRemove: RemoveCustomerRequest[];
  successCallback?: () => void;
  failureCallback?: () => void;
}>;
type UpdatePriceListCustomersSuccess = PayloadAction;
type UpdatePriceListCustomersFailure = PayloadAction<Error>;

/**
 * State slice for working with the customers of a price list.
 */
const priceListCustomersSlice = createSlice({
  name: "priceListCustomers",
  initialState,
  reducers: {
    fetchPriceListCustomersRequest: (state, _action: FetchPriceListCustomersRequest) => ({
      ...state,
      ...initialState,
      loading: true
    }),
    // TODO: Flip the overrideList value so that the default behaviour is to
    //       always replace the list data.  This lets us write less code in
    //       that we don't have to keep adding `prepareSuccessActionParams` in
    //       our createSaga methods to set this value to true, and then we can
    //       control 'append/accumulate data' in the loadNextPageV2 action.
    fetchPriceListCustomersSuccess: (
      state,
      { payload: { data, links, total, overrideList = false } }: FetchPriceListCustomersSuccess
    ) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      total: total ?? 0,
      error: null,
      loading: false
    }),
    fetchPriceListCustomersFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest) => ({
      ...state,
      loading: true
    }),
    loadPreviousPageRequest: (state, _action: LoadPreviousPageRequest) => ({
      ...state,
      loading: true
    }),
    // Add a customer to the list of changes if it doesn't already exist in this
    // price list's customers.  If we're adding an existing customer, take it
    // out of the changes.
    addCustomerToPriceList: (state, { payload: buyerId }: AddCustomerToPriceList) => ({
      ...state,
      changes:
        !isCustomerInList(buyerId, state.changes) && // Guard against double adding
        !isCustomerInList(buyerId, state.list)
          ? state.changes.concat({ buyerId })
          : excludeCustomerIfPresent(buyerId, state.changes)
    }),
    // Add a customer to the list of changes if it already exists in this price
    // list's customers.  If we're removing a non-existing customer, take it out
    // of the changes.
    removeCustomerFromPriceList: (state, { payload: buyerId }: RemoveCustomerFromPriceList) => ({
      ...state,
      changes:
        !isCustomerInList(buyerId, state.changes) && // Guard against double removing
        isCustomerInList(buyerId, state.list)
          ? state.changes.concat({ buyerId })
          : excludeCustomerIfPresent(buyerId, state.changes)
    }),
    updatePriceListCustomersRequest: (state, _action: UpdatePriceListCustomersRequest) => ({
      ...state,
      loading: true
    }),
    updatePriceListCustomersSuccess: (state, _action: UpdatePriceListCustomersSuccess) => ({
      ...state,
      loading: false
    }),
    updatePriceListCustomersFailure: (state, { payload: error }: UpdatePriceListCustomersFailure) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const {
  fetchPriceListCustomersRequest,
  fetchPriceListCustomersSuccess,
  fetchPriceListCustomersFailure,
  loadNextPageRequest,
  addCustomerToPriceList,
  removeCustomerFromPriceList,
  updatePriceListCustomersRequest,
  updatePriceListCustomersSuccess,
  updatePriceListCustomersFailure
} = priceListCustomersSlice.actions;
export default priceListCustomersSlice.reducer;
