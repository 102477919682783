import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PageLinks,
  ProductBasePriceValidation,
  Supplier,
  SupplierProductSummary,
  SupplierProductSummaryPagedSet
} from "types/api/generated/supplier";
import { LoadNextPageRequest, LoadPreviousPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";

interface NoPricesProductsState {
  list: ProductBasePriceValidation[];
  links: PageLinks | null;
  total: number;
  limit: number;
  loading: boolean;
  error: Error | null;
}

export interface ProductsState {
  list: SupplierProductSummary[];
  links: PageLinks | null;
  limit: number;
  loading: boolean;
  error: Error | null;
  total: number;
  noPrices: NoPricesProductsState;
}

const initialState: ProductsState = {
  list: [],
  links: null,
  limit: 25,
  loading: false,
  error: null,
  total: 0,
  noPrices: {
    list: [],
    links: null,
    limit: 25,
    loading: true,
    error: null,
    total: 0
  }
};

export type LoadProductsRequestPayload = Supplier.V11ProductsList.RequestQuery & PagingOptions;

export type LoadProductsRequest = RequestAction<LoadProductsRequestPayload, Supplier.V11ProductsList.ResponseBody>;
type LoadProductsSuccess = PayloadAction<SupplierProductSummaryPagedSet & PagingOptions>;
export type LoadProductsWithNoPricesRequest = RequestAction<
  { limit: number; isArchived?: boolean },
  Supplier.V1PricelistsProductsReviewList.ResponseBody
>;
type LoadProductsWithNoPricesSuccess = PayloadAction<Supplier.V1PricelistsProductsReviewList.ResponseBody>;

const productsSlice = createSlice({
  name: "supplierProducts",
  initialState,
  reducers: {
    loadProductsRequest: (state, { payload }: LoadProductsRequest) => ({
      ...initialState,
      loading: true,
      total: state.total,
      limit: payload?.limit ?? state.limit,
      list: payload?.overrideList ? [] : state.list
    }),
    loadProductsSuccess: (state, { payload: { data, links, total, overrideList = false } }: LoadProductsSuccess) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      total: total ?? 0,
      loading: false
    }),
    loadProductsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest<Supplier.V11ProductsList.ResponseBody>) => ({
      ...state,
      loading: true
    }),
    loadPreviousPageRequest: (state, _action: LoadPreviousPageRequest<Supplier.V11ProductsList.ResponseBody>) => ({
      ...state,
      loading: true
    }),
    loadProductsWithNoPricesRequest: (state, _action: LoadProductsWithNoPricesRequest) => ({
      ...state,
      noPrices: { ...state.noPrices, loading: true }
    }),
    loadProductsWithNoPricesSuccess: (state, { payload: data }: LoadProductsWithNoPricesSuccess) => ({
      ...state,
      noPrices: {
        ...state.noPrices,
        list: data.data,
        links: data.links,
        total: data.total ?? 0,
        loading: false
      }
    }),
    loadProductsWithNoPricesFailure: (
      state,
      {
        payload: {
          error: { message }
        }
      }
    ) => ({
      ...state,
      noPrices: {
        ...state.noPrices,
        error: message,
        loading: false
      }
    }),
    loadNextPageNoPricesRequest: (state, _action: LoadNextPageRequest) => ({
      ...state,
      noPrices: { ...state.noPrices, loading: true }
    }),
    loadPreviousPageNoPricesRequest: (state, _action: LoadPreviousPageRequest) => ({
      ...state,
      noPrices: { ...state.noPrices, loading: true }
    })
  }
});

export const {
  loadProductsRequest,
  loadProductsSuccess,
  loadProductsFailure,
  loadNextPageRequest,
  loadPreviousPageRequest,
  loadProductsWithNoPricesRequest,
  loadProductsWithNoPricesSuccess,
  loadProductsWithNoPricesFailure,
  loadNextPageNoPricesRequest,
  loadPreviousPageNoPricesRequest
} = productsSlice.actions;

export default productsSlice.reducer;
