import { Auth0ContextInterface } from "@auth0/auth0-react";

import {
  activatePriceListsFailure,
  activatePriceListsSuccess
} from "features/suppliers/price-lists/reducers/allPriceLists";
import { createSaga } from "redux/tasks/createSaga";
import { postDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

/**
 * Calls the API /supplier/v1/pricelists/activate to turn on price lists for the supplier.
 */
function activatePriceLists(
  auth: Auth0ContextInterface,
  tenancyId: string
): Promise<Supplier.V1PricelistsActivateCreate.ResponseBody> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/activate`;
  return postDataToApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Saga to activate all price lists for the supplier.
 */
export default createSaga(activatePriceLists, {
  successAction: activatePriceListsSuccess,
  errorFailureAction: activatePriceListsFailure
});
