import { loadTenancyIdFailure, LoadTenancyIdRequest, loadTenancyIdSuccess } from "redux/reducers/tenancy";
import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

/**
 * Saga to call `GET /directory/internal/users/latest-company` to fetch the las
 * t company the user had used.
 */
export default createSagaNext<LoadTenancyIdRequest>(
  auth0 => {
    return fetchDataFromApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/directory/internal/users/latest-company`);
  },
  {
    successAction: loadTenancyIdSuccess,
    failureAction: loadTenancyIdFailure
  }
);
