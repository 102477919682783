import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { SupplierInternal } from "types/api";

import { removeInvalidOrderLineSuccess } from "../actions/invalidOrderLines";

import supplierOrderQueryKeys from "./supplierOrderQueryKeys";

type RequestParams = SupplierInternal.InternalOrdersInvalidLinesDelete.RequestParams;
type RequestBody = SupplierInternal.InternalOrdersInvalidLinesDelete.RequestBody;
type RequestQuery = SupplierInternal.InternalOrdersInvalidLinesDelete.RequestQuery;
type ResponseBody = SupplierInternal.InternalOrdersInvalidLinesDelete.ResponseBody;

export const removeInvalidOrderLinesParams = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestParams
>(({ orderId, lineId }) => ({
  method: "DELETE",
  path: `/supplier/internal/orders/${orderId}/invalid-lines/${lineId}`
}));

export default function useRemoveInvalidOrderLine(): UseMutationResult<ResponseBody, unknown, RequestParams> {
  const apiFetch = useApiFetch();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async variables => {
      const response = await apiFetch(
        removeInvalidOrderLinesParams({ orderId: variables.orderId, lineId: variables.lineId })
      );

      return response.data;
    },
    onSuccess: (_data, variables) => {
      // Keep the supplierOrderById state upto date.
      dispatch(removeInvalidOrderLineSuccess({ orderId: variables.orderId }));
      queryClient.invalidateQueries(supplierOrderQueryKeys.timeline(variables.orderId).queryKey);
    }
  });
}
