import { JSX } from "react";

import useConnections, { selectByName } from "features/suppliers/integrations/queries/useConnections";
import StarshipitLogo from "media//third-parties/starshipit.svg?react";
import Cin7CoreLogo from "media/third-parties/cin7-core.svg?react";
import Cin7OmniLogo from "media/third-parties/cin7-omni.svg?react";
import SandboxIcon from "media/third-parties/codat-sandbox.svg?react";
import foodstuffsLogo from "media/third-parties/foodstuffs.png";
import MyobLogo from "media/third-parties/myob.svg?react";
import QuickBooksLogo from "media/third-parties/quickbooks.svg?react";
import UnleashedLogo from "media/third-parties/unleashed.svg?react";
import WoolworthsLogo from "media/third-parties/woolworths.svg?react";
import XeroLogo from "media/third-parties/xero.svg?react";
import { ApiConnection, ApiTokenDisplayedProviderType, ApiTokenType } from "types/api/generated/directory-internal";
import { ErrorProvider, IncomingOrderProvider, InvoiceProvider, ProductProvider } from "types/api/generated/supplier";

type Source =
  | ApiConnection
  | ApiTokenDisplayedProviderType
  | ApiTokenType
  | ErrorProvider
  | InvoiceProvider
  | ProductProvider
  | IncomingOrderProvider;

/**
 * Type guard for narrowing the source value to an `ApiConnection` type.
 */
function isApiConnection(source?: Source | null): source is ApiConnection {
  return !!source && typeof source === "object" && "name" in source;
}

interface Props {
  className?: string;
  source: Source;
}

/**
 * Component that renders the appropriate icon for a given integration.
 */
export default function IntegrationIcon({ source, ...rest }: Props): JSX.Element {
  const {
    data: [codatConnection]
  } = useConnections({
    select: selectByName(ApiTokenType.Codat)
  });
  const {
    data: [myIntegratorConnection]
  } = useConnections({
    select: selectByName(ApiTokenType.MyIntegrator)
  });
  const sourceName = isApiConnection(source) ? (source.displayedProvider ?? source.name) : source;

  const map: Record<
    | ApiTokenType
    | ApiTokenDisplayedProviderType
    | IncomingOrderProvider
    | InvoiceProvider
    | ErrorProvider
    | ProductProvider,
    JSX.Element
  > = {
    // Direct connections
    Automation: <span />,
    Cin7Omni: <Cin7OmniLogo aria-label="Cin7 Omni" {...rest} />,
    Dear: <Cin7CoreLogo aria-label="Cin7 Core" {...rest} />,
    Foodstuffs: <img src={foodstuffsLogo} alt="Foodstuffs" title="Foodstuffs" width="32" height="32" {...rest} />,
    Myob: <MyobLogo aria-label="MYOB" {...rest} />,
    Payments: <span />,
    QuickBooksOnline: <QuickBooksLogo aria-label="QuickBooks Online" {...rest} />,
    QuickBooksOnlineSandbox: <QuickBooksLogo aria-label="QuickBooks Online" {...rest} />,
    Sandbox: <SandboxIcon aria-label="Sandbox" {...rest} />,
    Starshipit: <StarshipitLogo aria-label="Starshipit" {...rest} />,
    Unleashed: <UnleashedLogo aria-label="Unleashed" {...rest} />,
    Webhooks: <span />,
    Woolworths: <WoolworthsLogo aria-label="Woolworths" {...rest} />,
    Xero: <XeroLogo aria-label="Xero" {...rest} />,

    // Intermediaries
    Codat: codatConnection?.displayedProvider ? (
      <IntegrationIcon source={codatConnection.displayedProvider} {...rest} />
    ) : (
      <span />
    ),
    MyIntegrator: myIntegratorConnection?.displayedProvider ? (
      <IntegrationIcon source={myIntegratorConnection.displayedProvider} {...rest} />
    ) : (
      <span />
    )
  };

  return map[sourceName];
}
