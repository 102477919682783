import { FunctionComponent, ReactNode } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps
} from "components/Dialog";

export interface ModalProps extends Omit<DialogProps, "title"> {
  /** If present, an X will be added to the right of the modal title that can close it, calling this function in the process. */
  onClose?: () => void;
  title?: ReactNode;
  /** Opt in to having dividing lines between the modal content and the title/actions. */
  contentDividers?: boolean;
  actions?: ReactNode;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  actionsClassName?: string;
  titleProps?: DialogTitleProps;
  contentProps?: DialogContentProps;
}

/**
 * A simplified modal to cover most common cases.  If you need more control, then consider using
 * the raw components this modal is built out of in the {@link Dialog} file.
 */
const Modal: FunctionComponent<ModalProps> = props => {
  const {
    open,
    onClose,
    title,
    children,
    contentDividers,
    actions,
    className,
    titleClassName,
    contentClassName,
    actionsClassName,
    titleProps,
    contentProps,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: className
      }}
      {...rest}
    >
      {title && (
        <DialogTitle className={titleClassName} {...titleProps}>
          {title}
        </DialogTitle>
      )}
      <DialogContent dividers={contentDividers} className={contentClassName} {...contentProps}>
        {children}
      </DialogContent>
      {actions && <DialogActions className={actionsClassName}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
