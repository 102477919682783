import Link, { LinkProps } from "@mui/material/Link";
import { TypeHandler } from "@ultraq/icu-message-formatter";
import { JSX } from "react";

/**
 * Type handler for in-app links.
 */
const externalLinkTypeHandler: TypeHandler = (
  { color = "primary", href, underline = "hover", onClick }: LinkProps,
  linkText,
  _,
  values,
  process
): JSX.Element => {
  return (
    <Link
      href={href}
      component="a"
      target="_blank"
      rel="noopener"
      color={color}
      underline={underline}
      onClick={onClick}
    >
      {process(linkText, values)}
    </Link>
  );
};

export default externalLinkTypeHandler;
