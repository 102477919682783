import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import HelpIcon from "media/help.svg?react";
import LogoIcon from "media/logo_mark.svg?react";
import { show } from "utils/Intercom";

import strings from "./NoAccessCompany.strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: "center"
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  },
  title: {
    margin: theme.spacing(1, "auto", 3)
  },
  supportButton: {
    display: "inline-flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  supportButtonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NoAccessCompany: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { user } = useAuth0();

  return (
    <Page
      containerClassName={cx(classes.container)}
      containerMaxWidth="xs"
      header={<SkeletonHeader backgroundColor="white" />}
      displayNav={false}
    >
      <LogoIcon className={cx(classes.icon)} />
      <Typography className={cx(classes.title)} variant="h1">
        <FormattedMessage id={messages.TITLE} />
      </Typography>

      <Typography paragraph>
        <FormattedMessage
          id={messages.BODY_1}
          values={{
            loginEmail: user?.email
          }}
        />
      </Typography>
      <Typography paragraph>
        <FormattedMessage id={messages.BODY_2} />
      </Typography>
      <Typography paragraph>
        <FormattedMessage id={messages.BODY_3} values={{ learnHow: { articleId: 5395941 } }} />
      </Typography>

      <Button className={cx(classes.supportButton)} color="primary" variant="outlined" onClick={show}>
        <HelpIcon className={cx(classes.supportButtonIcon)} />
        <FormattedMessage id={messages.SUPPORT_BUTTON} />
      </Button>
    </Page>
  );
};

export default NoAccessCompany;
