import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { useMemo } from "react";
import { ConditionalPick } from "type-fest";

export type Messages<Bundle extends Record<string, string | Record<string, string>>> = ConditionalPick<Bundle, string>;

/**
 * React hook for creating a message bundle for the current locale.  The
 * returned object will have keys similar to the message bundle, but will direct
 * to any locale-specific messages.  These can be used as an input to the `id`
 * prop of the `<FormattedMessage>` component, eg:
 *
 * ```json
 * {
 *   "GREETING": "Hello!",
 *   "en-US": {
 *     "GREETING": "Howdy! 🤠"
 *   }
 * }
 * ```
 *
 * ```jsx
 * const messages = useMessages(strings); // Message formatter locale is en-US
 * ...
 * <FormattedMessage id={messages.GREETING}/> // "Howdy! 🤠"
 * ```
 */
export default function useMessages<Bundle extends Record<string, string | Record<string, string>>>(
  strings: Bundle
): Messages<Bundle> {
  const { formatter } = useMessageFormatter();
  const { locale } = formatter;

  return useMemo(() => makeMessageBundle(strings, locale), [locale, strings]);
}

/**
 * Create the message bundle for the given strings file and locale.  This method
 * is exposed as some areas want to access the same bundle as a component would
 * have access to, but aren't in a hooks context, eg: `<ErrorBoundary>` is a
 * class component, and some tests.
 */
export function makeMessageBundle<Bundle extends Record<string, string | Record<string, string>>>(
  strings: Bundle,
  locale: string
): Messages<Bundle> {
  const bundle: Record<string, string> = {};

  // Load the root-level items from the message bundle.  Our default locale is
  // en-NZ, so these are assumed to be for the en-NZ locale.
  Object.entries(strings).reduce((acc, [key, value]) => {
    if (typeof value === "string") {
      acc[key] = value;
    }
    return acc;
  }, bundle);

  // Load any locale-specific overrides
  if (locale !== "en-NZ") {
    const localeStrings = strings[locale];
    if (localeStrings) {
      Object.entries(localeStrings).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, bundle);
    }
  }

  return bundle as Messages<Bundle>;
}
