import { useAuth0 } from "@auth0/auth0-react";
import { App } from "@capacitor/app";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMount, useUnmount } from "react-use";

import { registerDevicePushNotificationRequest } from "redux/reducers/pushNotifications";
import { NotificationTemplate, Platform } from "types/api/generated/directory-internal";
import { useAnalytics } from "utils/analytics";
import { IOS } from "utils/MobilePlatforms";

import usePushNotifications from "./usePushNotifications";

type PushNotificationData = {
  type: NotificationTemplate;
  action_url: string;
  [key: string]: any;
};

const usePushNotificationListeners = (): void => {
  const dispatch = useDispatch();
  const pushNotifications = usePushNotifications();
  const { track } = useAnalytics();
  const { isAuthenticated } = useAuth0();

  const canUsePushNotifications = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("PushNotifications");

  const [pluginListenerHandles, setPluginListenerHandles] = useState<PluginListenerHandle[]>([]);

  useMount(async () => {
    if (canUsePushNotifications) {
      const actionPerformedHandle = await PushNotifications.addListener("pushNotificationActionPerformed", action => {
        if (isAuthenticated) {
          const data = action.notification.data as PushNotificationData;
          track("PushNotificationOpened", { type: data.type });
          if (data.action_url) {
            const { pathname, search, hash } = new URL(data.action_url);
            window.location.href = pathname + search + hash;
          }
        }
      });

      const registrationHandle = await PushNotifications.addListener("registration", token => {
        if (isAuthenticated) {
          dispatch(
            registerDevicePushNotificationRequest({
              platform: Capacitor.getPlatform() === IOS ? Platform.Ios : Platform.Android,
              token: token.value
            })
          );
        }
      });

      const resumeHandle = await App.addListener("resume", async () => {
        if (isAuthenticated) {
          await pushNotifications.register();
        }
      });

      setPluginListenerHandles(handles => handles.concat(actionPerformedHandle, registrationHandle, resumeHandle));
    }
  });

  useUnmount(async () => {
    await Promise.all(pluginListenerHandles.map(handle => handle.remove()));
  });
};

export default usePushNotificationListeners;
