import { JSX, useCallback } from "react";
import { useMount } from "react-use";

import useConnections, { selectByName } from "features/suppliers/integrations/queries/useConnections";
import { ApiConnection, ApiTokenDisplayedProviderType, ApiTokenType } from "types/api/generated/directory-internal";
import { ErrorProvider, IncomingOrderProvider, InvoiceProvider, ProductProvider } from "types/api/generated/supplier";
import { logger } from "utils/Datadog";

import strings from "./IntegrationName.strings.json";

type Source =
  | ApiConnection
  | ApiTokenType
  | ApiTokenDisplayedProviderType
  | ErrorProvider
  | InvoiceProvider
  | ProductProvider
  | IncomingOrderProvider;

/**
 * Type guard for narrowing the source value to an `ApiConnection` type.
 */
function isApiConnection(source?: Source | null): source is ApiConnection {
  return !!source && typeof source === "object" && "name" in source;
}

export function useIntegrationName(source: Source | null | undefined): string {
  const {
    data: [codatConnection]
  } = useConnections({
    select: selectByName(ApiTokenType.Codat)
  });
  const {
    data: [myIntegratorConnection]
  } = useConnections({
    select: selectByName(ApiTokenType.MyIntegrator)
  });
  const sourceName = isApiConnection(source) ? (source.displayedProvider ?? source.name) : source;

  const stringForProvider = useCallback(
    (providerName: typeof sourceName | undefined): string => {
      switch (providerName) {
        case "Codat":
          return stringForProvider(codatConnection?.displayedProvider);
        case "Dear":
          return strings.DEAR;
        case "Cin7Omni":
          return strings.CIN7OMNI;
        case "Foodstuffs":
          return strings.FOODSTUFFS;
        case "Myob":
          return strings.MYOB;
        case "QuickBooksOnline":
        case "QuickBooksOnlineSandbox":
          return strings.QUICKBOOKS;
        case "Sandbox":
          return strings.SANDBOX;
        case "Starshipit":
          return strings.STARSHIPIT;
        case "Unleashed":
          return strings.UNLEASHED;
        case "Xero":
          return strings.XERO;
        case "Woolworths":
          return strings.WOOLWORTHS;
        case "MyIntegrator":
          return stringForProvider(myIntegratorConnection?.displayedProvider);
        default:
          return "";
      }
    },
    [codatConnection?.displayedProvider, myIntegratorConnection?.displayedProvider]
  );

  return stringForProvider(sourceName);
}

interface Props {
  className?: string;
  source: Source;
}

/**
 * Component that renders the appropriate name for a given integration.
 */
export default function IntegrationName({ source, ...rest }: Props): JSX.Element {
  const integrationName = useIntegrationName(source);

  useMount(() => {
    if (!integrationName) {
      logger.error(
        `No matching integration name for "${
          isApiConnection(source) ? (source.displayedProvider ?? source.name) : source
        }"`
      );
    }
  });

  return <span {...rest}>{integrationName}</span>;
}
