import { useAuth0 } from "@auth0/auth0-react";
import { JSX, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { recordError } from "redux/reducers/globalError";
import { createUserRequest } from "redux/reducers/userProfile";
import { ExistingAccount } from "routes/Routes";
import { ValidationErrorCodes } from "types/api";
import IdentityProvider from "types/IdentityProvider";
import { logger } from "utils/Datadog";
import { isApiValidationProblem } from "utils/Errors";
import { promisifySaga } from "utils/Sagas";

interface Props {
  children: JSX.Element;
}

/**
 * React component for ensuring that an authenticated user also exists in our
 * system.
 *
 * This is done at this level because of the issues associated with account
 * linking, where any linked account needs to appear to have the same access as
 * the main account, and linking accounts can occur during a normal login
 * process so there's no specific place that account linking can happen.
 * Account linking for new social accounts with no matching username/password
 * account takes place in an auth0 rule, whereas linking for new social accounts
 * with an existing username/password account takes place in the API's "create
 * user" command.
 */
export default function UpstockUserProvider({ children }: Props): JSX.Element | null {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userCreated, setUserCreated] = useState(false);

  useMount(async () => {
    // If authenticated, ensure the user exists in our system
    if (isAuthenticated) {
      try {
        await promisifySaga(dispatch, createUserRequest)({});
      } catch (error) {
        if (isApiValidationProblem(error)) {
          const errorCode = error.errors[0]?.code;
          const hasGoogleAccount = errorCode === ValidationErrorCodes.UserHasExistingSocialGoogleAccount;
          const hasAppleAccount = errorCode === ValidationErrorCodes.UserHasExistingSocialAppleAccount;
          const hasOtherSocialAccount = errorCode === ValidationErrorCodes.UserHasExistingSocialOtherAccount;
          const hasAuth0Account = errorCode === ValidationErrorCodes.UserHasExistingAuth0Account;
          if (hasGoogleAccount || hasAppleAccount || hasOtherSocialAccount || hasAuth0Account) {
            history.replace(
              ExistingAccount.toUrl(undefined, {
                identityProvider: hasGoogleAccount
                  ? IdentityProvider.Google
                  : hasAppleAccount
                    ? IdentityProvider.Apple
                    : hasAuth0Account
                      ? IdentityProvider.Auth0
                      : IdentityProvider.Unknown
              })
            );
          }
        } else {
          logger.info(
            "Unhandled error ensuring an Upstock user exists",
            { error },
            error instanceof Error ? error : undefined
          );
          dispatch(recordError(error));
        }
      } finally {
        setUserCreated(true);
      }
    } else {
      setUserCreated(true);
    }
  });

  return userCreated ? children : null;
}
