import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import useMessages from "i18n/hooks/useMessages";

import strings from "./OrderAlreadyConsolidatedModal.strings.json";

interface Props extends Pick<ModalProps, "open" | "onClose"> {
  confirm?: () => void;
}

/**
 * Modal shown to warn users that modifying a consolidated invoice order will
 * not update the consolidated invoice.
 */
const OrderAlreadyConsolidatedModal: FunctionComponent<Props> = ({ confirm = () => {}, onClose, ...modalProps }) => {
  const messages = useMessages(strings);
  return (
    <Modal
      data-testid="alreadyconsolidated-modal"
      title={<FormattedMessage id={messages.TITLE} />}
      actions={
        <>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage id={messages.ACTION_CANCEL} />
          </Button>
          <Button color="primary" variant="contained" onClick={confirm}>
            <FormattedMessage id={messages.ACTION_CONFIRM} />
          </Button>
        </>
      }
      maxWidth="xs"
      onClose={onClose}
      {...modalProps}
    >
      <Typography paragraph>
        <FormattedMessage id={messages.MESSAGE} />
      </Typography>
    </Modal>
  );
};

export default OrderAlreadyConsolidatedModal;
