import { useParams } from "react-router-dom";

import { CompanyRoot } from "routes/Routes";

/**
 * A simple React hook for returning the current company's ID, from the URL.
 */
export default function useTenancyId(): string {
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  return tenancyId;
}
