import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import MTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Formik, FormikConfig } from "formik";
import { FunctionComponent, useCallback, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { object, string } from "yup";

import Button from "components/Button";
import SimpleHeader from "components/Header/SimpleHeader";
import Page from "components/Page";
import Select from "components/Select";
import TextField from "components/TextField";
import useCountries from "features/global/geography/useCountries";
import { useAddressLookup } from "features/signup/queries/useAddressLookup";
import useMessages from "i18n/hooks/useMessages";
import { SupplierCustomersRequest, SupplierCustomersRequestComplete } from "routes/Routes";
import { Alpha2Code } from "types/api/generated/global-internal";
import { AddPendingBuyerRequest } from "types/api/generated/supplier";
import useAppSelector from "utils/hooks/useAppSelector";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";

import useSubmitPendingLead from "../../queries/useSubmitPendingLead";

import strings from "./CustomerRequestPage.strings.json";

const useStyles = makeStyles()(theme => ({
  main: {
    textAlign: "center"
  },
  header: {
    marginBottom: theme.spacing(5)
  },
  submitButton: {
    marginTop: theme.spacing(4)
  },
  sendInviteContainer: {
    marginTop: theme.spacing(2)
  },
  countrySelect: {
    textAlign: "start"
  },
  list: {
    maxHeight: theme.spacing(32)
  },
  listLoader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  deliveryAddressError: {
    marginLeft: theme.spacing(1.75),
    marginTop: 0
  }
}));

export const PARAM_TRADINGNAME = "tradingName";
export const PARAM_EMAIL = "email";

type FormValues = Omit<AddPendingBuyerRequest, "alpha2CountryCode"> & {
  countryCode: string | null; // Can remove null once we remove the buyer-request-glowup feature flag
};

/**
 * The page where suppliers can request to have a customer of theirs added,
 * instead of having to hit us up via other means like Intercom.
 */
const CustomerRequestPage: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { formatter } = useMessageFormatter();
  const { tenancyId } = useParams<typeof SupplierCustomersRequest.params>();
  const { country: supplierCountry } = useAppSelector(state => state.tenancy);
  const [addressSearch, setAddressSearch] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState<Alpha2Code | undefined>(supplierCountry?.code);
  const { data: addresses, isFetching: isFetchingAddresses } = useAddressLookup({
    search: addressSearch,
    countryCode: selectedCountryCode
  });
  const { showErrorSnackbar } = useErrorSnackbar();

  const { data: countries = [] } = useCountries();
  const supportedCountries = countries.filter(country => country.isSupported);
  const onlySupportedCountries = countries.length === supportedCountries.length;

  const urlSearchParams = new URLSearchParams(search);

  const validationSchema = object().shape({
    tradingName: string().required(messages.VALIDATION_REQUIRED),
    deliveryAddress: string().required(messages.VALIDATION_REQUIRED),
    email: string().email(messages.VALIDATION_EMAIL).required(messages.VALIDATION_REQUIRED),
    countryCode: string().required(messages.VALIDATION_REQUIRED)
  });

  const initialValues: FormValues = {
    tradingName: urlSearchParams.get(PARAM_TRADINGNAME) || "",
    deliveryAddress: "",
    email: urlSearchParams.get(PARAM_EMAIL) || "",
    countryCode: supplierCountry.code
  };

  const { mutateAsync: submitPendingLead } = useSubmitPendingLead();

  const onSubmit: FormikConfig<FormValues>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      const { countryCode, ...lead } = values;
      try {
        await submitPendingLead({
          ...lead,
          alpha2CountryCode: countryCode
        });
        history.push(SupplierCustomersRequestComplete.toUrl({ tenancyId }, { tradingName: values.tradingName }));
      } catch (error) {
        showErrorSnackbar(error, <FormattedMessage id={messages.SUBMIT_ERROR} />);
      } finally {
        setSubmitting(false);
      }
    },
    [history, messages, showErrorSnackbar, submitPendingLead, tenancyId]
  );

  return (
    <Page header={<SimpleHeader title={formatter.format(messages.TITLE)} />} containerMaxWidth="sm">
      <main className={cx(classes.main)}>
        <header className={cx(classes.header)}>
          <Typography variant="h1" gutterBottom>
            <FormattedMessage id={messages.PAGE_TITLE} />
          </Typography>
          <Typography color="textSecondary">
            <FormattedMessage
              id={messages.PAGE_SUBTITLE}
              values={{
                learnMore: { articleId: 6091315 }
              }}
            />
          </Typography>
        </header>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, values, setFieldValue, errors }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                id="tradingName"
                name="tradingName"
                margin="normal"
                label={<FormattedMessage id={messages.FORM_LABEL_TRADINGNAME} />}
                value={values.tradingName}
              />
              <Select
                label={<FormattedMessage id={messages.FORM_LABEL_COUNTRY} />}
                fullWidth
                id="countryCode"
                name="countryCode"
                value={values.countryCode}
                onChange={event => setSelectedCountryCode(event.target.value as Alpha2Code)}
                menuProps={{ classes: { list: classes.list } }}
                className={classes.countrySelect}
                margin="dense"
              >
                {supportedCountries.map((supportedCountry, index) => (
                  <MenuItem
                    key={supportedCountry.alpha2Code}
                    value={supportedCountry.alpha2Code}
                    divider={!onlySupportedCountries && index === supportedCountries.length - 1}
                  >
                    {supportedCountry.commonName}
                  </MenuItem>
                ))}
                {!onlySupportedCountries &&
                  countries.map(country => (
                    <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                      {country.commonName}
                    </MenuItem>
                  ))}
              </Select>

              <Autocomplete
                value={values.deliveryAddress}
                options={addresses ?? []}
                getOptionLabel={option => (typeof option === "string" ? option : option.address)}
                onInputChange={(_, value) => {
                  setFieldValue("deliveryAddress", value);
                  setAddressSearch(value);
                }}
                loading={isFetchingAddresses}
                loadingText={
                  <div className={classes.listLoader}>
                    <CircularProgress size={24} />
                  </div>
                }
                freeSolo
                disableClearable
                renderInput={params => (
                  <MTextField
                    {...params}
                    error={!!errors.deliveryAddress}
                    margin="normal"
                    label={<FormattedMessage id={messages.FORM_LABEL_DELIVERYADDRESS} />}
                    variant="filled"
                  />
                )}
              />
              <FormHelperText
                data-testid="deliveryAddress-helper-text"
                className={cx(classes.deliveryAddressError)}
                error={!!errors.deliveryAddress}
              >
                {errors.deliveryAddress}
              </FormHelperText>

              <TextField
                id="email"
                name="email"
                margin="normal"
                label={<FormattedMessage id={messages.FORM_LABEL_EMAIL} />}
                value={values.email}
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                showLoader={isSubmitting}
                className={cx(classes.submitButton)}
              >
                <FormattedMessage id={messages.FORM_SUBMIT} />
              </Button>
            </form>
          )}
        </Formik>
      </main>
    </Page>
  );
};

export default CustomerRequestPage;
