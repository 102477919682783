import {
  DeregisterDevicePushNotificationRequest,
  deregisterDevicePushNotificationFailure,
  deregisterDevicePushNotificationSuccess
} from "redux/reducers/pushNotifications";
import createSagaNext from "redux/tasks/createSagaNext";
import { deleteDataFromApiWrapper } from "services/helpers";

export default createSagaNext<DeregisterDevicePushNotificationRequest>(
  (auth0, tenancyId, payload) => {
    return deleteDataFromApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/directory/internal/users/notifications/push/deregister`,
      tenancyId,
      payload
    );
  },
  {
    successAction: deregisterDevicePushNotificationSuccess,
    failureAction: deregisterDevicePushNotificationFailure
  }
);
