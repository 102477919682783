import { combineReducers, Reducer, ReducersMapObject } from "@reduxjs/toolkit";

import buyerGettingStarted from "features/buyers/getting-started/reducers/gettingStarted";
import supplierGettingStarted from "features/suppliers/getting-started/reducers/gettingStarted";
import allPriceLists, { AllPriceListsState } from "features/suppliers/price-lists/reducers/allPriceLists";
import userProfile, { UserProfileState } from "redux/reducers/userProfile";
import { GettingStartedDetails } from "types/api/generated/directory-internal";

import app, { AppState } from "./app";
import company, { CompanyState } from "./company";
import features, { FeaturesState } from "./features";
import geography, { GeographyState } from "./geography";
import globalError, { GlobalErrorState } from "./globalError";
import integrations, { IntegrationsState } from "./integrations";
import navigationData, { NavigationDataState } from "./navigationData";
import permissions, { PermissionsState } from "./permissions";
import pushNotifications, { PushNotificationState } from "./pushNotifications";
import snackbar, { SnackbarState } from "./snackbar";
import buyerById from "./supplier/buyerById";
import customers from "./supplier/customers";
import paymentsSettingsMetadata from "./supplier/paymentSettingsMetadata";
import products from "./supplier/products";
import tenancy, { TenancyState } from "./tenancy";
import termsConditions from "./termsConditions";

const supplier = combineReducers({
  buyerById,
  customers,
  products,
  paymentsSettingsMetadata
});

export interface RootState {
  tenancy: TenancyState;
  integrations: IntegrationsState;
  company: CompanyState;
  supplier: ReturnType<typeof supplier>;
  app: AppState;
  features: FeaturesState;
  permissions: PermissionsState;
  snackbar: SnackbarState;
  globalError: GlobalErrorState;
  navigationData: NavigationDataState;
  termsConditions: {};
  buyerGettingStarted: GettingStartedDetails;
  supplierGettingStarted: GettingStartedDetails;
  userProfile: UserProfileState;
  allPriceLists: AllPriceListsState;
  geography: GeographyState;
  pushNotifications: PushNotificationState;
}

const allReducers: ReducersMapObject<RootState> = {
  tenancy,
  integrations,
  company,
  supplier,
  app,
  features,
  permissions,
  snackbar,
  globalError,
  navigationData,
  termsConditions,
  buyerGettingStarted,
  supplierGettingStarted,
  userProfile,
  allPriceLists,
  geography,
  pushNotifications
};

export function createReducer(injectedReducers: ReducersMapObject = {}): Reducer<RootState> {
  Object.keys(injectedReducers).forEach(key => {
    // @ts-ignore
    allReducers[key] = injectedReducers[key];
  });
  return combineReducers(allReducers);
}

export default combineReducers(allReducers);
