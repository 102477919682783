/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AcceptedBuyerInviteSummary {
  buyerId: string;
  tradingName: string;
  /** @format date-time */
  inviteAcceptedDateUtc: string;
}

export interface Account {
  code?: string | null;
  name: string;
  identifier: string;
}

export enum AccountIntent {
  PaymentReconciliationClearing = "PaymentReconciliationClearing",
  PaymentReconciliationFees = "PaymentReconciliationFees",
  PaymentReconciliationPayout = "PaymentReconciliationPayout"
}

export type AddDocumentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  /** @default "AddDocumentTimelineMetadata" */
  $type: string;
};

export enum AddressType {
  Physical = "Physical"
}

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export interface ApiProblem {
  type: string;
  title: string;
  /** @format int32 */
  status: number;
  message?: string | null;
}

export enum ApiTokenType {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Webhooks = "Webhooks",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface Attachment {
  name: string;
  file: File;
}

export interface BulkExportOrdersCommandRequest {
  orderIds: number[];
  format: SupplierExportFormatOption;
}

export interface BulkPrintOrdersCommandRequest {
  packingSlipRequest?: PackingSlipPrintRequest | null;
  productionReportRequest?: ProductionReportPrintRequest | null;
  deliveryRunReportRequest?: DeliveryRunReportPrintRequest | null;
  orderIds: number[];
  markAsPrinted: boolean;
}

export interface BulkUpdateOrderStatusCommand {
  orderIds: number[];
  status: OrderStatus;
}

export interface BulkUpdateOrderStatusError {
  /** @format int32 */
  orderId: number;
  problem: ApiProblem;
}

export interface BulkUpdateOrderStatusResponse {
  orders: SupplierOrderSummary[];
  errors: BulkUpdateOrderStatusError[];
}

export interface BulkUpdateProductRequest {
  productId: string;
  status?: ProductStatus | null;
  basePrice?: Money | null;
  defaultTaxRates?: CreateDefaultProductTaxRate[] | null;
  categoryId?: string | null;
}

export interface BulkUpdateProductsCommand {
  productDetails: BulkUpdateProductRequest[];
}

export interface BulkUpdateProductsError {
  productId: string;
  problem: ApiValidationProblem;
}

export interface BulkUpdateProductsResponse {
  results: SupplierProductSummary[];
  errors: BulkUpdateProductsError[];
}

export enum BuyerCategory {
  GrocerySupermarket = "GrocerySupermarket",
  Cafe = "Cafe",
  Restaurant = "Restaurant",
  Bar = "Bar",
  Accommodation = "Accommodation",
  AgedCare = "AgedCare",
  Bakery = "Bakery",
  BeautySalonSpa = "BeautySalonSpa",
  Butcher = "Butcher",
  Catering = "Catering",
  ChildCare = "ChildCare",
  Club = "Club",
  ConvenienceStore = "ConvenienceStore",
  Distributor = "Distributor",
  Florist = "Florist",
  FoodCoffeeTruck = "FoodCoffeeTruck",
  GiftBoxService = "GiftBoxService",
  GymFitness = "GymFitness",
  HairSalon = "HairSalon",
  LiquorStore = "LiquorStore",
  MedicalHealthCare = "MedicalHealthCare",
  Office = "Office",
  PetStore = "PetStore",
  PetrolStation = "PetrolStation",
  Pharmacy = "Pharmacy",
  RetailStore = "RetailStore",
  SchoolingEducation = "SchoolingEducation",
  WholesaleSupplier = "WholesaleSupplier"
}

export interface BuyerExternalMetadata {
  externalReferences: ((
    | UtilRequiredKeys<BuyerExternalReferenceProviderContact, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceExternalIdentifier, "$type">
    | UtilRequiredKeys<BuyerExternalReferenceTag, "$type">
  ) & {
    $type: "ProviderContact" | "ExternalIdentifier" | "Tag";
  })[];
}

export interface BuyerExternalReference {
  $type: "ProviderContact" | "ExternalIdentifier" | "Tag";
}

export type BuyerExternalReferenceExternalIdentifier = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  identifier: string;
  source: IdentifierSource;
  /** @default "ExternalIdentifier" */
  $type: string;
};

export type BuyerExternalReferenceProviderContact = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  identifier: string;
  source: ContactProvider;
  name: string;
  /** @default "ProviderContact" */
  $type: string;
};

export type BuyerExternalReferenceTag = UtilRequiredKeys<BuyerExternalReference, "$type"> & {
  name: string;
  value: string;
  /** @default "Tag" */
  $type: string;
};

export interface BuyerPaymentOptions {
  requirement: PaymentRequirement;
  options: ((
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  })[];
}

export interface BuyerProductSummary {
  supplierId: string;
  supplierName: string;
  productId: string;
  productName: string;
  productCode: string;
  buyerPrice?: Money | null;
  isAFavourite: boolean;
  supplierLogoUrl?: string | null;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  promotions: ProductPromotion[];
  unavailableReason?: ProductUnavailableReason | null;
  category?: CategoryRef | null;
  isAvailable: boolean;
}

export interface BuyersMetadata {
  initialCustomerImportStatus: InitialCustomerImportStatus;
}

export type CardPaymentMethod = UtilRequiredKeys<PaymentMethod, "$type"> & {
  cardType: CardPaymentMethodType;
  /** @format int32 */
  expirationMonth: number;
  /** @format int32 */
  expirationYear: number;
  last4: string;
  country?: Alpha2Code | null;
  failureDetails?: (UtilRequiredKeys<DefaultPaymentFailure, "$type"> | null) &
    ({
      $type: "Default";
    } | null);
  /** @default "Card" */
  $type: string;
};

export enum CardPaymentMethodType {
  Visa = "Visa",
  Mastercard = "Mastercard",
  Amex = "Amex",
  Diners = "Diners",
  Discover = "Discover",
  Jcb = "Jcb",
  UnionPay = "UnionPay"
}

export interface CategoryRef {
  id: string;
  name: string;
  fullName: string;
}

export interface CodatInvoiceNoteFormat {
  $type: "Standard" | "StandardWithCustomFooter";
}

export interface CodatSupplierSettings {
  pushOrderIdAsInvoiceNumber: boolean;
  invoiceNoteFormat: (UtilRequiredKeys<Standard, "$type"> | UtilRequiredKeys<StandardWithCustomFooter, "$type">) & {
    $type: "Standard" | "StandardWithCustomFooter";
  };
}

export interface CodatSupplierSettingsUpdate {
  pushOrderIdAsInvoiceNumber?: boolean | null;
  invoiceNoteFormat?: (
    | UtilRequiredKeys<Standard, "$type">
    | UtilRequiredKeys<StandardWithCustomFooter, "$type">
    | null
  ) &
    ({
      $type: "Standard" | "StandardWithCustomFooter";
    } | null);
}

export type CommentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  message: string;
  images?: Image[] | null;
  /** @default "CommentTimelineMetadata" */
  $type: string;
};

export interface CompanyAddress {
  externalId: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  type: AddressType;
  isDefault: boolean;
  country: Country;
  full: string;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface CompanyProfile {
  companyId: string;
  tradingName: string;
  legalBusinessName?: string | null;
  website?: string | null;
  shortDescription?: string | null;
  description?: string | null;
  phone?: string | null;
  email?: string | null;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  logoUrl?: string | null;
  profileImages?: ProfileImages | null;
  directoryTags?: DirectoryTags | null;
  brandPrimaryColour?: string | null;
  isVisibleInDirectoryAsSupplier: boolean;
  /** @deprecated */
  isVisibleInDirectoryAsBuyer: boolean;
  isBuyer: boolean;
  buyerStatus: CompanyTypeStatus;
  isSupplier: boolean;
  supplierStatus: CompanyTypeStatus;
  isClaimed: boolean;
  countryExtensions?: CountryExtensionsApi | null;
  country: Country;
  timezone: string;
  attachments?: Attachment[] | null;
}

export enum CompanyTypeStatus {
  None = "None",
  Onboarding = "Onboarding",
  Active = "Active",
  Inactive = "Inactive"
}

export interface ContactAddressSummary {
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  postalCode?: string | null;
}

export enum ContactProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Codat = "Codat",
  Dear = "Dear",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export interface ContactSummary {
  contactName?: string | null;
  emailAddress?: string | null;
  providerContactId: string;
  contactPersonName?: string | null;
  address?: ContactAddressSummary | null;
}

export interface ContactSummaryPagedSet {
  links: PageLinks;
  data: ContactSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface CopyOrderLine {
  /** @format int32 */
  lineId: number;
  productId: string;
  productName: string;
  productCode: string;
  imagesMetadata?: ProductImagesMetadata | null;
  /** @format double */
  quantity: number;
  isAvailable: boolean;
  isOrderable: boolean;
  unavailableReason?: ProductUnavailableReason | null;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  hasCustomUnitAmount: boolean;
  /** @deprecated */
  currentUnitAmount?: Money | null;
  /** @deprecated */
  currentLineAmount?: Money | null;
  priceListUnitAmount?: Money | null;
  priceListLineAmount?: Money | null;
}

export interface CopyOrderResult {
  supplierId: string;
  supplierName: string;
  buyerId: string;
  buyerName: string;
  /** @format int32 */
  orderId: number;
  lines: CopyOrderLine[];
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CountryExtensionsApi {
  australianBusinessNumber?: string | null;
  newZealandBusinessNumber?: string | null;
  newZealandGstNumber?: string | null;
}

export interface CreateDefaultProductTaxRate {
  taxRateId: string;
}

export interface CreateOrUpdateLinkedBuyerTag {
  /** @maxLength 500 */
  value?: string | null;
  /** @format int32 */
  tagId: number;
}

export interface CreateOrUpdateLinkedBuyerTagValue {
  /** @maxLength 500 */
  value?: string | null;
}

export interface CreateOrderPreviewQuery {
  buyerId: string;
}

export type CreateOrderTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceList?: OrderActionPriceListDetails | null;
  customLines?: OrderLineModificationAudit[] | null;
  files?: PrivateFile[] | null;
  /** @default "CreateOrderTimelineMetadata" */
  $type: string;
};

export interface CreatePayNowResponse {
  paymentId: string;
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  $type: "Stripe";
}

export interface CreateShipmentInProviderRequest {
  shippingProvider: ApiTokenType;
}

export interface Currency {
  code: string;
}

export interface CustomerGroup {
  providerGroupId: string;
  groupName: string;
  options: CustomerGroupValue[];
}

export interface CustomerGroupValue {
  providerGroupValueId: string;
  name: string;
}

export type DayOfFollowingMonth = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  day: number;
  /** @default "DayOfFollowingMonth" */
  $type: string;
};

export enum DearOrderStatus {
  Draft = "Draft",
  Authorised = "Authorised"
}

export enum DearSaleOrderCreationMode {
  NoPick = "NoPick",
  AutoPick = "AutoPick",
  AutoPickPack = "AutoPickPack",
  AutoPickPackShip = "AutoPickPackShip"
}

export enum DearSaleOrderMemoType {
  Empty = "Empty",
  DeliveryDate = "DeliveryDate"
}

export enum DearSaleOrderShipByType {
  Empty = "Empty",
  DeliveryDate = "DeliveryDate"
}

export enum DearSaleOrderShippingNotesType {
  Empty = "Empty",
  DeliveryDateAndComment = "DeliveryDateAndComment"
}

export enum DearSaleOrderType {
  Default = "Default",
  Simple = "Simple",
  Advanced = "Advanced"
}

export enum DearShipByDateSource {
  None = "None",
  DeliveryDate = "DeliveryDate",
  Obsolete = "Obsolete"
}

export type DefaultPaymentFailure = UtilRequiredKeys<PaymentFailureDetails, "$type"> & {
  /** @default "Default" */
  $type: string;
};

export interface DefaultProductTaxRate {
  taxRateId: string;
  name: string;
  code?: string | null;
  /** @format double */
  percentage: number;
}

export type DeleteInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider: InvoiceProvider;
  /** @default "DeleteInvoiceTimelineMetadata" */
  $type: string;
};

export interface DeleteOrderLineRequest {
  productId: string;
}

export interface DeliveryAddress {
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export enum DeliveryGroupType {
  Default = "Default",
  Custom = "Custom"
}

export type DeliveryGroupUpdatedMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  deliveryGroupId: string;
  deliveryGroupName: string;
  type: DeliveryGroupType;
  /** @default "DeliveryGroupUpdatedMetadata" */
  $type: string;
};

export type DeliveryRunReportPrintRequest = object;

export interface DirectoryTags {
  tags: Tag[];
  buyerCategories: BuyerCategory[];
}

export interface DraftProduct {
  providerProductId: string;
  name: string;
  code: string;
  basePrice?: Money | null;
  /** @deprecated */
  isNew: boolean;
  status: DraftProductStatus;
  upstockProduct?: UpstockProduct | null;
  markForImport: boolean;
  changes: DraftProductImportChanges[];
  providerLink: string;
  problem?: ApiValidationProblem | null;
}

export enum DraftProductImportChanges {
  UpdateName = "UpdateName"
}

export interface DraftProductPagedSet {
  links: PageLinks;
  data: DraftProduct[];
  /** @format int32 */
  total?: number | null;
}

export enum DraftProductStatus {
  New = "New",
  Update = "Update",
  Archive = "Archive"
}

export interface EditLineItemsActionPriceListDetails {
  priceWarningsDisabled: boolean;
  effectiveOrderPriceListNameBefore?: string | null;
  effectiveOrderPriceListNameAfter?: string | null;
}

export type EditLineItemsTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceListDetails?: EditLineItemsActionPriceListDetails | null;
  editedLines: OrderLineModificationAudit[];
  /** @default "EditLineItemsTimelineMetadata" */
  $type: string;
};

export interface EmailContact {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  isContactEditable: boolean;
}

export type EmailInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider?: InvoiceProvider | null;
  emails: string[];
  /** @default "EmailInvoiceTimelineMetadata" */
  $type: string;
};

export interface EmailPreviewRecipientDetails {
  to: EmailContact[];
  isClaimed: boolean;
}

export enum ErrorProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Dear = "Dear",
  Codat = "Codat",
  Payments = "Payments",
  Woolworths = "Woolworths",
  Automation = "Automation",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ExternalProduct {
  provider: ProductProvider;
  providerProductId: string;
  providerProductUrl: string;
  name?: string | null;
}

export interface ExternalViewOrder {
  orderDetails: OrderDetails;
  timeline: OrderTimeline[];
  viewingRole: ExternalViewOrderRole;
  supplierName: string;
  supplierId: string;
  /** @format int32 */
  buyerOrderId: number;
  isClaimed: boolean;
  supplierProfile: CompanyProfile;
}

export enum ExternalViewOrderRole {
  Owner = "Owner",
  Viewer = "Viewer",
  Supplier = "Supplier"
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export interface File {
  id: string;
  originalName: string;
  url: string;
  contentType?: string | null;
}

export interface FileSignature {
  hash: string;
  version: FileSignatureVersion;
}

export enum FileSignatureVersion {
  V1 = "V1"
}

export interface ForwardInvoiceCommand {
  toEmails: string[];
  /** @maxLength 1000 */
  comment?: string | null;
}

export type ForwardInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  emails: string[];
  /** @default "ForwardInvoiceTimelineMetadata" */
  $type: string;
};

export interface GetProductsForSupplierPreviewPagedSet {
  links: PageLinks;
  data: BuyerProductSummary[];
  /** @format int32 */
  total?: number | null;
  isFallbackResult: boolean;
}

export interface GetProductsToSyncResponse {
  products: ProductToSync[];
}

export interface GetProviderCustomerGroupsResponse {
  groups: CustomerGroup[];
}

export enum IdentifierSource {
  Foodstuffs = "Foodstuffs",
  Woolworths = "Woolworths"
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface ImageSummary {
  id: string;
  urls: ProfileImageAsset[];
  orientation: Orientation;
}

export enum IncomingOrderProvider {
  Foodstuffs = "Foodstuffs",
  Woolworths = "Woolworths"
}

export enum InitialCustomerImportStatus {
  None = "None",
  Incomplete = "Incomplete",
  Pending = "Pending",
  Complete = "Complete"
}

export interface InvalidOrderLine {
  productName: string;
  productCode?: string | null;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: InvalidOrderLineProblem[];
  providerProductId: string;
}

export interface InvalidOrderLineProblem {
  type: InvalidOrderLineProblemType;
  severity: Severity;
}

export enum InvalidOrderLineProblemType {
  ProviderProductNotMatched = "ProviderProductNotMatched",
  ProductNotFound = "ProductNotFound",
  MissingBasePrice = "MissingBasePrice"
}

export interface InvoiceDetails {
  invoiceNumber: string;
  provider?: InvoiceProvider | null;
  viewInProviderUrl?: string | null;
  viewPdfUrl?: string | null;
  isForwarded: boolean;
  isConsolidated: boolean;
}

export enum InvoiceProvider {
  Xero = "Xero",
  Codat = "Codat",
  MyIntegrator = "MyIntegrator"
}

export interface JobDetails {
  jobId: string;
  status: JobStatus;
}

export interface JobRef {
  jobId: string;
}

export enum JobStatus {
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed"
}

export interface LinkProviderProductsCommand {
  provider: ProductProvider;
  providerProducts: ProviderProductLink[];
}

export interface LinkStripeAccountCommand {
  /** @minLength 1 */
  accountId: string;
  /** @minLength 1 */
  returnUrl: string;
  /** @minLength 1 */
  refreshUrl: string;
}

export interface LinkedBuyerTag {
  /** @format int32 */
  tagId: number;
  name: string;
  value?: string | null;
}

export interface MarkDraftProductsCommand {
  providerProductIds: string[];
}

export interface MatchProductBuyerAliasBody {
  type: ProductBuyerAliasType;
  alias: string;
}

export interface MatchUpstockProductToProviderProductCommand {
  provider: ProductProvider;
  providerProduct: ProviderProductLink;
}

export interface Money {
  /** @format double */
  amount: number;
  currency: Currency;
}

export enum MyIntegratorInvoiceCreatedTrigger {
  OnOrderProcessing = "OnOrderProcessing",
  OnOrderShipped = "OnOrderShipped"
}

export interface MyIntegratorSupplierSettings {
  invoiceCreatedTrigger: MyIntegratorInvoiceCreatedTrigger;
}

export interface MyIntegratorUpdateSupplierSettings {
  invoiceCreatedTrigger?: MyIntegratorInvoiceCreatedTrigger | null;
}

export type NetDays = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  days: number;
  /** @default "NetDays" */
  $type: string;
};

export type OnAccountDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "OnAccount" */
  $type: string;
};

export type OnAccountOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "OnAccount" */
  $type: string;
};

export interface OrderAction {
  action: OrderActionType;
}

export interface OrderActionPriceListDetails {
  name: string;
  priceListId: string;
  effectiveOrderPriceListName?: string | null;
  priceWarningsDisabled: boolean;
}

export enum OrderActionType {
  EditOrderLines = "EditOrderLines",
  ForwardInvoice = "ForwardInvoice",
  NewOrder = "NewOrder",
  CopyOrder = "CopyOrder",
  AddComments = "AddComments",
  PayNow = "PayNow",
  UpdatePaymentOption = "UpdatePaymentOption",
  ManualTakePayment = "ManualTakePayment"
}

export interface OrderApprovedPreviewQuery {
  buyerId: string;
}

export enum OrderAuditAction {
  Ordered = "Ordered",
  Shipped = "Shipped",
  Processing = "Processing",
  CreateInvoice = "CreateInvoice",
  UpdateInvoice = "UpdateInvoice",
  Deleted = "Deleted",
  Comment = "Comment",
  Approved = "Approved",
  Cancelled = "Cancelled",
  Received = "Received",
  EmailInvoice = "EmailInvoice",
  EditLineItems = "EditLineItems",
  DeleteInvoice = "DeleteInvoice",
  ForwardInvoice = "ForwardInvoice",
  LinkExternalDocument = "LinkExternalDocument",
  Scheduled = "Scheduled",
  ReportIssue = "ReportIssue",
  PaymentReceived = "PaymentReceived",
  PaymentFailed = "PaymentFailed",
  ResolveIssue = "ResolveIssue",
  PaymentOptionUpdated = "PaymentOptionUpdated",
  InvalidOrderLineRemoved = "InvalidOrderLineRemoved",
  DeliveryGroupUpdated = "DeliveryGroupUpdated",
  Delivered = "Delivered",
  ShipmentStatusUpdated = "ShipmentStatusUpdated"
}

export enum OrderAuditActionSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Scheduler = "Scheduler",
  PaymentProvider = "PaymentProvider",
  ShipmentProvider = "ShipmentProvider"
}

export interface OrderComment {
  message: string;
  images?: Image[] | null;
}

export type OrderDeliveredMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  signature?: PrivateFile | null;
  signatory?: string | null;
  files: PrivateFile[];
  /** @default "OrderDeliveredMetadata" */
  $type: string;
};

export interface OrderDeliveryGroupSummary {
  deliveryGroupId: string;
  name: string;
  type: DeliveryGroupType;
}

export interface OrderDetails {
  /** @format int32 */
  orderId: number;
  orderStatus: OrderStatus;
  source: OrderSource;
  buyerName: string;
  buyerIsClaimed: boolean;
  supplierIsClaimed: boolean;
  buyerId: string;
  /** @format date-time */
  createdDateUtc: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  deliveryAddress?: string | null;
  lines: OrderLine[];
  invalidLines: InvalidOrderLine[];
  referenceNumber?: string | null;
  trackingNumber?: string | null;
  notes?: string | null;
  invoice?: InvoiceDetails | null;
  printed: boolean;
  workflow: OrderWorkflow;
  orderErrors: OrderError[];
  /** @format int32 */
  totalComments: number;
  orderProvider?: IncomingOrderProvider | null;
  tax?: OrderTaxDetails | null;
  payment?: (
    | UtilRequiredKeys<PaymentMethodDetails, "$type">
    | UtilRequiredKeys<PaymentLinkDetails, "$type">
    | UtilRequiredKeys<OnAccountDetails, "$type">
    | null
  ) &
    ({
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    } | null);
  subtotal?: Money | null;
  total?: Money | null;
  address?: DeliveryAddress | null;
  files: PrivateFile[];
  invoiceIsConsolidated: boolean;
  documents: OrderExternalDocument[];
  hasCustomPrices: boolean;
  orderIssues: OrderIssueSummary[];
}

export interface OrderError {
  /** @format int64 */
  notificationId: number;
  /** @format int32 */
  orderId: number;
  buyerName: string;
  providerError: ProviderError;
  isDismissed: boolean;
  isRetryable: boolean;
}

export enum OrderErrorType {
  Invoice = "Invoice",
  ApproveInvoice = "ApproveInvoice",
  InvalidOrderLines = "InvalidOrderLines",
  BuyerNotLinked = "BuyerNotLinked",
  CreateExternalOrder = "CreateExternalOrder",
  PaymentFailed = "PaymentFailed",
  UpdateInvoice = "UpdateInvoice",
  PaymentFailedNonRetryable = "PaymentFailedNonRetryable",
  OrderNotShippedExternalOrderOutOfSync = "OrderNotShipped_ExternalOrderOutOfSync",
  AutoApprovalFailed = "AutoApprovalFailed",
  OrderNotShippedExternalOrderOutOfSyncInvoicesIncomingOrderProvider = "OrderNotShipped_ExternalOrderOutOfSync_InvoicesIncomingOrderProvider",
  OrderNotShippedExternalOrderOutOfSyncOrderHasPayment = "OrderNotShipped_ExternalOrderOutOfSync_OrderHasPayment",
  ExternalOrderOutOfSyncOrderPaidIncorrectAmount = "ExternalOrderOutOfSync_OrderPaidIncorrectAmount",
  PaymentValidationTotal = "PaymentValidation_Total",
  PaymentValidationTax = "PaymentValidation_Tax",
  QuickBooksOnlineCustomTransactionNumberMustBeOff = "QuickBooksOnline_CustomTransactionNumberMustBeOff",
  IntegrationRecordPaymentFailed = "Integration_RecordPaymentFailed",
  CreateShipment = "Create_Shipment",
  WoolworthsRejectAlreadyAcknowledged = "Woolworths_Reject_AlreadyAcknowledged"
}

export interface OrderExternalDocument {
  documentProvider: ApiTokenType;
  documentType: OrderExternalDocumentType;
  documentStatus: OrderExternalDocumentStatus;
  name?: string | null;
  url?: string | null;
  errors: OrderError[];
  deferredReason?: OrderExternalDocumentDeferredReason | null;
  metadata?: (UtilRequiredKeys<StarshipitMetadata, "$type"> | null) &
    ({
      $type: "Starshipit";
    } | null);
}

export interface OrderExternalDocumentAndStatus {
  document?: OrderExternalDocument | null;
  documentType: OrderExternalDocumentType;
  status: OrderExternalDocumentStatus;
  errors: OrderError[];
}

export interface OrderExternalDocumentDeferredReason {
  type: OrderExternalDocumentDeferredReasonType;
  orderStatus?: OrderStatus | null;
}

export enum OrderExternalDocumentDeferredReasonType {
  WaitingForConsolidatedInvoiceRun = "WaitingForConsolidatedInvoiceRun",
  WaitingForOrderStatus = "WaitingForOrderStatus"
}

export interface OrderExternalDocumentMetadata {
  $type: "Starshipit";
}

export enum OrderExternalDocumentStatus {
  Submitted = "Submitted",
  Created = "Created",
  Failed = "Failed",
  Deferred = "Deferred"
}

export enum OrderExternalDocumentType {
  Invoice = "Invoice",
  GeneratedOrder = "GeneratedOrder",
  Shipment = "Shipment"
}

export enum OrderIssueFailureTypes {
  FailedPayments = "FailedPayments",
  OrderErrors = "OrderErrors",
  CustomerIssues = "CustomerIssues"
}

export interface OrderIssueSummary {
  orderIssueId: string;
  /** @format int32 */
  orderId: number;
  buyerTradingName: string;
  /** @deprecated */
  raisedBy?: UserProfile | null;
  raisedByName: string;
  /** @format date-time */
  raisedDateUtc: string;
  issueType: OrderIssueType;
  comment: OrderComment;
  isResolved: boolean;
  /** @format date-time */
  resolvedDateUtc?: string | null;
}

export enum OrderIssueType {
  Other = "Other",
  DidNotArrive = "DidNotArrive",
  ProductListOutOfDate = "ProductListOutOfDate",
  ProductDamaged = "ProductDamaged",
  ProductQuality = "ProductQuality"
}

export interface OrderLine {
  productName: string;
  productCode: string;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  productId: string;
  status: ProductStatus;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: OrderLineProblem[];
  hasCustomUnitAmount: boolean;
  priceListUnitAmount?: Money | null;
  priceListLineAmount?: Money | null;
  productImages?: ProductImagesMetadata | null;
  externalReferences: ProductExternalReference[];
  tax?: OrderLineTaxDetails | null;
  packingStatus?: OrderLinePackingStatus | null;
}

export interface OrderLineItemRequest {
  productId: string;
  /** @format double */
  quantity: number;
  /** @format double */
  customUnitAmount?: number | null;
  /** @format int32 */
  orderLineId?: number | null;
}

export interface OrderLineModificationAudit {
  modificationType: OrderLineModificationType;
  /** @format double */
  quantityBefore: number;
  /** @format double */
  quantityAfter: number;
  /** @format double */
  unitAmountBefore?: number | null;
  /** @format double */
  unitAmountAfter?: number | null;
  priceListNameBefore?: string | null;
  priceListNameAfter?: string | null;
  productName: string;
  productCode: string;
  productId: string;
  /** @format int32 */
  orderLineId?: number | null;
}

export enum OrderLineModificationType {
  Added = "Added",
  Removed = "Removed",
  QuantityChange = "QuantityChange",
  PriceChange = "PriceChange"
}

export enum OrderLinePackingStatus {
  NotPacked = "NotPacked",
  Packed = "Packed"
}

export interface OrderLinePackingStatusUpdate {
  /** @format int32 */
  lineId: number;
  status: OrderLinePackingStatus;
}

export interface OrderLineProblem {
  type: OrderLineProblemType;
  severity: Severity;
}

export enum OrderLineProblemType {
  DifferentPrice = "DifferentPrice",
  ProductArchived = "ProductArchived",
  ProductOutOfStock = "ProductOutOfStock",
  ProductHidden = "ProductHidden",
  NoTaxRatesCoded = "NoTaxRatesCoded",
  ProductDeleted = "ProductDeleted"
}

export interface OrderLineResult {
  /** @format int32 */
  orderId: number;
  lines: OrderLine[];
}

export interface OrderLineTaxDetails {
  taxRateComponents: OrderLineTaxRateComponent[];
  taxAmount: Money;
}

export interface OrderLineTaxRateComponent {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderPaymentDetails {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
}

export interface OrderPaymentFailure {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
}

export interface OrderPaymentRecord {
  identifier: string;
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  fee?: Money | null;
  failure?: OrderPaymentFailure | null;
}

export interface OrderPaymentStatusDetails {
  $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
}

export type OrderPaymentStatusDetailsFailed = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Failed" */
  $type: string;
};

export type OrderPaymentStatusDetailsPaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Paid" */
  $type: string;
};

export type OrderPaymentStatusDetailsProcessing = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Processing" */
  $type: string;
};

export type OrderPaymentStatusDetailsScheduled = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @format date-time */
  takePaymentDateUtc: string;
  delayUsed: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type">) & {
    $type: "NetDays" | "DayOfFollowingMonth";
  };
  /** @default "Scheduled" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnknown = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Unknown" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnpaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  deferredUntilOrderStatus?: OrderStatus | null;
  delayAfterOrderStatus?: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type"> | null) &
    ({
      $type: "NetDays" | "DayOfFollowingMonth";
    } | null);
  /** @default "Unpaid" */
  $type: string;
};

export interface OrderProgress {
  status: OrderStatus;
  isComplete: boolean;
  /** @format date-time */
  actionDateUtc?: string | null;
}

export enum OrderSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  StandingOrder = "StandingOrder"
}

export enum OrderStatus {
  Ordered = "Ordered",
  Processing = "Processing",
  Shipped = "Shipped",
  Cancelled = "Cancelled",
  Received = "Received",
  Scheduled = "Scheduled",
  Delivered = "Delivered"
}

export interface OrderTaxCodeBreakdownLine {
  taxCode: string;
  taxAmount: Money;
}

export interface OrderTaxDetails {
  taxCodeBreakdown?: OrderTaxCodeBreakdownLine[] | null;
  taxRateBreakdown: OrderTaxRateBreakdownLine[];
  totalTaxAmount: Money;
}

export interface OrderTaxRateBreakdownLine {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderTimeline {
  /** @format int32 */
  orderId: number;
  actionType: OrderAuditAction;
  /** @format date-time */
  actionDateUtc: string;
  actionByFirstName?: string | null;
  actionByLastName?: string | null;
  metadata?: (
    | UtilRequiredKeys<CreateOrderTimelineMetadata, "$type">
    | UtilRequiredKeys<EmailInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<DeleteInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<EditLineItemsTimelineMetadata, "$type">
    | UtilRequiredKeys<ForwardInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<AddDocumentTimelineMetadata, "$type">
    | UtilRequiredKeys<ReportIssueTimelineMetadata, "$type">
    | UtilRequiredKeys<CommentTimelineMetadata, "$type">
    | UtilRequiredKeys<PaymentOptionsUpdatedTimelineMetadata, "$type">
    | UtilRequiredKeys<DeliveryGroupUpdatedMetadata, "$type">
    | UtilRequiredKeys<OrderDeliveredMetadata, "$type">
    | UtilRequiredKeys<ShipmentStatusUpdatedTimelineMetadata, "$type">
    | null
  ) &
    ({
      $type:
        | "CreateOrderTimelineMetadata"
        | "EmailInvoiceTimelineMetadata"
        | "DeleteInvoiceTimelineMetadata"
        | "EditLineItemsTimelineMetadata"
        | "ForwardInvoiceTimelineMetadata"
        | "AddDocumentTimelineMetadata"
        | "ReportIssueTimelineMetadata"
        | "CommentTimelineMetadata"
        | "PaymentOptionsUpdatedTimelineMetadata"
        | "DeliveryGroupUpdatedMetadata"
        | "OrderDeliveredMetadata"
        | "ShipmentSatusUpdatedTimelineMetadata";
    } | null);
  actionBySelf: boolean;
  actionSource: OrderAuditActionSource;
}

export interface OrderTimelineMetadata {
  $type:
    | "CreateOrderTimelineMetadata"
    | "EmailInvoiceTimelineMetadata"
    | "DeleteInvoiceTimelineMetadata"
    | "EditLineItemsTimelineMetadata"
    | "ForwardInvoiceTimelineMetadata"
    | "AddDocumentTimelineMetadata"
    | "ReportIssueTimelineMetadata"
    | "CommentTimelineMetadata"
    | "PaymentOptionsUpdatedTimelineMetadata"
    | "DeliveryGroupUpdatedMetadata"
    | "OrderDeliveredMetadata"
    | "ShipmentSatusUpdatedTimelineMetadata";
}

export interface OrderTransition {
  isPrimary: boolean;
  action: OrderStatus;
  actionUrl: string;
  isDisabled: boolean;
}

export interface OrderWorkflow {
  availableActions: OrderAction[];
  transitions: OrderTransition[];
  progress: OrderProgress[];
}

export interface OrdersFilterRequest {
  textSearch?: string | null;
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  orderStatuses?: OrderStatus[] | null;
  excludeOrderStatuses?: OrderStatus[] | null;
  paymentStatuses?: PaymentStatus[] | null;
  issueTypes?: OrderIssueFailureTypes[] | null;
  orderProviders?: IncomingOrderProvider[] | null;
  deliveryGroups?: string[] | null;
  orderSources?: OrderSource[] | null;
  buyerId?: string | null;
  /** @format int32 */
  limit: number;
  pageToken?: string | null;
  sortBy?: string | null;
}

export enum Orientation {
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square"
}

export type PackingSlipPrintRequest = object;

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface PaymentFailureDetails {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
  $type: "Default";
}

export enum PaymentFailureReason {
  Unknown = "Unknown",
  InsufficientFunds = "InsufficientFunds",
  Expired = "Expired"
}

export enum PaymentFailureType {
  Retryable = "Retryable",
  NonRetryable = "NonRetryable"
}

export type PaymentLinkDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentLink" */
  $type: string;
};

export type PaymentLinkOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "PaymentLink" */
  $type: string;
};

export interface PaymentMethod {
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  identifier: string;
  /** @format date-time */
  createdDateUtc: string;
  status: PaymentMethodStatus;
  $type: "Card";
}

export type PaymentMethodDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentMethod" */
  $type: string;
};

export type PaymentMethodOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  /** @default "PaymentMethod" */
  $type: string;
};

export enum PaymentMethodStatus {
  Active = "Active",
  Failed = "Failed",
  Hidden = "Hidden"
}

export interface PaymentOption {
  isUnavailable: boolean;
  unavailableReason?: PaymentOptionUnavailableReason | null;
  $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
}

export enum PaymentOptionUnavailableReason {
  UnsupportedMerchantAccount = "UnsupportedMerchantAccount",
  PaymentMethodFailed = "PaymentMethodFailed"
}

export type PaymentOptionsUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  updatedOption: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
  /** @default "PaymentOptionsUpdatedTimelineMetadata" */
  $type: string;
};

export enum PaymentProvider {
  Stripe = "Stripe"
}

export enum PaymentRequirement {
  SupplierDefault = "SupplierDefault",
  None = "None",
  PayNow = "PayNow",
  PayNowOptional = "PayNowOptional"
}

export enum PaymentStatus {
  Unknown = "Unknown",
  Unpaid = "Unpaid",
  Processing = "Processing",
  Paid = "Paid",
  Failed = "Failed",
  Scheduled = "Scheduled"
}

export interface PaymentTermsPaymentDelay {
  $type: "NetDays" | "DayOfFollowingMonth";
}

export interface Price {
  discountType: PriceListDiscountType;
  /** @format double */
  discountPercentage?: number | null;
  discountAmount?: Money | null;
  calculatedPrice: Money;
  basePrice: Money;
}

export enum PriceListDiscountType {
  None = "None",
  Percentage = "Percentage",
  Dollar = "Dollar"
}

export interface PriceListProductsReviewPagedSet {
  links: PageLinks;
  data: PriceListProductsReviewProduct[];
  /** @format int32 */
  total?: number | null;
  areTaxRatesRequired: boolean;
}

export interface PriceListProductsReviewProduct {
  productId: string;
  name: string;
  code: string;
  /** @format double */
  basePrice?: number | null;
  defaultTaxRates: DefaultProductTaxRate[];
}

export interface PriceListSummary {
  priceListId: string;
  name: string;
  priceListType: PriceListType;
  /** @format int32 */
  totalBuyers?: number | null;
}

export enum PriceListType {
  Default = "Default",
  Custom = "Custom",
  Generated = "Generated"
}

export interface PrivateFile {
  id: string;
  originalName: string;
  location: string;
  contentType: string;
  /** @format int64 */
  contentLength: number;
  signature: FileSignature;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum ProductBuyerAliasType {
  ProductCode = "ProductCode"
}

export interface ProductDetails {
  productId: string;
  name: string;
  code: string;
  status: ProductStatus;
  supplier: ProductSupplier;
  /** @deprecated */
  providerLink: string;
  basePrice?: Money | null;
  externalProducts: ExternalProduct[];
  priceLists: ProductPriceList[];
  imagesMetadata?: ProductImagesMetadata | null;
  defaultTaxRates: DefaultProductTaxRate[];
  description?: string | null;
  category?: CategoryRef | null;
}

export interface ProductExternalReference {
  identifier: string;
  source: ProductProvider;
  name: string;
}

export interface ProductImagesMetadata {
  images: Image[];
}

export interface ProductImport {
  status: ProductImportStatus;
}

export type ProductImportComplete = ProductImport & {
  /** @format int32 */
  newProducts: number;
  /** @format int32 */
  updatedProducts: number;
  /** @default "Complete" */
  $type: string;
};

export type ProductImportReady = ProductImport & {
  /** @format int32 */
  markedProducts: number;
  /** @default "Ready" */
  $type: string;
};

export enum ProductImportStatus {
  Initialising = "Initialising",
  Populating = "Populating",
  Ready = "Ready",
  Importing = "Importing",
  Complete = "Complete",
  Failed = "Failed"
}

export interface ProductPriceList {
  priceListId: string;
  priceListName: string;
  productIsHidden: boolean;
  productPrice?: Price | null;
}

export interface ProductPromotion {
  type: ProductPromotionType;
}

export enum ProductPromotionType {
  Featured = "Featured"
}

export enum ProductProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export enum ProductStatus {
  Active = "Active",
  Archived = "Archived",
  OutOfStock = "OutOfStock"
}

export interface ProductSupplier {
  name: string;
  supplierId: string;
}

export interface ProductToImportOrUpdate {
  name?: string | null;
  code?: string | null;
  /** @format double */
  basePrice?: number | null;
  status?: ProductStatus | null;
  codeOverride?: string | null;
  description?: string | null;
  imageUrls?: string[] | null;
}

export interface ProductToSync {
  providerProductId: string;
  name: string;
  code: string;
  /** @format double */
  unitPrice?: number | null;
  isActive: boolean;
  isNew: boolean;
}

export enum ProductUnavailableReason {
  Unavailable = "Unavailable",
  Archived = "Archived",
  Hidden = "Hidden",
  OutOfStock = "OutOfStock",
  Deleted = "Deleted"
}

export interface ProductionReportPrintRequest {
  sortBy?: string | null;
}

export interface ProfileImageAsset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  resolution: Resolution;
}

export interface ProfileImages {
  imageSummaries: ImageSummary[];
}

export interface ProviderContactSummary {
  name: string;
  viewInProviderUrl?: string | null;
  provider: ContactProvider;
}

export interface ProviderError {
  invoiceNumber?: string | null;
  providerType: ErrorProvider;
  errorType: OrderErrorType;
  errors: string[];
}

export interface ProviderProductLink {
  productId: string;
  providerProductId: string;
  providerProductName?: string | null;
}

export interface ProviderProductMatchSummary {
  product: SupplierProductSummary;
  /** @deprecated */
  externalProduct?: ExternalProduct | null;
  externalProducts: ExternalProduct[];
}

export interface ProviderProductMatchSummaryProviderProductMatchPagedSet {
  links: PageLinks;
  data: ProviderProductMatchSummary[];
  /** @format int32 */
  total?: number | null;
  priceList?: PriceListSummary | null;
}

export interface ProviderTaxRate {
  identifier: string;
  name: string;
  /** @format double */
  percentage: number;
}

export type ReportIssueTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  issue: OrderIssueType;
  comment: OrderComment;
  /** @default "ReportIssueTimelineMetadata" */
  $type: string;
};

export enum Resolution {
  OneX = "OneX",
  TwoX = "TwoX"
}

export interface SavedOrdersFilter {
  name: string;
  savedFilterId: string;
  isDefault: boolean;
  filter: OrdersFilterRequest;
  /** @format int32 */
  orderCount?: number | null;
}

export interface SavedOrdersFilters {
  savedFilters: SavedOrdersFilter[];
}

export enum Severity {
  Error = "Error",
  Warning = "Warning"
}

export type ShipmentStatusUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  status: string;
  /** @default "ShipmentSatusUpdatedTimelineMetadata" */
  $type: string;
};

export interface SingleProductTaxRateSetting {
  taxRateId: string;
}

export type Standard = UtilRequiredKeys<CodatInvoiceNoteFormat, "$type"> & {
  /** @default "Standard" */
  $type: string;
};

export type StandardWithCustomFooter = UtilRequiredKeys<CodatInvoiceNoteFormat, "$type"> & {
  footer: string;
  /** @default "StandardWithCustomFooter" */
  $type: string;
};

export type StarshipitMetadata = UtilRequiredKeys<OrderExternalDocumentMetadata, "$type"> & {
  tracking: StarshipitTrackingMetadata;
  /** @default "Starshipit" */
  $type: string;
};

export interface StarshipitTrackingMetadata {
  status?: string | null;
  number?: string | null;
  url?: string | null;
}

export type Stripe = UtilRequiredKeys<CreatePayNowResponse, "$type"> & {
  clientSecret: string;
  publishableKey: string;
  /** @default "Stripe" */
  $type: string;
};

export enum SupplierExportFormatOption {
  Standard = "Standard",
  Unleashed = "Unleashed",
  MyobAccountRight = "MyobAccountRight",
  Cin7 = "Cin7",
  GlobalBake = "GlobalBake",
  Vinsight = "Vinsight",
  Accredo = "Accredo",
  DeliveryRun = "DeliveryRun",
  ProductionTallies = "ProductionTallies"
}

export interface SupplierOrderSummary {
  /** @format int32 */
  orderId: number;
  buyerName: string;
  buyerId: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  createdDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  orderStatus: OrderStatus;
  source: OrderSource;
  /** @format int32 */
  totalComments: number;
  trackingNumber?: string | null;
  referenceNumber?: string | null;
  notes?: string | null;
  invoice?: InvoiceDetails | null;
  documents: OrderExternalDocument[];
  printed: boolean;
  workflow: OrderWorkflow;
  orderErrors: OrderError[];
  orderProvider?: IncomingOrderProvider | null;
  payment?: (
    | UtilRequiredKeys<PaymentMethodDetails, "$type">
    | UtilRequiredKeys<PaymentLinkDetails, "$type">
    | UtilRequiredKeys<OnAccountDetails, "$type">
    | null
  ) &
    ({
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    } | null);
  hasUnresolvedOrderIssues: boolean;
  deliveryGroup?: OrderDeliveryGroupSummary | null;
}

export interface SupplierOrderSummaryPagedSet {
  links: PageLinks;
  data: SupplierOrderSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface SupplierProductSummary {
  productId: string;
  name: string;
  code: string;
  status: ProductStatus;
  basePrice?: Money | null;
  buyerPrice?: Money | null;
  isAvailable: boolean;
  isOrderable: boolean;
  unavailableReason?: ProductUnavailableReason | null;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  promotions: ProductPromotion[];
  category?: CategoryRef | null;
}

export interface SupplierViewOrder {
  orderDetails: OrderDetails;
  priceListName?: string | null;
  currentPriceListName?: string | null;
  deliveryGroupSummary?: OrderDeliveryGroupSummary | null;
  buyerMetadata: BuyerExternalMetadata;
}

export interface SyncXeroProducts {
  products: ProductToImportOrUpdate[];
  allowCodeOverrides: boolean;
  updateProviderProducts: boolean;
}

export enum Tag {
  Alcohol = "Alcohol",
  BakedGoods = "BakedGoods",
  CheeseDairy = "CheeseDairy",
  CoffeeTea = "CoffeeTea",
  Condiments = "Condiments",
  Drinks = "Drinks",
  MeatVeg = "MeatVeg",
  Packaging = "Packaging",
  PersonalCare = "PersonalCare",
  DryGoods = "DryGoods",
  Vegan = "Vegan",
  Beer = "Beer",
  Bread = "Bread",
  CertifiedOrganic = "CertifiedOrganic",
  Chocolate = "Chocolate",
  Coffee = "Coffee",
  Confectionary = "Confectionary",
  CookingOil = "CookingOil",
  Dessert = "Dessert",
  Eggs = "Eggs",
  Equipment = "Equipment",
  FreeRange = "FreeRange",
  FrozenFoods = "FrozenFoods",
  Fruit = "Fruit",
  GlutenFree = "GlutenFree",
  Honey = "Honey",
  HotSauce = "HotSauce",
  Juice = "Juice",
  Meat = "Meat",
  Milk = "Milk",
  PlantMilk = "PlantMilk",
  Produce = "Produce",
  Seafood = "Seafood",
  Snacks = "Snacks",
  Spirits = "Spirits",
  SugarFree = "SugarFree",
  Tea = "Tea",
  Wine = "Wine",
  Cleaning = "Cleaning",
  Supplements = "Supplements",
  Pets = "Pets",
  Homewares = "Homewares",
  Kitchenware = "Kitchenware",
  BCorp = "BCorp",
  AlcoholFree = "AlcoholFree",
  SpicesSeasonings = "SpicesSeasonings",
  ReadyMeals = "ReadyMeals",
  FirstNations = "FirstNations"
}

export interface TaxDeclarationSettings {
  $type: "NZ" | "AU";
}

export type TaxDeclarationSettingsAU = UtilRequiredKeys<TaxDeclarationSettings, "$type"> & {
  isGstRegistered?: boolean | null;
  australianBusinessNumber?: string | null;
  /** @default "AU" */
  $type: string;
};

export type TaxDeclarationSettingsNZ = UtilRequiredKeys<TaxDeclarationSettings, "$type"> & {
  isGstRegistered?: boolean | null;
  gstNumber?: string | null;
  /** @default "NZ" */
  $type: string;
};

export interface TaxImportStartRequestOutcome {
  isImportStartedSuccessfully: boolean;
}

export interface TaxImportStatus {
  isImportFinished: boolean;
  hasMissingDefaultProductTaxRates: boolean;
}

export interface TaxOnboardingSettings {
  status: TaxOnboardingStatus;
  taxDeclaration?: (
    | UtilRequiredKeys<TaxDeclarationSettingsNZ, "$type">
    | UtilRequiredKeys<TaxDeclarationSettingsAU, "$type">
    | null
  ) &
    ({
      $type: "NZ" | "AU";
    } | null);
  allowSingleProductTaxRate: boolean;
  suggestedSingleProductTaxRate?: SingleProductTaxRateSetting | null;
}

export interface TaxOnboardingSettingsUpdate {
  isGstRegistered?: boolean | null;
  gstNumber?: string | null;
  australianBusinessNumber?: string | null;
  declaredSingleProductTaxRate?: SingleProductTaxRateSetting | null;
}

export enum TaxOnboardingStatus {
  NotAvailable = "NotAvailable",
  Available = "Available",
  Overdue = "Overdue"
}

export enum TaxProvider {
  Xero = "Xero",
  Codat = "Codat",
  Dear = "Dear",
  Unleashed = "Unleashed",
  MyIntegrator = "MyIntegrator"
}

export interface TaxRate {
  taxRateId: string;
  code?: string | null;
  name: string;
  /** @format double */
  percentage: number;
}

export interface TaxRateExternalReference {
  identifier: string;
  source: TaxProvider;
}

export enum TaxType {
  Unknown = "Unknown",
  Exclusive = "Exclusive",
  Inclusive = "Inclusive",
  NoTax = "NoTax"
}

export enum UnleashedDefaultOrderStatus {
  Parked = "Parked",
  Placed = "Placed"
}

export interface UnleashedSupplierSettings {
  allowPriceListGeneration: boolean;
  isPollingEnabled: boolean;
  defaultOrderStatus: UnleashedDefaultOrderStatus;
}

export interface UnlinkProviderProductsCommand {
  provider: ProductProvider;
  productLinks: ProviderProductLink[];
}

export interface UnmarkDraftProductsCommand {
  providerProductIds: string[];
}

export interface UpdateDearSupplierSettingsCommand {
  defaultOrderStatus?: DearOrderStatus | null;
  saleOrderType?: DearSaleOrderType | null;
  shipByDateSource?: DearShipByDateSource | null;
  saleOrderMemoType?: DearSaleOrderMemoType | null;
  saleOrderShipByType?: DearSaleOrderShipByType | null;
  saleOrderShippingNotesType?: DearSaleOrderShippingNotesType | null;
  orderCreationMode?: DearSaleOrderCreationMode | null;
}

export interface UpdateDearSupplierSettingsCommandResult {
  /** @format int32 */
  supplierId: number;
  defaultOrderStatus?: DearOrderStatus | null;
  saleOrderType?: DearSaleOrderType | null;
  shipByDateSource?: DearShipByDateSource | null;
  saleOrderMemoType?: DearSaleOrderMemoType | null;
  saleOrderShipByType?: DearSaleOrderShipByType | null;
  saleOrderShippingNotesType?: DearSaleOrderShippingNotesType | null;
  orderCreationMode?: DearSaleOrderCreationMode | null;
}

export interface UpdateDraftProductCommand {
  providerProductId: string;
  name: string;
}

export interface UpdateLinkedBuyerProviderContactCommand {
  providerContactId?: string | null;
  createProviderContact: boolean;
}

export interface UpdateOrderLinesPackingStatusBody {
  lines: OrderLinePackingStatusUpdate[];
}

export interface UpdateUnleashedSupplierSettingsCommandRequest {
  allowPriceListGeneration?: boolean | null;
  isPollingEnabled?: boolean | null;
  defaultOrderStatus?: UnleashedDefaultOrderStatus | null;
}

export interface UpdateXeroSupplierSettingsCommand {
  xeroTaxSetting: TaxType;
  productNameSource?: XeroProductNameSource | null;
  applyTrackingToInvoice?: boolean | null;
  paymentTerms?: XeroPaymentTerms | null;
  invoiceDateSource?: XeroInvoiceDateSource | null;
  invoiceApproval?: XeroInvoiceApproval | null;
  /** @format int32 */
  paymentDay?: number | null;
  taxImportMode?: XeroTaxImportMode | null;
  invoiceCreatedTrigger?: XeroInvoiceCreatedTrigger | null;
}

export interface UploadCustomerImportList {
  customers: ContactSummary[];
}

export interface UpstockProduct {
  name: string;
  code: string;
  basePrice?: Money | null;
}

export interface UserProfile {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
}

export enum XeroInvoiceApproval {
  OnMarkAsShipped = "OnMarkAsShipped",
  Never = "Never"
}

export enum XeroInvoiceCreatedTrigger {
  OnOrderProcessing = "OnOrderProcessing",
  OnOrderShipped = "OnOrderShipped"
}

export enum XeroInvoiceDateSource {
  OrderDeliveryDate = "OrderDeliveryDate",
  OrderApprovalDate = "OrderApprovalDate",
  OrderCreationDate = "OrderCreationDate"
}

export enum XeroPaymentTerms {
  DaysAfterBillDate = "DaysAfterBillDate",
  DaysAfterBillMonth = "DaysAfterBillMonth",
  OfCurrentMonth = "OfCurrentMonth",
  OfFollowingMonth = "OfFollowingMonth"
}

export enum XeroProductNameSource {
  Name = "Name",
  Description = "Description"
}

export interface XeroSupplierSettings {
  supplierId: string;
  xeroTaxSetting: TaxType;
}

export enum XeroTaxImportMode {
  OnlyRevenueTaxRates = "OnlyRevenueTaxRates",
  RevenueAndExpenseTaxRates = "RevenueAndExpenseTaxRates"
}

export namespace Supplier {
  /**
   * @description The other system is whichever the default contact provider is currently set for the supplier.
   * @tags Buyers
   * @name InternalBuyersProviderContactCreate
   * @summary Link an Upstock buyer with a contact in another system. (Auth Permissions: UpdateBuyerSettings)
   * @request POST:/supplier/internal/buyers/{buyerId}/provider/contact
   * @secure
   * @response `200` `ProviderContactSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalBuyersProviderContactCreate {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateLinkedBuyerProviderContactCommand;
    export type RequestHeaders = {};
    export type ResponseBody = ProviderContactSummary;
  }
  /**
   * No description
   * @tags Buyers
   * @name InternalBuyersTagsCreate
   * @summary Create or update a linked buyer tag for the given supplier tag ID. (Auth Permissions: CreateLinkedBuyerTags, UpdateLinkedBuyerTags)
   * @request POST:/supplier/internal/buyers/{buyerId}/tags/{tagId}
   * @secure
   * @response `200` `(LinkedBuyerTag)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalBuyersTagsCreate {
    export type RequestParams = {
      buyerId: string;
      /** @format int32 */
      tagId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateLinkedBuyerTagValue;
    export type RequestHeaders = {};
    export type ResponseBody = LinkedBuyerTag[];
  }
  /**
   * No description
   * @tags Buyers
   * @name InternalBuyersTagsDelete
   * @summary  (Auth Permissions: DeleteLinkedBuyerTags)
   * @request DELETE:/supplier/internal/buyers/{buyerId}/tags/{tagId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalBuyersTagsDelete {
    export type RequestParams = {
      buyerId: string;
      /** @format int32 */
      tagId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Buyers
   * @name InternalBuyersTagsCreate2
   * @summary Update several linked buyer tags at once. (Auth Permissions: UpdateLinkedBuyerTags)
   * @request POST:/supplier/internal/buyers/{buyerId}/tags
   * @originalName internalBuyersTagsCreate
   * @duplicate
   * @secure
   * @response `200` `(LinkedBuyerTag)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalBuyersTagsCreate2 {
    export type RequestParams = {
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateLinkedBuyerTag[];
    export type RequestHeaders = {};
    export type ResponseBody = LinkedBuyerTag[];
  }
  /**
   * @description This endpoint is intended to be used by the Supplier > Dashboard page so that, for suppliers who have auto-accept invites on, they can see at a glance whose invitations were recently auto-accepted.
   * @tags Buyers
   * @name InternalBuyersRecentlyAcceptedInvitesList
   * @summary Returns a list of at most 5 recently accepted buyer invites. (Auth Permissions: ViewConnectedBuyers)
   * @request GET:/supplier/internal/buyers/recently-accepted-invites
   * @secure
   * @response `200` `(AcceptedBuyerInviteSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalBuyersRecentlyAcceptedInvitesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptedBuyerInviteSummary[];
  } /**
 * No description
 * @tags Buyers
 * @name InternalBuyersPaymentOptionsDetail
 * @summary Returns all payment options that the supplier can use when placing
an order or updating an order payment for the given buyer. (Auth Permissions: SupplierViewPaymentSettings)
 * @request GET:/supplier/internal/buyers/{id}/payment-options
 * @secure
 * @response `200` `BuyerPaymentOptions` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalBuyersPaymentOptionsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerPaymentOptions;
  }
  /**
   * No description
   * @tags Buyers
   * @name InternalBuyersMetaList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/internal/buyers/meta
   * @secure
   * @response `200` `BuyersMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalBuyersMetaList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyersMetadata;
  }
  /**
   * No description
   * @tags CustomerImport
   * @name InternalImportCustomersUploadCreate
   * @summary  (Auth Permissions: UploadCustomerList)
   * @request POST:/supplier/internal/import/customers/upload
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportCustomersUploadCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UploadCustomerImportList;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags EmailPreviews
   * @name InternalEmailPreviewsApprovedCreate
   * @summary  (Auth Permissions: ViewEmailPreviews)
   * @request POST:/supplier/internal/email-previews/approved
   * @secure
   * @response `200` `EmailPreviewRecipientDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalEmailPreviewsApprovedCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = OrderApprovedPreviewQuery;
    export type RequestHeaders = {};
    export type ResponseBody = EmailPreviewRecipientDetails;
  }
  /**
   * No description
   * @tags EmailPreviews
   * @name InternalEmailPreviewsCreateOrderCreate
   * @summary  (Auth Permissions: ViewEmailPreviews)
   * @request POST:/supplier/internal/email-previews/create-order
   * @secure
   * @response `200` `EmailPreviewRecipientDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalEmailPreviewsCreateOrderCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrderPreviewQuery;
    export type RequestHeaders = {};
    export type ResponseBody = EmailPreviewRecipientDetails;
  }
  /**
   * No description
   * @tags ExternalViewOrders
   * @name InternalExternalViewOrderDetail
   * @request GET:/supplier/internal/external-view-order/{supplierId}/{viewId}
   * @response `200` `ExternalViewOrder` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `404` `void` Not Found
   */
  export namespace InternalExternalViewOrderDetail {
    export type RequestParams = {
      supplierId: string;
      /** @format uuid */
      viewId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ExternalViewOrder;
  }
  /**
   * No description
   * @tags ExternalViewOrders
   * @name InternalExternalViewOrderInvoicePdfDetail
   * @request GET:/supplier/internal/external-view-order/{supplierId}/{viewId}/invoice/pdf
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `404` `ProblemDetails` Not Found
   */
  export namespace InternalExternalViewOrderInvoicePdfDetail {
    export type RequestParams = {
      supplierId: string;
      /** @format uuid */
      viewId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags ExternalViewOrders
   * @name InternalExternalViewOrderClaimCreate
   * @request POST:/supplier/internal/external-view-order/{supplierId}/{viewId}/claim
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalExternalViewOrderClaimCreate {
    export type RequestParams = {
      supplierId: string;
      /** @format uuid */
      viewId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags ExternalViewOrders
 * @name InternalExternalViewOrderPaymentsStripeCreate
 * @request POST:/supplier/internal/external-view-order/{supplierId}/{viewId}/payments/stripe
 * @response `200` `(UtilRequiredKeys<Stripe,"$type">) & ({
    $type: "Stripe",

})` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `404` `void` Not Found
*/
  export namespace InternalExternalViewOrderPaymentsStripeCreate {
    export type RequestParams = {
      supplierId: string;
      /** @format uuid */
      viewId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UtilRequiredKeys<Stripe, "$type"> & {
      $type: "Stripe";
    };
  }
  /**
   * No description
   * @tags Invoice
   * @name InternalInvoicesPdfDetail
   * @summary  (Auth Permissions: ViewInvoice)
   * @request GET:/supplier/internal/invoices/{orderId}/pdf
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `ProblemDetails` Not Found
   */
  export namespace InternalInvoicesPdfDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Invoice
   * @name InternalInvoicesForwardCreate
   * @summary  (Auth Permissions: ForwardInvoice)
   * @request POST:/supplier/internal/invoices/{orderId}/forward
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalInvoicesForwardCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = ForwardInvoiceCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Jobs
   * @name InternalJobsDetail
   * @request GET:/supplier/internal/jobs/{jobId}
   * @response `200` `JobDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalJobsDetail {
    export type RequestParams = {
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobDetails;
  }
  /**
   * No description
   * @tags Onboarding
   * @name InternalOnboardingProductListCreate
   * @summary  (Auth Permissions: UploadProductList)
   * @request POST:/supplier/internal/onboarding/product-list
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOnboardingProductListCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      file?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Onboarding
   * @name InternalOnboardingCustomerListCreate
   * @summary  (Auth Permissions: UploadCustomerList)
   * @request POST:/supplier/internal/onboarding/customer-list
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOnboardingCustomerListCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      file?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags OrderExternalDocuments
   * @name InternalOrderExternalDocumentsPushCreate
   * @summary  (Auth Permissions: CreateOrderExternalDocument)
   * @request POST:/supplier/internal/order-external-documents/{orderId}/push
   * @secure
   * @response `200` `JobRef` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrderExternalDocumentsPushCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * @description The details will include an external document, or, if the order could have a document in flight, a status of how that might be progressing.
   * @tags OrderExternalDocuments
   * @name InternalOrderExternalDocumentsDetailsDetail
   * @summary Get details about any external documents on the given order. (Auth Permissions: ViewOrderExternalDocument)
   * @request GET:/supplier/internal/order-external-documents/{orderId}/details
   * @secure
   * @response `200` `(OrderExternalDocumentAndStatus)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalOrderExternalDocumentsDetailsDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderExternalDocumentAndStatus[];
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersLinesDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/orders/{orderId}/lines
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderLineResult;
  }
  /**
   * @description This will not replace all items with the requested items, only update those matching. Use PUT if you want to replace the line items entirely.
   * @tags Orders
   * @name InternalOrdersLinesCreate
   * @summary Add/Update quantities for existing order items. (Auth Permissions: SupplierUpdateOrder)
   * @request POST:/supplier/internal/orders/{orderId}/lines
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderLineItemRequest[];
    export type RequestHeaders = {
      "if-Match"?: string;
    };
    export type ResponseBody = OrderLineResult;
  }
  /**
   * @description This allows line items to be removed. So all of the line items must be included in this request. If you only want to add one line item, or update it's quantity, you can use POST instead.
   * @tags Orders
   * @name InternalOrdersLinesUpdate
   * @summary Replace all order items with those in the request. (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/internal/orders/{orderId}/lines
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderLineItemRequest[];
    export type RequestHeaders = {
      "if-Match"?: string;
    };
    export type ResponseBody = OrderLineResult;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersLinesDelete
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request DELETE:/supplier/internal/orders/{orderId}/lines
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesDelete {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = DeleteOrderLineRequest[];
    export type RequestHeaders = {};
    export type ResponseBody = OrderLineResult;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersCopyOrderDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/orders/{orderId}/copy-order
   * @secure
   * @response `200` `CopyOrderResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersCopyOrderDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CopyOrderResult;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersLinesPackingStatusUpdate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request PUT:/supplier/internal/orders/{orderId}/lines/packing-status
   * @secure
   * @response `200` `OrderLineResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersLinesPackingStatusUpdate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateOrderLinesPackingStatusBody;
    export type RequestHeaders = {};
    export type ResponseBody = OrderLineResult;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersInvalidLinesProductCreate
   * @summary  (Auth Permissions: SupplierUpdateOrder)
   * @request POST:/supplier/internal/orders/{orderId}/invalid-lines/{lineId}/product
   * @secure
   * @response `200` `SupplierViewOrder` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersInvalidLinesProductCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
      /** @format int32 */
      lineId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = MatchUpstockProductToProviderProductCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierViewOrder;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersInvalidLinesDelete
   * @summary Removes an invalid order line from an order. (Auth Permissions: SupplierUpdateOrder)
   * @request DELETE:/supplier/internal/orders/{orderId}/invalid-lines/{lineId}
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersInvalidLinesDelete {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
      /** @format int32 */
      lineId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description For each order identified in `orderIds`, if a valid path towards the target status exists, then the order will be transitioned through the order statuses until it reaches that status.
   * @tags Orders
   * @name InternalOrdersStatusCreate
   * @summary Transition multiple orders towards a single order status. (Auth Permissions: SupplierUpdateOrder)
   * @request POST:/supplier/internal/orders/status
   * @secure
   * @response `200` `BulkUpdateOrderStatusResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersStatusCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BulkUpdateOrderStatusCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BulkUpdateOrderStatusResponse;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersExportCreate
   * @summary  (Auth Permissions: SupplierViewOrdersExportReport)
   * @request POST:/supplier/internal/orders/export
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersExportCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BulkExportOrdersCommandRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersPrintCreate
   * @summary Produce a combined PDF for all of the selected formats and all the selected orders. (Auth Permissions: ViewPackingSlips)
   * @request POST:/supplier/internal/orders/print
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `ProblemDetails` Not Found
   */
  export namespace InternalOrdersPrintCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BulkPrintOrdersCommandRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name InternalOrdersCreateShipmentCreate
   * @summary Create an order in the given shipment provider. (Auth Permissions: CreateProviderShipment)
   * @request POST:/supplier/internal/orders/{orderId}/create-shipment
   * @secure
   * @response `200` `JobRef` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalOrdersCreateShipmentCreate {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateShipmentInProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  } /**
 * No description
 * @tags OrderViews
 * @name InternalViewsOrdersRecentList
 * @summary Return a page of orders, sorted by the most recent order number by
default. (Auth Permissions: SupplierViewOrders)
 * @request GET:/supplier/internal/views/orders/recent
 * @secure
 * @response `200` `SupplierOrderSummaryPagedSet` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalViewsOrdersRecentList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersDueList
   * @summary Return a page of orders, sorted by delivery date by default. (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders/due
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersDueList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersNotReceivedList
   * @summary Return orders that have not yet been received. (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders/not-received
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersNotReceivedList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersList
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersList {
    export type RequestParams = {};
    export type RequestQuery = {
      textSearch?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      orderStatuses?: OrderStatus[];
      excludeOrderStatuses?: OrderStatus[];
      paymentStatuses?: PaymentStatus[];
      issueTypes?: OrderIssueFailureTypes[];
      orderProviders?: IncomingOrderProvider[];
      deliveryGroups?: string[];
      orderSources?: OrderSource[];
      buyerId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersFilteredList
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders/filtered
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersFilteredList {
    export type RequestParams = {};
    export type RequestQuery = {
      textSearch?: string;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      orderStatuses?: OrderStatus[];
      excludeOrderStatuses?: OrderStatus[];
      paymentStatuses?: PaymentStatus[];
      issueTypes?: OrderIssueFailureTypes[];
      orderProviders?: IncomingOrderProvider[];
      deliveryGroups?: string[];
      orderSources?: OrderSource[];
      buyerId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersSavedFiltersDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders/saved-filters/{savedFilterId}
   * @secure
   * @response `200` `SupplierOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersSavedFiltersDetail {
    export type RequestParams = {
      savedFilterId: string;
    };
    export type RequestQuery = {
      textSearch?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags OrderViews
   * @name InternalViewsOrdersSavedFiltersList
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/supplier/internal/views/orders/saved-filters
   * @secure
   * @response `200` `SavedOrdersFilters` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalViewsOrdersSavedFiltersList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedOrdersFilters;
  } /**
 * No description
 * @tags PackingSlips
 * @name InternalPackingSlipsList
 * @summary Produce a PDF for all of the packing slips in each of the given order
IDs. (Auth Permissions: ViewPackingSlips)
 * @request GET:/supplier/internal/packing-slips
 * @deprecated
 * @secure
 * @response `200` `void` Success
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `404` `ProblemDetails` Not Found
*/
  export namespace InternalPackingSlipsList {
    export type RequestParams = {};
    export type RequestQuery = {
      orderIds?: number[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags PackingSlips
 * @name InternalPackingSlipsCreate
 * @summary Produce a PDF for all of the packing slips in each of the given order
IDs. (Auth Permissions: ViewPackingSlips)
 * @request POST:/supplier/internal/packing-slips
 * @deprecated
 * @secure
 * @response `200` `void` Success
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
 * @response `404` `ProblemDetails` Not Found
*/
  export namespace InternalPackingSlipsCreate {
    export type RequestParams = {};
    export type RequestQuery = {
      orderIds?: number[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PriceLists
   * @name InternalPricelistsReviewProductsList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/internal/pricelists/review-products
   * @secure
   * @response `200` `PriceListProductsReviewPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalPricelistsReviewProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      includeComplete?: boolean;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PriceListProductsReviewPagedSet;
  }
  /**
   * No description
   * @tags PriceLists
   * @name InternalPricelistsReviewProductsUpdate
   * @summary  (Auth Permissions: UpdateProduct)
   * @request PUT:/supplier/internal/pricelists/review-products
   * @secure
   * @response `200` `BulkUpdateProductsResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalPricelistsReviewProductsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {
      updateProvider?: boolean;
    };
    export type RequestBody = BulkUpdateProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BulkUpdateProductsResponse;
  } /**
 * No description
 * @tags ProductImport
 * @name InternalImportProductsPrepareCreate
 * @summary If there are any jobs that we need to run before starting a product import,
we can kick those off and have the UI wait for them first. (Auth Permissions: ImportProducts)
 * @request POST:/supplier/internal/import/products/prepare
 * @secure
 * @response `200` `(JobRef)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalImportProductsPrepareCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef[];
  }
  /**
   * @description This endpoint returns immediately after the import process is initiated asynchronously.  To see how things are progressing, poll the `/supplier/internal/import/products/status` endpoint until the desired status code is returned.
   * @tags ProductImport
   * @name InternalImportProductsStartCreate
   * @summary Begin the product import process. (Auth Permissions: ImportProducts)
   * @request POST:/supplier/internal/import/products/start
   * @secure
   * @response `200` `(ProductImport | ProductImportReady | ProductImportComplete)` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsStartCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductImport | ProductImportReady | ProductImportComplete;
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsStatusList
   * @summary Get the status of the product import process. (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products/status
   * @secure
   * @response `200` `(ProductImport | ProductImportReady | ProductImportComplete)` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportProductsStatusList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductImport | ProductImportReady | ProductImportComplete;
  }
  /**
   * @description The returned products are the result of some processing we do after retrieving all of the products from the provider system.  This is mostly for determining which products are new or updates.
   * @tags ProductImport
   * @name InternalImportProductsList
   * @summary Get all of the products for product import. (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products
   * @secure
   * @response `200` `DraftProductPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProductPagedSet;
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsUpdateCreate
   * @summary  (Auth Permissions: ImportProducts)
   * @request POST:/supplier/internal/import/products/update
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportProductsUpdateCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateDraftProductCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsMarkCreate
   * @summary  (Auth Permissions: ImportProducts)
   * @request POST:/supplier/internal/import/products/mark
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportProductsMarkCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = MarkDraftProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsMarkDelete
   * @summary  (Auth Permissions: ImportProducts)
   * @request DELETE:/supplier/internal/import/products/mark
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportProductsMarkDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UnmarkDraftProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsReviewCodesList
   * @summary  (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products/review/codes
   * @secure
   * @response `200` `(DraftProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsReviewCodesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProduct[];
  }
  /**
   * @description This is a special view over all of the products to import where the incoming product has no name, a name collision with other incoming products, or a name collision with any of the supplier's existing products.
   * @tags ProductImport
   * @name InternalImportProductsReviewNamesList
   * @summary Get all of the importing products whose names have errors. (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products/review/names
   * @secure
   * @response `200` `(DraftProduct)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsReviewNamesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProduct[];
  }
  /**
   * No description
   * @tags ProductImport
   * @name InternalImportProductsReviewAccountsList
   * @summary Get all of the importing products that lack sales accounts. (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products/review/accounts
   * @secure
   * @response `200` `DraftProductPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsReviewAccountsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProductPagedSet;
  }
  /**
   * @description Any product that has been marked for import but is missing a sales account will be removed from the import.
   * @tags ProductImport
   * @name InternalImportProductsReviewAccountsCreate
   * @summary Remove products without a sales account from product import. (Auth Permissions: ImportProducts)
   * @request POST:/supplier/internal/import/products/review/accounts
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsReviewAccountsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags ProductImport
 * @name InternalImportProductsReviewArchivedList
 * @summary Get all of the products that will be archived as a result of this
import process. (Auth Permissions: ImportProducts)
 * @request GET:/supplier/internal/import/products/review/archived
 * @secure
 * @response `200` `(DraftProduct)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalImportProductsReviewArchivedList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProduct[];
  }
  /**
   * @description The response is similar to `/supplier/internal/import/products`, and includes the changes applied as a part of the process.
   * @tags ProductImport
   * @name InternalImportProductsReviewConfirmList
   * @summary Get all of the included products and their changes. (Auth Permissions: ImportProducts)
   * @request GET:/supplier/internal/import/products/review/confirm
   * @secure
   * @response `200` `DraftProductPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalImportProductsReviewConfirmList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DraftProductPagedSet;
  }
  /**
   * @description This will copy over the selected products from the provider system and apply any changes made in the pages before.
   * @tags ProductImport
   * @name InternalImportProductsConfirmCreate
   * @summary Confirm the product import. (Auth Permissions: ImportProducts)
   * @request POST:/supplier/internal/import/products/confirm
   * @secure
   * @response `200` `(ProductImport | ProductImportReady | ProductImportComplete)` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalImportProductsConfirmCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProductImport | ProductImportReady | ProductImportComplete;
  }
  /**
   * No description
   * @tags Products
   * @name InternalProductsDelete
   * @summary Delete multiple supplier products in one call. (Auth Permissions: DeleteProduct)
   * @request DELETE:/supplier/internal/products
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProductsDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name InternalProductsProviderMatchDetail
   * @summary  (Auth Permissions: LinkProviderProducts)
   * @request GET:/supplier/internal/products/provider/{provider}/match
   * @secure
   * @response `200` `ProviderProductMatchSummaryProviderProductMatchPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProductsProviderMatchDetail {
    export type RequestParams = {
      provider: ProductProvider;
    };
    export type RequestQuery = {
      showHidden?: boolean;
      status?: ProductStatus[];
      search?: string;
      buyerId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProviderProductMatchSummaryProviderProductMatchPagedSet;
  }
  /**
   * No description
   * @tags Products
   * @name InternalProductsMatchCreate
   * @request POST:/supplier/internal/products/{productId}/{buyerId}/match
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalProductsMatchCreate {
    export type RequestParams = {
      productId: string;
      buyerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = MatchProductBuyerAliasBody;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Products
   * @name InternalProductsCategoriseCreate
   * @summary  (Auth Permissions: SupplierAutocategoriseProducts)
   * @request POST:/supplier/internal/products/categorise
   * @secure
   * @response `200` `JobRef` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProductsCategoriseCreate {
    export type RequestParams = {};
    export type RequestQuery = {
      asDryRun?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * No description
   * @tags Products
   * @name InternalProductsPreviewList
   * @summary  (Auth Permissions: SupplierViewProducts)
   * @request GET:/supplier/internal/products/preview
   * @secure
   * @response `200` `GetProductsForSupplierPreviewPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProductsPreviewList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      "fallbackQuery.search"?: string;
      "fallbackQuery.promotionStatuses"?: ProductPromotionType[];
      "fallbackQuery.categoryId"?: string;
      search?: string;
      promotionStatuses?: ProductPromotionType[];
      categoryId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetProductsForSupplierPreviewPagedSet;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderXeroSettingsList
   * @summary  (Auth Permissions: ViewProviderSettings)
   * @request GET:/supplier/internal/provider/xero/settings
   * @secure
   * @response `200` `XeroSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderXeroSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = XeroSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderXeroSettingsCreate
   * @summary  (Auth Permissions: UpdateProviderSettings)
   * @request POST:/supplier/internal/provider/xero/settings
   * @secure
   * @response `200` `XeroSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderXeroSettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateXeroSupplierSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = XeroSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderDearSettingsCreate
   * @summary  (Auth Permissions: UpdateProviderSettings)
   * @request POST:/supplier/internal/provider/dear/settings
   * @secure
   * @response `200` `UpdateDearSupplierSettingsCommandResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderDearSettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateDearSupplierSettingsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = UpdateDearSupplierSettingsCommandResult;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderUnleashedSettingsList
   * @summary  (Auth Permissions: ViewProviderSettings)
   * @request GET:/supplier/internal/provider/unleashed/settings
   * @secure
   * @response `200` `UnleashedSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderUnleashedSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UnleashedSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderUnleashedSettingsCreate
   * @summary  (Auth Permissions: UpdateProviderSettings)
   * @request POST:/supplier/internal/provider/unleashed/settings
   * @secure
   * @response `200` `UnleashedSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderUnleashedSettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateUnleashedSupplierSettingsCommandRequest;
    export type RequestHeaders = {};
    export type ResponseBody = UnleashedSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderCodatSettingsList
   * @summary  (Auth Permissions: ViewProviderSettings)
   * @request GET:/supplier/internal/provider/codat/settings
   * @secure
   * @response `200` `CodatSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderCodatSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CodatSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderCodatSettingsUpdate
   * @summary  (Auth Permissions: UpdateProviderSettings)
   * @request PUT:/supplier/internal/provider/codat/settings
   * @secure
   * @response `200` `CodatSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderCodatSettingsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CodatSupplierSettingsUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = CodatSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderMyintegratorSettingsList
   * @summary  (Auth Permissions: ViewProviderSettings)
   * @request GET:/supplier/internal/provider/myintegrator/settings
   * @secure
   * @response `200` `MyIntegratorSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderMyintegratorSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MyIntegratorSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderMyintegratorSettingsUpdate
   * @summary  (Auth Permissions: UpdateProviderSettings)
   * @request PUT:/supplier/internal/provider/myintegrator/settings
   * @secure
   * @response `200` `MyIntegratorSupplierSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderMyintegratorSettingsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = MyIntegratorUpdateSupplierSettings;
    export type RequestHeaders = {};
    export type ResponseBody = MyIntegratorSupplierSettings;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderContactsList
   * @summary  (Auth Permissions: ViewProviderContacts)
   * @request GET:/supplier/internal/provider/contacts
   * @secure
   * @response `200` `ContactSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderContactsList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      customerGroupId?: string;
      customerGroupOptionId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ContactSummaryPagedSet;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderContactsGroupsList
   * @summary  (Auth Permissions: ViewProviderContacts)
   * @request GET:/supplier/internal/provider/contacts/groups
   * @secure
   * @response `200` `GetProviderCustomerGroupsResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderContactsGroupsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetProviderCustomerGroupsResponse;
  } /**
 * No description
 * @tags Provider
 * @name InternalProviderContactsSyncCreate
 * @summary If the contact provider has the sync capability, we can initiate a sync. This will allow us to get the most
up-to-date contacts if they've been added/updated recently.
 * @request POST:/supplier/internal/provider/contacts/sync
 * @response `200` `JobRef` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalProviderContactsSyncCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = JobRef;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderContactsUpstockUpdate
   * @summary  (Auth Permissions: CreateProviderContacts)
   * @request PUT:/supplier/internal/provider/contacts/upstock
   * @secure
   * @response `200` `ContactSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderContactsUpstockUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ContactSummary;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderProductsCreate
   * @summary  (Auth Permissions: LinkProviderProducts)
   * @request POST:/supplier/internal/provider/products
   * @secure
   * @response `200` `LinkProviderProductsCommand` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalProviderProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LinkProviderProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = LinkProviderProductsCommand;
  } /**
 * No description
 * @tags Provider
 * @name InternalProviderProductsDelete
 * @summary Remove the link between one or more Upstock products and provider
products. (Auth Permissions: LinkProviderProducts)
 * @request DELETE:/supplier/internal/provider/products
 * @secure
 * @response `200` `(ProductDetails)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalProviderProductsDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UnlinkProviderProductsCommand;
    export type RequestHeaders = {};
    export type ResponseBody = ProductDetails[];
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderAccountsList
   * @summary  (Auth Permissions: ViewProviderAccounts)
   * @request GET:/supplier/internal/provider/accounts
   * @secure
   * @response `200` `(Account)[]` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderAccountsList {
    export type RequestParams = {};
    export type RequestQuery = {
      intent: AccountIntent;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Account[];
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderAccountsPaymentReconciliationClearingUpdate
   * @summary  (Auth Permissions: CreateProviderAccounts)
   * @request PUT:/supplier/internal/provider/accounts/payment-reconciliation-clearing
   * @secure
   * @response `200` `Account` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderAccountsPaymentReconciliationClearingUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Account;
  }
  /**
   * No description
   * @tags Provider
   * @name InternalProviderTaxRatesPurchasesList
   * @summary  (Auth Permissions: ViewProviderTaxRates)
   * @request GET:/supplier/internal/provider/tax-rates/purchases
   * @secure
   * @response `200` `(ProviderTaxRate)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalProviderTaxRatesPurchasesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ProviderTaxRate[];
  }
  /**
   * No description
   * @tags Stripe
   * @name InternalStripeAccountLinkCreate
   * @summary  (Auth Permissions: PaymentLinkMerchantAccount)
   * @request POST:/supplier/internal/stripe/account-link
   * @secure
   * @response `200` `string` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalStripeAccountLinkCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LinkStripeAccountCommand;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Stripe
   * @name InternalStripeDashboardLoginCreate
   * @summary  (Auth Permissions: PaymentViewMerchantAccount)
   * @request POST:/supplier/internal/stripe/dashboard-login
   * @secure
   * @response `200` `string` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalStripeDashboardLoginCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Tax
   * @name InternalTaxOnboardingSettingsList
   * @summary  (Auth Permissions: SupplierUpdateSettings)
   * @request GET:/supplier/internal/tax/onboarding-settings
   * @secure
   * @response `200` `TaxOnboardingSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalTaxOnboardingSettingsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxOnboardingSettings;
  }
  /**
   * No description
   * @tags Tax
   * @name InternalTaxOnboardingSettingsCreate
   * @summary  (Auth Permissions: SupplierUpdateSettings)
   * @request POST:/supplier/internal/tax/onboarding-settings
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalTaxOnboardingSettingsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TaxOnboardingSettingsUpdate;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Tax
   * @name InternalTaxImportStatusList
   * @summary  (Auth Permissions: SupplierViewSettings)
   * @request GET:/supplier/internal/tax/import/status
   * @secure
   * @response `200` `TaxImportStatus` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalTaxImportStatusList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxImportStatus;
  }
  /**
   * No description
   * @tags Tax
   * @name InternalTaxImportStartCreate
   * @summary  (Auth Permissions: SupplierUpdateSettings)
   * @request POST:/supplier/internal/tax/import/start
   * @secure
   * @response `200` `TaxImportStartRequestOutcome` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalTaxImportStartCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxImportStartRequestOutcome;
  }
  /**
   * No description
   * @tags Tax
   * @name InternalTaxImportStartProductsCreate
   * @summary  (Auth Permissions: SupplierUpdateSettings)
   * @request POST:/supplier/internal/tax/import/start/products
   * @secure
   * @response `200` `boolean` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalTaxImportStartProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }
  /**
   * No description
   * @tags XeroSync
   * @name InternalXeroSyncProductsList
   * @summary  (Auth Permissions: CreateProduct)
   * @request GET:/supplier/internal/xero/sync/products
   * @deprecated
   * @secure
   * @response `200` `GetProductsToSyncResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalXeroSyncProductsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetProductsToSyncResponse;
  } /**
 * No description
 * @tags XeroSync
 * @name InternalXeroSyncProductsCreate
 * @summary Despite the name of this API endpoint, this is used to bulk-upload
products, which is used by the Product Import CSV page in Retool.
            
It's "XeroSync" because we used to use this to sync back to Xero. (Auth Permissions: CreateProduct)
 * @request POST:/supplier/internal/xero/sync/products
 * @secure
 * @response `200` `void` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalXeroSyncProductsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SyncXeroProducts;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}
