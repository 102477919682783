import { Add as AddIcon, Edit as EditIcon, HelpOutline as HelpIcon } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { JSX, MouseEventHandler, ReactNode, useCallback } from "react";
import NumberFormat from "react-number-format";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Image from "components/Image/Image";
import Tooltip from "components/Tooltip";
import { BuyerOrderLine } from "features/buyers/orders/new-order/reducers/types";
import useMessages from "i18n/hooks/useMessages";
import {
  InvalidOrderLine,
  InvalidOrderLineProblemType,
  InvoiceLinesSortOrderType,
  OrderLine,
  OrderLineProblemType,
  OrderTaxDetails,
  SupplierProductSummary
} from "types/api/generated/supplier";
import { OrderLinePackingStatus } from "types/api/generated/supplier-internal";
import { LocalOrderLine } from "types/Orders";
import getOrderTotals from "utils/getOrderTotals";
import { isNullOrUndefined } from "utils/helpers";
import useCountryConfig from "utils/hooks/useCountryConfig";
import { DECIMALSCALE } from "utils/numbers";
import { getPrimaryOrderTaxBreakdown, sortByLineId, sortByProductName } from "utils/Orders";

import LineProblemChipTooltipWrapper from "./LineProblemChipTooltipWrapper";
import strings from "./ReadOnlyLineItemsTable.strings.json";

const useStyles = makeStyles()(theme => {
  return {
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius
    },
    table: {
      // Allows children of table cells to use height:100%
      height: "100%",
      borderCollapse: "separate",
      "& thead th": {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: 1,
        whiteSpace: "nowrap",
        color: theme.palette.text.secondary,
        background: theme.palette.grey[50],
        boxSizing: "border-box",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
      },
      "& tfoot td": {
        background: theme.palette.grey[50],
        border: "none",
        borderTop: `1px solid ${theme.palette.divider}`
      },
      "& tbody tr:last-child td, & tbody tr:last-child th": {
        borderBottom: "none"
      },
      "& td, & th": {
        padding: theme.spacing(1.5, 2),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(1.5)
        }
      },
      "& tbody th": {
        width: "100%"
      }
    },
    productNameCellWrapper: {
      display: "flex"
    },
    productImage: {
      marginRight: theme.spacing(2)
    },
    productName: {
      flex: 1,
      flexBasis: "fit-content",
      "& *": {
        lineHeight: 1.25
      }
    },
    productNameProblemsWrapper: {
      flex: 1,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    productProblems: {
      padding: theme.spacing(0.5, 0)
    },
    footer: {
      display: "flex",
      alignItems: "center"
    },
    pricingSource: {
      // Hacky workaround to make text-overflow:ellipsis work in a table layout
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
      textOverflow: "ellipsis",
      overflow: "hidden",
      minWidth: 0,
      lineHeight: 1.25,
      cursor: "default"
    },
    totalLabel: {
      paddingLeft: theme.spacing(2),
      marginLeft: "auto"
    },
    totalAmount: {
      fontWeight: "bold",
      whiteSpace: "nowrap"
    },
    noItems: {
      color: theme.palette.grey[600]
    },
    noPrice: {
      display: "inline-flex",
      alignItems: "center",
      color: theme.palette.grey[600],
      fontSize: theme.typography.caption.fontSize,
      cursor: "default",
      whiteSpace: "nowrap"
    },
    noPriceIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: 4
    },
    addButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      color: theme.palette.primary.main
    },
    addButtonIcon: {
      width: "20px",
      height: "20px",
      marginRight: "4px"
    },
    editButtonIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5)
    },
    invalidLineButtons: {
      display: "flex",
      marginTop: theme.spacing(1.5),
      "& > * + *": {
        marginLeft: theme.spacing(1.5)
      }
    },
    quantityEditButtonWrapper: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        placeContent: "flex-end"
      }
    },
    packedIcon: {
      color: theme.palette.common.green
    }
  };
});

export interface ReadOnlyLineItemsTableProps<L extends BuyerOrderLine | OrderLine | LocalOrderLine> {
  className?: string;
  invalidLineItems?: InvalidOrderLine[];
  isBuyer?: boolean;
  lineItems: L[];
  onAddItem?: () => void;
  onEditItem?: (lineItem: L) => void;
  onPackItem?: (lineItem: L, status: OrderLinePackingStatus) => void;
  onMatchProduct?: (lineItem: InvalidOrderLine) => void;
  pricingSource?: ReactNode;
  quickAddProducts?: SupplierProductSummary[];
  removeInvalidLine?: LineItemRowProps<L>["removeInvalidLine"];
  tax?: OrderTaxDetails | null;
  sortOrder?: InvoiceLinesSortOrderType;
}

function ReadOnlyLineItemsTable<L extends BuyerOrderLine | OrderLine | LocalOrderLine>({
  className,
  invalidLineItems = [],
  isBuyer,
  lineItems,
  onAddItem,
  onEditItem,
  onPackItem,
  onMatchProduct,
  pricingSource,
  quickAddProducts,
  removeInvalidLine,
  sortOrder = InvoiceLinesSortOrderType.OrderLineId,
  tax
}: ReadOnlyLineItemsTableProps<L>): JSX.Element {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const messages = useMessages(strings);
  const { salesTaxName } = useCountryConfig();

  const allLineItems = [...lineItems, ...invalidLineItems].sort((a, b) =>
    sortOrder === InvoiceLinesSortOrderType.ProductName ? sortByProductName(a, b) : sortByLineId(a, b)
  );
  const hasPrices = allLineItems.some(lineItem => Boolean(lineItem.unitAmount));
  const taxDisplayLines = getPrimaryOrderTaxBreakdown(hasPrices, tax);
  const hasTax = taxDisplayLines.length > 0;
  const [calculatedTotal, calculatedSubtotal] = getOrderTotals(allLineItems, tax);

  const subtotal = hasPrices ? calculatedSubtotal : null;
  const total = hasPrices ? calculatedTotal : null;

  let fullWidthColSpan = hasPrices ? (isXsDown ? 3 : 4) : 2;
  const subtotalLabelColSpan = isXsDown ? 2 : 3;
  const footerLabelsColSpan = hasPrices ? (isXsDown ? 2 : 3) : 2;

  const disableRemoveInvalidButton = lineItems.length + (invalidLineItems?.length ?? 0) <= 1;

  if (onPackItem) {
    fullWidthColSpan += 1;
  }

  return (
    <TableContainer className={cx(className, classes.root)}>
      <Table className={cx(classes.table)}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id={messages.PRODUCT_HEADING} />
            </TableCell>

            <TableCell align="right">
              <FormattedMessage id={messages.QUANTITY_HEADING} />
            </TableCell>

            {hasPrices && (
              <>
                {!isXsDown && (
                  <TableCell align="right">
                    <FormattedMessage id={messages.UNIT_PRICE_HEADING} />
                  </TableCell>
                )}
                <TableCell align="right">
                  <FormattedMessage id={messages.AMOUNT_HEADING} />
                </TableCell>
              </>
            )}

            {onPackItem && (
              <TableCell align="center">
                <FormattedMessage id={messages.PACKED_HEADING} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {allLineItems?.map(lineItem =>
            "productId" in lineItem ? (
              <LineItemRow
                key={"lineId" in lineItem ? `${lineItem.lineId} ${lineItem.productId}` : lineItem.productId}
                lineItem={lineItem}
                quickAddProducts={quickAddProducts}
                hasPrices={hasPrices}
                isXsDown={isXsDown}
                onEditItem={onEditItem}
                onPackItem={onPackItem}
                showPackColumn={!!onPackItem}
              />
            ) : (
              <LineItemRow
                key={lineItem.lineId}
                lineItem={lineItem}
                onMatchProduct={onMatchProduct}
                hasPrices={hasPrices}
                isXsDown={isXsDown}
                removeInvalidLine={removeInvalidLine}
                disableRemoveInvalidButton={disableRemoveInvalidButton}
                showPackColumn={!!onPackItem}
              />
            )
          )}

          {lineItems.length === 0 && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="div" className={cx(classes.noItems)}>
                  <FormattedMessage id={messages.NO_ITEMS_MESSAGE} />
                </Typography>
              </TableCell>

              <TableCell />

              {hasPrices && (
                <>
                  {!isXsDown && <TableCell />}
                  <TableCell />
                </>
              )}

              {onPackItem && <TableCell />}
            </TableRow>
          )}

          {onAddItem && (
            <TableRow>
              <TableCell component="th" scope="row" padding="none" colSpan={fullWidthColSpan}>
                <ButtonBase className={cx(classes.addButton)} focusRipple onClick={onAddItem}>
                  <AddIcon className={cx(classes.addButtonIcon)} />
                  <Typography component="div">
                    <FormattedMessage id={messages.ADD_BUTTON} />
                  </Typography>
                </ButtonBase>
              </TableCell>
            </TableRow>
          )}

          {hasPrices && hasTax && (
            <TableRow>
              <TableCell component="th" scope="row" align="right" colSpan={subtotalLabelColSpan}>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage id={messages.SUBTOTAL_LABEL} />
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography component="div" data-testid="read-only-line-items-table-subtotal">
                  <NumberFormat
                    displayType="text"
                    value={subtotal}
                    thousandSeparator
                    decimalScale={DECIMALSCALE}
                    fixedDecimalScale
                  />
                </Typography>
              </TableCell>
              {onPackItem && <TableCell />}
            </TableRow>
          )}

          {taxDisplayLines.map(taxDisplayLine => (
            <TableRow key={taxDisplayLine.key}>
              <TableCell component="th" scope="row" align="right" colSpan={subtotalLabelColSpan}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  data-testid="read-only-line-items-table-tax-label"
                  data-chameleon-id="line-items-table-tax"
                >
                  {taxDisplayLine.displayName}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography component="div" data-testid="read-only-line-items-table-tax-amount">
                  <NumberFormat
                    displayType="text"
                    value={taxDisplayLine.amount}
                    thousandSeparator
                    decimalScale={DECIMALSCALE}
                    fixedDecimalScale
                  />
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {(hasPrices || isBuyer) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={footerLabelsColSpan} padding="none">
                <div className={cx(classes.footer)}>
                  {hasPrices ? (
                    <>
                      {pricingSource && (
                        <Typography
                          className={cx(classes.pricingSource)}
                          variant="caption"
                          data-testid="read-only-line-items-table-pricing-source"
                        >
                          {pricingSource}
                        </Typography>
                      )}

                      <div className={cx(classes.totalLabel)}>
                        <Typography variant="caption">
                          <FormattedMessage
                            id={hasTax ? messages.TOTAL_WITH_TAX_LABEL : messages.TOTAL_WITHOUT_TAX_LABEL}
                            values={{ salesTaxName }}
                          />
                        </Typography>
                      </div>
                    </>
                  ) : (
                    isBuyer && (
                      <Tooltip
                        className={cx(classes.noPrice)}
                        data-testid="read-only-line-items-table-no-price-tooltip"
                        title={
                          <Typography>
                            <FormattedMessage
                              id={messages.NO_PRICE_TOOLTIP}
                              values={{
                                learnMore: {
                                  articleId: 5168102
                                }
                              }}
                            />
                          </Typography>
                        }
                      >
                        <>
                          <HelpIcon className={cx(classes.noPriceIcon)} />
                          <FormattedMessage id={messages.NO_PRICE} />
                        </>
                      </Tooltip>
                    )
                  )}
                </div>
              </TableCell>

              {hasPrices && (
                <TableCell align="right">
                  <Typography
                    component="div"
                    className={cx(classes.totalAmount)}
                    color="textPrimary"
                    data-testid="read-only-line-items-table-total-price"
                  >
                    <NumberFormat
                      displayType="text"
                      prefix="$"
                      value={total}
                      thousandSeparator
                      decimalScale={DECIMALSCALE}
                      fixedDecimalScale
                    />
                  </Typography>
                </TableCell>
              )}

              {onPackItem && <TableCell />}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}

interface LineItemRowProps<L extends BuyerOrderLine | OrderLine | InvalidOrderLine | LocalOrderLine> {
  disableRemoveInvalidButton?: boolean;
  hasPrices: boolean;
  isXsDown: boolean;
  lineItem: L;
  onEditItem?: (lineItem: L) => void;
  onMatchProduct?: (lineItem: L) => void;
  onPackItem?: (lineItem: L, status: OrderLinePackingStatus) => void;
  quickAddProducts?: SupplierProductSummary[];
  removeInvalidLine?: (lineItem: InvalidOrderLine) => void;
  showPackColumn: boolean;
}

function LineItemRow<L extends BuyerOrderLine | OrderLine | InvalidOrderLine | LocalOrderLine>({
  disableRemoveInvalidButton,
  hasPrices,
  isXsDown,
  lineItem,
  onEditItem,
  onMatchProduct,
  onPackItem,
  quickAddProducts,
  removeInvalidLine,
  showPackColumn
}: LineItemRowProps<L>): JSX.Element {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();

  const showMatchProductButton =
    "providerProductId" in lineItem && // Narrow the type to just an InvalidOrderLine since we're only checking for a InvalidOrderLineProblemType
    lineItem.problems.find(({ type }) => type === InvalidOrderLineProblemType.ProviderProductNotMatched);

  // Filter out ProductHidden problems for quick add products
  const lineItemProblems =
    "problems" in lineItem
      ? "productId" in lineItem && quickAddProducts
        ? lineItem.problems.filter(problem => {
            return !(
              problem.type === OrderLineProblemType.ProductHidden &&
              quickAddProducts.find(product => lineItem.productId && product.productId)
            );
          })
        : lineItem.problems
      : [];

  const productImages =
    "imagesMetadata" in lineItem
      ? lineItem.imagesMetadata
      : "productImages" in lineItem
        ? lineItem.productImages
        : undefined;

  const packingStatus = "packingStatus" in lineItem ? lineItem.packingStatus : undefined;

  const matchInvalidLine: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    onMatchProduct?.(lineItem);
  }, [lineItem, onMatchProduct]);

  const handleRemoveInvalidLine: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    removeInvalidLine?.(lineItem as InvalidOrderLine);
  }, [lineItem, removeInvalidLine]);

  return (
    <TableRow onClick={() => !isSmUp && onEditItem && onEditItem(lineItem)}>
      <TableCell component="th" scope="row">
        <div className={cx(classes.productNameCellWrapper)}>
          {isSmUp && (
            <Image
              className={cx(classes.productImage)}
              image={productImages?.images[0]}
              alt={formatter.format(messages.PRODUCT_IMAGE_ALT, { name: lineItem.productName })}
              sizes="40px"
            />
          )}
          <div className={cx(classes.productNameProblemsWrapper)}>
            <div className={cx(classes.productName)}>
              <Typography component="div">{lineItem.productName}</Typography>
              {showMatchProductButton && onMatchProduct ? (
                removeInvalidLine ? (
                  <div className={cx(classes.invalidLineButtons)}>
                    <Button color="primary" size="small" variant="outlined" onClick={matchInvalidLine}>
                      <FormattedMessage id={messages.INVALID_LINE_MATCH} />
                    </Button>
                    <Tooltip
                      title={<FormattedMessage id={messages.NO_ITEMS_TOOLTIP} />}
                      disabled={!disableRemoveInvalidButton}
                    >
                      <Button
                        color="primary"
                        disabled={disableRemoveInvalidButton}
                        size="small"
                        variant="outlined"
                        onClick={handleRemoveInvalidLine}
                      >
                        <FormattedMessage id={messages.INVALID_LINE_REMOVE} />
                      </Button>
                    </Tooltip>
                  </div>
                ) : (
                  <Button displayAsLink onClick={matchInvalidLine}>
                    <Typography variant="caption" align="left">
                      <FormattedMessage id={messages.MATCH_PRODUCT_BUTTON} />
                    </Typography>
                  </Button>
                )
              ) : (
                lineItem.productCode && (
                  <Typography variant="caption" color="textSecondary">
                    {lineItem.productCode}
                  </Typography>
                )
              )}
            </div>
            {!showMatchProductButton && (
              <LineProblemChipTooltipWrapper
                problems={lineItemProblems}
                productId={"productId" in lineItem ? lineItem.productId : undefined}
                className={cx(classes.productProblems)}
              />
            )}
          </div>
        </div>
      </TableCell>

      <TableCell align="right" data-testid="read-only-line-items-table-quantity">
        <div className={cx(classes.quantityEditButtonWrapper)}>
          {onEditItem && (
            <IconButton color="primary" size="small" title={formatter.format(messages.EDIT_BUTTON)}>
              <EditIcon className={cx(classes.editButtonIcon)} />
            </IconButton>
          )}
          <Typography component="div">{lineItem.quantity}</Typography>
        </div>
      </TableCell>

      {hasPrices && (
        <>
          {!isXsDown && (
            <TableCell align="right">
              {!isNullOrUndefined(lineItem.unitAmount?.amount) && (
                <Typography component="div">
                  <NumberFormat
                    displayType="text"
                    value={lineItem.unitAmount?.amount}
                    thousandSeparator
                    decimalScale={DECIMALSCALE}
                    fixedDecimalScale
                  />
                </Typography>
              )}
            </TableCell>
          )}
          <TableCell align="right">
            {!isNullOrUndefined(lineItem.lineAmount?.amount) && (
              <Typography component="div">
                <NumberFormat
                  displayType="text"
                  value={lineItem.lineAmount?.amount}
                  thousandSeparator
                  decimalScale={DECIMALSCALE}
                  fixedDecimalScale
                />
              </Typography>
            )}
          </TableCell>
        </>
      )}

      {showPackColumn && (
        <TableCell align="center">
          {packingStatus === OrderLinePackingStatus.Packed ? (
            <IconButton
              disabled={!!removeInvalidLine}
              onClick={() => onPackItem?.(lineItem, OrderLinePackingStatus.NotPacked)}
              data-testid="unpack-button"
            >
              <CheckCircleIcon className={classes.packedIcon} />
            </IconButton>
          ) : (
            <IconButton
              disabled={!!removeInvalidLine}
              onClick={() => onPackItem?.(lineItem, OrderLinePackingStatus.Packed)}
              data-testid="pack-button"
            >
              <CircleOutlinedIcon />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

export default ReadOnlyLineItemsTable;
