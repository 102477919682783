import { useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, ListItemButton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import DropDownMenu from "components/DropDownMenu";
import ListItemLink from "components/ListItemLink";
import UserAvatar from "components/UserAvatar/UserAvatar";
import useMessages from "i18n/hooks/useMessages";
import { CompanyRoot, Notifications, PersonalProfile } from "routes/Routes";
import useAppSelector from "utils/hooks/useAppSelector";

import strings from "./ProfileMenu.strings.json";

const useStyles = makeStyles()(theme => ({
  button: {
    justifyContent: "space-between",
    padding: theme.spacing(2.5, 2),
    borderRadius: 0,
    borderTopColor: theme.palette.divider,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    textAlign: "left"
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: 150
  },
  list: {
    width: theme.spacing(28.25)
  },
  listItem: {
    padding: theme.spacing(1.5, 2)
  }
}));

type ProfileMenuProps = {
  onMenuItemClick: () => void;
};

const ProfileMenu: FunctionComponent<ProfileMenuProps> = ({ onMenuItemClick }) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { logout, user } = useAuth0();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const { firstName, lastName, email } = useAppSelector(state => state.userProfile);

  const name = firstName && lastName ? `${firstName} ${lastName}` : email;

  const onLogout = (): void => {
    onMenuItemClick();
    logout();
  };

  return (
    <DropDownMenu
      id="profile-menu"
      trigger={triggerProps => (
        <Button endIcon={<KeyboardArrowDownIcon />} className={cx(classes.button)} {...triggerProps}>
          <Stack direction="row" alignItems="center" gap={1}>
            <UserAvatar name={name} className={classes.avatar} />
            <Typography className={classes.name}>{name || user?.email}</Typography>
          </Stack>
        </Button>
      )}
      MenuListProps={{ disablePadding: true, className: classes.list }}
    >
      <ListItemLink to={Notifications.toUrl({ tenancyId })} onClick={onMenuItemClick} className={classes.listItem}>
        <FormattedMessage id={messages.NOTIFICATION_SETTINGS} />
      </ListItemLink>
      <ListItemLink to={PersonalProfile.toUrl({ tenancyId })} onClick={onMenuItemClick} className={classes.listItem}>
        <FormattedMessage id={messages.PERSONAL_PROFILE} />
      </ListItemLink>
      <Divider />
      <ListItemButton onClick={onLogout} className={classes.listItem}>
        <FormattedMessage id={messages.SIGNOUT} />
      </ListItemButton>
    </DropDownMenu>
  );
};

export default ProfileMenu;
