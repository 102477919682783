import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { JSX } from "react";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import { CompanyRoot } from "routes/Routes";
import { logger } from "utils/Datadog";
import useAppSelector from "utils/hooks/useAppSelector";
import useTenancyId from "utils/hooks/useTenancyId";

import strings from "./CompanyInactivePage.strings.json";

const useStyles = makeStyles()(theme => ({
  button: {
    left: theme.spacing(-1)
  },
  main: {
    display: "grid",
    gap: theme.spacing(3),
    justifyItems: "center",
    textAlign: "center"
  },
  icon: {
    height: "40px",
    width: "40px"
  }
}));

/**
 * Page shown if landing on a company which has been deactivated.  Usually
 * encountered if an old user returns to Upstock after a long time (and we've
 * since deactivated their company), or by us when we enter a companyId in the
 * URL and it leads to an inactive one.
 */
export default function CompanyInactivePage(): JSX.Element {
  const { classes } = useStyles();
  const tenancyId = useTenancyId();
  const messages = useMessages(strings);
  const { companies } = useAppSelector(state => state.navigationData);

  // Inactive companies are not included in the companies list
  const nextAvailableCompany = companies[0];

  useMount(() => {
    logger.warn(`Someone tried to launch Upstock with an inactive companyId: ${tenancyId}`);
  });

  return (
    <Page
      header={
        <SkeletonHeader
          backgroundColor="white"
          LeftComp={
            nextAvailableCompany ? (
              <Button
                color="primary"
                href={CompanyRoot.toUrl({ tenancyId: nextAvailableCompany.companyId })}
                component="a"
                className={classes.button}
              >
                <FormattedMessage id={messages.HEADER_HOME} />
              </Button>
            ) : null
          }
        />
      }
      displayNav={false}
      containerMaxWidth="xs"
    >
      <main className={classes.main}>
        <LogoIcon className={classes.icon} />
        <Typography variant="h1">
          <FormattedMessage id={messages.HEADER} />
        </Typography>
        <div>
          <Typography paragraph>
            <FormattedMessage id={messages.MESSAGE1} />
          </Typography>
          <Typography>
            <FormattedMessage id={messages.MESSAGE2} />
          </Typography>
        </div>
      </main>
    </Page>
  );
}
