import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

import { ActivatePriceListSuccess } from "../reducers/allPriceLists";
import {
  fetchPriceListsMetadataFailure,
  FetchPriceListsMetadataRequest,
  fetchPriceListsMetadataSuccess
} from "../reducers/metadata";

/**
 * Saga to call `GET /supplier/v1/pricelists/meta` for the price lists metadata.
 */
export default createSagaNext<FetchPriceListsMetadataRequest | ActivatePriceListSuccess>(
  (auth0, tenancyId) => {
    return fetchDataFromApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/meta`, tenancyId);
  },
  {
    successAction: fetchPriceListsMetadataSuccess,
    failureAction: fetchPriceListsMetadataFailure
  }
);
