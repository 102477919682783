import { useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import { useUnmount } from "react-use";

import PrivateRoute from "auth/PrivateRoute";
import { BuyerEditOrderLines, BuyerNewOrder, BuyerOrders, BuyerPrintInvoice, BuyerViewOrder } from "routes/Routes";
import lazyComponent from "services/lazyComponent";

import { clearOrders } from "./orders-list/reducers/buyerOrders";

const BuyerOrdersFeature = lazyComponent(() => import("./orders-list/OrdersListFeature"));
const BuyerViewOrderFeature = lazyComponent(() => import("./view-order/ViewBuyerOrderFeature"));
const BuyerNewOrderFeature = lazyComponent(() => import("./new-order/NewOrderFeature"));
const BuyerEditOrderFeature = lazyComponent(() => import("./edit-order/EditOrderFeature"));

/**
 * A component that encompasses all of the Buyer > Orders pages/features/flows.
 * This is useful to act as a boundary around all of buyer ordering, allowing us
 * to hook into the entry/exit of this area of our app.
 */
export default function OrdersFeature(): JSX.Element {
  const dispatch = useDispatch();

  useUnmount(() => {
    dispatch(clearOrders());
  });

  return (
    <Switch>
      <PrivateRoute
        exact
        path={BuyerOrders.path}
        component={BuyerOrdersFeature}
        permissions={BuyerOrders.permissions}
      />
      <PrivateRoute path={BuyerNewOrder.path} component={BuyerNewOrderFeature} />
      <PrivateRoute exact path={[BuyerViewOrder.path, BuyerPrintInvoice.path]} component={BuyerViewOrderFeature} />
      <PrivateRoute path={BuyerEditOrderLines.path} component={BuyerEditOrderFeature} />
    </Switch>
  );
}
