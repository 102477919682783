import { fetchBuyerByIdFailure, FetchBuyerByIdRequest, fetchBuyerByIdSuccess } from "redux/reducers/supplier/buyerById";
import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

/**
 * Saga to get the current buyer details.
 */
export default createSagaNext<FetchBuyerByIdRequest>(
  (auth0, tenancyId, { buyerId }) => {
    return fetchDataFromApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/supplier/v1/buyers/${buyerId}`, tenancyId);
  },
  {
    successAction: fetchBuyerByIdSuccess,
    failureAction: fetchBuyerByIdFailure
  }
);
