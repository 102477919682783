import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { SupplierInternal } from "types/api";
import { safeurl } from "utils/urls";

type RequestBody = SupplierInternal.InternalBuyersProviderContactCreate.RequestBody;
type RequestQuery = SupplierInternal.InternalBuyersProviderContactCreate.RequestQuery;
type RequestParams = SupplierInternal.InternalBuyersProviderContactCreate.RequestParams;
type ResponseBody = SupplierInternal.InternalBuyersProviderContactCreate.ResponseBody;
type LinkProviderContactRequest = RequestParams & RequestBody;

export const linkProviderContactOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParams>(
  ({ buyerId }) => ({
    method: "POST",
    path: safeurl`/supplier/internal/buyers/${buyerId}/provider/contact`
  })
);

/**
 * React query hook to call `POST /supplier/internal/buyers/{buyerId}/provider/contact`
 * to link a customer to a provider contact.
 */
export default function useLinkProviderContact(): UseMutationResult<
  ResponseBody,
  FetchV2Error,
  LinkProviderContactRequest
> {
  const apiFetch = useApiFetch();

  return useMutation({
    async mutationFn({ buyerId, ...requestBody }) {
      const response = await apiFetch(linkProviderContactOptions({ buyerId }, { data: requestBody }));
      return response.data;
    }
  });
}
