import { createAction, PayloadAction } from "@reduxjs/toolkit";

import { HideModalProperties, ModalToHide } from "types/api/generated/directory-internal";

type Request = {
  modals: ModalToHide[];
};

export type HideModalsRequest = PayloadAction<Request>;

export const hideModalsRequest = createAction<Request>("hideModals/request");
export const hideModalsSuccess = createAction<HideModalProperties>("hideModals/success");
export const hideModalsFailure = createAction("hideModals/failure");
