import { useMediaQuery } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Container, { ContainerTypeMap } from "@mui/material/Container";
import { useTheme } from "@mui/styles";
import { FunctionComponent, ReactNode, useContext } from "react";
import { makeStyles } from "tss-react/mui";

import PhoneOrientationBanner from "components/Banner/PhoneOrientationBanner";
import ErrorBoundary from "components/ErrorBoundary";
import HeaderErrorBoundary from "components/ErrorBoundary/Header";
import { FabContext } from "components/Fab";
import Header from "components/Header";
import useMobileNav from "mobile/useMobileNav";
import LoadingScreen from "pages/LoadingScreen";
import useAppSelector from "utils/hooks/useAppSelector";
import useGlobalNav from "utils/hooks/useGlobalNav";

interface StyleProps extends Pick<PageProps, "backgroundColor" | "disableGutters"> {
  mobileNavRendered: boolean;
  sharedWithFab: boolean;
  fullScreen: boolean;
}

const useStyles = makeStyles<StyleProps>()(
  (theme, { backgroundColor, fullScreen, mobileNavRendered, sharedWithFab, disableGutters }) => ({
    page: {
      backgroundColor: backgroundColor || theme.palette.common.white,
      minHeight: !fullScreen ? "100vh" : "auto",
      height: fullScreen ? "100vh" : "auto",
      position: "relative",
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    container: {
      paddingBottom: `calc(${theme.spacing(
        mobileNavRendered ? (sharedWithFab ? 22 : 10) : sharedWithFab ? 15 : 10
      )} + env(safe-area-inset-bottom))`,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        paddingBottom: theme.spacing(15),
        paddingTop: theme.spacing(6)
      },
      "@media (orientation: landscape)": {
        paddingLeft: `calc(${disableGutters ? 0 : theme.spacing(3)} + env(safe-area-inset-left))`,
        paddingRight: `calc(${disableGutters ? 0 : theme.spacing(3)} + env(safe-area-inset-right))`
      }
    },
    wrapper: {
      height: "inherit"
    }
  })
);

const placeholderHeader = <Header LeftComp={<span>Upstock</span>} />;

export interface PageProps extends BoxProps {
  backgroundColor?: string;

  /**
   * Which will be displayed under the mobile banner, commonly used for invoice
   * errors
   */
  banner?: ReactNode;

  children: NonNullable<ReactNode>;
  containerClassName?: string;
  bannerClassName?: string;

  /**
   * Which is spread on the container component.
   */
  containerMaxWidth?: ContainerTypeMap["props"]["maxWidth"];

  disableGutters?: boolean;
  disableGuttersSmallScreen?: boolean;

  /**
   * Control the visibility of the global and mobile navs.
   */
  displayNav?: boolean;

  header?: JSX.Element;

  /**
   * If `true`, then the page will show the loading animation.
   */
  isLoading?: boolean;

  /**
   * Dictates if this page is better viewed in landscape mode for mobile
   */
  showBestInLandscapeBanner?: boolean;

  /**
   * For some pages.
   */
  showContainer?: boolean;

  showMobileNav?: boolean;

  /** Whether the Page should be fixed to the viewport width/height. Useful for full screen layouts with embedded scrollbars like the New Order flow */
  fullScreen?: boolean;
}

/**
 * Common page component that manages displaying the header, some shared banner
 * components and the global nav menu.
 */
const Page: FunctionComponent<PageProps> = ({
  backgroundColor,
  banner,
  children,
  containerClassName,
  bannerClassName,
  containerMaxWidth = "md",
  disableGutters = false,
  disableGuttersSmallScreen = false,
  displayNav = true,
  fullScreen = false,
  header = placeholderHeader,
  isLoading = false,
  showBestInLandscapeBanner = false,
  showContainer = true,
  showMobileNav = displayNav,
  ...rest
}) => {
  const { renderedFabCount } = useContext(FabContext);

  useGlobalNav(displayNav);
  const { mobileNavRendered } = useMobileNav(showMobileNav);

  const { classes, cx } = useStyles({
    backgroundColor,
    disableGutters,
    sharedWithFab: renderedFabCount > 0,
    mobileNavRendered,
    fullScreen
  });

  const { phoneOrientationBannerDisplayed } = useAppSelector(state => state.app);
  const showPhoneOrientationBanner = showBestInLandscapeBanner && phoneOrientationBannerDisplayed;

  const theme = useTheme();
  const smallScreenMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const aboveContainerMaxWidth = useMediaQuery(theme.breakpoints.up(containerMaxWidth || "md"));

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Box className={cx(classes.page)} {...rest}>
      <HeaderErrorBoundary>{header}</HeaderErrorBoundary>

      <ErrorBoundary>
        {/* Even when gutters are disabled, we still want to have gutters on mobile for the banner */}
        <Container
          maxWidth={containerMaxWidth}
          className={bannerClassName}
          disableGutters={disableGutters && aboveContainerMaxWidth}
        >
          {showPhoneOrientationBanner && <PhoneOrientationBanner />}
          {banner}
        </Container>

        {showContainer ? (
          <Container
            maxWidth={containerMaxWidth}
            className={cx(classes.container, containerClassName)}
            disableGutters={disableGutters || (disableGuttersSmallScreen && smallScreenMatches)}
          >
            {children}
          </Container>
        ) : (
          <div className={cx(classes.wrapper, containerClassName)}>{children}</div>
        )}
      </ErrorBoundary>
    </Box>
  );
};

export default Page;
