import { useMemo } from "react";

import { GettingStartedAction, GettingStartedActionType } from "types/api/generated/directory-internal";

import useSupplierGettingStarted from "./useSupplierGettingStarted";

/**
 * React hook to return whether or not a supplier is in the onboarding state.
 *
 * We've often asked for a simple flag on the company to indicate this, but the
 * API team keep saying no, so this hook wraps the logic of figuring it out into
 * a single place so other parts of our app don't need to reach everywhere to
 * figure it out for themselves.
 */
export default function useIsSupplierOnboarding(): boolean {
  const { actions } = useSupplierGettingStarted(false);
  const isSupplierOnboardingResult = isSupplierOnboarding(actions);

  return useMemo(() => isSupplierOnboardingResult, [isSupplierOnboardingResult]);
}

/**
 * The underlying function to check if a supplier is in the onboarding state or
 * not.  Exposed for cases that need to run this check manually.
 */
export function isSupplierOnboarding(actions: GettingStartedAction[]): boolean {
  return actions.some(
    action => action.actionType === GettingStartedActionType.SupplierActivation && !action.isCompleted
  );
}
