import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FunctionComponent, useContext } from "react";
import { makeStyles } from "tss-react/mui";

import { SoftwareKeyboardContext } from "mobile/SoftwareKeyboardDetector";

const useStyles = makeStyles()(theme => ({
  footer: {
    borderTop: `1px solid ${theme.palette.grey["300"]}`,
    padding: theme.spacing(1, 2),
    paddingBottom: "calc(env(safe-area-inset-bottom) + 8px)", // fix for mobile devices with bottom "notch"/home button
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 4)
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: `calc(${theme.mixins.footer.height}px - ${theme.spacing(4)})`
  }
}));

export interface FooterProps extends BoxProps {
  className?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ children, className, ...rest }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("md"));
  const softwareKeyboardVisible = useContext(SoftwareKeyboardContext);
  const renderFooter = !(softwareKeyboardVisible && isXsDown);

  return renderFooter ? (
    <Box className={cx(classes.footer, className)} {...rest}>
      {children}
    </Box>
  ) : null;
};

export default Footer;
