import CameraIcon from "@mui/icons-material/CameraAlt";
import { Box, Stack, Typography } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useState } from "react";
import { makeStyles } from "tss-react/mui";

import Image from "components/Image/Image";
import Modal, { ModalProps } from "components/Modal";
import StackedPrice from "components/StackedPrice";
import useMessages from "i18n/hooks/useMessages";
import { BuyerProductSummary } from "types/api/generated/buyer";
import { SupplierProductSummary } from "types/api/generated/supplier";

import strings from "./ProductDetailsModal.strings.json";

const useStyles = makeStyles()(theme => ({
  content: {
    padding: 0
  },
  image: {
    width: "100%",
    height: "auto",
    aspectRatio: 1,
    outline: "none",
    borderRadius: 0
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(8)
  },
  imageWrapper: {
    overflowY: "hidden",
    margin: theme.spacing(3, 3, 0)
  },
  thumbnail: {
    outline: "none"
  },
  selectedThumbnail: {
    outline: "1px solid rgba(0, 0, 0, 0.15)"
  }
}));

interface ProductDetailsModalProps extends ModalProps {
  product: BuyerProductSummary | SupplierProductSummary;
}

const ProductDetailsModal: FunctionComponent<ProductDetailsModalProps> = ({ product, ...rest }) => {
  const { classes, cx } = useStyles();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const [selectedImage, setSelectedImage] = useState(product.imagesMetadata?.images?.[0]);

  const images = product.imagesMetadata?.images;
  const supplier = "supplierName" in product ? product.supplierName : undefined;
  const name = "productName" in product ? product.productName : product.name;
  const code = "productCode" in product ? product.productCode : product.code;

  return (
    <Modal data-testid="product-details-modal" contentProps={{ className: classes.content }} fullWidth {...rest}>
      <Image
        image={selectedImage}
        className={classes.image}
        data-testid={`main-image-${selectedImage?.id}`}
        emptyState={
          <Box className={classes.emptyState}>
            <CameraIcon color="disabled" fontSize="large" />
          </Box>
        }
        alt={formatter.format(strings.PRODUCT_IMAGE_ALT, { name })}
      />
      {images && images.length > 1 && (
        <Stack direction="row" gap={2} className={classes.imageWrapper}>
          {images.map(image => (
            <Image
              onClick={() => setSelectedImage(image)}
              key={image.id}
              image={image}
              className={cx(classes.thumbnail, { [classes.selectedThumbnail]: selectedImage?.id === image.id })}
              data-testid={`thumbnail-image-${image.id}`}
              alt={formatter.format(strings.PRODUCT_THUMBNAIL_IMAGE_ALT, { name })}
              sizes="48px"
            />
          ))}
        </Stack>
      )}
      <Stack gap={2} padding={3}>
        <Box>
          {supplier && (
            <Typography variant="caption" color="textSecondary">
              {supplier}
            </Typography>
          )}
          <Typography variant="h2">{name}</Typography>
        </Box>
        {product.description && <Typography whiteSpace="pre-line">{product.description}</Typography>}
        <Box>
          {!!product.buyerPrice?.amount && <StackedPrice price={product.buyerPrice.amount} />}
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage id={messages.CODE} values={{ code }} />
          </Typography>
        </Box>
      </Stack>
    </Modal>
  );
};

export default ProductDetailsModal;
