import { Auth0ContextInterface } from "@auth0/auth0-react";
import { select } from "redux-saga/effects";

import {
  loadProductsFailure,
  LoadProductsRequest,
  loadProductsSuccess,
  ProductsState
} from "redux/reducers/supplier/products";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { ProductStatus, SupplierProductSummaryPagedSet } from "types/api/generated/supplier";
import { buildSearchString } from "utils/urls";

/**
 * Calls the API to fetch the products available to the supplier.
 */
export function fetchProductsApi(
  auth: Auth0ContextInterface,
  tenancyId: string,
  search?: string,
  status?: ProductStatus[],
  buyerId?: string,
  limit?: number,
  pageToken?: number,
  sortBy?: string
): Promise<SupplierProductSummaryPagedSet> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1.1/products${buildSearchString({
      search,
      buyerId,
      limit,
      pageToken,
      sortBy,
      status
    })}`,
    tenancyId
  );
}

/**
 * Saga task for fetching data from the `/supplier/v1.1/products` API endpoint,
 * then placing it in the {@link products} state slice.
 */
export default createSaga(fetchProductsApi, {
  *prepareApiMethodParams({ buyerId, status, limit, pageToken, search, sortBy }: LoadProductsRequest["payload"] = {}) {
    const { limit: defaultLimit } = (yield select(state => state.supplier.products)) as ProductsState;
    return [search, status, buyerId, limit || defaultLimit, pageToken, sortBy];
  },
  prepareSuccessActionParams: (action: LoadProductsRequest, response) => [
    {
      ...response,
      overrideList: !!action.payload.overrideList
    }
  ],
  successAction: loadProductsSuccess,
  failureAction: loadProductsFailure,
  rethrowError: false
});
