import { createContext, JSX, ReactNode, useState } from "react";
import { useMount } from "react-use";

export const SoftwareKeyboardContext = createContext(false);

const supportsVisualViewport = !!window.visualViewport;

/**
 * Makes a best attempt to get the viewport height by first using the
 * [VisualViewport API](https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API)
 * if present, before falling back to a dumber `window.innerHeight` check.
 */
function getViewportHeight(): number {
  return supportsVisualViewport ? window.visualViewport!.height : window.innerHeight;
}

const initialViewportHeight = getViewportHeight();

interface SoftwareKeyboardDetectorProps {
  children: ReactNode;
}

/**
 * Component for checking changes in the viewport height as a proxy for
 * determining whether a software keyboard is visible.
 */
export default function SoftwareKeyboardDetector({ children }: SoftwareKeyboardDetectorProps): JSX.Element {
  const [keyboardVisible, setKeyboardVisible] = useState(false);

  useMount(() => {
    const resizeListener = (): void => {
      const currentViewportHeight = getViewportHeight();
      // Consider the viewport as shrinking if it is below some threshold of the
      // original height.  This is to take into account small reductions in
      // height from the additional/removal of chrome in mobile browser UIs (eg:
      // Safari shrinks/grows the address bar and controls, Chrome and Firefox
      // on Android hide/show the address bar).
      if (currentViewportHeight < initialViewportHeight - 50) {
        setKeyboardVisible(true);
      } else {
        setKeyboardVisible(false);
      }
    };

    if (supportsVisualViewport) {
      window.visualViewport!.addEventListener("resize", resizeListener);
    } else {
      window.addEventListener("resize", resizeListener);
    }
  });

  return <SoftwareKeyboardContext.Provider value={keyboardVisible}>{children}</SoftwareKeyboardContext.Provider>;
}
