import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  globalNavRendered: boolean;
  globalNavVisible: boolean;
  isSupplierNavDisplayed: boolean;
  mobileNavRendered: boolean;

  /**
   * Intercom notifications, badge appears next to help icons when this is more
   * than one.
   */
  notificationsCount: number;

  phoneOrientationBannerDisplayed: boolean;
}

const initialState: AppState = {
  globalNavRendered: false,
  globalNavVisible: false,
  isSupplierNavDisplayed: true,
  mobileNavRendered: false,
  notificationsCount: 0,
  phoneOrientationBannerDisplayed: true
};

export type UpdateNotificationsCount = PayloadAction<number>;
export type ShowSupplierNavMenu = PayloadAction<boolean>;

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    addGlobalNav: state => ({
      ...state,
      globalNavRendered: true
    }),
    removeGlobalNav: state => ({
      ...state,
      globalNavRendered: false
    }),
    showGlobalNav: state => ({
      ...state,
      globalNavVisible: true
    }),
    hideGlobalNav: state => ({
      ...state,
      globalNavVisible: false
    }),
    addMobileNav: state => ({
      ...state,
      mobileNavRendered: true
    }),
    removeMobileNav: state => ({
      ...state,
      mobileNavRendered: false
    }),
    updateNotificationsCount: (state, { payload }: UpdateNotificationsCount) => ({
      ...state,
      notificationsCount: payload
    }),
    showSupplierNavMenu: (state, { payload: isSupplierNavDisplayed }: ShowSupplierNavMenu) => ({
      ...state,
      isSupplierNavDisplayed
    }),
    hidePhoneOrientationBanner: state => ({
      ...state,
      phoneOrientationBannerDisplayed: false
    })
  }
});

export const {
  addGlobalNav,
  removeGlobalNav,
  showGlobalNav,
  hideGlobalNav,
  addMobileNav,
  removeMobileNav,
  updateNotificationsCount,
  showSupplierNavMenu,
  hidePhoneOrientationBanner
} = appSlice.actions;

export default appSlice.reducer;
