import TableCell from "@mui/material/TableCell";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { ChangeEvent, FunctionComponent, KeyboardEvent, useRef, useState } from "react";
import { useUpdate, useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";

import CurrencyInput, { CurrencyInputProps } from "components/CurrencyInput";
import { isNullOrUndefined } from "utils/helpers";
import { hasNumericValue } from "utils/numbers";

import strings from "./CurrencyCell.strings.json";

const useStyles = makeStyles()(theme => ({
  priceTableCell: {
    height: "100%",
    padding: 0,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    minWidth: "28px",
    "&:focus-within": {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: "-2px"
    }
  },
  currencyInput: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    border: "none",
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.text.primary,
    background: "none",
    textAlign: "right",
    "&:focus": {
      outline: "none"
    },
    "&:disabled": {
      color: theme.palette.text.disabled
    }
  }
}));

interface Props extends Omit<CurrencyInputProps, "onBlur"> {
  onBlur?: (value: CurrencyInputProps["value"]) => void;
  inputClassName?: string;
  className?: string;
}
/**
 * A TableCell wrapper around our {@link CurrencyInput} with it's own set of
 * business rules:
 *  - Applies a prefix of $ if there is a value
 *  - Focuses the input on click of the table cell
 *  - Handles the value in React state instead of using a form
 */
const CurrencyCell: FunctionComponent<Props> = ({
  name = "",
  onBlur = () => {},
  value = "",
  className,
  inputClassName,
  prefix = "$",
  ...rest
}) => {
  const { cx, classes } = useStyles();
  const [currentValue, setCurrentValue] = useState<CurrencyInputProps["value"]>(value);
  const [hasPrefix, setHasPrefix] = useState<boolean>(hasNumericValue(currentValue));
  const update = useUpdate();
  const { formatter } = useMessageFormatter();
  const inputEl = useRef<HTMLInputElement>(null);

  const handleOnBlur = (): void => {
    const isNumber = hasNumericValue(currentValue);
    // Only call `onBlur` with valid numbers and reset the input to the original value if it contains an invalid number on blur
    if (isNumber) {
      onBlur(currentValue);
      setHasPrefix(true);
    } else {
      setCurrentValue(value);
      setHasPrefix(hasNumericValue(value));
    }

    /**
     * Manually force an update of the component on blur otherwise it doesn't take the newly formatted value
     * Relates to this issue logged recently https://github.com/s-yadav/react-number-format/issues/455
     */
    update();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { target } = e;
    if (!isNullOrUndefined(target.value) && target.value !== "") {
      setCurrentValue(target.value);
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (e.key === "Enter") {
      inputEl.current?.blur();
    }
  };

  useUpdateEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
      setHasPrefix(hasNumericValue(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TableCell className={cx(classes.priceTableCell, className)}>
      <CurrencyInput
        ref={inputEl}
        name={name}
        value={currentValue}
        placeholder={formatter.format(strings.ENTERAPRICE)}
        className={cx(classes.currencyInput, inputClassName)}
        onBlur={handleOnBlur}
        prefix={hasPrefix ? prefix : ""}
        autoComplete="off"
        data-testid={`${name}-currencycell`}
        {...rest}
        onKeyDown={onKeyPress}
        onChange={handleOnChange}
      />
    </TableCell>
  );
};

export default CurrencyCell;
