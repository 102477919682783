import { createSlice } from "@reduxjs/toolkit";

export interface GlobalErrorState {
  error: any;
}

const initialState: GlobalErrorState = {
  error: null
};

/**
 * Global state to hold errors, if anything is inside this state an error boundary will be thrown
 *  and not removed until the page is refreshed.
 */
const globalError = createSlice({
  name: "globalError",
  initialState,
  reducers: {
    recordError: (state, { payload: error }) => ({ ...state, error })
  }
});

export const { recordError } = globalError.actions;

export default globalError.reducer;
