import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import debounce from "lodash.debounce";
import { useMemo } from "react";
import { SetOptional, SetRequired } from "type-fest";

import { loginAgain } from "auth/auth0";
import { logger } from "utils/Datadog";
import { isNullOrUndefined } from "utils/helpers";
import useTenancyId from "utils/hooks/useTenancyId";
import { TENANCY_NONE_FOUND } from "utils/Tenancy";
import { buildSearchString } from "utils/urls";

import fetchV2, { FETCH_NETWORK_ERROR, FetchV2Error, isFetchV2Error, isJsonMediaType } from "./fetchV2";
import { isWaitingForNetworkConnectivity, waitForNetworkConnectivity } from "./offlineHandler";

// Debounce the Auth0 error handling because if multiple requests are executed at the same time,
// if there's an Auth0 error, all the requests will likely return the same error at the same time.
const handleAuth0Error = debounce(
  (auth: Auth0ContextInterface, error: unknown): void => {
    const errorMessage = error instanceof Error ? error.message : "Unknown error";
    // Call logout when there's an Auth0 error clear out any bad state the user might have
    logger.info(`Auth0 fetch error: ${errorMessage}`, { error }, error instanceof Error ? error : undefined);

    loginAgain(auth);
  },
  500,
  { leading: true, trailing: false }
);

export type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface ApiFetchResponse<D> {
  headers: Headers;
  data: D;
}

export type SearchParams = Record<
  string,
  string | number | boolean | undefined | null | Array<string | number | boolean>
>;

export interface ApiFetchRequestInit extends Omit<RequestInit, "headers"> {
  headers?: Record<string, string>;
}

export interface ApiFetchArgs<REQ, SEA, RES> {
  /** The Auth0 context. */
  auth?: Auth0ContextInterface;
  /** ID of company the request is acting on. */
  tenancyId?: string;
  /** The HTTP method. */
  method: Method;
  /**
   * The request URL path.
   *
   * If you want to change the protocol or hostname, change the default `baseUrl`.
   */
  path: string;
  /**
   * The protocol and hostname sections of the request URL.
   *
   * Defaults to the `REACT_APP_API_URL` environment variable.
   */
  baseUrl?: string;
  /** Object to be encoded as the query string. */
  searchParams?: SEA;
  /** The request body. */
  data?: REQ;
  /** Any custom HTTP headers to be sent with the request. */
  headers?: Record<string, string>;
  /**
   * What the response body should be decoded as.
   *
   * By default it'll try to infer the response type from the `Content-Type` header, otherwise it falls back to `text`.
   */
  responseType?: "arrayBuffer" | "blob" | "formData" | "json" | "text";
  /** Additional options to pass to the underlying native `fetch` function. */
  fetchOptions?: ApiFetchRequestInit;
  /**
   * Whether to force the data to be JSON encoded.
   *
   * This is mostly useful for forcing strings to be JSON encoded (because by default they're considered as valid values for the body as is).
   */
  json?: boolean;
  /** Dummy properties for inferring the types from `buildApiFetchOptions` helpers. */
  _requestBody?: REQ;
  _responseBody?: RES;
}

type ApiFetchOptionsInternal = SetRequired<ApiFetchRequestInit, "method" | "headers">;

/**
 * `fetch()` wrapper for making API calls that includes a bunch of useful features like strict typing,
 * automatic JSON encoding/parsing, Auth0 login handling, offline handling, query string encoding, etc.
 */
export async function apiFetch<REQ = unknown, SEA extends SearchParams | unknown = unknown, RES = unknown>(
  args: ApiFetchArgs<REQ, SEA, RES>
): Promise<ApiFetchResponse<RES>> {
  const {
    auth,
    tenancyId,
    method,
    path,
    baseUrl = process.env.REACT_APP_API_URL,
    searchParams,
    data,
    headers = {},
    responseType,
    fetchOptions: fetchOpts,
    json: forceJsonEncoding
  } = args;

  // If we're currently waiting for network connectivity, wait instead of trying to make the request
  if (isWaitingForNetworkConnectivity()) {
    await waitForNetworkConnectivity();
  }

  const fetchOptions: ApiFetchOptionsInternal = {
    method,
    headers,
    ...fetchOpts
  };

  if (tenancyId && tenancyId !== TENANCY_NONE_FOUND) {
    fetchOptions.headers["X-Tenant-Id"] = tenancyId;
  }

  if (!isNullOrUndefined(data)) {
    if (
      !forceJsonEncoding &&
      (typeof data === "string" || data instanceof FormData || data instanceof Blob || data instanceof ArrayBuffer)
    ) {
      fetchOptions.body = data;
    } else {
      fetchOptions.headers["Content-Type"] = "application/json";
      fetchOptions.body = JSON.stringify(data);
    }
  }

  let url = `${baseUrl}${path}`;

  if (searchParams) {
    if (new URL(path, baseUrl).search) {
      throw new FetchV2Error("The 'path' can't contain a query string when 'searchParams' is defined", {
        url,
        request: fetchOptions
      });
    }
    url = `${url}${buildSearchString(searchParams)}`;
  }

  try {
    if (auth) {
      try {
        const accessToken = await auth.getAccessTokenSilently();
        fetchOptions.headers.Authorization = `Bearer ${accessToken}`;
      } catch (error: unknown) {
        // Catch network errors so they can trigger the offline handling.
        // Auth0 network errors aren't being retried here because it appears that the Auth0 SDK already does 3 retries before it throws.
        if (error instanceof TypeError) {
          const err = new FetchV2Error("Network error fetching Auth0 access token", { url, request: fetchOptions });
          err.code = FETCH_NETWORK_ERROR;
          throw err;
        }

        handleAuth0Error(auth, error);

        // Return a promise that never resolves so the request doesn't execute
        // but any loaders continue to show while the login redirect happens
        return new Promise(() => {});
      }
    }

    const response = await fetchV2(url, fetchOptions);

    let responseData: any;
    if (responseType) {
      responseData = await response[responseType]();
    } else if (isJsonMediaType(response.headers.get("content-type"))) {
      responseData = await response.json();
    } else {
      responseData = await response.text();
    }

    return {
      headers: response.headers,
      data: responseData
    };
  } catch (error: unknown) {
    if (isFetchV2Error(error)) {
      if (error.code === FETCH_NETWORK_ERROR) {
        // If a network error occurs, wait for network connectivity and then retry the request
        await waitForNetworkConnectivity();
        return apiFetch(args);
      }

      if (auth && error.status === 401) {
        // Handle HTTP 401 (Unauthorized) errors in the same way as Auth0 errors
        handleAuth0Error(auth, error);
        // Return a promise that never resolves so no errors are displayed to the user and
        // any loaders continue to show while the login redirect happens
        return new Promise(() => {});
      }
    }
    throw error;
  }
}

export type UseApiFetchReturn = <REQ = unknown, SEA extends SearchParams | unknown = unknown, RES = unknown>(
  args: SetOptional<ApiFetchArgs<REQ, SEA, RES>, "auth" | "tenancyId">
) => Promise<ApiFetchResponse<RES>>;

/**
 * A small helper hook that pre-fills the `auth` and `tenancyId` parameters.
 */
export function useApiFetch(): UseApiFetchReturn {
  const auth = useAuth0();
  const tenancyId = useTenancyId();

  return useMemo(() => {
    return args => apiFetch({ auth, tenancyId, ...args });
  }, [auth, tenancyId]);
}

/**
 * A small helper hook that pre-fills the `tenancyId` parameters and works without auth.
 */
export function useUnauthenticatedApiFetch(): UseApiFetchReturn {
  const tenancyId = useTenancyId();

  return useMemo(() => {
    return args => apiFetch({ auth: undefined, tenancyId, ...args });
  }, [tenancyId]);
}

/**
 * A small factory function that allows the method/path parameters and the request/response types to
 * be easily shared between the fetch call and the test mocks without code duplication.
 */
export function buildApiFetchOptions<
  REQ = unknown,
  SEA extends SearchParams | unknown = unknown,
  RES = unknown,
  P = void
>(
  callback: (params: P) => ApiFetchArgs<REQ, SEA, RES>
): (params: P, args?: Omit<ApiFetchArgs<REQ, SEA, RES>, "method" | "path">) => ApiFetchArgs<REQ, SEA, RES> {
  return (params, args) => ({
    ...callback(params),
    ...args
  });
}
