import { useMount } from "react-use";

import { html, PrimitiveHtmlAttributeValues } from "utils/Html";

/**
 * React hook for loading an external script by inserting a `<script>` tag into
 * the document.  The script will only ever be added once.
 *
 * @param src
 *   The URL to the external script.  If it is a falsey value, then no
 *   `<script>` tag is created.
 * @param options
 *   Attributes to apply to the `<script>` tag.
 */
export default function useScript(src: string | null, options: PrimitiveHtmlAttributeValues<"script"> = {}): void {
  useMount(() => {
    if (src) {
      const existingScriptEl = document.querySelector(`script[src="${src}"]`);
      if (!existingScriptEl) {
        const scriptEl = html("script", {
          ...options,
          src
        });
        document.head.appendChild(scriptEl);
      }
    }
  });
}
