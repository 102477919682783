import { ReactNode, useMemo } from "react";
import { useDispatch } from "react-redux";

import { showSnackbar as showSnackbarAction, SnackbarState } from "redux/reducers/snackbar";

interface UseSnackbarResult {
  showSnackbar: (message: string | ReactNode, options?: Partial<Omit<SnackbarState, "message">>) => void;
}

/**
 * React hook for controlling the global snackbar.
 *
 * @return {Object}
 *   An object with a property `showSnackbar`, which is a function for bringing
 *   up the global snackbar.  The function takes 2 parameters: the message to
 *   show in the snackbar, and an object that is spread as props over the
 *   `<GlobalSnackbar>` component.
 */
export default function useSnackbar(): UseSnackbarResult {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      showSnackbar: (message, snackbarProps) => {
        dispatch(
          showSnackbarAction({
            message,
            ...snackbarProps
          })
        );
      }
    }),
    [dispatch]
  );
}
