import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { PendingSupplierSummaryPagedSet } from "types/api/generated/buyer";
import { buildSearchString } from "utils/urls";

import {
  fetchPendingLeadsFailure,
  FetchPendingLeadsRequest,
  FetchPendingLeadsSuccess,
  fetchPendingLeadsSuccess
} from "../reducers/pendingLeads";

/**
 * Calls the API the fetch the pending supplier leads for a buyer.
 */
function fetchPendingLeads(
  auth: Auth0ContextInterface,
  tenancyId: string,
  name?: string
): Promise<PendingSupplierSummaryPagedSet> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/pending/leads${buildSearchString({
    name
  })}`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Saga to fetch the pending supplier leads for a buyer
 */
export default createSaga(fetchPendingLeads, {
  prepareApiMethodParams: ({ name }: FetchPendingLeadsRequest["payload"] = {}) => [name],
  successAction: fetchPendingLeadsSuccess,
  prepareSuccessActionParams: (_action: FetchPendingLeadsSuccess, response: PendingSupplierSummaryPagedSet) => [
    {
      ...response,
      overrideList: true
    }
  ],
  errorFailureAction: fetchPendingLeadsFailure
});
