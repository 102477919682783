import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import AccountDropDownMenu from "components/AccountDropDownMenu";
import Button from "components/Button";
import CompanyLogo from "components/CompanyLogo/CompanyLogo";
import LogoHeader from "components/Header/LogoHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import { createCompanyInviteRequest, fetchCompanyProfileByIdRequest } from "redux/reducers/company";
import { ClaimCompany, CompanyRoot } from "routes/Routes";
import { AddressType, CompanyProfile, InviteFromClaimTokenResult } from "types/api/generated/directory";
import { getDefaultAddress } from "utils/Addresses";
import { useAnalytics } from "utils/analytics";
import { trackError } from "utils/Errors";
import useAppSelector from "utils/hooks/useAppSelector";
import useUrlParams from "utils/hooks/useUrlParams";
import { showNewMessage } from "utils/Intercom";

import strings from "./ClaimCompanyPage.strings.json";

const DIRECTORY_CLAIM_COMPANY_TOKEN = "token";

const useStyles = makeStyles()(() => ({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const ClaimCompanyPage: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const urlToken = useUrlParams(DIRECTORY_CLAIM_COMPANY_TOKEN);
  const { user } = useAuth0();
  const { formatter } = useMessageFormatter();
  const { tenancyId } = useParams<typeof ClaimCompany.params>();
  const { inviting } = useAppSelector(state => state.company);
  const [invite, setInvite] = useState<InviteFromClaimTokenResult>();
  const [profile, setProfile] = useState<CompanyProfile>();
  const [loading, setLoading] = useState(true);

  const token = urlToken.getValue();

  useMount(() => {
    dispatch(
      fetchCompanyProfileByIdRequest({
        id: tenancyId,
        successCallback(companyProfile) {
          // Company already claimed, head to the dashboard
          if (companyProfile.isClaimed) {
            history.replace(CompanyRoot.toUrl({ tenancyId }));
          } else {
            setProfile(companyProfile);
            setLoading(false);
          }
        }
      })
    );
  });

  const onCompanyClaim = (): void => {
    analytics.track("Claim business clicked", { companyId: tenancyId });

    dispatch(
      createCompanyInviteRequest({
        id: tenancyId,
        token,
        successCallback: setInvite,
        failureCallback: error => {
          trackError(error);
          history.push(CompanyRoot.toUrl({ tenancyId }));
        }
      })
    );
  };

  const onJoinOtherCompany = (): void => {
    analytics.track("ClaimCompanyPageJoinOtherBusinessClicked");
    showNewMessage();
  };

  const onUseAnotherEmailAddress = (): void => {
    analytics.track("ClaimCompanyPageUseDifferentEmailClicked");
    showNewMessage(
      formatter.format(messages.HELP_DIFFERENT_EMAIL_PREFILL, {
        tradingName: profile?.tradingName,
        tenancyId,
        email: user?.email
      })
    );
  };

  return (
    <Page
      header={<LogoHeader title={formatter.format(messages.TITLE)} LeftComp={<AccountDropDownMenu />} />}
      containerClassName={cx(classes.page)}
      containerMaxWidth="sm"
      isLoading={loading}
    >
      <Box sx={{ pb: 2 }}>
        <LogoIcon width={40} height={40} />
      </Box>
      {invite ? (
        <Box sx={{ maxWidth: 396, textAlign: "center" }}>
          <Typography variant="h1" sx={{ pb: 1.5 }}>
            <FormattedMessage id={messages.HEADER_INVITED} values={{ email: invite.invitedEmail }} />
          </Typography>
          <Typography color="textSecondary" sx={{ pb: 5 }}>
            <FormattedMessage id={messages.SUBHEADER_INVITED} />
          </Typography>
          <Typography variant="h2" sx={{ pb: 1.5 }}>
            <FormattedMessage id={messages.INCORRECT_EMAIL_HEADER} />
          </Typography>
          <Typography color="textSecondary">
            <FormattedMessage id={messages.INCORRECT_EMAIL_SUBHEADER} />
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ maxWidth: 396, textAlign: "center" }}>
            <Typography variant="h1" sx={{ pb: 1.5 }}>
              <FormattedMessage id={messages.HEADER} />
            </Typography>
            <Typography color="textSecondary">
              {profile?.isBuyer && profile.isSupplier ? (
                <FormattedMessage id={messages.SUPPLIER_AND_BUYER_SUBHEADER} />
              ) : profile?.isBuyer ? (
                <FormattedMessage id={messages.BUYER_SUBHEADER} />
              ) : profile?.isSupplier ? (
                <FormattedMessage id={messages.SUPPLIER_SUBHEADER} />
              ) : null}
            </Typography>
          </Box>
          {profile && (
            <Card sx={{ mt: 4, borderRadius: 2, width: "100%" }}>
              <CardActionArea onClick={onCompanyClaim}>
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <CompanyLogo
                    logoUrl={profile.logoUrl}
                    tradingName={profile.tradingName}
                    sx={{ width: 64, height: 64 }}
                  />
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="h3" sx={{ pb: 1 }}>
                      {profile.tradingName}
                    </Typography>
                    <Typography color="textSecondary">
                      {getDefaultAddress(profile?.addresses, AddressType.Physical)?.full ?? profile.primaryAddress}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onCompanyClaim}
            showLoader={inviting}
            disabled={!token}
            sx={{ mt: 3 }}
          >
            <FormattedMessage id={messages.CLAIM_BUTTON} />
          </Button>
        </>
      )}
      {invite ? (
        <Button color="primary" onClick={onUseAnotherEmailAddress} sx={{ mt: 3 }}>
          <FormattedMessage id={messages.HELP_DIFFERENT_EMAIL} />
        </Button>
      ) : (
        <Button color="primary" onClick={onJoinOtherCompany} sx={{ mt: 3 }}>
          <FormattedMessage id={messages.HELP_DIFFERENT_BUSINESS} />
        </Button>
      )}
    </Page>
  );
};

export default ClaimCompanyPage;
