import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put, select } from "redux-saga/effects";

import { fetchUsersForCompanyFailure, fetchUsersForCompanySuccess } from "redux/reducers/tenancy";
import { fetchDataFromApiWrapper } from "services/helpers";
import { DirectoryInternal } from "types/api";

/**
 * Calls the API /directory/internal/users to fetch the users that belong to the current company
 */
export const fetchUsersForCompanyApi = (
  auth: Auth0ContextInterface,
  tenancyId: string
): Promise<DirectoryInternal.InternalUsersList.ResponseBody> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/directory/internal/users`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
};

/**
 * Calls the API to fetch all the users that are associated with the current tenant
 */
export default function* fetchUsersForCompany(): Generator {
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const tenancyId = (yield select(state => state.tenancy.companyId)) as string;
  try {
    const data = (yield call(
      fetchUsersForCompanyApi,
      auth,
      tenancyId
    )) as DirectoryInternal.InternalUsersList.ResponseBody;
    yield put(fetchUsersForCompanySuccess(data));
  } catch (e: any) {
    yield put(fetchUsersForCompanyFailure(e.message));
  }
}
