import { TypeHandler } from "@ultraq/icu-message-formatter";
import { JSX } from "react";

import IntercomHelpArticle, { IntercomHelpArticleProps } from "components/IntercomHelpArticle";

/**
 * Type handler for the `intercomArticle` type, which is a wrapper around the
 * `<IntercomHelpArticle>` component.
 */
const intercomArticleTypeHandler: TypeHandler = (
  props: Omit<IntercomHelpArticleProps, "children">,
  helpText,
  _,
  values,
  process
): JSX.Element => {
  return <IntercomHelpArticle {...props}>{process(helpText, values)}</IntercomHelpArticle>;
};

export default intercomArticleTypeHandler;
