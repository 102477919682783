import { Collapse, Fade, Paper, PaperProps } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      borderRadius: theme.shape.borderRadius
    },
    "& + *": {
      marginTop: theme.spacing(2)
    }
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-2)
  },
  contentItem: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  iconWrapper: {
    display: "flex",
    marginRight: theme.spacing(1.5)
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "flex-end"
    }
  }
}));

export interface BannerProps extends PaperProps {
  actionsClassName?: string;
  className?: string;
  /** Positioned at the far right of the banner */
  actions?: ReactNode;
  /** Positioned at the left of the banner */
  icon?: ReactNode;
}

/**
 * Generic banner component that generally appears under the header on a page.
 *
 * Best used in the `banner` prop of the `Page` component.
 */
const Banner: FunctionComponent<BannerProps> = ({ actionsClassName, className, icon, children, actions, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <Collapse timeout={300} in appear>
      <Fade timeout={400} in appear>
        <Paper elevation={2} className={cx(classes.root, className)} role="banner" {...props}>
          {icon && <div className={cx(classes.iconWrapper)}>{icon}</div>}

          <div className={cx(classes.contentWrapper)}>
            <div className={cx(classes.contentItem)}>{children}</div>
            {actions && (
              <div className={cx(classes.actionsWrapper, classes.contentItem, actionsClassName)}>{actions}</div>
            )}
          </div>
        </Paper>
      </Fade>
    </Collapse>
  );
};

export default Banner;
