import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import React, { ReactElement, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Tooltip from "components/Tooltip";
import { ProductTabs } from "features/suppliers/products/views/product/ProductTabs";
import useMessages from "i18n/hooks/useMessages";
import { GenericProductTaxSetup, SupplierProduct, SupplierViewOrder } from "routes/Routes";
import { OrderLineProblemType } from "types/api/generated/supplier";
import { LocalOrderLineProblem, LocalOrderLineProblemType } from "types/Orders";
import useCountryConfig from "utils/hooks/useCountryConfig";
import { SELECTEDTAB_URL_PARAM } from "utils/hooks/useTabs";

import strings from "./LineProblemTooltip.strings.json";

const useStyles = makeStyles()(theme => ({
  list: {
    margin: 0,
    padding: 0,
    "& > li": {
      marginLeft: theme.spacing(2)
    }
  },
  link: {
    margin: theme.spacing(0, 0, 1)
  },
  unstyledLink: {
    textDecoration: "inherit",
    lineHeight: 1.75
  },
  learnMoreLink: {
    marginBottom: theme.spacing(0.5)
  }
}));

interface LineProblemTooltipProps {
  className?: string;
  problems?: LocalOrderLineProblem[];
  productId?: string;
  children: ReactElement;
}

/**
 * Maps the problem type to a message we display next to a line problem chip for certain problems
 */
const LineProblemTooltip = React.forwardRef<unknown, LineProblemTooltipProps>((props, ref) => {
  const { children, className, problems = [], productId } = props;
  const { classes, cx } = useStyles();
  const messages = useMessages(strings);
  const { salesTaxName } = useCountryConfig();
  const { tenancyId } = useParams<typeof SupplierViewOrder.params>();

  const getTipText = (type: LocalOrderLineProblemType | undefined): ReactNode =>
    type === "DifferentPrice" ? (
      <FormattedMessage id={messages.PRICE_PROBLEM_DESCRIPTION} />
    ) : type === "ProductHidden" ? (
      <FormattedMessage id={messages.HIDDEN_PRODUCT_PROBLEM_DESCRIPTION} />
    ) : type === "ProviderProductNotMatched" ? (
      <FormattedMessage id={messages.PRODUCT_NOT_IN_UPSTOCK} />
    ) : type === "ProductArchived" ? (
      <FormattedMessage id={messages.DISCONTIUED_PRODUCT_DESCRIPTION} />
    ) : type === "ProductOutOfStock" ? (
      <FormattedMessage id={messages.OUT_OF_STOCK_DESCRIPTION} />
    ) : type === "NoTaxRatesCoded" ? (
      <FormattedMessage id={messages.NO_TAX_RATES_CODED_DESCRIPTION_NEW} values={{ salesTaxName }} />
    ) : type === "UnitAmountLostPrecision" ? (
      <FormattedMessage id={messages.UNIT_AMOUNT_LOST_PRECISION} />
    ) : type === "LineAmountDidNotMatch" ? (
      <FormattedMessage id={messages.LINE_AMOUNT_MISMATCH} />
    ) : type === "QuantityDidNotMatch" || type === "QuantityLostPrecision" ? (
      <FormattedMessage id={messages.QUANTITY_MISMATCH} />
    ) : type === "MissingBasePrice" ? (
      <FormattedMessage id={messages.MISSING_BASE_PRICE} />
    ) : type === "ProductNotFound" || type === "ProductDeleted" ? (
      <FormattedMessage id={messages.DELETED} />
    ) : null;

  /**
   * Returns true if the problem is related to tax rates and the manual tax rate feature is enabled
   */
  const productTaxRelatedProblem = (type: LocalOrderLineProblemType): boolean | undefined =>
    type === OrderLineProblemType.NoTaxRatesCoded;

  /**
   * Filter the order line problems so we only display 'Learn more' once and any extra links such as the tax related ones.
   */
  const filterOrderProblems = (
    { type }: LocalOrderLineProblem,
    index: number,
    arr: LocalOrderLineProblem[]
  ): boolean => {
    if (productTaxRelatedProblem(type)) {
      return true;
    }
    // Find the first other type (excluding "NoTaxRatesCoded") and keep it
    const otherTypeIndex = arr.findIndex(({ type: t }) => t !== OrderLineProblemType.NoTaxRatesCoded);
    return index === otherTypeIndex;
  };

  const getLinks = (type: LocalOrderLineProblemType | undefined): ReactNode =>
    type && productTaxRelatedProblem(type) ? (
      productId && (
        <div key={type} className={cx(classes.link)}>
          <Link
            className={cx(classes.unstyledLink)}
            to={SupplierProduct.toUrl({ tenancyId, productId }, { [SELECTEDTAB_URL_PARAM]: ProductTabs.Pricing })}
          >
            <Typography component="span">
              <FormattedMessage id={messages.EDIT_THIS_PRODUCT} />
            </Typography>
          </Link>
          <br />
          <Link className={cx(classes.unstyledLink)} to={GenericProductTaxSetup.toUrl({ tenancyId })}>
            <Typography component="span">
              <FormattedMessage id={messages.REVIEW_ALL_MISSING_RATES} />
            </Typography>
          </Link>
        </div>
      )
    ) : (
      <Typography key={type} component="span" className={cx(classes.learnMoreLink)}>
        <FormattedMessage
          id={messages.LEARN_MORE_LINK}
          values={{
            learnMore: {
              articleId: 5325476
            }
          }}
        />
      </Typography>
    );

  return problems.length ? (
    <Tooltip
      data-testid="lineproblem-tooltip"
      ref={ref}
      className={className}
      title={
        problems.length > 1 ? (
          <>
            <ul className={cx(classes.list)}>
              {problems.map(({ type }) => (
                <li key={type}>
                  <Typography paragraph>{getTipText(type)}</Typography>
                </li>
              ))}
            </ul>
            {problems.filter(filterOrderProblems).map(({ type }) => getLinks(type))}
          </>
        ) : (
          <>
            <Typography paragraph>{getTipText(problems?.[0]?.type)}</Typography>
            {getLinks(problems?.filter(filterOrderProblems)?.[0]?.type)}
          </>
        )
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
});

export default LineProblemTooltip;
