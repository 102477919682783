import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { loadProductsWithNoPricesRequest } from "redux/reducers/supplier/products";
import loadNextPageV2 from "redux/tasks/loadNextPageV2";
import loadPreviousPage from "redux/tasks/loadPreviousPage";

import {
  activatePriceListsRequest,
  activatePriceListsSuccess,
  createDefaultPriceListRequest,
  loadNextPriceListsPage
} from "../reducers/allPriceLists";
import { fetchPriceListsMetadataRequest } from "../reducers/metadata";
import {
  createPriceListRequest,
  createPriceListSuccess,
  deletePriceListRequest,
  deletePriceListSuccess,
  duplicatePriceListRequest,
  duplicatePriceListSuccess,
  fetchPriceListRequest,
  renamePriceListRequest,
  renamePriceListSuccess
} from "../reducers/priceList";
import {
  fetchPriceListCustomersRequest,
  loadNextPageRequest as loadNextPriceListCustomersPageRequest,
  updatePriceListCustomersRequest,
  updatePriceListCustomersSuccess
} from "../reducers/priceListCustomers";
import {
  applyDiscountsRequest,
  fetchNextPageRequest as fetchNextPagePriceListProductsRequest,
  fetchPreviousPageRequest as fetchPreviousPagePriceListProductsRequest,
  fetchPriceListProductsRequest,
  hideProductsRequest,
  hideProductsSuccess,
  showProductsRequest,
  showProductsSuccess
} from "../reducers/priceListProducts";
import { fetchPriceListStatsRequest } from "../reducers/priceListStats";

import activatePriceLists from "./activatePriceLists";
import createDefaultPriceList from "./createDefaultPriceList";
import createPriceList from "./createPriceList";
import deletePriceList from "./deletePriceList";
import duplicatePriceList from "./duplicatePriceList";
import fetchAllPriceLists from "./fetchAllPriceLists";
import fetchPriceList from "./fetchPriceList";
import fetchPriceListCustomers from "./fetchPriceListCustomers";
import fetchPriceListProducts from "./fetchPriceListProducts";
import fetchPriceListsMetadata from "./fetchPriceListsMetadata";
import fetchPriceListStats from "./fetchPriceListStats";
import fetchProductWithNoPrices from "./fetchProductWithNoPrices";
import renamePriceList from "./renamePriceList";
import updatePriceListCustomers from "./updatePriceListCustomers";
import updatePriceListProducts from "./updatePriceListProducts";

/**
 * The price lists combined saga.
 */
export default function* priceListsSaga(): Generator {
  yield all([
    takeLatest(createDefaultPriceListRequest, createDefaultPriceList),
    takeLatest(createPriceListRequest, createPriceList),
    takeLatest(deletePriceListRequest, deletePriceList),
    takeLatest(fetchPriceListRequest, fetchPriceList),
    takeLatest(renamePriceListRequest, renamePriceList),
    takeLatest(loadProductsWithNoPricesRequest, fetchProductWithNoPrices),
    takeLatest([fetchPriceListStatsRequest, hideProductsSuccess, showProductsSuccess], fetchPriceListStats),
    takeLatest(fetchPriceListProductsRequest, fetchPriceListProducts),
    takeLatest(fetchPriceListCustomersRequest, fetchPriceListCustomers),
    takeLatest(updatePriceListCustomersRequest, updatePriceListCustomers),
    takeLatest([applyDiscountsRequest, hideProductsRequest, showProductsRequest], updatePriceListProducts),
    takeLatest(activatePriceListsRequest, activatePriceLists),
    takeLatest(
      [
        createPriceListSuccess,
        deletePriceListSuccess,
        renamePriceListSuccess,
        updatePriceListCustomersSuccess,
        duplicatePriceListSuccess
      ],
      fetchAllPriceLists
    ),
    takeEvery(
      [loadNextPriceListsPage, fetchNextPagePriceListProductsRequest, loadNextPriceListCustomersPageRequest],
      loadNextPageV2
    ),
    takeEvery([fetchPreviousPagePriceListProductsRequest], loadPreviousPage),
    takeEvery(duplicatePriceListRequest, duplicatePriceList),
    takeLatest([fetchPriceListsMetadataRequest, activatePriceListsSuccess], fetchPriceListsMetadata)
  ]);
}
