import { DefinedUseQueryResult, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { DirectoryInternal } from "types/api";
import { ApiTokenType, ProviderOffering } from "types/api/generated/directory-internal";
import useAppSelector from "utils/hooks/useAppSelector";
import useTenancyId from "utils/hooks/useTenancyId";

import { connectionsKey } from "./keys";

type RequestBody = DirectoryInternal.InternalConnectList.RequestBody;
type RequestQuery = DirectoryInternal.InternalConnectList.RequestQuery;
type ResponseBody = DirectoryInternal.InternalConnectList.ResponseBody;

export const connectionsOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody>(() => ({
  method: "GET",
  path: "/directory/internal/connect"
}));

type ConnectionsOptions = Pick<UseQueryOptions<ResponseBody, FetchV2Error, ResponseBody>, "select" | "staleTime"> & {
  // This can be called in situations where the tenancyId isn't in the URL yet,
  // which apiFetch relies on, so allow calls to define their own.
  tenancyId?: string;
};

/**
 * React query hook to call `GET /directory/internal/connect` for a list of
 * active third party connections a company has.
 */
export default function useConnections(
  options?: ConnectionsOptions
): DefinedUseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useApiFetch();
  const tenancyIdFromUrl = useTenancyId();
  const { isSupplier } = useAppSelector(state => state.tenancy);
  const { connections } = useAppSelector(state => state.integrations);
  const tenancyId = options?.tenancyId ?? tenancyIdFromUrl;

  return useQuery({
    queryKey: connectionsKey.active.queryKey,
    async queryFn() {
      const response = await apiFetch(connectionsOptions(undefined, { tenancyId }));
      return response.data;
    },
    enabled: !!tenancyId && isSupplier,
    initialData: connections,
    // refetchOnWindowFocus: "always",
    select: options?.select,
    // staleTime: 60000 // 1 hour
    staleTime: options?.staleTime ?? Infinity
  });
}

// The following factory functions can be used to create a selector for `select`
// in the hook above, and replicate what `useProviderConnection` did so that we
// can slowly migrate things from using that.

type Selector = ConnectionsOptions["select"];
type SelectorFactory = (...args: any[]) => Selector;

export const selectByName: SelectorFactory = (name: ApiTokenType) => connections =>
  connections.filter(c => c.name === name);

export const selectByDefaultOffering: SelectorFactory = (feature: ProviderOffering) => connections =>
  connections.filter(c => c.features.some(f => f.isDefault && f.name === feature));
