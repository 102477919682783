import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer, PageLinks, PendingSupplierSummary, PendingSupplierSummaryPagedSet } from "types/api/generated/buyer";
import { ApiValidationProblem } from "types/api/generated/supplier";
import { FailureAction, LoadNextPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";

export interface PendingLeadsState {
  error: ApiValidationProblem | Error | null;
  links: PageLinks | null;
  list: PendingSupplierSummary[];
  loading: boolean;
  total: number;
}

const initialState: PendingLeadsState = {
  list: [],
  links: null,
  total: 0,
  error: null,
  loading: false
};

export type FetchPendingLeadsRequest = PayloadAction<{ name?: string } | void>;
export type FetchPendingLeadsSuccess = PayloadAction<PendingSupplierSummaryPagedSet & PagingOptions>;

export type SubmitNewLeadRequest = RequestAction<
  Buyer.V1SuppliersPendingLeadsCreate.RequestBody,
  Buyer.V1SuppliersPendingLeadsCreate.ResponseBody
>;

type SubmitNewLeadSuccess = PayloadAction<Buyer.V1SuppliersPendingLeadsCreate.ResponseBody>;

/**
 * State slice for pending lead suppliers that a buyer has requested.
 */
const pendingLeadsSlice = createSlice({
  name: "pendingLeads",
  initialState,
  reducers: {
    fetchPendingLeadsRequest: (state, _action: FetchPendingLeadsRequest) => ({
      ...state,
      loading: true
    }),
    fetchPendingLeadsSuccess: (
      state,
      { payload: { data, links, total, overrideList = false } }: FetchPendingLeadsSuccess
    ) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      total: total ?? 0,
      error: null,
      loading: false
    }),
    fetchPendingLeadsFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      error,
      loading: false
    }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest) => ({
      ...state,
      loading: true
    }),
    submitNewLeadRequest: (state, _action: SubmitNewLeadRequest) => ({
      ...state,
      loading: true
    }),
    submitNewLeadSuccess: (state, _: SubmitNewLeadSuccess) => ({
      ...state,
      loading: false
    }),
    submitNewLeadFailure: (state, { payload: error }: FailureAction) => ({
      ...state,
      loading: false,
      error
    }),
    clearPendingLeads: state => ({
      ...state,
      list: [],
      links: null,
      loading: false,
      total: 0
    })
  }
});

export const {
  clearPendingLeads,
  fetchPendingLeadsRequest,
  fetchPendingLeadsSuccess,
  fetchPendingLeadsFailure,
  loadNextPageRequest,
  submitNewLeadRequest,
  submitNewLeadSuccess,
  submitNewLeadFailure
} = pendingLeadsSlice.actions;
export default pendingLeadsSlice.reducer;
