import {
  Auth0Callback,
  CompanyError,
  isUnauthenticatedRoute,
  isExternalRoute,
  isAuthenticatedWithoutCompanyRoute,
  StoreBuyerNewOrderCallback,
  ConnectXeroCallback,
  ConnectCodatCallback,
  ConnectIdentityCallbackRoute,
  ConnectStripeCallback
} from "routes/Routes";

export const blacklistFallbackRoutes = [
  Auth0Callback,
  CompanyError,
  StoreBuyerNewOrderCallback,
  ConnectXeroCallback,
  ConnectCodatCallback,
  ConnectIdentityCallbackRoute,
  ConnectStripeCallback
];

// If the passed pathname is listed above, an external link, authenticated link without a company or unauthenticated link.
// This isn't an acceptable fallback we can redirect to.
export function isPathAcceptableFallback(pathname: string): boolean {
  return (
    !blacklistFallbackRoutes.some(route => route.matches(pathname)) &&
    !isAuthenticatedWithoutCompanyRoute(pathname) &&
    !isUnauthenticatedRoute(pathname) &&
    !isExternalRoute(pathname)
  );
}
