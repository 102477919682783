import { createContext, JSX, ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import Route from "./Route";
import { Root } from "./Routes";

interface Context {
  fallbackRoute?: Route;
  setFallbackRoute: (route: Route) => void;
  previousPathname?: string | null;
}

export const FallbackRouteContext = createContext<Context>({
  setFallbackRoute: () => {}
});

export interface FallbackRouteProviderProps {
  children: ReactNode;
  defaultFallbackRoute?: Route;
}

type PathnameTrackingState = {
  to: string;
  from: string | null;
};

/**
 * Using React context to store and set a fallback route which is then
 * used in our back links. Also recording the previous route visited
 * when the location changes.
 */
export default function FallbackRouteProvider({
  children,
  defaultFallbackRoute = Root
}: FallbackRouteProviderProps): JSX.Element {
  const location = useLocation();
  const [fallbackRoute, setFallbackRoute] = useState(defaultFallbackRoute);
  const [pathnameTracking, setPathnameTracking] = useState<PathnameTrackingState>({
    to: location.pathname,
    from: null
  });

  const context = useMemo(
    () => ({
      fallbackRoute,
      setFallbackRoute,
      previousPathname: pathnameTracking.from
    }),
    [fallbackRoute, setFallbackRoute, pathnameTracking]
  );

  useEffect(() => {
    if (pathnameTracking.to !== location.pathname) {
      setPathnameTracking(
        (prev: PathnameTrackingState): PathnameTrackingState => ({ to: location.pathname, from: prev.to })
      );
    }
  }, [location.pathname, pathnameTracking.to]);

  return <FallbackRouteContext.Provider value={context}>{children}</FallbackRouteContext.Provider>;
}
