import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer, PendingSupplierLinkSummary } from "types/api/generated/buyer";
import { RequestPayload } from "types/redux-helpers";

export interface PendingInvitesState {
  list: PendingSupplierLinkSummary[];
  loading: boolean;
  error: string | null;
}

const initialState: PendingInvitesState = {
  list: [],
  loading: true,
  error: null
};

export type FetchPendingInvitesRequest = PayloadAction<
  RequestPayload<{}, Buyer.V1SuppliersPendingInvitesList.ResponseBody> | undefined
>;
export type FetchPendingInvitesSuccess = PayloadAction<Buyer.V1SuppliersPendingInvitesList.ResponseBody>;

/**
 * State slice for the pending invited suppliers that a buyer has requested.
 */
const pendingInvitesSlice = createSlice({
  name: "pendingInvites",
  initialState,
  reducers: {
    fetchPendingInvitesRequest: (state, _action: FetchPendingInvitesRequest) => ({
      ...state,
      loading: true
    }),
    fetchPendingInvitesSuccess: (state, { payload: invites }: FetchPendingInvitesSuccess) => ({
      ...state,
      loading: false,
      list: invites
    }),
    fetchPendingInvitesFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const { fetchPendingInvitesRequest, fetchPendingInvitesSuccess, fetchPendingInvitesFailure } =
  pendingInvitesSlice.actions;
export default pendingInvitesSlice.reducer;
