import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  ListItem,
  Popover,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { useQueryClient } from "@tanstack/react-query";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import { Fragment, FunctionComponent, useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import ColouredChip from "components/Chip/ColouredChip";
import EmptyStateBox from "components/EmptyState/EmptyStateBox";
import FixedFooter from "components/Footer/FixedFooter";
import ListItemLink from "components/ListItemLink";
import DeliveryAddressField from "components/Orders/Fields/DeliveryAddressField";
import SupplierEditLineItemsTable, { UnitPriceChange } from "components/Orders/SupplierEditLineItemsTable";
import useFetchBuyerDetails from "features/suppliers/directory/queries/useBuyerDetails";
import { useBuyersList } from "features/suppliers/directory/queries/useBuyersList";
import { PARAM_EMAIL } from "features/suppliers/directory/views/request-customer/CustomerRequestPage";
import { isMatchingLine } from "features/suppliers/orders/view-order/views/LineItemsSection";
import useCreateProductMatch from "features/suppliers/products/queries/useCreateProductMatch";
import useMessages from "i18n/hooks/useMessages";
import WandIcon from "media/gated-sections/wand.svg?react";
import { SupplierCustomersRequest, SupplierInboxMessage, SupplierOrdersGridLayout } from "routes/Routes";
import { AddressType } from "types/api/generated/directory";
import { InboxMessageDetails, SupplierProductSummary } from "types/api/generated/supplier";
import { ProductBuyerAliasType } from "types/api/generated/supplier-internal";
import { LocalInvalidOrderLine, LocalOrderLine } from "types/Orders";
import { getDefaultAddress } from "utils/Addresses";
import { useAnalytics } from "utils/analytics";
import { PURCHASE_ORDER_SUGGESTIONS_ALIASES } from "utils/flags";
import useAppSelector from "utils/hooks/useAppSelector";
import useDebounceValue from "utils/hooks/useDebounceValue";
import useFeatures from "utils/hooks/useFeatures";
import useModal from "utils/hooks/useModal";
import useTenancyId from "utils/hooks/useTenancyId";
import { createOrderLineFromProduct, replaceInvalidLineWithProduct, replaceOrderLineWithProduct } from "utils/Orders";

import InboxMessageDeleteModal from "../components/InboxMessageDeleteModal/InboxMessageDeleteModal";
import { inboxQueryKey } from "../queries/keys";
import useFetchInboxMessageSuggestions from "../queries/useFetchInboxMessageSuggestions";
import useValidatePurchaseOrder from "../queries/useValidatePurchaseOrder";
import { InboxMessageOrderFormValues } from "../types/InboxMessageOrderFormValues";
import {
  mapSuggestedOrderLines,
  mergeLocalOrderLineWithLineNotifications,
  toValidationLine
} from "../utilities/suggestions";

import strings from "./InboxMessageOrder.strings.json";

const useStyles = makeStyles()(theme => ({
  noOptions: {
    listStyle: "none",
    padding: theme.spacing(0.75, 0)
  },
  right: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: `calc(100vh - ${theme.spacing(23)})`,
    overflowY: "scroll"
  },
  listLoader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  popupIndicator: {
    right: 3
  },
  activeSuggestion: {
    "::first-line": {
      backgroundColor: "#FFF5DD"
    }
  },
  emptyState: {
    marginTop: theme.spacing(1)
  },
  icon: {
    color: theme.palette.secondary.main
  },
  highlight: {
    backgroundColor: "#FFF5DD"
  }
}));

type InboxMessageOrderFormProps = FormikProps<InboxMessageOrderFormValues> & {
  message: InboxMessageDetails;
};

const InboxMessageOrderForm: FunctionComponent<InboxMessageOrderFormProps> = ({
  message,
  handleSubmit,
  handleChange,
  setFieldValue,
  values,
  isSubmitting
}) => {
  const formId = useId();
  const history = useHistory();
  const tenancyId = useTenancyId();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const messages = useMessages(strings);
  const dateButtonRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const { timezone } = useAppSelector(state => state.tenancy);
  const { formatter } = useMessageFormatter();
  const { messageId } = useParams<typeof SupplierInboxMessage.params>();
  const [arePurchaseOrderSuggestionAliasesEnabled] = useFeatures(tenancyId, PURCHASE_ORDER_SUGGESTIONS_ALIASES);

  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();
  const [isDeliveryDateCalendarOpen, openDeliveryDateCalendar, closeDeliveryDateCalendar] = useModal();

  const [companySearch, setCompanySearch] = useState(message.buyer?.tradingName ?? "");
  const debouncedCompanySearch = useDebounceValue(companySearch, 500);

  const { data: buyersData, isLoading: isLoadingBuyers } = useBuyersList({ name: debouncedCompanySearch });
  const { data: buyerDetails, isLoading: isLoadingBuyer } = useFetchBuyerDetails({
    buyerId: values.buyer?.buyerId ?? ""
  });
  const { data: suggestions, isLoading: isFetchingSuggestions } = useFetchInboxMessageSuggestions(
    { buyerId: values.buyer?.buyerId },
    { purchaseOrderSuggestionsId: messageId },
    message
  );
  const { data: validation } = useValidatePurchaseOrder(
    { inboxMessageId: messageId },
    {
      buyerId: values.buyer?.buyerId ?? "",
      orderLines: values.orderLines.map(toValidationLine)
    },
    {
      enabled:
        Boolean(values.buyer?.buyerId) &&
        values.orderLines.length > 0 &&
        (suggestions?.purchaseOrderSuggestions.orderLines?.length ?? 0) > 0
    }
  );
  const { mutate: matchProduct } = useCreateProductMatch();

  const buyers = useMemo(() => buyersData?.data ?? [], [buyersData?.data]);

  const customPrices = values.orderLines.some(it => it.unitAmount?.amount !== it.priceListUnitAmount?.amount);
  const suggestionsWereProvided =
    suggestions?.purchaseOrderSuggestions.deliveryDateUtc ||
    suggestions?.purchaseOrderSuggestions.referenceNumber ||
    (suggestions?.purchaseOrderSuggestions.orderLines?.length ?? 0) > 0;

  const setInvalidOrderLines = useCallback(
    (f: (lines: LocalInvalidOrderLine[]) => LocalInvalidOrderLine[]): void => {
      setFieldValue("invalidOrderLines", f(values.invalidOrderLines));
    },
    [setFieldValue, values.invalidOrderLines]
  );

  const setOrderLines = useCallback(
    (f: (lines: LocalOrderLine[]) => LocalOrderLine[]): void => {
      setFieldValue("orderLines", f(values.orderLines));
    },
    [setFieldValue, values.orderLines]
  );

  useUpdateEffect(() => {
    if (validation) {
      setOrderLines(lines => {
        return lines.map((line, index) => {
          return mergeLocalOrderLineWithLineNotifications(line, validation.lines?.[index]?.notifications ?? []);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  useEffect(() => {
    if (buyerDetails) {
      setCompanySearch(buyerDetails.tradingName);
      setFieldValue("deliveryAddress", getDefaultAddress(buyerDetails.addresses ?? [], AddressType.Physical) ?? {});
      setFieldValue("notes", buyerDetails.settings?.defaultOrderNote ?? "");
    }
  }, [setFieldValue, buyerDetails]);

  useEffect(() => {
    const lines = mapSuggestedOrderLines(suggestions);

    setFieldValue("orderLines", lines.validOrderLines);
    setFieldValue("invalidOrderLines", lines.invalidOrderLines);

    if (suggestions?.purchaseOrderSuggestions.deliveryDateUtc) {
      setFieldValue("deliveryDateUtc", dayjs(suggestions.purchaseOrderSuggestions.deliveryDateUtc));
    }

    if (suggestions?.purchaseOrderSuggestions.referenceNumber) {
      setFieldValue("referenceNumber", suggestions.purchaseOrderSuggestions.referenceNumber);
    }
  }, [setFieldValue, suggestions]);

  const onRemove = (lineItem: LocalOrderLine | LocalInvalidOrderLine): void => {
    if ("productId" in lineItem) {
      setOrderLines(previous => previous.filter(it => it !== lineItem));
    } else {
      setInvalidOrderLines(previous => previous.filter(it => it !== lineItem));
    }
  };

  const onQuantityChange = (lineItem: LocalOrderLine, quantity: number): void => {
    setOrderLines(prevLineItems => {
      return prevLineItems.map<LocalOrderLine>(prevLineItem => {
        if (isMatchingLine(lineItem, prevLineItem)) {
          if (prevLineItem.unitAmount && prevLineItem.lineAmount) {
            return {
              ...prevLineItem,
              quantity,
              lineAmount: {
                ...prevLineItem.lineAmount,
                amount: quantity * prevLineItem.unitAmount.amount
              },
              priceListLineAmount: prevLineItem.priceListUnitAmount && {
                ...prevLineItem.priceListUnitAmount,
                amount: quantity * prevLineItem.priceListUnitAmount.amount
              }
            };
          }
          return { ...prevLineItem, quantity };
        }
        return prevLineItem;
      });
    });
  };

  const onUnitPriceChange = (changes: UnitPriceChange<LocalOrderLine>[]): void => {
    setOrderLines(orderLines =>
      orderLines.map(line => {
        const change = changes.find(c => line.productId === c.lineItem.productId);
        if (change) {
          return {
            ...line,
            unitAmount: line.unitAmount && {
              ...line.unitAmount,
              amount: change.unitPrice
            },
            lineAmount: line.lineAmount && {
              ...line.lineAmount,
              amount: change.unitPrice * line.quantity
            }
          };
        }
        return line;
      })
    );
  };

  const onReplaceInvalidLine = (lineItem: LocalInvalidOrderLine, product: SupplierProductSummary): void => {
    setInvalidOrderLines(previous => previous.filter(it => it !== lineItem));
    setOrderLines(previous => [...previous, replaceInvalidLineWithProduct(lineItem, product)]);

    if (arePurchaseOrderSuggestionAliasesEnabled && buyerDetails && lineItem.productCode) {
      matchProduct({
        parameters: {
          productId: product.productId,
          buyerId: buyerDetails.buyerId
        },
        body: {
          type: ProductBuyerAliasType.ProductCode,
          alias: lineItem.productCode
        }
      });
    }
  };

  const onReplaceLine = (lineItem: LocalOrderLine, product: SupplierProductSummary): void => {
    setOrderLines(previous =>
      previous.map(it => (it.productId === lineItem.productId ? replaceOrderLineWithProduct(it, product) : it))
    );

    if (arePurchaseOrderSuggestionAliasesEnabled && buyerDetails && lineItem.documentProductCode) {
      matchProduct({
        parameters: {
          productId: product.productId,
          buyerId: buyerDetails.buyerId
        },
        body: {
          type: ProductBuyerAliasType.ProductCode,
          alias: lineItem.documentProductCode
        }
      });
    }
  };

  const onAddProduct = (product: SupplierProductSummary): void => {
    const currentMaxLineId = Math.max(
      ...values.orderLines.map(it => it.lineId ?? 0),
      ...values.invalidOrderLines.map(it => it.lineId)
    );

    setOrderLines(previous => [...previous, createOrderLineFromProduct(product, 0, currentMaxLineId + 1)]);
  };

  const onSkipSuggestions = (): void => {
    analytics.track("SkipPurchaseOrderSuggestionsClicked");

    queryClient.cancelQueries({
      queryKey: inboxQueryKey.suggestions(
        { buyerId: values.buyer?.buyerId },
        { purchaseOrderSuggestionsId: messageId },
        message,
        {}
      ).queryKey
    });
  };

  return (
    <Stack flex={1} padding={3} gap={3} className={classes.right}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1">
          <FormattedMessage id={messages.HEADER} />
        </Typography>
        {suggestionsWereProvided && (
          <ColouredChip color="yellow" label={<FormattedMessage id={messages.SUGGESTIONS_CHIP} />} />
        )}
      </Stack>
      <form id={formId} onSubmit={handleSubmit}>
        <Stack gap={2}>
          <Autocomplete
            options={buyers.map(it => ({ tradingName: it.tradingName, buyerId: it.buyerId }))}
            value={values.buyer}
            getOptionLabel={option => option.tradingName}
            onChange={(_, value) => setFieldValue("buyer", value)}
            onInputChange={(_, value) => setCompanySearch(value)}
            isOptionEqualToValue={(option, value) => option.buyerId === value.buyerId}
            classes={{ noOptions: classes.noOptions, popupIndicator: classes.popupIndicator }}
            renderOption={(e, value) => (
              <Fragment key={value.buyerId}>
                <ListItem {...e} key={value.buyerId}>
                  <Typography color="textPrimary">{value.tradingName}</Typography>
                </ListItem>
                {/* If the selected buyer is the last one in the list, show the add customer button */}
                {buyers.findIndex(it => it.buyerId === value.buyerId) === buyers.length - 1 && (
                  <ListItemLink
                    to={SupplierCustomersRequest.toUrl({ tenancyId }, { [PARAM_EMAIL]: message?.sender.email })}
                    key="add-customer"
                  >
                    <FormattedMessage id={messages.ADD_CUSTOMER_BUTTON} />
                  </ListItemLink>
                )}
              </Fragment>
            )}
            noOptionsText={
              <ListItemLink
                to={SupplierCustomersRequest.toUrl({ tenancyId }, { [PARAM_EMAIL]: message?.sender.email })}
              >
                <Typography color="textPrimary">
                  <FormattedMessage id={messages.ADD_CUSTOMER_BUTTON} />
                </Typography>
              </ListItemLink>
            }
            loading={isLoadingBuyers}
            loadingText={
              <div className={classes.listLoader}>
                <CircularProgress size={24} />
              </div>
            }
            disableClearable
            renderInput={params => (
              <TextField {...params} label={formatter.format(messages.CUSTOMER_INPUT_LABEL)} variant="filled" />
            )}
            popupIcon={<ExpandMoreIcon />}
          />

          {isLoadingBuyer ? (
            <EmptyStateBox icon={<WandIcon className={classes.icon} />} className={classes.emptyState}>
              <Stack gap={2}>
                <Typography color="textPrimary">
                  <FormattedMessage id={messages.EMPTY_STATE_START_1} />
                </Typography>
                <Typography color="textPrimary">
                  <FormattedMessage
                    id={messages.EMPTY_STATE_START_2}
                    values={{ highlighted: (text: string) => <span className={classes.highlight}>{text}</span> }}
                  />
                </Typography>
              </Stack>
            </EmptyStateBox>
          ) : isFetchingSuggestions ? (
            <EmptyStateBox icon={<CircularProgress color="secondary" size={20} />} className={classes.emptyState}>
              <Stack gap={1}>
                <Typography color="textPrimary">
                  <FormattedMessage id={messages.EMPTY_STATE_LOADING_SUGGESTIONS} />
                </Typography>
                <Button color="primary" onClick={onSkipSuggestions}>
                  <FormattedMessage id={messages.EMPTY_STATE_SKIP_SUGGESTIONS_BUTTON} />
                </Button>
              </Stack>
            </EmptyStateBox>
          ) : buyerDetails ? (
            <>
              <DeliveryAddressField
                label={formatter.format(messages.DELIVERY_ADDRESS_INPUT_LABEL)}
                address={values.deliveryAddress}
                onSave={address => setFieldValue("deliveryAddress", address)}
              />
              <TextField
                value={dayjs(values.deliveryDateUtc).format("dddd, D MMMM")}
                variant="filled"
                onClick={openDeliveryDateCalendar}
                ref={dateButtonRef}
                label={formatter.format(messages.DELIVERY_DATE_INPUT_LABEL)}
                placeholder={formatter.format(messages.DELIVERY_DATE_INPUT_LABEL)}
                InputProps={{
                  readOnly: true,
                  classes: values.deliveryDateUtc.isSame(suggestions?.purchaseOrderSuggestions.deliveryDateUtc)
                    ? { input: classes.activeSuggestion }
                    : {},
                  endAdornment: (
                    <InputAdornment position="end">
                      <ExpandMoreIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Popover
                open={isDeliveryDateCalendarOpen}
                anchorEl={dateButtonRef.current}
                onClose={closeDeliveryDateCalendar}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <DateCalendar
                  value={values.deliveryDateUtc}
                  onChange={date => {
                    setFieldValue("deliveryDateUtc", date);
                    closeDeliveryDateCalendar();
                  }}
                  timezone={timezone}
                />
              </Popover>
              <TextField
                value={values.referenceNumber}
                name="referenceNumber"
                onChange={e => handleChange(e)}
                label={<FormattedMessage id={messages.REFERENCE_INPUT_LABEL} />}
                variant="filled"
                fullWidth
                InputProps={{
                  classes:
                    values.referenceNumber === suggestions?.purchaseOrderSuggestions.referenceNumber
                      ? { input: classes.activeSuggestion }
                      : {}
                }}
              />
              <TextField
                value={values.notes}
                name="notes"
                onChange={handleChange}
                label={<FormattedMessage id={messages.NOTES_INPUT_LABEL} />}
                variant="filled"
                fullWidth
              />
              <SupplierEditLineItemsTable
                lineItems={values.orderLines}
                invalidLineItems={values.invalidOrderLines}
                suggestedLineItems={suggestions?.purchaseOrderSuggestions.orderLines}
                priceListName={buyerDetails.priceList?.name}
                pricingSource={customPrices ? formatter.format(messages.CUSTOM_PRICES) : buyerDetails.priceList?.name}
                onRemove={onRemove}
                onQuantityChange={onQuantityChange}
                onUnitPriceChange={onUnitPriceChange}
                onAddItemDropdown={onAddProduct}
                onReplace={(line, product) =>
                  "productId" in line ? onReplaceLine(line, product) : onReplaceInvalidLine(line, product)
                }
                buyerId={buyerDetails.buyerId}
              />
            </>
          ) : null}
        </Stack>
        <FixedFooter>
          <Stack direction="row" justifyContent="space-between" flex={1}>
            <Button color="primary" onClick={openDeleteModal}>
              <FormattedMessage id={messages.DELETE_EMAIL_BUTTON} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              showLoader={isSubmitting}
              disabled={values.orderLines.length === 0}
              form={formId}
            >
              <FormattedMessage id={messages.CREATE_ORDER_BUTTON} />
            </Button>
          </Stack>
        </FixedFooter>
      </form>
      <InboxMessageDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        externalIds={[messageId]}
        successCallback={() =>
          history.push(SupplierOrdersGridLayout.toUrl({ tenancyId }, { savedFilter: "inbox" }), {
            bypassNavigationBlock: true
          })
        }
      />
    </Stack>
  );
};

export default InboxMessageOrderForm;
