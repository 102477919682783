import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put, select } from "redux-saga/effects";

import {
  claimCompanyFromExternalOrderFailure,
  ClaimCompanyFromExternalOrderRequest,
  claimCompanyFromExternalOrderSuccess
} from "redux/reducers/tenancy";
import { postDataToApiWrapper } from "services/helpers";
import { SupplierInternal } from "types/api";
import { ApiValidationProblem } from "types/api/generated/supplier-internal";

/**
 * Calls the API /supplier/internal/external-view-order/{supplierId}/{viewId}/claim to claim a company
 */
export const claimCompanyFromExternalOrderApi = (
  auth: Auth0ContextInterface,
  tenancyId: string,
  viewId: string,
  supplierId: string
): Promise<SupplierInternal.InternalExternalViewOrderClaimCreate.ResponseBody> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/supplier/internal/external-view-order/${supplierId}/${viewId}/claim`;
  return postDataToApiWrapper(auth, urlWithDomain, tenancyId);
};

/**
 * Saga to call claim a company as any role.
 */
export default function* claimCompanyFromExternalOrder(action: ClaimCompanyFromExternalOrderRequest): Generator {
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const tenancyId = (yield select(state => state.tenancy.companyId)) as string;
  const { supplierId, viewId, companyId, onSuccess } = action.payload;

  try {
    const { errors } =
      ((yield call(claimCompanyFromExternalOrderApi, auth, tenancyId, viewId, supplierId)) as
        | SupplierInternal.InternalExternalViewOrderClaimCreate.ResponseBody
        | ApiValidationProblem) ?? {};

    if (!!errors && Object.keys(errors).length) {
      yield put(claimCompanyFromExternalOrderFailure(errors));
    } else {
      yield put(claimCompanyFromExternalOrderSuccess({ companyId }));
      if (onSuccess) {
        yield call(onSuccess);
      }
    }
  } catch (e) {
    yield put(claimCompanyFromExternalOrderFailure(e));
  }
}
