import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FetchV2Error } from "services/fetchV2";
import { JobRef } from "types/api/generated/supplier";
import { JobDetails, JobStatus } from "types/api/generated/supplier-internal";
import { logger } from "utils/Datadog";
import { safeurl } from "utils/urls";

import { buildApiFetchOptions, useApiFetch, UseApiFetchReturn } from "./apiFetch";

const JOB_POLL_INTERVAL_MS = 1000;

export const startJobOptions = buildApiFetchOptions<unknown, unknown, JobRef | JobRef[], string>(jobUrl => ({
  method: "POST",
  path: jobUrl
}));

export const getJobOptions = buildApiFetchOptions<unknown, unknown, JobDetails, string>(jobId => ({
  method: "GET",
  path: safeurl`/supplier/internal/jobs/${jobId}`
}));

type Callback = () => void | Promise<void>;
type ErrorCallback = (error: FetchV2Error) => void;

type StartJobOptions = {
  /**
   * Function to call after the API has said the job has completed, but before
   * the UI reports that the job has completed.  This is so that success
   * handling can all be part of the same loading state as returned by
   * `useApiJob` and the `start` Promise.
   */
  onSuccess?: Callback;

  onError?: ErrorCallback;

  onSettled?: Callback;
};

type StartJob<RequestBody> = (jobBody?: RequestBody, options?: StartJobOptions) => void;

type ApiJobResult<RequestBody> = {
  start: StartJob<RequestBody>;
  jobs: JobRef[] | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isSettled: boolean;
};

/**
 * Start and track the progress of an API job.
 *
 * @param jobUrl
 *   API URL at which a POST request will return one or more job IDs that we can
 *   keep track of.
 * @return
 *   An object that contains a function to start the job, as well as status
 *   flags to keep track of the overall job progress.
 */
export function useApiJob<RequestBody = void>(jobUrl: string): ApiJobResult<RequestBody> {
  const apiFetch = useApiFetch();
  const [jobRefs, setJobRefs] = useState<JobRef[]>();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [isSettled, setSettled] = useState(false);
  const onSuccessCallbackRef = useRef<Callback>();
  const onErrorCallbackRef = useRef<ErrorCallback>();
  const onSettledCallbackRef = useRef<Callback>();
  const [callbacksExecuted, setCallbacksExecuted] = useState(false);

  // Query to start the job
  const { mutate: startJob } = useMutation<unknown, FetchV2Error, RequestBody | undefined>({
    async mutationFn(jobBody) {
      const response = await apiFetch(startJobOptions(jobUrl, { data: jobBody }));
      const jobRefOrJobRefs = response.data;
      setJobRefs(Array.isArray(jobRefOrJobRefs) ? jobRefOrJobRefs : [jobRefOrJobRefs]);
    },
    onError(error) {
      onErrorCallbackRef.current?.(error);
      setLoading(false);
      setError(true);
      setSettled(true);
    }
  });

  // Function to start the job using the query above and register any callbacks via the start options
  const start: StartJob<RequestBody> = useCallback(
    (jobBody, options) => {
      const { onSuccess, onError, onSettled } = options ?? {};
      setLoading(true);
      setSuccess(false);
      setError(false);
      setSettled(false);

      if (onSuccess) {
        onSuccessCallbackRef.current = onSuccess;
      }
      if (onError) {
        onErrorCallbackRef.current = onError;
      }
      if (onSettled) {
        onSettledCallbackRef.current = onSettled;
      }
      startJob(jobBody);
    },
    [startJob]
  );

  // Poll job statuses once we have job refs to poll for
  const { isSuccess: allJobsSuccessful, isError: aJobErrored, isSettled: allJobsSettled } = useExistingApiJobs(jobRefs);

  // Map job statuses to loading/error/settled statuses
  useEffect(() => {
    if (allJobsSettled) {
      setLoading(false);
      setSettled(true);
    }

    if (allJobsSuccessful) {
      setLoading(false);
      setSuccess(true);
      setSettled(true);
    } else if (aJobErrored) {
      setLoading(false);
      setError(true);
      setSettled(true);
    }
  }, [aJobErrored, allJobsSettled, allJobsSuccessful]);

  // Execute any registered callbacks
  useEffect(() => {
    (async () => {
      if (!callbacksExecuted) {
        if (allJobsSettled && onSettledCallbackRef.current) {
          try {
            await onSettledCallbackRef.current();
          } finally {
            setCallbacksExecuted(true);
          }
        }

        if (allJobsSuccessful && onSuccessCallbackRef.current) {
          try {
            await onSuccessCallbackRef.current();
          } finally {
            setCallbacksExecuted(true);
          }
        } else if (aJobErrored && onErrorCallbackRef.current) {
          try {
            onErrorCallbackRef.current(new FetchV2Error("Job returned 'Failed'"));
          } finally {
            setCallbacksExecuted(true);
          }
        }
      }
    })();
  }, [aJobErrored, allJobsSettled, allJobsSuccessful, callbacksExecuted]);

  return useMemo(
    () => ({
      start,
      jobs: jobRefs,
      isLoading,
      isSuccess,
      isError,
      isSettled
    }),
    [start, jobRefs, isLoading, isSuccess, isError, isSettled]
  );
}

type ExistingApiJobsResult = {
  isError: boolean;
  isLoading: boolean;
  isSettled: boolean;
  isSuccess: boolean;
};

/**
 * Track the progress of existing jobs.
 */
export function useExistingApiJobs(jobRefs: JobRef[] | undefined): ExistingApiJobsResult {
  const apiFetch = useApiFetch();

  // Query to poll job statuses until they returns a completion status
  const {
    data: jobs,
    error,
    isLoading: isPolling
  } = useQuery<JobDetails[], FetchV2Error>({
    queryKey: ["jobs", jobRefs],
    async queryFn() {
      return Promise.all(
        jobRefs!.map(async jobRef => {
          const response = await apiFetch(getJobOptions(jobRef.jobId));
          return response.data;
        })
      );
    },
    enabled: !!jobRefs && jobRefs.length > 0,
    refetchInterval(jobResponses) {
      if (jobResponses?.every(jobResponse => jobResponse.status !== JobStatus.Processing)) {
        return false;
      }
      return process.env.NODE_ENV === "test" ? 100 : JOB_POLL_INTERVAL_MS;
    },
    useErrorBoundary: false
  });

  const allJobsSuccessful = jobRefs?.length === 0 || jobs?.every(job => job.status === JobStatus.Completed);
  const aJobErrored = jobs?.some(job => job.status === JobStatus.Failed);
  const allJobsSettled = jobRefs?.length === 0 || jobs?.every(job => job.status !== JobStatus.Processing);

  // Map job statuses to loading/success/error/settled statuses
  let isLoading = isPolling;
  let isSuccess = false;
  let isError = false;
  let isSettled = false;

  if (allJobsSettled) {
    isLoading = false;
    isSettled = true;
  }

  if (allJobsSuccessful) {
    isLoading = false;
    isSuccess = true;
    isSettled = true;
  } else if (aJobErrored) {
    isLoading = false;
    isError = true;
    isSettled = true;
  }

  useEffect(() => {
    if (isError) {
      logger.error("Job polling resulted in error", undefined, error ?? undefined);
    }
  }, [error, isError]);

  return useMemo(
    () => ({
      isError,
      isLoading,
      isSettled,
      isSuccess
    }),
    [isError, isLoading, isSettled, isSuccess]
  );
}

export async function pollApiJob(apiFetch: UseApiFetchReturn, jobId: string, signal: AbortSignal): Promise<boolean> {
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await apiFetch(getJobOptions(jobId));
    switch (response.data.status) {
      case JobStatus.Processing:
        // eslint-disable-next-line no-await-in-loop
        await new Promise<void>(resolve => {
          setTimeout(resolve, JOB_POLL_INTERVAL_MS);
        });
        break;
      case JobStatus.Completed:
        return true;
      case JobStatus.Failed:
        return false;
      default:
        throw new Error(`Unexpected job status: ${response.data.status}`);
    }
  } while (signal.aborted === false);

  return false;
}
