import { useAuth0 } from "@auth0/auth0-react";
import { JSX, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useOnboardingFlowManagement from "features/onboarding/useOnboardingFlowManagement";
import LoadingScreen from "pages/LoadingScreen";
import { fetchNavigationDataRequest } from "redux/reducers/navigationData";
import { claimCompanyFromExternalOrderRequest } from "redux/reducers/tenancy";
import { AcceptClaim, CompanyRoot } from "routes/Routes";
import { ValidationErrorCodes } from "types/api";
import { logger } from "utils/Datadog";
import useAppSelector from "utils/hooks/useAppSelector";
import {
  UNAUTHENTICATED_ORDER_ACTION,
  UNAUTHENTICATED_ORDER_ACTION_CLAIMACCOUNT,
  UNAUTHENTICATED_ORDER_DETAIL_KEY_SUPPLIERID,
  UNAUTHENTICATED_ORDER_DETAIL_KEY_VIEWID
} from "utils/UnauthenticatedOrders";

/**
 * Page from which we attempt to claim a company.  The UI is only the loading
 * animation while we communicate with the API.
 */
export default function AcceptClaimPage(): JSX.Element {
  const history = useHistory();
  const { tenancyId } = useParams<typeof AcceptClaim.params>();
  const location = useLocation();
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { error, loading } = useAppSelector(state => state.tenancy);
  const claimedError =
    !!error && "errors" in error ? error.errors.find(({ property }) => property === "IsClaimed") : undefined;

  const urlSearchParams = new URLSearchParams(location.search);
  const supplierId = urlSearchParams.get(UNAUTHENTICATED_ORDER_DETAIL_KEY_SUPPLIERID);
  const viewId = urlSearchParams.get(UNAUTHENTICATED_ORDER_DETAIL_KEY_VIEWID);
  const postLoginValue = urlSearchParams.get(UNAUTHENTICATED_ORDER_ACTION);
  const [claiming, setClaiming] = useState(false);

  const { getFirstOnboardingFlowPath, isLoading: onboardingDataLoading } = useOnboardingFlowManagement();
  const { companyProfileLoading } = useAppSelector(state => state.tenancy);

  const isLoading = onboardingDataLoading || companyProfileLoading;

  useEffect(() => {
    if (!isLoading) {
      getFirstOnboardingFlowPath([]).then(path => {
        history.push(path);
      });
    }
  }, [getFirstOnboardingFlowPath, history, isLoading]);

  useEffect(() => {
    if (!loading && postLoginValue === UNAUTHENTICATED_ORDER_ACTION_CLAIMACCOUNT && !claiming && supplierId && viewId) {
      setClaiming(true);

      dispatch(
        claimCompanyFromExternalOrderRequest({
          supplierId,
          viewId,
          companyId: tenancyId
        })
      );
    }
  }, [claiming, dispatch, loading, postLoginValue, supplierId, tenancyId, viewId]);

  useEffect(() => {
    if (claimedError?.code === ValidationErrorCodes.CompanyIsClaimed) {
      // Logging to see under what circumstances we get here.  We suspect this
      // should be fine (eg: people clicking account claiming links even after
      // they have claimed the company, as a means of getting into the app), but
      // would like to be notified to see them.  If after some time we find no
      // suspicous account claiming, then we should be good to remove this
      // logging.  Some more info in: https://upstock.atlassian.net/browse/ORD-2381
      logger.error(`User ${user!.email} attempted to claim company ${tenancyId} but the company is already claimed`);
      dispatch(fetchNavigationDataRequest({}));
      history.push("/");
    }
    // User already has role in the claimed company, go straight to the company
    else if (claimedError?.code === ValidationErrorCodes.ClaimCompanyAlreadyHasRole) {
      history.push(CompanyRoot.toUrl({ tenancyId }));
    }
  }, [claimedError?.code, dispatch, history, tenancyId, user]);

  return <LoadingScreen />;
}
