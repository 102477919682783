import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchNavigationDataSuccess } from "redux/reducers/navigationData";
import { ApiConnection } from "types/api/generated/directory-internal";

export interface IntegrationsState {
  connections: ApiConnection[];
}

const initialState: IntegrationsState = {
  connections: []
};

type SetConnectionsAction = PayloadAction<ApiConnection[]>;

/**
 * Reducer for the Integrations slice.  This only holds the initial data from
 * the navigation data endpoint as that is still done using redux saga.  After
 * that, it's all react query.  This should be removable once nav data becomes
 * react query too.
 */
const integrations = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    // This action is only for keeping the redux connections updated from the
    // react query values.  There's still a lot of our app using useProviderConnection
    // which only operates on this prior connection data.
    setConnections: (state, { payload: connections }: SetConnectionsAction) => ({
      ...state,
      connections
    })
  },
  extraReducers: builder => {
    builder.addCase(fetchNavigationDataSuccess, (state, action) => ({
      ...state,
      connections: action.payload.connections
    }));
  }
});

export const { setConnections } = integrations.actions;
export default integrations.reducer;
