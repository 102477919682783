import { createAction } from "@reduxjs/toolkit";

import { ActionablePromptType, Directory } from "types/api/generated/directory-internal";
import { ApiValidationProblem } from "types/api/generated/supplier";
import { RequestPayload } from "types/redux-helpers";

export type DeletePromptRequest = RequestPayload<
  Directory.InternalUsersActionablePromptDelete.RequestParams,
  ActionablePromptType
>;
type DeletePromptSuccess = ActionablePromptType;

export const deletePromptRequest = createAction<DeletePromptRequest>("deletePrompt/request");
export const deletePromptSuccess = createAction<DeletePromptSuccess>("deletePrompt/success");
export const deletePromptFailure = createAction<ApiValidationProblem | Error>("deletePrompt/failure");
