import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import SkeletonHeader from "components/Header/SkeletonHeader";
import ListItemLink from "components/ListItemLink";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg";
import { fetchUsersCompaniesRequest } from "redux/reducers/tenancy";
import { isBuyerRoute, isSupplierRoute } from "routes/Routes";
import { CompanySummary } from "types/api/generated/directory-internal";
import useAppSelector from "utils/hooks/useAppSelector";

import strings from "./CompanyRedirect.strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: "center"
  },
  logo: {
    height: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  list: {
    margin: theme.spacing(3, 0)
  },
  listItemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }
}));

export default function CompanyRedirect(): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { targetRoute } = useParams<{ targetRoute: string }>();
  const history = useHistory();
  const { companies } = useAppSelector(state => state.tenancy);

  useMount(() => {
    dispatch(
      fetchUsersCompaniesRequest({
        // We need to handle the loading state ourselves because `state.tenancy.loading` defaults to false
        // and we need to handle users with no companies
        successCallback() {
          setIsLoading(false);
        },
        failureCallback() {
          // If an error occurs, just set `isLoading` to false so the user gets redirected
          // to the app root below (because `companies` with be empty)
          setIsLoading(false);
        }
      })
    );
  });

  const getRoute = useCallback(
    (company: CompanySummary) => {
      const route = `/c/${company.companyId}/${targetRoute}`;

      // If the target route doesn't match the company type, fallback to the company root
      if ((isBuyerRoute(route) && !company.isBuyer) || (isSupplierRoute(route) && !company.isSupplier)) {
        return `/c/${company.companyId}`;
      }

      return `${route}${location.search}${location.hash}`;
    },
    [targetRoute, location]
  );

  useEffect(() => {
    if (!isLoading && companies.length <= 1) {
      if (companies.length === 1) {
        // If the user is only in a single company, redirect them straight there
        history.replace(getRoute(companies[0]!));
      } else {
        // If the user isn't in any companies, just redirect them to the app root and let them go through the usual flows
        history.replace("/");
      }
    }
  }, [isLoading, companies, history, getRoute]);

  return (
    <Page
      containerClassName={cx(classes.container)}
      containerMaxWidth="xs"
      displayNav={false}
      header={
        <SkeletonHeader
          backgroundColor="white"
          centerComp={<img className={cx(classes.logo)} src={LogoIcon} alt="Upstock" />}
        />
      }
      isLoading={isLoading || companies.length <= 1}
    >
      <Typography className={cx(classes.title)} variant="h1">
        <FormattedMessage id={messages.TITLE} />
      </Typography>
      <Typography paragraph color="textSecondary">
        <FormattedMessage id={messages.SUB_TITLE} />
      </Typography>

      <Paper className={cx(classes.list)}>
        <List disablePadding>
          {companies.map((company, index) => (
            <ListItemLink
              key={company.companyId}
              // Perform a full page reload because if the user presses the back button and chooses a
              // different company, the redux state needs to be reset so prevent a lot of weird behaviour
              href={getRoute(company)}
              divider={index < companies.length - 1}
              color="textPrimary"
            >
              <ListItemText
                primary={company.tradingName}
                primaryTypographyProps={{
                  className: cx(classes.listItemText),
                  title: company.tradingName
                }}
              />
              <ChevronRightIcon color="action" />
            </ListItemLink>
          ))}
        </List>
      </Paper>

      <Typography paragraph color="textSecondary">
        <FormattedMessage id={messages.CONTACT_SUPPORT} />
      </Typography>
    </Page>
  );
}
