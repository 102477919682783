import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put, select } from "redux-saga/effects";

import {
  fetchFailure as fetchFeaturesFailure,
  FetchFeatureRequest,
  fetchSuccess as fetchFeaturesSuccess
} from "redux/reducers/features";
import { fetchDataFromApiWrapper, fetchUnauthenticatedDataFromApiWrapper } from "services/helpers";
import { DirectoryInternal } from "types/api";
import { buildSearchString } from "utils/urls";

/**
 * Calls the features API to determine if the company and/or user is opted in
 * that feature(s).
 */
export const fetchFeaturesApi = async (
  auth: Auth0ContextInterface,
  tenancyId: string | undefined,
  key: string,
  features: string[]
): Promise<DirectoryInternal.InternalFeaturesList.ResponseBody> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/directory/internal/features${buildSearchString({
    key,
    feature: features
  })}`;

  // The only real way of knowing someone is actually logged in is to try getting the access token.
  // This makes it so that fetching the feature flags doesn't redirect you to the login page if
  // you were previously logged in but your session expired (useful for the unauthenticated pages).
  const isLoggedIn = await auth
    .getAccessTokenSilently()
    .then(() => true)
    .catch(() => false);

  if (isLoggedIn) {
    return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
  }
  return fetchUnauthenticatedDataFromApiWrapper(urlWithDomain);
};

/**
 * Saga task for querying the Features API endpoint for a feature or list of
 * features for the given key.
 */
export default function* fetchFeatures({ payload }: FetchFeatureRequest): Generator {
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const tenancyId = (yield select(state => state.tenancy.companyId)) as string | undefined;
  const key = (payload.key || tenancyId)!;

  try {
    const data = (yield call(
      fetchFeaturesApi,
      auth,
      tenancyId,
      key,
      payload.features
    )) as DirectoryInternal.InternalFeaturesList.ResponseBody;
    yield put(
      fetchFeaturesSuccess({
        key,
        features: data
      })
    );
    if (payload.successCallback) {
      yield call(payload.successCallback, data);
    }
  } catch (e: any) {
    yield put(fetchFeaturesFailure(e.message));
  }
}
