import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SimpleHeader, { SimpleHeaderProps } from "components/Header/SimpleHeader";
import useMessages from "i18n/hooks/useMessages";
import logo from "media/logo_white.svg";

import strings from "./strings.json";

const useStyles = makeStyles()(theme => ({
  button: {
    color: theme.palette.common.white
  },
  logo: {
    height: "1.7rem",
    marginLeft: theme.spacing(-1) // Offset the button alignment on the left
  },
  leftComp: {
    minWidth: "40px" // Space for an icon if its missing.
  }
}));

interface SignOutBtnProps {
  className: string;
  handleSignOutClick: () => void;
  isAuthenticated: boolean;
}

function SignOutBtn({ className, handleSignOutClick, isAuthenticated }: SignOutBtnProps): JSX.Element | null {
  const messages = useMessages(strings);
  return isAuthenticated ? (
    <Button className={className} onClick={handleSignOutClick}>
      <FormattedMessage id={messages.SIGNOUT} />
    </Button>
  ) : null;
}

export interface LogoHeaderProps extends Omit<SimpleHeaderProps, "leftComp"> {
  logoClassName?: string;
  LeftComp?: JSX.Element;
}

/**
 * An app header which has the Upstock logo in the middle, a customer component
 * on the left (defaults to a Sign out button if not provided), and Intercom
 * button on the right.
 */
const LogoHeader: FunctionComponent<LogoHeaderProps> = ({ logoClassName, LeftComp, ...props }) => {
  const { classes, cx } = useStyles();
  const { isAuthenticated, logout } = useAuth0();

  const handleSignOutClick = (): void => {
    isAuthenticated && logout();
  };

  return (
    <SimpleHeader
      centerComp={<img src={logo} alt="Upstock logo" className={cx(classes.logo, logoClassName)} />}
      LeftComp={
        LeftComp ? (
          <div className={cx(classes.leftComp)}>{LeftComp}</div>
        ) : (
          <SignOutBtn
            className={classes.button}
            handleSignOutClick={handleSignOutClick}
            isAuthenticated={isAuthenticated}
          />
        )
      }
      {...props}
    />
  );
};

export default LogoHeader;
