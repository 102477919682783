/**
 * The type of identity providers for a user's account.  The values for the
 * enums match what auth0 has set for their "provider" property.
 */
enum IdentityProvider {
  Apple = "apple",
  Auth0 = "auth0",
  Google = "google-oauth2",
  Unknown = "unknown" // Not a real one, but fallback for future social logins?
}

export default IdentityProvider;
