import { useField } from "formik";
import { ChangeEventHandler, FunctionComponent, useCallback } from "react";

import { getHelperText, hasError } from "utils/fields";

import TextFieldWithoutFormik, { TextFieldWithoutFormikProps } from "./TextFieldWithoutFormik";

export type TextFieldProps = TextFieldWithoutFormikProps;

/**
 * Customizations to the Material UI `<TextField>` component, styled for the
 * defaults of our app and Formik as the form library.  All of the defaults can
 * be overridden as all props are spread over the original `<TextField>`.
 */
const TextField: FunctionComponent<TextFieldProps> = ({ helperText, id, name, onChange, ...rest }) => {
  const [field, meta] = useField(name);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onChange?.(event);
      field.onChange(event);
    },
    [field, onChange]
  );

  return (
    <TextFieldWithoutFormik
      id={id}
      name={name}
      error={hasError(meta.touched, meta.error)}
      helperText={getHelperText(meta.touched, meta.error, helperText)}
      onBlur={field.onBlur}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

export default TextField;
