import { Auth0ContextInterface } from "@auth0/auth0-react";

import { fetchUsersCompaniesFailure, fetchUsersCompaniesSuccess } from "redux/reducers/tenancy";
import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { CompanySummary } from "types/api/generated/directory-internal";

/**
 * Calls `/directory/internal/users/companies` to fetch the companies a user
 * belongs to.
 *
 * If the user exists in our system, but has no companies an empty array is
 * returned.  If the user does not exist in the Upstock DB then a 403 error is
 * returned.
 */
function fetchUsersCompanies(auth: Auth0ContextInterface, tenancyId: string): Promise<CompanySummary[]> {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/directory/internal/users/companies`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
}

/**
 * Calls the API to determine what companies the user belongs to.
 */
export default createSaga(fetchUsersCompanies, {
  successAction: fetchUsersCompaniesSuccess,
  errorFailureAction: fetchUsersCompaniesFailure
});
