import { retry } from "@ultraq/promise-utils";

import { logger } from "utils/Datadog";
import {
  HTTP_STATUS_CONFLICT,
  HTTP_STATUS_INTERNAL_SERVER,
  HTTP_STATUS_NETWORK_FAILURE,
  HTTP_STATUS_REQUESTTIMEOUT
} from "utils/HttpStatuses";

export class FetchError extends Error {
  code?: string;

  url?: string;

  method?: string;

  status?: number;

  statusText?: string;

  constructor(message: string) {
    super(message);
    this.name = "FetchError";
  }
}

export const MAX_ATTEMPTS = 3;

/**
 * Return by how many milliseconds to delay a retry for given the attempt
 * number.
 */
export function retryAfterMs(attempt: number): number {
  // Add some random jitter to help space out retries from competing clients
  const jitter = Math.round(Math.random() * 100);
  return 2 ** (attempt - 1) * 1000 + jitter;
}

/**
 * A retry capable `fetch` function.  Retries a request up to 2 times (for a
 * total of 3 calls to the URL in question) if we receive a 500 error from the
 * server or a network error.
 */
export function fetchWithRetry(url: string, options?: RequestInit): Promise<Response> {
  return retry(
    () => fetch(url, options),
    (response, error, attempts) => {
      if (attempts < MAX_ATTEMPTS) {
        if (response?.status === 408) {
          logger.info("Retrying API request.  Reason: request timeout");
          return retryAfterMs(attempts);
        }
        if (response?.status === 409) {
          logger.info("Retrying API request.  Reason: conflict");
          return retryAfterMs(attempts);
        }
        if (response?.status === 500) {
          logger.info("Retrying API request.  Reason: internal server error");
          return retryAfterMs(attempts);
        }
        // A TypeError is thrown when a network error is encountered.  See:
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
        if (error instanceof TypeError) {
          logger.info("Retrying API request.  Reason: network error");
          return retryAfterMs(attempts);
        }
      } else if (
        response?.status === 408 ||
        response?.status === 409 ||
        response?.status === 500 ||
        error instanceof TypeError
      ) {
        const err = new FetchError("Maximum number of retries reached");

        if (response?.status === 408) {
          err.code = HTTP_STATUS_REQUESTTIMEOUT;
        } else if (response?.status === 409) {
          err.code = HTTP_STATUS_CONFLICT;
        } else if (response?.status === 500) {
          err.code = HTTP_STATUS_INTERNAL_SERVER;
        } else if (error instanceof TypeError) {
          err.code = HTTP_STATUS_NETWORK_FAILURE;
        }

        err.url = url;
        err.method = options?.method;
        err.status = response?.status;
        err.statusText = response?.statusText;

        throw err;
      }

      return false;
    }
  );
}
