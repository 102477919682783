import { Reducer } from "@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { Saga } from "redux-saga";

interface InjectReducerAndSagaOptions {
  key: string;
  reducer: Reducer;
  saga: Saga;
}

/**
 * Convenience hook that combines `useInjectReducer` and `useInjectSaga` and
 * returns a single "injected" flag that waits for both.  Similar to
 * `createManager` from redux-injectors, but in hook form so we don't end up
 * with nested managers.
 */
export default function useInjectReducerAndSaga({ key, reducer, saga }: InjectReducerAndSagaOptions): boolean {
  const reducerInjected = useInjectReducer({ key, reducer });
  const sagaInjected = useInjectSaga({ key, saga });

  return reducerInjected && sagaInjected;
}
