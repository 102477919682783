import { Auth0ContextInterface } from "@auth0/auth0-react";
import { call, getContext, put } from "redux-saga/effects";

import {
  updateLatestCompanyFailure,
  UpdateLatestCompanyRequest,
  updateLatestCompanySuccess
} from "redux/reducers/tenancy";
import { postDataToApiWrapper } from "services/helpers";
import { DirectoryInternal } from "types/api";

/**
 * Calls the API /directory/internal/users/latest-company to update the latest company a user has accessed.
 */
export const updateLatestCompanyApi = (
  auth: Auth0ContextInterface,
  tenancyId: string
): Promise<DirectoryInternal.InternalUsersLatestCompanyCreate.ResponseBody> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/directory/internal/users/latest-company`;
  return postDataToApiWrapper(auth, urlWithDomain, tenancyId, {
    id: tenancyId
  });
};

/**
 * Saga to update the latest company for a user
 */
export default function* updateLatestCompany({ payload = {} }: UpdateLatestCompanyRequest): Generator {
  const auth = (yield getContext("auth0")) as Auth0ContextInterface;
  const { tenantId, successCallback, companyId } = payload;

  const tenancyId = tenantId || companyId;

  try {
    yield call(updateLatestCompanyApi, auth, tenancyId!);

    yield put(updateLatestCompanySuccess({ companyId: tenancyId! }));
    if (successCallback) {
      yield call(successCallback, tenancyId!);
    }
  } catch (e: any) {
    yield put(updateLatestCompanyFailure(e.message));
  }
}
