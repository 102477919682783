import { Box, MenuItem } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { Formik } from "formik";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import Select from "components/Select";
import TextField from "components/TextField";
import useCountries from "features/global/geography/useCountries";
import useMessages from "i18n/hooks/useMessages";
import { DeliveryAddress } from "types/api/generated/supplier";

import strings from "./DeliveryAddressModal.strings.json";

const FORM_ID = "address-modal";

const useStyles = makeStyles()(theme => ({
  desktopRow: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(2)
    }
  },
  countrySelect: {
    marginBottom: theme.spacing(1.5)
  },
  list: {
    maxHeight: theme.spacing(32)
  }
}));

interface DeliveryAddressModalProps extends ModalProps {
  address: DeliveryAddress;
  onSave: (address: DeliveryAddress) => void;
  isSaving?: boolean;
}

const DeliveryAddressModal: FunctionComponent<DeliveryAddressModalProps> = ({
  address,
  onSave,
  isSaving = false,
  onClose,
  open,
  ...rest
}) => {
  const messages = useMessages(strings);
  const { classes } = useStyles();

  const { data: countries = [] } = useCountries();
  const supportedCountries = countries.filter(country => country.isSupported);

  return (
    <Modal
      title={<FormattedMessage id={messages.TITLE} />}
      maxWidth="sm"
      onClose={onClose}
      actions={
        <>
          <Button type="button" color="primary" variant="text" onClick={onClose}>
            <FormattedMessage id={messages.CANCEL_BUTTON} />
          </Button>
          <Button type="submit" form={FORM_ID} color="primary" variant="contained" showLoader={isSaving}>
            <FormattedMessage id={messages.SAVE_BUTTON} />
          </Button>
        </>
      }
      open={open}
      {...rest}
    >
      <Formik initialValues={address} onSubmit={onSave}>
        {({ values, handleSubmit }) => (
          <form id={FORM_ID} onSubmit={handleSubmit}>
            <TextField
              label={<FormattedMessage id={messages.ATTENTION_LABEL} />}
              id="attention"
              name="attention"
              value={values.attention}
              margin="dense"
            />
            <TextField
              label={<FormattedMessage id={messages.ADDRESS_LINE_1_LABEL} />}
              id="address1"
              name="address1"
              value={values.address1}
              margin="dense"
            />
            <TextField
              label={<FormattedMessage id={messages.ADDRESS_LINE_2_LABEL} />}
              id="address2"
              name="address2"
              value={values.address2}
              margin="dense"
            />
            <Box className={classes.desktopRow}>
              <TextField
                label={<FormattedMessage id={messages.CITY_LABEL} />}
                id="city"
                name="city"
                value={values.city}
                margin="dense"
              />
              <TextField
                label={<FormattedMessage id={messages.ZONE_LABEL} />}
                id="zone"
                name="zone"
                value={values.zone}
                margin="dense"
              />
              <TextField
                label={<FormattedMessage id={messages.POST_CODE_LABEL} />}
                id="postalCode"
                name="postalCode"
                value={values.postalCode}
                margin="dense"
              />
            </Box>
            <Select
              label={<FormattedMessage id={messages.COUNTRY_LABEL} />}
              id="countryCode"
              name="countryCode"
              value={values.countryCode ?? ""}
              menuProps={{ classes: { list: classes.list } }}
              className={classes.countrySelect}
              fullWidth
            >
              {supportedCountries.map((supportedCountry, index) => (
                <MenuItem
                  key={supportedCountry.alpha2Code}
                  value={supportedCountry.alpha2Code}
                  divider={index === supportedCountries.length - 1}
                >
                  {supportedCountry.commonName}
                </MenuItem>
              ))}
              {countries.map(country => (
                <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                  {country.commonName}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label={<FormattedMessage id={messages.NOTES_LABEL} />}
              id="notes"
              name="notes"
              value={values.notes}
              margin="dense"
              characterLimit={100}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default DeliveryAddressModal;
