import { Redirect, RedirectProps, useLocation } from "react-router-dom";
import { useMount } from "react-use";

import { logger } from "utils/Datadog";

/**
 * A tiny wrapper around react-router's `<Redirect>` component to log that the
 * redirect has been used so that we have some visibility of these in Datadog.
 * You can view logged redirects with this dashboard:
 * https://app.datadoghq.com/dashboard/z4y-gw8-bxz/ui-redirects
 */
export default function LoggedRedirect(props: RedirectProps): JSX.Element {
  const { search } = useLocation();

  useMount(() => {
    const { from, to } = props;
    const fromUrl = from + (search ?? "");
    const toUrl = typeof to === "string" ? to : to.pathname + (to.search ?? "");

    logger.info(`Redirecting from ${fromUrl} to ${toUrl}`);
  });

  return <Redirect {...props} />;
}
