import {
  FetchAddressSuggestionsRequest,
  fetchAddressSuggestionsFailure,
  fetchAddressSuggestionsSuccess
} from "redux/reducers/geography";
import createSaga from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";
import { buildSearchString } from "utils/urls";

export default createSaga<FetchAddressSuggestionsRequest>(
  (auth0, _, { countryCode, search }) => {
    return fetchDataFromApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/global/internal/geography/addresses${buildSearchString({
        countryCode,
        search
      })}`
    );
  },
  {
    successAction: fetchAddressSuggestionsSuccess,
    failureAction: fetchAddressSuggestionsFailure
  }
);
