import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { useTheme } from "@mui/styles";
import { useMount } from "react-use";

import { onHide, onShow } from "utils/Intercom";
import { ANDROID } from "utils/MobilePlatforms";

/**
 * React hook for managing the status bar area on the native Android app.
 */
export default function useAndroidStatusBar(): void {
  const theme = useTheme();
  const { isAuthenticated } = useAuth0();

  useMount(() => {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      if (Capacitor.getPlatform() === ANDROID && isAuthenticated) {
        StatusBar.setBackgroundColor({ color: theme.palette.primary.main });
      }

      onShow(() => {
        StatusBar.hide();
      });

      onHide(() => {
        StatusBar.show();
      });
    }
  });
}
