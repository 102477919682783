import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Store } from "@reduxjs/toolkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { History } from "history";
import { JSX, ReactNode } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { TssCacheProvider } from "tss-react";

import rootSaga from "redux/sagas";
import defaultStore, { sagaMiddleware } from "redux/store";
import defaultQueryClient from "services/queryClient";
import defaultHistory from "utils/history";

sagaMiddleware.run(rootSaga);

const muiCache = createCache({
  key: "mui",
  prepend: true
});

const tssCache = createCache({
  key: "tss"
});

// This is to suppress local dev errors about :first-child being unsafe for SSR
// as we do not use SSR.  See: https://github.com/emotion-js/emotion/issues/1105
tssCache.compat = true;

interface ThirdPartyProviderProps {
  children: ReactNode;
  history?: History;
  queryClient?: QueryClient;
  store?: Store;
}

/**
 * All of the 3rd-party providers that we load at the top of the component tree,
 * plus any setup to get them going.
 */
export default function ThirdPartyProviders({
  children,
  history = defaultHistory,
  queryClient = defaultQueryClient,
  store = defaultStore
}: ThirdPartyProviderProps): JSX.Element {
  return (
    // Use custom style caches so our custom styles have higher specificity and
    // can override the default MUI styles
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <Provider store={store}>{children}</Provider>
          </Router>
        </QueryClientProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
}
