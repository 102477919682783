import {
  LoadPaymentSettingsMetadataRequest,
  loadPaymentSettingsMetadataSuccess,
  loadPaymentSettingsMetadataFailure
} from "redux/reducers/supplier/paymentSettingsMetadata";
import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api/generated/supplier";

/**
 * Fetches the metadata that describes the requirements for editing payment settings (i.e. what fields are available, required, etc).
 */
export default createSagaNext<LoadPaymentSettingsMetadataRequest, Supplier.V1SettingsPaymentsMetadataList.ResponseBody>(
  (auth, tenancyId) => {
    return fetchDataFromApiWrapper(
      auth,
      `${process.env.REACT_APP_API_URL}/supplier/v1/settings/payments/meta`,
      tenancyId
    );
  },
  {
    successAction: loadPaymentSettingsMetadataSuccess,
    failureAction: loadPaymentSettingsMetadataFailure
  }
);
