import { useAuth0 } from "@auth0/auth0-react";
import { JSX, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import PrivateRoute from "auth/PrivateRoute";
import usePermissions from "auth/usePermissions";
import GlobalNav from "components/GlobalNav";
import MobileNav from "components/MobileNav";
import BuyerOrderNowRedirect from "features/buyers/directory/OrderNowRedirect";
import BuyerOrdersFeature from "features/buyers/orders/OrdersFeature";
import InboxFeature from "features/suppliers/inbox-messages/InboxFeature";
import NewsfeedsGate from "features/suppliers/newsfeed/views/NewsfeedsGate";
import SupplierOrdersFeature from "features/suppliers/orders/OrdersFeature";
import PriceListsGate from "features/suppliers/price-lists/views/PriceListGate";
import QuickAddProductsFeature from "features/suppliers/quick-add-products/QuickAddProductsFeature";
import ReportsGate from "features/suppliers/reports/views/ReportsGate";
import useMobileNav from "mobile/useMobileNav";
import usePushNotifications from "native/usePushNotifications";
import CompanyInactivePage from "pages/CompanyInactivePage";
import CompanyNotFoundPage from "pages/CompanyNotFoundPage";
import ErrorPage from "pages/Error";
import LoadingScreen from "pages/LoadingScreen";
import PageNotFound from "pages/PageNotFound";
import { showSupplierNavMenu } from "redux/reducers/app";
import { recordError } from "redux/reducers/globalError";
import { setCompanyId } from "redux/reducers/tenancy";
import LoggedRedirect from "routes/LoggedRedirect";
import {
  BusinessProfile,
  BuyerDashboard,
  BuyerFavouritesLists,
  BuyerGettingStarted,
  BuyerGettingStartedOld,
  BuyerNewOrder,
  BuyerNewsfeed,
  BuyerOrderNowRedirect as BuyerOrderNowRedirectRoute,
  BuyerOrders,
  BuyerPaymentManagement,
  BuyerPlacedOrder,
  BuyerReports,
  BuyerSuppliers,
  CompanyError,
  CompanyOnboardingRoot,
  CompanyRoot,
  isBuyerRoute,
  isSupplierRoute,
  NoCompanyAccess,
  NotificationForwardingRules,
  Notifications as NotificationsRoute,
  PersonalProfile as PersonalProfileRoute,
  PlansPricing,
  Settings,
  SupplierCustomers,
  SupplierDashboard,
  SupplierDeliveryGroups,
  SupplierGettingStarted,
  SupplierInboxMessage,
  SupplierIntegrationsSettings,
  SupplierNewOrderChooseCustomer,
  SupplierNewsfeed,
  SupplierOldOrdersLayout,
  SupplierOrderSettings,
  SupplierOrdersGridLayout,
  SupplierOrdersGridLayout as SupplierOrdersGridRoute,
  SupplierPayments,
  SupplierPaymentTerms,
  SupplierPriceLists,
  SupplierProductImport,
  SupplierProducts,
  SupplierQuickAddProducts,
  SupplierReports,
  SupplierTaxSetup,
  Users as UsersRoute
} from "routes/Routes";
import lazyComponent from "services/lazyComponent";
import { Feature, MenuGroupType } from "types/api/generated/global-internal";
import { useIdentifyUser } from "utils/analytics";
import { isFetchError } from "utils/Errors";
import useAppSelector from "utils/hooks/useAppSelector";
import useExtraIntercomSettings from "utils/hooks/useExtraIntercomSettings";
import useGlobalNav from "utils/hooks/useGlobalNav";
import useRefreshNavigationData from "utils/hooks/useRefreshNavigationData";

import RootRedirect from "./RootRedirect";

// Common features
// -----------------------------------------------------------------------------

const OnboardingFeature = lazyComponent(() => import("features/onboarding/OnboardingFeature"));
const UserManagementFeature = lazyComponent(() => import("features/user-management/UserManagementFeature"));
const SettingsPage = lazyComponent(() => import("features/settings/SettingsPage"));
const BusinessProfileFeature = lazyComponent(() => import("features/settings/business-profile/BusinessProfileFeature"));
const PersonalProfileFeature = lazyComponent(() => import("features/settings/personal-profile/PersonalProfileFeature"));
const NotificationsFeature = lazyComponent(() => import("features/settings/notifications/NotificationSettingsFeature"));
const PlansPricingFeature = lazyComponent(() => import("features/settings/plans-pricing/PlansPricingFeature"));
const NotificationForwardingFeature = lazyComponent(
  () => import("features/settings/notification-forwarding/NotificationForwardingFeature")
);

// Buyer features
// -----------------------------------------------------------------------------

const BuyerDirectoryFeature = lazyComponent(() => import("features/buyers/directory/BuyerDirectoryFeature"));
const Favourites = lazyComponent(() => import("features/buyers/favourites/FavouritesFeature"));
const BuyerGettingStartedFeature = lazyComponent(() => import("features/buyers/getting-started/GettingStartedFeature"));
const BuyerNewsfeedFeature = lazyComponent(() => import("features/buyers/newsfeed/BuyerNewsfeedFeature"));
const BuyerDashboardFeature = lazyComponent(() => import("features/buyers/dashboard/BuyerDashboardFeature"));
const BuyerReportsFeature = lazyComponent(() => import("features/buyers/reports/ReportsFeature"));
const BuyerPaymentManagementFeature = lazyComponent(() => import("features/buyers/payments/BuyerPaymentsFeature"));

// Supplier features
// -----------------------------------------------------------------------------

const SupplierDirectory = lazyComponent(() => import("features/suppliers/directory/SupplierDirectoryFeature"));
const IntegrationsFeature = lazyComponent(() => import("features/suppliers/integrations/IntegrationsFeature"));
const ProductImportFeature = lazyComponent(() => import("features/suppliers/product-import/ProductImportFeature"));
const Products = lazyComponent(() => import("features/suppliers/products/ProductsFeature"));
const OrderSettings = lazyComponent(() => import("features/suppliers/order-settings/OrderSettingsFeature"));
const PriceLists = lazyComponent(() => import("features/suppliers/price-lists/PriceListsFeature"));
const TaxSetupFeature = lazyComponent(() => import("features/suppliers/tax-setup/TaxSetupFeature"));
const SupplierGettingStartedFeature = lazyComponent(
  () => import("features/suppliers/getting-started/GettingStartedFeature")
);
const SupplierReportsFeature = lazyComponent(() => import("features/suppliers/reports/ReportsFeature"));
const SupplierNewsfeedFeature = lazyComponent(() => import("features/suppliers/newsfeed/SupplierNewsfeedFeature"));
const SupplierDashboardFeature = lazyComponent(() => import("features/suppliers/dashboard/SupplierDashboardFeature"));
const SupplierPaymentsFeature = lazyComponent(() => import("features/suppliers/payments/PaymentsFeature"));
const SupplierPaymentTermsFeature = lazyComponent(
  () => import("features/suppliers/payments/terms/PaymentTermsFeature")
);
const SupplierDeliveryGroupsFeature = lazyComponent(
  () => import("features/suppliers/delivery-groups/DeliveryGroupsFeature")
);

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  rootWithNav: {
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      // Set a minimum width of 0 to prevent children from horizontally overflowing the grid
      gridTemplateColumns: "256px minmax(0, 1fr)"
    }
  }
}));

/**
 * Authenticated company routes, as denoted by the `/c` path prefix.
 */
export default function CompanyRouter(): JSX.Element {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const pushNotifications = usePushNotifications();
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const { pathname, search } = useLocation();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const { hasCompanyAccess, loading: permissionsLoading } = usePermissions();

  // Automatically identifies the user in analytics tools once the navigation data loads
  useIdentifyUser();

  const dispatch = useDispatch();
  const {
    preferences: { defaultView },
    // Pull the companyId from the navigationData permissions state so we know the navigation data has finished loading
    permissions: { companyId: tenancyIdInRedux }
  } = useAppSelector(state => state.navigationData);
  const { globalNavRendered } = useGlobalNav();
  const { mobileNavRendered } = useMobileNav();

  // Don't use the normal loading booleans here because some pages cause them to toggle on mount
  // which causes an infinite loop (like the tenancy loading boolean on the Users page)
  const isTenancyLoading = !tenancyIdInRedux;
  const isLoading = authLoading || permissionsLoading || isTenancyLoading;
  // Don't check for isTenancyLoading here because the tenancyId won't be set when the navigation data request 403's
  const noCompanyAccess = !authLoading && !permissionsLoading && isAuthenticated && !hasCompanyAccess;

  useExtraIntercomSettings();
  useRefreshNavigationData();

  const [companyNotFound, setCompanyNotFound] = useState(false);
  const [companyInactive, setCompanyInactive] = useState(false);

  useMount(() => {
    pushNotifications.register();

    if (!tenancyIdInRedux) {
      dispatch(
        setCompanyId({
          companyId: tenancyId,
          menuOverride: isBuyerRoute(pathname)
            ? MenuGroupType.Buyer
            : isSupplierRoute(pathname)
              ? MenuGroupType.Supplier
              : undefined,
          successCallback(response) {
            // This will be called with each of the responses from the sagas
            // that it triggers, so wait for the nav data response to check the
            // company inactive status
            if (typeof response !== "string") {
              const { companyProfile } = response;
              if (!companyProfile.isSupplier && !companyProfile.isBuyer) {
                setCompanyInactive(true);
              }
            }
          },
          failureCallback(error) {
            if (isFetchError(error) && error.status === 404) {
              setCompanyNotFound(true);
            } else {
              dispatch(recordError(error));
            }
          }
        })
      );
    }
  });

  const [navSet, setNavSet] = useState(false);

  // Decide which global nav to show (supplier or buyer)
  useEffect(() => {
    if (!isTenancyLoading && defaultView && !navSet) {
      dispatch(showSupplierNavMenu(defaultView === MenuGroupType.Supplier));
      setNavSet(true);
    }
  }, [isTenancyLoading, navSet, defaultView, dispatch]);

  useEffect(() => {
    if (noCompanyAccess) {
      history.push(NoCompanyAccess.toUrl());
    }
  }, [history, noCompanyAccess]);

  if (companyNotFound) {
    return <CompanyNotFoundPage />;
  }

  if (companyInactive) {
    return <CompanyInactivePage />;
  }

  if (isLoading || !isAuthenticated || noCompanyAccess) {
    return <LoadingScreen />;
  }

  return (
    <div className={cx(classes.root, { [classes.rootWithNav]: globalNavRendered })}>
      {globalNavRendered && <GlobalNav />}
      {mobileNavRendered && <MobileNav />}
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <PrivateRoute exact path={CompanyRoot.path} component={RootRedirect} />

          {/* Supplier features */}
          {/* ---------------------------------------------------------------- */}

          <PrivateRoute path={SupplierDashboard.path} component={SupplierDashboardFeature} />
          <PrivateRoute
            path={SupplierGettingStarted.path}
            component={SupplierGettingStartedFeature}
            permissions={SupplierGettingStarted.permissions}
          />
          {/* I've been told that our super old order emails point directly to
              the old order route, so log this to see if it's still a thing! */}
          <LoggedRedirect
            from="/c/:tenancyId/supplier/order/*"
            to={{
              pathname: "/c/:tenancyId/supplier/orders/*",
              search
            }}
          />
          <LoggedRedirect
            exact
            from="/c/:tenancyId/supplier/order"
            to={{
              pathname: SupplierNewOrderChooseCustomer.path,
              search
            }}
          />
          <LoggedRedirect
            exact
            from={SupplierOldOrdersLayout.path}
            to={{ pathname: SupplierOrdersGridRoute.path, search }}
          />
          <PrivateRoute
            path={[SupplierOldOrdersLayout.path, SupplierOrdersGridLayout.path]}
            component={SupplierOrdersFeature}
          />
          <PrivateRoute path={SupplierInboxMessage.path} component={InboxFeature} />
          <PrivateRoute
            path={SupplierProductImport.path}
            component={ProductImportFeature}
            permissions={SupplierProductImport.permissions}
          />
          <PrivateRoute path={SupplierProducts.path} component={Products} permissions={SupplierProducts.permissions} />
          <PrivateRoute
            path={SupplierPriceLists.path}
            component={PriceLists}
            permissions={SupplierPriceLists.permissions}
            billingFeature={Feature.ManagePriceLists}
            BillingFeatureGate={PriceListsGate}
          />
          <PrivateRoute
            path={SupplierCustomers.path}
            component={SupplierDirectory}
            permissions={SupplierCustomers.permissions}
          />
          <PrivateRoute
            path={SupplierNewsfeed.path}
            component={SupplierNewsfeedFeature}
            billingFeature={Feature.ManageNewsFeeds}
            BillingFeatureGate={NewsfeedsGate}
          />
          <PrivateRoute
            path={SupplierIntegrationsSettings.path}
            component={IntegrationsFeature}
            permissions={SupplierIntegrationsSettings.permissions}
          />
          <PrivateRoute
            path={SupplierOrderSettings.path}
            component={OrderSettings}
            permissions={SupplierOrderSettings.permissions}
          />
          <PrivateRoute
            path={SupplierQuickAddProducts.path}
            component={QuickAddProductsFeature}
            permissions={SupplierQuickAddProducts.permissions}
          />
          <LoggedRedirect
            from="/c/:tenancyId/reports*"
            to={{
              pathname: `${SupplierReports.path}*`,
              search
            }}
          />
          <PrivateRoute
            path={SupplierReports.path}
            component={SupplierReportsFeature}
            billingFeature={Feature.Reports}
            BillingFeatureGate={ReportsGate}
          />
          <PrivateRoute
            path={SupplierPayments.path}
            component={SupplierPaymentsFeature}
            permissions={SupplierPayments.permissions}
          />
          <PrivateRoute
            path={SupplierTaxSetup.path}
            component={TaxSetupFeature}
            permissions={SupplierTaxSetup.permissions}
          />
          <PrivateRoute
            path={SupplierPaymentTerms.path}
            component={SupplierPaymentTermsFeature}
            permissions={SupplierPaymentTerms.permissions}
          />
          <PrivateRoute
            path={SupplierDeliveryGroups.path}
            component={SupplierDeliveryGroupsFeature}
            fallbackRoute={Settings}
          />

          {/* Buyer features */}
          {/* ---------------------------------------------------------------- */}

          <PrivateRoute path={BuyerDashboard.path} component={BuyerDashboardFeature} />
          <PrivateRoute
            path={BuyerGettingStarted.path}
            component={BuyerGettingStartedFeature}
            permissions={BuyerGettingStarted.permissions}
          />
          <LoggedRedirect from={BuyerGettingStartedOld.path} to={BuyerGettingStarted.path} />
          <LoggedRedirect
            exact
            from="/c/:tenancyId/buyer/order/new"
            to={{
              pathname: BuyerNewOrder.path,
              search
            }}
          />
          <LoggedRedirect
            exact
            from="/c/:tenancyId/buyer/order/new/products"
            to={{
              pathname: BuyerNewOrder.path,
              search
            }}
          />
          {/* I've been told that our super old order emails point directly to the
            old order route, so log this to see if it's still a thing! */}
          <LoggedRedirect from="/c/:tenancyId/buyer/order/placed" to={BuyerPlacedOrder.path} />
          <LoggedRedirect
            from="/c/:tenancyId/buyer/order/*"
            to={{
              pathname: "/c/:tenancyId/buyer/orders/*",
              search
            }}
          />
          <PrivateRoute path={BuyerOrders.path} component={BuyerOrdersFeature} />
          <PrivateRoute path={BuyerOrderNowRedirectRoute.path} component={BuyerOrderNowRedirect} />
          <PrivateRoute
            path={BuyerFavouritesLists.path}
            component={Favourites}
            permissions={BuyerFavouritesLists.permissions}
          />
          <PrivateRoute path={BuyerSuppliers.path} component={BuyerDirectoryFeature} />
          <PrivateRoute path={BuyerNewsfeed.path} component={BuyerNewsfeedFeature} />
          <PrivateRoute path={BuyerReports.path} component={BuyerReportsFeature} />
          <PrivateRoute path={BuyerPaymentManagement.path} component={BuyerPaymentManagementFeature} />

          {/* Common features features */}
          {/* ---------------------------------------------------------------- */}

          <PrivateRoute path={CompanyOnboardingRoot.path} component={OnboardingFeature} />
          <PrivateRoute
            path={BusinessProfile.path}
            component={BusinessProfileFeature}
            permissions={BusinessProfile.permissions}
          />
          <PrivateRoute path={UsersRoute.path} component={UserManagementFeature} permissions={UsersRoute.permissions} />
          <PrivateRoute path={PlansPricing.path} component={PlansPricingFeature} />
          <PrivateRoute path={PersonalProfileRoute.path} component={PersonalProfileFeature} />
          <PrivateRoute path={NotificationsRoute.path} component={NotificationsFeature} />
          <PrivateRoute path={NotificationForwardingRules.path} component={NotificationForwardingFeature} />
          <PrivateRoute path={Settings.path} component={SettingsPage} />
          <PrivateRoute path={CompanyError.path} component={ErrorPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </div>
  );
}
