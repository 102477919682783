import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { JSX } from "react";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import { logger } from "utils/Datadog";
import useTenancyId from "utils/hooks/useTenancyId";

import strings from "./CompanyNotFoundPage.strings.json";

const useStyles = makeStyles()(theme => ({
  main: {
    display: "grid",
    gap: theme.spacing(3),
    justifyItems: "center",
    textAlign: "center"
  },
  icon: {
    height: "40px",
    width: "40px"
  }
}));

/**
 * A 404 page for company landing pages.  Usually encountered by us when we
 * throw a tenantId into the URL and it matches no company.
 */
export default function CompanyNotFoundPage(): JSX.Element {
  const { classes } = useStyles();
  const tenancyId = useTenancyId();
  const messages = useMessages(strings);

  useMount(() => {
    logger.warn(`Someone tried to launch Upstock with a non-existent companyId: ${tenancyId}`);
  });

  return (
    <Page header={<SkeletonHeader backgroundColor="white" />} displayNav={false} containerMaxWidth="xs">
      <main className={classes.main}>
        <LogoIcon className={classes.icon} />
        <Typography variant="h1">
          <FormattedMessage id={messages.HEADER} />
        </Typography>
        <div>
          <Typography paragraph>
            <FormattedMessage id={messages.MESSAGE1} />
          </Typography>
          <Typography>
            <FormattedMessage id={messages.MESSAGE2} values={{ home: { href: "/" } }} />
          </Typography>
        </div>
        <Button component="a" color="primary" variant="contained" href="/">
          <FormattedMessage id={messages.HOME} />
        </Button>
      </main>
    </Page>
  );
}
