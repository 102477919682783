import MChip, { ChipProps as MChipProps } from "@mui/material/Chip";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  chip: {
    borderRadius: theme.shape.borderRadius
  }
}));

export interface ChipProps extends MChipProps {}

const Chip: FunctionComponent<ChipProps> = ({ className, ...other }) => {
  const { classes, cx } = useStyles();
  return <MChip className={cx(classes.chip, className)} {...other} />;
};

export default Chip;
