import { TypeHandler } from "@ultraq/icu-message-formatter";
import { ReactNode } from "react";

/**
 * Simple type handler for any React component that can take a string for its
 * `children` prop.
 */
const componentTypeHandler: TypeHandler = function componentTypeHandler(
  component,
  children,
  _,
  values,
  process
): ReactNode {
  return component(process(children, values));
};

export default componentTypeHandler;
