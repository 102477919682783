import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { useDispatch } from "react-redux";

import { deregisterDevicePushNotificationRequest, updatePermissionStatus } from "redux/reducers/pushNotifications";
import { Platform } from "types/api/generated/directory-internal";
import useAppSelector from "utils/hooks/useAppSelector";
import { IOS } from "utils/MobilePlatforms";
import { promisifySaga } from "utils/Sagas";

export interface UsePushNotifications {
  register: () => Promise<void>;
  deregister: () => Promise<void>;
}

const usePushNotifications = (): UsePushNotifications => {
  const dispatch = useDispatch();
  const pushNotifications = useAppSelector(state => state.pushNotifications);
  const { isAuthenticated } = useAuth0();

  const canUsePushNotifications = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable("PushNotifications");

  const register = async (): Promise<void> => {
    if (!isAuthenticated) {
      return;
    }

    if (!canUsePushNotifications) {
      return;
    }

    const status = await PushNotifications.checkPermissions();

    if (status.receive === "granted") {
      await PushNotifications.register();
    }

    dispatch(updatePermissionStatus({ status: status.receive }));
  };

  const deregister = async (): Promise<void> => {
    if (!isAuthenticated) {
      return;
    }

    if (!canUsePushNotifications) {
      return;
    }

    // Deregister the device with APNS/FCM
    await PushNotifications.unregister();

    const { token } = pushNotifications;

    if (token) {
      // Deregsiter the device with our backend
      await promisifySaga(
        dispatch,
        deregisterDevicePushNotificationRequest
      )({
        token,
        platform: Capacitor.getPlatform() === IOS ? Platform.Ios : Platform.Android
      });
    }
  };

  return { register, deregister };
};

export default usePushNotifications;
