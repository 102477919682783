import { MessageFormatter, pluralTypeHandler, selectTypeHandler } from "@ultraq/icu-message-formatter";
import { MessageFormatterProvider as Provider, MessageResolver } from "@ultraq/react-icu-message-formatter";
import { JSX, PropsWithChildren, useCallback, useMemo } from "react";

import useLocale from "./hooks/useLocale";
import componentTypeHandler from "./type-handlers/componentTypeHandler";
import currencyTypeHandler from "./type-handlers/currencyTypeHandler";
import dateTimeTypeHandler from "./type-handlers/dateTimeTypeHandler";
import externalLinkTypeHandler from "./type-handlers/externalLinkTypeHandler";
import fallbackTypeHandler from "./type-handlers/fallbackTypeHandler";
import intercomArticleTypeHandler from "./type-handlers/intercomArticleTypeHandler";
import intercomLinkTypeHandler from "./type-handlers/intercomLinkTypeHandler";
import linkTypeHandler from "./type-handlers/linkTypeHandler";
import listTypeHandler from "./type-handlers/listTypeHandler";

/**
 * Component of all of the configuration for the ICU Message Formatter.
 */
export default function MessageFormatterProvider({ children }: PropsWithChildren): JSX.Element {
  const locale = useLocale();

  const formatter = useMemo(
    () =>
      new MessageFormatter(locale, {
        // React components
        component: componentTypeHandler,
        externalLink: externalLinkTypeHandler,
        intercomArticle: intercomArticleTypeHandler,
        intercomLink: intercomLinkTypeHandler,
        link: linkTypeHandler,

        // Plain string formatting
        currency: currencyTypeHandler,
        dateTime: dateTimeTypeHandler,
        fallback: fallbackTypeHandler,
        list: listTypeHandler,
        plural: pluralTypeHandler,
        select: selectTypeHandler
      }),
    [locale]
  );

  const messageResolver: MessageResolver = useCallback((id: string, _locale: string) => id, []);

  return (
    <Provider formatter={formatter} messageResolver={messageResolver}>
      {children}
    </Provider>
  );
}
