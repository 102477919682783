import { useState } from "react";
import { useUpdateEffect } from "react-use";

import { isNullOrUndefined } from "utils/helpers";

import useAppSelector from "./useAppSelector";

export interface UseCompanyRolesResult {
  isSupplier: boolean | null;
  isBuyer: boolean | null;
  isBothSupplierBuyer: boolean | null;
  directoryString: "supplier" | "buyer";
  companyLoading: boolean;
}

export default (): UseCompanyRolesResult => {
  const { isSupplier, isBuyer, loading } = useAppSelector(state => state.tenancy);
  const [isSupplierCompany, setIsSupplierCompany] = useState(isSupplier);
  const [isBuyerCompany, setIsBuyerCompany] = useState(isBuyer);

  useUpdateEffect(() => {
    setIsSupplierCompany(isSupplier);
    setIsBuyerCompany(isBuyer);
  }, [isSupplier, isBuyer]);

  return {
    isSupplier: isSupplierCompany,
    isBuyer: isBuyerCompany,
    isBothSupplierBuyer: isBuyer && isSupplier,
    directoryString: isSupplier ? "supplier" : "buyer",
    companyLoading: loading || isNullOrUndefined(isSupplierCompany)
  };
};
