import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, ListItemButton, ListSubheader } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { menuClasses } from "@mui/material/Menu";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import ColouredChip from "components/Chip/ColouredChip";
import DropDownMenu from "components/DropDownMenu";
import ListItemLink from "components/ListItemLink";
import useIsSupplierOnboarding from "features/suppliers/getting-started/useIsSupplierOnboarding";
import useMessages from "i18n/hooks/useMessages";
import { CompanyRoot, PersonalProfile, SupplierGettingStarted } from "routes/Routes";
import { CompanySummary } from "types/api/generated/directory-internal";
import useAppSelector from "utils/hooks/useAppSelector";
import useGlobalNav from "utils/hooks/useGlobalNav";
import { showNewMessage } from "utils/Intercom";

import strings from "./CompanySwitcher.strings.json";

const useStyles = makeStyles()(theme => ({
  listItem: {
    lineHeight: "24px" // So that items without icons have the same height
  },
  listItemInset: {
    paddingLeft: theme.spacing(6)
  },
  button: {
    fontSize: "18px",
    fontWeight: 700,
    justifyContent: "flex-start",
    lineHeight: 1.25,
    padding: theme.spacing(1.5, 2),
    textAlign: "left",
    width: "100%"
  },
  listSubheader: {
    backgroundColor: theme.palette.common.white,
    lineHeight: "40px",
    paddingTop: theme.spacing(1)
  },
  dropDown: {
    [`& .${menuClasses.list}`]: {
      padding: 0
    }
  },
  wrapper: {
    border: `1px solid ${theme.palette.divider}`,
    borderLeft: "none",
    borderRight: "none"
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24
  },
  colouredChip: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  onMenuItemClick?: () => void;
}

/**
 * Return whether or not the country code should be shown alongside the given
 * company in the switcher.  This is used to distinguish between being in the
 * same company in multiple regions.
 */
function showCountryCode(currentCompany: CompanySummary, companies: CompanySummary[]): boolean {
  const companiesWithSameName = companies.filter(company => company.tradingName === currentCompany.tradingName);
  return companiesWithSameName.length > 1;
}

/**
 * Return the display name for the company any place it is shown in the company
 * switcher.
 */
function getCompanyDisplayName(company: CompanySummary | undefined, companies: CompanySummary[]): string {
  return company ? company.tradingName + (showCountryCode(company, companies) ? ` (${company.country.code})` : "") : "";
}

/**
 * A kind of drop-down menu in the global nav which allows people to change
 * companies and access personal/account settings.
 */
const CompanySwitcher: FunctionComponent<Props> = ({ onMenuItemClick = () => {} }) => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const { logout, user } = useAuth0();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const { firstName, lastName } = useAppSelector(state => state.userProfile);
  const tenancy = useAppSelector(state => state.tenancy);
  const isSupplierOnboarding = useIsSupplierOnboarding();

  const userName = firstName && lastName ? `${firstName} ${lastName}` : user?.email;

  // Add the companyProfile to the list of companies if it's missing from it.
  // This fixes the company switcher looking broken for admin users when they
  // view a company that they're not a part of.
  const allCompanies: CompanySummary[] = useMemo(() => {
    if (tenancy.companyId && !tenancy.companies.find(c => c.companyId === tenancy.companyId)) {
      return [tenancy, ...tenancy.companies];
    }
    return tenancy.companies;
  }, [tenancy]);

  const handleLogout = useCallback(() => {
    onMenuItemClick();
    logout();
  }, [logout, onMenuItemClick]);

  const { hideGlobalNav } = useGlobalNav();

  const openIntercomWithAddBusinessMessage = useCallback(() => {
    showNewMessage(formatter.format(strings.BUSINESSES_ADD_INTERCOMMESSAGE));
    hideGlobalNav();
  }, [formatter, hideGlobalNav]);

  return (
    <Box className={cx(classes.wrapper)}>
      <DropDownMenu
        id="company-switcher"
        className={cx(classes.dropDown)}
        trigger={triggerProps => (
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            className={cx(classes.button)}
            {...triggerProps}
            data-testid="company-switcher"
          >
            {getCompanyDisplayName(
              allCompanies.find(c => c.companyId === tenancy.companyId),
              allCompanies
            )}
          </Button>
        )}
      >
        {[
          <List
            key="businesses-list"
            subheader={
              <ListSubheader className={cx(classes.listSubheader)} disableSticky>
                <FormattedMessage id={messages.BUSINESSES_HEADER} />
              </ListSubheader>
            }
          >
            {allCompanies.map(company => {
              const selected = company.companyId === tenancy.companyId;
              return (
                <ListItemLink
                  className={cx(classes.listItem, { [classes.listItemInset]: !selected })}
                  key={company.companyId}
                  href={CompanyRoot.toUrl({ tenancyId: company.companyId })}
                  onClick={onMenuItemClick}
                >
                  {selected && (
                    <span className={cx(classes.icon)}>
                      <CheckIcon />
                    </span>
                  )}
                  {getCompanyDisplayName(company, allCompanies)}
                </ListItemLink>
              );
            })}
            <ListItemButton onClick={openIntercomWithAddBusinessMessage}>
              <span className={cx(classes.icon)}>
                <AddIcon />
              </span>
              <FormattedMessage id={messages.BUSINESSES_ADD} />
            </ListItemButton>
          </List>,
          <Divider key="divider" />,
          <List
            key="other-options"
            subheader={
              <ListSubheader className={cx(classes.listSubheader)} disableSticky>
                {userName || user?.email}
              </ListSubheader>
            }
          >
            <ListItemLink
              className={cx(classes.listItem)}
              to={PersonalProfile.toUrl({ tenancyId })}
              onClick={onMenuItemClick}
            >
              <FormattedMessage id={messages.PERSONALPROFILE} />
            </ListItemLink>
            <ListItemButton onClick={handleLogout} className={cx(classes.listItem)}>
              <FormattedMessage id={messages.SIGNOUT} />
            </ListItemButton>
          </List>
        ]}
      </DropDownMenu>

      {isSupplierOnboarding && (
        <ColouredChip
          className={cx(classes.colouredChip)}
          color="blue"
          label={<FormattedMessage id={messages.ONBOARDING_CHIP} />}
          onClick={() => history.push(SupplierGettingStarted.toUrl({ tenancyId }))}
        />
      )}
    </Box>
  );
};

export default CompanySwitcher;
