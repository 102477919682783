import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Buyer } from "types/api";
import { buildSearchString } from "utils/urls";

import {
  fetchFeaturedSuppliersFailure,
  FetchFeaturedSuppliersRequest,
  fetchFeaturedSuppliersSuccess
} from "../reducers/directory";

export default createSagaNext<FetchFeaturedSuppliersRequest, Buyer.V1DirectoryList.ResponseBody>(
  (auth0, tenancyId, { tags, limit }) => {
    const urlWithDomain = `${process.env.REACT_APP_API_URL}/buyer/v1/directory${buildSearchString({
      tags,
      limit,
      featured: true,
      sortBy: "random"
    })}`;
    return fetchDataFromApiWrapper(auth0, urlWithDomain, tenancyId);
  },
  {
    successAction: fetchFeaturedSuppliersSuccess,
    failureAction: fetchFeaturedSuppliersFailure
  }
);
