import { Auth0ContextInterface } from "@auth0/auth0-react";

import { acceptTermsConditionsFailure, acceptTermsConditionsSuccess } from "redux/reducers/termsConditions";
import { putDataToApiWrapper } from "services/helpers";

import { createSaga } from "./createSaga";

function acceptTermsConditionsApi(auth: Auth0ContextInterface): Promise<void> {
  return putDataToApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/directory/internal/users/accept-terms-conditions`,
    "",
    {}
  );
}

/**
 * Saga task for accepting terms and conditions via the
 * `/supplier/internal/users/accept-terms-conditions endpoint.
 */
export default createSaga(acceptTermsConditionsApi, {
  successAction: acceptTermsConditionsSuccess,
  failureAction: acceptTermsConditionsFailure
});
