import { Feature } from "types/api/generated/global-internal";

import useAppSelector from "./useAppSelector";

export default function useHasBillingFeature(feature?: Feature): boolean {
  const allBillingFeatures = useAppSelector(state => state.navigationData.billing?.features);

  if (!feature) {
    return true; // if feature is not passed in, assume they have access
  }

  return allBillingFeatures.includes(feature);
}
