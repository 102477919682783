import { createAction } from "@reduxjs/toolkit";

import { withPayloadType } from "types/redux-helpers";

import { OrderId } from "../reducers/supplierOrderById";

export const removeInvalidOrderLineSuccess = createAction(
  "invalidOrderLines/removeInvalidOrderLineSuccess",
  withPayloadType<OrderId>()
);
