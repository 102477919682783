import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";

import strings from "./strings.json";

const useStyles = makeStyles()(theme => ({
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  goHomeBtn: {
    marginTop: theme.spacing(1)
  }
}));

const ErrorPage: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();

  return (
    <Page
      header={<SkeletonHeader backgroundColor="white" />}
      displayNav={false}
      containerClassName={cx(classes.pageContainer)}
    >
      <Typography variant="h1" gutterBottom>
        <FormattedMessage id={messages.SORRYMESSAGE} />
      </Typography>
      <div>
        <p>
          <FormattedMessage id={messages.SUBMESSAGE} />
        </p>
        <p>
          <FormattedMessage id={messages.ISSUESMESSAGE} />
        </p>
        <Button component="a" variant="contained" color="primary" href="/" className={classes.goHomeBtn}>
          <FormattedMessage id={messages.GOHOME} />
        </Button>
      </div>
    </Page>
  );
};

export default ErrorPage;
