import {
  fetchCompanyProfileByIdFailure,
  FetchCompanyProfileByIdRequest,
  fetchCompanyProfileByIdSuccess
} from "redux/reducers/company";
import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";

export default createSagaNext<FetchCompanyProfileByIdRequest>(
  (auth, _tenancyId, { id }) => {
    return fetchDataFromApiWrapper(auth, `${process.env.REACT_APP_API_URL}/directory/v1/companies/${id}/profile`);
  },
  {
    successAction: fetchCompanyProfileByIdSuccess,
    failureAction: fetchCompanyProfileByIdFailure
  }
);
