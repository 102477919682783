import { Box, SxProps, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  price: {
    display: "flex",
    flexDirection: "row"
  },
  denomination: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  denominationText: {
    marginBottom: "0.5em",
    fontSize: 12,
    fontWeight: "bold"
  },
  dollars: {
    display: "flex",
    padding: "0 2px"
  },
  dollarsText: {
    fontSize: 22,
    fontWeight: "bold"
  },
  cents: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  centsText: {
    marginBottom: "0.5em",
    fontSize: 12,
    fontWeight: "bold"
  }
});

interface Props {
  price: number;
  className?: string;
  sx?: SxProps;
}

const StackedPrice: FunctionComponent<Props> = ({ price, className, sx }) => {
  const { classes, cx } = useStyles();
  const dollars = price > 0 ? Math.floor(price) : Math.ceil(price);
  const cents = Math.abs((price - dollars) * 100).toLocaleString(undefined, {
    minimumIntegerDigits: 2
  });

  return (
    <Box className={cx(classes.price, className)} sx={sx}>
      <div className={cx(classes.denomination)}>
        <Typography className={cx(classes.denominationText)}>$</Typography>
      </div>
      <div className={cx(classes.dollars)}>
        <Typography className={cx(classes.dollarsText)}>{dollars}</Typography>
      </div>
      <div className={cx(classes.cents)}>
        <Typography className={cx(classes.centsText)}>{cents}</Typography>
      </div>
    </Box>
  );
};

export default StackedPrice;
