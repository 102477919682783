import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";

import Header from "components/Header";
import Page from "components/Page";
import PaidFeaturePromotion, { Perk, Quote } from "components/PaidFeaturePromotion/PaidFeaturePromotion";
import useMessages from "i18n/hooks/useMessages";
import BoxIcon from "media/gated-sections/box.svg?react";
import CompanyPhoto from "media/gated-sections/company-images/yum-photo.jpg";
import CompanyLogo from "media/gated-sections/company-logos/yum-logo.png";
import FileStackIcon from "media/gated-sections/file-stack.svg?react";
import ReportsThumbnail from "media/gated-sections/thumbnails/production-report.png";
import { Feature } from "types/api/generated/global-internal";

import strings from "./ReportsGate.strings.json";

interface ReportsGateProps {
  feature?: Feature;
}

const ReportsGate: FunctionComponent<ReportsGateProps> = ({ feature }) => {
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();

  const header = <FormattedMessage id={messages.HEADER} />;
  const description = <FormattedMessage id={messages.DESCRIPTION} />;
  const upgradePrefill = formatter.format(messages.UPGRADE_PREFILL);
  const demoPrefill = formatter.format(messages.DEMO_PREFILL);
  const videoId = "703097400";
  const perks: Perk[] = [
    {
      icon: BoxIcon,
      title: <FormattedMessage id={messages.PERK1_TITLE} />,
      description: <FormattedMessage id={messages.PERK1_DESCRIPTION} />
    },
    {
      icon: FileStackIcon,
      title: <FormattedMessage id={messages.PERK2_TITLE} />,
      description: <FormattedMessage id={messages.PERK2_DESCRIPTION} />
    }
  ];
  const quote: Quote = {
    image: CompanyPhoto,
    text: <FormattedMessage id={messages.QUOTE} />,
    author: <FormattedMessage id={messages.QUOTE_AUTHOR} />,
    logo: CompanyLogo
  };

  return (
    <Page header={<Header title={formatter.format(messages.TITLE)} />}>
      <PaidFeaturePromotion
        header={header}
        upgradePrefill={upgradePrefill}
        demoPrefill={demoPrefill}
        description={description}
        videoId={videoId}
        videoThumbnail={ReportsThumbnail}
        perks={perks}
        quote={quote}
        feature={feature}
      />
    </Page>
  );
};

export default ReportsGate;
