import { formLabelClasses, iconButtonClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ThemeProvider as MuiThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid";
import { ReactNode } from "react";

import { LocalLink } from "components/LocalLinkOrExternalLink";
import { DEFAULT_PAGE_SIZES_DATAGRID } from "utils/paging";

const theme = responsiveFontSizes(
  createTheme({
    /* Overriden theme colors, shades generated by Material UI Color tool */
    palette: {
      primary: {
        main: "#fd554a"
      },
      secondary: {
        main: "#120095"
      },
      error: {
        main: "#fd554a"
      },
      success: {
        main: "#1bbf60"
      },
      info: {
        main: "#ffffff"
      },
      text: {
        primary: "#212121",
        secondary: grey[600],
        disabled: "rgba(0, 0, 0, 0.38)"
        // hint: "rgba(0, 0, 0, 0.38)"
      },
      common: {
        darkShadow: "#e24f45",
        green: "#1BBF60",
        red: "#D13820"
      },

      background: {
        default: "rgb(250, 250, 250)"
      }
    },
    mixins: {
      header: {
        height: 64
      },
      footer: {
        height: 70
      }
    },
    typography: {
      fontFamily: [
        '"Inter"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        "Oxygen-Sans",
        "Ubuntu",
        "Cantarell",
        '"Helvetica Neue"',
        "sans-serif"
      ].join(","),
      body1: {
        fontSize: "1rem"
      },
      body2: {
        fontSize: "0.875rem"
      },
      caption: {
        fontSize: "0.75rem"
      },
      h1: {}
    }
  })
);

theme.typography.h1 = {
  fontSize: "1.375rem",
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem"
  }
};

theme.typography.h2 = {
  fontSize: "1.125rem",
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.25rem"
  }
};

theme.typography.h3 = {
  fontSize: "1.0625rem",
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.125rem"
  }
};

theme.typography.button = {
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1.75
};

theme.mixins.toolbar = {
  minHeight: 48,
  [theme.breakpoints.up("sm")]: {
    minHeight: 64
  }
};

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em"
      }
    }
  },

  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        "&.MuiFab-extended": {
          padding: "0 1.5rem"
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.grey[300]
        }
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)"
      }
    }
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[600]
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: "99px",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[400]
        },
        "& .MuiOutlinedInput-notchedOutline": {
          top: 0
        },
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(2, 0),
          width: "100%"
        }
      }
    }
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[50],
        "&:hover": {
          backgroundColor: theme.palette.grey[50]
        },
        "&.Mui-focused": {
          backgroundColor: theme.palette.grey[50]
        },
        ".Mui-underline": {
          "&:before": {
            borderColor: theme.palette.grey[400]
          },
          "&:hover:before": {
            borderColor: theme.palette.grey[400]
          }
        },
        [theme.breakpoints.down("xs")]: {
          width: "100%"
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.grey[50]
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      filled: {
        textAlign: "left"
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(1),
        lineHeight: 1.25,
        "&.Mui-disabled": {
          color: theme.palette.grey[600]
        }
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LocalLink
    },
    styleOverrides: {
      root: {
        fontFamily: theme.typography.fontFamily
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        [theme.breakpoints.down("xs")]: {
          borderRadius: 0
        }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        ".Mui-expanded": {
          margin: 0
        },
        "&:before": {
          height: 0
        }
      }
    }
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        ".Mui-expanded": {
          margin: 0
        }
      }
    }
  },
  MuiTabs: {
    defaultProps: {
      textColor: "inherit"
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        marginRight: "28px !important"
      }
    },
    defaultProps: {
      disableTouchRipple: true
    }
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 0, 0)
      }
    }
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: theme.palette.grey[50]
        }
      }
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: theme.palette.grey[50]
        },
        "&.Mui-selected:hover": {
          backgroundColor: theme.palette.grey[200]
        }
      }
    }
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "40px"
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      labelSmall: {
        fontSize: "12px"
      }
    }
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true
    }
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
      component: LocalLink
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: theme.palette.grey[50]
        },
        "&.Mui-selected:hover": {
          backgroundColor: theme.palette.grey[200]
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.secondary,
        [`&.${formLabelClasses.focused}`]: {
          color: theme.palette.text.secondary
        },
        [`&.${formLabelClasses.error}`]: {
          color: theme.palette.error.main
        }
      }
    }
  },
  MuiDataGrid: {
    defaultProps: {
      autoHeight: true,
      columnHeaderHeight: 48,
      rowHeight: 56,
      disableColumnMenu: true,
      sortingOrder: ["asc", "desc"],
      pageSizeOptions: DEFAULT_PAGE_SIZES_DATAGRID
    },
    styleOverrides: {
      root: {
        borderColor: theme.palette.grey[400],
        background: theme.palette.common.white,
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          [`&:not(.${gridClasses.columnHeaderCheckbox})`]: {
            padding: theme.spacing(0, 2)
          },
          "&:focus, &:focus-within": {
            outline: "none"
          }
        },
        [`& .${gridClasses.columnHeaderCheckbox} + .${gridClasses.columnHeader}`]: {
          paddingLeft: 0
        },
        [`& .${gridClasses.cellCheckbox} + .${gridClasses.cell}`]: {
          paddingLeft: 0
        }
      },
      columnSeparator: {
        display: "none"
      },
      columnHeaders: {
        background: theme.palette.grey[50]
      },
      columnHeader: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
        color: theme.palette.text.secondary
      },
      "columnHeader--sortable": {
        textDecoration: "underline"
      },
      iconButtonContainer: {
        [`& .${iconButtonClasses.root}`]: {
          fontSize: "inherit"
        }
      },
      withBorderColor: {
        borderColor: theme.palette.grey[300]
      },
      row: {
        cursor: "pointer",
        "&.Mui-selected": {
          background: "none"
        },
        "&:hover, &.Mui-selected:hover": {
          background: theme.palette.grey[100]
        },
        // Sets the text styles for the first non-checkbox column
        [`& > .${gridClasses.cell}`]: {
          fontSize: theme.typography.body1.fontSize,
          fontWeight: theme.typography.body1.fontWeight,
          lineHeight: theme.typography.body1.lineHeight,
          color: theme.palette.text.primary
        },
        // Sets the text styles for all the other columns
        [`& > .${gridClasses.cell}:not(.${gridClasses.cellCheckbox}) ~ .${gridClasses.cell}:not(.${gridClasses.cellCheckbox})`]:
          {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.body2.fontWeight,
            lineHeight: theme.typography.body2.lineHeight,
            color: theme.palette.text.secondary
          }
      }
    }
  }
};

export { theme };

interface ThemeProviderProps {
  children: ReactNode;
}

/**
 * The main Upstock theme provider.
 */
export default function ThemeProvider({ children }: ThemeProviderProps): JSX.Element {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
