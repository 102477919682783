import MList, { ListProps as MListProps } from "@mui/material/List";
import { ElementType, FunctionComponent, memo, ReactNode } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  scrollableList: {
    overflowY: "auto",
    overflowX: "visible"
  }
});

export interface ListProps extends MListProps {
  component?: ElementType;
  className?: string;
  loadMoreCallback?: () => void;
  hasMore?: boolean;
  loader?: ReactNode;
  isLoading?: boolean;
  isScrollable?: boolean;
}

/**
 * A Material UI `<List>` component combined with an infinite scroll mechanism
 * for such lists that appear throughout our app.
 */
const List: FunctionComponent<ListProps> = ({
  className,
  loadMoreCallback = () => {},
  hasMore = false,
  loader,
  isLoading = false,
  children,
  isScrollable,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const handleLoadMore = (): void => {
    hasMore && loadMoreCallback();
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: handleLoadMore,
    rootMargin: "0px 0px 100px 0px"
  });

  // When the list element isn't scrollable, we want the window to be the scroll root
  const ref = isScrollable ? rootRef : null;

  return (
    <MList
      className={cx(className, {
        [classes.scrollableList]: isScrollable
      })}
      ref={ref}
      {...rest}
    >
      {children}
      {hasMore && !isLoading && <div ref={sentryRef} />}
      {isLoading && loader}
    </MList>
  );
};
export default memo(List);
