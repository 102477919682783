import CameraIcon from "@mui/icons-material/CameraAlt";
import { Box, BoxProps, IconButton } from "@mui/material";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Property } from "csstype";
import { FunctionComponent, ReactNode, useMemo } from "react";
import { makeStyles } from "tss-react/mui";

import SmallRemoveIcon from "media/icon-remove-small.svg?react";
import { Image as ImageType } from "types/api/generated/supplier";

import strings from "./Image.strings.json";

const useStyles = makeStyles<{ fit?: Property.ObjectFit; aspectRatio?: number }>()((theme, { fit, aspectRatio }) => ({
  deleteButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    position: "absolute"
  },
  iconBtn: {
    width: 24,
    height: 24,
    top: -12,
    left: 12,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    "&:hover": {
      backgroundColor: theme.palette.grey[50]
    }
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    objectFit: fit ?? (aspectRatio && aspectRatio > 0.6 && aspectRatio < 1.5 ? "cover" : "contain")
  },
  imageWrapper: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    backgroundColor: theme.palette.common.white,
    outline: "1px solid rgba(0, 0, 0, 0.15)",
    outlineOffset: "-1px",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    boxSizing: "border-box"
  },
  emptyState: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50]
  }
}));

export interface ImageProps extends BoxProps {
  alt: string;
  emptyState?: ReactNode;
  fallbackIcon?: ReactNode;
  fit?: Property.ObjectFit;
  image?: ImageType;
  onDelete?: () => void;
  sizes?: string;
}

const Image: FunctionComponent<ImageProps> = ({
  alt,
  className,
  emptyState,
  fallbackIcon = <CameraIcon color="disabled" />,
  fit,
  image,
  onClick,
  onDelete,
  sizes,
  sx,
  ...rest
}) => {
  const firstAsset = image?.assets[0];
  const aspectRatio = (firstAsset?.width ?? 1) / (firstAsset?.height ?? 1);
  const { classes, cx } = useStyles({ fit, aspectRatio });
  const { formatter } = useMessageFormatter();

  // List out all available images
  const srcSet = useMemo(() => {
    return image ? image.assets.map(asset => `${asset.url} ${asset.width}w`).join(", ") : undefined;
  }, [image]);

  return image ? (
    <Box className={cx(classes.imageWrapper, className)} onClick={onClick} data-testid="image-asset" sx={sx} {...rest}>
      {onDelete && (
        <div className={cx(classes.deleteButtonContainer)}>
          <IconButton
            size="small"
            className={cx(classes.iconBtn)}
            onClick={onDelete}
            aria-label={formatter.format(strings.DELETE_LABEL)}
          >
            <SmallRemoveIcon />
          </IconButton>
        </div>
      )}
      <img
        src={image.assets[0]?.url}
        alt={alt}
        loading="lazy"
        srcSet={srcSet}
        sizes={sizes}
        className={cx(classes.image)}
      />
    </Box>
  ) : (
    (emptyState ?? (
      <Box
        onClick={onClick}
        className={cx(classes.imageWrapper, classes.emptyState, className)}
        sx={sx}
        data-testid="image-asset-empty-state"
      >
        {fallbackIcon}
      </Box>
    ))
  );
};

export default Image;
