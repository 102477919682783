import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { JSX, ReactNode, useEffect, useMemo } from "react";

import Auth0Provider from "auth/Auth0Provider";
import ErrorBoundary from "components/ErrorBoundary";
import { FabProvider } from "components/Fab";
import useLocale, { dayjsLocales } from "i18n/hooks/useLocale";
import MessageFormatterProvider from "i18n/MessageFormatterProvider";
import SoftwareKeyboardDetector from "mobile/SoftwareKeyboardDetector";
import FallbackRouteProvider from "routes/FallbackRouteProvider";
import ThemeProviderV5 from "theme/ThemeProvider";
import dayjs from "utils/dayjs";

interface AppProvidersProps {
  children: ReactNode;
}

/**
 * All of the providers that we've written or that rely on our specific app
 * customizations to work.
 */
export default function AppProviders({ children }: AppProvidersProps): JSX.Element {
  const locale = useLocale();

  const dayjsLocale = useMemo(() => {
    // If the locale isn't supported yet, default to en
    let newLocale = locale.toLowerCase();
    if (!dayjsLocales.includes(newLocale)) {
      newLocale = "en";
    }
    return newLocale;
  }, [locale]);

  useEffect(() => {
    dayjs.locale(dayjsLocale);
  }, [dayjsLocale]);

  return (
    <Auth0Provider>
      <ThemeProviderV5>
        <MessageFormatterProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
            <ErrorBoundary>
              <FallbackRouteProvider>
                <SoftwareKeyboardDetector>
                  <FabProvider>{children}</FabProvider>
                </SoftwareKeyboardDetector>
              </FallbackRouteProvider>
            </ErrorBoundary>
          </LocalizationProvider>
        </MessageFormatterProvider>
      </ThemeProviderV5>
    </Auth0Provider>
  );
}
