import { Typography } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Formik } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SimpleHeader from "components/Header/SimpleHeader";
import Modal from "components/Modal";
import Page from "components/Page";
import ConnectProviderContactModal from "features/suppliers/connect-provider-contact/ConnectProviderContactModal";
import useMessages from "i18n/hooks/useMessages";
import NavigationPrompt from "routes/NavigationPrompt";
import { SupplierInboxMessage, SupplierOrdersGridLayout, SupplierViewOrder } from "routes/Routes";
import { ValidationErrorCodes } from "types/api";
import { PrivateFile } from "types/api/generated/supplier";
import dayjs from "utils/dayjs";
import { isApiValidationProblemWithErrorCode } from "utils/Errors";
import useModal from "utils/hooks/useModal";
import useSnackbar from "utils/hooks/useSnackbar";
import useTenancyId from "utils/hooks/useTenancyId";

import InboxMessageFileViewer from "../components/InboxMessageFileViewer/InboxMessageFileViewer";
import useCreateMailboxOrder from "../queries/useCreateMailboxOrder";
import useFetchInboxMessage from "../queries/useFetchInboxMessage";
import { InboxMessageOrderFormValues } from "../types/InboxMessageOrderFormValues";
import { resolveFiles } from "../utilities/files";

import strings from "./InboxMessageOrder.strings.json";
import InboxMessageOrderForm from "./InboxMessageOrderForm";

const useStyles = makeStyles()(_ => ({
  container: {
    display: "flex"
  }
}));

export const initialInboxMessageFormValues: InboxMessageOrderFormValues = {
  deliveryAddress: {},
  deliveryDateUtc: dayjs().add(1, "day"),
  referenceNumber: "",
  orderLines: [],
  invalidOrderLines: []
};

const InboxMessageOrder: FunctionComponent = () => {
  const history = useHistory();
  const messages = useMessages(strings);
  const tenancyId = useTenancyId();
  const { classes } = useStyles();
  const { formatter } = useMessageFormatter();
  const { messageId } = useParams<typeof SupplierInboxMessage.params>();
  const { showSnackbar } = useSnackbar();

  const [files, setFiles] = useState<PrivateFile[]>([]);

  const { data: message, isLoading: isLoadingMessage } = useFetchInboxMessage({ externalId: messageId });
  const { mutate: createOrder, isLoading: isCreatingOrder } = useCreateMailboxOrder({ inboxMessageId: messageId });

  const [isIncompleteOrderModalOpen, openIncompleteOrderModal, closeIncompleteOrderModal] = useModal();
  const [isProviderContactModalOpen, openProviderContactModal, closeProviderContactModal] = useModal();

  useEffect(() => {
    if (message) {
      setFiles(resolveFiles(message));
    }
  }, [message]);

  const onCreateOrder = (values: InboxMessageOrderFormValues): void => {
    if (values.invalidOrderLines.length > 0) {
      openIncompleteOrderModal();
      return;
    }

    if (values.buyer === undefined || message === undefined) {
      return;
    }

    createOrder(
      {
        buyerId: values.buyer.buyerId,
        deliveryDateUtc: values.deliveryDateUtc.toISOString(),
        address: values.deliveryAddress,
        referenceNumber: values.referenceNumber,
        inboxMessageId: message.externalId,
        notes: values.notes,
        orderLines: values.orderLines.map(line => ({
          productId: line.productId,
          quantity: line.quantity,
          customUnitAmount: line.unitAmount?.amount
        })),
        files
      },
      {
        onSuccess: order => {
          history.push(SupplierOrdersGridLayout.toUrl({ tenancyId }, { savedFilter: "inbox" }), {
            bypassNavigationBlock: true
          });
          showSnackbar(
            <FormattedMessage id={messages.CREATE_ORDER_SUCCESS} values={{ tradingName: order.buyerName }} />,
            {
              action: (
                <Button
                  color="inherit"
                  size="small"
                  to={SupplierViewOrder.toUrl({ tenancyId, orderId: order.orderId.toString() })}
                >
                  <FormattedMessage id={messages.CREATE_ORDER_SUCCESS_ACTION} />
                </Button>
              )
            }
          );
        },
        onError: error => {
          if (isApiValidationProblemWithErrorCode(error.body, ValidationErrorCodes.InvalidProviderContact)) {
            openProviderContactModal();
          }
        }
      }
    );
  };

  const onDeleteFile = (file: PrivateFile): void => {
    setFiles(existing => existing.filter(prevFile => prevFile.id !== file.id));
  };

  return (
    <Page
      containerClassName={classes.container}
      header={<SimpleHeader title={formatter.format(messages.TITLE)} />}
      showContainer={false}
      isLoading={isLoadingMessage}
    >
      <InboxMessageFileViewer files={files} onDeleteFile={onDeleteFile} externalId={messageId} />
      {message && (
        <Formik initialValues={initialInboxMessageFormValues} onSubmit={onCreateOrder} enableReinitialize>
          {options => (
            <>
              <InboxMessageOrderForm {...options} message={message} isSubmitting={isCreatingOrder} />
              <Modal
                open={isIncompleteOrderModalOpen}
                onClose={closeIncompleteOrderModal}
                title={messages.INCOMPLETE_ORDER_MODAL_TITLE}
                maxWidth="xs"
                actions={
                  <Button color="primary" variant="contained" onClick={closeIncompleteOrderModal}>
                    <FormattedMessage id={messages.INCOMPLETE_ORDER_MODAL_CONTINUE_BUTTON} />
                  </Button>
                }
              >
                <Typography>
                  <FormattedMessage id={messages.INCOMPLETE_ORDER_MODAL_REASON_UNMATCHED_LINES} />
                </Typography>
              </Modal>
              {options.values.buyer && (
                <ConnectProviderContactModal
                  open={isProviderContactModalOpen}
                  buyerId={options.values.buyer.buyerId}
                  companyName={options.values.buyer.tradingName}
                  onClose={closeProviderContactModal}
                  onContinue={() => {
                    closeProviderContactModal();
                    options.handleSubmit();
                  }}
                />
              )}
              <NavigationPrompt enabled={options.dirty}>
                <FormattedMessage id={messages.DISCARD_ORDER_MESSAGE} />
              </NavigationPrompt>
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default InboxMessageOrder;
