import { Typography } from "@mui/material";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { Action, Location, LocationDescriptor, UnregisterCallback } from "history";
import { useState, useEffect, useCallback, VoidFunctionComponent, ReactNode, useRef } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import Modal from "components/Modal";
import { ReferrerLocationState } from "utils/history";

import strings from "./NavigationPrompt.strings.json";

interface HistoryState extends Partial<ReferrerLocationState> {
  bypassNavigationBlock?: boolean;
}

interface NavigationPromptProps {
  /** Whether to show the confirmation modal when the user next tries to navigate somewhere */
  enabled: boolean;
  /** Title of the confirmation modal */
  title?: string;
  /** Text shown in discard button of the confirmation modal */
  discardButtonText?: string;
  /** Text shown in cancel button of the confirmation modal */
  cancelButtonText?: string;
  /** Message shown in the body of the confirmation modal */
  children: NonNullable<ReactNode>;
}

/**
 * When enabled, this component blocks page navigation and shows a modal asking the user to confirm whether
 * they want to leave the current URL.
 *
 * This is mostly useful for asking the user if they want to discard their changes to an unsaved form.
 *
 * The navigation block can be bypassed by passing `{ bypassNavigationBlock: true }` in the history state,
 * which can useful when redirecting after a successful save.
 */
const NavigationPrompt: VoidFunctionComponent<NavigationPromptProps> = props => {
  const { formatter } = useMessageFormatter();
  const {
    enabled,
    title = formatter.format(strings.DEFAULT_TITLE),
    discardButtonText = formatter.format(strings.DEFAULT_DISCARD_BUTTON_TEXT),
    cancelButtonText = formatter.format(strings.DEFAULT_CANCEL_BUTTON_TEXT),
    children
  } = props;
  const history = useHistory<HistoryState | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const targetLocationRef = useRef<Location<HistoryState | undefined>>();
  const targetActionRef = useRef<Action>();
  const unblockRef = useRef<UnregisterCallback>();

  useEffect(() => {
    if (enabled) {
      unblockRef.current = history.block((location, action) => {
        // Allow the navigation block to be bypassed via history state, but not for pop events (back button)
        // This is useful for when redirecting after a successful save for example
        if (location.state?.bypassNavigationBlock && action !== "POP") {
          return;
        }

        targetLocationRef.current = location;
        targetActionRef.current = action;
        setIsModalOpen(true);
        return false;
      });
    }

    return () => {
      // Automatically unblock navigation when the component is unmounted or `enabled` changes
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [history, enabled]);

  const handleDiscard = useCallback(() => {
    // Unblock navigation and navigate to the location the user was trying to get to
    if (unblockRef.current) {
      unblockRef.current();
    }

    if (targetLocationRef.current) {
      const target: LocationDescriptor<HistoryState | undefined> = {
        pathname: targetLocationRef.current.pathname,
        search: targetLocationRef.current.search,
        hash: targetLocationRef.current.hash,
        state: {
          referrer: targetLocationRef.current.state?.referrer
        }
      };

      if (targetActionRef.current === "POP") {
        history.goBack();
      } else if (targetActionRef.current === "REPLACE") {
        history.replace(target);
      } else {
        history.push(target);
      }
    }
  }, [targetLocationRef, history]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onClose={handleCancel}
      maxWidth="xs"
      actions={
        <>
          <Button color="primary" onClick={handleDiscard}>
            {discardButtonText}
          </Button>
          <Button color="primary" variant="contained" onClick={handleCancel}>
            {cancelButtonText}
          </Button>
        </>
      }
    >
      <Typography>{children}</Typography>
    </Modal>
  );
};

export default NavigationPrompt;
