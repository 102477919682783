import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Directory, DirectoryInternal } from "types/api";
import { ApiValidationProblem } from "types/api/generated/supplier";
import { RequestAction } from "types/redux-helpers";

export interface CompanyState {
  loadingProfile: boolean;
  inviting: boolean;
  isFetchingQrCode: boolean;
}

const initialState: CompanyState = {
  loadingProfile: false,
  inviting: false,
  isFetchingQrCode: false
};

export type FetchCompanyProfileByIdRequest = RequestAction<
  Directory.V1CompaniesProfileDetail.RequestParams,
  Directory.V1CompaniesProfileDetail.ResponseBody
>;
type FetchCompanyProfileByIdSuccess = PayloadAction<Directory.V1CompaniesProfileDetail.ResponseBody>;

export type CreateCompanyInviteRequest = RequestAction<
  DirectoryInternal.InternalCompanyClaimCreate.RequestParams & DirectoryInternal.InternalCompanyClaimCreate.RequestBody,
  DirectoryInternal.InternalCompanyClaimCreate.ResponseBody,
  ApiValidationProblem
>;
type CreateCompanyInviteSuccess = PayloadAction<DirectoryInternal.InternalCompanyClaimCreate.ResponseBody>;

export type FetchCompanyInviteQrCodeRequest = RequestAction<
  DirectoryInternal.InternalCompanyClaimQrDetail.RequestParams,
  DirectoryInternal.InternalCompanyClaimQrDetail.ResponseBody
>;
type FetchCompanyInviteQrCodeSuccess = PayloadAction<DirectoryInternal.InternalCompanyClaimQrDetail.ResponseBody>;

const snackbarSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    fetchCompanyProfileByIdRequest: (state, _payload: FetchCompanyProfileByIdRequest) => ({
      ...state,
      loadingProfile: true
    }),
    fetchCompanyProfileByIdSuccess: (state, _profile: FetchCompanyProfileByIdSuccess) => ({
      ...state,
      loadingProfile: false
    }),
    fetchCompanyProfileByIdFailure: (state, _payload) => ({
      ...state,
      loadingProfile: false
    }),
    createCompanyInviteRequest: (state, _payload: CreateCompanyInviteRequest) => ({
      ...state,
      inviting: true
    }),
    createCompanyInviteSuccess: (state, _payload: CreateCompanyInviteSuccess) => ({
      ...state,
      inviting: false
    }),
    createCompanyInviteFailure: (state, _payload) => ({
      ...state,
      inviting: false
    }),
    fetchCompanyInviteQrCodeRequest: (state, _payload: FetchCompanyInviteQrCodeRequest) => ({
      ...state,
      isFetchingQrCode: true
    }),
    fetchCompanyInviteQrCodeSuccess: (state, _payload: FetchCompanyInviteQrCodeSuccess) => ({
      ...state,
      isFetchingQrCode: false
    }),
    fetchCompanyInviteQrCodeFailure: (state, _payload) => ({
      ...state,
      isFetchingQrCode: false
    })
  }
});

export const {
  fetchCompanyProfileByIdRequest,
  fetchCompanyProfileByIdSuccess,
  fetchCompanyProfileByIdFailure,
  createCompanyInviteRequest,
  createCompanyInviteSuccess,
  createCompanyInviteFailure,
  fetchCompanyInviteQrCodeRequest,
  fetchCompanyInviteQrCodeSuccess,
  fetchCompanyInviteQrCodeFailure
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
