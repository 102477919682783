import { Skeleton } from "@mui/material";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius
  },
  row: {
    padding: theme.spacing(2),
    "&:not(:first-child)": {
      borderTop: `1px solid ${theme.palette.grey[300]}`
    }
  }
}));

export interface DataGridSkeletonProps {
  className?: string;
  dataTestId?: string;
}

const DataGridSkeleton: FunctionComponent<DataGridSkeletonProps> = ({
  className,
  dataTestId = "data-grid-skeleton"
}) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)} data-testid={dataTestId}>
      <div className={classes.row}>
        <Skeleton variant="rectangular" sx={{ maxWidth: 160 }} />
      </div>
      <div className={classes.row}>
        <Skeleton variant="rectangular" sx={{ maxWidth: 320 }} />
      </div>
      <div className={classes.row}>
        <Skeleton variant="rectangular" sx={{ maxWidth: 240 }} />
      </div>
    </div>
  );
};

export default DataGridSkeleton;
