import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { inboxQueryKey } from "./keys";

type RequestBody = Supplier.V1MailboxInboxMessagesDetail.RequestBody;
type RequestQuery = Supplier.V1MailboxInboxMessagesDetail.RequestQuery;
export type RequestParamaters = Supplier.V1MailboxInboxMessagesDetail.RequestParams;
export type ResponseBody = Supplier.V1MailboxInboxMessagesDetail.ResponseBody;

export const fetchInboxMessage = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParamaters>(
  it => ({
    method: "GET",
    path: safeurl`/supplier/v1/mailbox/inbox/messages/${it.externalId}`
  })
);

const useFetchInboxMessage = (parameters: RequestParamaters): UseQueryResult<ResponseBody> => {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: inboxQueryKey.detail(parameters).queryKey,
    queryFn: async () => {
      const response = await apiFetch(fetchInboxMessage(parameters));
      return response.data;
    }
  });
};

export default useFetchInboxMessage;
