import { Auth0ContextInterface } from "@auth0/auth0-react";

import createSagaNext from "redux/tasks/createSagaNext";
import { deleteDataFromApiWrapper } from "services/helpers";

import {
  removeConnectionByIdFailure,
  RemoveConnectionByIdRequest,
  removeConnectionByIdSuccess
} from "../reducers/pendingSupplierById";

/**
 * Saga task for removing a supplier lead using the
 * `/buyer/v1/suppliers/pending/leads/${requestId}` API endpoint.
 */
export default createSagaNext<RemoveConnectionByIdRequest>(
  (auth: Auth0ContextInterface, tenancyId, { requestId }) => {
    return deleteDataFromApiWrapper(
      auth,
      `${process.env.REACT_APP_API_URL}/buyer/v1/suppliers/pending/leads/${requestId}`,
      tenancyId
    );
  },
  {
    successAction: removeConnectionByIdSuccess,
    failureAction: removeConnectionByIdFailure
  }
);
