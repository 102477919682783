import { BuyerOrderLine } from "features/buyers/orders/new-order/reducers/types";
import { InvalidOrderLine, OrderLine, OrderTaxDetails } from "types/api/generated/supplier";
import { LocalOrderLine } from "types/Orders";

type MixedOrderLine = BuyerOrderLine | OrderLine | LocalOrderLine | InvalidOrderLine;

export default function getOrderTotals(orderLines: MixedOrderLine[], tax?: OrderTaxDetails | null): [number, number] {
  const subtotal = orderLines.reduce((total, lineItem) => total + (lineItem.lineAmount?.amount ?? 0), 0);
  const total = subtotal && tax ? subtotal + tax.totalTaxAmount.amount : subtotal;

  return [total, subtotal];
}
