import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Header, { HeaderProps } from "components/Header";
import useMessages from "i18n/hooks/useMessages";
import { MobileWelcome } from "routes/Routes";
import useAppSelector from "utils/hooks/useAppSelector";
import { PARAM_FORWARD_PATH } from "utils/MobileUrlSearchParams";

import strings from "./strings.json";

interface StyleProps extends Pick<SkeletonHeaderProps, "backgroundColor"> {}

const useStyles = makeStyles<StyleProps>()((theme, { backgroundColor }) => ({
  toolbar: {
    backgroundColor: backgroundColor === "white" ? theme.palette.common.white : theme.palette.primary.main,
    boxShadow: backgroundColor === "white" ? "none" : "inherit"
  },
  button: {
    color:
      backgroundColor === "white"
        ? theme.palette.primary.main
        : theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(1),
    left: theme.spacing(-1)
  },
  title: {
    fontWeight: 700,
    marginLeft: theme.spacing(1)
  },
  helpIcon: {
    marginRight: theme.spacing(-1.5),
    color:
      backgroundColor === "white"
        ? theme.palette.primary.main
        : theme.palette.getContrastText(theme.palette.primary.main)
  }
}));

export interface SkeletonHeaderProps extends HeaderProps {
  backgroundColor?: "white" | "primary";
  ctaClassName?: string;
  includeCtaAction?: boolean;
  postAuthLocation?: string;
}

/**
 * A basic header made to blend the header actions into the current page
 * background.  Used mainly for pages outside of the company ones.
 */
const SkeletonHeader: FunctionComponent<SkeletonHeaderProps> = ({
  backgroundColor = "primary",
  ctaClassName,
  includeCtaAction = true,
  postAuthLocation = "/",
  ...other
}) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles({ backgroundColor });
  const history = useHistory();
  const { isAuthenticated, logout } = useAuth0();

  /**
   * We need access to the current company to avoid an endless redirect back to the current home.
   * Without access to the current company, we cannot find out other companies they have may
   * access to for security reasons.
   */

  const belongsToACompanyAndHasAccess = useAppSelector(state => !!state.tenancy.companies.length);

  const getCTAText = (): string => {
    if (isAuthenticated) {
      if (belongsToACompanyAndHasAccess) {
        return messages.HOME;
      }
      return messages.LOGOUT;
    }
    return messages.LOGIN;
  };

  const handleButtonClick = (): void => {
    if (isAuthenticated) {
      if (belongsToACompanyAndHasAccess) {
        history.push(postAuthLocation);
        return;
      }
      logout();
      return;
    }

    if (Capacitor.isNativePlatform()) {
      history.push(`${MobileWelcome.toUrl()}?${PARAM_FORWARD_PATH}=${postAuthLocation}&auth0ScreenHint=login`);
    } else {
      history.push(postAuthLocation);
    }
  };

  return (
    <Header
      className={cx(classes.toolbar)}
      helpIconClassName={cx(classes.helpIcon)}
      LeftComp={
        includeCtaAction ? (
          <Button
            className={cx(classes.button, ctaClassName)}
            onClick={handleButtonClick}
            data-testid="skeletonHeader-actionBtn"
          >
            <FormattedMessage id={getCTAText()} />
          </Button>
        ) : null
      }
      data-testid="skeletonheader"
      {...other}
    />
  );
};

export default SkeletonHeader;
