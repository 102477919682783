import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import PermissionTooltip from "auth/PermissionTooltip";
import useHasPermission from "auth/useHasPermission";
import DataGridSkeleton from "components/DataGridSkeleton";
import EmptyStateBox from "components/EmptyState/EmptyStateBox";
import Fab from "components/Fab";
import SimpleHeader from "components/Header/SimpleHeader";
import Page from "components/Page";
import Tooltip from "components/Tooltip";
import ChooseProductsModal from "features/suppliers/directory/views/buyer-profile/ChooseProductsModal";
import useMessages from "i18n/hooks/useMessages";
import ProductsIcon from "media/products_icon.svg?react";
import { SupplierProduct } from "routes/Routes";
import { Permission } from "types/api";
import { SupplierProductSummary } from "types/api/generated/supplier";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";
import useModal from "utils/hooks/useModal";
import useSnackbar from "utils/hooks/useSnackbar";
import useTenancyId from "utils/hooks/useTenancyId";

import { useAddQuickAddProduct } from "../queries/useAddQuickAddProduct";
import useQuickAddProducts from "../queries/useQuickAddProducts";
import { useRemoveQuickAddProduct } from "../queries/useRemoveQuickAddProduct";

import strings from "./QuickAddProductsPage.strings.json";

export const QUICK_ADD_PRODUCTS_LIMIT = 10;

const useStyles = makeStyles()(theme => ({
  header: {
    marginBottom: theme.spacing(4)
  },
  headerTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  description: {
    marginTop: theme.spacing(1)
  }
}));

const QuickAddProductsPage: FunctionComponent = () => {
  const { classes } = useStyles();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const history = useHistory();
  const tenancyId = useTenancyId();
  const canEditQuickAddProducts = useHasPermission([
    Permission.CreateQuickAddProducts,
    Permission.RemoveQuickAddProducts
  ]);
  const { showSnackbar } = useSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const [isChooseProductsModalOpen, openChooseProductsModal, closeChooseProductsModal] = useModal();
  const { data, isSuccess: hasLoadedQuickAddProducts } = useQuickAddProducts();
  const { mutate: addQuickAddProduct, isLoading: isAddingQuickAddProduct } = useAddQuickAddProduct();
  const { mutate: removeQuickAddProduct } = useRemoveQuickAddProduct();

  const total = useMemo(() => data?.products.length ?? 0, [data?.products.length]);
  const canAddMoreProducts = total < QUICK_ADD_PRODUCTS_LIMIT;

  const addProduct = (productIds: string[]): void => {
    const productId = productIds[0];
    if (!productId) return;
    addQuickAddProduct(
      { productId },
      {
        onSuccess: () => {
          closeChooseProductsModal();
          showSnackbar(<FormattedMessage id={messages.ADD_PRODUCT_SUCCESS} />);
        },
        onError: error => {
          showErrorSnackbar(error, <FormattedMessage id={messages.ADD_PRODUCT_FAILURE} />);
        }
      }
    );
  };

  const removeProduct = (productId: string): void => {
    removeQuickAddProduct(
      { productId },
      {
        onSuccess: () => {
          showSnackbar(<FormattedMessage id={messages.REMOVE_PRODUCT_SUCCESS} />);
        },
        onError: error => {
          showErrorSnackbar(error, <FormattedMessage id={messages.REMOVE_PRODUCT_FAILURE} />);
        }
      }
    );
  };

  const handleRowClick = (product: SupplierProductSummary): void => {
    history.push(SupplierProduct.toUrl({ tenancyId, productId: product.productId }));
  };

  return (
    <Page header={<SimpleHeader title={formatter.format(messages.TITLE)} />}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <Typography variant="h1">
            <FormattedMessage id={messages.TITLE} />
          </Typography>

          <PermissionTooltip hasPermission={canEditQuickAddProducts}>
            <Tooltip
              title={formatter.format(messages.LIMIT_TOOLTIP)}
              disabled={!canEditQuickAddProducts || !hasLoadedQuickAddProducts || canAddMoreProducts}
              data-testid="add-product-button-tooltip"
            >
              <Fab
                disabled={!canEditQuickAddProducts || !hasLoadedQuickAddProducts || !canAddMoreProducts}
                onClick={openChooseProductsModal}
              >
                <FormattedMessage id={messages.ADD_PRODUCT_BUTTON} />
              </Fab>
            </Tooltip>
          </PermissionTooltip>
        </div>

        {total > 0 && (
          <Typography className={classes.description} color="textSecondary">
            <FormattedMessage id={messages.DESCRIPTION} values={{ helpLink: { articleId: 5321947 } }} />
          </Typography>
        )}
      </div>

      {!hasLoadedQuickAddProducts ? (
        <DataGridSkeleton />
      ) : total === 0 ? (
        <EmptyStateBox icon={<ProductsIcon />}>
          <Typography paragraph>
            <FormattedMessage id={messages.EMPTY_STATE_1} />
          </Typography>
          <Typography>
            <FormattedMessage id={messages.EMPTY_STATE_2} />
          </Typography>
        </EmptyStateBox>
      ) : (
        <DataGrid
          rows={data.products.map(product => ({ ...product, id: product.productId }))}
          hideFooter
          sortingMode="client"
          disableRowSelectionOnClick
          onRowClick={params => handleRowClick(params.row)}
          columns={[
            {
              field: "name",
              headerName: formatter.format(messages.COLUMN_NAME),
              maxWidth: 300,
              flex: 1
            },
            {
              field: "code",
              headerName: formatter.format(messages.COLUMN_CODE),
              flex: 1
            },
            {
              field: "remove",
              sortable: false,
              headerName: "",
              width: 70,
              renderCell: params => {
                return canEditQuickAddProducts ? (
                  <IconButton
                    aria-label={formatter.format(messages.REMOVE_PRODUCT_BUTTON)}
                    onClick={e => {
                      e.stopPropagation();
                      removeProduct(params.row.productId);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null;
              }
            }
          ]}
        />
      )}

      <ChooseProductsModal
        singleSelection
        open={isChooseProductsModalOpen}
        onClose={closeChooseProductsModal}
        onSave={addProduct}
        isSaving={isAddingQuickAddProduct}
      />
    </Page>
  );
};

export default QuickAddProductsPage;
