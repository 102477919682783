/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AddressType {
  Physical = "Physical"
}

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface Attachment {
  name: string;
  file: File;
}

export enum BuyerCategory {
  GrocerySupermarket = "GrocerySupermarket",
  Cafe = "Cafe",
  Restaurant = "Restaurant",
  Bar = "Bar",
  Accommodation = "Accommodation",
  AgedCare = "AgedCare",
  Bakery = "Bakery",
  BeautySalonSpa = "BeautySalonSpa",
  Butcher = "Butcher",
  Catering = "Catering",
  ChildCare = "ChildCare",
  Club = "Club",
  ConvenienceStore = "ConvenienceStore",
  Distributor = "Distributor",
  Florist = "Florist",
  FoodCoffeeTruck = "FoodCoffeeTruck",
  GiftBoxService = "GiftBoxService",
  GymFitness = "GymFitness",
  HairSalon = "HairSalon",
  LiquorStore = "LiquorStore",
  MedicalHealthCare = "MedicalHealthCare",
  Office = "Office",
  PetStore = "PetStore",
  PetrolStation = "PetrolStation",
  Pharmacy = "Pharmacy",
  RetailStore = "RetailStore",
  SchoolingEducation = "SchoolingEducation",
  WholesaleSupplier = "WholesaleSupplier"
}

export interface CompanyAddress {
  externalId: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  type: AddressType;
  isDefault: boolean;
  country: Country;
  full: string;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface CompanyProfile {
  companyId: string;
  tradingName: string;
  legalBusinessName?: string | null;
  website?: string | null;
  shortDescription?: string | null;
  description?: string | null;
  phone?: string | null;
  email?: string | null;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  logoUrl?: string | null;
  profileImages?: ProfileImages | null;
  directoryTags?: DirectoryTags | null;
  brandPrimaryColour?: string | null;
  isVisibleInDirectoryAsSupplier: boolean;
  /** @deprecated */
  isVisibleInDirectoryAsBuyer: boolean;
  isBuyer: boolean;
  buyerStatus: CompanyTypeStatus;
  isSupplier: boolean;
  supplierStatus: CompanyTypeStatus;
  isClaimed: boolean;
  countryExtensions?: CountryExtensionsApi | null;
  country: Country;
  timezone: string;
  attachments?: Attachment[] | null;
}

export enum CompanyTypeStatus {
  None = "None",
  Onboarding = "Onboarding",
  Active = "Active",
  Inactive = "Inactive"
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CountryExtensionsApi {
  australianBusinessNumber?: string | null;
  newZealandBusinessNumber?: string | null;
  newZealandGstNumber?: string | null;
}

export interface CreateBuyerInviteCommand {
  buyerId: string;
}

export interface CreateInviteCommand {
  invitedEmail: string;
  roles: Role[];
}

export interface CreateOrUpdateCompanyAddressRequest {
  isDefault?: boolean | null;
  type?: AddressType | null;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  countryCode?: Alpha2Code | null;
  /** @format double */
  longitude?: number | null;
  /** @format double */
  latitude?: number | null;
  notes?: string | null;
}

export type DeleteCompanyAddressCommand = object;

export interface DirectoryTags {
  tags: Tag[];
  buyerCategories: BuyerCategory[];
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export interface File {
  id: string;
  originalName: string;
  url: string;
  contentType?: string | null;
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface ImageSummary {
  id: string;
  urls: ProfileImageAsset[];
  orientation: Orientation;
}

export interface InviteFromClaimTokenResult {
  invitedEmail: string;
}

export enum InviteSource {
  AdminRequest = "AdminRequest",
  UserManagement = "UserManagement",
  Marketing = "Marketing",
  SignupProcess = "SignupProcess",
  Supplier = "Supplier",
  ClaimToken = "ClaimToken"
}

export enum Orientation {
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square"
}

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface ProfileImageAsset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  resolution: Resolution;
}

export interface ProfileImages {
  imageSummaries: ImageSummary[];
}

export interface RedeemClaimTokenCommand {
  /** @format uuid */
  token: string;
}

export enum Resolution {
  OneX = "OneX",
  TwoX = "TwoX"
}

export enum Role {
  Owner = "Owner",
  Full = "Full",
  LimitedBuyer = "LimitedBuyer"
}

export enum Tag {
  Alcohol = "Alcohol",
  BakedGoods = "BakedGoods",
  CheeseDairy = "CheeseDairy",
  CoffeeTea = "CoffeeTea",
  Condiments = "Condiments",
  Drinks = "Drinks",
  MeatVeg = "MeatVeg",
  Packaging = "Packaging",
  PersonalCare = "PersonalCare",
  DryGoods = "DryGoods",
  Vegan = "Vegan",
  Beer = "Beer",
  Bread = "Bread",
  CertifiedOrganic = "CertifiedOrganic",
  Chocolate = "Chocolate",
  Coffee = "Coffee",
  Confectionary = "Confectionary",
  CookingOil = "CookingOil",
  Dessert = "Dessert",
  Eggs = "Eggs",
  Equipment = "Equipment",
  FreeRange = "FreeRange",
  FrozenFoods = "FrozenFoods",
  Fruit = "Fruit",
  GlutenFree = "GlutenFree",
  Honey = "Honey",
  HotSauce = "HotSauce",
  Juice = "Juice",
  Meat = "Meat",
  Milk = "Milk",
  PlantMilk = "PlantMilk",
  Produce = "Produce",
  Seafood = "Seafood",
  Snacks = "Snacks",
  Spirits = "Spirits",
  SugarFree = "SugarFree",
  Tea = "Tea",
  Wine = "Wine",
  Cleaning = "Cleaning",
  Supplements = "Supplements",
  Pets = "Pets",
  Homewares = "Homewares",
  Kitchenware = "Kitchenware",
  BCorp = "BCorp",
  AlcoholFree = "AlcoholFree",
  SpicesSeasonings = "SpicesSeasonings",
  ReadyMeals = "ReadyMeals",
  FirstNations = "FirstNations"
}

export interface UpdateCompanyProfileRequest {
  /** @maxLength 100 */
  tradingName?: string | null;
  /** @maxLength 100 */
  legalBusinessName?: string | null;
  /**
   * @format email
   * @maxLength 100
   */
  email?: string | null;
  /** @maxLength 1000 */
  description?: string | null;
  /** @maxLength 250 */
  shortDescription?: string | null;
  /** @maxLength 100 */
  website?: string | null;
  /** @maxLength 100 */
  phone?: string | null;
  isVisibleInDirectoryAsSupplier: boolean;
  directoryTags?: DirectoryTags | null;
  countryExtensions?: CountryExtensionsApi | null;
  profileImages?: ProfileImages | null;
  logoUrl?: string | null;
  brandPrimaryColour?: string | null;
  attachments?: Attachment[] | null;
}

export interface UpdateInviteCommand {
  roles: Role[];
}

export interface UserInviteSummary {
  inviteId: string;
  email: string;
  roles: Role[];
  isBounced: boolean;
  inviteUrl?: string | null;
}

export interface UserInviteSummaryPagedSet {
  links: PageLinks;
  data: UserInviteSummary[];
  /** @format int32 */
  total?: number | null;
}

export namespace Directory {
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesProfileDetail
   * @request GET:/directory/v1/companies/{id}/profile
   * @response `200` `CompanyProfile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1CompaniesProfileDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyProfile;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesProfileList
   * @summary  (Auth Permissions: ViewCompanyProfile)
   * @request GET:/directory/v1/companies/profile
   * @secure
   * @response `200` `CompanyProfile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesProfileList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyProfile;
  }
  /**
   * @description This endpoint acts more like a `PATCH` in that only non-null values present in the request will update the profile.
   * @tags Companies
   * @name V1CompaniesProfileUpdate
   * @summary Updates a company's profile. (Auth Permissions: UpdateCompanyProfile)
   * @request PUT:/directory/v1/companies/profile
   * @secure
   * @response `200` `CompanyProfile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesProfileUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCompanyProfileRequest;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyProfile;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesAddressesCreate
   * @summary Create a (structured) address for a company. (Auth Permissions: UpdateCompanyProfile)
   * @request POST:/directory/v1/companies/addresses
   * @secure
   * @response `200` `CompanyAddress` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesAddressesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateCompanyAddressRequest;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyAddress;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesAddressesUpdate
   * @summary Update a company's (structured) address. (Auth Permissions: UpdateCompanyProfile)
   * @request PUT:/directory/v1/companies/addresses/{addressId}
   * @secure
   * @response `200` `CompanyAddress` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesAddressesUpdate {
    export type RequestParams = {
      addressId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateCompanyAddressRequest;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyAddress;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesAddressesDelete
   * @summary  (Auth Permissions: UpdateCompanyProfile)
   * @request DELETE:/directory/v1/companies/addresses/{id}
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesAddressesDelete {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = DeleteCompanyAddressCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesProfileImagesCreate
   * @summary  (Auth Permissions: UpdateCompanyProfile)
   * @request POST:/directory/v1/companies/profile/images
   * @secure
   * @response `200` `Image` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesProfileImagesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      image?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Image;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesProfileLogoCreate
   * @summary  (Auth Permissions: UpdateCompanyProfile)
   * @request POST:/directory/v1/companies/profile/logo
   * @secure
   * @response `200` `Image` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1CompaniesProfileLogoCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      /** @format binary */
      image?: File;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Image;
  }
  /**
   * No description
   * @tags Companies
   * @name V1CompaniesInviteCreate
   * @request POST:/directory/v1/companies/{id}/invite
   * @deprecated
   * @response `200` `InviteFromClaimTokenResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace V1CompaniesInviteCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RedeemClaimTokenCommand;
    export type RequestHeaders = {};
    export type ResponseBody = InviteFromClaimTokenResult;
  }
  /**
   * @description And invite email will be sent to the email address in the request. If the email already exists for another invite or user in the company, then a validation error is returned.
   * @tags Invites
   * @name V1InvitesCreate
   * @summary Create a new invite in the company for the given roles. (Auth Permissions: CreateUserInvite)
   * @request POST:/directory/v1/invites
   * @secure
   * @response `200` `UserInviteSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInviteCommand;
    export type RequestHeaders = {};
    export type ResponseBody = UserInviteSummary;
  }
  /**
   * No description
   * @tags Invites
   * @name V1InvitesList
   * @summary Returns a paged set of all pending user invites. (Auth Permissions: ViewUserInvites)
   * @request GET:/directory/v1/invites
   * @secure
   * @response `200` `UserInviteSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserInviteSummaryPagedSet;
  }
  /**
   * @description Deleting an invite effectively cancels/expires the invite (anybody trying to follow the link from the invite email will get the Invite Expired page).
   * @tags Invites
   * @name V1InvitesDelete
   * @summary Deletes a user invite in the current company. (Auth Permissions: DeleteUserInvite)
   * @request DELETE:/directory/v1/invites/{inviteId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesDelete {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Invites
   * @name V1InvitesDetail
   * @summary Retrieve the details of a single pending user invite. (Auth Permissions: ViewUserInvites)
   * @request GET:/directory/v1/invites/{inviteId}
   * @secure
   * @response `200` `UserInviteSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1InvitesDetail {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserInviteSummary;
  }
  /**
   * No description
   * @tags Invites
   * @name V1InvitesCreate2
   * @summary Updates the roles attached to a pending user invite. (Auth Permissions: UpdateUserInvite)
   * @request POST:/directory/v1/invites/{inviteId}
   * @originalName v1InvitesCreate
   * @duplicate
   * @secure
   * @response `200` `UserInviteSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesCreate2 {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateInviteCommand;
    export type RequestHeaders = {};
    export type ResponseBody = UserInviteSummary;
  }
  /**
   * No description
   * @tags Invites
   * @name V1InvitesResendCreate
   * @summary Resends the pending user invite with the given invite ID. (Auth Permissions: CreateUserInvite)
   * @request POST:/directory/v1/invites/{inviteId}/resend
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesResendCreate {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description An invite email will be sent to the email address associated with the current pending link between a buyer and supplier
   * @tags Invites
   * @name V1InvitesBuyerCreate
   * @summary Invite an unclaimed buyer to join Upstock (invite is from a supplier) (Auth Permissions: CreateCompanyInvite)
   * @request POST:/directory/v1/invites/buyer
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1InvitesBuyerCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateBuyerInviteCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}
