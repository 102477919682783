import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Supplier } from "types/api";
import { PaymentSettingsMetadata } from "types/api/generated/supplier";
import { RequestAction } from "types/redux-helpers";

export type LoadPaymentSettingsMetadataRequest = RequestAction<
  {},
  Supplier.V1SettingsPaymentsMetadataList.ResponseBody
>;
type LoadPaymentSettingsMetadataSuccess = PayloadAction<Supplier.V1SettingsPaymentsMetadataList.ResponseBody>;

export interface PaymentsSettingsMetadataState {
  loadingMetadata: boolean;
  error?: unknown;
  metadata?: PaymentSettingsMetadata;
}

const initialState: PaymentsSettingsMetadataState = {
  loadingMetadata: true
};

const paymentsSettingsMetadataSlice = createSlice({
  name: "productsMetadata",
  initialState,
  reducers: {
    loadPaymentSettingsMetadataRequest: (state, _payload: LoadPaymentSettingsMetadataRequest) => ({
      ...state,
      loadingMetadata: true
    }),
    loadPaymentSettingsMetadataSuccess: (state, { payload }: LoadPaymentSettingsMetadataSuccess) => ({
      ...state,
      loadingMetadata: false,
      metadata: payload
    }),
    loadPaymentSettingsMetadataFailure: (state, { payload: error }) => ({
      ...state,
      loadingMetadata: false,
      error
    })
  }
});

export const {
  loadPaymentSettingsMetadataRequest,
  loadPaymentSettingsMetadataSuccess,
  loadPaymentSettingsMetadataFailure
} = paymentsSettingsMetadataSlice.actions;

export default paymentsSettingsMetadataSlice.reducer;
