import { createQueryKeys } from "@lukemorales/query-key-factory";
import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api/generated/supplier";
import { safeurl } from "utils/urls";

type RequestBody = Supplier.V11ProductsList.RequestBody;
export type RequestQuery = Supplier.V11ProductsList.RequestQuery;
type RequestParamaters = Supplier.V11ProductsList.RequestParams;
export type ResponseBody = Supplier.V11ProductsList.ResponseBody;

export const productsQueryKey = createQueryKeys("products", {
  list: (parameters: RequestQuery) => [parameters],
  listInfinite: (parameters: RequestQuery) => [parameters]
});

export const productsOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParamaters>(_ => ({
  method: "GET",
  path: safeurl`/supplier/v1.1/products`
}));

/**
 * React hook for retrieving a page of supplier products from our API.
 */
export default function useProducts(request: RequestQuery, enabled: boolean = true): UseQueryResult<ResponseBody> {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: productsQueryKey.list(request).queryKey,
    queryFn: async () => {
      const response = await apiFetch(productsOptions({}, { searchParams: request }));
      return response.data;
    },
    enabled
  });
}

export function useProductsInfinite(
  request: RequestQuery,
  enabled: boolean = true
): UseInfiniteQueryResult<ResponseBody> {
  const apiFetch = useApiFetch();

  return useInfiniteQuery({
    enabled,
    queryKey: productsQueryKey.listInfinite(request).queryKey,
    queryFn: async ({ pageParam }) => {
      const response = await apiFetch(
        pageParam ? { ...productsOptions({}), path: pageParam } : productsOptions({}, { searchParams: request })
      );
      return response.data;
    },
    getNextPageParam: lastPage => lastPage.links.next
  });
}
