import FavoriteIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import StoreIcon from "@mui/icons-material/Storefront";
import ListItemText from "@mui/material/ListItemText";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { ReactNode, useCallback } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import ListItemNavLink, { ListItemNavLinkProps } from "components/ListItemNavLink";
import useMessages from "i18n/hooks/useMessages";
import NewsfeedIcon from "media/newsfeed.svg?react";
import OrdersIcon from "media/orders.svg?react";
import ProductsIcon from "media/products_icon.svg?react";
import {
  BuyerFavouritesLists,
  BuyerNewsfeed,
  BuyerOrders,
  BuyerSuppliers,
  CompanyRoot,
  SupplierCustomers,
  SupplierNewsfeed,
  SupplierOrdersGridLayout,
  SupplierViewOrder,
  SupplierProducts
} from "routes/Routes";
import { useAnalytics } from "utils/analytics";
import useAppSelector from "utils/hooks/useAppSelector";

import strings from "./MobileNav.strings.json";

const useStyles = makeStyles()(theme => ({
  root: {
    alignItems: "stretch",
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
    bottom: 0,
    display: "flex",
    height: "56px",
    justifyItems: "stretch",
    left: 0,
    paddingBottom: "env(safe-area-inset-bottom)",
    position: "fixed",
    right: 0,
    zIndex: theme.zIndex.appBar
  },
  list: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    justifyContent: "stretch",
    listStyle: "none",
    margin: 0,
    padding: 0,
    width: "100%"
  },
  listItem: {
    display: "block",
    height: "56px",
    textAlign: "center"
  },
  listItemRoot: {
    margin: 0
  },
  listItemText: {
    fontSize: "12px"
  },
  active: {
    color: theme.palette.primary.main
  }
}));

interface MobileNavItemProps extends Pick<ListItemNavLinkProps, "isActive" | "to"> {
  icon: ReactNode;
  text: ReactNode;
  trackingPropLink?: string;
  trackingPropNav?: "buyer" | "supplier";
}

/**
 * Component for each button in the mobile nav.
 */
function MobileNavItem({
  icon,
  isActive,
  text,
  to,
  trackingPropLink,
  trackingPropNav
}: MobileNavItemProps): JSX.Element {
  const { classes, cx } = useStyles();
  const { track } = useAnalytics();

  const trackClick = useCallback(() => {
    if (trackingPropLink) {
      track("Bottom Nav Link Clicked", {
        link: trackingPropLink,
        nav: trackingPropNav
      });
    }
  }, [track, trackingPropLink, trackingPropNav]);

  return (
    <ListItemNavLink
      to={to}
      className={cx(classes.listItem)}
      activeClassName={cx(classes.active)}
      isActive={isActive}
      onClick={trackClick}
    >
      {icon}
      <ListItemText
        primary={text}
        classes={{
          root: cx(classes.listItemRoot),
          primary: cx(classes.listItemText)
        }}
      />
    </ListItemNavLink>
  );
}

/**
 * Nav item fixed to the bottom of a mobile phone screen so that certain items
 * are in easier reach.
 */
export default function MobileNav(): JSX.Element {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const { isSupplierNavDisplayed } = useAppSelector(state => state.app);

  return (
    <nav className={cx(classes.root)}>
      <ul className={cx(classes.list)}>
        {isSupplierNavDisplayed ? (
          <>
            <MobileNavItem
              to={SupplierOrdersGridLayout.toUrl({ tenancyId })}
              // TODO: have the grid layout live on
              //       the same /orders URL so that we don't need to have this
              //       special matching code.

              isActive={(match, location) => !!match || SupplierViewOrder.matches(location.pathname)}
              icon={<OrdersIcon />}
              text={<FormattedMessage id={messages.ORDERS} />}
              trackingPropLink="Orders"
              trackingPropNav="supplier"
            />
            <MobileNavItem
              to={SupplierNewsfeed.toUrl({ tenancyId })}
              icon={<NewsfeedIcon />}
              text={<FormattedMessage id={messages.NEWSFEED} />}
              trackingPropLink="Newsfeed"
              trackingPropNav="supplier"
            />
            <MobileNavItem
              to={SupplierCustomers.toUrl({ tenancyId })}
              icon={<ShoppingBasketOutlinedIcon />}
              text={<FormattedMessage id={messages.SUPPLIER_CUSTOMERS} />}
              trackingPropLink="Customers"
              trackingPropNav="supplier"
            />
            <MobileNavItem
              to={SupplierProducts.toUrl({ tenancyId })}
              icon={<ProductsIcon />}
              text={<FormattedMessage id={messages.SUPPLIER_PRODUCTS} />}
              trackingPropLink="Products"
              trackingPropNav="supplier"
            />
          </>
        ) : (
          <>
            <MobileNavItem
              to={BuyerOrders.toUrl({ tenancyId })}
              icon={<OrdersIcon />}
              text={<FormattedMessage id={messages.ORDERS} />}
              trackingPropLink="Orders"
              trackingPropNav="buyer"
            />
            <MobileNavItem
              to={BuyerNewsfeed.toUrl({ tenancyId })}
              icon={<NewsfeedIcon />}
              text={<FormattedMessage id={messages.NEWSFEED} />}
              trackingPropLink="Newsfeed"
              trackingPropNav="buyer"
            />
            <MobileNavItem
              to={BuyerFavouritesLists.toUrl({ tenancyId })}
              icon={<FavoriteIcon />}
              text={<FormattedMessage id={messages.BUYER_FAVOURITES} />}
              trackingPropLink="Favourites"
              trackingPropNav="buyer"
            />
            <MobileNavItem
              to={BuyerSuppliers.toUrl({ tenancyId })}
              icon={<StoreIcon />}
              text={<FormattedMessage id={messages.BUYER_SUPPLIERS} />}
              trackingPropLink="Suppliers"
              trackingPropNav="buyer"
            />
          </>
        )}
      </ul>
    </nav>
  );
}
