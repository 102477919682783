import HelpIcon from "@mui/icons-material/HelpOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { LocationDescriptor } from "history";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import GlobalNavItem from "components/GlobalNav/GlobalNavItem";
import { clearOrder as supplierClearLastFoundOrder } from "features/suppliers/orders/view-order/reducers/supplierOrderById";
import useMessages from "i18n/hooks/useMessages";
import SettingsIcon from "media/icon-settings.svg?react";
import LogoIcon from "media/logo-32-h.svg?react";
import { showSupplierNavMenu } from "redux/reducers/app";
import { BuyerDashboard, CompanyRoot, Settings, SupplierDashboard } from "routes/Routes";
import useAppSelector from "utils/hooks/useAppSelector";
import useCompanyRoles from "utils/hooks/useCompanyRoles";
import useGlobalNav from "utils/hooks/useGlobalNav";
import useModal from "utils/hooks/useModal";

import BuyerNav from "./BuyerNav";
import CompanySwitcher from "./CompanySwitcher";
import HelpCentreModal from "./HelpCentreModal";
import ProfileMenu from "./ProfileMenu";
import strings from "./strings.json";
import SupplierNav from "./SupplierNav";

/**
 * Our app's navigation menu, shown on the left side of the screen on large
 * screen sizes, or as a drawer that slides out from the left on smaller
 * screens.
 */
const GlobalNav: FunctionComponent = () => {
  const messages = useMessages(strings);
  const history = useHistory();
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isBothSupplierBuyer } = useCompanyRoles();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const { isSupplierNavDisplayed } = useAppSelector(state => state.app);
  const { globalNavVisible, hideGlobalNav } = useGlobalNav();
  const [isHelpCentreModalOpen, openHelpCentreModal, closeHelpCentreModal] = useModal();

  const handleNavClose = useCallback(
    (to?: LocationDescriptor): void => {
      if (pathname === to) {
        window.location.reload();
      }
      if (!isLgUp) {
        hideGlobalNav();
      }
    },
    [hideGlobalNav, pathname, isLgUp]
  );

  const navItemOnClick = useCallback(() => {
    handleNavClose();
  }, [handleNavClose]);

  const handleHelpClick = useCallback(() => {
    openHelpCentreModal();
    handleNavClose();
  }, [handleNavClose, openHelpCentreModal]);

  // Only perform the switch to the other nav after navigation has finished.
  // This is so that we don't prematurely show the other nav in situations where
  // we're blocking the route change, eg: editing business profile, then we
  // click 'Switch to X'.
  useEffect(() => {
    return history.listen((location, _action) => {
      // Going to Supplier view and we're on the buyer nav, or going to Buyer
      // view and we're on the supplier nav.
      if (
        (location.pathname === SupplierDashboard.toUrl({ tenancyId }) && !isSupplierNavDisplayed) ||
        (location.pathname === BuyerDashboard.toUrl({ tenancyId }) && isSupplierNavDisplayed)
      ) {
        dispatch(showSupplierNavMenu(!isSupplierNavDisplayed));
        dispatch(supplierClearLastFoundOrder());
      }
    });
  }, [dispatch, history, isSupplierNavDisplayed, tenancyId]);

  return (
    <>
      <Drawer
        open={globalNavVisible}
        onClose={navItemOnClick}
        variant={isLgUp ? "permanent" : "temporary"}
        data-testid="nav-drawer"
        sx={{
          "& .MuiPaper-root": {
            paddingTop: `env(safe-area-inset-top)`, // fix for mobile devices with statusbar/notch
            paddingBottom: `env(safe-area-inset-bottom)`, // fix for mobile devices with no home button
            boxSizing: "border-box",
            width: "256px",
            "@media (orientation: landscape)": {
              width: "calc(env(safe-area-inset-left) + 256px)",
              paddingLeft: `env(safe-area-inset-left)`
            }
          }
        }}
      >
        <Button
          href={
            tenancyId
              ? isSupplierNavDisplayed
                ? SupplierDashboard.toUrl({ tenancyId })
                : BuyerDashboard.toUrl({ tenancyId })
              : "/"
          }
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            padding: 2,
            width: "100%",
            "&:hover": {
              backgroundColor: theme.palette.grey[200]
            }
          }}
          onClick={navItemOnClick}
          data-testid="upstock-logo"
        >
          <Box sx={{ height: 32 }}>
            <LogoIcon />
          </Box>
        </Button>
        <CompanySwitcher onMenuItemClick={navItemOnClick} />
        <List disablePadding sx={{ flexGrow: 1 }} role="presentation" data-testid="nav-root">
          {isSupplierNavDisplayed ? (
            <SupplierNav handleNavClose={handleNavClose} />
          ) : (
            <BuyerNav handleNavClose={handleNavClose} />
          )}
          <GlobalNavItem
            to={Settings.toUrl({ tenancyId })}
            icon={<SettingsIcon />}
            text={<FormattedMessage id={messages.SETTINGS} />}
            onClick={handleNavClose}
            trackingPropLink="Settings"
          />
          <GlobalNavItem
            icon={<HelpIcon />}
            text={<FormattedMessage id={messages.HELP} />}
            onClick={handleHelpClick}
            data-testid="help-btn"
            trackingPropLink="Help"
          />
          {isBothSupplierBuyer && (
            <GlobalNavItem
              href={
                isSupplierNavDisplayed ? BuyerDashboard.toUrl({ tenancyId }) : SupplierDashboard.toUrl({ tenancyId })
              }
              icon={<SwapHorizIcon />}
              text={<FormattedMessage id={isSupplierNavDisplayed ? messages.SWITCHBUYING : messages.SWITCHSUPPLYING} />}
              data-testid="switch-buying-supplying"
            />
          )}
        </List>
        <ProfileMenu onMenuItemClick={navItemOnClick} />
      </Drawer>
      <HelpCentreModal
        open={isHelpCentreModalOpen}
        showSupplierArticles={isSupplierNavDisplayed}
        onClose={closeHelpCentreModal}
      />
    </>
  );
};

export default GlobalNav;
