import { PermissionState } from "@capacitor/core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DirectoryInternal } from "types/api";
import { RequestAction } from "types/redux-helpers";

export interface PushNotificationState {
  loading: boolean;
  token: string | null;
  permissionStatus: PermissionState | null;
}

const initialState: PushNotificationState = {
  loading: false,
  token: null,
  permissionStatus: null
};

export type RegisterDevicePushNotificationRequest = RequestAction<
  DirectoryInternal.InternalUsersNotificationsPushRegisterCreate.RequestBody,
  DirectoryInternal.InternalUsersNotificationsPushRegisterCreate.ResponseBody
>;
type RegisterDevicePushNotificationSuccess =
  PayloadAction<DirectoryInternal.InternalUsersNotificationsPushRegisterCreate.ResponseBody>;

export type DeregisterDevicePushNotificationRequest = RequestAction<
  DirectoryInternal.InternalUsersNotificationsPushDeregisterDelete.RequestBody,
  DirectoryInternal.InternalUsersNotificationsPushDeregisterDelete.ResponseBody
>;
type DeregisterDevicePushNotificationSuccess =
  PayloadAction<DirectoryInternal.InternalUsersNotificationsPushDeregisterDelete.ResponseBody>;

type UpdatePermissionStatus = PayloadAction<{ status: PermissionState }>;

const pushNotificationsSlice = createSlice({
  name: "pushNotifications",
  initialState,
  reducers: {
    registerDevicePushNotificationRequest: (state, _action: RegisterDevicePushNotificationRequest) => ({
      ...state
    }),
    registerDevicePushNotificationSuccess: (state, { payload }: RegisterDevicePushNotificationSuccess) => ({
      ...state,
      token: payload
    }),
    registerDevicePushNotificationFailure: (state, _payload) => ({
      ...state
    }),
    deregisterDevicePushNotificationRequest: (state, _action: DeregisterDevicePushNotificationRequest) => ({
      ...state
    }),
    deregisterDevicePushNotificationSuccess: (state, _payload: DeregisterDevicePushNotificationSuccess) => ({
      ...state
    }),
    deregisterDevicePushNotificationFailure: (state, _payload) => ({
      ...state
    }),
    updatePermissionStatus: (state, { payload }: UpdatePermissionStatus) => ({
      ...state,
      permissionStatus: payload.status
    })
  }
});

export const {
  registerDevicePushNotificationRequest,
  registerDevicePushNotificationSuccess,
  registerDevicePushNotificationFailure,
  deregisterDevicePushNotificationRequest,
  deregisterDevicePushNotificationSuccess,
  deregisterDevicePushNotificationFailure,
  updatePermissionStatus
} = pushNotificationsSlice.actions;

export default pushNotificationsSlice.reducer;
