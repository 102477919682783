import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api/generated/supplier";
import { buildSearchString } from "utils/urls";

import {
  fetchPriceListProductsFailure,
  FetchPriceListProductsRequest,
  fetchPriceListProductsSuccess
} from "../reducers/priceListProducts";

/**
 * Call the API to retrieve the price list's products with the given ID.
 */
function fetchPriceListProducts(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId?: string,
  limit?: number,
  search?: string,
  sortBy?: string,
  isHidden = false
): Promise<Supplier.V1PricelistsProductsDetail.ResponseBody> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/products${buildSearchString({
      limit,
      search,
      sortBy,
      isHidden
    })}`,
    tenancyId
  );
}

/**
 * Saga to retrieve a price list's products and save it to redux.
 */
export default createSaga(fetchPriceListProducts, {
  prepareApiMethodParams: ({
    priceListId,
    limit,
    search,
    sortBy,
    isHidden
  }: FetchPriceListProductsRequest["payload"]) => [priceListId, limit, search, sortBy, isHidden],
  prepareSuccessActionParams: ({ overrideList }: FetchPriceListProductsRequest["payload"], response) => [
    { ...response, overrideList }
  ],
  successAction: fetchPriceListProductsSuccess,
  errorFailureAction: fetchPriceListProductsFailure
});
