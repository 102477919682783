import CloseIcon from "@mui/icons-material/Close";
import MDialog, { DialogProps as MDialogProps } from "@mui/material/Dialog";
import MDialogActions, { DialogActionsProps as MDialogActionsProps } from "@mui/material/DialogActions";
import MDialogContent, {
  DialogContentProps as MDialogContentProps,
  dialogContentClasses
} from "@mui/material/DialogContent";
import MDialogTitle, { DialogTitleProps as MDialogTitleProps } from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { FunctionComponent, MouseEventHandler } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ fullScreen?: boolean }>()((theme, { fullScreen }) => ({
  dialogPaper: {
    boxSizing: "border-box",
    margin: fullScreen ? 0 : theme.spacing(2),
    borderRadius: fullScreen ? 0 : theme.spacing(3),
    paddingTop: fullScreen ? `env(safe-area-inset-top)` : 0,
    paddingBottom: fullScreen ? `env(safe-area-inset-bottom)` : 0
  },
  dialogPaperFullWidth: {
    width: fullScreen ? "100%" : `calc(100% - ${theme.spacing(4)})`
  },
  dialogPaperWidthFalse: {
    maxWidth: `calc(100% - ${theme.spacing(4)})`
  },
  dialogCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: fullScreen ? `calc(env(safe-area-inset-top) + ${theme.spacing(1)})` : theme.spacing(1),
    zIndex: theme.zIndex.modal,
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  dialogActions: {
    padding: theme.spacing(2)
  },
  dialogTitle: {
    fontWeight: "bold",
    // By default the content doesn't have top padding when the dialog has a title.
    // We're adding some padding back in so that shadows in the content don't get cut off (like with accordions).
    [`&+.${dialogContentClasses.root}`]: {
      paddingTop: "4px !important"
    }
  }
}));

export interface DialogProps extends Omit<MDialogProps, "onClose"> {
  onClose?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * A wrapper around the Material UI `<Dialog>` component to contain some light
 * styling/customizations for our app.
 */
export const Dialog: FunctionComponent<DialogProps> = ({
  children,
  classes: modalClasses,
  fullScreen,
  onClose,
  ...rest
}) => {
  const { classes, cx } = useStyles({ fullScreen });

  return (
    <MDialog
      aria-labelledby="dialog-title"
      fullScreen={fullScreen}
      classes={{
        ...modalClasses,
        paper: cx(classes.dialogPaper, modalClasses?.paper),
        paperFullWidth: cx(classes.dialogPaperFullWidth, modalClasses?.paperFullWidth),
        paperWidthFalse: cx(classes.dialogPaperWidthFalse, modalClasses?.paperWidthFalse)
      }}
      // Disable the open/close transitions when testing to speed up the unit tests
      transitionDuration={process.env.NODE_ENV === "test" ? 0 : rest.transitionDuration}
      onClose={onClose} // allow the dialog to be closed by pressing the escape key or clicking outside the dialog
      {...rest}
    >
      {onClose && (
        <IconButton onClick={onClose} className={cx(classes.dialogCloseButton)} aria-label="close" size="large">
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </MDialog>
  );
};

export interface DialogTitleProps extends MDialogTitleProps {}

/**
 * A wrapper around the Material UI `<DialogTitle>` component to contain some
 * light styling/customizations for our app.
 */
export const DialogTitle: FunctionComponent<DialogTitleProps> = ({ children, className, ...rest }) => {
  const { classes, cx } = useStyles({});

  return (
    <MDialogTitle id="dialog-title" className={cx(classes.dialogTitle, className)} {...rest}>
      {children}
    </MDialogTitle>
  );
};

export type DialogContentProps = MDialogContentProps;

/**
 * A wrapper around the Material UI `<DialogContent>` component to contain some
 * light styling/customizations for our app.
 */
export const DialogContent: FunctionComponent<DialogContentProps> = ({ className, ...rest }) => {
  return <MDialogContent className={className} {...rest} />;
};

export type DialogActionsProps = MDialogActionsProps;

/**
 * A wrapper around the Material UI `<DialogActions>` component to contain some
 * light styling/customizations for our app.
 *
 * Hidden when the mobile keeyboard is visible to maximise space.
 */
export const DialogActions: FunctionComponent<DialogActionsProps> = ({ className, ...rest }) => {
  const { classes, cx } = useStyles({});

  return <MDialogActions className={cx(classes.dialogActions, className)} {...rest} />;
};
