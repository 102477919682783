import { useAuth0 } from "@auth0/auth0-react";
import { JSX, useState } from "react";
import { useMount } from "react-use";

import { startUpAuthenticatedSagas } from "redux/actions";
import store, { sagaMiddleware } from "redux/store";

interface Props {
  children: JSX.Element;
}

/**
 * A component to load the auth0 object into the redux saga context.
 */
export default function Auth0ReduxSagaBridge({ children }: Props): JSX.Element | null {
  const auth0 = useAuth0();
  const [startedSagas, setStartedSagas] = useState(false);

  useMount(() => {
    sagaMiddleware.setContext({
      auth0
    });
    store.dispatch(startUpAuthenticatedSagas());
    setStartedSagas(true);
  });

  return startedSagas ? children : null;
}
