import { datadogLogs, Logger } from "@datadog/browser-logs";

export const isEnabled = process.env.NODE_ENV === "production" && !window.Cypress;

/**
 * A logger object with the same contract as the Datadog logger. These methods
 * will send log entries to the console in development builds and to Datadog in production builds
 * (since Datadog is disabled in development).
 *
 * See: https://docs.datadoghq.com/logs/log_collection/javascript/#usage
 */
export const logger = {
  /* eslint-disable no-console */
  debug(...args: Parameters<Logger["debug"]>): void {
    if (isEnabled) {
      datadogLogs.logger.debug(...args);
    } else {
      console.debug(...args);
    }
  },
  info(...args: Parameters<Logger["info"]>): void {
    if (isEnabled) {
      datadogLogs.logger.info(...args);
    } else {
      console.info(...args);
    }
  },
  warn(...args: Parameters<Logger["warn"]>): void {
    if (isEnabled) {
      datadogLogs.logger.warn(...args);
    } else {
      console.warn(...args);
    }
  },
  error(...args: Parameters<Logger["error"]>): void {
    if (isEnabled) {
      datadogLogs.logger.error(...args);
    } else {
      console.error(...args);
    }
  }
  /* eslint-enable no-console */
};
