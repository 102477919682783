import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addProductToFavouritesListFailure,
  addProductToFavouritesListRequest,
  removeProductFromFavouritesListRequest
} from "features/buyers/favourites/reducers/favouritesList";
import { BuyerInternal } from "types/api";
import { Buyer, BuyerProductSummary, OnAccountOption, PaymentMethodOption } from "types/api/generated/buyer";
import { PaymentRequirement } from "types/api/generated/buyer-internal";
import { RequestAction } from "types/redux-helpers";
import dayjs, { Dayjs } from "utils/dayjs";
import { createDefaultCalculatedDeliverySchedule, getNextAvailableDeliveryDay } from "utils/deliverySchedule";
import { trackError } from "utils/Errors";
import { isNullOrUndefined } from "utils/helpers";
import { localStorageHelpers } from "utils/LocalStorage";

import { currentBuyerCreatedOrderLocalStorageKey } from "../orderInLocalStorage";
import { canUseLocalStorageState } from "../utils/LocalStorage";

import { BuyerOrderLine, NewOrder, NewOrderSettings } from "./types";

export interface BuyerNewOrderState {
  orderLines: BuyerOrderLine[];
  orders: NewOrder[];
  submittingOrder: boolean;
}

export interface BuyerCreatedOrderLocalStorage {
  buyerNewOrder: BuyerNewOrderState;
  storedTimestamp: number;
}

const initialOrderSettings: NewOrderSettings = {
  promptForDeliveryDate: false,
  orderNote: "",
  calculatedDeliverySchedule: createDefaultCalculatedDeliverySchedule(),
  paymentOptions: {
    requirement: PaymentRequirement.None,
    supportedAccounts: [],
    options: []
  },
  timezone: "UTC",
  upcomingOrders: []
};

const initialState: BuyerNewOrderState = {
  orderLines: [],
  orders: [],
  submittingOrder: false
};

const [getCurrentBuyerCreatedOrderState] = localStorageHelpers(currentBuyerCreatedOrderLocalStorageKey);

export const tryLoadOrderFromLocalStorage = (): BuyerNewOrderState | null => {
  try {
    const savedState = getCurrentBuyerCreatedOrderState() as Partial<BuyerCreatedOrderLocalStorage> | undefined;

    if (!savedState || !savedState.buyerNewOrder || !canUseLocalStorageState()) {
      return null;
    }

    // Convert the deliveryDateUTC from a string back to a Dayjs object (the JSON encoding converts it to a string)
    const dateFormattedOrders = savedState.buyerNewOrder.orders.map((order: NewOrder) => {
      return dayjs.isDayjs(order.deliveryDateUtc) || isNullOrUndefined(order.deliveryDateUtc)
        ? order
        : { ...order, deliveryDateUtc: dayjs(order.deliveryDateUtc).tz(order.settings.timezone) };
    });

    return { ...savedState.buyerNewOrder, orders: dateFormattedOrders };
  } catch (error) {
    // Silently capture any potential errors (corrupt state, etc) and abort loading the state
    trackError(error, "Failed to load order from localstorage");
    return null;
  }
};

type ProductOrOrderLine = BuyerProductSummary | BuyerOrderLine;

type AddProductPayload = PayloadAction<{ product: ProductOrOrderLine }>;
type LoadState = PayloadAction<BuyerNewOrderState>;
type CopyOrderPayload = PayloadAction<{ orderLines: BuyerOrderLine[] }>;
type RemoveProductPayload = PayloadAction<{
  product: ProductOrOrderLine;
  onRemove?: (orderLines: BuyerOrderLine[]) => void;
}>;
type UpdateProductQuantityPayload = PayloadAction<{ product: ProductOrOrderLine; quantity: number }>;
type AddComment = PayloadAction<{ comment: string; supplierId: string }>;
type AddDeliveryDate = PayloadAction<{ deliveryDate: Dayjs; supplierId: string }>;
type AddReference = PayloadAction<{ reference: string; supplierId: string }>;
type AddPaymentOption = PayloadAction<{ paymentOption: PaymentMethodOption | OnAccountOption; supplierId: string }>;

export type SubmitBuyerOrderRequest = RequestAction<
  { order: Buyer.V1OrdersCreate.RequestBody },
  Buyer.V1OrdersCreate.ResponseBody
>;
type SubmitBuyerOrderSuccess = PayloadAction<Buyer.V1OrdersCreate.ResponseBody>;

export type FetchAllSupplierOrderSettingsRequest = RequestAction<
  BuyerInternal.InternalSuppliersSettingsList.RequestQuery,
  BuyerInternal.InternalSuppliersSettingsList.ResponseBody
>;
type FetchAllSupplierOrderSettingsSuccess = PayloadAction<BuyerInternal.InternalSuppliersSettingsList.ResponseBody>;

/**
 * Return whether or not a product is in the new order.
 */
function isProductInOrder(orderLines: BuyerOrderLine[], product: BuyerProductSummary): boolean {
  return !!orderLines.find(line => line.productId === product.productId);
}

function isSupplierInOrder(orders: NewOrder[], supplierId: string): boolean {
  return !!orders.find(order => order.supplierId === supplierId);
}

/**
 * Return a copy of `orders` where a single order with the matching index has
 * its property updated.
 */
function updateOrderBySupplierId<K extends keyof NewOrder>(
  orders: NewOrder[],
  supplierId: string,
  fieldName: K,
  fieldValue: NewOrder[K]
): NewOrder[] {
  return orders.map(order => {
    if (order.supplierId === supplierId) {
      return {
        ...order,
        [fieldName]: fieldValue
      };
    }
    return order;
  });
}

/**
 * Return a state with the selected product unfavourited.
 */
function removeFavouriteProduct(state: BuyerNewOrderState, product: BuyerProductSummary): BuyerNewOrderState {
  return isProductInOrder(state.orderLines, product)
    ? {
        ...state,
        orderLines: state.orderLines.slice().map(line => {
          if (line.productId === product.productId) {
            return {
              ...line,
              isAFavourite: false
            };
          }
          return line;
        })
      }
    : state;
}

/**
 * State slice for the data used in our new order pages.
 */
const buyerNewOrderSlice = createSlice({
  name: "buyerNewOrder",
  initialState,
  reducers: {
    clearOrder: () => initialState,
    loadState: (_, { payload }: LoadState) => payload,
    copyOrder: (_, { payload: order }: CopyOrderPayload) => {
      const orderLines: BuyerOrderLine[] = [];
      const orders: NewOrder[] = [];

      order.orderLines.forEach(({ buyerPrice, ...orderLine }) => {
        orderLines.push({
          unitAmount: buyerPrice,
          lineAmount: buyerPrice && {
            amount: buyerPrice.amount * orderLine.quantity,
            currency: buyerPrice.currency
          },
          ...orderLine
        });

        if (!isSupplierInOrder(orders, orderLine.supplierId)) {
          orders.push({
            supplierId: orderLine.supplierId,
            supplierName: orderLine.supplierName,
            supplierLogoUrl: orderLine.supplierLogoUrl,
            deliveryDateUtc: null,
            settings: initialOrderSettings
          });
        }
      });

      return {
        ...initialState,
        orderLines,
        orders
      };
    },

    addProduct: (state, { payload: { product } }: AddProductPayload) => {
      const orderLines = !isProductInOrder(state.orderLines, product)
        ? state.orderLines.concat({
            productId: product.productId,
            productName: product.productName,
            productCode: product.productCode,
            unitAmount: product.buyerPrice,
            lineAmount: product.buyerPrice,
            quantity: 1,
            isAFavourite: isNullOrUndefined(product.isAFavourite) ? true : product.isAFavourite, // overall products list will have property but favourites list doesn't so we manually set this to true
            supplierLogoUrl: product.supplierLogoUrl,
            supplierName: product.supplierName,
            supplierId: product.supplierId,
            isAvailable: product.isAvailable,
            unavailableReason: product.unavailableReason,
            hasCustomUnitAmount: false,
            imagesMetadata: product.imagesMetadata,
            promotions: product.promotions
          })
        : state.orderLines;

      const orders = !isSupplierInOrder(state.orders, product.supplierId)
        ? state.orders.concat({
            supplierId: product.supplierId,
            supplierName: product.supplierName,
            supplierLogoUrl: product.supplierLogoUrl,
            deliveryDateUtc: null,
            settings: initialOrderSettings
          })
        : state.orders;

      return {
        ...state,
        orderLines,
        orders
      };
    },

    /**
     * Remove a product from the order.  Note that at this point, `product` is
     * either a product or an order line.  The important thing is that the
     * object has a `productId` on it, which we use to locate the line to remove
     * from the order.
     */
    removeProduct: (state, { payload: { product, onRemove } }: RemoveProductPayload) => {
      let { orderLines, orders } = state;

      if (isProductInOrder(state.orderLines, product)) {
        const updatedOrderLines = state.orderLines.slice();
        const indexOfProduct = updatedOrderLines.findIndex(line => line.productId === product.productId);
        if (indexOfProduct !== -1) {
          updatedOrderLines.splice(indexOfProduct, 1);
        }
        orderLines = updatedOrderLines;

        // Remove the supplier's order if this was the last product from that supplier
        if (orderLines.filter(line => line.supplierId === product.supplierId).length === 0) {
          const updatedOrders = state.orders.slice();
          const indexOfOrder = updatedOrders.findIndex(order => order.supplierId === product.supplierId);
          if (indexOfOrder !== -1) {
            updatedOrders.splice(indexOfOrder, 1);
          }
          orders = updatedOrders;
        }
      }

      // Return the new order lines list so the Confirm Order page can redirect when the last order line is removed
      if (onRemove) {
        onRemove(orderLines);
      }

      return {
        ...state,
        orderLines,
        orders
      };
    },

    /**
     * Update the quantity of a product on an order.  Note that at this point,
     * `product` is either a product or an order line.  The important thing is
     * that the object has a `productId` on it, which we use to locate the line
     * to update in the order.
     */
    updateProductQuantity: (state, { payload: { product, quantity } }: UpdateProductQuantityPayload) => {
      // @ts-expect-error
      const { productId, buyerPrice, lineAmount, unitAmount } = product;
      return {
        ...state,
        orderLines: state.orderLines.slice().map((line: BuyerOrderLine) => {
          if (line.productId === productId) {
            return {
              ...line,
              ...(lineAmount || buyerPrice
                ? {
                    lineAmount: {
                      amount: (unitAmount || buyerPrice).amount * Number(quantity),
                      currency: (lineAmount || buyerPrice).currency
                    }
                  }
                : null),
              quantity
            };
          }
          return line;
        })
      };
    },

    setDefaultPaymentMethod: state => {
      const orders = state.orders.map(order => {
        if (!order.payment?.option) {
          return order;
        }

        return {
          ...order,
          payment: {
            option: order.settings.paymentOptions.options?.[0] as PaymentMethodOption
          }
        };
      });

      return {
        ...state,
        orders
      };
    },

    addComment: (state, { payload: { comment, supplierId } }: AddComment) => ({
      ...state,
      orders: updateOrderBySupplierId(state.orders, supplierId, "comment", comment)
    }),
    addDeliveryDate: (state, { payload: { deliveryDate, supplierId } }: AddDeliveryDate) => ({
      ...state,
      orders: updateOrderBySupplierId(state.orders, supplierId, "deliveryDateUtc", deliveryDate)
    }),
    addReference: (state, { payload: { reference, supplierId } }: AddReference) => ({
      ...state,
      orders: updateOrderBySupplierId(state.orders, supplierId, "referenceNumber", reference)
    }),
    addPaymentOption: (state, { payload: { paymentOption, supplierId } }: AddPaymentOption) => ({
      ...state,
      orders: updateOrderBySupplierId(state.orders, supplierId, "payment", {
        option: paymentOption
      })
    }),

    submitBuyerOrderRequest: (state, _action: SubmitBuyerOrderRequest) => ({
      ...state,
      submittingOrder: true
    }),
    submitBuyerOrderSuccess: (state, _action: SubmitBuyerOrderSuccess) => ({
      ...state,
      submittingOrder: false
    }),
    submitBuyerOrderFailure: state => ({
      ...state,
      submittingOrder: false
    }),

    fetchAllSupplierOrderSettingsRequest: (state, _action: FetchAllSupplierOrderSettingsRequest) => state,
    fetchAllSupplierOrderSettingsSuccess: (state, { payload: settings }: FetchAllSupplierOrderSettingsSuccess) => {
      const orders = state.orders.map(order => {
        const supplierSettings =
          settings.find(supplierSetting => supplierSetting.supplierId === order.supplierId) || order.settings;

        const supplierOrder: NewOrder = {
          ...order,
          settings: {
            promptForDeliveryDate: supplierSettings.promptForDeliveryDate,
            orderNote: supplierSettings.orderNote,
            calculatedDeliverySchedule: supplierSettings.calculatedDeliverySchedule,
            paymentOptions: supplierSettings.paymentOptions,
            orderMinimumSettings: supplierSettings.orderMinimumSettings,
            timezone: supplierSettings.timezone,
            upcomingOrders: supplierSettings.upcomingOrders
          },
          deliveryDateUtc:
            // Maintain the delivery date if the user navigated back/forward in the flow or
            // the order was restored from localstorage (when adding a payment method)
            order.deliveryDateUtc
              ? order.deliveryDateUtc
              : // Don't prefill the delivery date when the promptForDeliveryDate setting is enabled
                // (require the user to explicitly select a delivery date)
                supplierSettings.promptForDeliveryDate
                ? null
                : // Otherwise calculate the next available delivery date and prefill it
                  getNextAvailableDeliveryDay(supplierSettings.calculatedDeliverySchedule, supplierSettings.timezone)
        };

        if (
          supplierSettings.paymentOptions?.options.length === 1 &&
          !supplierSettings.paymentOptions?.options[0]?.isUnavailable
        ) {
          supplierOrder.payment = {
            option: supplierSettings.paymentOptions.options[0] as PaymentMethodOption
          };
        }

        return supplierOrder;
      });

      return {
        ...state,
        orders
      };
    },
    fetchAllSupplierOrderSettingsFailure: state => state
  },
  extraReducers: builder => {
    builder
      // Working with favourites
      .addCase(addProductToFavouritesListRequest, (state, { payload }) => {
        const [product] = payload.products;
        const { productId } = product;
        return isProductInOrder(state.orderLines, product)
          ? {
              ...state,
              orderLines: state.orderLines.slice().map((line: BuyerOrderLine) => {
                if (line.productId === productId) {
                  return {
                    ...line,
                    isAFavourite: true
                  };
                }
                return line;
              })
            }
          : state;
      })
      .addCase(addProductToFavouritesListFailure, (state, { payload }) => {
        const [product] = payload.products;
        return removeFavouriteProduct(state, product);
      })
      .addCase(removeProductFromFavouritesListRequest, (state, { payload }) => {
        const [product] = payload.products;
        return removeFavouriteProduct(state, product);
      });
  }
});

export const {
  loadState,
  addProduct,
  removeProduct,
  updateProductQuantity,
  clearOrder,
  copyOrder,
  addComment,
  addDeliveryDate,
  addReference,
  addPaymentOption,
  setDefaultPaymentMethod,
  submitBuyerOrderRequest,
  submitBuyerOrderSuccess,
  submitBuyerOrderFailure,
  fetchAllSupplierOrderSettingsRequest,
  fetchAllSupplierOrderSettingsSuccess,
  fetchAllSupplierOrderSettingsFailure
} = buyerNewOrderSlice.actions;
export default buyerNewOrderSlice.reducer;
