import { useMemo } from "react";

import useHasPermission from "auth/useHasPermission";
import { Permission } from "types/api";
import { SupplierDetails, SupplierLinkActions } from "types/api/generated/buyer";
import { isConnected } from "utils/CompanyLinkStatus";

type CanConnectToSupplierResult = {
  /**
   * This is for whether the supplier can be connected to from this buyer.
   * However, to allow a connection action, you also need to check whether this
   * user has permission to do so with {@link #hasCreateInvitePermission}.
   */
  canConnectToSupplier: boolean;

  /**
   * The permission necessary to create an invite to connect to the supplier.
   */
  hasCreateInvitePermission: boolean;

  /**
   * Whether the supplier is accepting new connections or not.  While this forms
   * a part of {@link #canConnectToSupplier}, it can be useful to know this
   * separately to inform the user that the supplier can't be connected to for
   * this particular reason.
   */
  isSupplierAcceptingConnections: boolean;
};

/**
 * React hook for determining whether a buyer can send an invite to trade to a
 * supplier.
 */
export default function useCanConnectToSupplier(supplier?: SupplierDetails): CanConnectToSupplierResult {
  const isSupplierAcceptingConnections = !!supplier?.connectAction;
  const canConnectToSupplier =
    !!supplier?.link.actions.find(item => item.action === SupplierLinkActions.InviteToTrade) &&
    isSupplierAcceptingConnections &&
    !isConnected(supplier.link.status);
  const hasCreateInvitePermission = useHasPermission([Permission.CreateSupplierInvite]);

  return useMemo(
    () => ({
      canConnectToSupplier,
      hasCreateInvitePermission,
      isSupplierAcceptingConnections
    }),
    [canConnectToSupplier, hasCreateInvitePermission, isSupplierAcceptingConnections]
  );
}
