import { TableCell } from "@mui/material";
import { FocusEventHandler, JSX, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from "react";
import NumberFormat, { NumberFormatProps, NumberFormatValues } from "react-number-format";
import { makeStyles } from "tss-react/mui";

import Tooltip, { TooltipProps } from "./Tooltip";

const useStyles = makeStyles()(theme => ({
  tableCell: {
    height: "100%",
    padding: 0,
    "&:focus-within": {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: "-2px"
    }
  },
  tableCellInput: {
    background: "none",
    border: "none",
    color: theme.palette.text.primary,
    height: "100%",
    padding: theme.spacing(2),
    width: "100%",
    "&:focus": {
      outline: "none"
    },
    "&:disabled": {
      color: theme.palette.text.disabled
    }
  },
  input: {
    boxSizing: "border-box",
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    textAlign: "right"
  }
}));

interface NumberCellProps extends NumberCellInputProps {
  inputClassName?: string;
  tooltip?: Omit<TooltipProps, "children">;
}

/**
 * A TableCell for displaying a plain editable number, like a quantity, in a
 * Table.
 */
export default function NumberCell({
  className,
  inputClassName,
  tooltip,
  ...inputProps
}: NumberCellProps): JSX.Element {
  const { classes, cx } = useStyles();

  return (
    <TableCell className={cx(classes.tableCell, className)}>
      {tooltip ? (
        <Tooltip {...tooltip}>
          <NumberCellInput className={cx(classes.tableCellInput, inputClassName)} {...inputProps} />
        </Tooltip>
      ) : (
        <NumberCellInput className={cx(classes.tableCellInput, inputClassName)} {...inputProps} />
      )}
    </TableCell>
  );
}

interface NumberCellInputProps extends Omit<NumberFormatProps, "onBlur"> {
  className?: string;
  onBlur: (newValue: number | null) => void;
  onNativeBlur?: FocusEventHandler<HTMLInputElement>;
  value: number | null;
}

/**
 * The underlying input element within a `<NumberCell>`.  Exposed so that other
 * code can have access to this without necessarily the full cell + input code.
 */
export function NumberCellInput({
  className,
  name,
  onBlur,
  onNativeBlur,
  value,
  ...rest
}: NumberCellInputProps): JSX.Element {
  const { classes, cx } = useStyles();
  const inputEl = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<number | null>(value);

  useEffect(() => {
    setCurrentValue(value);
    // Reset the value when the input name changes too, so that the value is reset properly when
    // doing things like changing tabs (e.g: the mobile standing order table)
  }, [value, name]);

  const handleValueChange: NumberFormatProps["onValueChange"] = useCallback((values: NumberFormatValues) => {
    setCurrentValue(values.floatValue ?? null);
  }, []);

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      onNativeBlur?.(event);

      // Only update the value if it's changed
      if (currentValue !== value) {
        onBlur(currentValue);
      }
    },
    [currentValue, onBlur, onNativeBlur, value]
  );

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(event => {
    if (event.key === "Enter") {
      inputEl.current?.blur();
    }
  }, []);

  return (
    <NumberFormat
      className={cx(classes.input, className)}
      getInputRef={inputEl}
      name={name}
      // Allows the input to be emptied by passing null
      value={currentValue === null ? "" : currentValue}
      placeholder="0"
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      onValueChange={handleValueChange}
      thousandSeparator
      allowEmptyFormatting
      allowNegative={false}
      inputMode="decimal"
      data-lpignore="true"
      {...rest}
    />
  );
}
