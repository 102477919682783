import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { FunctionComponent, PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import LogoHeader from "components/Header/LogoHeader";
import Page, { PageProps } from "components/Page";
import { useAnalytics } from "utils/analytics";

const useStyles = makeStyles()(theme => ({
  pageContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 48px)", // App bar height
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(100vh - 64px)"
    }
  },
  button: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(1),
    left: theme.spacing(-1)
  },
  stepper: {
    justifyContent: "center",
    padding: theme.spacing(1, 3, 3)
  },
  step: {
    padding: 0
  },
  completedLabel: {
    fill: `${theme.palette.grey[300]} !important`,
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  activeLabel: {
    fill: `${theme.palette.secondary.main} !important`,
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  leftIcon: {
    display: "flex"
  }
}));

export interface OnboardingWrapperProps extends Pick<PageProps, "isLoading"> {
  activeStep?: number;
  containerMaxWidth?: PageProps["containerMaxWidth"];
  LeftComp?: JSX.Element;
  stepTotal?: number;
  title?: string;
}

/**
 * A common layout for onboarding pages, which is the logo header at the top and
 * a set of dots to show progress through the onboarding process.
 */
const OnboardingWrapper: FunctionComponent<PropsWithChildren<OnboardingWrapperProps>> = ({
  activeStep = 0,
  children,
  containerMaxWidth = "sm",
  LeftComp,
  isLoading,
  stepTotal = 0,
  title = ""
}) => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const analytics = useAnalytics();

  const handleBackClick = (): void => {
    history.goBack();
    analytics.track("Back Button Clicked");
  };

  const BackArrowComp = (
    <IconButton onClick={handleBackClick} className={cx(classes.button)} data-testid="cancel-action" size="large">
      <span className={classes.leftIcon}>
        <ArrowBack />
      </span>
    </IconButton>
  );

  const steps = [...Array(stepTotal).keys()];

  return (
    <Page
      header={<LogoHeader title={title} LeftComp={activeStep > 0 ? BackArrowComp : LeftComp} />}
      containerClassName={cx(classes.pageContainer)}
      containerMaxWidth={containerMaxWidth}
      displayNav={false}
      isLoading={isLoading}
    >
      {!!stepTotal && (
        <Stepper activeStep={activeStep} connector={null} className={cx(classes.stepper)}>
          {steps.map(el => (
            <Step key={`step-${el}`} className={cx(classes.step)}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    active: cx(classes.activeLabel),
                    completed: cx(classes.completedLabel),
                    root: cx(classes.completedLabel)
                  },
                  icon: ""
                }}
              />
            </Step>
          ))}
        </Stepper>
      )}
      {children}
    </Page>
  );
};

export default OnboardingWrapper;
