import createSagaNext from "redux/tasks/createSagaNext";
import { fetchDataFromApiWrapper } from "services/helpers";
import { SupplierSummaryPagedSet } from "types/api/generated/buyer";
import { buildSearchString } from "utils/urls";

import { fetchDirectoryFailure, FetchDirectoryRequest, fetchDirectorySuccess } from "../reducers/directory";

/**
 * Saga to call the `/buyer/v1/directory` endpoint to get the suppliers available
 * to the buyer from the directory
 */
export default createSagaNext<FetchDirectoryRequest>(
  async (auth0, tenancyId, payload) => {
    const { search, includeConnected = true, tags, limit, featured, sortBy } = payload;
    const result = await fetchDataFromApiWrapper<SupplierSummaryPagedSet>(
      auth0,
      `${process.env.REACT_APP_API_URL}/buyer/v1/directory${buildSearchString({
        limit,
        featured,
        sortBy,
        search,
        includeConnected,
        tags,
        "searchArea.latitude": payload["searchArea.latitude"],
        "searchArea.longitude": payload["searchArea.longitude"],
        "searchArea.radius": payload["searchArea.radius"]
      })}`,
      tenancyId
    );
    return {
      ...payload,
      ...result,
      overrideList: true
    };
  },
  {
    successAction: fetchDirectorySuccess,
    failureAction: fetchDirectoryFailure
  }
);
