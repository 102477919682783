import Decimal from "decimal.js-light";

import { Money } from "types/api/generated/supplier";

/**
 * Constants to define the number of decimal places our currency field defaults too.
 */
export const DECIMALSCALE = 2;

/**
 * Return whether or not the given value can be converted to a number.
 */
export function hasNumericValue(value: number | string | undefined | null): value is number | string {
  if (value === undefined || value === null) {
    return false;
  }

  try {
    return !Number.isNaN(value) && !!new Decimal(value);
  } catch (e) {
    return false;
  }
}

/** Rounds a given value to 2 decimal places using decimal.js-light */
export const roundTo2DP = (value: number | string): string => new Decimal(value).todp(2).toFixed(DECIMALSCALE);

export const getRandomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

export const displayFormattedCurrency = (value: Money | null | undefined, locale = "en-NZ"): string | null => {
  if (value != null) {
    // narrowSymbol requires Safari 14+, so emulate the behaviour and hard-code $ with plain number formatting for now
    // TODO: Revert to this simpler solution when we change our minimum Safari support targets
    // return new Intl.NumberFormat(locale, {
    //   style: "currency",
    //   currency: value.currency.code,
    //   currencyDisplay: "narrowSymbol"
    // }).format(value.amount);

    const numberFormat = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return value.amount < 0
      ? `-$${numberFormat.format(Math.abs(value.amount))}`
      : `$${numberFormat.format(value.amount)}`;
  }
  return null;
};
