import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

type AcceptTermsConditionsRequestPayloadAction = PayloadAction<{
  successCallback: () => void;
  failureCallback: () => void;
}>;

const termsConditionsSlice = createSlice({
  name: "termsConditions",
  initialState,
  reducers: {
    acceptTermsConditionsRequest: (state, _payload: AcceptTermsConditionsRequestPayloadAction) => {
      return {
        ...state,
        loading: true
      };
    },
    acceptTermsConditionsSuccess: state => ({
      ...state,
      loading: false
    }),
    acceptTermsConditionsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    })
  }
});

export const { acceptTermsConditionsRequest, acceptTermsConditionsSuccess, acceptTermsConditionsFailure } =
  termsConditionsSlice.actions;
export default termsConditionsSlice.reducer;
