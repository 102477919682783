import { Chip, Typography } from "@mui/material";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import useMessages from "i18n/hooks/useMessages";
import { SupplierProductSummary } from "types/api/generated/supplier";
import { GlobalReduxState } from "types/redux";

import strings from "./QuickAddProducts.strings.json";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  label: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    color: "inherit"
  }
}));

export interface ReduxState extends GlobalReduxState {}

export interface QuickAddProductsProps {
  onAdd: (product: SupplierProductSummary) => void;
  isAdding?: boolean;
  className?: string;
  products: SupplierProductSummary[];
}

const QuickAddProducts: FunctionComponent<QuickAddProductsProps> = ({ onAdd, isAdding, className, products }) => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();

  if (products.length === 0) {
    return null;
  }

  return (
    <div className={cx(classes.root, className)}>
      <Typography component="div" className={cx(classes.label)} color="textSecondary" variant="caption">
        <FormattedMessage id={messages.ADD_LABEL} />
      </Typography>

      {products.map(product => (
        <Chip
          key={product.productId}
          className={cx(classes.chip)}
          label={product.code}
          variant="outlined"
          onClick={() => onAdd(product)}
          disabled={isAdding}
        />
      ))}
    </div>
  );
};

export default QuickAddProducts;
