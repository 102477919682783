import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";

import { MobileWelcome } from "routes/Routes";
import { IOS } from "utils/MobilePlatforms";
import { PARAM_FORWARD_PATH } from "utils/MobileUrlSearchParams";

const platform = Capacitor.getPlatform();

export function getAuth0ClientId(): string {
  if (
    !process.env.REACT_APP_AUTH_CLIENTID ||
    !process.env.REACT_APP_AUTH_CLIENTID_IOS ||
    !process.env.REACT_APP_AUTH_CLIENTID_ANDROID
  ) {
    throw new Error("Missing auth0 clientId environment variable");
  }
  return platform === "web"
    ? process.env.REACT_APP_AUTH_CLIENTID
    : platform === IOS
      ? process.env.REACT_APP_AUTH_CLIENTID_IOS
      : process.env.REACT_APP_AUTH_CLIENTID_ANDROID;
}

/**
 * Clears all Auth0 state and logs the user in again.
 *
 * Useful for when an Auth0 error happens to get the user unstuck.
 */
export function loginAgain(auth: Auth0ContextInterface): void {
  // Clear all auth state (in case something's bad/corrupt) but don't redirect to the login page,
  // so we can redirect appropriately with a `returnTo` param below
  auth.logout({ openUrl: false });

  if (Capacitor.isNativePlatform()) {
    window.location.href = MobileWelcome.toUrl(undefined, {
      [PARAM_FORWARD_PATH]: window.location.pathname + window.location.search + window.location.hash
    });
  } else {
    auth.loginWithRedirect({
      appState: {
        returnTo: window.location.pathname + window.location.search + window.location.hash
      }
    });
  }
}
