import { TypeHandler } from "@ultraq/icu-message-formatter";

import Button, { ButtonProps } from "components/Button";
import { show, showNewMessage } from "utils/Intercom";

interface IntercomLinkTypeProps extends ButtonProps {
  newMessage?: string;
}

const intercomLinkTypeHandler: TypeHandler = (
  { className, newMessage }: IntercomLinkTypeProps,
  linkText,
  _,
  values,
  process
): JSX.Element => {
  return (
    <Button className={className} displayAsLink onClick={() => (newMessage ? showNewMessage(newMessage) : show())}>
      {process(linkText, values)}
    </Button>
  );
};

export default intercomLinkTypeHandler;
