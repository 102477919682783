import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchNavigationDataSuccess } from "redux/reducers/navigationData";
import { DirectoryInternal } from "types/api";
import { User, UserProfile } from "types/api/generated/directory-internal";
import { RequestAction, RequestPayload } from "types/redux-helpers";

export interface UserProfileState extends UserProfile {
  error: unknown;
  loading: boolean;
}

const initialState: UserProfileState = {
  userId: "",
  email: "",
  error: null,
  loading: true
};

export type CreateUserRequest = RequestAction<{}, User>;
type CreateUserSuccess = PayloadAction<User>;

export type LoadUserProfileRequest = PayloadAction<
  RequestPayload<{}, DirectoryInternal.InternalUsersProfileList.ResponseBody> | undefined
>;
type LoadUserProfileSuccess = PayloadAction<DirectoryInternal.InternalUsersProfileList.ResponseBody>;

type UpdateUserProfile = PayloadAction<DirectoryInternal.InternalUsersProfileUpdate.ResponseBody>;

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    createUserRequest: (state, _: CreateUserRequest) => state,
    createUserSuccess: (state, { payload: profile }: CreateUserSuccess) => ({
      ...state,
      ...profile
    }),
    createUserFailure: state => state,
    loadUserProfileRequest: (_state, _action: LoadUserProfileRequest) => ({
      ...initialState,
      loading: true
    }),
    loadUserProfileSuccess: (state, { payload: profile }: LoadUserProfileSuccess) => ({
      ...state,
      ...profile,
      loading: false
    }),
    loadUserProfileFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    // Only used by the useUpdateUserProfile query to keep the redux state in sync
    updateUserProfile: (state, { payload: profile }: UpdateUserProfile) => ({
      ...state,
      ...profile
    })
  },
  extraReducers: builder => {
    builder.addCase(fetchNavigationDataSuccess, (state, { payload }) => ({
      ...state,
      ...payload.userProfile
    }));
  }
});

export const {
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  loadUserProfileRequest,
  loadUserProfileSuccess,
  loadUserProfileFailure,
  updateUserProfile
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
