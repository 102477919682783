import { localStorageHelpers } from "utils/LocalStorage";

import { currentBuyerCreatedOrderLocalStorageKey, readBuyerOrderFromLocalStorageKey } from "../orderInLocalStorage";

const [getCurrentBuyerCreatedOrderState] = localStorageHelpers(currentBuyerCreatedOrderLocalStorageKey);
const [getReadBuyerOrderFromLocalStorage] = localStorageHelpers(readBuyerOrderFromLocalStorageKey);

export const canUseLocalStorageState = (): boolean => {
  const stateInLocalStorage = getCurrentBuyerCreatedOrderState();
  const readBuyerOrderFromLocalStorage = getReadBuyerOrderFromLocalStorage();

  if (!stateInLocalStorage || !readBuyerOrderFromLocalStorage) {
    return false;
  }
  const storedTime = stateInLocalStorage.storedTimestamp;

  // IF buyerNewOrder state exists, plus the stored time is within the hour (60 second * 60 minutes = 3600)
  return stateInLocalStorage.buyerNewOrder && Math.floor(storedTime / 1000) >= Math.floor(Date.now() / 1000) - 3600;
};
