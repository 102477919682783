import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Footer, { FooterProps } from "./index";

const useStyles = makeStyles<{ excludeNav?: boolean }>()((theme, { excludeNav }) => ({
  footer: {
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    boxShadow: theme.shadows[1],
    boxSizing: "border-box",
    display: "flex",
    height: "auto",
    justifyContent: "flex-end",
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up("lg")]: {
      left: excludeNav ? 0 : "256px"
    }
  }
}));

export interface FixedFooterProps extends FooterProps {
  className?: string;

  /**
   * If `true`, the page has no global nav on it and so the footer should take
   * up the entire page width.
   */
  excludeNav?: boolean;

  /**
   * If `true`, then the footer is only fixed at sm- screen sizes.
   */
  responsive?: boolean;
}

/**
 * A footer that is either fixed at the bottom of the page at all screen sizes,
 * or only on "sm" down responsive sizings, often used for things like
 * navigation / next step actions.
 *
 * Don't forget to add a bottom margin to your page when using this so that it
 * doesn't overlap your content!
 */
const FixedFooter: FunctionComponent<FixedFooterProps> = ({
  children,
  className,
  excludeNav = false,
  responsive = false
}) => {
  const { classes, cx } = useStyles({ excludeNav });
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return responsive && isSmUp ? (
    <Box className={className}>{children}</Box>
  ) : (
    <Footer className={cx(classes.footer, className)}>{children}</Footer>
  );
};

export default FixedFooter;
