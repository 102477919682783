import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Banner from "components/Banner";
import Button from "components/Button";
import useMessages from "i18n/hooks/useMessages";
import PhoneRotationIcon from "media/icon-phone-rotate.svg?react";
import { hidePhoneOrientationBanner } from "redux/reducers/app";

import strings from "./PhoneOrientation.strings.json";

const useStyles = makeStyles()(() => ({
  banner: {
    "@media (orientation: landscape)": {
      display: "none"
    }
  }
}));

/**
 * Banner that appears on pages that work best in a landscape orientation.
 */
const PhoneOrientationBanner: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const onDismissAction = (): void => {
    dispatch(hidePhoneOrientationBanner());
  };

  return isSmDown ? (
    <Banner
      icon={<PhoneRotationIcon />}
      actions={
        <Button color="primary" data-testid="banner-dismiss-btn" onClick={onDismissAction} displayAsLink>
          <FormattedMessage id={messages.DISMISS} />
        </Button>
      }
      className={cx(classes.banner)}
      data-testid="banner-phoneorientation"
    >
      <FormattedMessage id={messages.PAGE_MESSAGE} />
    </Banner>
  ) : null;
};

export default PhoneOrientationBanner;
