import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fetchNavigationDataSuccess, MenuOverride } from "redux/reducers/navigationData";
import { GlobalInternal } from "types/api";
import {
  CompanyProfile,
  CompanyTypeStatus,
  Directory,
  UpdateCompanyProfileRequest
} from "types/api/generated/directory";
import { CompanySummary, UserPagedSet } from "types/api/generated/directory-internal";
import { Alpha2Code } from "types/api/generated/global-internal";
import { ApiValidationProblem } from "types/api/generated/supplier";
import { Supplier } from "types/api/generated/supplier-internal";
import { FailureAction, RequestAction, RequestPayload } from "types/redux-helpers";
import { isError } from "utils/Errors";
import { HTTP_STATUS_FORBIDDEN, HTTP_STATUS_NOTFOUND } from "utils/HttpStatuses";
import { getBusinessNumber, TENANCY_NONE_FOUND } from "utils/Tenancy";

export interface TenancyState extends CompanyProfile {
  companyProfileLoading: boolean;
  loading: boolean;
  users: Pick<UserPagedSet, "data">;
  companies: CompanySummary[];
  error: Error | ApiValidationProblem | null;
  businessNumber?: string | null;
  gstNumber?: string | null;
  // These CompanyProfile properties are made non-nullable
  email: string;
  primaryAddress: string;
}

const initialState: TenancyState = {
  companyProfileLoading: true,
  loading: false,
  users: {
    data: []
  },
  companies: [],
  error: null,
  // CompanyProfile properties
  brandPrimaryColour: null,
  companyId: "",
  tradingName: "",
  website: null,
  description: null,
  phone: null,
  email: "",
  primaryAddress: "",
  addresses: [],
  isVisibleInDirectoryAsSupplier: false,
  isVisibleInDirectoryAsBuyer: false,
  isBuyer: false,
  isSupplier: false,
  isClaimed: false,
  buyerStatus: CompanyTypeStatus.None,
  supplierStatus: CompanyTypeStatus.None,
  timezone: "UTC",
  country: {
    code: Alpha2Code.NZ,
    commonName: ""
  }
};

export type LoadTenancyIdRequest = RequestAction<{}, CompanySummary>;
type LoadTenancyIdSuccess = PayloadAction<CompanySummary>;
type SetCompanyId = RequestAction<
  Pick<CompanySummary, "companyId"> & MenuOverride,
  string | GlobalInternal.InternalNavigationList.ResponseBody
>;

export type LoadCompanyProfileRequest = PayloadAction<
  undefined | RequestPayload<{}, Directory.V1CompaniesProfileDetail.ResponseBody>
>;
type LoadCompanyProfileSuccess = PayloadAction<Directory.V1CompaniesProfileDetail.ResponseBody>;

export type UpdateLatestCompanyRequest = PayloadAction<{
  tenantId?: string;
  companyId?: string;
  successCallback?: (tenancyId: string) => void;
}>;
type UpdateLatestCompanySuccess = PayloadAction<{ companyId: string }>;

type FetchUsersForCompanyRequest = PayloadAction;
type FetchUsersForCompanySuccess = PayloadAction<Pick<UserPagedSet, "data" | "total">>;
export type FetchUsersCompaniesRequest = RequestAction<{}, CompanySummary[]>;
type FetchUsersCompaniesSuccess = PayloadAction<CompanySummary[]>;

export type SaveCompanyDetailsPayload = Omit<UpdateCompanyProfileRequest, "countryExtensions"> & {
  businessNumber?: string | null;
  gstNumber?: string | null;
};
export type SaveCompanyDetailsRequest = RequestAction<
  { companyProfile: SaveCompanyDetailsPayload; countryCode?: Alpha2Code },
  CompanyProfile
>;
type SaveCompanyDetailsSuccess = PayloadAction<CompanyProfile>;

export type ClaimCompanyFromExternalOrderRequest = RequestAction<
  { supplierId: string; viewId: string; companyId: string; onSuccess?: () => void },
  Supplier.InternalExternalViewOrderClaimCreate.ResponseBody
>;
type ClaimCompanyFromExternalOrderSuccess = PayloadAction<{ companyId: string }>;

/**
 * A state slice for the tenancy information.
 */
const snackbarSlice = createSlice({
  name: "tenancy",
  initialState,
  reducers: {
    loadTenancyIdRequest: (_state, _payload: LoadTenancyIdRequest) => ({
      ...initialState,
      loading: true
    }),
    loadTenancyIdSuccess: (state, { payload: data }: LoadTenancyIdSuccess) => ({
      ...state,
      ...data,
      loading: false
    }),
    loadTenancyIdFailure: (state, { payload: error }: FailureAction) => {
      if (isError(error)) {
        return {
          ...state,
          companyId:
            error.message === HTTP_STATUS_FORBIDDEN || error.message === HTTP_STATUS_NOTFOUND
              ? TENANCY_NONE_FOUND
              : state.companyId,
          loading: false,
          isSupplier: false,
          isBuyer: false,
          error
        };
      }
      return state;
    },
    setCompanyId: (state, { payload: { companyId } }: SetCompanyId) => ({
      ...state,
      companyId
    }),
    loadCompanyProfileRequest: (state, _action: LoadCompanyProfileRequest) => ({
      ...state,
      loading: true,
      companyProfileLoading: true
    }),
    loadCompanyProfileSuccess: (state, { payload: result }: LoadCompanyProfileSuccess) => ({
      ...state,
      ...result,
      email: result.email ?? "",
      primaryAddress: result.primaryAddress ?? "",
      businessNumber: getBusinessNumber(result),
      gstNumber: result.countryExtensions?.newZealandGstNumber ?? null,
      loading: false,
      companyProfileLoading: false,
      directoryTags: result.directoryTags ?? initialState.directoryTags
    }),
    loadCompanyProfileFailure: (state, { payload: error }) => ({
      ...state,
      companyId: TENANCY_NONE_FOUND,
      isSupplier: false,
      isBuyer: false,
      loading: false,
      companyProfileLoading: false,
      error
    }),
    updateLatestCompanyRequest: (state, _action: UpdateLatestCompanyRequest) => ({ ...state, loading: true }),
    updateLatestCompanySuccess: (state, { payload: { companyId } }: UpdateLatestCompanySuccess) => ({
      ...state,
      companyId,
      loading: false
    }),
    updateLatestCompanyFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    fetchUsersForCompanyRequest: (state, _action: FetchUsersForCompanyRequest) => ({ ...state, loading: true }),
    fetchUsersForCompanySuccess: (state, { payload: users }: FetchUsersForCompanySuccess) => ({
      ...state,
      loading: false,
      users: {
        ...users,
        /** Pretty sure this is a role now and not a user but lets filter anyway,
         * we don't want them showing in the list if it is hanging around.
         */
        data: users.data.filter(
          user => !user.email.includes("support@ordamatic.com") && !user.email.includes("support@upstock.app")
        )
      }
    }),
    fetchUsersForCompanyFailure: (state, { payload: error }) => ({
      ...state,
      loading: false,
      error
    }),
    fetchUsersCompaniesRequest: (state, _action: FetchUsersCompaniesRequest) => ({ ...state, loading: true }),
    fetchUsersCompaniesSuccess: (state, { payload: companies }: FetchUsersCompaniesSuccess) => ({
      ...state,
      companies,
      loading: false
    }),
    fetchUsersCompaniesFailure: (state, { payload: error }) => ({
      ...state,
      error,
      companyId: "",
      loading: false
    }),
    claimCompanyFromExternalOrderRequest: (_, { payload: { companyId } }: ClaimCompanyFromExternalOrderRequest) => ({
      ...initialState,
      companyId,
      loading: true
    }),
    claimCompanyFromExternalOrderSuccess: (
      state,
      { payload: { companyId } }: ClaimCompanyFromExternalOrderSuccess
    ) => ({
      ...state,
      companyId,
      loading: false
    }),
    claimCompanyFromExternalOrderFailure: (state, { payload: error }) => ({
      ...state,
      loading: false,
      error
    }),
    saveCompanyDetailsRequest: (state, _action: SaveCompanyDetailsRequest) => state,
    saveCompanyDetailsSuccess: (state, { payload: profile }: SaveCompanyDetailsSuccess) => ({
      ...state,
      ...profile,
      email: profile.email ?? "",
      primaryAddress: profile.primaryAddress ?? "",
      businessNumber: getBusinessNumber(profile),
      gstNumber: profile.countryExtensions?.newZealandGstNumber ?? null,
      loading: false,
      companies: state.companies.map(company =>
        company.companyId === profile.companyId
          ? {
              companyId: profile.companyId,
              country: profile.country,
              tradingName: profile.tradingName,
              isBuyer: profile.isBuyer,
              isSupplier: profile.isSupplier,
              buyerStatus: profile.buyerStatus,
              supplierStatus: profile.supplierStatus
            }
          : company
      )
    }),
    saveCompanyDetailsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    })
  },
  extraReducers: builder => {
    // Populate `state.tenancy` from the navigationData. This allows us to remove the
    // `/directory/internal/users/companies` and `/directory/v1/companies/{companyId}/profile` requests
    // from page load without having to update all the `state.tenancy` references in the app.
    builder.addCase(fetchNavigationDataSuccess, (state, { payload: { companyProfile, companies } }) => {
      return {
        ...state,
        ...companyProfile,
        companies,
        email: companyProfile.email ?? "",
        primaryAddress: companyProfile.primaryAddress ?? "",
        businessNumber: getBusinessNumber(companyProfile),
        gstNumber: companyProfile.countryExtensions?.newZealandGstNumber ?? null
      };
    });
  }
});

export const {
  loadTenancyIdRequest,
  loadTenancyIdSuccess,
  loadTenancyIdFailure,
  setCompanyId,
  loadCompanyProfileRequest,
  loadCompanyProfileSuccess,
  loadCompanyProfileFailure,
  updateLatestCompanyRequest,
  updateLatestCompanySuccess,
  updateLatestCompanyFailure,
  fetchUsersCompaniesRequest,
  fetchUsersCompaniesSuccess,
  fetchUsersCompaniesFailure,
  fetchUsersForCompanyRequest,
  fetchUsersForCompanySuccess,
  fetchUsersForCompanyFailure,
  claimCompanyFromExternalOrderRequest,
  claimCompanyFromExternalOrderSuccess,
  claimCompanyFromExternalOrderFailure,
  saveCompanyDetailsRequest,
  saveCompanyDetailsSuccess,
  saveCompanyDetailsFailure
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
