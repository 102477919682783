import { useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MFab, { FabProps as MFabProps } from "@mui/material/Fab";
import { useTheme } from "@mui/styles";
import { LocationDescriptor } from "history";
import React, {
  FunctionComponent,
  JSX,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useUnmount } from "react-use";
import { makeStyles } from "tss-react/mui";

import { LocalLink } from "components/LocalLinkOrExternalLink";
import { SoftwareKeyboardContext } from "mobile/SoftwareKeyboardDetector";
import useMobileNav from "mobile/useMobileNav";

interface FabContextType {
  onFabRendered: () => void;
  onFabUnrendered: () => void;
  renderedFabCount: number;
}

// This is used to used by the snackbar to detect if there's a Fab on the page,
// so that it can make room for it on mobile
export const FabContext = React.createContext<FabContextType>({
  onFabRendered: () => {},
  onFabUnrendered: () => {},
  renderedFabCount: 0
});

// Use a dynamic context so that the snackbar can react realtime to a Fab being mounted on the page
// (which can happen when the snackbar is shown and then the user navigates to a page with a Fab)
export const FabProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [renderedFabCount, setRenderedFabCount] = useState(0);
  const onFabRendered = useCallback(() => setRenderedFabCount(prevCount => prevCount + 1), []);
  const onFabUnrendered = useCallback(() => setRenderedFabCount(prevCount => prevCount - 1), []);

  const context = useMemo(
    () => ({
      onFabRendered,
      onFabUnrendered,
      renderedFabCount
    }),
    [onFabRendered, onFabUnrendered, renderedFabCount]
  );

  return <FabContext.Provider value={context}>{children}</FabContext.Provider>;
};

const useStyles = makeStyles<{ mobileNavRendered: boolean }>()((theme, { mobileNavRendered }) => ({
  fabContainer: {
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      bottom: `calc(${theme.spacing(mobileNavRendered ? 11 : 4)} + env(safe-area-inset-bottom))`,
      display: "flex",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      right: 0,
      zIndex: 10,
      pointerEvents: "none"
    }
  },
  fabButton: {
    pointerEvents: "auto",
    whiteSpace: "nowrap",
    lineHeight: 1.25
  },
  circularProgress: {
    left: "calc(50% - 12px)",
    position: "absolute",
    top: "calc(50% - 12px)",
    color: theme.palette.grey[600]
  }
}));

export interface FabProps<S> extends MFabProps {
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  showLoader?: boolean;

  /**
   * If present, a link will be generated that performs a client-side redirect,
   * similar to the `to` prop in react-router components.
   */
  to?: LocationDescriptor<S>;

  /**
   * Wrapper component to inject between the container and the Fab.  Useful for
   * if you need to fixed position something with the Fab like a Tooltip.
   */
  wrapperComponent?: ReactElement;
}

/**
 * Our custom floating action button, which appears inline with other buttons on
 * large screens, but floats above other content on mobile screens.
 */
export default function Fab<S = unknown>({
  children,
  className,
  containerClassName,
  disabled,
  showLoader,
  to,
  wrapperComponent,
  ...fabProps
}: FabProps<S>): JSX.Element | null {
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("md"));
  const { onFabRendered, onFabUnrendered } = useContext(FabContext);
  const { mobileNavRendered } = useMobileNav();
  const { classes, cx } = useStyles({ mobileNavRendered });
  const softwareKeyboardVisible = useContext(SoftwareKeyboardContext);
  const renderFab = !(softwareKeyboardVisible && isXsDown);

  useEffect(() => {
    if (renderFab) {
      onFabRendered();
    } else {
      onFabUnrendered();
    }
  }, [onFabRendered, onFabUnrendered, renderFab]);

  useUnmount(() => {
    if (renderFab) {
      onFabUnrendered();
    }
  });

  const fabComponent = useMemo(() => {
    return to ? LocalLink : "button";
  }, [to]);

  const fab = (
    <MFab
      variant="extended"
      color="secondary"
      className={cx(classes.fabButton, className)}
      disabled={disabled || showLoader}
      component={fabComponent}
      to={to}
      {...fabProps}
    >
      {children}
      {showLoader && <CircularProgress size={24} className={cx(classes.circularProgress)} />}
    </MFab>
  );

  return renderFab ? (
    <div className={cx(classes.fabContainer, containerClassName)}>
      {wrapperComponent ? React.cloneElement(wrapperComponent, { children: fab }) : fab}
    </div>
  ) : null;
}
