import debounce from "lodash.debounce";

import { analytics } from "./analytics";

/*
 * Helper methods for working with the Intercom JavaScript API.  All of the
 * methods are based off the documentation found here:
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */

type IntercomMethods = Intercom_.IntercomCommandSignature;

/**
 * Have some action happen when the Intercom frame is closed.
 */
export const onHide: IntercomMethods["onHide"] = callback => {
  window.Intercom("onHide", debounce(callback, 1000, { leading: true, trailing: true }));
};

/**
 * Have some action happen when the Intercom frame is opened.
 */
export const onShow: IntercomMethods["onShow"] = callback => {
  window.Intercom("onShow", debounce(callback, 1000, { leading: true, trailing: true }));
};

/**
 * Have some action happen when the unread message count from Intercom changes.
 */
export const onUnreadCountChange: IntercomMethods["onUnreadCountChange"] = callback => {
  // Debounce the event because sometimes Intercom can go a little nuts
  window.Intercom("onUnreadCountChange", debounce(callback, 1000, { leading: true, trailing: true }));
};

/**
 * Open the Intercom frame.
 */
export const show: IntercomMethods["show"] = () => {
  window.Intercom("show");
};

/**
 * Open Intercom with the given help article already in view.
 */
export const showArticle: IntercomMethods["showArticle"] = articleId => {
  window.Intercom("showArticle", articleId);
};

/**
 * Open the Intercome frame with a pre-populated message.
 */
export const showNewMessage: IntercomMethods["showNewMessage"] = prepopulateMessage => {
  window.Intercom("showNewMessage", prepopulateMessage);
};

/**
 * Shut down Intercom.
 */
export const shutdown: IntercomMethods["shutdown"] = () => {
  window.Intercom("shutdown");
};

/**
 * Pass some data along to Intercom to update the current user details and log
 * an impression at the current URL.
 */
export const update: IntercomMethods["update"] = settings => {
  window.Intercom("update", settings);
};

// Prevent this code from erroring in the unit tests when Intercom isn't mocked
if (process.env.NODE_ENV !== "test" || "Intercom" in window) {
  onShow(() => {
    // Track when Intercom is opened so CS can more easily find where help was requested in Session Replay
    analytics.track("Intercom Opened");
  });
}
