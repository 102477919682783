import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { DirectoryInternal } from "types/api";

import { loadUserProfileFailure, loadUserProfileSuccess } from "../reducers/userProfile";

/**
 * Call the API to fetch the authenticated users profile
 */
const loadUserProfile = (
  auth: Auth0ContextInterface,
  tenancyId: string
): Promise<DirectoryInternal.InternalUsersProfileList.ResponseBody> => {
  const urlWithDomain = `${process.env.REACT_APP_API_URL}/directory/internal/users/profile`;
  return fetchDataFromApiWrapper(auth, urlWithDomain, tenancyId);
};

/**
 * Saga task for fetching data from the `/directory/internal/users/profile` API endpoint, then
 * placing it in the {@link personalProfile} state slice.
 */
export default createSaga(loadUserProfile, {
  successAction: loadUserProfileSuccess,
  failureAction: loadUserProfileFailure
});
