import Decimal from "decimal.js-light";

import { PriceListDiscountType, PriceListProduct } from "types/api/generated/supplier";
import { isNullOrUndefined } from "utils/helpers";

const TWO_DECIMAL_PLACES = 2;

/**
 * Calculate the $ value of the percentage provided against the base price.
 */
export const calculateDollarFromPercentageDiscount = (percentageDiscount: number, basePrice: number): number => {
  return new Decimal(basePrice).dividedBy(100).times(percentageDiscount).toDecimalPlaces(TWO_DECIMAL_PLACES).toNumber();
};

/**
 * Calculates the % value from discount value of the base price.
 */
export const calculatePercentageFromDollarDiscount = (dollarDiscount: number, basePrice: number): number => {
  return basePrice === 0
    ? new Decimal(dollarDiscount).toDecimalPlaces(TWO_DECIMAL_PLACES).toNumber()
    : new Decimal(dollarDiscount).dividedBy(basePrice).times(100).toDecimalPlaces(TWO_DECIMAL_PLACES).toNumber();
};

/**
 * Apply a discount to a product object, setting the appropriate object values
 * that represent the discount type and value.
 */
export function applyDiscount<P extends Pick<PriceListProduct, "price">>(
  product: P,
  discountType: PriceListDiscountType,
  discountValue?: number
): P {
  const price: PriceListProduct["price"] = {
    ...product.price,
    discountType
  };
  if (discountValue === 0) {
    price.discountType = PriceListDiscountType.None;
    delete price.discountPercentage;
    price.discountAmount = { ...price.discountAmount, amount: 0 } as PriceListProduct["price"]["discountAmount"];
  } else if (discountType === PriceListDiscountType.Dollar && !isNullOrUndefined(discountValue)) {
    price.discountAmount = {
      amount: discountValue,
      currency: product.price.discountAmount?.currency || { code: "NZD" }
    };
    price.discountPercentage = calculatePercentageFromDollarDiscount(discountValue, product.price.basePrice?.amount);
  } else if (discountType === PriceListDiscountType.Percentage && !isNullOrUndefined(discountValue)) {
    price.discountPercentage = discountValue;
    price.discountAmount = {
      amount: calculateDollarFromPercentageDiscount(discountValue, product.price.basePrice?.amount),
      currency: product.price.discountAmount?.currency || { code: "NZD" }
    };
  } else {
    delete price.discountPercentage;
    price.discountAmount = { ...price.discountAmount, amount: 0 } as PriceListProduct["price"]["discountAmount"];
  }

  return {
    ...product,
    price
  };
}

/**
 * Return the product from the list with the matching ID.
 */
export function findProductById(products: PriceListProduct[], productId: string): PriceListProduct | undefined {
  return products.find(p => p.productId === productId);
}

/**
 * Returns the products from the list with the matching IDs.
 */
export function findProductsByIds(
  products: PriceListProduct[],
  productIds: string[]
): (PriceListProduct | undefined)[] {
  return products.filter(product => productIds.some(productId => productId === product.productId));
}

/**
 * Updates any products found in the first array with new details found
 * in the second product array
 */
export const updateProductList = (
  currentProducts: PriceListProduct[],
  updatedProducts: PriceListProduct[]
): PriceListProduct[] =>
  currentProducts.map(currentProduct => findProductById(updatedProducts, currentProduct.productId) ?? currentProduct);

/**
 * Merges the two product lists together, updating any objects found in the
 * first array with teh data in the second. Then any unmatched products
 * are added at the end of the new array.
 */
export const mergeProductLists = (
  currentProducts: PriceListProduct[],
  additionalProducts?: PriceListProduct[]
): PriceListProduct[] => {
  if (!additionalProducts?.length) {
    return currentProducts;
  }
  // Check if any of the new data is actually the same as current data and update
  const updatedProducts = additionalProducts.length
    ? updateProductList(currentProducts, additionalProducts)
    : currentProducts;

  // Find any products that haven't been updated.
  const newProducts = updatedProducts.length
    ? additionalProducts.filter(newProduct => !updatedProducts.find(p => p.productId === newProduct.productId))
    : additionalProducts;

  return [...updatedProducts, ...newProducts];
};
