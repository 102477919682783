import { isNullOrUndefined } from "utils/helpers";

/**
 * Create a URL parameters ("search") string using the keys/values in the given
 * object.  If a value is an empty string, `null`, or `undefined`, then it is
 * not included in the resulting string.  eg:
 *
 * ```
 * const urlParams = buildSearchString({
 *   name: 'test',
 *   limit: 25,
 *   sortBy: undefined
 * });
 * console.log(urlParams); // ?name=test&limit=25
 * ```
 */
export function buildSearchString(params: Record<string, any> | null | undefined): string {
  if (!params) {
    return "";
  }

  const urlSearchParamsString = Object.keys(params)
    .reduce((acc, key) => {
      const value = params[key];

      if (Array.isArray(value)) {
        if (value.length > 0) {
          value.forEach(v => {
            if (!isNullOrUndefined(v) && v !== "") {
              acc.append(key, v);
            }
          });
        }
      } else if (!isNullOrUndefined(value) && value !== "") {
        acc.set(key, value);
      }
      return acc;
    }, new URLSearchParams())
    .toString();

  return urlSearchParamsString ? `?${urlSearchParamsString}` : "";
}

/**
 * Return a new `URLSearchParams` object with the same keys/values as an
 * existing `URLSearchParams` object and any additional keys/valus in the
 * optional record.
 */
export function copyUrlSearchParams(
  urlSearchParams: URLSearchParams,
  additionalParams: Record<string, any> = {}
): URLSearchParams {
  const newUrlSearchParams = new URLSearchParams();
  urlSearchParams.forEach((value, key) => {
    newUrlSearchParams.append(key, value);
  });
  Object.keys(additionalParams).forEach(key => {
    newUrlSearchParams.append(key, additionalParams[key]);
  });
  return newUrlSearchParams;
}

/**
 * Tagged template string helper that automatically URL encodes any variables concatenated into a URL string.
 *
 * @example
 * const tenancyId = "bad/value";
 * safeurl`/directory/v1/companies/${tenancyId}/profile` === "/directory/v1/companies/bad%2Fvalue/profile"
 */
export function safeurl(strings: TemplateStringsArray, ...params: Array<string | number>): string {
  return strings.reduce((acc, string, i) => {
    const param = params[i];
    const encodedParam = !isNullOrUndefined(param) ? encodeURIComponent(param) : "";
    return `${acc}${string}${encodedParam}`;
  }, "");
}
