import { MessageFormatter } from "@ultraq/icu-message-formatter";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";

import { DayOfWeek } from "types/api/generated/supplier";
import dayjs, { Dayjs } from "utils/dayjs";

import strings from "./Dates.strings.json";

dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

/**
 * Will check if a given date is before but not equal to, the current day.
 */
export const isDateInThePast = (date: Date | string | number): boolean => {
  const JSDate = new Date(date);
  return dayjs(JSDate).isBefore(dayjs(), "day");
};

/**
 * Will check a given date is more than 2 days ahead of the current day.
 */
export const isDateGreaterThanTwoDaysAhead = (date: Date | string | number): boolean => {
  const JSDate = new Date(date);
  const dateFormatted = dayjs(JSDate).format("MM/DD/YYYY");

  const tomorrow = dayjs().add(1, "days").toDate();
  const tomorrowDate = dayjs(tomorrow).format("MM/DD/YYYY");

  return dayjs(dateFormatted).isAfter(tomorrowDate, "day");
};

export const dateTense = (JSdate: Date | number, displayTime?: boolean): string | false => {
  const formattedTime = dayjs(JSdate).format("h:mm a");
  if (dayjs(JSdate).isSame(dayjs(), "day")) {
    return displayTime ? `${formattedTime} today` : "today";
  }

  if (dayjs(JSdate).isYesterday()) {
    return displayTime ? `${formattedTime} yesterday` : "yesterday";
  }

  if (dayjs(JSdate).isTomorrow()) {
    return displayTime ? `${formattedTime} tomorrow` : "tomorrow";
  }
  return false;
};

export const getFormattedDayDifference = (
  baseDate: Date,
  dateToCompare: Date,
  formatter: MessageFormatter
): string | null => {
  const daysDifference = dayjs(dateToCompare).diff(baseDate, "days");

  if (daysDifference === 0) {
    return formatter.format(strings.TODAY);
  }
  if (daysDifference === 1) {
    return formatter.format(strings.TOMORROW);
  }
  if (daysDifference > 1) {
    return formatter.format(strings.DAYS_AHEAD, { days: daysDifference });
  }
  if (daysDifference === -1) {
    return formatter.format(strings.YESTERDAY);
  }
  if (daysDifference < -1) {
    return formatter.format(strings.DAYS_AGO, { days: Math.abs(daysDifference) });
  }

  return null;
};

/**
 * Formats a given date based on either a standard format or a custom formmat.
 * Unless the date requires a tense such as today, tomorrow or yesterday.
 */
export const getFormattedOrderDate = (
  dateTime: Date | string | number | undefined,
  displayTime = true,
  customFormatString?: string
): string => {
  if (!dateTime) {
    return "";
  }
  const JSdate = new Date(dateTime);
  const today = new Date();
  const currentDate = new Date(dayjs(today).format("MM/DD/YYYY"));
  if (Number.isNaN(Date.parse(JSdate.toString()))) {
    return "";
  }

  const findDateTense = dateTense(JSdate, displayTime);
  if (findDateTense) {
    return findDateTense;
  }

  const dateAhead = isDateGreaterThanTwoDaysAhead(dateTime);
  const formattedDateDistance =
    dateAhead && !customFormatString ? ` (${dayjs(JSdate).diff(currentDate, "day")} days)` : "";

  return `${dayjs(JSdate).format(
    customFormatString || (displayTime ? "DD MMM, h:mm a" : "DD MMM")
  )}${formattedDateDistance}`;
};

/**
 * Checks if the passed date object is a valid date object
 */
export const isValidDate = (date: unknown): date is Date => {
  if (date instanceof Date) {
    return date.toString() !== "Invalid Date" && !Number.isNaN(date);
  }

  return false;
};

/**
 * Adds a timezone to date-times that don't have a timezone.
 *
 * @example '2022-02-15T22:26:12.000' -> '2022-02-15T22:26:12.000Z'
 * @see https://stackoverflow.com/a/39209842
 */
export const addTimezoneToDateTime = (date: string): string => {
  const tempDate = new Date(date);
  const userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;
  return new Date(tempDate.getTime() - userTimezoneOffset).toISOString();
};

export type DayKey = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

/**
 * Formats a DayOfWeek or DayKey value into a string that can be displayed to the user.
 * @param dayOfWeek Enum value or day key
 * @param formatter MessageFormatter instance
 * @param [useShortName=false] Whether to use the short version of the day name. e.g: Tue
 */
export const formatDayOfWeek = (
  dayOfWeek: DayOfWeek | DayKey,
  formatter: MessageFormatter,
  useShortName?: boolean
): string => {
  if (dayOfWeek === DayOfWeek.Monday || dayOfWeek === "monday") {
    return formatter.format(useShortName ? strings.MONDAY_SHORT : strings.MONDAY);
  }
  if (dayOfWeek === DayOfWeek.Tuesday || dayOfWeek === "tuesday") {
    return formatter.format(useShortName ? strings.TUESDAY_SHORT : strings.TUESDAY);
  }
  if (dayOfWeek === DayOfWeek.Wednesday || dayOfWeek === "wednesday") {
    return formatter.format(useShortName ? strings.WEDNESDAY_SHORT : strings.WEDNESDAY);
  }
  if (dayOfWeek === DayOfWeek.Thursday || dayOfWeek === "thursday") {
    return formatter.format(useShortName ? strings.THURSDAY_SHORT : strings.THURSDAY);
  }
  if (dayOfWeek === DayOfWeek.Friday || dayOfWeek === "friday") {
    return formatter.format(useShortName ? strings.FRIDAY_SHORT : strings.FRIDAY);
  }
  if (dayOfWeek === DayOfWeek.Saturday || dayOfWeek === "saturday") {
    return formatter.format(useShortName ? strings.SATURDAY_SHORT : strings.SATURDAY);
  }
  if (dayOfWeek === DayOfWeek.Sunday || dayOfWeek === "sunday") {
    return formatter.format(useShortName ? strings.SUNDAY_SHORT : strings.SUNDAY);
  }

  return "";
};

/**
 * Creates a date object from day and time strings.
 * @param day Day of week string, e.g. "monday"
 * @param time Time of day string, e.g: "14:30"
 */
export function createDate(day: string, time: string): Date {
  const [hours, minutes] = time.split(":");

  let date = new Date();
  // Weekday starts on a Sunday.
  if (day === "monday") {
    date = dayjs(date).day(1).toDate();
  }
  if (day === "tuesday") {
    date = dayjs(date).day(2).toDate();
  }
  if (day === "wednesday") {
    date = dayjs(date).day(3).toDate();
  }
  if (day === "thursday") {
    date = dayjs(date).day(4).toDate();
  }
  if (day === "friday") {
    date = dayjs(date).day(5).toDate();
  }
  if (day === "saturday") {
    date = dayjs(date).day(6).toDate();
  }
  if (day === "sunday") {
    date = dayjs(date).day(0).toDate();
  }

  date.setHours(Number(hours), Number(minutes));

  return date;
}

/**
 * Format a date using the `Intl.DateTimeFormat` API.
 */
export function formatDate(
  date: Date | string,
  options: Intl.DateTimeFormatOptions | undefined,
  locale: string
): string {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options);
  return date instanceof Date ? dateTimeFormat.format(date) : dateTimeFormat.format(new Date(date));
}

/**
 * Format a date using the `Intl.DateTimeFormat` API.
 */
export function formatDateToParts(
  date: Date | string,
  options: Intl.DateTimeFormatOptions | undefined,
  locale: string
): Intl.DateTimeFormatPart[] {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, options);
  return date instanceof Date ? dateTimeFormat.formatToParts(date) : dateTimeFormat.formatToParts(new Date(date));
}

/**
 * Format the given IANA timezone to suit the given locale.
 *
 * @example formatTimezone("Australia/Perth", "en-AU") === "AWST"
 */
export function formatTimezone(timezone: string, locale: string): string {
  return (
    formatDateToParts(
      new Date(),
      {
        timeZone: timezone,
        timeZoneName: "short"
      },
      locale
    ).find(part => part.type === "timeZoneName")?.value ?? ""
  );
}

export function isUserInDifferentTimezone(timezone: string): boolean {
  return dayjs.tz.guess() !== timezone;
}

export function formatPrettyDateWithTimezone(
  date: Dayjs,
  timezone: string,
  locale: string,
  formatter: MessageFormatter,
  options?: Intl.DateTimeFormatOptions
): string {
  const isDifferentTimezone = isUserInDifferentTimezone(timezone);

  let formattedDate = "";
  // Only show "today" or "tomorrow" if the user is in the same timezone
  if (!isDifferentTimezone && dayjs().tz(timezone).isSame(date, "day")) {
    formattedDate = formatter.format(strings.TODAY).toLowerCase();
  } else if (!isDifferentTimezone && dayjs().tz(timezone).add(1, "day").isSame(date, "day")) {
    formattedDate = formatter.format(strings.TOMORROW).toLowerCase();
  } else if (!isDifferentTimezone && dayjs().tz(timezone).subtract(1, "day").isSame(date, "day")) {
    formattedDate = formatter.format(strings.YESTERDAY).toLowerCase();
  } else {
    formattedDate = formatDate(
      date.toDate(),
      {
        weekday: "short",
        day: "numeric",
        month: "short",
        ...options,
        timeZone: timezone
      },
      locale
    );
  }

  // Show the timezone abbreviation if the user is in a different timezone
  if (isDifferentTimezone) {
    const formattedTimezone = formatTimezone(timezone, locale);
    return `${formattedDate} (${formattedTimezone})`;
  }

  return formattedDate;
}

export const formatTimeUntil = (date: string, formatter: MessageFormatter): { message: string; isSoon: boolean } => {
  const dayJsDate = dayjs(date);
  const now = dayjs();
  const differenceMinutes = dayJsDate.diff(now, "minutes");

  if (differenceMinutes < 60) {
    return {
      message: formatter.format(strings.TIME_UNTIL_MINUTES, { minutes: differenceMinutes }),
      isSoon: true
    };
  }

  if (differenceMinutes < 1440) {
    return {
      message: formatter.format(strings.TIME_UNTIL_HOURS, { hours: Math.floor(differenceMinutes / 60) }),
      isSoon: true
    };
  }

  return {
    message: formatter.format(strings.TIME_UNTIL_DAYS, { days: Math.floor(differenceMinutes / 1440) }),
    isSoon: false
  };
};
