export const MINIMUM_ORDER_REQUIREMENTS = "minimum-order-requirements";
export const PRODUCT_CATEGORIES = "product-categories";
export const BUYER_ORDERS_VNEXT = "buyer-orders-vnext";
export const SUPPLIERS_APPROVE_STANDING_ORDERS = "suppliers-approve-standing-orders";
export const SUPPLIER_PREVIEW_PRODUCTS = "supplier-preview-products";
export const INBOX = "inbox";
export const DELIVERED_STATUS = "add-delivered-status";
export const NET_DAYS = "net-days";
export const BUYER_CATEGORIES = "buyer-categories";
export const CUSTOMER_IMPORT_PICKER = "customer-import-picker";
export const PURCHASE_ORDER_SUGGESTIONS = "purchase-order-suggestions";
export const PURCHASE_ORDER_SUGGESTIONS_ALIASES = "purchase-order-suggestions-lines-buyer-aliases";
export const SCHEDULED_ORDERS_PAUSE_REWORK = "scheduled-orders-pause-rework";
export const STACKED_STANDING_ORDERS = "stacked-standing-orders";
export const SCHEDULED_ORDERS_BACKFILL_BUTTON = "scheduled-orders-backfill-button";
export const HACKATHON_QUICK_ADD_PRODUCTS = "hackathon-quick-add-products";
export const SIGNUP_FLOW_CALLING_CODES = "signup-flow-calling-codes";
export const MANUAL_DOCUMENT_JOBS = "manual-document-jobs";
export const PRODUCT_CATEGORIES_GROUPED_PRODUCTS = "product-categories-grouped-products";
export const DUPLICATE_ORDER_MODAL = "duplicate-order-modal";

const SUPPLIER_PAYMENTS_FLAG = "supplier-payments";

export const SUPPLIER_PAYMENTS = [SUPPLIER_PAYMENTS_FLAG];

// Feature flags used with a companyId as the key
export const companyFlags = [
  SUPPLIER_PAYMENTS_FLAG,
  MINIMUM_ORDER_REQUIREMENTS,
  PRODUCT_CATEGORIES,
  BUYER_ORDERS_VNEXT,
  SUPPLIERS_APPROVE_STANDING_ORDERS,
  SUPPLIER_PREVIEW_PRODUCTS,
  INBOX,
  DELIVERED_STATUS,
  NET_DAYS,
  BUYER_CATEGORIES,
  CUSTOMER_IMPORT_PICKER,
  PURCHASE_ORDER_SUGGESTIONS,
  PURCHASE_ORDER_SUGGESTIONS_ALIASES,
  SCHEDULED_ORDERS_PAUSE_REWORK,
  STACKED_STANDING_ORDERS,
  SCHEDULED_ORDERS_BACKFILL_BUTTON,
  HACKATHON_QUICK_ADD_PRODUCTS,
  MANUAL_DOCUMENT_JOBS,
  PRODUCT_CATEGORIES_GROUPED_PRODUCTS,
  DUPLICATE_ORDER_MODAL
];

// Feature flags used with an user's email as the key
export const emailFlags = [SIGNUP_FLOW_CALLING_CODES];
