import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { inboxQueryKey } from "./keys";

type RequestBody = Supplier.V1MailboxInboxMessagesFilesDetail.RequestBody;
type RequestQuery = Supplier.V1MailboxInboxMessagesFilesDetail.RequestQuery;
export type RequestParams = Supplier.V1MailboxInboxMessagesFilesDetail.RequestParams;
export type ResponseBody = Blob;

export const fetchInboxMessageFile = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParams>(
  ({ externalId, fileId }) => ({
    method: "GET",
    path: safeurl`/supplier/v1/mailbox/inbox/messages/${externalId}/files/${fileId}`,
    responseType: "blob"
  })
);

export const useFetchInboxMessageFile = (request: RequestParams): UseQueryResult<ResponseBody> => {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: inboxQueryKey.file(request).queryKey,
    queryFn: async () => {
      const response = await apiFetch(fetchInboxMessageFile(request));
      return response.data;
    },
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    enabled: !!request.externalId && !!request.fileId
  });
};
