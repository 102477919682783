import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, JSX, ReactNode, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import HelpButton from "components/Header/HelpButton";
import useBuyerGettingStarted from "features/buyers/getting-started/useBuyerGettingStarted";
import useCustomersStats from "features/suppliers/directory/queries/useCustomersStats";
import useSupplierGettingStarted from "features/suppliers/getting-started/useSupplierGettingStarted";
import { updateNotificationsCount } from "redux/reducers/app";
import useAppSelector from "utils/hooks/useAppSelector";
import useGlobalNav from "utils/hooks/useGlobalNav";
import useTenancyId from "utils/hooks/useTenancyId";
import useTitleForUpstock from "utils/hooks/useTitleForUpstock";
import { onUnreadCountChange } from "utils/Intercom";

import strings from "./strings.json";

const useStyles = makeStyles<{ centerComp?: ReactNode }>()((theme, { centerComp }) => ({
  appBar: {
    paddingTop: `env(safe-area-inset-top)`, // fixes notch on mobile devices
    transition: "background-color 0.5s"
  },
  redAppBar: {
    backgroundColor: theme.palette.primary.main
  },
  whiteAppBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none"
  },
  icon: {
    transition: "color 0.5s"
  },
  red: {
    color: theme.palette.common.white
  },
  white: {
    color: theme.palette.primary.main
  },
  toolbar: {
    justifyContent: "space-between"
  },
  section: {
    display: "flex",
    alignItems: "center",
    flexBasis: "0"
  },
  leftSection: {
    flexGrow: centerComp ? 1 : 2
  },
  centerSection: {
    justifyContent: "center"
  },
  rightSection: {
    flexGrow: 1,
    justifyContent: "flex-end"
  },
  menuIconButton: {
    marginRight: theme.spacing(2)
  },
  fabWrapper: {
    top: theme.spacing(4),
    position: "relative",
    right: 0
  },
  secondaryToolbar: {
    display: "flex",
    justifyContent: "flex-end"
  },
  fabToolbar: {
    justifyContent: "flex-end"
  },
  badge: {
    right: "2px",
    top: "5px"
  }
}));

type Variant = "red" | "white";

export interface HeaderProps {
  className?: string;
  centerComp?: ReactNode;
  /**
   * If you need a document title different from the `title` value, use this.
   */
  documentTitle?: string;
  helpIconClassName?: string;
  LeftComp?: JSX.Element | null;
  RightComp?: JSX.Element | null;
  title?: string;
  variant?: Variant;
}

const Header: FunctionComponent<HeaderProps> = ({
  centerComp,
  className,
  documentTitle,
  helpIconClassName = "",
  LeftComp,
  RightComp,
  title = "",
  variant = "red",
  ...rest
}) => {
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const { classes, cx } = useStyles({ centerComp });
  const { isAuthenticated } = useAuth0();
  const { formatter } = useMessageFormatter();
  const tenancyId = useTenancyId();
  const { showGlobalNav } = useGlobalNav();

  const dispatch = useDispatch();
  const { notificationsCount } = useAppSelector(state => state.app);
  const { isSupplier } = useAppSelector(state => state.tenancy);
  const { remainingActions: remainingBuyerGettingStartedActions } = useBuyerGettingStarted(false);
  const { remainingActions: remainingSupplierGettingStartedActions } = useSupplierGettingStarted(false);
  const {
    data: { invitedCount }
  } = useCustomersStats({
    enabled: !!tenancyId && !!isSupplier // isSupplier can be undefined in tests because we allow partial initial state 🙃
  });

  // Derive the badge shown on the slide-out nav menu from various sources
  const navNotificationsCount = useMemo(() => {
    let count: number;
    if (isSupplier) {
      count = (invitedCount ?? 0) + remainingSupplierGettingStartedActions.length;
    } else {
      count = remainingBuyerGettingStartedActions.length;
    }
    return count;
  }, [
    invitedCount,
    isSupplier,
    remainingBuyerGettingStartedActions.length,
    remainingSupplierGettingStartedActions.length
  ]);

  // Prevent this code from erroring in the unit tests when Intercom isn't mocked
  if (process.env.NODE_ENV !== "test" || "Intercom" in window) {
    // TODO: This needs to be done only once, but React will rerender this
    //       component many times, thus registering this event handler many times.
    //       Also, notificationsCount could include other items and this would
    //       wipe those other items from the count, so we'll need to take that
    //       into account too 🤔
    onUnreadCountChange(unreadCount => {
      if (unreadCount !== notificationsCount) dispatch(updateNotificationsCount(unreadCount));
    });
  }

  useEffect(() => {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      StatusBar.setStyle({ style: variant === "white" ? Style.Light : Style.Dark });
    }
  }, [variant]);

  useTitleForUpstock(documentTitle ?? title);

  return (
    <AppBar
      enableColorOnDark
      position="sticky"
      className={cx(classes.appBar, classes[`${variant}AppBar`], className)}
      data-testid="header"
      {...rest}
    >
      <Toolbar className={cx(classes.toolbar, className)}>
        <div className={cx(classes.section, classes.leftSection)}>
          {LeftComp || (
            <>
              {isAuthenticated && isLgDown && (
                <Button
                  onClick={showGlobalNav}
                  className={cx(classes.icon, classes[variant], classes.menuIconButton)}
                  aria-label={formatter.format(strings.SHOW_NAV)}
                >
                  <Badge
                    color="secondary"
                    badgeContent={navNotificationsCount}
                    variant="dot"
                    classes={{
                      badge: cx(classes.badge)
                    }}
                    overlap="rectangular"
                    data-testid="gettingstarted-badge"
                  >
                    <MenuIcon />
                  </Badge>
                </Button>
              )}
              <Typography data-testid="header-title" variant="h3">
                {title}
              </Typography>
            </>
          )}
        </div>
        <div className={cx(classes.section, classes.centerSection)}>{centerComp}</div>
        <div className={cx(classes.section, classes.rightSection)}>
          <HelpButton
            notificationsCount={notificationsCount}
            className={cx(classes.icon, classes[variant], helpIconClassName)}
          />

          {RightComp}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
