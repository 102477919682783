import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BuyerSummary, BuyerSummaryPagedSet, PageLinks, Supplier } from "types/api/generated/supplier";
import { LoadNextPageRequest, LoadPreviousPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";

export interface State {
  list: BuyerSummary[];
  links: PageLinks | null;
  loading: boolean;
  total?: number | null;
}

const initialState: State = {
  list: [],
  links: null,
  loading: true,
  total: 0
};

export type FetchCustomersRequest = RequestAction<Supplier.V1BuyersList.RequestQuery, BuyerSummaryPagedSet>;
type FetchCustomersSuccess = PayloadAction<BuyerSummaryPagedSet & PagingOptions>;

/**
 * Reducer for the list of current customers (buyers) that a supplier has.
 */
const customers = createSlice({
  name: "customers",
  initialState,
  reducers: {
    clearCustomers: () => ({
      ...initialState,
      loading: false
    }),
    fetchCustomersRequest: (_state, _action: FetchCustomersRequest) => ({ ...initialState, loading: true }),
    fetchCustomersSuccess: (
      state,
      { payload: { data, links, total, overrideList = false } }: FetchCustomersSuccess
    ) => ({
      ...state,
      list: overrideList ? data : [...state.list, ...data],
      links,
      total,
      loading: false
    }),
    fetchCustomersFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest) => ({
      ...state,
      loading: true
    }),
    loadPreviousPageRequest: (state, _action: LoadPreviousPageRequest) => ({
      ...state,
      loading: true
    })
  }
});

export const {
  clearCustomers,
  fetchCustomersRequest,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  loadNextPageRequest,
  loadPreviousPageRequest
} = customers.actions;

export default customers.reducer;
