import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api";

export type SubmitPendingLeadRequest = Supplier.V1BuyersPendingLeadsCreate.RequestBody;
type RequestQuery = Supplier.V1BuyersPendingLeadsCreate.RequestQuery;
export type SubmitPendingLeadResponse = Supplier.V1BuyersPendingLeadsCreate.ResponseBody;

export const submitPendingLeadOptions = buildApiFetchOptions<
  SubmitPendingLeadRequest,
  RequestQuery,
  SubmitPendingLeadResponse
>(() => ({
  method: "POST",
  path: "/supplier/v1/buyers/pending/leads"
}));

/**
 * React hook for submitting a buyer request.
 */
export default function useSubmitPendingLead(): UseMutationResult<
  SubmitPendingLeadResponse,
  FetchV2Error,
  SubmitPendingLeadRequest
> {
  const apiFetch = useApiFetch();

  return useMutation({
    async mutationFn(pendingLeadRequest) {
      const response = await apiFetch(
        submitPendingLeadOptions(undefined, {
          data: pendingLeadRequest
        })
      );
      return response.data;
    }
  });
}
