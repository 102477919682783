import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer } from "types/api";
import { CompanyTypeStatus, LinkedSupplierStatus, SupplierDetails } from "types/api/generated/buyer";
import { RequestAction } from "types/redux-helpers";

export interface SupplierByIdState extends SupplierDetails {
  loading: boolean;
  error: string;
}

export const initialState: SupplierByIdState = {
  supplierId: "",
  tradingName: "",
  description: "",
  website: "",
  primaryAddress: "",
  addresses: [],
  email: "",
  phone: "",
  isClaimed: true,
  link: {
    actions: [],
    status: LinkedSupplierStatus.Unlinked
  },
  loading: false,
  error: "",
  connectAction: null,
  orderSettings: {
    orderNote: null,
    deliveryAreas: null,
    deliverySchedule: null
  },
  supplierStatus: CompanyTypeStatus.None
};

export type FetchSupplierDetailsRequest = RequestAction<
  Buyer.V1SuppliersDetail.RequestParams,
  Buyer.V1SuppliersDetail.ResponseBody
>;
type FetchSupplierDetailsSuccess = PayloadAction<Buyer.V1SuppliersDetail.ResponseBody>;

export type InviteToTradeRequest = RequestAction<
  Buyer.V1SuppliersPendingInvitesCreate.RequestBody,
  Buyer.V1SuppliersPendingInvitesCreate.ResponseBody
>;
type InviteToTradeSuccess = PayloadAction<Buyer.V1SuppliersPendingInvitesCreate.ResponseBody>;

const supplierByIdSlice = createSlice({
  name: "supplierById",
  initialState,
  reducers: {
    fetchSupplierDetailsRequest: (state, _payload: FetchSupplierDetailsRequest) => ({ ...state, loading: true }),
    fetchSupplierDetailsSuccess: (state, { payload: supplier }: FetchSupplierDetailsSuccess) => ({
      ...state,
      ...supplier,
      loading: false
    }),
    fetchSupplierDetailsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    inviteToTradeRequest: (state, _payload: InviteToTradeRequest) => ({ ...state, loading: true }),
    inviteToTradeSuccess: (state, { payload: link }: InviteToTradeSuccess) => ({
      ...state,
      link,
      loading: false
    }),
    inviteToTradeFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    clearSupplierDetails: () => initialState
  }
});

export const {
  fetchSupplierDetailsRequest,
  fetchSupplierDetailsSuccess,
  fetchSupplierDetailsFailure,
  inviteToTradeRequest,
  inviteToTradeSuccess,
  inviteToTradeFailure
} = supplierByIdSlice.actions;
export default supplierByIdSlice.reducer;
