import createSagaNext from "redux/tasks/createSagaNext";
import { postDataToApiWrapper } from "services/helpers";

import {
  createDefaultPriceListFailure,
  CreateDefaultPriceListRequest,
  createDefaultPriceListSuccess
} from "../reducers/allPriceLists";

/**
 * Saga to call `POST /supplier/v1/pricelists/default` to create the default
 * price list.  If the company hasn't set all their base prices a validation
 * error will be returned.
 */
export default createSagaNext<CreateDefaultPriceListRequest>(
  (auth0, tenancyId, { name }) => {
    return postDataToApiWrapper(auth0, `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/default`, tenancyId, {
      name
    });
  },
  {
    successAction: createDefaultPriceListSuccess,
    failureAction: createDefaultPriceListFailure
  }
);
