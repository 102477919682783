import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { useMount } from "react-use";

import { IOS } from "utils/MobilePlatforms";

/**
 * React hook for managing the keyboard on the native iOS app.
 */
export default function useIosKeyboard(): void {
  useMount(() => {
    if (Capacitor.isPluginAvailable("Keyboard") && Capacitor.getPlatform() === IOS) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
  });
}
