import { createQueryKeys } from "@lukemorales/query-key-factory";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api";

type RequestBody = Supplier.V1ProductsQuickAddList.RequestBody;
export type RequestQuery = Supplier.V1ProductsQuickAddList.RequestQuery;
export type ResponseBody = Supplier.V1ProductsQuickAddList.ResponseBody;

export const quickAddProductsKey = createQueryKeys("quickAddProducts", {
  list: (request?: RequestQuery) => [request]
});

export const quickAddProductsOptions = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestQuery | undefined
>(searchParams => ({
  method: "GET",
  path: "/supplier/v1/products/quick-add",
  searchParams
}));

export interface UseQuickAddProductsProps {
  query?: RequestQuery;
  enabled?: boolean;
}

/**
 * React query hook to call `GET /supplier/v1/products/quick-add` to retrieve a
 * list of quick-add products.
 */
export default function useQuickAddProducts(
  params: UseQuickAddProductsProps = {}
): UseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useApiFetch();
  const enabled = params.enabled ?? true;

  return useQuery({
    queryKey: quickAddProductsKey.list(params.query).queryKey,
    async queryFn() {
      const response = await apiFetch(quickAddProductsOptions(params.query));
      return response.data;
    },
    enabled
  });
}
