import { LinkAction as SupplierLinkAction, LinkedSupplierStatus, SupplierLinkActions } from "types/api/generated/buyer";
import { BuyerLinkActions, LinkAction as BuyerLinkAction, LinkedBuyerStatus } from "types/api/generated/supplier";

/**
 * Tests if the linkStatus string matches the 'Linked' string
 */
export const isConnected = (linkStatus?: LinkedBuyerStatus | LinkedSupplierStatus): boolean =>
  linkStatus === LinkedBuyerStatus.Linked || linkStatus === LinkedSupplierStatus.Linked;

/**
 * Tests if the linkStatus string matches the 'Pending' string
 */
export const isPending = (linkStatus?: LinkedSupplierStatus): boolean => linkStatus === LinkedSupplierStatus.Pending;

export const hasActionCancel = (actions: Array<BuyerLinkAction | SupplierLinkAction>): boolean =>
  !!actions.find(item => item.action === BuyerLinkActions.Cancel || item.action === SupplierLinkActions.Cancel);

export const hasActionOrder = (actions: Array<BuyerLinkAction | SupplierLinkAction>): boolean =>
  !!actions.find(item => item.action === BuyerLinkActions.Order || item.action === SupplierLinkActions.Order);

/**
 * Returns if the `actions` array contains the `Accept` action.
 */
export const hasActionAccept = (actions: BuyerLinkAction[]): boolean =>
  !!actions.find(action => action.action === BuyerLinkActions.Accept);

/**
 * Returns if the `actions` array contains the `Decline` action.
 */
export const hasActionDecline = (actions: BuyerLinkAction[]): boolean =>
  !!actions.find(action => action.action === BuyerLinkActions.Decline);
