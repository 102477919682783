import { Auth0ContextInterface } from "@auth0/auth0-react";
import debounce from "lodash.debounce";

import { loginAgain } from "auth/auth0";
import { logger } from "utils/Datadog";
import dayjs from "utils/dayjs";
import { isNullOrUndefined } from "utils/helpers";
import { HTTP_STATUS_FORBIDDEN, HTTP_STATUS_NETWORK_FAILURE, HTTP_STATUS_NOTFOUND } from "utils/HttpStatuses";
import { TENANCY_NONE_FOUND } from "utils/Tenancy";

import { FetchError, fetchWithRetry } from "./fetch";
import { isWaitingForNetworkConnectivity, waitForNetworkConnectivity } from "./offlineHandler";

interface FetchOptions extends RequestInit {
  headers: Record<string, string>;
}

interface RequestOptions {
  responseType?: "blob";
  returnHeaders?: boolean;
}

type FetchStructureArgs = [
  method: string,
  auth: Auth0ContextInterface | null,
  url: string,
  tenancyId?: string | null,
  data?: any | null,
  requestOptions?: RequestOptions,
  requestHeaders?: Record<string, string>
];

export interface FetchResult<R> {
  headers: Headers;
  data: R;
}

function formatResult(options: RequestOptions, response: Response, data: any): any | FetchResult<any> {
  return options.returnHeaders ? { headers: response.headers, data } : data;
}

// Debounce the Auth0 error handling because if multiple requests are executed at the same time,
// if there's an Auth0 error, all the requests will likely return the same error at the same time.
const handleAuth0Error = debounce(
  (auth: Auth0ContextInterface, error: unknown): void => {
    const errorMessage = error instanceof Error ? error.message : "Unknown error";
    // Call logout when there's an Auth0 error clear out any bad state the user might have
    logger.info(`Auth0 fetch error: ${errorMessage}`, { error }, error instanceof Error ? error : undefined);

    loginAgain(auth);
  },
  dayjs.duration({ seconds: 0.5 }).asMilliseconds(),
  { leading: true, trailing: false }
);

/**
 * fetchStructure will handle all our interactions with the API. Including different response statuses for errors:
 *
 * 204: returns an empty object
 * 500: Redirects straight to the error page at /a/error
 * 403: Rejects the promise with an error of "Forbidden"
 * 404: Rejects the promise with an error of "NotFound"
 * 400: Rejects the promise so the sagas will pick up the error and throw the failure action
 * 200: All good return the json response
 */
async function fetchStructure<R>(...args: FetchStructureArgs): Promise<R> {
  const [method, auth, url, tenancyId, data, requestOptions = {}, requestHeaders = {}] = args;

  // If we're currently waiting for network connectivity, wait instead of trying to make the request
  if (isWaitingForNetworkConnectivity()) {
    await waitForNetworkConnectivity();
  }

  const options: FetchOptions = {
    method,
    headers: {
      ...requestHeaders
    }
  };

  if (tenancyId && tenancyId !== TENANCY_NONE_FOUND) {
    options.headers["X-Tenant-Id"] = tenancyId;
  }

  if (!isNullOrUndefined(data)) {
    if (data instanceof FormData) {
      options.body = data;
    } else {
      options.headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(data);
    }
  }

  let response;
  try {
    if (auth) {
      try {
        const accessToken = await auth.getAccessTokenSilently();
        options.headers.Authorization = `Bearer ${accessToken}`;
      } catch (error: unknown) {
        // Catch network errors so they can trigger the offline handling.
        // Auth0 network errors aren't being retried here because it appears that the Auth0 SDK already does 3 retries before it throws.
        if (error instanceof TypeError) {
          const err = new FetchError("Network error fetching Auth0 access token");
          err.code = HTTP_STATUS_NETWORK_FAILURE;
          err.url = url;
          err.method = options?.method;
          throw err;
        }

        handleAuth0Error(auth, error);

        // Return a promise that never resolves so the request doesn't execute
        // but any loaders continue to show while the login redirect happens
        return new Promise(() => {});
      }
    }

    response = await fetchWithRetry(url, options);
  } catch (e) {
    const error = e as FetchError;
    // If a network error occurs, wait for network connectivity and then retry the request
    if (error.code === HTTP_STATUS_NETWORK_FAILURE) {
      await waitForNetworkConnectivity();
      return fetchStructure(...args);
    }
    throw error;
  }

  if (response && response.ok) {
    const contentType = response.headers.get("content-type");

    if (response.status === 204) {
      return {} as R;
    }

    if (requestOptions.responseType === "blob") {
      return formatResult(requestOptions, response, await response.blob());
    }

    if (contentType && contentType.includes("application/json")) {
      return formatResult(requestOptions, response, await response.json());
    }
    return formatResult(requestOptions, response, await response.text());
  }

  // Forbidden response
  if (response.status === 403) {
    const error = new FetchError(HTTP_STATUS_FORBIDDEN);
    error.url = url;
    error.method = method;
    error.status = response.status;
    error.statusText = response.statusText;
    return Promise.reject(error);
  }

  if (response.status === 404) {
    const error = new FetchError(HTTP_STATUS_NOTFOUND);
    error.url = url;
    error.method = method;
    error.status = response.status;
    error.statusText = response.statusText;
    return Promise.reject(error);
  }

  // Unauthorized, user needs to login or provide a new token
  if (auth && response.status === 401) {
    handleAuth0Error(auth, new Error("HTTP 401 (Unauthorized)"));
    // Return a promise that never resolves so no errors are displayed to the user and
    // any loaders continue to show while the login redirect happens
    return new Promise(() => {});
  }

  if (response.status === 400) {
    return Promise.reject(formatResult(requestOptions, response, await response.json()));
  }

  const error = new FetchError(`HTTP ${response.status} ${response.statusText}`);
  error.url = url;
  error.method = method;
  error.status = response.status;
  error.statusText = response.statusText;

  throw error;
}

// Authenticated fetch methods
// -----------------------------------------------------------------------------

export async function fetchDataFromApiWrapper<R>(
  auth: Auth0ContextInterface | null,
  url: string,
  tenancyId = "",
  requestOptions: RequestOptions = {},
  requestHeaders: Record<string, string> = {}
): Promise<R> {
  return fetchStructure<R>("GET", auth, url, tenancyId, null, requestOptions, requestHeaders);
}

export async function fetchBlobDataFromApiWrapper(
  auth: Auth0ContextInterface,
  url: string,
  tenancyId = ""
): Promise<Blob> {
  return fetchStructure("GET", auth, url, tenancyId, null, {
    responseType: "blob"
  });
}

export async function postDataToApiWrapper<R>(
  auth: Auth0ContextInterface,
  url: string,
  tenancyId = "",
  data?: any,
  requestOptions: RequestOptions = {},
  requestHeaders: Record<string, string> = {}
): Promise<R> {
  return fetchStructure<R>("POST", auth, url, tenancyId, data, requestOptions, requestHeaders);
}

export async function putDataToApiWrapper<R>(
  auth: Auth0ContextInterface,
  url: string,
  tenancyId: string,
  data?: any,
  requestOptions: RequestOptions = {},
  requestHeaders: Record<string, string> = {}
): Promise<R> {
  return fetchStructure("PUT", auth, url, tenancyId, data, requestOptions, requestHeaders);
}

export async function deleteDataFromApiWrapper<R>(
  auth: Auth0ContextInterface,
  url: string,
  tenancyId: string,
  data: any = {},
  requestOptions: RequestOptions = {},
  requestHeaders: Record<string, string> = {}
): Promise<R> {
  return fetchStructure<R>("DELETE", auth, url, tenancyId, data, requestOptions, requestHeaders);
}

// Unauthenticated fetch methods
// -----------------------------------------------------------------------------

export async function fetchUnauthenticatedDataFromApiWrapper<R>(url: string): Promise<R> {
  return fetchStructure("GET", null, url);
}

export async function fetchUnauthenticatedBlobDataFromApiWrapper(url: string): Promise<Blob> {
  return fetchStructure("GET", null, url, null, null, {
    responseType: "blob"
  });
}

export async function postUnauthenticatedDataToApiWrapper<R>(
  url: string,
  data?: any,
  requestOptions: RequestOptions = {},
  requestHeaders: Record<string, string> = {}
): Promise<R> {
  return fetchStructure("POST", null, url, null, data, requestOptions, requestHeaders);
}
