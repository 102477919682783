import { useAuth0 } from "@auth0/auth0-react";
import { useDebounce } from "react-use";

import { VERSION } from "config";
import { update } from "utils/Intercom";

import useAppSelector from "./useAppSelector";

/**
 * Add extra context to Intercom for companyId, user's data.
 */
export default function useExtraIntercomSettings(): void {
  const { user, isAuthenticated } = useAuth0();
  const { companyId: tenancyId, tradingName, isBuyer, isSupplier, isClaimed } = useAppSelector(state => state.tenancy);
  const { userId, firstName, lastName } = useAppSelector(state => state.userProfile);
  const { token } = useAppSelector(state => state.pushNotifications);

  useDebounce(
    () => {
      if (isAuthenticated && user && userId && !window.Cypress) {
        const intercomData: Record<string, any> = {
          user_id: user.sub,
          name: firstName && lastName ? `${firstName} ${lastName}` : user.name,
          email: user.email,
          email_verified: user.email_verified,
          tenant: tenancyId,
          app_version: VERSION,
          user_hash: user["https://upstock.app/intercom/user_hash"],
          enabled_upstock_push_notifications: Boolean(token)
        };
        if (tenancyId && tradingName) {
          intercomData.company = {
            id: tenancyId,
            name: tradingName,
            isBuyer,
            isSupplier,
            isClaimed
          };
        }
        update(intercomData);
      }
    },
    5000,
    [firstName, isAuthenticated, isBuyer, isClaimed, isSupplier, lastName, tenancyId, tradingName, user, userId]
  );
}
