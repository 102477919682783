import MLink, { LinkProps as MLinkProps } from "@mui/material/Link";
import { ForwardedRef, forwardRef, JSX, ReactNode, RefObject } from "react";
import { Link as RLink, LinkProps as RLinkProps } from "react-router-dom";

import { logger } from "utils/Datadog";

export interface LocalLinkOrExternalLinkProps {
  children?: ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  download?: string;
  href?: string;
  rel?: string;
  target?: string;
  underline?: MLinkProps["underline"];
  to?: LocalLinkProps["to"];
}

/**
 * A react-router `<Link>` for local links (determined by the presence of a `to`
 * prop), or a Material UI `<Link>` for absolute URLs (`href` prop).
 */
const LocalLinkOrExternalLink = forwardRef<unknown, LocalLinkOrExternalLinkProps>(
  ({ href, to, ...props }, ref): JSX.Element | null => {
    if (to) {
      return <LocalLink ref={ref as RefObject<HTMLAnchorElement>} to={to} role="link" {...props} />;
    }
    if (href) {
      return <MLink ref={ref as ForwardedRef<HTMLAnchorElement>} href={href} component="a" role="link" {...props} />;
    }

    logger.error("Using LocalLinkOrExternalLink component with neither an `href` or `to` prop");
    return null;
  }
);

export default LocalLinkOrExternalLink;

export interface LocalLinkProps extends RLinkProps {
  href?: string | undefined;
}

/**
 * A react-router `<Link>`, the underlying local link component for
 * `<LocalLinkOrExternalLink>` and the component used for our MUI Link defined in the Theme.
 */
export const LocalLink = forwardRef<HTMLAnchorElement, LocalLinkProps>(({ to, href, ...props }, ref): JSX.Element => {
  return <RLink ref={ref} to={to || href || ""} {...props} />;
});
