import useInjectReducerAndSaga from "utils/hooks/useInjectReducerAndSaga";

import directoryReducer from "./reducers";
import directorySaga from "./sagas";

export default function useInjectDirectory(): boolean {
  return useInjectReducerAndSaga({
    key: "directory",
    reducer: directoryReducer,
    saga: directorySaga
  });
}
