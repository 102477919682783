import { ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { hideModalsRequest } from "redux/actions/hideModals";
import { ModalToHide } from "types/api/generated/directory-internal";

type Action<T> = (...args: any[]) => T;

interface DontShowAgainResult<T> {
  /**
   * The original `action` passed to this hook, but wrapped to make an API call
   * to hide the modal if `dontShowAgain` is `true`.
   */
  actionWithHideModalRequest: (...args: Parameters<Action<T>>) => T;

  /**
   * The 'do not show again' option state, used to drive the option in the UI
   * (eg: checkboxes).
   */
  dontShowAgain: boolean;

  /**
   * A change event handler to attach to a checkbox.
   */
  updateDontShowAgain: ChangeEventHandler<HTMLInputElement>;
}

/**
 * Common logic for modals that have a 'do not show this again' option.  This
 * hook wraps an existing action to also fire a request to the API to not show a
 * modal again if that option is selected.
 */
export default function useDontShowAgain<T>(modalToHide: ModalToHide, action: Action<T>): DontShowAgainResult<T> {
  const dispatch = useDispatch();
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const updateDontShowAgain = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(event.target.checked);
  }, []);

  const hideModal = useCallback(() => {
    dispatch(
      hideModalsRequest({
        modals: [modalToHide]
      })
    );
  }, [dispatch, modalToHide]);

  const actionWithHideModalRequest = useCallback(() => {
    if (dontShowAgain) {
      hideModal();
    }
    return action();
  }, [action, dontShowAgain, hideModal]);

  return useMemo(
    () => ({
      actionWithHideModalRequest,
      dontShowAgain,
      updateDontShowAgain
    }),
    [actionWithHideModalRequest, dontShowAgain, updateDontShowAgain]
  );
}
