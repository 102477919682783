import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { SoftwareKeyboardContext } from "mobile/SoftwareKeyboardDetector";
import { addMobileNav as addMobileNavAction, removeMobileNav as removeMobileNavAction } from "redux/reducers/app";
import useAppSelector from "utils/hooks/useAppSelector";

interface MobileNavResult {
  addMobileNav: () => void;
  mobileNavRendered: boolean;
  removeMobileNav: () => void;
}

/**
 * React hook for working with the mobile nav.
 *
 * @param displayNav
 *   Controls whether or not the mobile nav could potentially be shown on the
 *   page.  Some pages don't include navigation, like the new order flow, so
 *   setting this to `false` can be used to hide the nav even if other criteria
 *   would normally have it available.
 */
export default function useMobileNav(displayNav?: boolean): MobileNavResult {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { mobileNavRendered } = useAppSelector(state => state.app);

  const addMobileNav = useCallback(() => {
    dispatch(addMobileNavAction());
  }, [dispatch]);

  const removeMobileNav = useCallback(() => {
    dispatch(removeMobileNavAction());
  }, [dispatch]);

  const softwareKeyboardVisible = useContext(SoftwareKeyboardContext);
  const shouldRenderMobileNav = isSmDown && displayNav && !softwareKeyboardVisible;

  useEffect(() => {
    if (displayNav !== undefined) {
      if (shouldRenderMobileNav && !mobileNavRendered) {
        addMobileNav();
      } else if (!shouldRenderMobileNav && mobileNavRendered) {
        removeMobileNav();
      }
    }
  }, [addMobileNav, dispatch, displayNav, mobileNavRendered, removeMobileNav, shouldRenderMobileNav]);

  return useMemo(
    () => ({
      addMobileNav,
      mobileNavRendered,
      removeMobileNav
    }),
    [addMobileNav, mobileNavRendered, removeMobileNav]
  );
}
