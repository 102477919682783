import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useShallowCompareEffect } from "react-use";

import { fetch } from "redux/reducers/features";
import { isUnauthenticatedRoute } from "routes/Routes";

import useAppSelector from "./useAppSelector";
import useFeatures from "./useFeatures";

type FeatureResult = [boolean, boolean];

/**
 * React hook for doing a lookup and fetch of feature flags stored in redux.
 * The default value ("Off"/`false`) is returned immediately for the features,
 * with the real value being returned eventually.  If the feature(s) have
 * already been fetched for the given key, then the value is served from redux
 * and no request to the API is made.
 *
 * @param key
 *   The key/scope of the feature.
 * @param features
 *   The feature or list of feature names to retrieve.
 * @return
 *   An array of boolean pairs (so a 2D array), with each pair representing the
 *   features in the order they were requested in.  The inner arrays contain 2
 *   values: the state of the feature flag right now (`true` if the value is
 *   "On"), and whether we are loading or yet to load the feature flag state
 *   from the API.
 */
export default function useFetchFeatures(key: string | null | undefined, ...features: string[]): FeatureResult[] {
  const { pathname } = useLocation();
  const { isLoading: authLoading } = useAuth0();
  const allFeatures = useAppSelector(state => state.features);
  // @ts-ignore Indexing into objects is hard
  const featuresToFetch = features.filter(feature => !allFeatures[key]?.[feature]);

  // For any features that haven't yet been fetched from split.io, make a
  // request for them
  const dispatch = useDispatch();
  useShallowCompareEffect(() => {
    if (!authLoading && featuresToFetch.length) {
      // Only check for features if we have a key, or the key is null and we're
      // on an unauthenticated route
      if (key || (!key && isUnauthenticatedRoute(pathname))) {
        dispatch(
          fetch({
            key,
            features: featuresToFetch
          })
        );
      }
    }
  }, [dispatch, key, authLoading, featuresToFetch, pathname]);

  const featureResults = useFeatures(key, ...features);
  return featureResults.map((featureResult, index) => {
    // @ts-ignore Indexing into objects is hard
    const featureValue = allFeatures[key]?.[features[index]!];
    return [featureResult, !featureValue || featureValue === "Loading"];
  });
}
