import { FunctionComponent } from "react";
import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => {
  const box = keyframes`
    0% {
      transform: translate(-45px, 0)
    },
    /* 200ms */
    11.11% {
      transform: translate(0, 0)
    },
    /* 1600ms */
    88.88% {
      transform: translate(0, 0)
    },
    100% {
      transform: translate(45px, 0)
    }
  `;

  const bottle1 = keyframes`
    0% {
      transform: translate(0, -41px)
    },
    /* 400ms */
    22.22% {
      transform: translate(0, 0)
    },
    /* 1600ms */
    88.88% {
      transform: translate(0, 0)
    },
    100% {
      transform: translate(45px, 0)
    }
  `;

  const bottle2 = keyframes`
    0% {
      transform: translate(0, -41px)
    },
    /* 300ms */
    16.66% {
      transform: translate(0, -41px)
    },
    /* 500ms */
    27.77% {
      transform: translate(0, 0)
    },
    /* 1600ms */
    88.88% {
      transform: translate(0, 0)
    },
    100% {
      transform: translate(45px, 0)
    }
  `;

  const bottle3 = keyframes`
    0% {
      transform: translate(0, -41px)
    },
    /* 400ms */
    22.22% {
      transform: translate(0, -41px)
    },
    /* 600ms */
    33.33% {
      transform: translate(0, 0)
    },
    /* 1600ms */
    88.88% {
      transform: translate(0, 0)
    },
    100% {
      transform: translate(45px, 0)
    }
  `;

  const cap3 = keyframes`
    0% {
      transform: translate(0, -41px)
    },
    /* 500ms */
    27.77% {
      transform: translate(0, -41px)
    },
    /* 700ms */
    38.88% {
      transform: translate(0, 0)
    },
    /* 1600ms */
    88.88% {
      transform: translate(0, 0)
    },
    100% {
      transform: translate(45px, 0)
    }
  `;

  return {
    box: {
      transformOrigin: "0 0",
      animation: `${box} 1800ms 0ms ease-out infinite`
    },
    bottle1: {
      transformOrigin: "0 0",
      animation: `${bottle1} 1800ms 0ms ease-out infinite`
    },
    bottle2: {
      transformOrigin: "0 0",
      animation: `${bottle2} 1800ms 0ms ease-out infinite`
    },
    bottle3: {
      transformOrigin: "0 0",
      animation: `${bottle3} 1800ms 0ms ease-out infinite`
    },
    cap1: {
      transformOrigin: "0 0",
      animation: `${bottle2} 1800ms 0ms ease-out infinite`
    },
    cap2: {
      transformOrigin: "0 0",
      animation: `${bottle3} 1800ms 0ms ease-out infinite`
    },
    cap3: {
      transformOrigin: "0 0",
      animation: `${cap3} 1800ms 0ms ease-out infinite`
    }
  };
});

/**
 * The box + bottles loading animation 😊
 */
const Loader: FunctionComponent = () => {
  const { classes, cx } = useStyles();

  return (
    <svg
      data-testid="loader-animation"
      width="56"
      height="56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="bottle"
          d="M20.8 27c0 2.8-.2 8.7 0 9.7.1.5.3 1.6.2 2.6-.1 1-1 1.7-1.9 1.7h-4.2c-1 0-1.8-.7-1.9-1.7l.2-2.6c.2-1 0-7 0-9.7 0-2.8 1.7-4.6 1.9-5.2a49.6 49.6 0 00.3-6.5c0-.2.1-.3.3-.3h2.6c.2 0 .3.1.3.3a305.8 305.8 0 00.3 6.5c.2.6 1.9 2.4 1.9 5.2z"
          fill="#fff"
        />
        <path id="cap" d="M15.5 13.5c0-.3.2-.5.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5z" fill="#fff" />
      </defs>
      <mask id="box-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
        <circle cx="28" cy="28" r="28" fill="#FD554A" />
      </mask>
      <mask id="bottles-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="26">
        <path d="M0 26A26 26 0 0126 0h4a26 26 0 0126 26H0z" fill="#FD554A" />
      </mask>
      <g mask="url(#box-mask)">
        <circle cx="28" cy="28" r="28" fill="#FD554A" />
        <path
          className={cx(classes.box)}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45 28H11v11c0 1.1.9 2 2 2h30a2 2 0 002-2V28zm-21 3.2a1 1 0 100 2h8.2a1 1 0 000-2H24z"
          fill="#fff"
        />
      </g>
      <g mask="url(#bottles-mask)">
        <use x="0" y="0" xlinkHref="#bottle" className={cx(classes.bottle1)} />
        <use x="11" y="0" xlinkHref="#bottle" className={cx(classes.bottle2)} />
        <use x="22" y="0" xlinkHref="#bottle" className={cx(classes.bottle3)} />
        <use x="0" y="0" xlinkHref="#cap" className={cx(classes.cap1)} />
        <use x="11" y="0" xlinkHref="#cap" className={cx(classes.cap2)} />
        <use x="22" y="0" xlinkHref="#cap" className={cx(classes.cap3)} />
      </g>
    </svg>
  );
};

export default Loader;
