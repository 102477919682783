import { FunctionComponent } from "react";

import { LocalOrderLineProblem } from "types/Orders";

import LineProblemChip, { LINE_PROBLEM_MULTIPLE } from "./LineProblemChip";
import LineProblemTooltip from "./LineProblemTooltip";

export interface LineProblemChipTooltipWrapperProps {
  className?: string;
  problems: LocalOrderLineProblem[];
  productId?: string;
}

/**
 * A wrapper around line item chips with tooltips, deciding if a singular or multiple chip warning should show
 */
const LineProblemChipTooltipWrapper: FunctionComponent<LineProblemChipTooltipWrapperProps> = ({
  problems,
  className,
  productId
}) => {
  return problems.length > 0 ? (
    <LineProblemTooltip problems={problems} className={className} productId={productId}>
      {problems.length > 1 ? (
        <LineProblemChip type={LINE_PROBLEM_MULTIPLE} problemsCount={problems.length} />
      ) : (
        // There will always be at least one item in the array here
        <LineProblemChip type={problems[0]!.type} />
      )}
    </LineProblemTooltip>
  ) : null;
};

export default LineProblemChipTooltipWrapper;
