import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import Header from "components/Header";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import useAppSelector from "utils/hooks/useAppSelector";

import strings from "./NoAccessOverlay.strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: "center"
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  homeButton: {
    marginTop: theme.spacing(3)
  }
}));

const NoAccessOveraly: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { tradingName: companyName } = useAppSelector(state => state.tenancy);

  return (
    <Page containerClassName={cx(classes.container)} containerMaxWidth="xs" header={<Header />} displayNav>
      <LogoIcon className={cx(classes.icon)} />

      <Typography className={cx(classes.title)} variant="h1">
        <FormattedMessage id={messages.TITLE} />
      </Typography>

      <Typography paragraph>
        <FormattedMessage id={messages.BODY} values={{ companyName }} />
      </Typography>

      <Typography>
        <FormattedMessage id={messages.CONTACT_US} />
      </Typography>

      <Button className={cx(classes.homeButton)} component="a" variant="contained" color="primary" href="/">
        <FormattedMessage id={messages.HOME_BUTTON} />
      </Button>
    </Page>
  );
};

export default NoAccessOveraly;
