import MTooltip, { TooltipProps as MTooltipProps } from "@mui/material/Tooltip";
import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

const useTooltipStyles = makeStyles()(theme => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(2),
    maxWidth: "268px", // account for the 32px padding to make it 300px.
    // Copied from theme.shadow[3] because the box-shadow and filter:drop-shadow() syntaxes aren't compatible
    filter:
      "drop-shadow(0px 3px 3px rgba(0,0,0,0.2)) drop-shadow(0px 3px 4px rgba(0,0,0,0.14)) drop-shadow(0px 1px 8px rgba(0,0,0,0.12))"
  }
}));

const useStyles = makeStyles()(() => ({
  inline: {
    display: "inline-block"
  }
}));

export interface TooltipProps extends MTooltipProps {
  children: ReactElement;
  title: NonNullable<ReactNode>;
  className?: string;
  disabled?: boolean;
  isInline?: boolean;
  ["data-testid"]?: string;
}

const Tooltip = React.forwardRef<unknown, TooltipProps>((props, ref) => {
  const { children, title, className, disabled, isInline, ...rest } = props;
  const { classes: tooltipClasses, cx } = useTooltipStyles();
  const { classes } = useStyles();

  if (disabled) {
    // Add the wrapper even when disabled for layout consistency
    return (
      <div
        data-tooltip={!disabled}
        className={cx(className, { [classes.inline]: isInline })}
        data-testid={rest["data-testid"]}
      >
        {React.cloneElement(children, { ref })}
      </div>
    );
  }

  return (
    <MTooltip classes={tooltipClasses} title={title} enterTouchDelay={25} leaveTouchDelay={3000} arrow {...rest}>
      {/* Add a wrapper so that the Tooltip works on disabled elements (material-ui adds pointer-events:none to all disabled elements) */}
      <div
        // Add a simple data atribute to help with debugging
        data-tooltip={!disabled}
        className={cx(className, { [classes.inline]: isInline })}
      >
        {React.cloneElement(children, { ref })}
      </div>
    </MTooltip>
  );
});

export default Tooltip;
