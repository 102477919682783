import { CompanyAddress, AddressType, Alpha2Code } from "types/api/generated/directory";
import { DeliveryAddress } from "types/api/generated/supplier";

import { joinIfPresent } from "./string";

export const getDefaultAddress = (addresses: CompanyAddress[], type: AddressType): CompanyAddress | undefined => {
  return addresses.find(address => address.type === type && address.isDefault);
};

// TODO: Eventually we should pass in a schema so that we can keep this aligned with the API formatter: https://github.com/UpstockApp/api/blob/0201ace60d414598c81d1157966156a52c4a34d0/src/Api.Domain/Extensions/AddressExtensions.cs#L97
export const formatAddress = (
  address: DeliveryAddress | CompanyAddress,
  formatCountryCode: Alpha2Code | null,
  separator: string = "\n"
): string => {
  const parts =
    formatCountryCode === Alpha2Code.AU
      ? [
          address.attention,
          address.address1,
          address.address2,
          address.city,
          joinIfPresent([address.zone, address.postalCode], " ")
        ]
      : [
          address.attention,
          address.address1,
          address.address2,
          joinIfPresent([address.city, address.postalCode], " "),
          address.zone
        ];

  return joinIfPresent(parts, separator);
};
