import { Auth0Provider as Provider } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";

import { getAuth0ClientId } from "auth/auth0";
import { Auth0Callback } from "routes/Routes";
import { IOS } from "utils/MobilePlatforms";
import { buildSearchString } from "utils/urls";

import Auth0ContextWrapper from "./Auth0ContextWrapper";
import Auth0CustomCache from "./Auth0CustomCache";
import Auth0ReduxSagaBridge from "./Auth0ReduxSagaBridge";
import UpstockUserProvider from "./UpstockUserProvider";

const platform = Capacitor.getPlatform();

const origin = platform === "web" || platform === IOS ? window.location.origin : `${process.env.REACT_APP_BUNDLE_ID}:/`;

interface Props {
  children: JSX.Element;
}

/**
 * The entry point for all of our auth0 configuration and customizations.
 */
export default function Auth0Provider({ children }: Props): JSX.Element {
  const history = useHistory();

  return (
    <Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={getAuth0ClientId()}
      cacheLocation={platform === "web" ? "localstorage" : undefined}
      cache={platform !== "web" ? Auth0CustomCache : undefined}
      onRedirectCallback={appState =>
        history.replace(appState?.returnTo || `/${buildSearchString({ referral: appState?.referral })}`)
      }
      skipRedirectCallback={
        window.location.pathname.startsWith("/a/connect") ||
        window.location.pathname.startsWith("/u/signup/referral/xero")
      }
      useRefreshTokens
      // Turning this on as this change in v2 upgrade means retry error previously handled in an iframe by default, are being surfaced to us but we want the retries to continue.
      // https://github.com/auth0/auth0-react/blob/master/MIGRATION_GUIDE.md#no-more-iframe-fallback-by-default-when-using-refresh-tokens
      // The refresh token fallback doesn't work in the native apps and causes timeout errors.
      useRefreshTokensFallback={platform === "web"}
      authorizationParams={{
        redirect_uri: origin + Auth0Callback.path,
        audience: process.env.REACT_APP_AUTH_AUDIENCE
      }}
    >
      <Auth0ContextWrapper>
        <Auth0ReduxSagaBridge>
          <UpstockUserProvider>{children}</UpstockUserProvider>
        </Auth0ReduxSagaBridge>
      </Auth0ContextWrapper>
    </Provider>
  );
}
