import { JSX } from "react";
import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  dots: {
    display: "flex"
  },
  dot: {
    animationName: keyframes`
      0% {
        transform: scale(1)
      }
      25% {
        transform: scale(1.5)
      }
      50% {
        transform: scale(1)
      }
      100% {
        transform: scale(1)
      }
    `,
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-out",
    transformOrigin: "center",
    backgroundColor: "#666",
    width: "8px",
    height: "8px",
    borderRadius: "4px"
  },
  dot2: {
    marginLeft: "8px",
    marginRight: "8px",
    animationDelay: "150ms"
  },
  dot3: {
    animationDelay: "300ms"
  }
}));

/**
 * 3-pulsing dots shown as a loading indicator in some places.
 */
export default function LoadingDots(): JSX.Element {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.dots}>
      <span className={cx(classes.dot)} />
      <span className={cx(classes.dot, classes.dot2)} />
      <span className={cx(classes.dot, classes.dot3)} />
    </div>
  );
}
