import { useAuth0 } from "@auth0/auth0-react";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { JSX, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";

import Auth0CallbackPage from "auth/Auth0CallbackPage";
import AuthenticatedRoute from "auth/AuthenticatedRoute";
import GlobalSnackbar from "components/GlobalSnackbar";
import TermsConditionsModal from "components/TermsConditionsModal";
import UserChangedModal from "components/UserChangedModal";
import useAndroidStatusBar from "native/useAndroidStatusBar";
import useDeepLinks from "native/useDeepLinks";
import useIosInputFocuser from "native/useIosInputFocuser";
import useIosKeyboard from "native/useIosKeyboard";
import usePushNotificationListeners from "native/usePushNotificationListeners";
import AcceptClaimPage from "pages/AcceptClaimPage";
import ClaimCompanyPage from "pages/ClaimCompanyPage";
import ClaimedRedirectPage from "pages/ClaimedRedirectPage";
import ErrorPage from "pages/Error";
import ExistingAccountPage from "pages/ExistingAccountPage";
import LoadingScreen from "pages/LoadingScreen";
import LoadingScreenUnbranded from "pages/LoadingScreenUnbranded";
import LogoutPage from "pages/LogoutPage";
import MobileWelcome from "pages/MobileWelcome";
import NoCompanyAccess from "pages/NoAccessCompany";
import PageNotFound from "pages/PageNotFound";
import PendingInvite from "pages/PendingInvite";
import LoggedRedirect from "routes/LoggedRedirect";
import {
  AcceptClaim,
  AcceptClaimExternal,
  AcceptInviteExternal,
  Auth0Callback as Auth0CallbackRoute,
  AuthenticatedError,
  AuthenticatedOnboarding,
  BusinessProfile,
  BusinessProfileExternal,
  BuyerDirectoryOldExternal,
  BuyerFavouritesExternal,
  BuyerFavouritesLists,
  BuyerNewOrder,
  BuyerNewOrderExternal,
  BuyerNewsfeed,
  BuyerNewsfeedExternal,
  BuyerOrders,
  BuyerOrdersExternal,
  BuyerPaymentManagement,
  BuyerPaymentManagementExternal,
  BuyerSupplier,
  BuyerSupplierExternal,
  BuyerSuppliers,
  BuyerSuppliersExternal,
  BuyerViewOrder,
  BuyerViewOrderExternal,
  ClaimCompany,
  ClaimCompanyExternal,
  ClaimedRedirect,
  ClaimedRedirectExternal,
  CompanyRedirectRoute,
  CompanyRoot,
  ConfirmEmail as ConfirmEmailRoute,
  ConfirmEmailError as ConfirmEmailErrorRoute,
  ConfirmEmailExternal,
  ConnectCodatCallback,
  ConnectIdentityCallbackRoute,
  ConnectStripeCallback,
  ConnectXeroCallback,
  ExistingAccount,
  GenericCompanyNewOrder,
  isUnbrandedRoute,
  Logout,
  MobileWelcome as MobileWelcomeRoute,
  NewUserRoute,
  NoCompanyAccess as NoCompanyAccessRoute,
  Notifications,
  NotificationsExternal,
  OnboardingChooseInviteUser,
  OnboardingRoot,
  PendingInvite as PendingInviteRoute,
  PlansPricingExternal,
  Signup,
  SignupExternal,
  SignupExternalQrCode,
  SignupReferral,
  StoreBuyerNewOrderCallback,
  SupplierCustomers,
  SupplierCustomersExternal,
  SupplierDeliveryGroupsExternal,
  SupplierIntegrationsExternal,
  SupplierIntegrationsSettings,
  SupplierNewOrder,
  SupplierNewOrderExternal,
  SupplierNewsfeed,
  SupplierNewsfeedExternal,
  SupplierOrderSettings,
  SupplierOrderSettingsExternal,
  SupplierOrdersExternal,
  SupplierOrdersGridLayout,
  SupplierPendingInvitesExternal,
  SupplierPriceLists,
  SupplierPriceListsExternal,
  SupplierProducts,
  SupplierProductsExternal,
  SupplierReports,
  SupplierReportsExternal,
  SupplierReportsProductionListExternal,
  SupplierReportsProductionReport,
  SupplierViewOrder,
  SupplierViewOrderExternal,
  SupplierViewOrderXeroRedirect,
  UnauthenticatedError,
  UnauthenticatedOrder,
  UnauthenticatedOrderExternal,
  UnauthenticatedOrderPaymentConfirmation,
  UnauthenticatedOrderPrintInvoice,
  UnauthenticatedOrderPrintInvoiceExternal,
  UnauthenticatedOrderQrCodeExternal,
  Users,
  UsersExternal,
  UsersExternalSettings,
  SupplierDeliveryGroups
} from "routes/Routes";
import TrackedRoute from "routes/TrackedRoute";
import OfflineHandler from "services/offlineHandler";
import { setHasPageViewBeenTracked } from "utils/analytics";
import { emailFlags } from "utils/flags";
import useDataDog from "utils/hooks/useDataDog";
import useFetchFeatures from "utils/hooks/useFetchFeatures";
import useScript from "utils/hooks/useScript";
import { startCheckingForUpdates, UpdateOnNavigation } from "utils/updateChecker";

import CompanyRedirect from "./routes/CompanyRedirect";
import CompanyRouter from "./routes/CompanyRouter";
import ExternalRedirect from "./routes/ExternalRedirect";
import RedirectToNewOrder from "./routes/RedirectToNewOrder";
import RedirectToPlansPricing from "./routes/RedirectToPlansPricing";
import RootRedirect from "./routes/RootRedirect";
import lazyComponent from "./services/lazyComponent";

const BuyerNewOrderFeature = lazyComponent(() => import("features/buyers/orders/new-order/NewOrderFeature"));
const EmailConfirmationFeature = lazyComponent(() => import("features/email-confirmation/EmailConfirmationFeature"));
const InvitesFeature = lazyComponent(() => import("features/invites/InvitesFeature"));
const IntegrationsFeature = lazyComponent(() => import("features/suppliers/integrations/IntegrationsFeature"));
const PaymentsFeature = lazyComponent(() => import("features/suppliers/payments/PaymentsFeature"));
const PersonalProfileFeature = lazyComponent(() => import("features/settings/personal-profile/PersonalProfileFeature"));
const SignupFeature = lazyComponent(() => import("features/signup/SignUpFeature"));
const UnauthenticatedViewOrderFeature = lazyComponent(
  () => import("features/unauthenticated/view-order/UnauthenticatedViewOrderFeature")
);
const SignupReferralFeature = lazyComponent(() => import("features/unauthenticated/referral/SignupReferralFeature"));

startCheckingForUpdates();

export default function App(): JSX.Element {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth0();

  // Injects the Statuspage widget into the page.
  useScript(
    // Disable in the unit tests and Cypress
    process.env.NODE_ENV === "test" || window.Cypress ? null : "https://status.upstock.app/embed/script.js",
    { async: true }
  );

  // Don't include routes such as login, sign, error and onboarding.
  const currentlyOnUnbrandedRoute = isUnbrandedRoute(pathname);

  // Ask for all features relating to user email keys.
  useFetchFeatures(user?.email ?? null, ...emailFlags);

  // General setup
  useDataDog();

  // Native app setup
  useDeepLinks();
  useIosInputFocuser();
  useIosKeyboard();
  usePushNotificationListeners();
  useAndroidStatusBar();

  // Reset page tracking var only on path changes, used by <TrackedRoute/>
  useEffect(() => {
    return history.listen((location, _action) => {
      if (location.pathname !== pathname) {
        setHasPageViewBeenTracked(false);
      }
    });
  }, [history, pathname]);

  // Scroll to the top of the page on path changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Suspense fallback={currentlyOnUnbrandedRoute ? <LoadingScreenUnbranded /> : <LoadingScreen />}>
        <UpdateOnNavigation>
          <Switch>
            <TrackedRoute path={Auth0CallbackRoute.path} component={Auth0CallbackPage} />
            <Redirect exact from="/u" to={UnauthenticatedError.path} />
            <TrackedRoute path={Logout.path} component={LogoutPage} />
            <TrackedRoute path={UnauthenticatedError.path} component={ErrorPage} />
            <TrackedRoute path={MobileWelcomeRoute.path} component={MobileWelcome} />
            <TrackedRoute path={SignupReferral.path} component={SignupReferralFeature} />
            <TrackedRoute path={[AuthenticatedOnboarding.path, OnboardingRoot.path]} component={InvitesFeature} />
            <TrackedRoute path={Signup.path} component={SignupFeature} />
            <TrackedRoute
              exact
              path={[
                UnauthenticatedOrder.path,
                UnauthenticatedOrderPrintInvoice.path,
                UnauthenticatedOrderPaymentConfirmation.path
              ]}
              component={UnauthenticatedViewOrderFeature}
            />
            <Redirect exact from="/a" to="/" />
            <TrackedRoute path={AuthenticatedError.path} component={ErrorPage} />
            <TrackedRoute path={NoCompanyAccessRoute.path} component={NoCompanyAccess} />
            <LoggedRedirect from={NewUserRoute.path} to={Signup.path} />
            <AuthenticatedRoute path={ExistingAccount.path} component={ExistingAccountPage} />
            <AuthenticatedRoute path={PendingInviteRoute.path} component={PendingInvite} />
            <AuthenticatedRoute path={ClaimedRedirect.path} component={ClaimedRedirectPage} />
            <AuthenticatedRoute
              path={AcceptClaim.path}
              component={AcceptClaimPage}
              loginOptions={{
                authorizationParams: {
                  screen_hint: "signup"
                }
              }}
            />
            <AuthenticatedRoute
              path={ClaimCompany.path}
              component={ClaimCompanyPage}
              loginOptions={{
                authorizationParams: {
                  screen_hint: "signup"
                }
              }}
            />
            <AuthenticatedRoute
              path={[ConfirmEmailRoute.path, ConfirmEmailErrorRoute.path]}
              component={EmailConfirmationFeature}
            />
            <AuthenticatedRoute path={ConnectXeroCallback.path} component={IntegrationsFeature} />
            <AuthenticatedRoute path={ConnectCodatCallback.path} component={IntegrationsFeature} />
            <AuthenticatedRoute path={ConnectIdentityCallbackRoute.path} component={PersonalProfileFeature} />
            <AuthenticatedRoute path={ConnectStripeCallback.path} component={PaymentsFeature} />
            <AuthenticatedRoute path={StoreBuyerNewOrderCallback.path} component={BuyerNewOrderFeature} />
            <AuthenticatedRoute exact path={GenericCompanyNewOrder.path} component={RedirectToNewOrder} />
            <AuthenticatedRoute path={PlansPricingExternal.path} component={RedirectToPlansPricing} />
            {/** This is to handle links back from Xero Invoices to our app */}
            <Redirect exact from={SupplierViewOrderXeroRedirect.path} to={SupplierViewOrder.path} />
            {/** Links available externally */}
            <ExternalRedirect from={NotificationsExternal.path} to={Notifications.path} />
            <ExternalRedirect from={UnauthenticatedOrderExternal.path} to={UnauthenticatedOrder.path} />
            <ExternalRedirect
              exact
              from={UnauthenticatedOrderQrCodeExternal.path}
              to={{
                pathname: UnauthenticatedOrderExternal.path,
                search: "?utm_source=packing-slip&utm_medium=qr&utm_campaign=:supplierId&utm_content=:viewId"
              }}
            />
            <ExternalRedirect
              from={UnauthenticatedOrderPrintInvoiceExternal.path}
              to={UnauthenticatedOrderPrintInvoice.path}
            />
            <ExternalRedirect from={SupplierNewOrderExternal.path} to={SupplierNewOrder.path} />
            <ExternalRedirect from={BuyerNewOrderExternal.path} to={BuyerNewOrder.path} />
            <ExternalRedirect exact from={SupplierViewOrderExternal.path} to={SupplierViewOrder.path} />
            <ExternalRedirect exact from={SupplierOrdersExternal.path} to={SupplierOrdersGridLayout.path} />
            <ExternalRedirect exact from={BuyerViewOrderExternal.path} to={BuyerViewOrder.path} />
            <ExternalRedirect exact from={BuyerOrdersExternal.path} to={BuyerOrders.path} />
            <ExternalRedirect from={ConfirmEmailExternal.path} to={ConfirmEmailRoute.path} />
            <ExternalRedirect
              from={SupplierPendingInvitesExternal.path}
              to={{
                pathname: SupplierCustomers.path,
                search: "?selectedTab=pending"
              }}
            />
            <ExternalRedirect from={BuyerDirectoryOldExternal.path} to={BuyerSuppliers.path} />
            <ExternalRedirect from={BuyerSuppliersExternal.path} to={BuyerSuppliers.path} />
            <ExternalRedirect from={BuyerSupplierExternal.path} to={BuyerSupplier.path} />
            <ExternalRedirect from={BuyerPaymentManagementExternal.path} to={BuyerPaymentManagement.path} />
            <ExternalRedirect from={AcceptInviteExternal.path} to={OnboardingChooseInviteUser.path} />
            <ExternalRedirect from={SupplierPriceListsExternal.path} to={SupplierPriceLists.path} />
            <ExternalRedirect from={UsersExternalSettings.path} to={Users.path} />
            <ExternalRedirect from={UsersExternal.path} to={Users.path} />
            <ExternalRedirect from={SupplierOrderSettingsExternal.path} to={SupplierOrderSettings.path} />
            <ExternalRedirect from={BusinessProfileExternal.path} to={BusinessProfile.path} />
            <ExternalRedirect from={BuyerFavouritesExternal.path} to={BuyerFavouritesLists.path} />
            <ExternalRedirect from={BuyerNewsfeedExternal.path} to={BuyerNewsfeed.path} />
            <ExternalRedirect from={SupplierDeliveryGroupsExternal.path} to={SupplierDeliveryGroups.path} />
            <ExternalRedirect from={SupplierNewsfeedExternal.path} to={SupplierNewsfeed.path} />
            <ExternalRedirect from={SupplierProductsExternal.path} to={SupplierProducts.path} />
            <ExternalRedirect from={SupplierCustomersExternal.path} to={SupplierCustomers.path} />
            <ExternalRedirect from={SignupExternal.path} to={Signup.path} />
            <ExternalRedirect
              from={SignupExternalQrCode.path}
              to={{
                pathname: SignupExternal.path,
                search: "?utm_source=packing-slip&utm_medium=qr"
              }}
            />
            <ExternalRedirect from={AcceptClaimExternal.path} to={AcceptClaim.path} />
            <ExternalRedirect from={ClaimCompanyExternal.path} to={ClaimCompany.path} />
            <ExternalRedirect from={ClaimedRedirectExternal.path} to={ClaimedRedirect.path} />
            <ExternalRedirect from={SupplierReportsExternal.path} to={SupplierReports.path} />
            <ExternalRedirect
              from={SupplierReportsProductionListExternal.path}
              to={SupplierReportsProductionReport.path}
            />
            <ExternalRedirect from={SupplierIntegrationsExternal.path} to={SupplierIntegrationsSettings.path} />

            <AuthenticatedRoute exact path="/" component={RootRedirect} />
            <AuthenticatedRoute exact path="/c" component={RootRedirect} />
            <AuthenticatedRoute path={CompanyRedirectRoute.path} component={CompanyRedirect} />
            <AuthenticatedRoute path={CompanyRoot.path} component={CompanyRouter} />
            <Route component={PageNotFound} />
          </Switch>
        </UpdateOnNavigation>
      </Suspense>
      <GlobalSnackbar />
      <OfflineHandler />
      <TermsConditionsModal />
      <UserChangedModal />
      {/* <ReactQueryDevtools buttonPosition="bottom-left" /> */}
    </>
  );
}
