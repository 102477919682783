import Container from "@mui/material/Container";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

import Header from "components/Header";
import Loader from "components/Loader";

const useStyles = makeStyles()(theme => ({
  page: {
    backgroundColor: theme.palette.grey["100"]
  },
  container: {
    alignItems: "center",
    display: "flex",
    height: "calc(100vh - 48px)",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)"
    }
  }
}));

const LoadingScreen: FunctionComponent = () => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.page)}>
      <Header />
      <Container className={cx(classes.container)} maxWidth="md" data-testid="loading-screen">
        <Loader />
      </Container>
    </div>
  );
};

export default LoadingScreen;
