import { ProviderFeatureAccess, ProviderOffering } from "types/api/generated/global-internal";
import useHasProviderPermissions from "utils/hooks/useHasProviderPermissions";

import useProviderConnection from "./useProviderConnection";

/**
 * Return whether or not we can perform a feature in the UI.  This is when:
 *  - the feature is managed in an external system and we have the given
 *    permission necessary for what we're inquiring about
 *  - or the feature is only managed in Upstock
 */
export default function useCanPerformFeature(feature: ProviderOffering, permission: ProviderFeatureAccess): boolean {
  const [provider] = useProviderConnection(null, feature, true);
  const canPerformWithProvider = useHasProviderPermissions(feature, permission);

  return provider ? canPerformWithProvider : true;
}
