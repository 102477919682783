import { useAuth0 } from "@auth0/auth0-react";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useMount, useUnmount } from "react-use";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import { PARAM_AUTH0SCREENHINT } from "features/invites/UrlSearchParams";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_white_alt.svg?react";
import { Signup } from "routes/Routes";
import { useAnalytics } from "utils/analytics";
import useUrlParams from "utils/hooks/useUrlParams";
import { ANDROID } from "utils/MobilePlatforms";
import { PARAM_FORWARD_PATH } from "utils/MobileUrlSearchParams";

import strings from "./MobileWelcome.strings.json";

const useStyles = makeStyles()(theme => ({
  page: {
    backgroundColor: theme.palette.secondary.main,
    height: "100vh",
    display: "flex",
    alignItems: "center"
  },
  container: {
    display: "flex",
    width: "100vw",
    flexDirection: "column",
    alignItems: "center"
  },
  logo: {
    transform: "scale(1.5)",
    marginBottom: theme.spacing(8)
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  loginButton: {
    borderRadius: 30,
    backgroundColor: "white"
  },
  signUpButton: {
    borderRadius: 30,
    marginTop: theme.spacing(2),
    borderColor: "white"
  },
  bottomText: {
    color: "white",
    position: "absolute",
    bottom: "8.55%",
    width: "60%",
    left: "20%",
    textAlign: "center",
    justifyContent: "center"
  }
}));

const MobileWelcome: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { loginWithRedirect, logout } = useAuth0();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const analytics = useAnalytics();
  const forwardPath = useUrlParams(PARAM_FORWARD_PATH);
  const auth0Hint = useUrlParams(PARAM_AUTH0SCREENHINT);

  function authorisePopup(hint: string, returnTo: string): void {
    analytics.track(`Mobile ${hint} Tapped`);

    loginWithRedirect({
      appState: {
        returnTo
      },
      authorizationParams: {
        screen_hint: hint
      },
      async openUrl(loginUrl) {
        // logout of any existing sessions (i.e., Chrome Custom Tabs shared sessions) then redirect to the login screen
        logout({
          logoutParams: {
            returnTo: loginUrl
          },
          async openUrl(logoutUrl) {
            await Browser.open({
              presentationStyle: "popover",
              url: logoutUrl
            });
          }
        });
      }
    });
  }

  useMount(() => {
    if (auth0Hint.getValue()) {
      authorisePopup(auth0Hint.getValue(), forwardPath.getValue()); // Automatically show the IAB if URL contains auth0ScreenHint
    }

    if (Capacitor.isPluginAvailable("StatusBar")) {
      if (Capacitor.getPlatform() === ANDROID) {
        StatusBar.setBackgroundColor({ color: theme.palette.secondary.main });
      }
    }
  });

  useUnmount(() => {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      if (Capacitor.getPlatform() === ANDROID) {
        StatusBar.setBackgroundColor({ color: theme.palette.primary.main });
      }
    }
  });

  return (
    <div className={cx(classes.page)}>
      <div className={cx(classes.container)}>
        <LogoIcon className={cx(classes.logo)} />
        <div className={cx(classes.buttonContainer)}>
          <Button
            variant="contained"
            onClick={() => authorisePopup("login", forwardPath.getValue())}
            className={classes.loginButton}
            data-testid="mobile-login-btn"
            size="large"
          >
            <Typography color="secondary" variant="h2">
              <FormattedMessage id={messages.LOGIN_BUTTON} />
            </Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() => authorisePopup("signup", Signup.path)}
            className={classes.signUpButton}
            data-testid="mobile-signup-btn"
            size="large"
          >
            <Typography color="white" variant="h2">
              <FormattedMessage id={messages.SIGN_UP_BUTTON} />
            </Typography>
          </Button>
        </div>
      </div>
      <Typography className={cx(classes.bottomText)} variant="h3">
        <FormattedMessage id={messages.PROMOTIONAL_TEXT} />
      </Typography>
    </div>
  );
};

export default MobileWelcome;
