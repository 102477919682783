import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreateWeeklyStandingOrderScheduleBody,
  ManageWeeklyStandingOrderProductRequest,
  StandingOrderSchedule,
  SupplierProductSummary,
  WeeklyStandingOrderProduct
} from "types/api/generated/supplier";
import { RequestAction } from "types/redux-helpers";

export interface StandingOrderProduct {
  product: SupplierProductSummary;
  monday: number | null;
  tuesday: number | null;
  wednesday: number | null;
  thursday: number | null;
  friday: number | null;
  saturday: number | null;
  sunday: number | null;
}

/**
 * Converts all the zero quantity values to null so that the inputs display as empty in the table.
 */
function removeZeroQuantities(product: WeeklyStandingOrderProduct): StandingOrderProduct {
  return {
    ...product,
    monday: product.monday === 0 ? null : product.monday,
    tuesday: product.tuesday === 0 ? null : product.tuesday,
    wednesday: product.wednesday === 0 ? null : product.wednesday,
    thursday: product.thursday === 0 ? null : product.thursday,
    friday: product.friday === 0 ? null : product.friday,
    saturday: product.saturday === 0 ? null : product.saturday,
    sunday: product.sunday === 0 ? null : product.sunday
  };
}

export interface StandingOrdersState {
  creatingStandingOrder: boolean;
  loadingProducts: boolean;
  updatingProducts: boolean;
  deletingProducts: boolean;
  updatingStatus: boolean;
  products: StandingOrderProduct[];
  error: unknown;
}

const initialState: StandingOrdersState = {
  creatingStandingOrder: false,
  loadingProducts: false,
  updatingProducts: false,
  deletingProducts: false,
  updatingStatus: false,
  products: [],
  error: null
};

export type CreateStandingOrderRequest = RequestAction<CreateWeeklyStandingOrderScheduleBody, StandingOrderSchedule>;
type CreateStandingOrderSuccess = PayloadAction<StandingOrderSchedule>;
export type FetchStandingOrderProductsRequest = RequestAction<
  { standingOrderId: string },
  WeeklyStandingOrderProduct[]
>;
type FetchStandingOrderProductsSuccess = PayloadAction<WeeklyStandingOrderProduct[]>;
interface UpdateStandingOrderProductsResponse {
  standingOrderId: string;
  products: WeeklyStandingOrderProduct[];
}
export type UpdateStandingOrderProductsRequest = RequestAction<
  { standingOrderId: string; products: ManageWeeklyStandingOrderProductRequest[] },
  UpdateStandingOrderProductsResponse
>;
type UpdateStandingOrderProductsSuccess = PayloadAction<UpdateStandingOrderProductsResponse>;
interface DeleteStandingOrderProductsResponse {
  standingOrderId: string;
  products: WeeklyStandingOrderProduct[];
}
export type DeleteStandingOrderProductsRequest = RequestAction<
  { standingOrderId: string; productsIds: string[] },
  DeleteStandingOrderProductsResponse
>;
type DeleteStandingOrderProductsSuccess = PayloadAction<DeleteStandingOrderProductsResponse>;
export type PauseStandingOrderRequest = RequestAction<
  { standingOrderId: string; pauseUntilDateUtc?: string; cancelUpcomingOrders?: boolean },
  StandingOrderSchedule
>;
type PauseStandingOrderSuccess = PayloadAction<StandingOrderSchedule>;
export type ResumeStandingOrderRequest = RequestAction<
  { standingOrderId: string; backfillOrders?: boolean },
  StandingOrderSchedule
>;
type ResumeStandingOrderSuccess = PayloadAction<StandingOrderSchedule>;

const standingOrders = createSlice({
  name: "standingOrders",
  initialState,
  reducers: {
    createStandingOrderRequest: (state, _action: CreateStandingOrderRequest) => ({
      ...state,
      creatingStandingOrder: true,
      error: null
    }),
    createStandingOrderSuccess: (state, _action: CreateStandingOrderSuccess) => ({
      ...state,
      creatingStandingOrder: false
    }),
    createStandingOrderFailure: (state, { payload: error }) => ({
      ...state,
      creatingStandingOrder: false,
      error
    }),
    fetchStandingOrderProductsRequest: (state, _action: FetchStandingOrderProductsRequest) => ({
      ...state,
      loadingProducts: true,
      error: null
    }),
    fetchStandingOrderProductsSuccess: (state, { payload }: FetchStandingOrderProductsSuccess) => ({
      ...state,
      loadingProducts: false,
      products: payload.map(removeZeroQuantities)
    }),
    fetchStandingOrderProductsFailure: (state, { payload: error }) => ({
      ...state,
      loadingProducts: false,
      error
    }),
    updateStandingOrderProductsRequest: (state, _action: UpdateStandingOrderProductsRequest) => ({
      ...state,
      updatingProducts: true,
      error: null
    }),
    updateStandingOrderProductsSuccess: (state, { payload: { products } }: UpdateStandingOrderProductsSuccess) => ({
      ...state,
      updatingProducts: false,
      products: products.map(removeZeroQuantities)
    }),
    updateStandingOrderProductsFailure: (state, { payload: error }) => ({
      ...state,
      updatingProducts: false,
      error
    }),
    deleteStandingOrderProductsRequest: (state, _action: DeleteStandingOrderProductsRequest) => ({
      ...state,
      deletingProducts: true,
      error: null
    }),
    deleteStandingOrderProductsSuccess: (state, { payload: { products } }: DeleteStandingOrderProductsSuccess) => ({
      ...state,
      deletingProducts: false,
      products: products.map(removeZeroQuantities)
    }),
    deleteStandingOrderProductsFailure: (state, { payload: error }) => ({
      ...state,
      deletingProducts: false,
      error
    }),
    pauseStandingOrderRequest: (state, _action: PauseStandingOrderRequest) => ({
      ...state,
      updatingStatus: true,
      error: null
    }),
    pauseStandingOrderSuccess: (state, _action: PauseStandingOrderSuccess) => ({
      ...state,
      updatingStatus: false
    }),
    pauseStandingOrderFailure: (state, { payload: error }) => ({
      ...state,
      updatingStatus: false,
      error
    }),
    resumeStandingOrderRequest: (state, _action: ResumeStandingOrderRequest) => ({
      ...state,
      updatingStatus: true,
      error: null
    }),
    resumeStandingOrderSuccess: (state, _action: ResumeStandingOrderSuccess) => ({
      ...state,
      updatingStatus: false
    }),
    resumeStandingOrderFailure: (state, { payload: error }) => ({
      ...state,
      updatingStatus: false,
      error
    })
  }
});

export const {
  createStandingOrderRequest,
  createStandingOrderSuccess,
  createStandingOrderFailure,
  fetchStandingOrderProductsRequest,
  fetchStandingOrderProductsSuccess,
  fetchStandingOrderProductsFailure,
  updateStandingOrderProductsRequest,
  updateStandingOrderProductsSuccess,
  updateStandingOrderProductsFailure,
  deleteStandingOrderProductsRequest,
  deleteStandingOrderProductsSuccess,
  deleteStandingOrderProductsFailure,
  pauseStandingOrderRequest,
  pauseStandingOrderSuccess,
  pauseStandingOrderFailure,
  resumeStandingOrderRequest,
  resumeStandingOrderSuccess,
  resumeStandingOrderFailure
} = standingOrders.actions;

export default standingOrders.reducer;
