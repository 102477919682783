import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

import { BUILD_TIME_ISO, BUILD_TIMESTAMP, VERSION } from "config";
import { takeMatchingApiCall } from "services/fetchV2";
import { isEnabled } from "utils/Datadog";

import useAppSelector from "./useAppSelector";

if (isEnabled) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOGS_CLIENT_TOKEN!,
    forwardErrorsToLogs: true,
    service: "web-ui",
    env: process.env.REACT_APP_DATADOG_ENV,
    version: VERSION,
    sessionSampleRate: 100
  });

  // Configure Datadog to send a copy of the logs to the console too (by default it only sends them to the Datadog server)
  datadogLogs.logger.setHandler(["console", "http"]);

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID!,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN!,
    site: "datadoghq.com",
    service: "web-ui",
    env: process.env.REACT_APP_DATADOG_ENV,
    version: VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    allowedTracingUrls: [process.env.REACT_APP_API_URL!],
    /* eslint-disable no-param-reassign */
    beforeSend(event, _context) {
      if (
        event.type === "resource" &&
        event.resource.type === "fetch" &&
        event.resource.url.startsWith(process.env.REACT_APP_API_URL!)
      ) {
        if (!event.context) {
          event.context = {};
        }
        event.context.apiCall = takeMatchingApiCall(event.resource.url, event.resource.method);
      }
      return true;
    },
    /* eslint-enable no-param-reassign */
    defaultPrivacyLevel: "allow",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackViewsManually: true
  });

  datadogRum.setGlobalContextProperty("buildTimeIso", BUILD_TIME_ISO);
  datadogRum.setGlobalContextProperty("buildTimestamp", BUILD_TIMESTAMP);
}

/**
 * React hook to add extra context to DataDog Logs and RUM
 */
export default function useDataDog(): void {
  const { user, isAuthenticated } = useAuth0();
  const tenancyId = useAppSelector(state => state.tenancy.companyId);
  const companyProfile = useAppSelector(state => state.tenancy);
  const { userId } = useAppSelector(state => state.userProfile);

  useEffect(() => {
    if (isEnabled && tenancyId) {
      datadogLogs.setGlobalContextProperty("tenancyId", tenancyId);
      datadogRum.setGlobalContextProperty("tenancyId", tenancyId);
    }
  }, [tenancyId]);

  useEffect(() => {
    // Only set these properties if a company profile has loaded.
    // These are set separately from the tenancyId above because a company profile doesn't exist in some places where a tenancyId does.
    if (isEnabled && companyProfile.tradingName) {
      datadogRum.setGlobalContextProperty("companyName", companyProfile.tradingName);
      datadogRum.setGlobalContextProperty("countryCode", companyProfile.country?.code);
      datadogRum.setGlobalContextProperty("isBuyer", companyProfile.isBuyer);
      datadogRum.setGlobalContextProperty("isSupplier", companyProfile.isSupplier);
      datadogRum.setGlobalContextProperty("platform", Capacitor.getPlatform());
    }
  }, [companyProfile.country?.code, companyProfile.isBuyer, companyProfile.isSupplier, companyProfile.tradingName]);

  useEffect(() => {
    if (isEnabled && isAuthenticated && user) {
      datadogLogs.setGlobalContextProperty("user_id", user.sub);
      datadogLogs.setGlobalContextProperty("user_external_id", userId);
      datadogLogs.setGlobalContextProperty("user_email", user.email);
      datadogRum.setUser({
        id: user.sub,
        externalId: userId,
        email: user.email,
        name: user.name
      });
    }
  }, [isAuthenticated, user, userId]);
}
