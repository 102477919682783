import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { postDataToApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import { duplicatePriceListFailure, duplicatePriceListSuccess, DuplicatePriceListRequest } from "../reducers/priceList";

/**
 * API call to POST `/supplier/v1/(priceListId)/duplicate` to duplicate the
 * price list with the given ID.
 */
function duplicatePriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string
): Promise<Supplier.V1PricelistsDuplicateCreate.ResponseBody> {
  return postDataToApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}/duplicate`,
    tenancyId
  );
}

/**
 * Saga for duplicating a price list.
 */
export default createSaga(duplicatePriceList, {
  prepareApiMethodParams: ({ priceListId }: DuplicatePriceListRequest["payload"]) => [priceListId],
  successAction: duplicatePriceListSuccess,
  errorFailureAction: duplicatePriceListFailure
});
