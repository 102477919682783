import { useMemo } from "react";

import { ApiConnection, ApiTokenType, ProviderOffering } from "types/api/generated/directory-internal";
import useAppSelector from "utils/hooks/useAppSelector";

/**
 * Find the default connection for the feature passed in.
 *
 * @returns connection object found.
 */
export const findConnectionWithDefaultFeature = (
  feature: ProviderOffering,
  connections: ApiConnection[]
): ApiConnection | undefined =>
  connections?.find(({ features }) =>
    features?.find(connectionFeature => connectionFeature.name === feature && connectionFeature.isDefault)
  );

/**
 * Find all the connections for a feature.
 */
export const findAllConnectionsWithFeature = (
  feature: ProviderOffering,
  connections: ApiConnection[]
): ApiConnection[] =>
  connections?.filter(({ features }) => features?.find(connectionFeature => connectionFeature.name === feature));

/**
 * A hook for retrieving the connection details of a given provider, or all
 * providers if none is selected.
 *
 * @param provider
 *   One of {@link ApiTokenType.Xero}, {@link ApiTokenType.Unleashed}, or
 *   {@link ApiTokenType.Foodstuffs} nothing to retrieve all provider
 *   information.
 * @param connectionFeature
 *   One of {@link ProviderOffering.Contacts}, {@link ProviderOffering.Invoices}
 *   or {@link ProviderOffering.Products} or
 *   {@link ProviderOffering.IncomingOrders}
 * @param isDefault
 *   Is used to bring back only the default feature when true, or all
 *   connections with the feature if false. Is ignored if no feature is
 *   provided.
 * @return
 *   An array of 2 values: either the selected provider connection or a list of
 *   all connections, and a flag for whether we are still loading the data from
 *   the API.
 */
export default function useProviderConnection(
  provider: null | undefined,
  connectionFeature: ProviderOffering,
  isDefault?: true
): [connection: ApiConnection | undefined, loading: boolean];
export default function useProviderConnection(
  provider: null | undefined,
  connectionFeature: ProviderOffering,
  isDefault: false
): [connections: ApiConnection[], loading: boolean];
export default function useProviderConnection(
  provider?: null,
  connectionFeature?: null,
  isDefault?: boolean
): [connections: ApiConnection[], loading: boolean];
export default function useProviderConnection(
  provider?: ApiTokenType | null,
  connectionFeature?: ProviderOffering | null,
  isDefault = true
): [connections: ApiConnection | ApiConnection[] | undefined, loading: boolean] {
  const { connections } = useAppSelector(state => state.integrations);

  const connectionData = provider
    ? connections.find(connection => connection.name === provider)
    : connectionFeature
      ? isDefault
        ? findConnectionWithDefaultFeature(connectionFeature, connections)
        : findAllConnectionsWithFeature(connectionFeature, connections)
      : connections;

  return useMemo(
    () => [
      connectionData,
      // This hard-coded value is from when connection data was being migrated
      // and found that this value is always false, but we didn't yet want to
      // update every part of the app that used this.
      // TODO: Remove this part of the return signature and update affected parts of the app
      false
    ],
    [connectionData]
  );
}
