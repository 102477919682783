import { Capacitor } from "@capacitor/core";
import { useContext, useEffect } from "react";

import { SoftwareKeyboardContext } from "mobile/SoftwareKeyboardDetector";
import { IOS } from "utils/MobilePlatforms";

/**
 * Adjust page offset in the native app on iOS to fix when the keyboard goes
 * over input elements.
 */
export default function useIosInputFocuser(): void {
  const softwareKeyboardVisible = useContext(SoftwareKeyboardContext);

  useEffect(() => {
    if (Capacitor.getPlatform() === IOS && softwareKeyboardVisible) {
      const input = document.activeElement;
      if (input) {
        window.scrollTo({
          behavior: "smooth",
          top: window.scrollY + input.getBoundingClientRect().top - window.innerHeight / 4
        });
      }
    }
  }, [softwareKeyboardVisible]);
}
