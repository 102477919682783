import { MessageFormatter } from "@ultraq/icu-message-formatter";

import { BuyerOrderLine } from "features/buyers/orders/new-order/reducers/types";
import { BuyerProductSummary, OrderLine } from "types/api/generated/buyer";
import { GetBuyerOrderLinesResult } from "types/api/generated/buyer-internal";
import { ApiTokenType, ProviderFeatureAccess, ProviderOffering } from "types/api/generated/directory-internal";
import {
  AdvanceOrderOptions,
  CancelOrderOptions,
  DeliveredOptions,
  FoodstuffsCancelOrderOptions,
  Image,
  InvalidOrderLine,
  InvoiceProvider,
  OnAccountDetails,
  OrderActionType,
  OrderComment,
  OrderDetails,
  OrderExternalDocument,
  OrderExternalDocumentStatus,
  OrderExternalDocumentType,
  OrderPaymentStatusDetailsUnpaid,
  OrderStatus,
  OrderTaxDetails,
  PaymentLinkDetails,
  PaymentMethodDetails,
  ProductStatus,
  SupplierOrderSummary,
  SupplierProductSummary
} from "types/api/generated/supplier";
import { CopyOrderResult } from "types/api/generated/supplier-internal";
import { LocalInvalidOrderLine, LocalOrderLine, NewBuyerOrder, NewSupplierOrder } from "types/Orders";

import useCanPerformFeature from "./hooks/useCanPerformFeature";
import strings from "./Orders.strings.json";

export const COPY_FROM_ORDER_ID = "copyFromOrderId";

/**
 * Copies a previous order into the format of a new one.  Specific to a
 * supplier-entered order.
 */
export function createNewSupplierOrderFromInternalOrderLines(orderDetails: CopyOrderResult): NewSupplierOrder {
  return {
    buyerId: orderDetails.buyerId,
    buyerName: orderDetails.buyerName,
    orderLines: orderDetails.lines
      .slice()
      .reverse()
      .map(line => {
        return {
          ...line,
          // Make sure custom prices aren't copied over
          unitAmount: line.priceListUnitAmount,
          lineAmount: line.priceListLineAmount,
          hasCustomUnitAmount: false
        };
      })
  };
}

/**
 * Copies a previous order into the format of a new one.  Specific to a
 * buyer-entered order.
 */
export function createNewOrderFromBuyerInternalOrderLines(orderDetails: GetBuyerOrderLinesResult): NewBuyerOrder {
  return {
    orderLines: orderDetails.lines
      .slice()
      .reverse()
      .map(line => {
        const { isFavourite, ...rest } = line;
        return {
          supplierId: orderDetails.supplierId,
          supplierName: orderDetails.supplierName,
          supplierLogoUrl: orderDetails.supplierLogoUrl,
          isAFavourite: isFavourite,
          ...rest,
          // Make sure custom prices aren't copied over
          buyerPrice: line.priceListUnitAmount,
          unitAmount: line.priceListUnitAmount,
          hasCustomUnitAmount: false,
          promotions: []
        };
      })
  };
}

/**
 * Convert the given products to a list of order lines for use in a buyer order.
 */
export function convertProductsToNewBuyerOrderFormat(
  products: BuyerProductSummary[],
  setProductsToFavourite = false
): NewBuyerOrder {
  return {
    orderLines: products.slice().map(product => {
      const orderLine: BuyerOrderLine = {
        ...product,
        hasCustomUnitAmount: false,
        quantity: 1,
        unitAmount: product.buyerPrice,
        lineAmount: product.buyerPrice
      };
      if (setProductsToFavourite) {
        orderLine.isAFavourite = true;
      }
      return orderLine;
    })
  };
}

/**
 * Extract an order document from an order.  This should simply be the
 * `order.documents` property, but invoices are still returned to us in a
 * separate property, so this function will map it to a document for use in the
 * UI.
 */
export function getOrderDocument(order: SupplierOrderSummary | OrderDetails): OrderExternalDocument | undefined {
  const { documents, invoice } = order;

  const invoiceProviderToApiTokenType: Record<InvoiceProvider, ApiTokenType> = {
    [InvoiceProvider.Xero]: ApiTokenType.Xero,
    [InvoiceProvider.Codat]: ApiTokenType.Codat,
    [InvoiceProvider.MyIntegrator]: ApiTokenType.MyIntegrator
  };

  return invoice
    ? {
        documentProvider: invoiceProviderToApiTokenType[invoice.provider ?? InvoiceProvider.Xero], // invoice.provider is nullable so need a fallback
        documentType: OrderExternalDocumentType.Invoice,
        name: invoice?.invoiceNumber ?? "",
        url: invoice?.viewInProviderUrl ?? "",
        documentStatus: OrderExternalDocumentStatus.Created,
        errors: []
      }
    : documents?.[0];
}

type OrderMaybe = null | {
  orderLines?: (BuyerOrderLine | LocalOrderLine)[];
};

/**
 * Return whether or not a new order object/state has order lines in it.
 */
export function hasOrderLines(newOrder: OrderMaybe): boolean {
  return (newOrder?.orderLines?.length ?? 0) > 0;
}

export const isOrderScheduled = (status?: OrderStatus | null): boolean => status === OrderStatus.Scheduled;

export const isOrderShipped = (status?: OrderStatus | null): boolean => status === OrderStatus.Shipped;

export const isOrderProcessing = (status?: OrderStatus | null): boolean => status === OrderStatus.Processing;

export const isOrderCancelled = (status?: OrderStatus | null): boolean => status === OrderStatus.Cancelled;

export const isOrderReceived = (status?: OrderStatus | null): boolean => status === OrderStatus.Received;

export const isOrderOrdered = (status?: OrderStatus | null): boolean => status === OrderStatus.Ordered;

export const isOrderDelivered = (status?: OrderStatus | null): boolean => status === OrderStatus.Delivered;

export const isOrderShippedOrReceived = (status: OrderStatus): boolean =>
  status === OrderStatus.Shipped || status === OrderStatus.Received;

export const isOrderOrderedOrProcessing = (status: OrderStatus): boolean =>
  status === OrderStatus.Processing || status === OrderStatus.Ordered;

/**
 * Hook that returns whether an order can currently be edited in the provider.
 */
export const useCanEditOrderInProvider = (orderDocument: OrderExternalDocument | undefined): boolean => {
  const canEditOutgoingOrders = useCanPerformFeature(ProviderOffering.OutgoingOrders, ProviderFeatureAccess.Edit);
  const canEditInvoices = useCanPerformFeature(ProviderOffering.Invoices, ProviderFeatureAccess.Edit);

  // If the order doesn't have a document, then it can always be edited
  if (!orderDocument) {
    return true;
  }

  return (
    // When the document is deferred, the order can be edited because it hasn't been created in the provider yet
    (orderDocument.documentType === OrderExternalDocumentType.GeneratedOrder && canEditOutgoingOrders) ||
    (orderDocument.documentType === OrderExternalDocumentType.Invoice && canEditInvoices) ||
    orderDocument.documentStatus === OrderExternalDocumentStatus.Deferred
  );
};

/**
 * Return a displayable action string that can be applied to an order for a
 * given status.
 */
export function actionStringForOrderStatus(formatter: MessageFormatter, orderStatus: OrderStatus): string {
  // Only these statuses are used for actions - the rest don't have a corresponding action yet
  if (
    orderStatus === OrderStatus.Ordered ||
    orderStatus === OrderStatus.Processing ||
    orderStatus === OrderStatus.Shipped ||
    orderStatus === OrderStatus.Delivered ||
    orderStatus === OrderStatus.Received
  ) {
    return formatter.format(strings[`ORDERACTION_${orderStatus}`]);
  }
  return "";
}

/**
 * Return a displayable string for a given order status.
 */
export function stringForOrderStatus(formatter: MessageFormatter, orderStatus: OrderStatus): string {
  return formatter.format(strings[`ORDERSTATUS_${orderStatus}`]);
}

/**
 * Return whether an action is available for the current order.
 */
export function hasOrderAction(order: SupplierOrderSummary | OrderDetails, action: OrderActionType): boolean {
  return order.workflow.availableActions.some(a => a.action === action);
}

/**
 * Merge valid and invalid order lines, sorted by lineId to display sorted
 * correctly in an order.
 */
export function mergeValidAndInvalidOrderLines<A, B>(lines?: A[] | null, invalidLines?: B[] | null): Array<A | B> {
  const firstLineArray = lines || [];
  const secondLineArray = invalidLines || [];

  return [...secondLineArray, ...firstLineArray];
}

/**
 * Small helper to build an {@link AdvanceOrderOptions} object.
 */
export function advanceOrderOptions(
  comment?: OrderComment | null,
  delivered?: DeliveredOptions | null,
  ignoreOrderLineWarnings = false
): AdvanceOrderOptions {
  return {
    $type: "Advance",
    comment,
    delivered,
    ignoreOrderLineWarnings
  };
}

/**
 * Small helper to build a {@link CancelOrderOptions} object.
 */
export function cancelOrderOptions(comment?: OrderComment | null): CancelOrderOptions {
  return {
    $type: "Cancel",
    comment
  };
}

/**
 * Small helper to build a {@link FoodstuffsCancelOrderOptions} object.
 */
export function foodstuffsCancelOrderOptions(
  rejectionReason: string,
  comment?: OrderComment | null
): FoodstuffsCancelOrderOptions {
  return {
    $type: "FoodstuffsCancel",
    rejectionReason,
    comment
  };
}

/**
 * Small helper to build an {@link OrderComment} object.
 */
export function orderComment(message: string, images?: Image[] | null): OrderComment {
  return {
    message,
    images
  };
}

export type PrimaryOrderTaxBreakdown = Array<{
  key: string;
  displayName: string;
  amount: number;
}>;

/**
 * Gets the main order tax breakdown that should be displayed.
 */
export function getPrimaryOrderTaxBreakdown(
  orderHasPrices: boolean,
  orderTax: OrderTaxDetails | null | undefined
): PrimaryOrderTaxBreakdown {
  if (!orderHasPrices || !orderTax) return [];

  if (orderTax.taxCodeBreakdown) {
    return orderTax.taxCodeBreakdown.map(taxCodeBreakdownLine => ({
      key: taxCodeBreakdownLine.taxCode,
      displayName: taxCodeBreakdownLine.taxCode,
      amount: taxCodeBreakdownLine.taxAmount.amount
    }));
  }

  return orderTax.taxRateBreakdown.map(taxRateBreakdownLine => ({
    key: taxRateBreakdownLine.taxRate.taxRateId,
    displayName: taxRateBreakdownLine.taxRate.name,
    amount: taxRateBreakdownLine.taxAmount.amount
  }));
}

type PaymentParam = PaymentMethodDetails | PaymentLinkDetails | OnAccountDetails | undefined;
export function isDeferredOrderDocumentStatus(payment: PaymentParam, deferredStatus?: OrderStatus | null): boolean {
  return (payment?.statusDetails as OrderPaymentStatusDetailsUnpaid).deferredUntilOrderStatus === deferredStatus;
}

/** Checks whether a scheduled order is still before it's cutoff time */
export function isBeforeCutoffTime(
  autoApproveDateUtc: string | null | undefined,
  orderStatus: OrderStatus
): autoApproveDateUtc is string {
  return !!autoApproveDateUtc && new Date(autoApproveDateUtc) > new Date() && isOrderScheduled(orderStatus);
}

/**
 * Compares two order lines (of any type), sorting them by the lineId property if it exists.
 */
export function sortByLineId(
  a: BuyerOrderLine | OrderLine | LocalOrderLine | InvalidOrderLine | LocalInvalidOrderLine,
  b: BuyerOrderLine | OrderLine | LocalOrderLine | InvalidOrderLine | LocalInvalidOrderLine
): number {
  if ("lineId" in a === false || "lineId" in b === false) {
    return 0;
  }

  if (a.lineId === undefined || b.lineId === undefined) {
    return 0;
  }

  return a.lineId - b.lineId;
}

/**
 * Compares two order lines (of any type), sorting them by the product name.
 */
export function sortByProductName(
  a: BuyerOrderLine | OrderLine | LocalOrderLine | InvalidOrderLine | LocalInvalidOrderLine,
  b: BuyerOrderLine | OrderLine | LocalOrderLine | InvalidOrderLine | LocalInvalidOrderLine
): number {
  if (a.productName === undefined || b.productName === undefined || a.productName === null || b.productName === null) {
    return 0;
  }

  return a.productName.localeCompare(b.productName);
}

export const createOrderLineFromProduct = (
  product: SupplierProductSummary,
  quantity: number,
  lineId: number
): LocalOrderLine => ({
  lineId,
  productId: product.productId,
  productName: product.name,
  productCode: product.code,
  productImages: product.imagesMetadata,
  unitAmount: product.buyerPrice,
  lineAmount: product.buyerPrice && {
    amount: product.buyerPrice.amount * quantity,
    currency: product.buyerPrice.currency
  },
  priceListUnitAmount: product.buyerPrice,
  priceListLineAmount: product.buyerPrice && {
    amount: product.buyerPrice.amount * quantity,
    currency: product.buyerPrice.currency
  },
  quantity,
  hasCustomUnitAmount: false,
  isAvailable: product.isAvailable,
  isOrderable: product.isOrderable,
  problems: []
});

export const replaceOrderLineWithProduct = (line: LocalOrderLine, product: SupplierProductSummary): LocalOrderLine => ({
  productId: product.productId,
  productName: product.name,
  productCode: product.code,
  productImages: product.imagesMetadata,
  unitAmount: product.buyerPrice,
  lineAmount: product.buyerPrice && {
    amount: product.buyerPrice.amount * line.quantity,
    currency: product.buyerPrice.currency
  },
  priceListUnitAmount: product.buyerPrice,
  priceListLineAmount: product.buyerPrice && {
    amount: product.buyerPrice.amount * line.quantity,
    currency: product.buyerPrice.currency
  },
  lineId: line.lineId,
  quantity: line.quantity,
  hasCustomUnitAmount: false,
  isAvailable: product.isAvailable,
  isOrderable: product.isOrderable,
  documentProductCode: line.documentProductCode,
  problems: []
});

export const replaceInvalidLineWithProduct = (
  line: LocalInvalidOrderLine,
  product: SupplierProductSummary
): LocalOrderLine => {
  const quantity = line.quantity ?? 0;

  return {
    productId: product.productId,
    productName: product.name,
    productCode: product.code,
    productImages: product.imagesMetadata,
    unitAmount: product.buyerPrice,
    lineAmount: product.buyerPrice && {
      amount: product.buyerPrice.amount * quantity,
      currency: product.buyerPrice.currency
    },
    priceListUnitAmount: product.buyerPrice,
    priceListLineAmount: product.buyerPrice && {
      amount: product.buyerPrice.amount * quantity,
      currency: product.buyerPrice.currency
    },
    lineId: line.lineId,
    quantity,
    hasCustomUnitAmount: false,
    isAvailable: product.isAvailable,
    isOrderable: product.isOrderable,
    documentProductCode: line.productCode,
    problems: []
  };
};

export const mapOrderLineToLocalOrderLine = (line: OrderLine): LocalOrderLine => ({
  lineId: line.lineId,
  productId: line.productId,
  productName: line.productName,
  productCode: line.productCode,
  productImages: line.productImages,
  unitAmount: line.unitAmount,
  lineAmount: line.lineAmount,
  priceListUnitAmount: line.priceListUnitAmount,
  priceListLineAmount: line.priceListLineAmount,
  quantity: line.quantity,
  hasCustomUnitAmount: line.hasCustomUnitAmount,
  problems: line.problems,
  packingStatus: line.packingStatus,
  tax: line.tax,
  isAvailable: line.status === ProductStatus.Active,
  isOrderable: line.status === ProductStatus.Active
});
