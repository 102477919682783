import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GlobalInternal } from "types/api";
import { RequestAction } from "types/redux-helpers";

export interface GeographyState {
  loadingCountries: boolean;
  loadingAddressSuggestions: boolean;
}

const initialState: GeographyState = {
  loadingCountries: false,
  loadingAddressSuggestions: false
};

export type FetchAddressSuggestionsRequest = RequestAction<
  GlobalInternal.InternalGeographyAddressesList.RequestQuery,
  GlobalInternal.InternalGeographyAddressesList.ResponseBody
>;
type FetchAddressSuggestionsSuccess = PayloadAction<GlobalInternal.InternalGeographyAddressesList.ResponseBody>;

const geographySlice = createSlice({
  name: "geography",
  initialState,
  reducers: {
    fetchAddressSuggestionsRequest: (state, _payload: FetchAddressSuggestionsRequest) => ({
      ...state,
      loadingAddressSuggestions: true
    }),
    fetchAddressSuggestionsSuccess: (state, _profile: FetchAddressSuggestionsSuccess) => ({
      ...state,
      loadingAddressSuggestions: false
    }),
    fetchAddressSuggestionsFailure: (state, _payload) => ({
      ...state,
      loadingAddressSuggestions: false
    })
  }
});

export const { fetchAddressSuggestionsRequest, fetchAddressSuggestionsSuccess, fetchAddressSuggestionsFailure } =
  geographySlice.actions;

export default geographySlice.reducer;
