import { DefaultOptions, QueryClient } from "@tanstack/react-query";

import { FETCH_NETWORK_ERROR, isFetchV2Error } from "./fetchV2";

export const queryClientDefaultOptions: DefaultOptions = {
  queries: {
    // Throw errors by default to trigger the error boundary.
    // This can be overridden on a per-query basis when errors are being explicitly handled.
    useErrorBoundary: true,
    // Don't use the online/offline functionality because it's a bit unreliable in Chrome in react-query@<5
    // https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#network-status-no-longer-relies-on-the-navigatoronline-property
    networkMode: "always",
    retry: 2
  },
  mutations: {
    // Don't use the online/offline functionality because it's a bit unreliable in Chrome in react-query@<5
    // https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#network-status-no-longer-relies-on-the-navigatoronline-property
    networkMode: "always",
    retry: (failureCount: number, error: unknown): boolean => {
      if (isFetchV2Error(error)) {
        return (
          failureCount < 3 &&
          // Only retry these error types for non-GET requests:
          // - Request Timeout
          (error.status === 408 ||
            // - Conflict
            error.status === 409 ||
            // - Internal Server Error
            error.status === 500 ||
            // - Network errors
            error.code === FETCH_NETWORK_ERROR)
        );
      }
      return false;
    }
  }
};

export default new QueryClient({
  defaultOptions: queryClientDefaultOptions
});
