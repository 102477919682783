import { Alpha2Code, CompanyProfile } from "types/api/generated/directory";

export const TENANCY_NONE_FOUND = "NoneFound";

export function getBusinessNumber(companyProfile: CompanyProfile): string | null | undefined {
  return companyProfile.country.code === Alpha2Code.AU
    ? companyProfile.countryExtensions?.australianBusinessNumber
    : companyProfile.country.code === Alpha2Code.NZ
      ? companyProfile.countryExtensions?.newZealandBusinessNumber
      : null;
}
