import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { styled, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase, { InputBaseComponentProps, InputBaseProps } from "@mui/material/InputBase";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { FunctionComponent, KeyboardEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useMount, useUnmount } from "react-use";

import useSearch from "utils/hooks/useSearch";

const NOOP = (): void => {};
const getCommonStyled = (theme: Theme): any => ({
  width: "100%",
  paddingTop: 2,
  paddingBottom: 2,
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up("sm")]: {
    width: "auto"
  },
  "& legend": {
    display: "none"
  },
  "& input": {
    // When no width is set on the parent (when it's not stretching 100%) Chrome, FireFox and Safari
    // all fallback different widths for the input. This hints to the browsers what width to fallback
    // to while still allowing the input to stretch 100%.
    width: theme.spacing(19),
    flex: 1,
    textOverflow: "ellipsis",
    // Adjust the padding so the searchbox is the same height as the standard Button
    padding: "6.5px 14px 6.5px 0"
  },
  "& input::-webkit-search-cancel-button": {
    display: "none"
  }
});

const StyledSearchInputBase = styled(InputBase, { name: "searchfield" })(({ theme }) => getCommonStyled(theme));
const StyledSearchOutlinedInput = styled(OutlinedInput, {
  name: "searchfield"
})(({ theme }) => getCommonStyled(theme));

export type SearchBoxProps = Omit<OutlinedInputProps, "onChange"> & {
  autoFocus?: boolean;
  className?: string;
  sx?: InputBaseProps["sx"] | OutlinedInputProps["sx"];
  onChange?: (value: string) => void;
  /**
   * Passing this callback switches the component to controlled mode, allowing a parent component
   * to control the input value.
   */
  onEveryChange?: (value: string) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  endAdornmentIcon?: ReactNode;
  inputProps?: InputBaseComponentProps;
  placeholder?: string;
  startAdornmentIcon?: ReactNode;
  /**
   * This prop controls the search-on-mount behaviour of the search box. Many existing pages rely on this,
   * but it can problematic if the same data needs to be retrieved first which then leads to redundant requests.
   */
  searchOnMount?: boolean;
  useUrlParam?: boolean;
  value?: string;
  variant?: "outlined" | "none";
};

const SearchBox: FunctionComponent<SearchBoxProps> = ({
  autoFocus = false,
  className,
  sx,
  // Note: having both onChange and onEveryChange is a workaround to support both controlled and uncontrolled usage
  //       without needing to update all the SearchBox instances. This should probably be split into two components.
  onChange = NOOP,
  onEveryChange,
  onKeyDown = NOOP,
  endAdornmentIcon,
  inputProps = {},
  placeholder = "Search",
  startAdornmentIcon = null,
  searchOnMount = true,
  useUrlParam = true,
  value = "",
  variant = "outlined",
  ...rest
}) => {
  const [search, setSearch] = useSearch();
  const [internalValue, setInternalValue] = useState(useUrlParam ? search : value);

  const previousValueRef = useRef(internalValue);
  const requestTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Changes to the search input field are debounced and will update the
  // "search" query parameter in the URL
  const queueValueUpdate = useCallback(
    (latestValue: string) => {
      if (requestTimeoutIdRef.current) {
        clearTimeout(requestTimeoutIdRef.current);
      }

      requestTimeoutIdRef.current = setTimeout(() => {
        if (latestValue !== previousValueRef.current) {
          previousValueRef.current = latestValue;
          setInternalValue(latestValue);
          onChange(latestValue);

          if (useUrlParam) {
            setSearch(latestValue);
          }
        }
      }, 500);
    },
    [onChange, setSearch, useUrlParam]
  );

  const handleOnChange = (latestValue: string): void => {
    setInternalValue(latestValue);

    // Support controlled usage
    if (onEveryChange) {
      onEveryChange(latestValue);
    }

    queueValueUpdate(latestValue);
  };

  useEffect(() => {
    // Sync up the internalValue and value if the value is changed in controlled mode
    if (onEveryChange && internalValue !== value) {
      queueValueUpdate(value);
    }
  }, [onEveryChange, internalValue, value, queueValueUpdate]);

  useMount(() => {
    // To retain the behaviour where this component kicks off an initial search
    if (searchOnMount) {
      onChange(internalValue);
      previousValueRef.current = internalValue;
    }
  });

  useUnmount(() => {
    // Make sure to cancel any active timeouts to not cause state errors in React
    if (requestTimeoutIdRef.current) {
      clearTimeout(requestTimeoutIdRef.current);
    }
  });

  const inputPropsObject: InputBaseComponentProps = {
    "data-testid": "searchbox-input",
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "off",
    ...inputProps
  };

  const settings: InputBaseProps = {
    id: "outlined-search",
    margin: "dense",
    fullWidth: true,
    placeholder,
    autoFocus,
    // Support both controlled and uncontrolled usage
    value: onEveryChange ? value : internalValue,
    className,
    sx,
    onChange: e => handleOnChange(e.target.value),
    onKeyDown,
    type: "search",
    inputProps: inputPropsObject,
    startAdornment: <InputAdornment position="start">{startAdornmentIcon || <SearchIcon />}</InputAdornment>,
    endAdornment: (value || internalValue) && (
      <InputAdornment position="end">
        {endAdornmentIcon || (
          <IconButton
            onClick={() => {
              handleOnChange("");
              if (useUrlParam) {
                setSearch();
              }
            }}
            aria-label="Clear search"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
      </InputAdornment>
    ),
    ...rest
  };

  return variant === "outlined" ? (
    <StyledSearchOutlinedInput label="Search field" {...settings} />
  ) : (
    <StyledSearchInputBase {...settings} />
  );
};

export default SearchBox;
