import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { fetchNavigationDataSilentlyRequest } from "redux/reducers/navigationData";
import { isCompanyRoute } from "routes/Routes";
import { logger } from "utils/Datadog";

/**
 * React hook for refreshing the navigation data when a tab is refocused after a
 * user has left it.
 */
export default function useRefreshNavigationData(): void {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isListenerLoaded, setListenerLoaded] = useState(false);

  useEffect(() => {
    if (isCompanyRoute(pathname) && !isListenerLoaded) {
      window.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          dispatch(
            fetchNavigationDataSilentlyRequest({
              failureCallback(error) {
                logger.warn("Failed to refetch navigation data", undefined, error instanceof Error ? error : undefined);
              }
            })
          );
        }
      });
      setListenerLoaded(true);
    }
  }, [dispatch, isListenerLoaded, pathname]);
}
