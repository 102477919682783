import { ComponentProps } from "react";

/**
 * A mapping of HTML attribute keys to primitive/string values because we're
 * using `setAtrribute` which accepts only string values, even though some
 * properties are not string types.  eg: the `style` attribute is of
 * `CSSStyleDeclaration`, but it's valid to use
 * `el.setAttribute("style", "display: grid")`
 */
export type PrimitiveHtmlAttributeValues<E extends keyof HTMLElementTagNameMap> = {
  [Property in keyof ComponentProps<E>]: string | boolean;
};

/**
 * Shorthand for `document.createElement` and subsequent `setAttribute` calls on
 * that element.
 */
export function html<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  options?: PrimitiveHtmlAttributeValues<K>
): HTMLElementTagNameMap[K] {
  const element = document.createElement(tagName);
  if (options) {
    Object.entries(options).forEach(([key, value]) => {
      if (value) {
        element.setAttribute(
          key,
          key.startsWith("aria-") ? value.toString() : value === "boolean" ? key : value.toString()
        );
      }
    });
  }
  return element;
}
