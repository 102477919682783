import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import SkeletonHeader from "components/Header/SkeletonHeader";
import Page from "components/Page";
import useMessages from "i18n/hooks/useMessages";
import LogoIcon from "media/logo_mark.svg?react";
import { logger } from "utils/Datadog";

import strings from "./PageNotFound.strings.json";

const useStyles = makeStyles()(theme => ({
  container: {
    alignItems: "center",
    display: "flex",
    textAlign: "center"
  },
  icon: {
    height: "40px",
    width: "40px"
  },
  header: {
    margin: theme.spacing(2, "auto", 3)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));

/**
 * Our 404 page 🙂
 */
const PageNotFound: FunctionComponent = () => {
  const messages = useMessages(strings);
  const { classes, cx } = useStyles();
  const { hash, pathname, search } = useLocation();

  useMount(() => {
    if (!window.Cypress) {
      logger.error(
        `Someone has found themselves on the 404 page, location: ${pathname + (search || "") + (hash || "")}`
      );
    }
  });

  return (
    <Page
      header={<SkeletonHeader backgroundColor="white" />}
      displayNav={false}
      containerMaxWidth="xs"
      containerClassName={cx(classes.container)}
    >
      <main>
        <LogoIcon className={cx(classes.icon)} />
        <Typography gutterBottom variant="h1" className={cx(classes.header)}>
          <FormattedMessage id={messages.HEADER} />
        </Typography>
        <Typography paragraph>
          <FormattedMessage id={messages.MESSAGE1} />
        </Typography>
        <Typography>
          <FormattedMessage
            id={messages.MESSAGE2}
            values={{
              home: {
                href: "/"
              }
            }}
          />
        </Typography>
        <Button color="primary" variant="contained" href="/" className={cx(classes.button)}>
          <FormattedMessage id={messages.HOMEBUTTON} />
        </Button>
      </main>
    </Page>
  );
};

export default PageNotFound;
