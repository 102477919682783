import { useMemo } from "react";

import useTypedUrlParams from "utils/hooks/useTypedUrlParams";

export const SEARCH_URL_PARAM = "search";

type UseSearchResult = [search: string, setSearch: (value?: string) => void];

/**
 * React hook to use as a shortcut to reading/writing the `search` URL query
 * param, with an API similar to `setState`.
 */
export default function useSearch(initialValue?: string | null): UseSearchResult {
  const [search, setSearch] = useTypedUrlParams(SEARCH_URL_PARAM, initialValue);

  return useMemo(() => [search ?? "", setSearch], [search, setSearch]);
}
