/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptInviteResponse {
  companyId: string;
}

export enum AccountHolderTypeEnum {
  UnKnown = "UnKnown",
  Individual = "Individual",
  Company = "Company"
}

export enum AccountTypeEnum {
  UnKnown = "UnKnown",
  Checking = "Checking",
  Savings = "Savings",
  BusinessChecking = "BusinessChecking",
  Current = "Current"
}

export enum ActionablePromptType {
  RateMobileApplication = "RateMobileApplication"
}

export interface AddOnDetails {
  type: AddOnType;
  pricing: Metadata;
}

export enum AddOnType {
  SampleStore = "SampleStore",
  Foodstuffs = "Foodstuffs",
  StockTransfer = "StockTransfer",
  WebhookIntegration = "WebhookIntegration",
  CodatIntegration = "CodatIntegration",
  WoolworthsIntegration = "WoolworthsIntegration",
  MyIntegratorIntegration = "MyIntegratorIntegration",
  StarshipitIntegration = "StarshipitIntegration"
}

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export interface ApiConnection {
  name: ApiTokenType;
  tenantName?: string | null;
  displayedProvider?: ApiTokenDisplayedProviderType | null;
  features: ProviderFeature[];
}

export enum ApiTokenAuditAction {
  Connected = "Connected",
  Disconnected = "Disconnected"
}

export enum ApiTokenDisplayedProviderType {
  Sandbox = "Sandbox",
  Myob = "Myob",
  Xero = "Xero",
  QuickBooksOnlineSandbox = "QuickBooksOnlineSandbox",
  QuickBooksOnline = "QuickBooksOnline"
}

export enum ApiTokenType {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Webhooks = "Webhooks",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface BasicCompanyProfile {
  companyId: string;
  tradingName: string;
  logoUrl?: string | null;
}

export interface BillingDetails {
  plan: BillingPlanDetails;
  addOns: AddOnDetails[];
}

export interface BillingPlanDetails {
  type: PlanType;
  currency: Currency;
  pricing: Metadata;
  invoiceEmail?: string | null;
  chargebeeMetadata: ChargebeeMetadataDetails;
  /** @format date-time */
  startDateUtc?: string | null;
}

export enum BrandEnum {
  UnKnown = "UnKnown",
  Visa = "Visa",
  Mastercard = "Mastercard",
  AmericanExpress = "AmericanExpress",
  Discover = "Discover",
  Jcb = "Jcb",
  DinersClub = "DinersClub",
  Other = "Other",
  Bancontact = "Bancontact",
  CmrFalabella = "CmrFalabella",
  TarjetaNaranja = "TarjetaNaranja",
  Nativa = "Nativa",
  Cencosud = "Cencosud",
  Cabal = "Cabal",
  Argencard = "Argencard",
  Elo = "Elo",
  Hipercard = "Hipercard",
  Carnet = "Carnet",
  Rupay = "Rupay",
  Maestro = "Maestro",
  NotApplicable = "NotApplicable"
}

export interface Charge {
  type: ChargeType;
  unit: UnitType;
  /** @format double */
  amount?: number | null;
  notes?: string | null;
  discount?: Discount | null;
  /** @format double */
  calculatedAmount?: number | null;
}

export enum ChargeType {
  Default = "Default",
  SupplierPlacedOrders = "SupplierPlacedOrders",
  BuyerPlacedOrders = "BuyerPlacedOrders",
  CustomerConnection = "CustomerConnection",
  ScheduledPlacedOrders = "ScheduledPlacedOrders"
}

export interface ChargebeeMetadataDetails {
  subscriptionId?: string | null;
  customerId?: string | null;
  site?: string | null;
}

export interface ClaimCompanyQrCode {
  qrCode: string;
  url: string;
}

export interface CompanyClaimToken {
  externalId: string;
  /** @format uuid */
  token: string;
}

export interface CompanyPermissions {
  companyId: string;
  permissions: Permission[];
}

export interface CompanySettings {
  defaultView?: MenuGroupType | null;
}

export interface CompanySuggestion {
  tradingName?: string | null;
  legalBusinessName?: string | null;
  businessNumber?: string | null;
}

export interface CompanySummary {
  companyId: string;
  tradingName: string;
  isBuyer: boolean;
  buyerStatus: CompanyTypeStatus;
  isSupplier: boolean;
  supplierStatus: CompanyTypeStatus;
  country: Country;
}

export enum CompanyTypeStatus {
  None = "None",
  Onboarding = "Onboarding",
  Active = "Active",
  Inactive = "Inactive"
}

export interface CompleteGettingStartedStepCommand {
  actionType: GettingStartedActionType;
}

export interface CompleteSignupRequestCommand {
  matchedCompanyId: string;
  skipFreeSamplesStore: boolean;
  skipInvite: boolean;
  supplierReferrerIdOverride?: string | null;
}

export interface ConfirmEmailCommand {
  requestId?: string | null;
}

export interface ConnectToMyIntegratorRequest {
  displayedProvider: ApiTokenDisplayedProviderType;
}

export interface ConnectToServiceCredentialsProviderRequest {
  apiKey: string;
  apiSecret: string;
  tenantId?: string | null;
}

export interface ConnectionMetadata {
  integrations: ConnectionMetadataIntegration[];
}

export interface ConnectionMetadataIntegration {
  integration: ApiTokenType;
  isAvailableForPlan: boolean;
  unavailableReason?: ConnectionMetadataIntegrationUnavailableReason | null;
  displayedProvider?: ApiTokenDisplayedProviderType | null;
  canManageConnection: boolean;
  isAvailable: boolean;
}

export enum ConnectionMetadataIntegrationUnavailableReason {
  ContactSupport = "ContactSupport",
  Conflict = "Conflict"
}

export interface ConnectionTimeline {
  connectionType: ApiTokenType;
  actionType: ApiTokenAuditAction;
  /** @format date-time */
  actionDateUtc: string;
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CreateOrUpdateKnockPreferencesCommand {
  notification: NotificationTemplate;
  isPushEnabled?: boolean | null;
  isEmailEnabled?: boolean | null;
}

export interface CreateOrUpdateSignupRequestCommand {
  email: string;
  isBuyer: boolean;
  isSupplier: boolean;
  tradingName?: string | null;
  legalBusinessName?: string | null;
  address?: string | null;
  countryCallingCode?: string | null;
  website?: string | null;
  description?: string | null;
  supplierReferrerId?: string | null;
  hasTradingRelationship?: boolean | null;
  salesDetails?: SalesDetails | null;
  alpha2CountryCode?: string | null;
  signupReferralId?: string | null;
  businessNumber?: string | null;
  attribution?: SignupAttribution | null;
  phoneNumber?: PhoneNumber | null;
  /** @deprecated */
  phone?: string | null;
}

export interface CreateXeroSignupReferralCommand {
  redirectUrl: string;
  code: string;
  state: string;
}

export interface Currency {
  code: string;
}

export interface DashboardAccess {
  url: string;
  token: string;
}

export interface DeregisterPushNotificationTokenCommand {
  token: string;
  platform: Platform;
}

export enum DirectDebitSchemeEnum {
  UnKnown = "UnKnown",
  Ach = "Ach",
  Bacs = "Bacs",
  SepaCore = "SepaCore",
  Autogiro = "Autogiro",
  Becs = "Becs",
  BecsNz = "BecsNz",
  Pad = "Pad",
  NotApplicable = "NotApplicable"
}

export interface Discount {
  /** @format double */
  amount: number;
  type: DiscountType;
}

export enum DiscountType {
  Percentage = "Percentage",
  Amount = "Amount"
}

export enum EcheckTypeEnum {
  UnKnown = "UnKnown",
  Web = "Web",
  Ppd = "Ppd",
  Ccd = "Ccd"
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export enum FundingTypeEnum {
  UnKnown = "UnKnown",
  Credit = "Credit",
  Debit = "Debit",
  Prepaid = "Prepaid",
  NotKnown = "NotKnown",
  NotApplicable = "NotApplicable"
}

export enum GatewayEnum {
  UnKnown = "UnKnown",
  Chargebee = "Chargebee",
  ChargebeePayments = "ChargebeePayments",
  Stripe = "Stripe",
  Wepay = "Wepay",
  Braintree = "Braintree",
  AuthorizeNet = "AuthorizeNet",
  PaypalPro = "PaypalPro",
  Pin = "Pin",
  Eway = "Eway",
  EwayRapid = "EwayRapid",
  Worldpay = "Worldpay",
  BalancedPayments = "BalancedPayments",
  Beanstream = "Beanstream",
  Bluepay = "Bluepay",
  Elavon = "Elavon",
  FirstDataGlobal = "FirstDataGlobal",
  Hdfc = "Hdfc",
  Migs = "Migs",
  Nmi = "Nmi",
  Ogone = "Ogone",
  Paymill = "Paymill",
  PaypalPayflowPro = "PaypalPayflowPro",
  SagePay = "SagePay",
  Tco = "Tco",
  Wirecard = "Wirecard",
  AmazonPayments = "AmazonPayments",
  PaypalExpressCheckout = "PaypalExpressCheckout",
  Gocardless = "Gocardless",
  Adyen = "Adyen",
  Orbital = "Orbital",
  MonerisUs = "MonerisUs",
  Moneris = "Moneris",
  Bluesnap = "Bluesnap",
  Cybersource = "Cybersource",
  Vantiv = "Vantiv",
  CheckoutCom = "CheckoutCom",
  Paypal = "Paypal",
  IngenicoDirect = "IngenicoDirect",
  Exact = "Exact",
  Mollie = "Mollie",
  Quickbooks = "Quickbooks",
  Razorpay = "Razorpay",
  GlobalPayments = "GlobalPayments",
  BankOfAmerica = "BankOfAmerica",
  Ecentric = "Ecentric",
  MetricsGlobal = "MetricsGlobal",
  Windcave = "Windcave",
  PayCom = "PayCom",
  Ebanx = "Ebanx",
  Dlocal = "Dlocal",
  NotApplicable = "NotApplicable"
}

export interface GetCompanySuggestionsResult {
  suggestions: CompanySuggestion[];
}

export interface GettingStartedAction {
  actionType: GettingStartedActionType;
  isCompleted: boolean;
  isLocked: boolean;
  isRequiredForOnboarding: boolean;
}

export enum GettingStartedActionType {
  BuyerFirstOrder = "BuyerFirstOrder",
  SupplierFirstOrder = "SupplierFirstOrder",
  AddSupplier = "AddSupplier",
  AddBuyer = "AddBuyer",
  CheckProfile = "CheckProfile",
  AddFavourites = "AddFavourites",
  ConnectToProvider = "ConnectToProvider",
  SupplierAddProducts = "SupplierAddProducts",
  SupplierSetupPricing = "SupplierSetupPricing",
  SupplierGrowthCustomersOrders = "SupplierGrowthCustomersOrders",
  InviteUsers = "InviteUsers",
  SupplierActivation = "SupplierActivation",
  SupplierQuickAddProducts = "SupplierQuickAddProducts",
  SupplierAddCustomers = "SupplierAddCustomers",
  PaymentMerchant = "PaymentMerchant",
  PayUpstock = "PayUpstock",
  DeliveryGroupSettings = "DeliveryGroupSettings"
}

export interface GettingStartedDetails {
  actions: GettingStartedAction[];
  isCompleted: boolean;
}

export interface HideModalCommand {
  modals: ModalToHide[];
}

export interface HideModalProperties {
  hideApproveOrderConfirmationModal: boolean;
  hidePriceMismatchWarningModal: boolean;
  hideUpdateProductInProviderModal: boolean;
  hideBasePriceInfoModal: boolean;
  hideCannotSyncOrderModal: boolean;
  hideApproveOrderPaymentModal: boolean;
  hideOrderLineProblemsModalForProviderOrders: boolean;
  hideFoodstuffsOrderEditWarningModal: boolean;
  hideMobilePushNotificationsModal: boolean;
  hideWoolworthsOrderEditWarningModal: boolean;
  hideWoolworthsShipInvoiceWarningModal: boolean;
  hideScheduledOrderForceApprovedWarningModal: boolean;
}

export interface InviteDetails {
  tradingName: string;
  companyId: string;
  isAccepted: boolean;
  isAcceptedByCurrentUser: boolean;
  expectedAccountEmail?: string | null;
  referrerDetails?: BasicCompanyProfile | null;
}

export interface InviteFromClaimTokenResult {
  invitedEmail: string;
}

export interface LinkUserExternalIdentityCommand {
  provider: string;
  connectionAccessToken: string;
}

export enum MenuGroupType {
  Buyer = "Buyer",
  Supplier = "Supplier"
}

export interface Metadata {
  charges: Charge[];
  /** @format double */
  minimumMonthlyFee?: number | null;
}

export enum ModalToHide {
  ApproveOrder = "ApproveOrder",
  ApproveOrderPayment = "ApproveOrderPayment",
  PriceMismatch = "PriceMismatch",
  UpdateProductInProvider = "UpdateProductInProvider",
  BasePriceInfoModal = "BasePriceInfoModal",
  CannotSyncOrder = "CannotSyncOrder",
  OrderLineProblemsForProviderOrders = "OrderLineProblemsForProviderOrders",
  HideFoodstuffsOrderEditWarningModal = "HideFoodstuffsOrderEditWarningModal",
  MobilePushNotificationsModal = "MobilePushNotificationsModal",
  HideWoolworthsOrderEditWarningModal = "HideWoolworthsOrderEditWarningModal",
  HideWoolworthsShipInvoiceWarningModal = "HideWoolworthsShipInvoiceWarningModal",
  HideScheduledOrderForceApprovedWarningModal = "HideScheduledOrderForceApprovedWarningModal"
}

export interface NotificationPreference {
  notification: NotificationTemplate;
  metadata: NotificationPreferenceMetadata;
  isPushEnabled?: boolean | null;
  isEmailEnabled?: boolean | null;
}

export interface NotificationPreferenceMetadata {
  isBuyer: boolean;
  isSupplier: boolean;
}

export enum NotificationTemplate {
  OrderCommentCreated = "OrderCommentCreated",
  OrderSupplierCreatedPaymentRequired = "OrderSupplierCreatedPaymentRequired",
  OrderSupplierCreated = "OrderSupplierCreated",
  OrderBuyerCreated = "OrderBuyerCreated",
  OrderScheduleCreated = "OrderScheduleCreated",
  OrderStatusSupplierApproved = "OrderStatusSupplierApproved",
  OrderStatusSupplierShipped = "OrderStatusSupplierShipped",
  OrderStatusSupplierCancelled = "OrderStatusSupplierCancelled",
  OrderStatusBuyerCancelled = "OrderStatusBuyerCancelled",
  OrderStatusSchedulePlaced = "OrderStatusSchedulePlaced",
  Custom = "Custom",
  BuyerAddressUpdated = "BuyerAddressUpdated"
}

export enum OrderNotificationLevel {
  All = "All",
  OnlyConnected = "OnlyConnected",
  None = "None"
}

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface PaymentSource {
  id?: string | null;
  /** @format int64 */
  resourceVersion?: number | null;
  /** @format date-time */
  updatedAt?: string | null;
  /** @format date-time */
  createdAt: string;
  customerId?: string | null;
  paymentSourceType: TypeEnum;
  referenceId?: string | null;
  status: StatusEnum;
  gateway: GatewayEnum;
  gatewayAccountId?: string | null;
  ipAddress?: string | null;
  issuingCountry?: string | null;
  card?: PaymentSourceCard | null;
  bankAccount?: PaymentSourceBankAccount | null;
  boleto?: PaymentSourceCustVoucherSource | null;
  billingAddress?: PaymentSourceBillingAddress | null;
  amazonPayment?: PaymentSourceAmazonPayment | null;
  upi?: PaymentSourceUpi | null;
  paypal?: PaymentSourcePaypal | null;
  venmo?: PaymentSourceVenmo | null;
  klarnaPayNow?: PaymentSourceKlarnaPayNow | null;
  mandates?: PaymentSourceMandate[] | null;
  deleted: boolean;
  businessEntityId?: string | null;
}

export interface PaymentSourceAmazonPayment {
  email?: string | null;
  agreementId?: string | null;
}

export interface PaymentSourceBankAccount {
  last4?: string | null;
  nameOnAccount?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  directDebitScheme?: DirectDebitSchemeEnum | null;
  bankName?: string | null;
  mandateId?: string | null;
  accountType?: AccountTypeEnum | null;
  echeckType?: EcheckTypeEnum | null;
  accountHolderType?: AccountHolderTypeEnum | null;
  email?: string | null;
}

export interface PaymentSourceBillingAddress {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  phone?: string | null;
  line1?: string | null;
  line2?: string | null;
  line3?: string | null;
  city?: string | null;
  stateCode?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  validationStatus?: ValidationStatusEnum | null;
}

export interface PaymentSourceCard {
  firstName?: string | null;
  lastName?: string | null;
  iin?: string | null;
  last4?: string | null;
  brand: BrandEnum;
  fundingType: FundingTypeEnum;
  /** @format int32 */
  expiryMonth: number;
  /** @format int32 */
  expiryYear: number;
  billingAddr1?: string | null;
  billingAddr2?: string | null;
  billingCity?: string | null;
  billingStateCode?: string | null;
  billingState?: string | null;
  billingCountry?: string | null;
  billingZip?: string | null;
  maskedNumber?: string | null;
}

export interface PaymentSourceCustVoucherSource {
  last4?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export interface PaymentSourceKlarnaPayNow {
  email?: string | null;
}

export interface PaymentSourceMandate {
  id?: string | null;
  subscriptionId?: string | null;
  /** @format date-time */
  createdAt: string;
}

export interface PaymentSourcePaypal {
  email?: string | null;
  agreementId?: string | null;
}

export interface PaymentSourceUpi {
  vpa?: string | null;
}

export interface PaymentSourceVenmo {
  userName?: string | null;
}

export enum Permission {
  ViewCompanyProfile = "ViewCompanyProfile",
  UpdateCompanyProfile = "UpdateCompanyProfile",
  GetNotificationPreferences = "GetNotificationPreferences",
  UpdateNotificationPreferences = "UpdateNotificationPreferences",
  ViewGettingStarted = "ViewGettingStarted",
  UpdateGettingStarted = "UpdateGettingStarted",
  UploadProductList = "UploadProductList",
  UploadCustomerList = "UploadCustomerList",
  ActivateSupplier = "ActivateSupplier",
  DeactivateSupplier = "DeactivateSupplier",
  ViewBilling = "ViewBilling",
  UpdateBilling = "UpdateBilling",
  ViewUsers = "ViewUsers",
  ManageUsers = "ManageUsers",
  ViewUserInvites = "ViewUserInvites",
  CreateUserInvite = "CreateUserInvite",
  DeleteUserInvite = "DeleteUserInvite",
  UpdateUserInvite = "UpdateUserInvite",
  CreateCompanyInvite = "CreateCompanyInvite",
  SupplierViewOrderSettings = "SupplierViewOrderSettings",
  SupplierUpdateOrderSettings = "SupplierUpdateOrderSettings",
  SupplierViewSettings = "SupplierViewSettings",
  BuyerViewSupplierSettings = "BuyerViewSupplierSettings",
  SupplierUpdateSettings = "SupplierUpdateSettings",
  SupplierViewNotificationForwardingSettings = "SupplierViewNotificationForwardingSettings",
  SupplierUpdateNotificationForwardingSettings = "SupplierUpdateNotificationForwardingSettings",
  SupplierUpdatePaymentSettings = "SupplierUpdatePaymentSettings",
  SupplierViewPaymentSettings = "SupplierViewPaymentSettings",
  ViewApiConnections = "ViewApiConnections",
  ConnectApi = "ConnectApi",
  DisconnectApi = "DisconnectApi",
  ViewProviderContacts = "ViewProviderContacts",
  CreateProviderContacts = "CreateProviderContacts",
  ViewProviderSettings = "ViewProviderSettings",
  UpdateProviderSettings = "UpdateProviderSettings",
  LinkProviderProducts = "LinkProviderProducts",
  CreateOrderExternalDocument = "CreateOrderExternalDocument",
  ViewOrderExternalDocument = "ViewOrderExternalDocument",
  ForwardInvoice = "ForwardInvoice",
  CreateProviderShipment = "CreateProviderShipment",
  CreateProduct = "CreateProduct",
  UpdateProduct = "UpdateProduct",
  ImportProducts = "ImportProducts",
  DeleteProduct = "DeleteProduct",
  SupplierViewProducts = "SupplierViewProducts",
  BuyerViewProducts = "BuyerViewProducts",
  BuyerViewAllProducts = "BuyerViewAllProducts",
  BuyerViewPublicProducts = "BuyerViewPublicProducts",
  CreateQuickAddProducts = "CreateQuickAddProducts",
  RemoveQuickAddProducts = "RemoveQuickAddProducts",
  ViewQuickAddProducts = "ViewQuickAddProducts",
  SupplierCreateProductPromotions = "SupplierCreateProductPromotions",
  SupplierDeleteProductPromotions = "SupplierDeleteProductPromotions",
  ViewConnectedBuyers = "ViewConnectedBuyers",
  UpdateConnectedBuyers = "UpdateConnectedBuyers",
  ManageBuyerInvites = "ManageBuyerInvites",
  UpdateBuyerSettings = "UpdateBuyerSettings",
  ViewPendingBuyers = "ViewPendingBuyers",
  AddPendingBuyer = "AddPendingBuyer",
  ViewPendingBuyer = "ViewPendingBuyer",
  DeletePendingBuyer = "DeletePendingBuyer",
  UpdatePendingBuyer = "UpdatePendingBuyer",
  ViewConnectedSuppliers = "ViewConnectedSuppliers",
  ViewSupplierDirectory = "ViewSupplierDirectory",
  ViewSuppliersProfile = "ViewSuppliersProfile",
  CreateSupplierInvite = "CreateSupplierInvite",
  DeleteSupplierInvite = "DeleteSupplierInvite",
  ViewSupplierInvites = "ViewSupplierInvites",
  AddPendingSupplier = "AddPendingSupplier",
  ViewPendingSuppliers = "ViewPendingSuppliers",
  DeletePendingSupplier = "DeletePendingSupplier",
  BuyerViewOrders = "BuyerViewOrders",
  BuyerCreateOrder = "BuyerCreateOrder",
  BuyerUpdateOrder = "BuyerUpdateOrder",
  SupplierViewOrders = "SupplierViewOrders",
  SupplierCreateOrder = "SupplierCreateOrder",
  SupplierUpdateOrder = "SupplierUpdateOrder",
  SupplierUploadOrderFile = "SupplierUploadOrderFile",
  AddComment = "AddComment",
  ViewInvoice = "ViewInvoice",
  DeleteOrder = "DeleteOrder",
  ViewPackingSlips = "ViewPackingSlips",
  ViewEmailPreviews = "ViewEmailPreviews",
  ReportOrderIssue = "ReportOrderIssue",
  ViewOrderIssues = "ViewOrderIssues",
  ResolveOrderIssue = "ResolveOrderIssue",
  ViewPriceLists = "ViewPriceLists",
  CreatePriceList = "CreatePriceList",
  UpdatePriceList = "UpdatePriceList",
  DeletePriceList = "DeletePriceList",
  DuplicatePriceList = "DuplicatePriceList",
  ActivatePriceLists = "ActivatePriceLists",
  DeactivatePriceLists = "DeactivatePriceLists",
  SyncPriceLists = "SyncPriceLists",
  SupplierCreateStandingOrderSchedules = "SupplierCreateStandingOrderSchedules",
  SupplierUpdateStandingOrderSchedules = "SupplierUpdateStandingOrderSchedules",
  SupplierViewStandingOrderSchedules = "SupplierViewStandingOrderSchedules",
  BuyerUpdateStandingOrderSchedules = "BuyerUpdateStandingOrderSchedules",
  ViewFavourites = "ViewFavourites",
  CreateFavourite = "CreateFavourite",
  UpdateFavourites = "UpdateFavourites",
  DeleteFavourites = "DeleteFavourites",
  UpdateFavouriteProducts = "UpdateFavouriteProducts",
  SupplierViewTags = "SupplierViewTags",
  SupplierDeleteTags = "SupplierDeleteTags",
  SupplierCreateTags = "SupplierCreateTags",
  DeleteLinkedBuyerTags = "DeleteLinkedBuyerTags",
  CreateLinkedBuyerTags = "CreateLinkedBuyerTags",
  UpdateLinkedBuyerTags = "UpdateLinkedBuyerTags",
  SupplierViewReports = "SupplierViewReports",
  SupplierViewOrdersExportReport = "SupplierViewOrdersExportReport",
  SupplierViewProductionReport = "SupplierViewProductionReport",
  SupplierViewPaymentsPayoutReport = "SupplierViewPaymentsPayoutReport",
  BuyerViewOrdersReport = "BuyerViewOrdersReport",
  SupplierCreateNewsfeedPost = "SupplierCreateNewsfeedPost",
  SupplierDeleteNewsfeedPost = "SupplierDeleteNewsfeedPost",
  SupplierUpdateNewsfeedPost = "SupplierUpdateNewsfeedPost",
  CreateNewsfeedPostReaction = "CreateNewsfeedPostReaction",
  DeleteNewsfeedPostReaction = "DeleteNewsfeedPostReaction",
  CreateNewsfeedsBuyerCompany = "CreateNewsfeedsBuyerCompany",
  ViewSupplierDashboard = "ViewSupplierDashboard",
  PaymentLinkMerchantAccount = "PaymentLinkMerchantAccount",
  PaymentViewMerchantAccount = "PaymentViewMerchantAccount",
  PaymentCreateMerchantAccount = "PaymentCreateMerchantAccount",
  PaymentViewMethods = "PaymentViewMethods",
  PaymentSetupMethods = "PaymentSetupMethods",
  PaymentUpdateForOrder = "PaymentUpdateForOrder",
  PaymentTakeForOrder = "PaymentTakeForOrder",
  PaymentViewPayouts = "PaymentViewPayouts",
  PaymentCreatePaymentTerms = "PaymentCreatePaymentTerms",
  PaymentViewPaymentTerms = "PaymentViewPaymentTerms",
  PaymentUpdatePaymentTerms = "PaymentUpdatePaymentTerms",
  PaymentDeletePaymentTerms = "PaymentDeletePaymentTerms",
  ViewTaxRates = "ViewTaxRates",
  ViewProviderTaxRates = "ViewProviderTaxRates",
  ViewProviderAccounts = "ViewProviderAccounts",
  CreateProviderAccounts = "CreateProviderAccounts",
  SupplierCreateDeliveryGroups = "SupplierCreateDeliveryGroups",
  SupplierViewDeliveryGroups = "SupplierViewDeliveryGroups",
  SupplierUpdateDeliveryGroups = "SupplierUpdateDeliveryGroups",
  SupplierDeleteDeliveryGroups = "SupplierDeleteDeliveryGroups",
  SupplierAutocategoriseProducts = "SupplierAutocategoriseProducts",
  SupplierViewFeatureCategories = "SupplierViewFeatureCategories",
  SupplierUpdateFeatureCategories = "SupplierUpdateFeatureCategories",
  GlobalViewProductTaxonomy = "GlobalViewProductTaxonomy"
}

export interface PhoneNumber {
  countryCallingCode?: string | null;
  number: string;
}

export enum PlanType {
  InternalUpstock = "InternalUpstock",
  SupplierFree = "SupplierFree",
  Marketplace = "Marketplace",
  Logistics = "Logistics",
  BuyerPro = "BuyerPro",
  BuyerFree = "BuyerFree"
}

export enum Platform {
  Ios = "Ios",
  Android = "Android"
}

export interface ProviderFeature {
  name: ProviderOffering;
  isDefault: boolean;
  permissions: ProviderFeatureAccess[];
}

export enum ProviderFeatureAccess {
  Create = "Create",
  Edit = "Edit",
  Delete = "Delete",
  Pull = "Pull",
  DeepLink = "DeepLink",
  PullPdfs = "PullPdfs",
  SendToCustomer = "SendToCustomer",
  Sync = "Sync"
}

export enum ProviderOffering {
  Products = "Products",
  Invoices = "Invoices",
  Contacts = "Contacts",
  IncomingOrders = "IncomingOrders",
  OutgoingOrders = "OutgoingOrders",
  PriceLists = "PriceLists",
  Shipping = "Shipping"
}

export interface RedeemClaimTokenCommand {
  /** @format uuid */
  token: string;
}

export interface RegisterPushNotificationTokenCommand {
  token: string;
  platform: Platform;
}

export interface RejectSignupRequestRequest {
  rejectionReason?: SignupRequestRejectionReason | null;
}

export enum Role {
  Owner = "Owner",
  Full = "Full",
  LimitedBuyer = "LimitedBuyer"
}

export interface SalesDetails {
  accountingSoftware?: string | null;
  inventorySoftware?: string | null;
  suppliedCompanies: SuppliedCompanies[];
}

export interface SignupAttribution {
  source: SignupAttributionSource;
  freeText?: string | null;
}

export enum SignupAttributionSource {
  Friend = "Friend",
  News = "News",
  Accountant = "Accountant",
  SearchEngine = "SearchEngine",
  Instagram = "Instagram",
  LinkedIn = "LinkedIn",
  Facebook = "Facebook",
  MobileAppStore = "MobileAppStore",
  XeroAppStore = "XeroAppStore",
  TradeShow = "TradeShow",
  Other = "Other",
  Upstock = "Upstock",
  TradePartner = "TradePartner"
}

export enum SignupRequestRejectionReason {
  Duplicate = "Duplicate",
  PersonalAccount = "PersonalAccount",
  NoResponse = "NoResponse",
  Other = "Other",
  AlreadyCompleted = "AlreadyCompleted",
  SuspectedSpam = "SuspectedSpam",
  UnsupportedRegion = "UnsupportedRegion"
}

export enum SignupRequestStatus {
  Pending = "Pending",
  Completed = "Completed",
  Rejected = "Rejected",
  WaitingForResponse = "WaitingForResponse"
}

export interface SignupRequestSummary {
  signupRequestId: string;
  /** @format date-time */
  createdDateUtc: string;
  email: string;
  tradingName?: string | null;
  legalBusinessName?: string | null;
  address?: string | null;
  website?: string | null;
  description?: string | null;
  isBuyer: boolean;
  isSupplier: boolean;
  supplierReferrerId?: string | null;
  supplierReferrer?: SupplierReferrerSummary | null;
  hasTradingRelationship?: boolean | null;
  matchedCompanyId?: string | null;
  status: SignupRequestStatus;
  salesDetails?: SalesDetails | null;
  pendingInviteId?: string | null;
  pendingInviteEmail?: string | null;
  country?: Country | null;
  businessNumber?: string | null;
  attribution?: SignupAttribution | null;
  phoneNumber?: PhoneNumber | null;
  /** @deprecated */
  phone?: string | null;
}

export enum StatusEnum {
  UnKnown = "UnKnown",
  Valid = "Valid",
  Expiring = "Expiring",
  Expired = "Expired",
  Invalid = "Invalid",
  PendingVerification = "PendingVerification"
}

export enum SuppliedCompanies {
  Foodstuffs = "Foodstuffs",
  Progressive = "Progressive",
  Bidfoods = "Bidfoods",
  ServiceFoods = "ServiceFoods",
  Gilmours = "Gilmours",
  Woolworths = "Woolworths",
  IndependentGrocersAustralia = "IndependentGrocersAustralia",
  Coles = "Coles",
  Aldi = "Aldi",
  Costco = "Costco",
  Other = "Other"
}

export interface SupplierReferrerSummary {
  tradingName: string;
  isClaimed: boolean;
}

export enum TypeEnum {
  UnKnown = "UnKnown",
  Card = "Card",
  PaypalExpressCheckout = "PaypalExpressCheckout",
  AmazonPayments = "AmazonPayments",
  DirectDebit = "DirectDebit",
  Generic = "Generic",
  Alipay = "Alipay",
  Unionpay = "Unionpay",
  ApplePay = "ApplePay",
  WechatPay = "WechatPay",
  Ideal = "Ideal",
  GooglePay = "GooglePay",
  Sofort = "Sofort",
  Bancontact = "Bancontact",
  Giropay = "Giropay",
  Dotpay = "Dotpay",
  Upi = "Upi",
  NetbankingEmandates = "NetbankingEmandates",
  Venmo = "Venmo",
  PayTo = "PayTo",
  FasterPayments = "FasterPayments",
  SepaInstantTransfer = "SepaInstantTransfer",
  AutomatedBankTransfer = "AutomatedBankTransfer",
  KlarnaPayNow = "KlarnaPayNow"
}

export enum UnitType {
  Custom = "Custom",
  PerMonth = "PerMonth",
  PerOrder = "PerOrder",
  PerLine = "PerLine",
  PerCustomer = "PerCustomer"
}

export interface UpdateSignupRequestRequest {
  email?: string | null;
  isBuyer?: boolean | null;
  isSupplier?: boolean | null;
  tradingName?: string | null;
  legalBusinessName?: string | null;
  address?: string | null;
  website?: string | null;
  description?: string | null;
  supplierReferrerId?: string | null;
  hasTradingRelationship?: boolean | null;
  salesDetails?: SalesDetails | null;
  alpha2CountryCode?: string | null;
  signupReferralId?: string | null;
  businessNumber?: string | null;
  attribution?: SignupAttribution | null;
  phoneNumber?: PhoneNumber | null;
  /** @deprecated */
  phone?: string | null;
}

export interface UpdateSignupRequestStatusRequest {
  status: SignupRequestStatus;
}

export enum UpdateSource {
  Signup = "Signup"
}

export interface UpdateUserLatestCompanyCommand {
  id: string;
}

export interface UpdateUserProfileRequest {
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  source?: UpdateSource | null;
}

export interface UpdateUserRolesCommand {
  roles: Role[];
}

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: Role[];
}

export interface UserExternalIdentity {
  email: string;
  userId: string;
  provider: string;
  isPrimary: boolean;
}

export interface UserNotificationPreference {
  buyerLevel: OrderNotificationLevel;
  supplierLevel: OrderNotificationLevel;
}

export interface UserPagedSet {
  links: PageLinks;
  data: User[];
  /** @format int32 */
  total?: number | null;
}

export interface UserProfile {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
}

export interface UserSettings {
  /** @format date-time */
  acceptedTermsConditionsDateUtc?: string | null;
  userFlags: HideModalProperties;
  hasAcceptedLatestTermsConditions: boolean;
}

export enum ValidationStatusEnum {
  UnKnown = "UnKnown",
  NotValidated = "NotValidated",
  Valid = "Valid",
  PartiallyValid = "PartiallyValid",
  Invalid = "Invalid"
}

export interface Xero {
  userId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

export interface XeroSignupReferral {
  externalId: string;
  metadata: Xero;
}

export namespace Directory {
  /**
   * No description
   * @tags Billing
   * @name InternalBillingList
   * @summary  (Auth Permissions: ViewBilling)
   * @request GET:/directory/internal/billing
   * @secure
   * @response `200` `BillingDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalBillingList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BillingDetails;
  } /**
 * @description See the Chargebee docs for more info: https://www.chargebee.com/checkout-portal-docs/api-integration-overview.html#portal-via-api
 * @tags Chargebee
 * @name InternalChargebeeSelfServePortalCreateSessionCreate
 * @summary Use the API to create a Chargebee Portal Session token.  This is used
by the UI to launch the Chargebee Self-Service Portal. (Auth Permissions: UpdateBilling)
 * @request POST:/directory/internal/chargebee/self-serve-portal/create-session
 * @secure
 * @response `200` `any` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalChargebeeSelfServePortalCreateSessionCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * @description See the Chargebee docs for more info: https://apidocs.chargebee.com/docs/api/payment_sources#retrieve_a_payment_source
   * @tags Chargebee
   * @name InternalChargebeePaymentMethodList
   * @summary Retrieve payment method details for the current company from Chargebee. (Auth Permissions: ViewBilling)
   * @request GET:/directory/internal/chargebee/payment-method
   * @secure
   * @response `200` `PaymentSource` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalChargebeePaymentMethodList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentSource;
  }
  /**
   * No description
   * @tags CompanyClaim
   * @name InternalCompanyClaimCreate
   * @request POST:/directory/internal/company-claim/{id}
   * @response `200` `InviteFromClaimTokenResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompanyClaimCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RedeemClaimTokenCommand;
    export type RequestHeaders = {};
    export type ResponseBody = InviteFromClaimTokenResult;
  }
  /**
   * No description
   * @tags CompanyClaim
   * @name InternalCompanyClaimQrDetail
   * @request GET:/directory/internal/company-claim/qr/{id}
   * @response `200` `ClaimCompanyQrCode` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompanyClaimQrDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ClaimCompanyQrCode;
  }
  /**
   * No description
   * @tags CompanySettings
   * @name InternalCompaniesSettingsDetail
   * @request GET:/directory/internal/companies/{id}/settings
   * @response `200` `CompanySettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSettingsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanySettings;
  }
  /**
   * No description
   * @tags CompanySettings
   * @name InternalCompaniesSettingsCreate
   * @summary Note this is not currently exposed in the UI. Company Settings are primarily updated via Retool.
   * @request POST:/directory/internal/companies/{id}/settings
   * @response `200` `CompanySettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSettingsCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CompanySettings;
    export type RequestHeaders = {};
    export type ResponseBody = CompanySettings;
  }
  /**
   * No description
   * @tags ConfirmEmail
   * @name InternalConfirmEmailCreate
   * @summary Confirm the email address for the current user.
   * @request POST:/directory/internal/confirm-email
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalConfirmEmailCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConfirmEmailCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectList
   * @summary  (Auth Permissions: ViewApiConnections)
   * @request GET:/directory/internal/connect
   * @secure
   * @response `200` `(ApiConnection)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ApiConnection[];
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectMetadataList
   * @summary  (Auth Permissions: ViewApiConnections)
   * @request GET:/directory/internal/connect/metadata
   * @secure
   * @response `200` `ConnectionMetadata` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectMetadataList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ConnectionMetadata;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectXeroList
   * @summary  (Auth Permissions: ConnectApi)
   * @request GET:/directory/internal/connect/xero
   * @secure
   * @response `200` `string` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectXeroList {
    export type RequestParams = {};
    export type RequestQuery = {
      redirect_uri?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectXeroDelete
   * @summary Remove a company's connection to Xero. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/xero
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectXeroDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectXeroCallbackList
   * @summary  (Auth Permissions: ConnectApi)
   * @request GET:/directory/internal/connect/xero/callback
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectXeroCallbackList {
    export type RequestParams = {};
    export type RequestQuery = {
      code?: string;
      state?: string;
      redirect_uri?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectTimelineList
   * @summary  (Auth Permissions: ViewApiConnections)
   * @request GET:/directory/internal/connect/timeline
   * @secure
   * @response `200` `(ConnectionTimeline)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectTimelineList {
    export type RequestParams = {};
    export type RequestQuery = {
      provider?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ConnectionTimeline[];
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectUnleashedCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/unleashed
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectUnleashedCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToServiceCredentialsProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectUnleashedDelete
   * @summary Remove a company's connection to Unleashed. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/unleashed
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectUnleashedDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectFoodstuffsCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/foodstuffs
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectFoodstuffsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToServiceCredentialsProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectFoodstuffsDelete
   * @summary Remove a company's connection to Foodstuffs. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/foodstuffs
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectFoodstuffsDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectCin7OmniCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/cin7omni
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectCin7OmniCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToServiceCredentialsProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectCin7OmniDelete
   * @summary Remove a company's connection to Cin7 Omni. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/cin7omni
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectCin7OmniDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectWebhooksCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/webhooks
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectWebhooksCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectWebhooksDelete
   * @summary  (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/webhooks
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectWebhooksDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectWebhooksDashboardAccessCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/webhooks/dashboard-access
   * @secure
   * @response `200` `DashboardAccess` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectWebhooksDashboardAccessCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DashboardAccess;
  } /**
 * No description
 * @tags Connect
 * @name InternalConnectMyintegratorCreate
 * @summary Returns access to a portal in my integrator for the current company.
Creates the company in MI if they're not already connected. (Auth Permissions: ConnectApi)
 * @request POST:/directory/internal/connect/myintegrator
 * @secure
 * @response `200` `string` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace InternalConnectMyintegratorCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToMyIntegratorRequest;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectMyintegratorDelete
   * @summary Remove a company's connection to my integrator. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/myintegrator
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectMyintegratorDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectCodatCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/codat
   * @secure
   * @response `200` `string` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectCodatCreate {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format uri */
      baseRedirectUrl: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectCodatDelete
   * @summary Remove a company's connection to Unleashed. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/codat
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectCodatDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectCodatCallbackCreate
   * @summary  (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/codat/callback/{codatId}
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectCodatCallbackCreate {
    export type RequestParams = {
      codatId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectDearCreate
   * @summary Add a company's connection to Dear. (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/dear
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectDearCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToServiceCredentialsProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectDearDelete
   * @summary Remove a company's connection to Dear. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/dear
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectDearDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectStarshipitCreate
   * @summary Connect a company to their Starshipit account. (Auth Permissions: ConnectApi)
   * @request POST:/directory/internal/connect/starshipit
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectStarshipitCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConnectToServiceCredentialsProviderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Connect
   * @name InternalConnectStarshipitDelete
   * @summary Remove a company's connection to Starshipit. (Auth Permissions: DisconnectApi)
   * @request DELETE:/directory/internal/connect/starshipit
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalConnectStarshipitDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Features
   * @name InternalFeaturesList
   * @request GET:/directory/internal/features
   * @response `200` `Record<string,string>` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalFeaturesList {
    export type RequestParams = {};
    export type RequestQuery = {
      key?: string;
      feature?: string[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, string>;
  }
  /**
   * No description
   * @tags GettingStarted
   * @name InternalGettingStartedList
   * @summary  (Auth Permissions: ViewGettingStarted)
   * @request GET:/directory/internal/getting-started
   * @secure
   * @response `200` `GettingStartedDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalGettingStartedList {
    export type RequestParams = {};
    export type RequestQuery = {
      type?: MenuGroupType;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GettingStartedDetails;
  }
  /**
   * No description
   * @tags GettingStarted
   * @name InternalGettingStartedCreate
   * @summary  (Auth Permissions: UpdateGettingStarted)
   * @request POST:/directory/internal/getting-started
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalGettingStartedCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CompleteGettingStartedStepCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Invites
   * @name InternalInvitesAcceptCreate
   * @request POST:/directory/internal/invites/{inviteId}/accept
   * @response `200` `AcceptInviteResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalInvitesAcceptCreate {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptInviteResponse;
  }
  /**
   * No description
   * @tags Invites
   * @name InternalInvitesDetail
   * @request GET:/directory/internal/invites/{inviteId}
   * @response `200` `InviteDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalInvitesDetail {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {
      referrerId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InviteDetails;
  }
  /**
   * No description
   * @tags Invites
   * @name InternalInvitesResendCreate
   * @summary Resend the invite with the given ID.
   * @request POST:/directory/internal/invites/{inviteId}/resend
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalInvitesResendCreate {
    export type RequestParams = {
      inviteId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Permissions
   * @name InternalPermissionsList
   * @request GET:/directory/internal/permissions
   * @response `200` `CompanyPermissions` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalPermissionsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyPermissions;
  }
  /**
   * @description After completing a signup request, an event will be raised from which several actions will occur: - if the request came with a supplier referral, then the company will be connected to that supplier or an invite to trade sent to that supplier - if the request is for a buyer company it will be connected to the Free Sample store - a confirmation email will be sent to the requestor, inviting them into the created company
   * @tags Signup
   * @name InternalCompaniesSignupCompleteCreate
   * @summary Mark a signup request as complete.
   * @request POST:/directory/internal/companies/signup/{signupRequestId}/complete
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupCompleteCreate {
    export type RequestParams = {
      signupRequestId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CompleteSignupRequestCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description When determining whether to create/update, a lookup of the signup request using the current user is used.
   * @tags Signup
   * @name InternalCompaniesSignupCreate
   * @summary Create a new signup request or update an existing one.
   * @request POST:/directory/internal/companies/signup
   * @response `200` `SignupRequestSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateSignupRequestCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SignupRequestSummary;
  }
  /**
   * No description
   * @tags Signup
   * @name InternalCompaniesSignupList
   * @summary Return the current user's existing signup request, if available.
   * @request GET:/directory/internal/companies/signup
   * @response `200` `SignupRequestSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `404` `void` Not Found
   */
  export namespace InternalCompaniesSignupList {
    export type RequestParams = {};
    export type RequestQuery = {
      status?: SignupRequestStatus[];
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SignupRequestSummary;
  }
  /**
   * @description Rejecting a signup request will take it out of most of our queries, including the queue of signups in the Signup requests Retool dashboard.
   * @tags Signup
   * @name InternalCompaniesSignupRejectCreate
   * @summary Mark a signup request as rejected.
   * @request POST:/directory/internal/companies/signup/{signupRequestId}/reject
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupRejectCreate {
    export type RequestParams = {
      signupRequestId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RejectSignupRequestRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Signup
   * @name InternalCompaniesSignupCreate2
   * @summary Update an existing signup request.
   * @request POST:/directory/internal/companies/signup/{signupRequestId}
   * @originalName internalCompaniesSignupCreate
   * @duplicate
   * @response `200` `SignupRequestSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupCreate2 {
    export type RequestParams = {
      signupRequestId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateSignupRequestRequest;
    export type RequestHeaders = {};
    export type ResponseBody = SignupRequestSummary;
  }
  /**
   * @description Mainly used for moving between the various types of 'pending' state so that CS can more easily know what is happening with a signup.
   * @tags Signup
   * @name InternalCompaniesSignupStatusCreate
   * @summary Update the signup request to a more-specific pending status.
   * @request POST:/directory/internal/companies/signup/{signupRequestId}/status
   * @response `200` `SignupRequestSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupStatusCreate {
    export type RequestParams = {
      signupRequestId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateSignupRequestStatusRequest;
    export type RequestHeaders = {};
    export type ResponseBody = SignupRequestSummary;
  }
  /**
   * No description
   * @tags Signup
   * @name InternalCompaniesSignupReferralXeroInitiateList
   * @request GET:/directory/internal/companies/signup/referral/xero/initiate
   * @response `200` `string` Success
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupReferralXeroInitiateList {
    export type RequestParams = {};
    export type RequestQuery = {
      redirectUrl: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Signup
   * @name InternalCompaniesSignupReferralXeroCreate
   * @request POST:/directory/internal/companies/signup/referral/xero
   * @response `200` `XeroSignupReferral` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupReferralXeroCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateXeroSignupReferralCommand;
    export type RequestHeaders = {};
    export type ResponseBody = XeroSignupReferral;
  }
  /**
   * No description
   * @tags Signup
   * @name InternalCompaniesSignupCompaniesList
   * @request GET:/directory/internal/companies/signup/companies
   * @response `200` `GetCompanySuggestionsResult` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalCompaniesSignupCompaniesList {
    export type RequestParams = {};
    export type RequestQuery = {
      countryCode?: Alpha2Code;
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetCompanySuggestionsResult;
  } /**
 * @description The new user is created from the details of the currently-logged-in auth0 user.
 * @tags Users
 * @name InternalUsersCreate
 * @summary Create a new user in our system or return the existing user if one
has already been created.
 * @request POST:/directory/internal/users
 * @response `200` `UserProfile` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalUsersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserProfile;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersList
   * @summary Returns a paged list of users in the current company. (Auth Permissions: ViewUsers)
   * @request GET:/directory/internal/users
   * @secure
   * @response `200` `UserPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalUsersList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserPagedSet;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersDetail
   * @summary Returns the user that matches the given userId. (Auth Permissions: ViewUsers)
   * @request GET:/directory/internal/users/{userId}
   * @secure
   * @response `200` `User` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace InternalUsersDetail {
    export type RequestParams = {
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersDelete
   * @summary  (Auth Permissions: ManageUsers)
   * @request DELETE:/directory/internal/users/{userId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalUsersDelete {
    export type RequestParams = {
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description To update other properties, see the <see cref="M:Api.Controllers.Directory.Internal.UsersController.UpdateProfile(Api.Domain.Features.User.UpdateUserProfileRequest)">update user profile</see> endpoint.
   * @tags Users
   * @name InternalUsersCreate2
   * @summary Update a user's roles within a company. (Auth Permissions: ManageUsers)
   * @request POST:/directory/internal/users/{userId}
   * @originalName internalUsersCreate
   * @duplicate
   * @secure
   * @response `200` `User` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalUsersCreate2 {
    export type RequestParams = {
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateUserRolesCommand;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersCompaniesList
   * @request GET:/directory/internal/users/companies
   * @response `200` `(CompanySummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersCompaniesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanySummary[];
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersLatestCompanyList
   * @request GET:/directory/internal/users/latest-company
   * @response `200` `CompanySummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersLatestCompanyList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanySummary;
  } /**
 * No description
 * @tags Users
 * @name InternalUsersLatestCompanyCreate
 * @summary Updates the current user's 'last company' value, so that next time
they land in the app we can send them back to this company.
 * @request POST:/directory/internal/users/latest-company
 * @response `200` `CompanySummary` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalUsersLatestCompanyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateUserLatestCompanyCommand;
    export type RequestHeaders = {};
    export type ResponseBody = CompanySummary;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersProfileList
   * @request GET:/directory/internal/users/profile
   * @response `200` `UserProfile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersProfileList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserProfile;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersProfileUpdate
   * @summary Update an existing user profile.
   * @request PUT:/directory/internal/users/profile
   * @response `200` `UserProfile` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersProfileUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateUserProfileRequest;
    export type RequestHeaders = {};
    export type ResponseBody = UserProfile;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersExternalIdentitiesList
   * @request GET:/directory/internal/users/external-identities
   * @response `200` `(UserExternalIdentity)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersExternalIdentitiesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserExternalIdentity[];
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersExternalIdentitiesCreate
   * @request POST:/directory/internal/users/external-identities
   * @response `200` `(UserExternalIdentity)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersExternalIdentitiesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LinkUserExternalIdentityCommand;
    export type RequestHeaders = {};
    export type ResponseBody = UserExternalIdentity[];
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersExternalIdentitiesDelete
   * @request DELETE:/directory/internal/users/external-identities/{provider}/{userId}
   * @response `200` `(UserExternalIdentity)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersExternalIdentitiesDelete {
    export type RequestParams = {
      provider: string;
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserExternalIdentity[];
  }
  /**
   * @description Replaced by the more fine-grained notifications at `/notifications/preferences`
   * @tags Users
   * @name InternalUsersNotificationPreferencesList
   * @summary Old notifications endpoint for fetching a user's notification preferences. (Auth Permissions: GetNotificationPreferences)
   * @request GET:/directory/internal/users/notification-preferences
   * @deprecated
   * @secure
   * @response `200` `UserNotificationPreference` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalUsersNotificationPreferencesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserNotificationPreference;
  }
  /**
   * @description Replaced by the more fine-grained notifications at `/notifications/preferences`.
   * @tags Users
   * @name InternalUsersNotificationPreferencesUpdate
   * @summary Old notifications endpoint for updating how to receive notifications (Auth Permissions: UpdateNotificationPreferences)
   * @request PUT:/directory/internal/users/notification-preferences
   * @deprecated
   * @secure
   * @response `200` `UserNotificationPreference` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace InternalUsersNotificationPreferencesUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserNotificationPreference;
    export type RequestHeaders = {};
    export type ResponseBody = UserNotificationPreference;
  } /**
 * No description
 * @tags Users
 * @name InternalUsersNotificationsPreferencesCreate
 * @summary Updates the notification configuration around which items are sent
as emails, which are app notifications, etc.
 * @request POST:/directory/internal/users/notifications/preferences
 * @response `200` `(NotificationPreference)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalUsersNotificationsPreferencesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrUpdateKnockPreferencesCommand;
    export type RequestHeaders = {};
    export type ResponseBody = NotificationPreference[];
  } /**
 * No description
 * @tags Users
 * @name InternalUsersNotificationsPreferencesList
 * @summary Retrieves the notification configuration about which are sent as
emails, which are app notifications, etc.
 * @request GET:/directory/internal/users/notifications/preferences
 * @response `200` `(NotificationPreference)[]` OK
 * @response `400` `ApiValidationProblem` Bad Request
*/
  export namespace InternalUsersNotificationsPreferencesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = NotificationPreference[];
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersNotificationsPushRegisterCreate
   * @request POST:/directory/internal/users/notifications/push/register
   * @response `200` `string` Success
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersNotificationsPushRegisterCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RegisterPushNotificationTokenCommand;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersNotificationsPushDeregisterDelete
   * @request DELETE:/directory/internal/users/notifications/push/deregister
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersNotificationsPushDeregisterDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeregisterPushNotificationTokenCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersAcceptTermsConditionsUpdate
   * @request PUT:/directory/internal/users/accept-terms-conditions
   * @response `200` `UserSettings` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersAcceptTermsConditionsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserSettings;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersHideModalsUpdate
   * @summary Endpoint for configuring certain modal to not show again.
   * @request PUT:/directory/internal/users/hide-modals
   * @response `200` `HideModalProperties` OK
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersHideModalsUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = HideModalCommand;
    export type RequestHeaders = {};
    export type ResponseBody = HideModalProperties;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersCompanyEmailMatchList
   * @request GET:/directory/internal/users/company-email-match
   * @response `200` `CompanyClaimToken` OK
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersCompanyEmailMatchList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CompanyClaimToken;
  }
  /**
   * No description
   * @tags Users
   * @name InternalUsersActionablePromptDelete
   * @request DELETE:/directory/internal/users/actionable-prompt/{type}
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   */
  export namespace InternalUsersActionablePromptDelete {
    export type RequestParams = {
      type: ActionablePromptType;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}
