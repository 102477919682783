/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AddDocumentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  /** @default "AddDocumentTimelineMetadata" */
  $type: string;
};

export interface AddPendingSupplierCommand {
  tradingName: string;
  email?: string | null;
}

export enum AddressType {
  Physical = "Physical"
}

export type AdvanceOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  ignoreOrderLineWarnings: boolean;
  delivered?: DeliveredOptions | null;
  /** @default "Advance" */
  $type: string;
};

export enum Alpha2Code {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VA = "VA",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW"
}

export enum ApiTokenType {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Webhooks = "Webhooks",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export interface ApiValidationProblem {
  type: string;
  title: string;
  reason?: string | null;
  /** @format int32 */
  status: number;
  errors: Failure[];
  warnings: Failure[];
}

export interface Asset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
}

export interface Attachment {
  name: string;
  file: File;
}

export enum BuyerCategory {
  GrocerySupermarket = "GrocerySupermarket",
  Cafe = "Cafe",
  Restaurant = "Restaurant",
  Bar = "Bar",
  Accommodation = "Accommodation",
  AgedCare = "AgedCare",
  Bakery = "Bakery",
  BeautySalonSpa = "BeautySalonSpa",
  Butcher = "Butcher",
  Catering = "Catering",
  ChildCare = "ChildCare",
  Club = "Club",
  ConvenienceStore = "ConvenienceStore",
  Distributor = "Distributor",
  Florist = "Florist",
  FoodCoffeeTruck = "FoodCoffeeTruck",
  GiftBoxService = "GiftBoxService",
  GymFitness = "GymFitness",
  HairSalon = "HairSalon",
  LiquorStore = "LiquorStore",
  MedicalHealthCare = "MedicalHealthCare",
  Office = "Office",
  PetStore = "PetStore",
  PetrolStation = "PetrolStation",
  Pharmacy = "Pharmacy",
  RetailStore = "RetailStore",
  SchoolingEducation = "SchoolingEducation",
  WholesaleSupplier = "WholesaleSupplier"
}

export enum BuyerExportFormatOption {
  PosBoss = "PosBoss",
  Standard = "Standard"
}

export interface BuyerOrder {
  supplierId: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @maxLength 1000 */
  comment?: string | null;
  /** @maxLength 50 */
  referenceNumber?: string | null;
  orderLines: OrderLineItemRequest[];
  payment?: PaymentDetails | null;
}

export interface BuyerOrderPaymentDetails {
  status: PaymentStatus;
}

export interface BuyerOrderSummary {
  /** @format int32 */
  orderGroupId: number;
  /** @format int32 */
  buyerOrderId: number;
  supplierName: string;
  supplierId: string;
  /** @format int32 */
  supplierOrderId: number;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  orderStatus: OrderStatus;
  source?: OrderSource | null;
  /** @format int32 */
  orderLinesCount: number;
  actions: OrderTransition[];
  /** @format int32 */
  totalComments: number;
  referenceNumber?: string | null;
  invoiceNumber?: string | null;
  trackingNumber?: string | null;
  supplierLogoUrl?: string | null;
  payment?: BuyerOrderPaymentDetails | null;
}

export interface BuyerOrderSummaryPagedSet {
  links: PageLinks;
  data: BuyerOrderSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface BuyerOrdersExportReportSummaryRow {
  supplier: string;
  supplierId: string;
  /** @format date-time */
  receivedDateUtc?: string | null;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  createdDateUtc: string;
  referenceNumber?: string | null;
  status: OrderStatus;
}

export interface BuyerOrdersExportReportSummaryRowPagedSet {
  links: PageLinks;
  data: BuyerOrdersExportReportSummaryRow[];
  /** @format int32 */
  total?: number | null;
}

export interface BuyerOrdersStats {
  /** @format int32 */
  scheduledCount: number;
}

export interface BuyerPauseStandingOrderScheduleCommand {
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
  supplierId: string;
}

export interface BuyerProductSummary {
  supplierId: string;
  supplierName: string;
  productId: string;
  productName: string;
  productCode: string;
  buyerPrice?: Money | null;
  isAFavourite: boolean;
  supplierLogoUrl?: string | null;
  imagesMetadata?: ProductImagesMetadata | null;
  description?: string | null;
  promotions: ProductPromotion[];
  unavailableReason?: ProductUnavailableReason | null;
  category?: CategoryRef | null;
  isAvailable: boolean;
}

export interface BuyerReportSaveCommand {
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  exportDateOption?: ExportDateOption | null;
  requiredOrderStatus?: OrderStatus | null;
  includeCancelled?: boolean | null;
  format?: BuyerExportFormatOption | null;
  /** @format date-time */
  lastExportDateUtc?: string | null;
  reportId?: string | null;
  type: ReportType;
}

export interface BuyerResumeStandingOrderScheduleCommand {
  supplierId: string;
}

export interface BuyerViewOrder {
  /** @format int32 */
  buyerOrderId: number;
  supplierName: string;
  supplierId: string;
  orderDetails: OrderDetails;
}

export type CancelOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  /** @default "Cancel" */
  $type: string;
};

export interface CancelOrderPauseScheduleCommand {
  comment?: string | null;
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
}

export interface CancelSupplierLinkRequest {
  comment?: string | null;
}

export type CardPaymentMethod = UtilRequiredKeys<PaymentMethod, "$type"> & {
  cardType: CardPaymentMethodType;
  /** @format int32 */
  expirationMonth: number;
  /** @format int32 */
  expirationYear: number;
  last4: string;
  country?: Alpha2Code | null;
  failureDetails?: (UtilRequiredKeys<DefaultPaymentFailure, "$type"> | null) &
    ({
      $type: "Default";
    } | null);
  /** @default "Card" */
  $type: string;
};

export enum CardPaymentMethodType {
  Visa = "Visa",
  Mastercard = "Mastercard",
  Amex = "Amex",
  Diners = "Diners",
  Discover = "Discover",
  Jcb = "Jcb",
  UnionPay = "UnionPay"
}

export enum CategoryIcon {
  Apparel = "Apparel",
  Car = "Car",
  Cleaning = "Cleaning",
  Drink = "Drink",
  Food = "Food",
  Kitchen = "Kitchen",
  Office = "Office",
  Packaging = "Packaging",
  Personal = "Personal",
  Pet = "Pet",
  Services = "Services",
  Vaping = "Vaping"
}

export interface CategoryRef {
  id: string;
  name: string;
  fullName: string;
}

export interface CategorySummary {
  id: string;
  name: string;
  fullName: string;
  /** @format int32 */
  level: number;
  isLeaf: boolean;
  isRoot: boolean;
  parentId?: string | null;
  isCoreCategory?: boolean | null;
  /** @format int32 */
  sortOrder?: number | null;
  iconType?: CategoryIcon | null;
}

export type CommentTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  message: string;
  images?: Image[] | null;
  /** @default "CommentTimelineMetadata" */
  $type: string;
};

export interface CompanyAddress {
  externalId: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  type: AddressType;
  isDefault: boolean;
  country: Country;
  full: string;
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export enum CompanyTypeStatus {
  None = "None",
  Onboarding = "Onboarding",
  Active = "Active",
  Inactive = "Inactive"
}

export enum ConnectAction {
  Automatic = "Automatic",
  InviteRequest = "InviteRequest"
}

export interface Country {
  code: Alpha2Code;
  commonName: string;
}

export interface CreateBuyerOrderCommand {
  orders: BuyerOrder[];
  /** @deprecated */
  deliveryAddress: string;
  address?: DeliveryAddress | null;
}

export interface CreateFavouriteCommand {
  name: string;
  products?: FavouriteProduct[] | null;
}

export interface CreateNewsfeedPostReactionCommand {
  reaction: Reaction;
  activityId: string;
}

export interface CreateNewsfeedPostReactionResponse {
  reactionId: string;
}

export type CreateOrderTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceList?: OrderActionPriceListDetails | null;
  customLines?: OrderLineModificationAudit[] | null;
  files?: PrivateFile[] | null;
  /** @default "CreateOrderTimelineMetadata" */
  $type: string;
};

export interface CreateSupplierLinkCommand {
  supplierId: string;
  comment?: string | null;
  hasTradingRelationship?: boolean | null;
}

export interface Currency {
  code: string;
}

export type DayOfFollowingMonth = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  day: number;
  /** @default "DayOfFollowingMonth" */
  $type: string;
};

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}

export interface Deadline {
  time: string;
  day: DayOfWeek;
}

export type DefaultPaymentFailure = UtilRequiredKeys<PaymentFailureDetails, "$type"> & {
  /** @default "Default" */
  $type: string;
};

export type DeleteInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider: InvoiceProvider;
  /** @default "DeleteInvoiceTimelineMetadata" */
  $type: string;
};

export interface DeliveredOptions {
  signature?: PrivateFile | null;
  signatory?: string | null;
  files?: PrivateFile[] | null;
}

export interface DeliveryAddress {
  attention?: string | null;
  contactPhone?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  zone?: string | null;
  notes?: string | null;
  countryCode?: Alpha2Code | null;
}

export interface DeliveryAreas {
  locations: string[];
}

export enum DeliveryGroupType {
  Default = "Default",
  Custom = "Custom"
}

export type DeliveryGroupUpdatedMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  deliveryGroupId: string;
  deliveryGroupName: string;
  type: DeliveryGroupType;
  /** @default "DeliveryGroupUpdatedMetadata" */
  $type: string;
};

export interface DirectoryTags {
  tags: Tag[];
  buyerCategories: BuyerCategory[];
}

export interface EditLineItemsActionPriceListDetails {
  priceWarningsDisabled: boolean;
  effectiveOrderPriceListNameBefore?: string | null;
  effectiveOrderPriceListNameAfter?: string | null;
}

export type EditLineItemsTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  priceListDetails?: EditLineItemsActionPriceListDetails | null;
  editedLines: OrderLineModificationAudit[];
  /** @default "EditLineItemsTimelineMetadata" */
  $type: string;
};

export type EmailInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  invoiceProvider?: InvoiceProvider | null;
  emails: string[];
  /** @default "EmailInvoiceTimelineMetadata" */
  $type: string;
};

export enum ErrorProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Dear = "Dear",
  Codat = "Codat",
  Payments = "Payments",
  Woolworths = "Woolworths",
  Automation = "Automation",
  MyIntegrator = "MyIntegrator",
  Starshipit = "Starshipit",
  Cin7Omni = "Cin7Omni"
}

export enum ExportDateOption {
  SinceLastExport = "SinceLastExport",
  Custom = "Custom"
}

export interface Failure {
  property?: string | null;
  value?: string | null;
  code: string;
  reason: string;
}

export interface FavouriteDetails {
  /** @format int32 */
  listId: number;
  name: string;
  products: BuyerProductSummary[];
}

export interface FavouriteProduct {
  productId: string;
  supplierId: string;
}

export interface FavouriteSummary {
  /** @format int32 */
  listId: number;
  name: string;
  match: boolean;
  isEmpty: boolean;
  hasAvailableProducts: boolean;
}

export interface File {
  id: string;
  originalName: string;
  url: string;
  contentType?: string | null;
}

export interface FileSignature {
  hash: string;
  version: FileSignatureVersion;
}

export enum FileSignatureVersion {
  V1 = "V1"
}

export type FoodstuffsCancelOrderOptions = UtilRequiredKeys<UpdateOrderStatusOptions, "$type"> & {
  rejectionReason?: string | null;
  /** @default "FoodstuffsCancel" */
  $type: string;
};

export type ForwardInvoiceTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  emails: string[];
  /** @default "ForwardInvoiceTimelineMetadata" */
  $type: string;
};

export type FromDefaultsV1 = UtilRequiredKeys<OrderMinimums, "$type"> & {
  status: OrderMinimumsStatus;
  monetaryValue?: Money | null;
  /** @default "FromDefaultsV1" */
  $type: string;
};

export interface GetDefaultProductsPagedSet {
  links: PageLinks;
  data: BuyerProductSummary[];
  /** @format int32 */
  total?: number | null;
  isFallbackResult: boolean;
}

export interface GetProductsForBuyerPagedSet {
  links: PageLinks;
  data: BuyerProductSummary[];
  /** @format int32 */
  total?: number | null;
  isFallbackResult: boolean;
}

export interface HidePaymentMethodCommand {
  paymentMethod: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
}

export interface Image {
  assets: Asset[];
  id: string;
}

export interface ImageSummary {
  id: string;
  urls: ProfileImageAsset[];
  orientation: Orientation;
}

export enum IncomingOrderProvider {
  Foodstuffs = "Foodstuffs",
  Woolworths = "Woolworths"
}

export interface InvalidOrderLine {
  productName: string;
  productCode?: string | null;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: InvalidOrderLineProblem[];
  providerProductId: string;
}

export interface InvalidOrderLineProblem {
  type: InvalidOrderLineProblemType;
  severity: Severity;
}

export enum InvalidOrderLineProblemType {
  ProviderProductNotMatched = "ProviderProductNotMatched",
  ProductNotFound = "ProductNotFound",
  MissingBasePrice = "MissingBasePrice"
}

export interface InvoiceDetails {
  invoiceNumber: string;
  provider?: InvoiceProvider | null;
  viewInProviderUrl?: string | null;
  viewPdfUrl?: string | null;
  isForwarded: boolean;
  isConsolidated: boolean;
}

export enum InvoiceProvider {
  Xero = "Xero",
  Codat = "Codat",
  MyIntegrator = "MyIntegrator"
}

export interface LinkAction {
  action: SupplierLinkActions;
}

export enum LinkedSupplierAuditActionSource {
  Buyer = "Buyer",
  Supplier = "Supplier",
  Admin = "Admin",
  OrderProvider = "OrderProvider",
  SignupProcess = "SignupProcess"
}

export interface LinkedSupplierStats {
  /** @format int32 */
  linkedCount: number;
  /** @format int32 */
  invitedCount: number;
  /** @format int32 */
  leadCount: number;
}

export enum LinkedSupplierStatus {
  Unlinked = "Unlinked",
  Pending = "Pending",
  Linked = "Linked"
}

export interface Money {
  /** @format double */
  amount: number;
  currency: Currency;
}

export type NetDays = UtilRequiredKeys<PaymentTermsPaymentDelay, "$type"> & {
  /** @format int32 */
  days: number;
  /** @default "NetDays" */
  $type: string;
};

export type OnAccountDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "OnAccount" */
  $type: string;
};

export type OnAccountOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "OnAccount" */
  $type: string;
};

export interface OrderAction {
  action: OrderActionType;
}

export interface OrderActionPriceListDetails {
  name: string;
  priceListId: string;
  effectiveOrderPriceListName?: string | null;
  priceWarningsDisabled: boolean;
}

export enum OrderActionType {
  EditOrderLines = "EditOrderLines",
  ForwardInvoice = "ForwardInvoice",
  NewOrder = "NewOrder",
  CopyOrder = "CopyOrder",
  AddComments = "AddComments",
  PayNow = "PayNow",
  UpdatePaymentOption = "UpdatePaymentOption",
  ManualTakePayment = "ManualTakePayment"
}

export enum OrderAuditAction {
  Ordered = "Ordered",
  Shipped = "Shipped",
  Processing = "Processing",
  CreateInvoice = "CreateInvoice",
  UpdateInvoice = "UpdateInvoice",
  Deleted = "Deleted",
  Comment = "Comment",
  Approved = "Approved",
  Cancelled = "Cancelled",
  Received = "Received",
  EmailInvoice = "EmailInvoice",
  EditLineItems = "EditLineItems",
  DeleteInvoice = "DeleteInvoice",
  ForwardInvoice = "ForwardInvoice",
  LinkExternalDocument = "LinkExternalDocument",
  Scheduled = "Scheduled",
  ReportIssue = "ReportIssue",
  PaymentReceived = "PaymentReceived",
  PaymentFailed = "PaymentFailed",
  ResolveIssue = "ResolveIssue",
  PaymentOptionUpdated = "PaymentOptionUpdated",
  InvalidOrderLineRemoved = "InvalidOrderLineRemoved",
  DeliveryGroupUpdated = "DeliveryGroupUpdated",
  Delivered = "Delivered",
  ShipmentStatusUpdated = "ShipmentStatusUpdated"
}

export enum OrderAuditActionSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Scheduler = "Scheduler",
  PaymentProvider = "PaymentProvider",
  ShipmentProvider = "ShipmentProvider"
}

export interface OrderComment {
  message: string;
  images?: Image[] | null;
}

export type OrderDeliveredMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  signature?: PrivateFile | null;
  signatory?: string | null;
  files: PrivateFile[];
  /** @default "OrderDeliveredMetadata" */
  $type: string;
};

export interface OrderDetails {
  /** @format int32 */
  orderId: number;
  orderStatus: OrderStatus;
  source: OrderSource;
  buyerName: string;
  buyerIsClaimed: boolean;
  supplierIsClaimed: boolean;
  buyerId: string;
  /** @format date-time */
  createdDateUtc: string;
  /** @format date-time */
  deliveryDateUtc: string;
  /** @format date-time */
  autoApproveDateUtc?: string | null;
  deliveryAddress?: string | null;
  lines: OrderLine[];
  invalidLines: InvalidOrderLine[];
  referenceNumber?: string | null;
  trackingNumber?: string | null;
  notes?: string | null;
  invoice?: InvoiceDetails | null;
  printed: boolean;
  workflow: OrderWorkflow;
  orderErrors: OrderError[];
  /** @format int32 */
  totalComments: number;
  orderProvider?: IncomingOrderProvider | null;
  tax?: OrderTaxDetails | null;
  payment?: (
    | UtilRequiredKeys<PaymentMethodDetails, "$type">
    | UtilRequiredKeys<PaymentLinkDetails, "$type">
    | UtilRequiredKeys<OnAccountDetails, "$type">
    | null
  ) &
    ({
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    } | null);
  subtotal?: Money | null;
  total?: Money | null;
  address?: DeliveryAddress | null;
  files: PrivateFile[];
  invoiceIsConsolidated: boolean;
  documents: OrderExternalDocument[];
  hasCustomPrices: boolean;
  orderIssues: OrderIssueSummary[];
}

export interface OrderError {
  /** @format int64 */
  notificationId: number;
  /** @format int32 */
  orderId: number;
  buyerName: string;
  providerError: ProviderError;
  isDismissed: boolean;
  isRetryable: boolean;
}

export enum OrderErrorType {
  Invoice = "Invoice",
  ApproveInvoice = "ApproveInvoice",
  InvalidOrderLines = "InvalidOrderLines",
  BuyerNotLinked = "BuyerNotLinked",
  CreateExternalOrder = "CreateExternalOrder",
  PaymentFailed = "PaymentFailed",
  UpdateInvoice = "UpdateInvoice",
  PaymentFailedNonRetryable = "PaymentFailedNonRetryable",
  OrderNotShippedExternalOrderOutOfSync = "OrderNotShipped_ExternalOrderOutOfSync",
  AutoApprovalFailed = "AutoApprovalFailed",
  OrderNotShippedExternalOrderOutOfSyncInvoicesIncomingOrderProvider = "OrderNotShipped_ExternalOrderOutOfSync_InvoicesIncomingOrderProvider",
  OrderNotShippedExternalOrderOutOfSyncOrderHasPayment = "OrderNotShipped_ExternalOrderOutOfSync_OrderHasPayment",
  ExternalOrderOutOfSyncOrderPaidIncorrectAmount = "ExternalOrderOutOfSync_OrderPaidIncorrectAmount",
  PaymentValidationTotal = "PaymentValidation_Total",
  PaymentValidationTax = "PaymentValidation_Tax",
  QuickBooksOnlineCustomTransactionNumberMustBeOff = "QuickBooksOnline_CustomTransactionNumberMustBeOff",
  IntegrationRecordPaymentFailed = "Integration_RecordPaymentFailed",
  CreateShipment = "Create_Shipment",
  WoolworthsRejectAlreadyAcknowledged = "Woolworths_Reject_AlreadyAcknowledged"
}

export interface OrderExternalDocument {
  documentProvider: ApiTokenType;
  documentType: OrderExternalDocumentType;
  documentStatus: OrderExternalDocumentStatus;
  name?: string | null;
  url?: string | null;
  errors: OrderError[];
  deferredReason?: OrderExternalDocumentDeferredReason | null;
  metadata?: (UtilRequiredKeys<StarshipitMetadata, "$type"> | null) &
    ({
      $type: "Starshipit";
    } | null);
}

export interface OrderExternalDocumentDeferredReason {
  type: OrderExternalDocumentDeferredReasonType;
  orderStatus?: OrderStatus | null;
}

export enum OrderExternalDocumentDeferredReasonType {
  WaitingForConsolidatedInvoiceRun = "WaitingForConsolidatedInvoiceRun",
  WaitingForOrderStatus = "WaitingForOrderStatus"
}

export interface OrderExternalDocumentMetadata {
  $type: "Starshipit";
}

export enum OrderExternalDocumentStatus {
  Submitted = "Submitted",
  Created = "Created",
  Failed = "Failed",
  Deferred = "Deferred"
}

export enum OrderExternalDocumentType {
  Invoice = "Invoice",
  GeneratedOrder = "GeneratedOrder",
  Shipment = "Shipment"
}

export interface OrderIssueSummary {
  orderIssueId: string;
  /** @format int32 */
  orderId: number;
  buyerTradingName: string;
  /** @deprecated */
  raisedBy?: UserProfile | null;
  raisedByName: string;
  /** @format date-time */
  raisedDateUtc: string;
  issueType: OrderIssueType;
  comment: OrderComment;
  isResolved: boolean;
  /** @format date-time */
  resolvedDateUtc?: string | null;
}

export enum OrderIssueType {
  Other = "Other",
  DidNotArrive = "DidNotArrive",
  ProductListOutOfDate = "ProductListOutOfDate",
  ProductDamaged = "ProductDamaged",
  ProductQuality = "ProductQuality"
}

export interface OrderLine {
  productName: string;
  productCode: string;
  /** @format double */
  quantity: number;
  /** @format int32 */
  lineId: number;
  productId: string;
  status: ProductStatus;
  unitAmount?: Money | null;
  lineAmount?: Money | null;
  problems: OrderLineProblem[];
  hasCustomUnitAmount: boolean;
  priceListUnitAmount?: Money | null;
  priceListLineAmount?: Money | null;
  productImages?: ProductImagesMetadata | null;
  externalReferences: ProductExternalReference[];
  tax?: OrderLineTaxDetails | null;
  packingStatus?: OrderLinePackingStatus | null;
}

export interface OrderLineItemRequest {
  productId: string;
  /** @format double */
  quantity: number;
  /** @format double */
  customUnitAmount?: number | null;
  /** @format int32 */
  orderLineId?: number | null;
}

export interface OrderLineModificationAudit {
  modificationType: OrderLineModificationType;
  /** @format double */
  quantityBefore: number;
  /** @format double */
  quantityAfter: number;
  /** @format double */
  unitAmountBefore?: number | null;
  /** @format double */
  unitAmountAfter?: number | null;
  priceListNameBefore?: string | null;
  priceListNameAfter?: string | null;
  productName: string;
  productCode: string;
  productId: string;
  /** @format int32 */
  orderLineId?: number | null;
}

export enum OrderLineModificationType {
  Added = "Added",
  Removed = "Removed",
  QuantityChange = "QuantityChange",
  PriceChange = "PriceChange"
}

export enum OrderLinePackingStatus {
  NotPacked = "NotPacked",
  Packed = "Packed"
}

export interface OrderLineProblem {
  type: OrderLineProblemType;
  severity: Severity;
}

export enum OrderLineProblemType {
  DifferentPrice = "DifferentPrice",
  ProductArchived = "ProductArchived",
  ProductOutOfStock = "ProductOutOfStock",
  ProductHidden = "ProductHidden",
  NoTaxRatesCoded = "NoTaxRatesCoded",
  ProductDeleted = "ProductDeleted"
}

export interface OrderLineTaxDetails {
  taxRateComponents: OrderLineTaxRateComponent[];
  taxAmount: Money;
}

export interface OrderLineTaxRateComponent {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderMinimumSetting {
  $type: "MinimumMonetaryValue";
}

export type OrderMinimumSettingMinimumMonetaryValue = UtilRequiredKeys<OrderMinimumSetting, "$type"> & {
  /** @format double */
  value: number;
  /** @default "MinimumMonetaryValue" */
  $type: string;
};

export interface OrderMinimums {
  $type: "FromDefaultsV1";
}

export enum OrderMinimumsStatus {
  Enabled = "Enabled",
  Disabled = "Disabled"
}

export interface OrderPaymentDetails {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
}

export interface OrderPaymentFailure {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
}

export interface OrderPaymentRecord {
  identifier: string;
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  fee?: Money | null;
  failure?: OrderPaymentFailure | null;
}

export interface OrderPaymentStatusDetails {
  $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
}

export type OrderPaymentStatusDetailsFailed = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Failed" */
  $type: string;
};

export type OrderPaymentStatusDetailsPaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Paid" */
  $type: string;
};

export type OrderPaymentStatusDetailsProcessing = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Processing" */
  $type: string;
};

export type OrderPaymentStatusDetailsScheduled = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @format date-time */
  takePaymentDateUtc: string;
  delayUsed: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type">) & {
    $type: "NetDays" | "DayOfFollowingMonth";
  };
  /** @default "Scheduled" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnknown = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  /** @default "Unknown" */
  $type: string;
};

export type OrderPaymentStatusDetailsUnpaid = UtilRequiredKeys<OrderPaymentStatusDetails, "$type"> & {
  deferredUntilOrderStatus?: OrderStatus | null;
  delayAfterOrderStatus?: (UtilRequiredKeys<NetDays, "$type"> | UtilRequiredKeys<DayOfFollowingMonth, "$type"> | null) &
    ({
      $type: "NetDays" | "DayOfFollowingMonth";
    } | null);
  /** @default "Unpaid" */
  $type: string;
};

export interface OrderProgress {
  status: OrderStatus;
  isComplete: boolean;
  /** @format date-time */
  actionDateUtc?: string | null;
}

export enum OrderSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  StandingOrder = "StandingOrder"
}

export enum OrderStatus {
  Ordered = "Ordered",
  Processing = "Processing",
  Shipped = "Shipped",
  Cancelled = "Cancelled",
  Received = "Received",
  Scheduled = "Scheduled",
  Delivered = "Delivered"
}

export interface OrderTaxCodeBreakdownLine {
  taxCode: string;
  taxAmount: Money;
}

export interface OrderTaxDetails {
  taxCodeBreakdown?: OrderTaxCodeBreakdownLine[] | null;
  taxRateBreakdown: OrderTaxRateBreakdownLine[];
  totalTaxAmount: Money;
}

export interface OrderTaxRateBreakdownLine {
  taxRate: TaxRate;
  taxAmount: Money;
  externalReferences: TaxRateExternalReference[];
}

export interface OrderTimeline {
  /** @format int32 */
  orderId: number;
  actionType: OrderAuditAction;
  /** @format date-time */
  actionDateUtc: string;
  actionByFirstName?: string | null;
  actionByLastName?: string | null;
  metadata?: (
    | UtilRequiredKeys<CreateOrderTimelineMetadata, "$type">
    | UtilRequiredKeys<EmailInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<DeleteInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<EditLineItemsTimelineMetadata, "$type">
    | UtilRequiredKeys<ForwardInvoiceTimelineMetadata, "$type">
    | UtilRequiredKeys<AddDocumentTimelineMetadata, "$type">
    | UtilRequiredKeys<ReportIssueTimelineMetadata, "$type">
    | UtilRequiredKeys<CommentTimelineMetadata, "$type">
    | UtilRequiredKeys<PaymentOptionsUpdatedTimelineMetadata, "$type">
    | UtilRequiredKeys<DeliveryGroupUpdatedMetadata, "$type">
    | UtilRequiredKeys<OrderDeliveredMetadata, "$type">
    | UtilRequiredKeys<ShipmentStatusUpdatedTimelineMetadata, "$type">
    | null
  ) &
    ({
      $type:
        | "CreateOrderTimelineMetadata"
        | "EmailInvoiceTimelineMetadata"
        | "DeleteInvoiceTimelineMetadata"
        | "EditLineItemsTimelineMetadata"
        | "ForwardInvoiceTimelineMetadata"
        | "AddDocumentTimelineMetadata"
        | "ReportIssueTimelineMetadata"
        | "CommentTimelineMetadata"
        | "PaymentOptionsUpdatedTimelineMetadata"
        | "DeliveryGroupUpdatedMetadata"
        | "OrderDeliveredMetadata"
        | "ShipmentSatusUpdatedTimelineMetadata";
    } | null);
  actionBySelf: boolean;
  actionSource: OrderAuditActionSource;
}

export interface OrderTimelineMetadata {
  $type:
    | "CreateOrderTimelineMetadata"
    | "EmailInvoiceTimelineMetadata"
    | "DeleteInvoiceTimelineMetadata"
    | "EditLineItemsTimelineMetadata"
    | "ForwardInvoiceTimelineMetadata"
    | "AddDocumentTimelineMetadata"
    | "ReportIssueTimelineMetadata"
    | "CommentTimelineMetadata"
    | "PaymentOptionsUpdatedTimelineMetadata"
    | "DeliveryGroupUpdatedMetadata"
    | "OrderDeliveredMetadata"
    | "ShipmentSatusUpdatedTimelineMetadata";
}

export interface OrderTransition {
  isPrimary: boolean;
  action: OrderStatus;
  actionUrl: string;
  isDisabled: boolean;
}

export interface OrderWorkflow {
  availableActions: OrderAction[];
  transitions: OrderTransition[];
  progress: OrderProgress[];
}

export interface OrdersExportReportRunCommand {
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  exportDateOption?: ExportDateOption | null;
  requiredOrderStatus?: OrderStatus | null;
  includeCancelled?: boolean | null;
  format?: BuyerExportFormatOption | null;
}

export enum Orientation {
  Landscape = "Landscape",
  Portrait = "Portrait",
  Square = "Square"
}

export interface PageLinks {
  next?: string | null;
  prev?: string | null;
  current: string;
}

export interface PaymentDetails {
  option: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
}

export interface PaymentFailureDetails {
  reason: PaymentFailureReason;
  type: PaymentFailureType;
  $type: "Default";
}

export enum PaymentFailureReason {
  Unknown = "Unknown",
  InsufficientFunds = "InsufficientFunds",
  Expired = "Expired"
}

export enum PaymentFailureType {
  Retryable = "Retryable",
  NonRetryable = "NonRetryable"
}

export type PaymentLinkDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentLink" */
  $type: string;
};

export type PaymentLinkOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  /** @default "PaymentLink" */
  $type: string;
};

export interface PaymentMethod {
  provider: PaymentProvider;
  providerRegion: Alpha2Code;
  identifier: string;
  /** @format date-time */
  createdDateUtc: string;
  status: PaymentMethodStatus;
  $type: "Card";
}

export type PaymentMethodDetails = UtilRequiredKeys<OrderPaymentDetails, "status" | "statusDetails" | "$type"> & {
  status: PaymentStatus;
  statusDetails: (
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnknown, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsUnpaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsProcessing, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsPaid, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsFailed, "$type">
    | UtilRequiredKeys<OrderPaymentStatusDetailsScheduled, "$type">
  ) & {
    $type: "Unknown" | "Unpaid" | "Processing" | "Paid" | "Failed" | "Scheduled";
  };
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  record?: OrderPaymentRecord | null;
  /** @default "PaymentMethod" */
  $type: string;
};

export type PaymentMethodOption = UtilRequiredKeys<PaymentOption, "$type"> & {
  method: UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  };
  /** @default "PaymentMethod" */
  $type: string;
};

export interface PaymentMethodPagedSet {
  links: PageLinks;
  data: (UtilRequiredKeys<CardPaymentMethod, "$type"> & {
    $type: "Card";
  })[];
  /** @format int32 */
  total?: number | null;
}

export enum PaymentMethodStatus {
  Active = "Active",
  Failed = "Failed",
  Hidden = "Hidden"
}

export interface PaymentOption {
  isUnavailable: boolean;
  unavailableReason?: PaymentOptionUnavailableReason | null;
  $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
}

export enum PaymentOptionUnavailableReason {
  UnsupportedMerchantAccount = "UnsupportedMerchantAccount",
  PaymentMethodFailed = "PaymentMethodFailed"
}

export type PaymentOptionsUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  updatedOption: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
  /** @default "PaymentOptionsUpdatedTimelineMetadata" */
  $type: string;
};

export enum PaymentProvider {
  Stripe = "Stripe"
}

export enum PaymentStatus {
  Unknown = "Unknown",
  Unpaid = "Unpaid",
  Processing = "Processing",
  Paid = "Paid",
  Failed = "Failed",
  Scheduled = "Scheduled"
}

export interface PaymentTermsPaymentDelay {
  $type: "NetDays" | "DayOfFollowingMonth";
}

export enum PendingSupplierConnectionType {
  Manual = "Manual"
}

export interface PendingSupplierLinkSummary {
  tradingName: string;
  supplierId: string;
  initiatedBy: SupplierLinkInitiatedBy;
  logoUrl?: string | null;
}

export interface PendingSupplierSummary {
  /** @format int32 */
  requestId: number;
  tradingName: string;
  email: string;
  connectionType: PendingSupplierConnectionType;
}

export interface PendingSupplierSummaryPagedSet {
  links: PageLinks;
  data: PendingSupplierSummary[];
  /** @format int32 */
  total?: number | null;
}

export interface PrivateFile {
  id: string;
  originalName: string;
  location: string;
  contentType: string;
  /** @format int64 */
  contentLength: number;
  signature: FileSignature;
}

export interface ProductExternalReference {
  identifier: string;
  source: ProductProvider;
  name: string;
}

export interface ProductImagesMetadata {
  images: Image[];
}

export interface ProductPromotion {
  type: ProductPromotionType;
}

export enum ProductPromotionType {
  Featured = "Featured"
}

export enum ProductProvider {
  Xero = "Xero",
  Unleashed = "Unleashed",
  Foodstuffs = "Foodstuffs",
  Codat = "Codat",
  Dear = "Dear",
  Woolworths = "Woolworths",
  MyIntegrator = "MyIntegrator",
  Cin7Omni = "Cin7Omni"
}

export enum ProductStatus {
  Active = "Active",
  Archived = "Archived",
  OutOfStock = "OutOfStock"
}

export enum ProductUnavailableReason {
  Unavailable = "Unavailable",
  Archived = "Archived",
  Hidden = "Hidden",
  OutOfStock = "OutOfStock",
  Deleted = "Deleted"
}

export interface ProfileImageAsset {
  url: string;
  /** @format int32 */
  width: number;
  /** @format int32 */
  height: number;
  resolution: Resolution;
}

export interface ProfileImages {
  imageSummaries: ImageSummary[];
}

export interface ProviderError {
  invoiceNumber?: string | null;
  providerType: ErrorProvider;
  errorType: OrderErrorType;
  errors: string[];
}

export enum Reaction {
  Like = "Like",
  Love = "Love",
  Congratulations = "Congratulations"
}

export interface ReportIssueCommand {
  /** @format int32 */
  buyerOrderId: number;
  issue: OrderIssueType;
  comment: OrderComment;
}

export interface ReportIssueRequest {
  issue: OrderIssueType;
  comment: OrderComment;
}

export type ReportIssueTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  issue: OrderIssueType;
  comment: OrderComment;
  /** @default "ReportIssueTimelineMetadata" */
  $type: string;
};

export enum ReportType {
  OrdersExportReport = "OrdersExportReport"
}

export enum Resolution {
  OneX = "OneX",
  TwoX = "TwoX"
}

export interface SavedBuyerReportDetails {
  /** @format date-time */
  fromDateUtc?: string | null;
  /** @format date-time */
  toDateUtc?: string | null;
  exportDateOption?: ExportDateOption | null;
}

export interface SavedBuyerReportSummary {
  reportId: string;
  type: ReportType;
  exportFormat?: BuyerExportFormatOption | null;
  /** @format date-time */
  lastExportDateUtc?: string | null;
}

export enum ScheduleSource {
  Supplier = "Supplier",
  Buyer = "Buyer",
  Scheduler = "Scheduler"
}

export enum ScheduleStatus {
  Active = "Active",
  Paused = "Paused",
  Disabled = "Disabled"
}

export enum ScheduleType {
  Weekly = "Weekly"
}

export enum Severity {
  Error = "Error",
  Warning = "Warning"
}

export type ShipmentStatusUpdatedTimelineMetadata = UtilRequiredKeys<OrderTimelineMetadata, "$type"> & {
  document: OrderExternalDocument;
  status: string;
  /** @default "ShipmentSatusUpdatedTimelineMetadata" */
  $type: string;
};

export interface StandingOrderSchedule {
  externalId: string;
  status: ScheduleStatus;
  type: ScheduleType;
  /** @format date-time */
  pauseUntilDateUtc?: string | null;
  /** @format date-time */
  nextRunDateUtc?: string | null;
  nextRunOrderDay?: DayOfWeek | null;
  pausedBy?: ScheduleSource | null;
}

export type StarshipitMetadata = UtilRequiredKeys<OrderExternalDocumentMetadata, "$type"> & {
  tracking: StarshipitTrackingMetadata;
  /** @default "Starshipit" */
  $type: string;
};

export interface StarshipitTrackingMetadata {
  status?: string | null;
  number?: string | null;
  url?: string | null;
}

export interface SupplierDetails {
  supplierId: string;
  tradingName: string;
  shortDescription?: string | null;
  description?: string | null;
  website?: string | null;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  email?: string | null;
  phone?: string | null;
  isClaimed: boolean;
  connectAction?: ConnectAction | null;
  supplierStatus: CompanyTypeStatus;
  link: SupplierLink;
  lastOrder?: SupplierOrderSummary | null;
  logoUrl?: string | null;
  brandPrimaryColour?: string | null;
  profileImages?: ProfileImages | null;
  orderSettings: SupplierOrderSettings;
  /** @deprecated */
  deliveryAreas?: DeliveryAreas | null;
  directoryTags?: DirectoryTags | null;
  attachments?: Attachment[] | null;
}

export interface SupplierLink {
  status: LinkedSupplierStatus;
  actions: LinkAction[];
  initiatedBy?: SupplierLinkInitiatedBy | null;
}

export enum SupplierLinkActions {
  InviteToTrade = "InviteToTrade",
  Cancel = "Cancel",
  Order = "Order"
}

export enum SupplierLinkInitiatedBy {
  Buyer = "Buyer",
  Supplier = "Supplier"
}

export interface SupplierOrderSettings {
  deliveryAreas?: DeliveryAreas | null;
  deliverySchedule?: WeeklyDeliveryScheduleDetails | null;
  orderNote?: string | null;
  /** @deprecated */
  orderMinimums?: (UtilRequiredKeys<FromDefaultsV1, "$type"> | null) &
    ({
      $type: "FromDefaultsV1";
    } | null);
  orderMinimumSettings?:
    | (UtilRequiredKeys<OrderMinimumSettingMinimumMonetaryValue, "$type"> & {
        $type: "MinimumMonetaryValue";
      })[]
    | null;
}

export interface SupplierOrderSummary {
  /** @format int32 */
  orderId: number;
}

export interface SupplierSummary {
  supplierId: string;
  tradingName: string;
  primaryAddress?: string | null;
  addresses: CompanyAddress[];
  linkStatus: LinkedSupplierStatus;
  hasProductsFavourited?: boolean | null;
  logoUrl?: string | null;
  profileImages?: ProfileImages | null;
  shortDescription?: string | null;
  description?: string | null;
  connectAction?: ConnectAction | null;
  deliveryAreas?: DeliveryAreas | null;
  hasOrders?: boolean | null;
}

export interface SupplierSummaryPagedSet {
  links: PageLinks;
  data: SupplierSummary[];
  /** @format int32 */
  total?: number | null;
}

export enum Tag {
  Alcohol = "Alcohol",
  BakedGoods = "BakedGoods",
  CheeseDairy = "CheeseDairy",
  CoffeeTea = "CoffeeTea",
  Condiments = "Condiments",
  Drinks = "Drinks",
  MeatVeg = "MeatVeg",
  Packaging = "Packaging",
  PersonalCare = "PersonalCare",
  DryGoods = "DryGoods",
  Vegan = "Vegan",
  Beer = "Beer",
  Bread = "Bread",
  CertifiedOrganic = "CertifiedOrganic",
  Chocolate = "Chocolate",
  Coffee = "Coffee",
  Confectionary = "Confectionary",
  CookingOil = "CookingOil",
  Dessert = "Dessert",
  Eggs = "Eggs",
  Equipment = "Equipment",
  FreeRange = "FreeRange",
  FrozenFoods = "FrozenFoods",
  Fruit = "Fruit",
  GlutenFree = "GlutenFree",
  Honey = "Honey",
  HotSauce = "HotSauce",
  Juice = "Juice",
  Meat = "Meat",
  Milk = "Milk",
  PlantMilk = "PlantMilk",
  Produce = "Produce",
  Seafood = "Seafood",
  Snacks = "Snacks",
  Spirits = "Spirits",
  SugarFree = "SugarFree",
  Tea = "Tea",
  Wine = "Wine",
  Cleaning = "Cleaning",
  Supplements = "Supplements",
  Pets = "Pets",
  Homewares = "Homewares",
  Kitchenware = "Kitchenware",
  BCorp = "BCorp",
  AlcoholFree = "AlcoholFree",
  SpicesSeasonings = "SpicesSeasonings",
  ReadyMeals = "ReadyMeals",
  FirstNations = "FirstNations"
}

export enum TaxProvider {
  Xero = "Xero",
  Codat = "Codat",
  Dear = "Dear",
  Unleashed = "Unleashed",
  MyIntegrator = "MyIntegrator"
}

export interface TaxRate {
  taxRateId: string;
  code?: string | null;
  name: string;
  /** @format double */
  percentage: number;
}

export interface TaxRateExternalReference {
  identifier: string;
  source: TaxProvider;
}

export interface TaxonomySummary {
  currentNode?: CategorySummary | null;
  nodeTaxonomy: CategorySummary[];
}

export interface UpdateBuyerOrderStatusCommand {
  options?: (
    | UtilRequiredKeys<AdvanceOrderOptions, "$type">
    | UtilRequiredKeys<CancelOrderOptions, "$type">
    | UtilRequiredKeys<FoodstuffsCancelOrderOptions, "$type">
    | null
  ) &
    ({
      $type: "Advance" | "Cancel" | "FoodstuffsCancel";
    } | null);
}

export interface UpdateFavouriteListCommand {
  name: string;
}

export interface UpdateOrderStatusOptions {
  comment?: OrderComment | null;
  $type: "Advance" | "Cancel" | "FoodstuffsCancel";
}

export interface UpdatePaymentOptionForBuyerOrderRequest {
  paymentOption: (
    | UtilRequiredKeys<OnAccountOption, "$type">
    | UtilRequiredKeys<PaymentLinkOption, "$type">
    | UtilRequiredKeys<PaymentMethodOption, "$type">
  ) & {
    $type: "OnAccount" | "PaymentLink" | "PaymentMethod";
  };
  shareWithSupplier: boolean;
}

export interface UserProfile {
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  email: string;
}

export interface WeeklyDeliveryScheduleDay {
  deliveryAvailable: boolean;
  deadline: Deadline;
}

export interface WeeklyDeliveryScheduleDetails {
  monday: WeeklyDeliveryScheduleDay;
  tuesday: WeeklyDeliveryScheduleDay;
  wednesday: WeeklyDeliveryScheduleDay;
  thursday: WeeklyDeliveryScheduleDay;
  friday: WeeklyDeliveryScheduleDay;
  saturday: WeeklyDeliveryScheduleDay;
  sunday: WeeklyDeliveryScheduleDay;
}

export namespace Buyer {
  /**
   * No description
   * @tags Companies
   * @name V1DirectoryList
   * @summary  (Auth Permissions: ViewSupplierDirectory)
   * @request GET:/buyer/v1/directory
   * @secure
   * @response `200` `SupplierSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1DirectoryList {
    export type RequestParams = {};
    export type RequestQuery = {
      search?: string;
      includeConnected?: boolean;
      tags?: Tag[];
      featured?: boolean;
      /** @format int64 */
      "searchArea.radius"?: number;
      /** @format double */
      "searchArea.latitude"?: number;
      /** @format double */
      "searchArea.longitude"?: number;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierSummaryPagedSet;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesList
   * @summary  (Auth Permissions: ViewFavourites)
   * @request GET:/buyer/v1/favourites
   * @secure
   * @response `200` `(FavouriteSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1FavouritesList {
    export type RequestParams = {};
    export type RequestQuery = {
      productId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FavouriteSummary[];
  }
  /**
   * @description If a list of products is also included, then these will be added to the new favourites list, or an existing favourites list if the name matches that of an existing favourites list.
   * @tags Favourites
   * @name V1FavouritesCreate
   * @summary Create a new favourites list. (Auth Permissions: CreateFavourite)
   * @request POST:/buyer/v1/favourites
   * @secure
   * @response `200` `FavouriteSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1FavouritesCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateFavouriteCommand;
    export type RequestHeaders = {};
    export type ResponseBody = FavouriteSummary;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesDetail
   * @summary Retrieve a favourites list. (Auth Permissions: ViewFavourites)
   * @request GET:/buyer/v1/favourites/{listId}
   * @secure
   * @response `200` `FavouriteDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1FavouritesDetail {
    export type RequestParams = {
      /** @format int32 */
      listId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FavouriteDetails;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesDelete
   * @summary Delete a favourites list. (Auth Permissions: DeleteFavourites)
   * @request DELETE:/buyer/v1/favourites/{listId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1FavouritesDelete {
    export type RequestParams = {
      /** @format int32 */
      listId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesCreate2
   * @summary Update a favourites list. (Auth Permissions: UpdateFavourites)
   * @request POST:/buyer/v1/favourites/{listId}
   * @originalName v1FavouritesCreate
   * @duplicate
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1FavouritesCreate2 {
    export type RequestParams = {
      /** @format int32 */
      listId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateFavouriteListCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesProductsCreate
   * @summary Retrieve the products in a favourites list. (Auth Permissions: UpdateFavouriteProducts)
   * @request POST:/buyer/v1/favourites/{listId}/products
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1FavouritesProductsCreate {
    export type RequestParams = {
      /** @format int32 */
      listId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = FavouriteProduct[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Favourites
   * @name V1FavouritesProductsDelete
   * @summary Delete products from a favourites list. (Auth Permissions: UpdateFavouriteProducts)
   * @request DELETE:/buyer/v1/favourites/{listId}/products
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1FavouritesProductsDelete {
    export type RequestParams = {
      /** @format int32 */
      listId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = FavouriteProduct[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedInitialiseCreate
   * @summary  (Auth Permissions: CreateNewsfeedsBuyerCompany)
   * @request POST:/buyer/v1/newsfeed/initialise
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedInitialiseCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedReactionsCreate
   * @summary  (Auth Permissions: CreateNewsfeedPostReaction)
   * @request POST:/buyer/v1/newsfeed/reactions
   * @secure
   * @response `200` `CreateNewsfeedPostReactionResponse` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedReactionsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateNewsfeedPostReactionCommand;
    export type RequestHeaders = {};
    export type ResponseBody = CreateNewsfeedPostReactionResponse;
  }
  /**
   * No description
   * @tags Newsfeeds
   * @name V1NewsfeedReactionsDelete
   * @summary  (Auth Permissions: DeleteNewsfeedPostReaction)
   * @request DELETE:/buyer/v1/newsfeed/reactions/{reactionId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1NewsfeedReactionsDelete {
    export type RequestParams = {
      reactionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags OrderIssues
   * @name V1OrderIssuesReportCreate
   * @summary Report a new order issue. (Auth Permissions: ReportOrderIssue)
   * @request POST:/buyer/v1/order-issues/report
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrderIssuesReportCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReportIssueCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCreate
   * @summary  (Auth Permissions: BuyerCreateOrder)
   * @request POST:/buyer/v1/orders
   * @secure
   * @response `200` `(BuyerOrderSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateBuyerOrderCommand;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummary[];
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersList
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/v1/orders
   * @secure
   * @response `200` `BuyerOrderSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersList {
    export type RequestParams = {};
    export type RequestQuery = {
      supplierId?: string;
      search?: string;
      statuses?: OrderStatus[];
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      sortBy?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrderSummaryPagedSet;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersDetail
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/v1/orders/{buyerOrderId}
   * @secure
   * @response `200` `BuyerViewOrder` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersDetail {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerViewOrder;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersTimelineDetail
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/v1/orders/{buyerOrderId}/timeline
   * @secure
   * @response `200` `(OrderTimeline)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersTimelineDetail {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderTimeline[];
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCommentsCreate
   * @summary  (Auth Permissions: AddComment)
   * @request POST:/buyer/v1/orders/{buyerOrderId}/comments
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCommentsCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = OrderComment;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersIssueCreate
   * @summary  (Auth Permissions: ReportOrderIssue)
   * @request POST:/buyer/v1/orders/{buyerOrderId}/issue
   * @deprecated
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersIssueCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = ReportIssueRequest;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersStatusCreate
   * @summary Transition an order to the given status. (Auth Permissions: BuyerUpdateOrder)
   * @request POST:/buyer/v1/orders/{buyerOrderId}/status/{status}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1OrdersStatusCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
      status: OrderStatus;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateBuyerOrderStatusCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersCancelOrderAndPauseScheduleCreate
   * @summary  (Auth Permissions: BuyerUpdateOrder, BuyerUpdateStandingOrderSchedules)
   * @request POST:/buyer/v1/orders/{buyerOrderId}/cancel-order-and-pause-schedule
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersCancelOrderAndPauseScheduleCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = CancelOrderPauseScheduleCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersStatsList
   * @summary  (Auth Permissions: BuyerViewOrders)
   * @request GET:/buyer/v1/orders/stats
   * @secure
   * @response `200` `BuyerOrdersStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersStatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrdersStats;
  } /**
 * No description
 * @tags Orders
 * @name V1OrdersPaymentOptionCreate
 * @summary This allows a buyer to attach a payment method to an order.
If payment is due, it will be taken immediately. (Auth Permissions: PaymentUpdateForOrder)
 * @request POST:/buyer/v1/orders/{buyerOrderId}/payment-option
 * @secure
 * @response `200` `(UtilRequiredKeys<PaymentMethodDetails,"$type"> | UtilRequiredKeys<PaymentLinkDetails,"$type"> | UtilRequiredKeys<OnAccountDetails,"$type">) & ({
    $type: "PaymentMethod" | "PaymentLink" | "OnAccount",

})` OK
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1OrdersPaymentOptionCreate {
    export type RequestParams = {
      /** @format int32 */
      buyerOrderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePaymentOptionForBuyerOrderRequest;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | UtilRequiredKeys<PaymentMethodDetails, "$type">
      | UtilRequiredKeys<PaymentLinkDetails, "$type">
      | UtilRequiredKeys<OnAccountDetails, "$type">
    ) & {
      $type: "PaymentMethod" | "PaymentLink" | "OnAccount";
    };
  }
  /**
   * No description
   * @tags Orders
   * @name V1OrdersFilesDetail
   * @summary  (Auth Permissions: SupplierViewOrders)
   * @request GET:/buyer/v1/orders/{orderId}/files/{fileId}
   * @secure
   * @response `200` `void` Success
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1OrdersFilesDetail {
    export type RequestParams = {
      /** @format int32 */
      orderId: number;
      fileId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PaymentMethods
   * @name V1PaymentsMethodsList
   * @summary  (Auth Permissions: PaymentViewMethods)
   * @request GET:/buyer/v1/payments/methods
   * @secure
   * @response `200` `PaymentMethodPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsMethodsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentMethodPagedSet;
  }
  /**
   * No description
   * @tags PaymentMethods
   * @name V1PaymentsMethodsDelete
   * @summary  (Auth Permissions: PaymentSetupMethods)
   * @request DELETE:/buyer/v1/payments/methods
   * @secure
   * @response `200` `void` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1PaymentsMethodsDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = HidePaymentMethodCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  } /**
 * No description
 * @tags PendingSuppliers
 * @name V1SuppliersPendingLeadsCreate
 * @summary Capture a new supplier request from a buyer as they browse our
directory. (Auth Permissions: AddPendingSupplier)
 * @request POST:/buyer/v1/suppliers/pending/leads
 * @secure
 * @response `201` `PendingSupplierSummary` Created
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1SuppliersPendingLeadsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AddPendingSupplierCommand;
    export type RequestHeaders = {};
    export type ResponseBody = PendingSupplierSummary;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingLeadsList
   * @summary Retrieve a summary of all pending supplier requests. (Auth Permissions: ViewPendingSuppliers)
   * @request GET:/buyer/v1/suppliers/pending/leads
   * @secure
   * @response `200` `PendingSupplierSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingLeadsList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingSupplierSummaryPagedSet;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingLeadsDetail
   * @summary Retrieve the details of a pending supplier request. (Auth Permissions: ViewPendingSuppliers)
   * @request GET:/buyer/v1/suppliers/pending/leads/{requestId}
   * @secure
   * @response `200` `PendingSupplierSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingLeadsDetail {
    export type RequestParams = {
      /** @format int32 */
      requestId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingSupplierSummary;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingLeadsDelete
   * @summary Remove a pending supplier request from the buyer. (Auth Permissions: DeletePendingSupplier)
   * @request DELETE:/buyer/v1/suppliers/pending/leads/{requestId}
   * @secure
   * @response `204` `void` No Content
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingLeadsDelete {
    export type RequestParams = {
      /** @format int32 */
      requestId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingInvitesCreate
   * @summary  (Auth Permissions: CreateSupplierInvite)
   * @request POST:/buyer/v1/suppliers/pending/invites/{id}
   * @secure
   * @response `200` `SupplierLink` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingInvitesCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateSupplierLinkCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierLink;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingInvitesDelete
   * @summary Cancel an invite to trade. (Auth Permissions: DeleteSupplierInvite)
   * @request DELETE:/buyer/v1/suppliers/pending/invites/{supplierId}
   * @secure
   * @response `200` `SupplierLink` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingInvitesDelete {
    export type RequestParams = {
      supplierId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CancelSupplierLinkRequest;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierLink;
  }
  /**
   * No description
   * @tags PendingSuppliers
   * @name V1SuppliersPendingInvitesList
   * @summary  (Auth Permissions: ViewSupplierInvites)
   * @request GET:/buyer/v1/suppliers/pending/invites
   * @secure
   * @response `200` `(PendingSupplierLinkSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersPendingInvitesList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PendingSupplierLinkSummary[];
  }
  /**
   * No description
   * @tags Products
   * @name V1ProductsList
   * @summary  (Auth Permissions: BuyerViewProducts)
   * @request GET:/buyer/v1/products
   * @secure
   * @response `200` `GetProductsForBuyerPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ProductsList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      "fallbackQuery.search"?: string;
      "fallbackQuery.supplierId"?: string;
      "fallbackQuery.showOnlyFavourites"?: boolean;
      "fallbackQuery.promotionStatuses"?: ProductPromotionType[];
      "fallbackQuery.categoryId"?: string;
      "fallbackQuery.showOnlyUnconnectedSupplierProducts"?: boolean;
      search?: string;
      supplierId?: string;
      showOnlyFavourites?: boolean;
      promotionStatuses?: ProductPromotionType[];
      categoryId?: string;
      showOnlyUnconnectedSupplierProducts?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetProductsForBuyerPagedSet;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsSavedList
   * @summary  (Auth Permissions: BuyerViewOrdersReport)
   * @request GET:/buyer/v1/reports/saved
   * @secure
   * @response `200` `(SavedBuyerReportSummary)[]` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsSavedList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedBuyerReportSummary[];
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsSavedCreate
   * @summary  (Auth Permissions: BuyerViewOrdersReport)
   * @request POST:/buyer/v1/reports/saved
   * @secure
   * @response `200` `SavedBuyerReportSummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsSavedCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BuyerReportSaveCommand;
    export type RequestHeaders = {};
    export type ResponseBody = SavedBuyerReportSummary;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsSavedDetail
   * @summary  (Auth Permissions: BuyerViewOrdersReport)
   * @request GET:/buyer/v1/reports/saved/{reportId}
   * @secure
   * @response `200` `SavedBuyerReportDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsSavedDetail {
    export type RequestParams = {
      reportId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedBuyerReportDetails;
  }
  /**
   * No description
   * @tags Reports
   * @name V1ReportsOrdersExportList
   * @summary  (Auth Permissions: BuyerViewOrdersReport)
   * @request GET:/buyer/v1/reports/orders-export
   * @secure
   * @response `200` `BuyerOrdersExportReportSummaryRowPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1ReportsOrdersExportList {
    export type RequestParams = {};
    export type RequestQuery = {
      reportId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
      format?: BuyerExportFormatOption;
      requiredOrderStatus?: OrderStatus;
      includeCancelled?: boolean;
      /** @format date-time */
      fromDateUtc?: string;
      /** @format date-time */
      toDateUtc?: string;
      exportDateOption?: ExportDateOption;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BuyerOrdersExportReportSummaryRowPagedSet;
  } /**
 * No description
 * @tags Reports
 * @name V1ReportsOrdersExportRunCreate
 * @summary Return all of the orders in a format that can be used as an input
source for another system. (Auth Permissions: BuyerViewOrdersReport)
 * @request POST:/buyer/v1/reports/orders-export/{reportId}/run
 * @secure
 * @response `200` `void` Success
 * @response `400` `ApiValidationProblem` Bad Request
 * @response `401` `void` Unauthorized
 * @response `403` `void` Forbidden
*/
  export namespace V1ReportsOrdersExportRunCreate {
    export type RequestParams = {
      reportId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = OrdersExportReportRunCommand;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersResumeCreate
   * @summary  (Auth Permissions: BuyerUpdateStandingOrderSchedules)
   * @request POST:/buyer/v1/schedules/standingorders/{id}/resume
   * @secure
   * @response `200` `StandingOrderSchedule` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersResumeCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = BuyerResumeStandingOrderScheduleCommand;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderSchedule;
  }
  /**
   * No description
   * @tags StandingOrderSchedules
   * @name V1SchedulesStandingordersPauseCreate
   * @summary  (Auth Permissions: BuyerUpdateStandingOrderSchedules)
   * @request POST:/buyer/v1/schedules/standingorders/{id}/pause
   * @secure
   * @response `200` `StandingOrderSchedule` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SchedulesStandingordersPauseCreate {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = BuyerPauseStandingOrderScheduleCommand;
    export type RequestHeaders = {};
    export type ResponseBody = StandingOrderSchedule;
  }
  /**
   * No description
   * @tags Suppliers
   * @name V1SuppliersList
   * @summary  (Auth Permissions: ViewConnectedSuppliers)
   * @request GET:/buyer/v1/suppliers
   * @secure
   * @response `200` `SupplierSummaryPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersList {
    export type RequestParams = {};
    export type RequestQuery = {
      name?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierSummaryPagedSet;
  }
  /**
   * No description
   * @tags Suppliers
   * @name V1SuppliersDetail
   * @summary Lookup and return detailed information about a supplier by their ID. (Auth Permissions: ViewSuppliersProfile)
   * @request GET:/buyer/v1/suppliers/{id}
   * @secure
   * @response `200` `SupplierDetails` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   * @response `404` `void` Not Found
   */
  export namespace V1SuppliersDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SupplierDetails;
  }
  /**
   * No description
   * @tags Suppliers
   * @name V1SuppliersStatsList
   * @summary  (Auth Permissions: ViewConnectedSuppliers)
   * @request GET:/buyer/v1/suppliers/stats
   * @secure
   * @response `200` `LinkedSupplierStats` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersStatsList {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = LinkedSupplierStats;
  }
  /**
   * No description
   * @tags Suppliers
   * @name V1SuppliersProductsDetail
   * @summary  (Auth Permissions: BuyerViewPublicProducts)
   * @request GET:/buyer/v1/suppliers/{id}/products
   * @secure
   * @response `200` `GetDefaultProductsPagedSet` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersProductsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {
      search?: string;
      categoryId?: string;
      /** @format int32 */
      limit?: number;
      pageToken?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GetDefaultProductsPagedSet;
  }
  /**
   * No description
   * @tags Suppliers
   * @name V1SuppliersCategoriesDetail
   * @summary  (Auth Permissions: BuyerViewProducts)
   * @request GET:/buyer/v1/suppliers/{id}/categories
   * @secure
   * @response `200` `TaxonomySummary` OK
   * @response `400` `ApiValidationProblem` Bad Request
   * @response `401` `void` Unauthorized
   * @response `403` `void` Forbidden
   */
  export namespace V1SuppliersCategoriesDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {
      categoryId?: string;
      search?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TaxonomySummary;
  }
}
