import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useCallback } from "react";
import { makeStyles } from "tss-react/mui";

import DropDownMenu from "components/DropDownMenu";
import MenuItem from "components/MenuItem";
import MenuItemLink from "components/MenuItemLink";
import DeliveryAddressModal from "components/Modal/AddressModal/DeliveryAddressModal";
import useMessages from "i18n/hooks/useMessages";
import { DeliveryAddress } from "types/api/generated/supplier";
import { formatAddress } from "utils/Addresses";
import { useAnalytics } from "utils/analytics";
import useAppSelector from "utils/hooks/useAppSelector";
import useModal from "utils/hooks/useModal";
import useSnackbar from "utils/hooks/useSnackbar";

import strings from "./DeliveryAddressField.strings.json";

const useStyles = makeStyles()(theme => ({
  input: {
    cursor: "pointer"
  },
  inputAdornedStart: {
    paddingLeft: theme.spacing(1.5)
  },
  dropIcon: {
    color: theme.palette.grey[600]
  }
}));

type DeliveryAddressFieldProps = TextFieldProps & {
  address?: DeliveryAddress;
  onSave: (deliveryAddress: DeliveryAddress) => void;
  readonly?: boolean;
};

const DeliveryAddressField: FunctionComponent<DeliveryAddressFieldProps> = ({
  address = {},
  onSave,
  readonly,
  className,
  InputProps,
  ...rest
}) => {
  const messages = useMessages(strings);
  const analytics = useAnalytics();
  const { classes, cx } = useStyles();
  const { country } = useAppSelector(state => state.tenancy);
  const { formatter } = useMessageFormatter();
  const { showSnackbar } = useSnackbar();
  const [isModalOpen, openModal, closeModal] = useModal();

  const formattedAddress = formatAddress(address, address.countryCode ?? country.code, ", ");

  const onCopyAddress = (): void => {
    analytics.track("CopyAddressClicked");

    // eslint-disable-next-line compat/compat
    navigator.clipboard.writeText(formattedAddress);
    showSnackbar(<FormattedMessage id={messages.COPY_SUCCESS} />);
  };

  const onPickupClick = (): void => {
    analytics.track("PickupClicked");

    onSave({
      ...address,
      attention: null,
      contactPhone: null,
      address1: "This is a pickup order",
      address2: null,
      city: null,
      postalCode: null,
      countryCode: null,
      zone: null
    });
  };

  const onOpenInGoogleMapsClick = useCallback(() => {
    analytics.track("OpenInGoogleMapsClicked");
  }, [analytics]);

  const onSaveAction = (newAddress: DeliveryAddress): void => {
    analytics.track("EditAddressClicked");

    onSave(newAddress);
    closeModal();
  };

  return (
    <>
      <DropDownMenu
        id="deliveryAddressDropdown"
        trigger={props => (
          <TextField
            value={formattedAddress.length > 0 ? formattedAddress : address.notes ? address.notes : ""}
            variant="filled"
            placeholder={formatter.format(messages.PLACEHOLDER)}
            InputProps={{
              readOnly: true,
              endAdornment: <ExpandMoreIcon className={classes.dropIcon} />,
              classes: {
                root: cx(classes.input, className),
                input: classes.input,
                inputAdornedStart: classes.inputAdornedStart
              },
              ...InputProps
            }}
            hiddenLabel={rest.label === undefined}
            {...props}
            {...rest}
          />
        )}
      >
        <MenuItem onClick={onPickupClick}>
          <Typography>
            <FormattedMessage id={messages.PICKUP} />
          </Typography>
        </MenuItem>
        <MenuItem onClick={onCopyAddress}>
          <Typography>
            <FormattedMessage id={messages.COPY} />
          </Typography>
        </MenuItem>
        <MenuItem onClick={openModal} disabled={readonly}>
          <Typography>
            <FormattedMessage id={messages.EDIT} />
          </Typography>
        </MenuItem>
        <MenuItemLink
          href={`https://maps.google.com/maps?q=${formattedAddress}`}
          rel="noopener noreferrer"
          target="_blank"
          onClick={onOpenInGoogleMapsClick}
        >
          <Typography>
            <FormattedMessage id={messages.OPEN_IN_GOOGLE_MAPS} />
          </Typography>
        </MenuItemLink>
      </DropDownMenu>
      <DeliveryAddressModal address={address} onSave={onSaveAction} open={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default DeliveryAddressField;
