import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { UseApiFetchReturn, buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { pollApiJob } from "services/apiJobs";
import { Supplier } from "types/api";
import {
  InboxMessageDetails,
  InboxMessageSuggestionsRefPurchaseOrder,
  InboxMessageSuggestionsStatusCompleted,
  InboxMessageSuggestionsStatusNone,
  InboxMessageSuggestionsStatusPending
} from "types/api/generated/supplier";
import { throwUnhandledCase } from "utils/Errors";
import { PURCHASE_ORDER_SUGGESTIONS } from "utils/flags";
import useFeatures from "utils/hooks/useFeatures";
import useTenancyId from "utils/hooks/useTenancyId";
import { safeurl } from "utils/urls";

import { inboxQueryKey } from "./keys";
import { fetchInboxMessage } from "./useFetchInboxMessage";

export type RequestBody = Supplier.V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate.RequestBody;
export type RequestQuery = Supplier.V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate.RequestQuery;
export type ResponseBody = Supplier.V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate.ResponseBody;
export type RequestParameters = Supplier.V1MailboxInboxMessagesPurchaseOrdersSuggestionsUpdate.RequestParams;

export const fetchPurchaseOrderSuggestions = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestParameters
>(it => ({
  method: "PUT",
  path: safeurl`/supplier/v1/mailbox/inbox/messages/purchase-orders-suggestions/${it.purchaseOrderSuggestionsId}`
}));

export type FetchInboxMessageSuggestionsResult = {
  type: "PurchaseOrder";
  purchaseOrderSuggestions: ResponseBody;
};

export default function useFetchInboxMessageSuggestions(
  query: RequestQuery,
  parameters: RequestParameters,
  message: InboxMessageDetails
): UseQueryResult<FetchInboxMessageSuggestionsResult | null> {
  const apiFetch = useApiFetch();
  const tenancyId = useTenancyId();
  const [arePurchaseOrderSuggestionsEnabled] = useFeatures(tenancyId, PURCHASE_ORDER_SUGGESTIONS);

  return useQuery<FetchInboxMessageSuggestionsResult | null>({
    queryKey: inboxQueryKey.suggestions(query, parameters, message, apiFetch).queryKey,
    queryFn: async ctx => {
      return await fetchInboxMessageSuggestions(apiFetch, query, parameters, message, ctx.signal!);
    },
    enabled:
      arePurchaseOrderSuggestionsEnabled && Boolean(parameters.purchaseOrderSuggestionsId) && Boolean(query.buyerId),
    refetchOnWindowFocus: false
  });
}

async function fetchInboxMessageSuggestions(
  apiFetch: UseApiFetchReturn,
  query: RequestQuery,
  parameters: RequestParameters,
  message: InboxMessageDetails,
  signal: AbortSignal
): Promise<FetchInboxMessageSuggestionsResult | null> {
  const suggestionsStatus = getSuggestionsStatus(message);

  switch (suggestionsStatus.$type) {
    case "None":
      return null;
    case "Pending":
      return handlePendingSuggestionsStatus(apiFetch, query, parameters, message.externalId, suggestionsStatus, signal);
    case "Completed":
      return await handleCompletedSuggestionsStatus(apiFetch, query, parameters, suggestionsStatus);
    default:
      return throwUnhandledCase(suggestionsStatus);
  }
}

async function handlePendingSuggestionsStatus(
  apiFetch: UseApiFetchReturn,
  query: RequestQuery,
  parameters: RequestParameters,
  inboxMessageDetailsId: string,
  status: InboxMessageSuggestionsStatusPending,
  signal: AbortSignal
): Promise<FetchInboxMessageSuggestionsResult | null> {
  if ((await pollApiJob(apiFetch, status.jobRef.jobId, signal)) === false) {
    throw new Error("Failed to fetch suggestions, suggestions job failed.");
  }

  const inboxMessageDetails = await apiFetch(fetchInboxMessage({ externalId: inboxMessageDetailsId }));

  return await fetchInboxMessageSuggestions(apiFetch, query, parameters, inboxMessageDetails.data, signal);
}

async function handleCompletedSuggestionsStatus(
  apiFetch: UseApiFetchReturn,
  query: RequestQuery,
  parameters: RequestParameters,
  status: InboxMessageSuggestionsStatusCompleted
): Promise<FetchInboxMessageSuggestionsResult> {
  const suggestionsResult = getSuggestionsResult(status);

  switch (suggestionsResult.$type) {
    case "PurchaseOrder": {
      const purchaseOrderSuggestions = await apiFetch(
        fetchPurchaseOrderSuggestions(parameters, { searchParams: query })
      );

      return { type: "PurchaseOrder", purchaseOrderSuggestions: purchaseOrderSuggestions.data };
    }
    default:
      return throwUnhandledCase(suggestionsResult.$type);
  }
}

type SuggestionsStatus =
  | (InboxMessageSuggestionsStatusNone & { $type: "None" })
  | (InboxMessageSuggestionsStatusPending & { $type: "Pending" })
  | (InboxMessageSuggestionsStatusCompleted & { $type: "Completed" });

function getSuggestionsStatus(
  inboxMessageDetails: Supplier.V1MailboxInboxMessagesDetail.ResponseBody
): SuggestionsStatus {
  switch (inboxMessageDetails.suggestionsStatus.$type) {
    case "None":
      return { ...(inboxMessageDetails.suggestionsStatus as InboxMessageSuggestionsStatusNone), $type: "None" };
    case "Pending":
      return { ...(inboxMessageDetails.suggestionsStatus as InboxMessageSuggestionsStatusPending), $type: "Pending" };
    case "Completed":
      return {
        ...(inboxMessageDetails.suggestionsStatus as InboxMessageSuggestionsStatusCompleted),
        $type: "Completed"
      };
    default:
      return throwUnhandledCase(inboxMessageDetails.suggestionsStatus.$type);
  }
}

type CompletedSuggestionsResult = InboxMessageSuggestionsRefPurchaseOrder & { $type: "PurchaseOrder" };

function getSuggestionsResult(
  completedSuggestionsStatus: InboxMessageSuggestionsStatusCompleted
): CompletedSuggestionsResult {
  switch (completedSuggestionsStatus.result.$type) {
    case "PurchaseOrder":
      return {
        ...(completedSuggestionsStatus.result as InboxMessageSuggestionsRefPurchaseOrder),
        $type: "PurchaseOrder"
      };
    default:
      return throwUnhandledCase(completedSuggestionsStatus.result.$type);
  }
}
