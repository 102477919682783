import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { buyerQueryKeys } from "./keys";

type RequestBody = Supplier.V1BuyersList.RequestBody;
export type RequestQuery = Supplier.V1BuyersList.RequestQuery;
export type ResponseBody = Supplier.V1BuyersList.ResponseBody;

export const buyersListParams = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestQuery | undefined>(
  searchParams => {
    return {
      method: "GET",
      path: safeurl`/supplier/v1/buyers`,
      searchParams
    };
  }
);

export function useBuyersListInfinite(searchParams?: RequestQuery): UseInfiniteQueryResult<ResponseBody> {
  const apiFetch = useApiFetch();

  return useInfiniteQuery({
    queryKey: buyerQueryKeys.listInfinite(searchParams).queryKey,
    queryFn: async ({ pageParam }) => {
      const response = await apiFetch(
        // Use the searchParams for the initial request, then use the pageParam for paginated requests
        // (it includes the initial searchParams)
        pageParam ? { ...buyersListParams(undefined), path: pageParam } : buyersListParams(searchParams)
      );
      return response.data;
    },
    getNextPageParam: lastPage => lastPage.links.next
  });
}

export function useBuyersList(searchParams?: RequestQuery): UseQueryResult<ResponseBody> {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: buyerQueryKeys.list(searchParams).queryKey,
    queryFn: async () => {
      const response = await apiFetch(buyersListParams(searchParams));
      return response.data;
    }
  });
}
