import Drawer, { DrawerProps as MDrawerProps } from "@mui/material/Drawer";
import MMenu, { MenuProps as MMenuProps } from "@mui/material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { forwardRef, MouseEventHandler } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  drawerPaper: {
    maxHeight: "80%", // Prevents the drawer from taking up the whole screen
    paddingBottom: `env(safe-area-inset-bottom)` // fixes notch on mobile devices
  },
  drawerMenu: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
});

interface BasicProps {
  closeMenu?: MouseEventHandler<HTMLDivElement>;
  id?: string;
  anchorEl?: HTMLElement | null;
}

export type MenuProps = Omit<MMenuProps, "variant" | "open"> & Omit<MDrawerProps, "variant"> & BasicProps;

/**
 * A component wrapper for a list of menu items that will either appear as items
 * in a floating menu, or in an app drawer (for smaller screen sizes).
 */
const Menu = forwardRef<HTMLDivElement, MenuProps>(({ anchorEl, children, closeMenu = () => {}, id, ...rest }, ref) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const menuOrDrawerProps = {
    id,
    open: !!anchorEl,
    onClick: closeMenu,
    onClose: closeMenu,
    ...rest
  };

  return matches ? (
    <MMenu anchorEl={anchorEl} ref={ref} {...menuOrDrawerProps}>
      {children}
    </MMenu>
  ) : (
    <Drawer
      anchor="bottom"
      {...menuOrDrawerProps}
      classes={{
        paper: cx(classes.drawerPaper),
        ...menuOrDrawerProps.classes
      }}
    >
      <ul className={cx(classes.drawerMenu)}>{children}</ul>
    </Drawer>
  );
});

export default Menu;
