import { matchPath } from "react-router";
import { Redirect, RedirectProps, useLocation } from "react-router-dom";

export const EXTERNAL_REFERRER_PARAM = "externalReferrer";

/**
 * A component for external redirects, appends an `externalReferrer=true` to the
 * URL for our app to know that we came from an external link.
 */
export default function ExternalRedirect({ from, to, ...rest }: RedirectProps): JSX.Element {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams();
  const props =
    matchPath(location.pathname, {
      path: from,
      exact: true
    })?.params ?? {};

  // Create a new query string consisting of any parameters in the redirect URL,
  // the external URL, before finally adding `externalReferrer=true` to it.
  if (typeof to === "object" && to.search) {
    const toSearchParams = new URLSearchParams(to.search);
    toSearchParams.forEach((value, key) => {
      // This tries to retrieve variable properties from "from" link to see if any is used in the query params for the "to" link
      // If said variable property isn't found - the value is put as is
      // Example:
      //    from - "/ext/some-link/:tenancyId", to - { pathname: "/ext/another-link", search: "?tenancyId=:tenancyId" }
      //    results in ":tenancyId" being replaced with whatever is in the actual link.
      // @ts-ignore Props values can be anything given we're deconstructing any path string
      urlSearchParams.append(key, props[value.substring(1)] ?? value); // removing ':' in the start of property variable
    });
  }
  if (location.search) {
    const locationSearchParams = new URLSearchParams(location.search);
    locationSearchParams.forEach((value, key) => {
      urlSearchParams.append(key, value);
    });
  }
  urlSearchParams.set(EXTERNAL_REFERRER_PARAM, "true");

  return (
    <Redirect
      to={
        typeof to === "string"
          ? {
              pathname: to,
              search: urlSearchParams.toString()
            }
          : {
              ...to,
              search: urlSearchParams.toString()
            }
      }
      {...rest}
    />
  );
}
