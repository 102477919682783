import { Auth0Context, LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { ReactNode, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import usePushNotifications from "native/usePushNotifications";
import { logout as analyticsLogout } from "utils/analytics";
import { trackError } from "utils/Errors";
import { shutdown } from "utils/Intercom";

interface Props {
  children: ReactNode;
}

/**
 * Sets our own customized auth0 context made for our app and handle auth0 errors.
 */
export default function Auth0ContextWrapper({ children }: Props): JSX.Element | null {
  const auth0 = useAuth0();
  const history = useHistory();
  const { deregister } = usePushNotifications();

  useEffect(() => {
    if (auth0.error) {
      // Always redirect `Invalid state` errors to the root URL because they're often caused by old state,
      // so loading the root will either clear the invalid state and the app loads normally, or the
      // user will be taken to the login page again.
      if (auth0.error.message?.includes?.("Invalid state")) {
        history.replace("/");
      } else {
        // An otherwise unknown error, log:
        trackError(auth0.error, `useAuth0 error: \`${auth0.error.message}\``);
      }
    }
  }, [auth0.error, history]);

  const providerProps = useMemo(
    () => ({
      ...auth0,
      logout: async (options?: LogoutOptions) => {
        shutdown();
        analyticsLogout();
        await deregister();

        return auth0.logout({
          logoutParams: {
            returnTo: process.env.REACT_APP_APPLICATION_DOMAIN
          },
          openUrl: Capacitor.isNativePlatform() ? false : undefined,
          ...options
        });
      }
    }),
    [auth0, deregister]
  );

  return !auth0.isLoading ? <Auth0Context.Provider value={providerProps}>{children}</Auth0Context.Provider> : null;
}
