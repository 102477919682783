import { isNullOrUndefined } from "utils/helpers";

/**
 * Utility function for tracking the number of characters remaining in an input field
 * so that the value can be used as display feedback to users.  If there is no
 * `characterLimit`, then the value returned will always be -1.
 */
export default function calculateCharacterRemaining(text: unknown, characterLimit?: number): number {
  // null and undefined need to converted to an empty string, otherwise they'll become the words 'null' and 'undefined'
  const valueLength = (isNullOrUndefined(text) ? "" : String(text)).length;

  return characterLimit ? characterLimit - valueLength : -1;
}
