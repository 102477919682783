import {
  updateBuyerByIdFailure,
  UpdateBuyerByIdRequest,
  updateBuyerByIdSuccess
} from "redux/reducers/supplier/buyerById";
import createSagaNext from "redux/tasks/createSagaNext";
import { putDataToApiWrapper } from "services/helpers";

/**
 * Saga to call `PUT /supplier/v1/buyers/{buyerId}` to update a buyer's details.
 */
export default createSagaNext<UpdateBuyerByIdRequest>(
  (auth0, tenancyId, { buyerId, contact }) => {
    return putDataToApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/supplier/v1/buyers/${buyerId}`,
      tenancyId,
      contact
    );
  },
  {
    successAction: updateBuyerByIdSuccess,
    failureAction: updateBuyerByIdFailure
  }
);
