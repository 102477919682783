import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useMount } from "react-use";

import LoadingScreen from "pages/LoadingScreen";
import { loadTenancyIdRequest } from "redux/reducers/tenancy";
import { BuyerNewOrder, SupplierNewOrder } from "routes/Routes";
import { trackError } from "utils/Errors";
import { isNullOrUndefined } from "utils/helpers";
import useAppSelector from "utils/hooks/useAppSelector";
import useCompanyRoles from "utils/hooks/useCompanyRoles";

/**
 * Handles redirecting from an external order link to an internal one,
 * respecting the URL search params so they can be applied to a new order in the
 * same format.
 */
export default function RedirectToNewOrder(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isBuyer } = useCompanyRoles();

  const {
    // Pull the companyId from the navigationData permissions state so we know the navigation data has finished loading
    permissions: { companyId: tenancyId }
  } = useAppSelector(state => state.navigationData);

  useEffect(() => {
    if (tenancyId && !isNullOrUndefined(isBuyer)) {
      const urlSearchParams = new URLSearchParams(location.search);

      if (isBuyer) {
        history.push(BuyerNewOrder.toUrl({ tenancyId }, urlSearchParams));
      } else {
        history.push(SupplierNewOrder.toUrl({ tenancyId }, urlSearchParams));
      }
    }
  }, [history, isBuyer, location.search, tenancyId]);

  useMount(() => {
    dispatch(
      loadTenancyIdRequest({
        failureCallback: error => trackError(error)
      })
    );
  });

  return <LoadingScreen />;
}
