import { PayloadAction } from "@reduxjs/toolkit";

import {
  uploadOrderAttachmentFailure,
  UploadOrderAttachmentRequest,
  uploadOrderAttachmentSuccess
} from "redux/actions/uploadOrderAttachment";
import { postDataToApiWrapper } from "services/helpers";

import createSagaNext from "./createSagaNext";

/**
 * Saga for uploading an attachment for an order comment.  Calls `POST
 * /global/internal/files/order-attachments`.
 */
export default createSagaNext<PayloadAction<UploadOrderAttachmentRequest>>(
  (auth0, tenancyId, { attachment }) => {
    const formData = new FormData();
    formData.append("attachment", attachment);
    return postDataToApiWrapper(
      auth0,
      `${process.env.REACT_APP_API_URL}/global/internal/files/order-attachments`,
      tenancyId,
      formData
    );
  },
  {
    successAction: uploadOrderAttachmentSuccess,
    failureAction: uploadOrderAttachmentFailure
  }
);
