import { Auth0ContextInterface } from "@auth0/auth0-react";

import { createSaga } from "redux/tasks/createSaga";
import { fetchDataFromApiWrapper } from "services/helpers";
import { Supplier } from "types/api";

import { fetchPriceListFailure, FetchPriceListRequest, fetchPriceListSuccess } from "../reducers/priceList";

/**
 * Call the API to retrieve the price list with the given ID.
 */
function fetchPriceList(
  auth: Auth0ContextInterface,
  tenancyId: string,
  priceListId: string
): Promise<Supplier.V1PricelistsDetail.ResponseBody> {
  return fetchDataFromApiWrapper(
    auth,
    `${process.env.REACT_APP_API_URL}/supplier/v1/pricelists/${priceListId}`,
    tenancyId
  );
}

/**
 * Saga to retrieve a price list and save it to redux.
 */
export default createSaga(fetchPriceList, {
  prepareApiMethodParams: ({ priceListId }: FetchPriceListRequest["payload"]) => [priceListId],
  successAction: fetchPriceListSuccess,
  errorFailureAction: fetchPriceListFailure
});
