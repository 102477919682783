import { useAuth0 } from "@auth0/auth0-react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { useCallback } from "react";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import AccountDropDownMenu from "components/AccountDropDownMenu";
import Button from "components/Button";
import useMessages from "i18n/hooks/useMessages";
import AppleLogo from "media/third-parties/apple.svg?react";
import GoogleLogo from "media/third-parties/google.svg?react";
import OnboardingPageLayout from "page-layouts/Onboarding";
import IdentityProvider from "types/IdentityProvider";
import { trackError } from "utils/Errors";
import useUrlParams from "utils/hooks/useUrlParams";
import { show } from "utils/Intercom";

import strings from "./ExistingAccountPage.strings.json";

const useStyles = makeStyles()(theme => ({
  header: {
    paddingTop: theme.spacing(5)
  },
  content: {
    "& > * + *": {
      marginTop: theme.spacing(3)
    }
  },
  signInButton: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(1, 2)
  },
  signInButtonIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}));

/**
 * Page for when a customer is attempting to create a new account but one
 * already exists with the same email address.
 */
export default function ExistingAccountPage(): JSX.Element {
  const { classes, cx } = useStyles();
  const identityProvider = useUrlParams("identityProvider").getValue();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const { loginWithRedirect, user } = useAuth0();

  const method =
    identityProvider === IdentityProvider.Google
      ? formatter.format(messages.METHOD_GOOGLE)
      : identityProvider === IdentityProvider.Apple
        ? formatter.format(messages.METHOD_APPLE)
        : identityProvider === IdentityProvider.Auth0
          ? formatter.format(messages.METHOD_AUTH0)
          : null;

  useMount(() => {
    if (method === null) {
      trackError(new Error("Unhandled identityProvider on Signup > Existing Account page"));
    }
  });

  const loginWithProvider = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        // connection must be undefined to use auth0
        connection: identityProvider === IdentityProvider.Auth0 ? undefined : identityProvider,
        prompt: "login"
      }
    });
  }, [identityProvider, loginWithRedirect]);

  return (
    <OnboardingPageLayout
      title={formatter.format(messages.TITLE)}
      containerMaxWidth="xs"
      LeftComp={<AccountDropDownMenu />}
    >
      <main>
        <header className={cx(classes.header)}>
          <Typography variant="h1" gutterBottom>
            <FormattedMessage id={messages.HEADER} />
          </Typography>
        </header>
        <div className={cx(classes.content)}>
          <Typography>
            <FormattedMessage id={messages.INSTRUCTIONS} values={{ method, email: user?.email }} />
          </Typography>
          <Button color="primary" variant="contained" className={cx(classes.signInButton)} onClick={loginWithProvider}>
            {identityProvider === IdentityProvider.Google ? (
              <GoogleLogo className={cx(classes.signInButtonIcon)} />
            ) : identityProvider === IdentityProvider.Apple ? (
              <AppleLogo className={cx(classes.signInButtonIcon)} />
            ) : null}
            <Typography>
              <FormattedMessage id={messages.SIGNINWITH} values={{ method }} />
            </Typography>
            <ChevronRightIcon className={cx(classes.rightIcon)} />
          </Button>
          <Button color="primary" displayAsLink onClick={show}>
            <FormattedMessage id={messages.HELP} />
          </Button>
        </div>
      </main>
    </OnboardingPageLayout>
  );
}
