import { useParams } from "react-router-dom";
import { useTitle } from "react-use";

import { CompanyRoot } from "routes/Routes";

import useAppSelector from "./useAppSelector";

/**
 * A hook to apply our standard title format to the browser.  This is normally
 * used by one of our `<Header>` components, so you shouldn't need to call this
 * directly unless your page is headerless.
 */
export default function useTitleForUpstock(pageName?: string): void {
  const { tenancyId } = useParams<typeof CompanyRoot.params>();
  const tradingName = useAppSelector(state => state.tenancy.tradingName);

  useTitle([pageName, tenancyId ? tradingName : null, "Upstock"].filter(part => !!part).join(" • "));
}
