import { MessageFormatter } from "@ultraq/icu-message-formatter";
import { noCase } from "change-case";

import { ValidationErrorCodes } from "types/api";

import strings from "./index.strings.json";

export const isNullOrUndefined = (val: any): val is null | undefined => val === null || val === undefined;
export const convertNullOrUndefinedToEmptyString = <V>(val: V | null | undefined): V | string =>
  isNullOrUndefined(val) ? "" : val;

/**
 * Safely get all the values of a string-literal type. Part of the weird TypeScript dance where the static
 * world needs to communicate to the runtime world. Returns a compile error if the object passed in
 * doesn't contain all the cases.
 *
 * @example
 * type Animal = 'cat' | 'dog' | 'bird';
 * const animals = getStringValues<Animal>({ cat: 'cat', dog: 'dog' }); // Error: Property 'bird' is missing in ...
 * const animals = getStringValues<Animal>({ cat: 'cat', dog: 'dog', bird: 'bird' }); // ['cat', 'dog', 'bird']
 */
export function getStringValues<T extends string>(obj: Record<T, T>): T[] {
  return Object.values(obj) as T[];
}

export const getErrorCodeText = (
  errorCodeArray: string[],
  fieldName: string,
  formatter: MessageFormatter
): string[] => {
  const errorMessageArray: string[] = [];
  if (errorCodeArray.includes(ValidationErrorCodes.NotDuplicateValidator)) {
    errorMessageArray.push(
      formatter.format(strings.ALREADY_USED, {
        fieldName: noCase(fieldName)
      })
    );
  }

  if (errorCodeArray.includes(ValidationErrorCodes.NotEmptyValidator)) {
    errorMessageArray.push(formatter.format(strings.FIELD_REQUIRED));
  }

  return errorMessageArray;
};

interface ProductLike {
  productId: string;
  [k: string]: any;
}

interface FavouriteListLike {
  products: ProductLike[];
  [k: string]: any;
}

export const getMappedProductsToFavourites = <P extends ProductLike>(
  productsList: P[],
  favouriteList: FavouriteListLike
): Array<P & { isAFavourite: boolean }> =>
  productsList.map(product => {
    const foundProduct = favouriteList.products.some(
      favouriteProduct => favouriteProduct.productId === product.productId
    );
    return { ...product, isAFavourite: foundProduct };
  });
