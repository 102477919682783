import { Edit as EditIcon } from "@mui/icons-material";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage, useMessageFormatter } from "@ultraq/react-icu-message-formatter";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUnmount } from "react-use";
import { keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";

import Button from "components/Button";
import FixedFooter from "components/Footer/FixedFooter";
import { useIntegrationName } from "components/IntegrationName/IntegrationName";
import MobileEditLineItemModal from "components/Orders/MobileEditLineItemModal";
import QuickAddProducts, { QuickAddProductsProps } from "components/Orders/QuickAddProducts";
import ReadOnlyLineItemsTable from "components/Orders/ReadOnlyLineItemsTable";
import SupplierEditLineItemsTable, { UnitPriceChange } from "components/Orders/SupplierEditLineItemsTable";
import Tooltip from "components/Tooltip";
import useMessages from "i18n/hooks/useMessages";
import NavigationPrompt from "routes/NavigationPrompt";
import { FetchV2Error, isFetchV2Error } from "services/fetchV2";
import { ValidationErrorCodes } from "types/api";
import {
  ApiValidationProblem,
  BuyerExternalReferenceExternalIdentifier,
  IncomingOrderProvider,
  InvoiceLinesSortOrderType,
  OrderActionType,
  SupplierProductSummary
} from "types/api/generated/supplier";
import { OrderLinePackingStatus } from "types/api/generated/supplier-internal";
import { LocalInvalidOrderLine, LocalOrderLine } from "types/Orders";
import { useAnalytics } from "utils/analytics";
import { isApiValidationProblemWithErrorCode, isFetchError } from "utils/Errors";
import useAppSelector from "utils/hooks/useAppSelector";
import useErrorSnackbar from "utils/hooks/useErrorSnackbar";
import useModal from "utils/hooks/useModal";
import useSnackbar from "utils/hooks/useSnackbar";
import { getOrderDocument, hasOrderAction } from "utils/Orders";

import AddProductsModal from "../components/AddProductsModal";
import EditOrderProviderWarningModal from "../components/EditOrderProviderWarningModal";
import OrderAlreadyConsolidatedModal from "../components/OrderAlreadyConsolidatedModal";
import OrderCannotBeSyncedModal from "../components/OrderCannotBeSyncedModal";
import RemoveInvalidLineModal from "../components/RemoveInvalidLineModal";
import useAddOrderLines from "../queries/useAddOrderLines";
import useRemoveOrderLines from "../queries/useRemoveOrderLines";
import useReplaceOrderLines from "../queries/useReplaceOrderLines";
import useUpdateOrderLinesPackingStatus from "../queries/useUpdateOrderLinePackingStatus";
import useUpdateOrderLines from "../queries/useUpdateOrderLines";
import { setIsEditing, SupplierOrderByIdState } from "../reducers/supplierOrderById";
import { SupplierViewOrderReduxState } from "../Types";

import strings from "./LineItemsSection.strings.json";

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  headerLeft: {
    display: "flex",
    alignItems: "baseline"
  },
  editedLink: {
    padding: 0,
    margin: 0,
    marginLeft: theme.spacing(2),
    border: "none",
    background: "none",
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1,
    color: theme.palette.text.secondary,
    borderBottom: "1px solid currentColor",
    cursor: "pointer",
    "&:hover, &:focus": {
      color: theme.palette.text.primary,
      outline: "none"
    }
  },
  editItemsButton: {
    display: "inline-flex",
    alignItems: "baseline"
  },
  editItemsButtonIcon: {
    position: "relative",
    top: "2px",
    width: "18px",
    height: "18px"
  },
  footer: {
    animationTimingFunction: "ease-in-out",
    animationDuration: "0.3s",
    animationName: keyframes`
      from { transform: translateY(100%) }
      to { transform: translateY(0) }
    `,
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(2)
    }
  },
  customPricesTooltip: {
    // Only show the underline when the tooltip is enabled
    "&[data-tooltip=true]": {
      textDecoration: "underline"
    }
  }
}));

export interface LineItemsSectionProps {
  orderId: number;
  orderLines: LocalOrderLine[];
  orderDetails: SupplierOrderByIdState;
  quickAddProducts?: SupplierProductSummary[];
  showEditedLink?: boolean;
  canEditOrderInProvider?: boolean;
  hideCannotSyncOrderModal?: boolean;
  hideOrderProviderEditWarningModal?: boolean;
  sortBy?: InvoiceLinesSortOrderType;
  eTag?: string | null;
  onMatchProduct?: (lineItem: LocalInvalidOrderLine) => void;
  onEditedLinkClick?: () => void;
  onRefreshOrder?: () => void;
}

const LineItemsSection: FunctionComponent<LineItemsSectionProps> = ({
  orderId,
  orderLines,
  orderDetails,
  quickAddProducts = [],
  showEditedLink = false,
  canEditOrderInProvider = false,
  hideCannotSyncOrderModal = false,
  hideOrderProviderEditWarningModal = false,
  sortBy,
  eTag,
  onMatchProduct = () => {},
  onEditedLinkClick = () => {},
  onRefreshOrder = () => {}
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const messages = useMessages(strings);
  const { formatter } = useMessageFormatter();
  const { showSnackbar } = useSnackbar();
  const { showErrorSnackbar } = useErrorSnackbar();
  const analytics = useAnalytics();
  const isEditing = useAppSelector((state: SupplierViewOrderReduxState) => state.supplierOrderById.isEditing);
  const [editedLineItems, setEditedLineItems] = useState<Array<LocalOrderLine>>([]);
  const [mobileEditingLineItem, setMobileEditingLineItem] = useState<LocalOrderLine | null>(null);
  const [isMobileEditLineItemModalOpen, openMobileEditLineItemModal, closeMobileEditLineItemModal] = useModal();
  const [isAddProductsModalOpen, openAddProductsModal, closeAddProductsModal] = useModal();
  const [isAlreadyConsolidatedModalOpen, openAlreadyConsolidatedModal, closeAlreadyConsolidatedModal] = useModal();
  const [hasAlreadyConsolidatedModalConfirmed, setHasAlreadyConsolidatedModalConfirmed] = useState(false);
  const [isCannotSyncModalOpen, openCannotSyncModal, closeCannotSyncModal] = useModal();
  const [isEditOrderProviderWarningModalOpen, openEditOrderProviderWarningModal, closeEditOrderProviderWarningModal] =
    useModal();
  const [hasShownEditOrderProviderWarningModal, setHasShownEditOrderProviderWarningModal] = useState(false);
  const [hasCannotSyncModalConfirmed, setHasCannotSyncModalConfirmed] = useState(false);
  const [continueToEditingCallback, setContinueToEditingCallback] = useState<() => void>(() => {});
  const orderProviderName = useIntegrationName(orderDetails.orderProvider);

  const orderDocument = getOrderDocument(orderDetails);
  const canEditLineItems = hasOrderAction(orderDetails, OrderActionType.EditOrderLines);
  const { updatingItems } = orderDetails;
  const hasPrices = orderLines.some(lineItem => Boolean(lineItem.unitAmount));
  const { mutate: addLinesToOrder, isLoading: isAddingItems } = useAddOrderLines();
  const { mutate: removeOrderLines, isLoading: isRemovingItems } = useRemoveOrderLines();
  const { mutate: replaceOrderLinesRequest } = useReplaceOrderLines();
  const { mutate: updateOrderLines } = useUpdateOrderLines();
  const { mutate: updateOrderLinesPackingStatus } = useUpdateOrderLinesPackingStatus();

  function isExternalIdentifier(reference: any): reference is BuyerExternalReferenceExternalIdentifier {
    return reference?.$type === "ExternalIdentifier";
  }

  const externalReference = orderDetails.buyerMetadata.externalReferences.find(isExternalIdentifier);

  const uniqueSkuCount = orderLines.reduce((acc: string[], curr) => {
    if (!acc.includes(curr.productCode)) {
      acc.push(curr.productCode);
    }
    return acc;
  }, []).length;
  const totalQuantities = orderLines.reduce((acc: number, curr) => acc + curr.quantity, 0);
  const hasPackedOneLine = orderLines.some(line => line.packingStatus === OrderLinePackingStatus.Packed);

  const pricingSource =
    // Show "Custom prices" if there's any custom prices
    orderDetails.hasCustomPrices ? (
      <Tooltip
        className={cx(classes.customPricesTooltip)}
        isInline
        // Only show the tooltip for orders from a provider (Foodstuffs orders) and
        // disable it when in edit mode so it doesn't interfere with the reset prices dropdown
        disabled={!orderDetails.orderProvider || isEditing}
        title={
          <FormattedMessage
            id={messages.CUSTOM_PRICES_ORDER_PROVIDER_TOOLTIP}
            values={{
              orderProvider: orderProviderName,
              learnMore: { articleId: 7204860 }
            }}
          />
        }
      >
        <FormattedMessage id={messages.CUSTOM_PRICES} />
      </Tooltip>
    ) : // Else show the price list name if ones set
    orderDetails.priceListName ? (
      orderDetails.priceListName
    ) : // Else show the order provider name if ones set
    orderDetails.orderProvider ? (
      formatter.format(messages.ORDER_PROVIDER_PRICES, { orderProvider: orderProviderName })
    ) : (
      // Else show "No price list"
      formatter.format(messages.NO_PRICE_LIST)
    );

  // Update the pricing source in real time when editing an order
  const editedPricingSource =
    // If any price doesn't match the price list price and was modified, show "Custom prices"
    editedLineItems.some(
      lineItem => lineItem.unitAmount?.amount !== lineItem.priceListUnitAmount?.amount && lineItem.hasCustomUnitAmount
    )
      ? formatter.format(messages.CUSTOM_PRICES)
      : // Else if every price matches the price list prices and a price was modified, show the current price list name if ones set
        orderDetails.currentPriceListName &&
          editedLineItems.every(lineItem => lineItem.unitAmount?.amount === lineItem.priceListUnitAmount?.amount) &&
          editedLineItems.some(lineItem => lineItem.hasCustomUnitAmount)
        ? orderDetails.currentPriceListName
        : // Else show the normal pricing source
          pricingSource;

  const resetState = useCallback((): void => {
    closeMobileEditLineItemModal();
    closeAddProductsModal();
    closeAlreadyConsolidatedModal();
    closeCannotSyncModal();
    dispatch(setIsEditing(false));
  }, [
    closeMobileEditLineItemModal,
    closeAddProductsModal,
    closeAlreadyConsolidatedModal,
    closeCannotSyncModal,
    dispatch
  ]);

  // Close the modals and disable editing mode when switching between desktop and mobile to prevent weird behaviour
  useEffect(() => {
    resetState();
  }, [isXsDown, resetState]);

  // Make sure editing mode is disabled when the navigating away from the page
  useUnmount(() => {
    dispatch(setIsEditing(false));
  });

  const openEditProviderOrderWarningModalIfNeeded = (): void => {
    if (
      (orderDetails.orderProvider === IncomingOrderProvider.Foodstuffs ||
        orderDetails.orderProvider === IncomingOrderProvider.Woolworths) &&
      !hasShownEditOrderProviderWarningModal &&
      !hideOrderProviderEditWarningModal
    ) {
      openEditOrderProviderWarningModal();
      setHasShownEditOrderProviderWarningModal(true);
    }
  };

  const requestStartEditing = (startEditingCallback: () => void): void => {
    if (orderDetails.invoiceIsConsolidated && orderDetails.invoice && !hasAlreadyConsolidatedModalConfirmed) {
      setContinueToEditingCallback(() => () => {
        startEditingCallback();
        closeAlreadyConsolidatedModal();
        setHasAlreadyConsolidatedModalConfirmed(true);
      });
      openAlreadyConsolidatedModal();
    } else if (!canEditOrderInProvider && !hideCannotSyncOrderModal && !hasCannotSyncModalConfirmed) {
      setContinueToEditingCallback(() => () => {
        startEditingCallback();
        closeCannotSyncModal();
        setHasCannotSyncModalConfirmed(true);
      });
      openCannotSyncModal();
    } else {
      startEditingCallback();
    }
  };

  const desktopStartEditing = (): void => {
    requestStartEditing(() => {
      setEditedLineItems(orderLines);
      dispatch(setIsEditing(true));
    });

    analytics.track("Order Edit Link Clicked", { orderStatus: orderDetails.orderStatus });
  };

  const desktopCancelEditing = (): void => {
    dispatch(setIsEditing(false));
  };

  const mobileEditLineItem = (lineItem: LocalOrderLine): void => {
    requestStartEditing(() => {
      setMobileEditingLineItem(lineItem);
      openMobileEditLineItemModal();
    });

    analytics.track("Order Edit Link Clicked", { orderStatus: orderDetails.orderStatus });
  };

  const editErrorHandler = (error: Error | ApiValidationProblem | FetchV2Error | unknown): void => {
    if (
      isApiValidationProblemWithErrorCode(error, ValidationErrorCodes.OrderNotEditable) ||
      (isFetchV2Error(error) && isApiValidationProblemWithErrorCode(error.body, ValidationErrorCodes.OrderNotEditable))
    ) {
      showSnackbar(<FormattedMessage id={messages.EDIT_ERROR_ORDER_SHIPPED} />, { isError: true });
      resetState();
      onRefreshOrder();
    } else if ((isFetchError(error) && error.status === 412) || (isFetchV2Error(error) && error.status === 412)) {
      showSnackbar(<FormattedMessage id={messages.EDIT_ERROR_ORDER_CHANGED} />, { isError: true });
      resetState();
      onRefreshOrder();
    } else {
      showErrorSnackbar(error, <FormattedMessage id={messages.EDIT_ERROR_GENERIC} />);
    }
  };

  const handleDesktopQuantityChange = (lineItem: LocalOrderLine, quantity: number): void => {
    setEditedLineItems(prevLineItems => {
      return prevLineItems.map<LocalOrderLine>(prevLineItem => {
        if (isMatchingLine(lineItem, prevLineItem)) {
          if (prevLineItem.unitAmount && prevLineItem.lineAmount) {
            return {
              ...prevLineItem,
              quantity,
              lineAmount: {
                ...prevLineItem.lineAmount,
                amount: quantity * prevLineItem.unitAmount.amount
              },
              priceListLineAmount: prevLineItem.priceListUnitAmount && {
                ...prevLineItem.priceListUnitAmount,
                amount: quantity * prevLineItem.priceListUnitAmount.amount
              }
            };
          }

          return { ...prevLineItem, quantity };
        }

        return prevLineItem;
      });
    });
  };

  const handleDesktopUnitPriceChanges = (changes: UnitPriceChange<LocalOrderLine>[]): void => {
    setEditedLineItems(prevLineItems => {
      return prevLineItems.map<LocalOrderLine>(prevLineItem => {
        const change = changes.find(c => isMatchingLine(c.lineItem, prevLineItem));
        if (change && prevLineItem.unitAmount && prevLineItem.lineAmount) {
          return {
            ...prevLineItem,
            unitAmount: { ...prevLineItem.unitAmount, amount: change.unitPrice },
            lineAmount: { ...prevLineItem.lineAmount, amount: change.unitPrice * prevLineItem.quantity },
            hasCustomUnitAmount: true
          };
        }

        return prevLineItem;
      });
    });
  };

  const handleDesktopRemove = (lineItem: LocalOrderLine | LocalInvalidOrderLine): void => {
    if ("providerProductId" in lineItem) {
      return;
    }

    setEditedLineItems(prevLineItems => prevLineItems.filter(prevLineItem => !isMatchingLine(lineItem, prevLineItem)));
  };

  const handleDesktopSaveChanges = (): void => {
    replaceOrderLinesRequest(
      {
        eTag,
        orderId,
        orderLines: editedLineItems.map(lineItem => ({
          productId: lineItem.productId,
          orderLineId: lineItem.lineId,
          quantity: lineItem.quantity,
          customUnitAmount:
            lineItem.unitAmount &&
            // Only send the price if it's been changed by the user, to prevent price mismatches from being overridden
            lineItem.hasCustomUnitAmount
              ? lineItem.unitAmount.amount
              : undefined
        }))
      },
      {
        onSuccess() {
          showSnackbar(<FormattedMessage id={messages.ITEMS_UPDATED} />);
          dispatch(setIsEditing(false));
          openEditProviderOrderWarningModalIfNeeded();
        },
        onError: editErrorHandler
      }
    );
  };

  const handleAddQuickAddProduct: QuickAddProductsProps["onAdd"] = product => {
    if (isEditing) {
      setEditedLineItems(prevLineItems => {
        // Increment the quantity if the product is already in the order
        if (prevLineItems.find(line => line.productId === product.productId)) {
          return prevLineItems.map(line => {
            if (line.productId === product.productId) {
              return { ...line, quantity: line.quantity + 1 };
            }
            return line;
          });
        }

        // Otherwise add a new line item
        const newOrderLine: LocalOrderLine = {
          productId: product.productId,
          productName: product.name,
          productCode: product.code,
          quantity: 1,
          isAvailable: product.isAvailable,
          isOrderable: product.isOrderable,
          unavailableReason: product.unavailableReason,
          unitAmount: product.buyerPrice,
          lineAmount: product.buyerPrice,
          priceListUnitAmount: product.buyerPrice,
          priceListLineAmount: product.buyerPrice,
          hasCustomUnitAmount: false,
          lineId: prevLineItems.length,
          problems: []
        };
        return [...prevLineItems, newOrderLine];
      });
    } else {
      // Increment the quantity if the product is already in the order
      const currentQuantity = orderLines.find(line => line.productId === product.productId)?.quantity || 0;

      addLinesToOrder(
        {
          eTag,
          orderId,
          orderLines: [{ productId: product.productId, quantity: currentQuantity + 1 }]
        },
        {
          onSuccess() {
            showSnackbar(<FormattedMessage id={messages.ITEM_ADDED} />);
            openEditProviderOrderWarningModalIfNeeded();
          },
          onError: editErrorHandler
        }
      );
    }
  };

  const handleMobileRemove = (lineItem: LocalOrderLine): void => {
    removeOrderLines(
      {
        eTag,
        orderId,
        productIds: [{ productId: lineItem.productId }]
      },
      {
        onSuccess() {
          showSnackbar(<FormattedMessage id={messages.ITEM_REMOVED} />);
          closeMobileEditLineItemModal();
          openEditProviderOrderWarningModalIfNeeded();
        },
        onError: editErrorHandler
      }
    );
  };

  const handleMobileUpdate = (lineItem: LocalOrderLine, quantity: number, unitAmount?: number): void => {
    // Consider the line item having a custom price when unitAmount contains a value
    const hasCustomUnitAmount = lineItem.hasCustomUnitAmount || unitAmount != null;
    // Fallback to lineItem.unitAmount so existing custom prices are not reset
    const price = unitAmount != null ? unitAmount : lineItem.unitAmount?.amount;

    updateOrderLines(
      {
        eTag,
        orderId,
        orderLines: [
          {
            productId: lineItem.productId,
            orderLineId: lineItem.lineId,
            quantity,
            customUnitAmount:
              price &&
              // Only send the price if it's been changed by the user, to prevent price mismatches from being overridden
              hasCustomUnitAmount
                ? price
                : undefined
          }
        ]
      },
      {
        onSuccess() {
          showSnackbar(<FormattedMessage id={messages.ITEM_UPDATED} />);
          closeMobileEditLineItemModal();
          openEditProviderOrderWarningModalIfNeeded();
        },
        onError: editErrorHandler
      }
    );
  };

  const handleAddProductSubmit = (lineItems: Array<LocalOrderLine>): void => {
    if (isEditing) {
      setEditedLineItems(lineItems);
      closeAddProductsModal();
    } else {
      updateOrderLines(
        {
          eTag,
          orderId,
          orderLines: lineItems.map(lineItem => ({
            productId: lineItem.productId,
            orderLineId: lineItem.lineId,
            quantity: lineItem.quantity,
            customUnitAmount:
              lineItem.unitAmount &&
              // Only send the price if it's been changed by the user, to prevent price mismatches from being overridden
              lineItem.hasCustomUnitAmount
                ? lineItem.unitAmount.amount
                : undefined
          }))
        },
        {
          onSuccess() {
            showSnackbar(<FormattedMessage id={messages.ITEMS_UPDATED} />);
            closeAddProductsModal();
            openEditProviderOrderWarningModalIfNeeded();
          },
          onError: editErrorHandler
        }
      );
    }
  };

  const onUpdateOrderPackingStatus = (line: LocalOrderLine, status: OrderLinePackingStatus): void => {
    updateOrderLinesPackingStatus(
      {
        lines: [{ lineId: line.lineId, status }],
        orderId: orderDetails.orderId
      },
      {
        onSuccess: ({ lines }) => {
          analytics.track("OrderLinePackingStatusChanged", {
            packStatus: status,
            orderId: orderDetails.orderId,
            orderStatus: orderDetails.orderStatus,
            packedPercentage: Math.round(
              (lines.filter(it => it.packingStatus === OrderLinePackingStatus.Packed).length / lines.length) * 100
            )
          });
        }
      }
    );
  };

  const [isRemoveInvalidLineModalOpen, openRemoveInvalidLineModal, closeRemoveInvalidLineModal] = useModal();
  const [invalidLineToRemove, setInvalidLineToRemove] = useState<LocalInvalidOrderLine>();

  const confirmRemoveInvalidLine = useCallback(
    (line: LocalInvalidOrderLine): void => {
      setInvalidLineToRemove(line);
      openRemoveInvalidLineModal();
    },
    [openRemoveInvalidLineModal]
  );

  return (
    <section className={cx(classes.root)}>
      <NavigationPrompt enabled={isEditing}>
        <FormattedMessage id={messages.UNSAVED_CHANGES_CONTENT} />
      </NavigationPrompt>

      <header className={cx(classes.header)}>
        <div className={cx(classes.headerLeft)}>
          <Typography variant="h2">
            <FormattedMessage id={messages.TITLE} />
          </Typography>
          {showEditedLink && (
            <button type="button" className={cx(classes.editedLink)} onClick={onEditedLinkClick}>
              <FormattedMessage id={messages.EDITED_LINK} />
            </button>
          )}
        </div>

        <Stack direction="row" gap={3} alignItems="center">
          {hasPackedOneLine && !isXsDown && !isEditing && (
            <Stack direction="row" gap={3}>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id={messages.TOTAL_QUANTITY_HEADING} values={{ count: totalQuantities }} />
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id={messages.UNIQUE_SKUS_HEADING} values={{ count: uniqueSkuCount }} />
              </Typography>
            </Stack>
          )}
          {canEditLineItems && !isXsDown && !isEditing && (
            <Button
              className={cx(classes.editItemsButton)}
              color="primary"
              startIcon={<EditIcon className={cx(classes.editItemsButtonIcon)} />}
              onClick={desktopStartEditing}
              data-chameleon-id="supplier-view-order-edit-items-button"
            >
              <FormattedMessage id={messages.EDIT_BUTTON} />
            </Button>
          )}
        </Stack>
      </header>

      {isEditing ? (
        <SupplierEditLineItemsTable
          lineItems={editedLineItems}
          pricingSource={editedPricingSource}
          priceListName={orderDetails.currentPriceListName}
          onQuantityChange={handleDesktopQuantityChange}
          onUnitPriceChange={handleDesktopUnitPriceChanges}
          onRemove={handleDesktopRemove}
          quickAddProducts={quickAddProducts}
          onAddItem={openAddProductsModal}
          sortOrder={sortBy}
        />
      ) : (
        <ReadOnlyLineItemsTable
          lineItems={orderLines}
          invalidLineItems={orderDetails.invalidLines}
          pricingSource={pricingSource}
          onMatchProduct={onMatchProduct}
          quickAddProducts={quickAddProducts}
          onAddItem={isXsDown && canEditLineItems ? openAddProductsModal : undefined}
          onEditItem={isXsDown && canEditLineItems ? mobileEditLineItem : undefined}
          onPackItem={!isXsDown ? onUpdateOrderPackingStatus : undefined}
          removeInvalidLine={confirmRemoveInvalidLine}
          tax={orderDetails.tax}
          sortOrder={sortBy}
        />
      )}

      {canEditLineItems && (
        <QuickAddProducts products={quickAddProducts} onAdd={handleAddQuickAddProduct} isAdding={isAddingItems} />
      )}

      {isEditing && (
        <FixedFooter className={cx(classes.footer)}>
          <Button variant="text" color="primary" onClick={desktopCancelEditing}>
            <FormattedMessage id={messages.CANCEL_BUTTON} />
          </Button>

          <Tooltip title={<FormattedMessage id={messages.NO_ITEMS_TOOLTIP} />} disabled={editedLineItems.length > 0}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDesktopSaveChanges}
              showLoader={updatingItems}
              disabled={editedLineItems.length <= 0}
              data-chameleon-id="supplier-view-order-save-button"
            >
              <FormattedMessage id={messages.SAVE_BUTTON} />
            </Button>
          </Tooltip>
        </FixedFooter>
      )}

      {mobileEditingLineItem && (
        <MobileEditLineItemModal
          open={isMobileEditLineItemModalOpen}
          lineItem={mobileEditingLineItem}
          onClose={closeMobileEditLineItemModal}
          onRemove={handleMobileRemove}
          onUpdate={handleMobileUpdate}
          isRemoving={isRemovingItems}
          isUpdating={updatingItems}
          disableRemoveButton={orderLines.length <= 1}
          removeButtonTooltip={orderLines.length <= 1 && <FormattedMessage id={messages.NO_ITEMS_TOOLTIP} />}
          submitButtonText={formatter.format(messages.MOBILE_EDIT_LINE_ITEM_SUBMIT_BUTTON)}
        />
      )}

      <AddProductsModal
        open={isAddProductsModalOpen}
        buyerId={orderDetails.buyerId}
        priceListName={orderDetails.priceListName}
        lineItems={isEditing ? editedLineItems : orderLines}
        onClose={closeAddProductsModal}
        onSubmit={handleAddProductSubmit}
        submitButtonText={formatter.format(
          isEditing ? messages.ADD_PRODUCT_SUBMIT_BUTTON_EDITING : messages.ADD_PRODUCT_SUBMIT_BUTTON_NOT_EDITING
        )}
        isSubmitting={updatingItems}
        hidePrices={!hasPrices}
      />

      <OrderAlreadyConsolidatedModal
        open={isAlreadyConsolidatedModalOpen}
        onClose={closeAlreadyConsolidatedModal}
        confirm={continueToEditingCallback}
      />

      <OrderCannotBeSyncedModal
        open={isCannotSyncModalOpen}
        onClose={closeCannotSyncModal}
        onConfirm={continueToEditingCallback}
        documentProvider={orderDocument?.documentProvider}
      />

      <EditOrderProviderWarningModal
        open={isEditOrderProviderWarningModalOpen}
        onClose={closeEditOrderProviderWarningModal}
        onConfirm={closeEditOrderProviderWarningModal}
        orderProvider={orderDetails.orderProvider}
      />

      <RemoveInvalidLineModal
        invalidLine={invalidLineToRemove}
        open={isRemoveInvalidLineModalOpen}
        orderId={orderDetails.orderId}
        onClose={closeRemoveInvalidLineModal}
        externalProvider={isExternalIdentifier(externalReference) ? externalReference.source : undefined}
      />
    </section>
  );
};

export default LineItemsSection;

/**
 * Match the line being edited (`incomingOrderLine`) against a stored order line
 * (`existingOrderLine`), returning whether the incoming line is the same as
 * the stored one.
 */
export function isMatchingLine(incomingLine: LocalOrderLine, existingLine: LocalOrderLine): boolean {
  // Check both lineId and productId - each existing order line will have a
  // lineId that is unique across the order (and undefined for newly added lines
  // but undefined === undefined), whereas productId can be duplicated for
  // Foodstuffs orders 🙃
  return incomingLine.lineId === existingLine.lineId && incomingLine.productId === existingLine.productId;
}
