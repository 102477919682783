import { InboxMessageDetails, PrivateFile } from "types/api/generated/supplier";

export const resolveFiles = (message: InboxMessageDetails): PrivateFile[] => {
  let files: PrivateFile[] = [];

  if (message.htmlBody) {
    files.push(message.htmlBody);
  } else if (message.textBody) {
    files.push(message.textBody);
  }

  files = files.concat(message.attachments);

  return files;
};
