import { QueryObserverOptions, useQuery, UseQueryResult } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { Supplier } from "types/api";
import { safeurl } from "utils/urls";

import { inboxQueryKey } from "./keys";

export type RequestBody = Supplier.V1MailboxInboxMessagesValidateOrderCreate.RequestBody;
type RequestQuery = Supplier.V1MailboxInboxMessagesValidateOrderCreate.RequestQuery;
export type RequestParamaters = Supplier.V1MailboxInboxMessagesValidateOrderCreate.RequestParams;
export type ResponseBody = Supplier.V1MailboxInboxMessagesValidateOrderCreate.ResponseBody;

type Options = QueryObserverOptions<ResponseBody>;

export const validatePurchaseOrder = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody, RequestParamaters>(
  parmeters => ({
    method: "POST",
    path: safeurl`/supplier/v1/mailbox/inbox/messages/validate-order/${parmeters.inboxMessageId}`
  })
);

const useValidatePurchaseOrder = (
  parameters: RequestParamaters,
  body: RequestBody,
  options: Options = {}
): UseQueryResult<ResponseBody> => {
  const apiFetch = useApiFetch();

  return useQuery({
    queryKey: inboxQueryKey.validate(parameters, body).queryKey,
    queryFn: async () => {
      const response = await apiFetch(validatePurchaseOrder(parameters, { data: body }));
      return response.data;
    },
    ...options
  });
};

export default useValidatePurchaseOrder;
