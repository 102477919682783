import { TypeHandler } from "@ultraq/icu-message-formatter";

import { displayFormattedCurrency } from "utils/numbers";

/**
 * Type handler for the `currency` type in ICU message strings.  This is just a
 * bridge between message formatter and native `Intl.NumberFormat` APIs with the
 * "currency" setting.
 */
const currencyTypeHandler: TypeHandler = (value, _, locale) => {
  return displayFormattedCurrency(value, locale);
};

export default currencyTypeHandler;
