import { all, takeLatest } from "redux-saga/effects";

import loadNextPageV2 from "redux/tasks/loadNextPageV2";

import {
  fetchDirectoryRequest,
  fetchFeaturedSuppliersRequest,
  loadNextPageRequest as buyerDirectoryLoadNextPageRequest
} from "../reducers/directory";
import { fetchPendingInvitesRequest } from "../reducers/pendingInvites";
import {
  fetchPendingLeadsRequest,
  loadNextPageRequest as pendingLeadsLoadNextPageRequest,
  submitNewLeadRequest,
  submitNewLeadSuccess
} from "../reducers/pendingLeads";
import { removeConnectionByIdRequest, removeConnectionByIdSuccess } from "../reducers/pendingSupplierById";
import { fetchSupplierDetailsRequest, inviteToTradeRequest } from "../reducers/supplierById";
import { fetchSuppliersStatsRequest } from "../reducers/suppliersStats";

import fetchDirectory from "./fetchDirectory";
import fetchFeaturedSuppliers from "./fetchFeaturedSuppliers";
import fetchPendingInvites from "./fetchPendingInvites";
import fetchPendingLeads from "./fetchPendingLeads";
import fetchSupplierById from "./fetchSupplierById";
import fetchSuppliersStats from "./fetchSuppliersStats";
import inviteToTrade from "./inviteToTrade";
import removePendingSupplierConnection from "./removePendingSupplierConnection";
import submitNewLead from "./submitNewLead";

/**
 * All of the sagas for the buyer directory.
 */
export default function* buyerDirectorySaga(): Generator {
  yield all([
    takeLatest(fetchSupplierDetailsRequest, fetchSupplierById),
    takeLatest(fetchDirectoryRequest, fetchDirectory),
    takeLatest(fetchFeaturedSuppliersRequest, fetchFeaturedSuppliers),
    takeLatest([fetchSuppliersStatsRequest, submitNewLeadSuccess], fetchSuppliersStats),
    takeLatest([fetchPendingLeadsRequest, submitNewLeadSuccess, removeConnectionByIdSuccess], fetchPendingLeads),
    takeLatest(submitNewLeadRequest, submitNewLead),
    takeLatest(removeConnectionByIdRequest, removePendingSupplierConnection),
    takeLatest(inviteToTradeRequest, inviteToTrade),
    takeLatest(fetchPendingInvitesRequest, fetchPendingInvites),
    takeLatest([buyerDirectoryLoadNextPageRequest, pendingLeadsLoadNextPageRequest], loadNextPageV2)
  ]);
}
