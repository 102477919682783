import { useState } from "react";
import { useDebounce } from "react-use";

const useDebounceValue = <T>(value: T, delay: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(() => setDebouncedValue(value), delay, [value]);

  return debouncedValue;
};

export default useDebounceValue;
