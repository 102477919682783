import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Buyer, BuyerOrderSummary, BuyerOrderSummaryPagedSet, PageLinks } from "types/api/generated/buyer";
import { Buyer as BuyerInternal } from "types/api/generated/buyer-internal";
import {
  FailureAction,
  LoadNextPageRequest,
  LoadPreviousPageRequest,
  PagingOptions,
  RequestAction,
  RequestPayload
} from "types/redux-helpers";

import { submitBuyerOrderSuccess } from "../../new-order/reducers/buyerNewOrder";

export interface BuyerOrdersState {
  links: PageLinks | null;
  loading: boolean;
  orders: BuyerOrderSummary[];
  total?: number | null;
}

const initialState: BuyerOrdersState = {
  orders: [],
  links: null,
  loading: true,
  total: 0
};

type BuyerOrdersViewRequest = RequestPayload<
  BuyerInternal.InternalViewsOrdersDueList.RequestQuery,
  BuyerOrderSummaryPagedSet
>;
export type BuyerOrdersViewResponse = BuyerOrderSummaryPagedSet & PagingOptions;

export type BuyerOrdersViewRequestAction = PayloadAction<BuyerOrdersViewRequest | undefined>;
export type BuyerOrdersViewSuccessAction = PayloadAction<BuyerOrdersViewResponse>;
export type FetchOrdersRequest = RequestAction<Buyer.V1OrdersList.RequestQuery, BuyerOrdersViewResponse>;
type FetchOrdersSuccess = PayloadAction<BuyerOrdersViewResponse>;

/**
 * State slice for a buyer's orders.
 *
 * TODO: Once loadNextBuyerItem(Request/Success/Failure) actions have moved over
 *       to redux toolkit we could include te results here. They fetch the next
 *       1 order in the list.  Currently the list will be re-fetched which is
 *       very minor issue.
 */
const buyerOrdersSlice = createSlice({
  name: "buyerOrders",
  initialState,
  reducers: {
    clearOrders: () => initialState,
    fetchOrdersRequest: (state, _action: FetchOrdersRequest) => ({
      ...state,
      orders: [],
      links: null,
      total: 0,
      loading: true
    }),
    fetchOrdersSuccess: (state, { payload: { data, links, total, overrideList, prepend } }: FetchOrdersSuccess) => ({
      ...state,
      orders: overrideList ? data : prepend ? [...data, ...state.orders] : [...state.orders, ...data],
      links,
      total,
      loading: false
    }),
    fetchOrdersFailure: (state, _action: FailureAction) => ({
      ...state,
      loading: false
    }),
    recentBuyerOrdersRequest: (state, _action: BuyerOrdersViewRequestAction) => ({
      ...state,
      loading: true,
      orders: []
    }),
    recentBuyerOrdersSuccess: (
      state,
      { payload: { overrideList, prepend, data, links } }: BuyerOrdersViewSuccessAction
    ) => ({
      ...state,
      orders: overrideList ? data : prepend ? [...data, ...state.orders] : [...state.orders, ...data],
      links,
      loading: false
    }),
    recentBuyerOrdersFailure: state => ({ ...state, loading: false }),
    allDueBuyerOrdersRequest: (state, _action: BuyerOrdersViewRequestAction) => ({
      ...state,
      loading: true,
      orders: []
    }),
    allDueBuyerOrdersSuccess: (
      state,
      { payload: { overrideList, prepend, data, links } }: BuyerOrdersViewSuccessAction
    ) => ({
      ...state,
      orders: overrideList ? data : prepend ? [...data, ...state.orders] : [...state.orders, ...data],
      links,
      loading: false
    }),
    allDueBuyerOrdersFailure: state => ({ ...state, loading: false }),
    scheduledBuyerOrdersRequest: (state, _action: BuyerOrdersViewRequestAction) => ({
      ...state,
      loading: true,
      orders: []
    }),
    scheduledBuyerOrdersSuccess: (
      state,
      { payload: { overrideList, prepend, data, links } }: BuyerOrdersViewSuccessAction
    ) => ({
      ...state,
      orders: overrideList ? data : prepend ? [...data, ...state.orders] : [...state.orders, ...data],
      links,
      loading: false
    }),
    scheduledBuyerOrdersFailure: state => ({ ...state, loading: false }),
    loadNextPageRequest: (state, _action: LoadNextPageRequest<BuyerOrderSummaryPagedSet>) => ({
      ...state,
      loading: true
    }),
    loadPrevPageRequest: (state, _action: LoadPreviousPageRequest<BuyerOrderSummaryPagedSet>) => ({
      ...state,
      loading: true
    })
  },
  extraReducers: builder => {
    builder
      // Clear all the orders when creating new ones - we need to reset paging
      // variables and the like otherwise infinite scrolling goes all weird!
      .addCase(submitBuyerOrderSuccess, (_state, _action) => initialState);
  }
});

export const {
  clearOrders,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  recentBuyerOrdersRequest,
  recentBuyerOrdersSuccess,
  recentBuyerOrdersFailure,
  allDueBuyerOrdersRequest,
  allDueBuyerOrdersSuccess,
  allDueBuyerOrdersFailure,
  scheduledBuyerOrdersRequest,
  scheduledBuyerOrdersSuccess,
  scheduledBuyerOrdersFailure,
  loadNextPageRequest,
  loadPrevPageRequest
} = buyerOrdersSlice.actions;
export default buyerOrdersSlice.reducer;
