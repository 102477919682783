export const HTTP_STATUS_FORBIDDEN = "Forbidden"; // 403
export const HTTP_STATUS_NOTFOUND = "NotFound"; // 404
export const HTTP_STATUS_REQUESTTIMEOUT = "RequestTimeout"; // 408
export const HTTP_STATUS_CONFLICT = "Conflict"; // 409

export const HTTP_STATUS_INTERNAL_SERVER = "InternalServer"; // 500

// TypeError in JavaScript
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
export const HTTP_STATUS_NETWORK_FAILURE = "NetworkFailure";
