export interface Customer {
  buyerId: string;
}

/**
 * Return whether or not the customer with the given `buyerId` is in the list of
 * `customers`.
 */
export function isCustomerInList<C extends Customer>(buyerId: string, customers: C[]): boolean {
  return customers.some(customer => customer.buyerId === buyerId);
}

/**
 * If the customer with the given `buyerId` exists in `customers`, then a new
 * array excluding that customer is returned, otherwise `customers` is returned
 * as is.
 */
export function excludeCustomerIfPresent<C extends Customer>(buyerId: string, customers: C[]): C[] {
  return isCustomerInList(buyerId, customers) ? customers.filter(customer => customer.buyerId !== buyerId) : customers;
}
