import useInjectReducerAndSaga from "utils/hooks/useInjectReducerAndSaga";

import priceListsReducer, { PriceListsState } from "./reducers";
import priceListsSaga from "./sagas";

export interface PriceListsReduxState {
  priceLists: PriceListsState;
}

export default function useInjectPriceLists(): boolean {
  return useInjectReducerAndSaga({
    key: "priceLists",
    reducer: priceListsReducer,
    saga: priceListsSaga
  });
}
