import { createQueryKeys } from "@lukemorales/query-key-factory";
import { DefinedUseQueryResult, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { FetchV2Error } from "services/fetchV2";
import { Supplier } from "types/api";

type RequestQuery = Supplier.V1BuyersStatsList.RequestQuery;
type RequestBody = Supplier.V1BuyersStatsList.RequestBody;
export type ResponseBody = Supplier.V1BuyersStatsList.ResponseBody;

type CustomersStatsQueryOptions = Pick<UseQueryOptions<ResponseBody, FetchV2Error>, "enabled">;

export const customersStatsKey = createQueryKeys("customersStats");

export const customerStatsOptions = buildApiFetchOptions<RequestBody, RequestQuery, ResponseBody>(() => ({
  method: "GET",
  path: "/supplier/v1/buyers/stats"
}));

/**
 * React hook to call `GET /supplier/v1/buyers/stats` for a count of a
 * supplier's customers across different states.
 */
export default function useCustomersStats(
  queryOptions?: CustomersStatsQueryOptions
): DefinedUseQueryResult<ResponseBody, FetchV2Error> {
  const apiFetch = useApiFetch();

  return useQuery<ResponseBody, FetchV2Error>({
    queryKey: customersStatsKey._def,
    async queryFn() {
      const response = await apiFetch(customerStatsOptions());
      return response.data;
    },
    initialData: {
      linkedCount: 0,
      invitedCount: 0,
      leadCount: 0,
      standingOrdersCount: 0,
      issues: []
    },
    useErrorBoundary: false,
    ...queryOptions
  });
}
