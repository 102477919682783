import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { SupplierInternal } from "types/api";
import { OrderLineResult } from "types/api/generated/buyer-internal";
import { Etag } from "types/Etag";

import { updatePricingSource } from "../reducers/supplierOrderById";

import supplierOrderLinesQueryKeys from "./supplierOrderLinesQueryKeys";
import supplierOrderQueryKeys from "./supplierOrderQueryKeys";

type RequestParams = SupplierInternal.InternalOrdersLinesDelete.RequestParams;
type RequestBody = SupplierInternal.InternalOrdersLinesDelete.RequestBody;
type RequestQuery = SupplierInternal.InternalOrdersLinesDelete.RequestQuery;
export type ResponseBody = SupplierInternal.InternalOrdersLinesDelete.ResponseBody;
export type Response = ResponseBody & { eTag: string | null };

export const removeOrderLinesParams = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  Response,
  RequestParams & RequestQuery & Etag
>(({ orderId, eTag }) => ({
  method: "DELETE",
  path: `/supplier/internal/orders/${orderId}/lines`,
  headers: { "If-Match": eTag ?? "" }
}));

export default function useRemoveOrderLines(): UseMutationResult<
  ResponseBody & Etag,
  unknown,
  RequestParams & Etag & { productIds: RequestBody }
> {
  const apiFetch = useApiFetch();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async variables => {
      const response = await apiFetch(
        removeOrderLinesParams(
          { orderId: variables.orderId, eTag: variables.eTag },
          {
            data: variables.productIds
          }
        )
      );

      return {
        ...response.data,
        eTag: response.headers?.get("etag")
      };
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData<OrderLineResult>(
        supplierOrderLinesQueryKeys.detail({ orderId: variables.orderId }).queryKey,
        _ => response
      );

      dispatch(updatePricingSource(response.lines));

      queryClient.invalidateQueries(supplierOrderQueryKeys.timeline(variables.orderId).queryKey);
    }
  });
}
