import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { buildApiFetchOptions, useApiFetch } from "services/apiFetch";
import { SupplierInternal } from "types/api";
import { OrderLineResult } from "types/api/generated/supplier-internal";
import { Etag } from "types/Etag";

import { updatePricingSource } from "../reducers/supplierOrderById";

import supplierOrderLinesQueryKeys from "./supplierOrderLinesQueryKeys";

type RequestParams = SupplierInternal.InternalOrdersLinesPackingStatusUpdate.RequestParams;
type RequestBody = SupplierInternal.InternalOrdersLinesPackingStatusUpdate.RequestBody;
type RequestQuery = SupplierInternal.InternalOrdersLinesPackingStatusUpdate.RequestQuery;
type ResponseBody = SupplierInternal.InternalOrdersLinesPackingStatusUpdate.ResponseBody;

export const updateOrderLinesPackStatusParams = buildApiFetchOptions<
  RequestBody,
  RequestQuery,
  ResponseBody,
  RequestParams
>(params => ({
  method: "PUT",
  path: `/supplier/internal/orders/${params.orderId}/lines/packing-status`
}));

export default function useUpdateOrderLinesPackingStatus(): UseMutationResult<
  ResponseBody & Etag,
  RequestQuery,
  RequestBody & RequestParams
> {
  const apiFetch = useApiFetch();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ orderId, lines }) => {
      const result = await apiFetch(updateOrderLinesPackStatusParams({ orderId }, { data: { lines } }));

      return {
        ...result.data,
        eTag: result.headers?.get("etag")
      };
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData<OrderLineResult>(
        supplierOrderLinesQueryKeys.detail({ orderId: variables.orderId }).queryKey,
        _ => response
      );

      dispatch(updatePricingSource(response.lines));
    }
  });
}
