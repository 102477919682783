import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useFetchUnauthenticatedOrder from "features/unauthenticated/view-order/queries/useFetchUnauthenticatedOrder";
import LoadingScreen from "pages/LoadingScreen";
import { fetchUsersCompaniesRequest } from "redux/reducers/tenancy";
import { BuyerViewOrder, ClaimedRedirect, Signup } from "routes/Routes";
import {
  UNAUTHENTICATED_ORDER_DETAIL_KEY_SUPPLIERID,
  UNAUTHENTICATED_ORDER_DETAIL_KEY_VIEWID
} from "utils/UnauthenticatedOrders";

/**
 * Page from which we add invited users to a claimed company.  The UI is only
 * the loading animation while we communicate with the API.
 */
export default function ClaimedRedirectPage(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const { tenancyId } = useParams<typeof ClaimedRedirect.params>();
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(location.search);
  const supplierId = urlSearchParams.get(UNAUTHENTICATED_ORDER_DETAIL_KEY_SUPPLIERID) ?? "";
  const viewId = urlSearchParams.get(UNAUTHENTICATED_ORDER_DETAIL_KEY_VIEWID) ?? "";
  const { data: order, isLoading } = useFetchUnauthenticatedOrder({ supplierId, viewId });

  useEffect(() => {
    const redirectToSignup = (): void => {
      history.push(Signup.toUrl());
    };
    // console.log(order, isLoading);
    if (order && !isLoading) {
      dispatch(
        fetchUsersCompaniesRequest({
          successCallback: companies => {
            const alreadyUserOfCompany = companies?.some(comp => comp.companyId === tenancyId);
            if (alreadyUserOfCompany) {
              history.push(BuyerViewOrder.toUrl({ tenancyId, orderId: order.buyerOrderId.toString() }));
            } else {
              redirectToSignup();
            }
          },
          failureCallback: redirectToSignup
        })
      );
    }
  }, [dispatch, history, isLoading, order, tenancyId]);

  return <LoadingScreen />;
}
