import { ActionCreator, createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import { PageLinks, PriceListProduct, Supplier } from "types/api/generated/supplier";
import { LoadNextPageRequest, LoadPreviousPageRequest, PagingOptions, RequestAction } from "types/redux-helpers";
import { DEFAULT_PAGE_SIZE } from "utils/paging";

import { mergeProductLists, updateProductList } from "../utilities/products";

export interface PriceListProductsState {
  list: PriceListProduct[];
  links: PageLinks | null;
  total?: number | null;
  limit?: number | null;
  loading: boolean;
  allProducts: PriceListProduct[];
  saving: boolean;
  saved: boolean;
}

const initialState: PriceListProductsState = {
  list: [],
  links: null,
  total: 0,
  limit: DEFAULT_PAGE_SIZE,
  loading: true,
  allProducts: [],
  saving: false,
  saved: false
};

export type FetchPriceListProductsRequest = RequestAction<
  Supplier.V1PricelistsProductsDetail.RequestQuery & PagingOptions,
  Supplier.V1PricelistsProductsDetail.ResponseBody & PagingOptions
>;
type FetchPriceListProductsSuccess = PayloadAction<Supplier.V1PricelistsProductsDetail.ResponseBody & PagingOptions>;
export type UpdatePriceListProductsRequest = RequestAction<{
  priceListId: string;
  productDetails: PriceListProduct[];
  successAction: ActionCreator<UpdatePriceListProductsSuccess>;
}>;
type UpdatePriceListProductsSuccess = PayloadAction<{
  priceListId: string;
  productDetails: Supplier.V1PricelistsProductsCreate.ResponseBody;
}>;

/**
 * State slice for working over a single price list's products.
 */
const priceListProducts = createSlice({
  name: "priceListProducts",
  initialState,
  reducers: {
    fetchPriceListProductsRequest: (state, { payload }: FetchPriceListProductsRequest) => ({
      ...state,
      list: payload.overrideList ? [] : state.list,
      loading: true
    }),
    fetchPriceListProductsSuccess: (
      state,
      { payload: { data, total, links, overrideList = true } }: FetchPriceListProductsSuccess
    ) => ({
      ...state,
      allProducts: mergeProductLists(current(state).allProducts, data),
      list: overrideList ? data : [...state.list, ...data],
      total,
      links,
      loading: false
    }),
    fetchPriceListProductsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      loading: false
    }),
    fetchNextPageRequest: (state, _payload: LoadNextPageRequest) => ({ ...state, loading: true }),
    fetchPreviousPageRequest: (state, _payload: LoadPreviousPageRequest) => ({ ...state, loading: true }),
    hideProductsRequest: (state, _action: UpdatePriceListProductsRequest) => ({
      ...state,
      saving: true,
      saved: false
    }),
    hideProductsSuccess: (state, _action: UpdatePriceListProductsSuccess) => ({
      ...state,
      allProducts: [],
      saving: false,
      saved: true
    }),
    showProductsRequest: (state, _action: UpdatePriceListProductsRequest) => ({
      ...state,
      saving: true,
      saved: false
    }),
    showProductsSuccess: (state, _action: UpdatePriceListProductsSuccess) => ({
      ...state,
      allProducts: [],
      saving: false,
      saved: true
    }),

    // Save the given discount on request, but use the full discount calculation
    // done by the server from the response.
    applyDiscountsRequest: (state, { payload: { productDetails } }: UpdatePriceListProductsRequest) => ({
      ...state,
      list: updateProductList(state.list, productDetails),
      saving: true,
      saved: false
    }),
    applyDiscountsSuccess: (state, { payload: { productDetails } }: UpdatePriceListProductsSuccess) => ({
      ...state,
      list: updateProductList(state.list, productDetails),
      saving: false,
      saved: true
    }),

    updatePriceListProductsFailure: (state, { payload: error }) => ({
      ...state,
      error,
      saving: false
    })
  }
});

export const {
  fetchPriceListProductsRequest,
  fetchPriceListProductsSuccess,
  fetchPriceListProductsFailure,
  fetchNextPageRequest,
  fetchPreviousPageRequest,
  hideProductsRequest,
  hideProductsSuccess,
  showProductsRequest,
  showProductsSuccess,
  applyDiscountsRequest,
  applyDiscountsSuccess,
  updatePriceListProductsFailure
} = priceListProducts.actions;
export default priceListProducts.reducer;
