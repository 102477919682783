import { combineReducers } from "@reduxjs/toolkit";

import allPriceLists from "./allPriceLists";
import metadata from "./metadata";
import priceList from "./priceList";
import priceListCustomers from "./priceListCustomers";
import priceListProducts from "./priceListProducts";
import priceListStats from "./priceListStats";

/**
 * The price lists combined reducer.
 */
const reducers = combineReducers({
  metadata,
  allPriceLists,
  priceList,
  priceListCustomers,
  priceListProducts,
  priceListStats
});

export type PriceListsState = ReturnType<typeof reducers>;

export default reducers;
