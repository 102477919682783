import { createQueryKeys } from "@lukemorales/query-key-factory";

export const inboxQueryKey = createQueryKeys("inbox-messages", {
  list: parameters => [parameters],
  detail: parameters => [parameters],
  file: parameters => [parameters],
  stats: parameters => [parameters],
  delete: () => [{}],
  suggestions: (query, parameters, message, _) => [query, parameters, message],
  validate: (parameters, body) => [parameters, body]
});
