import { Checkbox, FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@ultraq/react-icu-message-formatter";

import Button from "components/Button";
import IntegrationName from "components/IntegrationName/IntegrationName";
import Modal, { ModalProps } from "components/Modal";
import useMessages from "i18n/hooks/useMessages";
import { ModalToHide } from "types/api/generated/directory-internal";
import { ApiTokenType } from "types/api/generated/supplier";
import useDontShowAgain from "utils/hooks/useDontShowAgain";

import strings from "./OrderCannotBeSyncedModal.strings.json";

interface Props extends ModalProps {
  documentProvider?: ApiTokenType;
  onConfirm?: () => void;
}

/**
 * Modal for warning users that changes to this order will not be reflected in
 * an external system.
 */
export default function OrderCannotBeSyncedModal({
  documentProvider,
  open,
  onClose,
  onConfirm = () => {},
  ...modalProps
}: Props): JSX.Element {
  const messages = useMessages(strings);
  const { actionWithHideModalRequest, dontShowAgain, updateDontShowAgain } = useDontShowAgain(
    ModalToHide.CannotSyncOrder,
    onConfirm
  );

  return (
    <Modal
      data-testid="cannotbesynced-modal"
      open={open}
      maxWidth="xs"
      title={<FormattedMessage id={messages.TITLE} />}
      onClose={onClose}
      actions={
        <>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage id={messages.ACTION_CANCEL} />
          </Button>
          <Button color="primary" variant="contained" onClick={actionWithHideModalRequest}>
            <FormattedMessage id={messages.ACTION_CONTINUE} />
          </Button>
        </>
      }
      {...modalProps}
    >
      <Typography paragraph>
        <FormattedMessage
          id={messages.CONTENT}
          values={{ providerName: <IntegrationName source={documentProvider!} /> }}
        />
      </Typography>
      <FormControlLabel
        control={
          <Checkbox checked={dontShowAgain} color="primary" name="dont-show-again" onChange={updateDontShowAgain} />
        }
        label={<FormattedMessage id={messages.DONTSHOWAGAIN} />}
      />
    </Modal>
  );
}
