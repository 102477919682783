import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";
import { JSX } from "react";

import LocalLinkOrExternalLink, { LocalLinkOrExternalLinkProps } from "components/LocalLinkOrExternalLink";

export interface ListItemLinkProps extends ListItemButtonProps, LocalLinkOrExternalLinkProps {
  containerClassName?: string;
}

/**
 * A combined Material UI `<ListItem>` that renders either a react-router
 * `<Link>` for local links, or a Material UI `<Link>` for absolute URLs, so
 * that the list item can contain standard links.
 */
export default function ListItemLink({ children, containerClassName, ...rest }: ListItemLinkProps): JSX.Element {
  return (
    <li className={containerClassName}>
      <ListItemButton component={LocalLinkOrExternalLink} {...rest}>
        {children}
      </ListItemButton>
    </li>
  );
}
