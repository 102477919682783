import { Route, Switch } from "react-router-dom";

import PrivateRoute from "auth/PrivateRoute";
import PageNotFound from "pages/PageNotFound";
import { Settings as SettingsRoute, SupplierQuickAddProducts } from "routes/Routes";

import useInjectPriceLists from "../price-lists/useInjectPriceLists";

import QuickAddProductsPage from "./views/QuickAddProductsPage";

export default function QuickAddProductsFeature(): JSX.Element | null {
  const priceListsInjected = useInjectPriceLists();

  return priceListsInjected ? (
    <Switch>
      <PrivateRoute
        exact
        path={SupplierQuickAddProducts.path}
        permissions={SupplierQuickAddProducts.permissions}
        component={QuickAddProductsPage}
        fallbackRoute={SettingsRoute}
      />
      <Route component={PageNotFound} />
    </Switch>
  ) : null;
}
