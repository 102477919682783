import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export interface UseUrlParamsResult {
  getValue: () => string;
  hasValue: () => boolean;
  setValue: (value?: string) => URLSearchParams;
}

/**
 * React hook for reading and manipulating a single URL search parameter.
 */
const useUrlParams = (paramName: string): UseUrlParamsResult => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getValue = useCallback(() => {
    return urlParams.get(paramName) || "";
  }, [paramName, urlParams]);

  const hasValue = useCallback(() => {
    return getValue() !== "";
  }, [getValue]);

  const setValue: UseUrlParamsResult["setValue"] = useCallback(
    (val = "") => {
      const latestUrlParams = new URLSearchParams(history.location.search);
      if (!val) {
        latestUrlParams.delete(paramName);
      } else if (latestUrlParams.has(paramName)) {
        latestUrlParams.set(paramName, val);
      } else {
        latestUrlParams.append(paramName, val);
      }

      history.replace({
        ...location,
        search: decodeURIComponent(latestUrlParams.toString())
      });
      return latestUrlParams;
    },
    [history, location, paramName]
  );

  return useMemo(
    () => ({
      getValue,
      hasValue,
      setValue
    }),
    [getValue, hasValue, setValue]
  );
};

export default useUrlParams;
